import { Expose, plainToInstance } from 'class-transformer';
import { TimeUsuarioStatus } from '..';

export class TimeProjetoListarDto {
  @Expose()
  id: string;

  @Expose()
  nome: string;

  @Expose()
  status: TimeUsuarioStatus;

  @Expose({ name: 'usuario_criacao' })
  usuarioCriacao: string;

  @Expose({ name: 'data_criacao' })
  dataCriacao: Date;

  /**
  * Cria uma nova instância de TimeProjetoListarDto a partir de dtos
  * @param props Interface de TimeProjetoListar
  * @returns nova instância de TimeProjetoListarDto
  */
  static from(props?: TimeProjetoListar): TimeProjetoListarDto {
    return plainToInstance(TimeProjetoListarDto, props, { exposeUnsetFields: false, excludeExtraneousValues: true });
  }
}

export interface TimeProjetoListar {
  id: string;
  nome: string;
  status: TimeUsuarioStatus;
  usuario_criacao: string;
  data_criacao: string;
}
