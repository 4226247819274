import { Component, Inject, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Endereco } from '@usucampeao/interfaces';
import { NotificacaoDto } from '@usucampeao/lib-reurb-simplificado';
import { AUTH_QUERY_INJECT_KEY, IAuthQuery } from '../../../../shared';

@Component({
  selector: 'usucampeao-vencimento-amanha',
  templateUrl: './vencimento-amanha.component.html',
  styleUrls: ['./vencimento-amanha.component.scss'],
})
export class VencimentoAmanhaComponent {
  @Input() set notificacao(notificacao: NotificacaoDto) {
    this.notificacaoSelecionada = notificacao;
    this.endereco = this.notificacaoSelecionada?.payload?.endereco;
    this.formatarParcela();
  }

  public notificacaoSelecionada: NotificacaoDto;
  public endereco: Endereco;
  public parcelaFormatada: string;
  public nomeUsuario$ = this.authQuery.nome$;

  constructor(
    @Inject(AUTH_QUERY_INJECT_KEY)
    private readonly authQuery: IAuthQuery,
    private readonly router: Router,
  ) { }

  private formatarParcela(): void {
    const { parcela } = this.notificacaoSelecionada?.payload ?? {};

    this.parcelaFormatada = parcela === 0 ? 'de Entrada' : `número ${parcela}`;
  }

  public navegarParaPagamento(): void {
    this.router.navigate(['cadastros', this.notificacaoSelecionada?.cadastroId, 'pagamentos', this.notificacaoSelecionada?.payload?.pagamentoId]);
  }
}
