export interface VendedoresVendasDto {
  valor: number;
  quantidade: number;
  vendedor: string;
  vendedorId: string;
}

export class RelatorioVendasVendedoresDto {
  vendedoresVendas: VendedoresVendasDto[];

  constructor() {
    this.vendedoresVendas = [];
  }
}
