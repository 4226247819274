import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { FooterModule, HeaderModule, UsuarioAvatarModule } from '../../../components';
import { TextareaModule } from '../../../components/forms/textarea/textarea.module';
import { ComentariosComponent } from './comentarios.component';

@NgModule({
  declarations: [
    ComentariosComponent
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,
    ReactiveFormsModule,

    // components
    UsuarioAvatarModule,
    TextareaModule,

    // components
    HeaderModule,
    FooterModule,
  ],
  exports: [
    ComentariosComponent
  ]
})
export class ComentariosModule { }
