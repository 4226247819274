import { IsNotEmpty, IsNumber, IsOptional, IsString } from 'class-validator';

export class PayAccountReceivableDto {
  @IsString()
  @IsNotEmpty()
  //@ApiProperty()
  paymentId: string;

  @IsNumber()
  @IsNotEmpty()
  //@ApiProperty()
  bankAccountId: number;

  @IsNumber()
  @IsNotEmpty()
  //@ApiProperty()
  amount: number;

  @IsString()
  @IsNotEmpty()
  date: Date;

  @IsNumber()
  @IsOptional()
  multa?: number;
}
