<section class="projetos-d">
  <div class="content-vertical-space-between">
    <usucampeao-header backRoute="../../selecionar-bairro" title="Detalhes do bairro"> </usucampeao-header>

    <ion-text>
      <h1 class="title title--large ion-no-margin">{{ projeto?.nome }}</h1>
      <p class="description-text ion-no-margin" *ngIf="projeto?.localizacao">
        {{ projeto.localizacao.rua }}, {{ projeto.localizacao.numero }}, {{ projeto.localizacao.bairro }}
      </p>
      <p class="font-text font-text--medium">
        O bairro <b>{{ projeto?.nome }}</b> está participando do processo de regularização fundiária através da lei
        federal da REURB
        nº 13.465. Finalize o seu cadastro para receber o título dos seus imóveis.
      </p>
      <p class="font-text font-text--medium">
        Confira o calendário contendo as datas e locais relevantes ao projeto de regularização, como da <b>assembleia para
        tirar as dúvidas</b> e <b>ponto de atendimento com nossa equipe de apoio:</b>
      </p>
    </ion-text>

    <ion-button mode="md" fill="outline" (click)="abrirModalCalendario()">
      <ion-icon slot="start" name="calendar-outline"></ion-icon>
      Ver calendário
    </ion-button>

    <div class="projetos-d__card ion-margin-top">
      <ion-text class="ion-text-center">
        <ng-container *ngIf="!projeto?.semPagamento && !projeto?.reurbGratuito; else templateGratis">
          <p class="title ion-no-margin">A partir de</p>
          <p class="title title--large title--primary ion-no-margin">
            {{ projeto?.parcelamentoValorMinimo | currency: 'BRL'}}
            <span class="font-text font-text--medium font-text--medium-color">/ mês</span>
          </p>
          <p class="font-text font-text--medium-color ion-no-margin">
            Valor total a partir de {{ projeto?.valorTotalMinimo | currency: 'BRL' }}
          </p>
        </ng-container>

        <ng-template #templateGratis>
          <p class="title title--large title--primary ion-no-margin">
            Grátis
          </p>
        </ng-template>
      </ion-text>

      <div class="projetos-d__beneficios">
        <ion-text>
          <h3 class="font-text font-text--medium font-text-bold ion-margin-bottom">Benefícios</h3>
        </ion-text>

        <div class="projetos-d__beneficios__item" *ngIf="!projeto?.semPagamento">
          <ion-icon class="ion-margin-end" name="checkmark-circle" color="success"></ion-icon>
          <ion-text>
            Pague em até {{ projeto?.parcelamentoMaximoSemJuros }}x sem juros ou em até {{ projeto?.parcelamentoMaximo }}x
          </ion-text>
        </div>
        <div class="projetos-d__beneficios__item">
          <ion-icon class="ion-margin-end" name="checkmark-circle" color="success"></ion-icon>
          <ion-text>Segurança jurídica</ion-text>
        </div>
        <div class="projetos-d__beneficios__item">
          <ion-icon class="ion-margin-end" name="checkmark-circle" color="success"></ion-icon>
          <ion-text>Posse legítima do terreno</ion-text>
        </div>
        <div class="projetos-d__beneficios__item">
          <ion-icon class="ion-margin-end" name="checkmark-circle" color="success"></ion-icon>
          <ion-text>Facilidade jurídica para vender o terreno</ion-text>
        </div>
        <div class="projetos-d__beneficios__item">
          <ion-icon class="ion-margin-end" name="checkmark-circle" color="success"></ion-icon>
          <ion-text>Valorização do terreno</ion-text>
        </div>
        <div class="projetos-d__beneficios__item">
          <ion-icon class="ion-margin-end" name="checkmark-circle" color="success"></ion-icon>
          <ion-text>Terreno regularizado</ion-text>
        </div>
        <div class="projetos-d__beneficios__item">
          <ion-icon class="ion-margin-end" name="checkmark-circle" color="success"></ion-icon>
          <ion-text>Facilidade para transferência de posse como herança </ion-text>
        </div>
        <div class="projetos-d__beneficios__item">
          <ion-icon class="ion-margin-end" name="checkmark-circle" color="success"></ion-icon>
          <ion-text>Atendimento com especialista jurídico</ion-text>
        </div>
      </div>
    </div>

    <ion-button mode="md" (click)="irParaMapa()">
      Continuar
    </ion-button>
    <usucampeao-footer></usucampeao-footer>
  </div>
</section>
