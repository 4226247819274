import { Component, Input } from '@angular/core';
import {
  CadastroContratoDto,
  MaritalStatus,
  MeioPagamento,
  OwnerDto,
  PropertyDto,
  PropertyUsage,
  ReurbModalidade
} from '@usucampeao/lib-reurb-simplificado';
import { NumeroExtensoTipo } from '@usucampeao/utils';

@Component({
  selector: 'usucampeao-regularizacao-fundiaria-v2',
  templateUrl: './regularizacao-fundiaria-v2.component.html',
  styleUrls: ['./regularizacao-fundiaria-v2.component.scss']
})
export class RegularizacaoFundiariaV2Component {
  @Input() cadastro!: CadastroContratoDto;
  @Input() proprietario!: Partial<OwnerDto>;
  @Input() conjuge!: Partial<OwnerDto>;
  @Input() imovel!: Partial<PropertyDto>;
  @Input() isPrint: boolean;

  public get imovelTipo(): typeof PropertyUsage {
    return PropertyUsage;
  }

  public get meioPagamento(): typeof MeioPagamento {
    return MeioPagamento;
  }

  public get numeroExtensoTipo(): typeof NumeroExtensoTipo {
    return NumeroExtensoTipo;
  }

  public get reurbModalidade(): typeof ReurbModalidade {
    return ReurbModalidade;
  }

  public get estadoCivil(): typeof MaritalStatus {
    return MaritalStatus;
  }

  public get titulo(): string {
    if (this.cadastro.averbacao) {
      return 'CONTRATO DE ADESÃO PARA A PRESTAÇÃO DE SERVIÇOS DE REGULARIZAÇÃO FUNDIÁRIA E AVERBAÇÃO DE DECLARAÇÃO DE EXISTÊNCIA DE EDIFICAÇÃO';
    }

    return 'CONTRATO DE ADESÃO PARA A PRESTAÇÃO DE SERVIÇOS DE REGULARIZAÇÃO FUNDIÁRIA';
  }

  public get isPagamentoAVista(): boolean {
    return this.cadastro.pagamentos.length === 1;
  }
}
