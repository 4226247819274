import { Pipe, PipeTransform } from '@angular/core';
import { TabelaPrecoParcela } from '@usucampeao/lib-reurb-simplificado';

@Pipe({
  name: 'parcela',
})
export class ParcelasPipe implements PipeTransform {
  transform(value: TabelaPrecoParcela): string {
    switch (value) {
      case TabelaPrecoParcela.PARCELA_0:
        return 'À vista';

      case TabelaPrecoParcela.PARCELA_1:
        return '1 parcela';

      default:
        return value + ' parcelas';
    }
  }
}
