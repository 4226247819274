import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ImoveisDocumentosUploadPage } from './imoveis-documentos-upload.page';

const routes: Routes = [{ path: '', component: ImoveisDocumentosUploadPage }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ImoveisDocumentosUploadRoutingModule { }
