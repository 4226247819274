import { FormControl } from '@angular/forms';

export function OnlyNumbers(control: FormControl) {
  const value = control.value;
  if (!value) {
    return false;
  }

  const validNumber = isOnlyNumbers(value);
  if (!validNumber) {
    return { only_numbers: true };
  }

  return false;
}


export function isOnlyNumbers(value: string) {
  const regex = new RegExp(/^[0-9]\d*(\.\d+)?$/);
  return regex.test(value);
}
