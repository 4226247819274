import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { ContratoSelecionarFormaPagamentoModule } from '@usucampeao/ui-mobile';
import { ContratoSelecionarFormaPagamentoRoutingModule } from './contrato-selecionar-forma-pagamento-routing.module';
import { ContratoSelecionarFormaPagamentoPage } from './contrato-selecionar-forma-pagamento.page';

@NgModule({
  declarations: [
    ContratoSelecionarFormaPagamentoPage
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,

    // route
    ContratoSelecionarFormaPagamentoRoutingModule,

    // components
    ContratoSelecionarFormaPagamentoModule,
  ]
})
export class ContratoSelecionarFormaPagamentoPageModule { }
