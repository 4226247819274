<ion-content class="ion-padding">
  <section class="content-vertical-space-between">
    <ion-text>
      <h1 class="title">Quase Lá!</h1>
      <p class="ion-no-margin">
        Tire uma foto para ajudar nosso assistentes a te identificar
      </p>
    </ion-text><br>

    <usucampeao-upload-foto-documento [podeEnviarVariosArquivos]="false" [podeEnviarPDF]="false"
      (aoRealizarUpload)="selecionarImagem($event)"></usucampeao-upload-foto-documento>

    <ion-text class="ion-text-center" *ngIf="!foto">
      <p class="description-text description-text--large">Não é um bom momento? <a class="text-link text-link--large"
          (click)="irParaCadastroFinalizado()">Deixe para depois</a></p>
    </ion-text>


    <usucampeao-footer>
      <ion-button *ngIf="foto" mode="md" expand="block" (click)="cadastrarFotoDePerfil()">
        Continuar
      </ion-button>
    </usucampeao-footer>
  </section>
</ion-content>
