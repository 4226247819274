import { Nationality, UF } from '@usucampeao/interfaces';
import { Expose, Type, instanceToPlain, plainToInstance } from 'class-transformer';
import { Gender } from '../../enums';
import { AddressDto } from '../address/address.dto';
import { DOCUMENTOS_ESTADO_CIVIL, DOCUMENTOS_IDENTIDADE, DocumentFileDto, OwnerDocument } from '../document-file';
import { MaritalStatus, MatrimonialRegime, PhoneDto, RgDto } from '../owner';
import { ProjetoTipo } from '../projeto';
import { PropertyAcquisitionDocument, PropertyDto, PropertyOccupancy, PropertyOwnershipDocument, PropertySituation, PropertyUsage } from '../property';
import { ProprietariosCadastroDto } from '../proprietario-cadastro';
import { RegistrationDto } from '../registration';
import { AlteracaoDto } from './alteracao.dto';
import { RevisaoDto } from './revisao.dto';
import { StatusRevisao } from './status-revisao.enum';

export class RevisaoDetalhesDto {
  @Expose()
  id: string;

  @Expose()
  comentario: string;

  @Expose()
  morador: string;

  @Expose()
  usuarioResponsavelId: string;

  @Expose()
  usuarioResponsavelNome: string;

  @Expose()
  dataInicio: Date;

  @Expose()
  dataUltimaRevisao: Date;

  @Expose()
  dataCancelamento: Date;

  @Expose()
  @Type(() => AlteracaoDto)
  alteracoes: AlteracaoDto[];

  @Expose()
  status: StatusRevisao;

  @Expose()
  dataCriacao: Date;

  @Expose()
  dataFim: Date;

  @Expose()
  quantidadeRevisoesRealizadas: number;

  /**
   * Cria uma nova instância de RevisaoDetalhesDto a partir de dtos
   * @param props DTO de Revisão
   * @returns nova instância de RevisaoDetalhesDto
   */
  static from(props?: Partial<RevisaoDto>): RevisaoDetalhesDto {
    const revisaoDto = plainToInstance(RevisaoDetalhesDto, props, { exposeUnsetFields: false, excludeExtraneousValues: true });
    if (props?.alteracoes) {
      revisaoDto.alteracoes = props?.alteracoes;
    }

    return revisaoDto;
  }
}

export class RevisaoDadosImovelDto {
  @Expose()
  id: string;

  @Expose()
  blockId: number;

  @Expose()
  quadra: string;

  @Expose()
  lotId: number;

  @Expose()
  lote: string;

  @Type(() => AddressDto)
  @Expose()
  address: AddressDto;

  @Expose()
  usage: PropertyUsage;

  @Expose()
  occupation: PropertyOccupancy;

  @Expose()
  situation: PropertySituation;

  @Expose()
  loteArea: number;

  @Expose()
  possuiOutroImovel: boolean;

  @Expose()
  documentosAquisicao: DocumentFileDto[];

  @Expose()
  documentosPosse: DocumentFileDto[];

  @Expose()
  photoId: string;

  constructor(props?: Partial<RevisaoDadosImovelDto>) {
    if (props) {
      Object.keys(props).forEach(key => this[key] = props[key]);
    }
  }

  /**
   * Cria uma nova instância de RevisaoDadosImovelDto a partir de dtos
   * @param props DTO de dados do imóvel
   * @param documentos Lista de documentos do imóvel
   * @returns nova instância de RevisaoDadosImovelDto
   */
  static from(props: Partial<PropertyDto>, documentos: DocumentFileDto[] = []): RevisaoDadosImovelDto {
    const data = plainToInstance(RevisaoDadosImovelDto, props, { exposeUnsetFields: false, excludeExtraneousValues: true });

    if (documentos.length > 0) {
      data.documentosAquisicao = documentos
        .filter(doc => Object.values(PropertyAcquisitionDocument).includes(doc.type as PropertyAcquisitionDocument));
      data.documentosPosse = documentos
        .filter(doc => Object.values(PropertyOwnershipDocument).includes(doc.type as PropertyOwnershipDocument));
    }

    return data;
  }

  /**
   * Transforma uma instância de RevisaoDadosImovelDto em um Objeto js
   * @returns objeto RevisaoDadosImovelDto
   */
  public toPlain(): RevisaoDadosImovelDto {
    return instanceToPlain(this, { ignoreDecorators: true, exposeUnsetFields: false }) as RevisaoDadosImovelDto;
  }

  /**
   * Converte uma classe de RevisaoDadosImovelDto para um DTO
   * @returns DTO de PropertyDto
   */
  public toDto(): Partial<PropertyDto> {
    return instanceToPlain(this, { exposeUnsetFields: false, exposeDefaultValues: false, excludeExtraneousValues: true }) as PropertyDto;
  }
}

export class RevisaoImovelDadosAdicionaisDto {
  @Expose()
  id: string;

  @Expose()
  usage: PropertyUsage;

  @Expose()
  occupation: PropertyOccupancy;

  @Expose()
  situation: PropertySituation;

  @Expose()
  loteArea: number;

  @Expose()
  possuiOutroImovel: boolean;

  /**
   * Cria uma nova instância de RevisaoImovelDadosAdicionaisDto a partir de dtos
   * @param props DTO de dados do imóvel
   * @returns nova instância de RevisaoImovelDadosAdicionaisDto
   */
  static from(props: Partial<RevisaoDadosImovelDto>): RevisaoImovelDadosAdicionaisDto {
    return plainToInstance(RevisaoImovelDadosAdicionaisDto, props, { exposeUnsetFields: false, excludeExtraneousValues: true });
  }
}

export class RevisaoDadosProprietarioDto {
  @Expose()
  id: string;

  @Expose()
  name: string;

  @Expose()
  cpf: string;

  @Expose()
  rg: RgDto;

  @Expose()
  email: string;

  @Type(() => PhoneDto)
  @Expose()
  phones: PhoneDto;

  @Expose()
  contactName: string;

  @Expose()
  gender: Gender;

  @Expose()
  birthDate: Date;

  @Expose()
  nationality: Nationality;

  @Expose()
  state: UF;

  @Expose()
  naturalidade: string;

  @Expose()
  maritalStatus: MaritalStatus;

  @Expose()
  matrimonialRegime: MatrimonialRegime;

  @Expose()
  weddingDate: Date;

  @Expose()
  profession: string;

  @Expose()
  familyIncome: number;

  @Expose()
  documentosEstadoCivil: DocumentFileDto[];

  @Expose()
  documentosPessoais: DocumentFileDto[];

  @Expose()
  proprietarioId: string;

  @Expose()
  main: boolean;

  constructor(props?: Partial<RevisaoDadosProprietarioDto>) {
    if (props) {
      Object.keys(props).forEach(key => this[key] = props[key]);
    }
  }

  /**
   * Cria uma nova instância de RevisaoDadosProprivetarioDto a partir de dtos
   * @param props DTO de dados do proprietario
   * @param documentos Lista de documentos do proprietario
   * @returns nova instância de RevisaoDadosProprivetarioDto
   */
  static from(props: Partial<ProprietariosCadastroDto>, documentos: DocumentFileDto[] = []): RevisaoDadosProprietarioDto {
    const data = plainToInstance(RevisaoDadosProprietarioDto, props, { exposeUnsetFields: false, excludeExtraneousValues: true });
    data.documentosEstadoCivil = documentos.filter(doc => DOCUMENTOS_ESTADO_CIVIL.includes(doc.type as OwnerDocument));
    data.documentosPessoais = documentos.filter(doc => DOCUMENTOS_IDENTIDADE.includes(doc.type as OwnerDocument));

    return data;
  }

  /**
   * Transforma uma instância de RevisaoDadosProprietarioDto em um Objeto js
   * @returns objeto RevisaoDadosProprietarioDto
   */
  public toPlain(): RevisaoDadosProprietarioDto {
    return instanceToPlain(this, { ignoreDecorators: true, exposeUnsetFields: false }) as RevisaoDadosProprietarioDto;
  }

  /**
   * Converte uma classe de RevisaoDadosProprietarioDto para um DTO
   * @returns DTO de ProprietariosCadastroDto
   */
  public toDto(): Partial<ProprietariosCadastroDto> {
    return instanceToPlain(this, { exposeUnsetFields: false, exposeDefaultValues: false, excludeExtraneousValues: true }) as ProprietariosCadastroDto;
  }
}

export class RevisaoProprietarioDadosBasicosDto {
  @Expose()
  id: string;

  @Expose()
  name: string;

  @Expose()
  gender: Gender;

  @Expose()
  birthDate: Date;

  @Expose()
  profession: string;

  @Expose()
  familyIncome: number;

  @Expose()
  nationality: Nationality;

  @Expose()
  state: UF;

  @Expose()
  naturalidade: string;

  @Expose()
  maritalStatus: MaritalStatus;

  @Expose()
  matrimonialRegime: MatrimonialRegime;

  @Expose()
  weddingDate: Date;

  @Expose()
  main: boolean;

  @Expose()
  proprietarioId: string;

  /**
   * Cria uma nova instância de RevisaoProprietarioDadosBasicosDto a partir de dtos
   * @param props DTO de dados de revisão do proprietario
   * @returns nova instância de RevisaoProprietarioDadosBasicosDto
   */
  static from(props: Partial<RevisaoDadosProprietarioDto>): RevisaoProprietarioDadosBasicosDto {
    return plainToInstance(RevisaoProprietarioDadosBasicosDto, props, { exposeUnsetFields: false, excludeExtraneousValues: true });
  }
}

export class RevisaoProprietarioDadosContatosDto {
  @Expose()
  id: string;

  @Expose()
  email: string;

  @Type(() => PhoneDto)
  @Expose()
  phones: PhoneDto;

  @Expose()
  contactName: string;

  @Expose()
  proprietarioId: string;

  /**
   * Cria uma nova instância de RevisaoProprietarioDadosContatosDto a partir de dtos
   * @param props DTO de dados de revisão do proprietario
   * @returns nova instância de RevisaoProprietarioDadosContatosDto
   */
  static from(props: Partial<RevisaoDadosProprietarioDto>): RevisaoProprietarioDadosContatosDto {
    return plainToInstance(RevisaoProprietarioDadosContatosDto, props, { exposeUnsetFields: false, excludeExtraneousValues: true });
  }
}

export class RevisaoProprietarioDocumentosDto {
  @Expose()
  id: string;

  @Expose()
  cpf: string;

  @Type(() => RgDto)
  @Expose()
  rg: RgDto;

  @Expose()
  proprietarioId: string;

  /**
   * Cria uma nova instância de RevisaoProprietarioDocumentosDto a partir de dtos
   * @param props DTO de dados de revisão do proprietario
   * @returns nova instância de RevisaoProprietarioDocumentosDto
   */
  static from(props: Partial<RevisaoDadosProprietarioDto>): RevisaoProprietarioDocumentosDto {
    return plainToInstance(RevisaoProprietarioDocumentosDto, props, { exposeUnsetFields: false, excludeExtraneousValues: true });
  }
}

export class RevisaoDadosCadastroDto {
  @Expose()
  id: string;

  @Expose({ name: 'projetoFid' })
  projetoId: string;

  @Expose()
  projetoTipo: ProjetoTipo;

  @Expose()
  contratoDataAssinatura: Date;

  @Expose()
  averbacao: boolean;

  @Expose()
  imovel: RevisaoDadosImovelDto;

  @Expose({ name: 'ownerName' })
  nomeProprietarioPrincipal: string;

  @Expose()
  proprietario: RevisaoDadosProprietarioDto;

  @Expose()
  conjuge?: RevisaoDadosProprietarioDto;

  @Expose()
  documentos: DocumentFileDto[];

  @Expose()
  dataAprovacao?: Date;

  @Expose()
  observacao?: string;

  /**
  * Cria uma nova instância de RevisaoDadosCadastroDto a partir de dtos
  * @param props DTO de dados do cadastro
  * @param imovel Dados de revisão do imóivel
  * @param proprietario Dados de revisão do proprietário
  * @param conjuge Dados de revisão do conjuge
  * @returns nova instância de RevisaoDadosCadastroDto
  */
  static from(
    props: Partial<RegistrationDto>,
    imovel: RevisaoDadosImovelDto,
    proprietario: RevisaoDadosProprietarioDto,
    documentos: DocumentFileDto[],
    conjuge?: RevisaoDadosProprietarioDto,
  ): RevisaoDadosCadastroDto {
    const data = plainToInstance(RevisaoDadosCadastroDto, props, { exposeUnsetFields: false, excludeExtraneousValues: true });

    if (imovel) {
      data.imovel = imovel;
    }

    if (proprietario) {
      data.proprietario = proprietario;
    }

    if (documentos) {
      data.documentos = documentos;
    }

    if (conjuge) {
      data.conjuge = conjuge;
    }

    return data;
  }
}


export interface RevisaoCadastroDetalhesDto {
  revisao: RevisaoDetalhesDto;
  cadastro: RevisaoDadosCadastroDto;
}
