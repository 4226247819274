import { CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaskDirective } from './mask.directive';

@NgModule({
  declarations: [MaskDirective],
  exports: [MaskDirective],
  providers: [CurrencyPipe]
})
export class MaskDirectiveModule { }
