<div class="textarea">
  <ion-item mode="md" fill="solid">
    <ion-label mode="md" position="floating" [class.textarea__label--readonly]="readonly">
      <ion-text class="input-text">
        {{ label }}
      </ion-text>
    </ion-label>
    <ion-textarea mode="md" fill="solid" [placeholder]="placeholder" [formControlName]="controlName"
      [readonly]="readonly" [class.input--readonly]="readonly"></ion-textarea>

    <div class="textarea__suffix-icon" slot="end">
      <ng-content select="[usu-suffix]"></ng-content>
    </div>
  </ion-item>

  <div class="textarea__error">
    <ng-content select="[usu-error]"></ng-content>
  </div>
</div>
