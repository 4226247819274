import { PropertyDto } from '@usucampeao/interfaces';
import { Expose, plainToInstance, Type } from 'class-transformer';
import { IsBoolean, IsEnum, IsNotEmpty } from 'class-validator';

import { AddressDto } from '../address/address.dto';
import { GetUpdatePropertyAdditionalInformationsDto } from './get-update-property-additional-informations.dto';
import { PropertySituation } from './property-situation.enum';

export class UpdatePropertyAddressAdditionalInformationsDto extends GetUpdatePropertyAdditionalInformationsDto {
  @Expose()
  @Type(() => AddressDto)
  @IsNotEmpty()
  address: AddressDto;

  /**
   * Cria uma nova instância de GetUpdatePropertyLocalization a partir de dtos
   * @param props DTO de Owner
   * @returns nova instância de GetUpdatePropertyLocalization
   */
  static from(props?: PropertyDto | Partial<PropertyDto>): UpdatePropertyAddressAdditionalInformationsDto {
    return plainToInstance(UpdatePropertyAddressAdditionalInformationsDto, props, { exposeUnsetFields: false, excludeExtraneousValues: true });
  }
}

export class ImovelEnderecoInformacoesAdicionaisDto {
  @Expose()
  @Type(() => AddressDto)
  @IsNotEmpty()
  address: AddressDto;

  @Expose()
  @IsBoolean()
  @IsNotEmpty()
  possuiOutroImovel: boolean;

  @Expose()
  @IsEnum(PropertySituation)
  @IsNotEmpty()
  situation: PropertySituation;

  /**
   * Cria uma nova instância de ImovelEnderecoInformacoesAdicionaisDto a partir de dtos
   * @param props DTO de Imóvel
   * @returns nova instância de ImovelEnderecoInformacoesAdicionaisDto
   */
  static from(props?: PropertyDto | Partial<PropertyDto>): ImovelEnderecoInformacoesAdicionaisDto {
    return plainToInstance(ImovelEnderecoInformacoesAdicionaisDto, props, { exposeUnsetFields: false, excludeExtraneousValues: true });
  }
}
