import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { AddressDto, DocumentFileDto, FileDto, GetUpdatePropertyAdditionalInformationsDto } from '@usucampeao/lib-reurb-simplificado';
import { ImovelFichaDto } from '@usucampeao/ui-mobile';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ImoveisStore, ImovelState } from './imoveis.store';

@Injectable({ providedIn: 'root' })
export class ImoveisQuery extends QueryEntity<ImovelState> {

  constructor(protected store: ImoveisStore) {
    super(store);
  }

  public buscarImovelFicha(id: string): Observable<ImovelFichaDto> {
    return this.selectEntity(id)
      .pipe(
        filter(imovel => !!imovel),
        map(imovel => ImovelFichaDto.from(imovel))
      );
  }

  public buscarLoteId(id: string): Observable<number> {
    return this.selectEntity(id)
      .pipe(
        map(imovel => imovel.lotId)
      );
  }

  public buscarEndereco(id: string): Observable<AddressDto> {
    return this.selectEntity(id)
      .pipe(
        filter(imovel => !!imovel),
        map(imovel => imovel.address),
      );
  }

  public buscarInformacoesAdicionais(id: string): Observable<GetUpdatePropertyAdditionalInformationsDto> {
    return this.selectEntity(id)
      .pipe(
        filter(imovel => !!imovel),
        map(imovel => ({
          usage: imovel.usage,
          situation: imovel.situation,
          loteArea: imovel.loteArea,
          occupation: imovel.occupation,
          possuiOutroImovel: imovel.possuiOutroImovel,
        }) as GetUpdatePropertyAdditionalInformationsDto),
      );
  }

  /**
   * Busca documentos do imóvel
   * @param id ID do imóvel
   * @returns Documentos do imóvel
   */
  public buscarDocumentos(id: string): Observable<DocumentFileDto[]> {
    return this.selectEntity(id)
      .pipe(
        filter(imovel => !!imovel),
        map(imovel => imovel.documentos)
      );
  }

  /**
   * Busca arquivos de fotos da fachada do imóvel
   * @param id ID do imóvel
   * @returns arquivos de foto da fachada do imóvel
   */
  public buscarArquivosFotoFachada(id: string): Observable<FileDto[]> {
    return this.selectEntity(id)
      .pipe(
        filter(imovel => !!imovel),
        map(imovel => imovel.files)
      );
  }
}
