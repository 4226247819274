import { isBefore } from 'date-fns';
import { CadastroContratoAssinaturaTipoEnvioLink, CadastroContratoTipoAssinatura } from './cadastro-contrato-tipo-assinatura';

export enum ContratoStatus {
  PENDENTE = 'PENDENTE',
  EXPIRADO = 'EXPIRADO',
  ASSINADO = 'ASSINADO',
}

export type CadastroAssinaturaContratoPendenteListarDto = {
  id: string;
  cliente: string;
  dataContrato: Date;
  contratoTipoAssinatura: CadastroContratoTipoAssinatura;
  cidade: string;
  projeto: string;
  projetoId: string;
  vendedor: string;
  contratoStatus: ContratoStatus;
  contratoDataExpiracao: Date;
  contratoLinkFalhaEnvio: boolean;
  contratoLinkTipoEnvio: CadastroContratoAssinaturaTipoEnvioLink;
}

/**
 * Calcula se o contrato esta expirado.
 * Se a flag possuiDataAssinatura for true retorna 'ASSINADO'.
 * @param contratoDataExpiracao - Data de expiração do contrato
 * @param possuiDataAssinatura flag para indicar se o cadastro poussi data de assinatura
 * @returns PENDENTE se a data de contrato está dentro do prazo ou EXPIRADO caso não esteja.
 */
export function calcularStatusContrato(contratoDataExpiracao: Date, possuiDataAssinatura = false): ContratoStatus {
  if (possuiDataAssinatura) {
    return ContratoStatus.ASSINADO;
  }

  const contratoEstaExpirado = isBefore(new Date(contratoDataExpiracao), new Date());

  if (contratoDataExpiracao && contratoEstaExpirado) {
    return ContratoStatus.EXPIRADO;
  }

  return ContratoStatus.PENDENTE;
}
