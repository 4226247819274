<form [formGroup]="form">
  <usucampeao-select controlName="usage" label="Uso do domicílio" [disabled]="readonly">
    <ion-select-option *ngFor="let uso of imovelUsos" [value]="uso">{{ uso | imovelUso }}</ion-select-option>

    <span usu-error>{{ gerarMensagemErro('usage') }}</span>
  </usucampeao-select>

  <usucampeao-select controlName="occupation" label="Caracterizacao da ocupação" [disabled]="readonly">
    <ion-select-option *ngFor="let ocupacao of imovelOcupacoes" [value]="ocupacao">{{ ocupacao | imovelOcupacao }}</ion-select-option>

    <span usu-error>{{ gerarMensagemErro('occupation') }}</span>
  </usucampeao-select>

  <usucampeao-select controlName="situation" label="Situação" [disabled]="readonly">
    <ion-select-option *ngFor="let situacao of imovelSituacoes" [value]="situacao">{{ situacao | imovelSituacao }}</ion-select-option>

    <span usu-error>{{ gerarMensagemErro('situation') }}</span>
  </usucampeao-select>

  <usucampeao-input controlName="loteArea" type="tel" label="Área do terreno ou lote" mask="000000000" [readonly]="readonly">
    <span usu-suffix>m²</span>

    <span usu-error>{{ gerarMensagemErro('loteArea') }}</span>
  </usucampeao-input>

  <div class="radio">
    <ion-label class="description-text description-text--medium">Possui outro imóvel?</ion-label>
    <ion-item mode="md" lines="none">
      <ion-radio-group formControlName="possuiOutroImovel">
        <ion-item class="ion-no-padding" lines="none">
          <ion-radio color="secondary" [value]="false" class="ion-margin-horizontal" [disabled]="readonly"></ion-radio>
          <ion-label>Não</ion-label>
        </ion-item>
        <ion-item class="ion-no-padding" lines="none">
          <ion-radio color="secondary" [value]="true" class="ion-margin-horizontal" [disabled]="readonly"></ion-radio>
          <ion-label>Sim</ion-label>
        </ion-item>
      </ion-radio-group>
    </ion-item>
  </div>
</form>
