import { Component, Input } from '@angular/core';
import {
  CupomDescontoTipoDesconto,
  TabelaPrecoDetalhesDto,
  TabelaPrecoParcela,
  TabelaPrecoParcelaListaDto
} from '@usucampeao/lib-reurb-simplificado';
import { CadastroStateDto } from '../../domain';

@Component({
  selector: 'usucampeao-cadastro-resumo-pagamento',
  templateUrl: './cadastro-resumo-pagamento.component.html',
  styleUrls: ['./cadastro-resumo-pagamento.component.scss'],
})
export class CadastroResumoPagamentoComponent {
  @Input() cadastro: CadastroStateDto;
  @Input() totalParcelas?: TabelaPrecoParcela;
  @Input() tabelaPreco: Partial<TabelaPrecoDetalhesDto>[];
  @Input() semPadding = false;

  public get descontoNaEntrada(): boolean {
    return this.cadastro?.cupomDescontoTipo === CupomDescontoTipoDesconto.ENTRADA;
  }

  public get descontoNoServico(): boolean {
    return this.cadastro?.cupomDescontoTipo === CupomDescontoTipoDesconto.SERVICO;
  }

  public get valorTotal(): number {
    return this.pagamentoSelecionado?.valorTotal;
  }

  public get valorDesconto(): number {
    return this.pagamentoSelecionado?.valorDesconto;
  }

  public get pagamentoAVista(): boolean {
    return this.pagamentoSelecionado?.parcela === TabelaPrecoParcela.PARCELA_0;
  }

  public get valorEntrada(): number {
    return this.pagamentoSelecionado?.valorAto;
  }

  public get opcoesParcelamento(): TabelaPrecoParcelaListaDto[] {
    return this.tabelaPrecoSelecionada?.parcelas || [];
  }

  public get pagamentoSelecionado(): TabelaPrecoParcelaListaDto {
    const parcelaSelecionada = +this.totalParcelas || +this.cadastro?.totalParcelasSelecionada || +this.cadastro?.totalParcelas;
    return this.tabelaPrecoSelecionada?.parcelas?.find(parcela => +parcela.parcela === parcelaSelecionada);
  }

  public get tabelaPrecoSelecionada(): Partial<TabelaPrecoDetalhesDto> {
    if (!this.tabelaPreco) {
      return null;
    }

    const possuiAverbacao = this.cadastro?.averbacaoSelecionada ?? this.cadastro?.averbacao;
    const tabelaPrecoSelecionada = this.tabelaPreco
      .find((item) => item.modalidade === this.cadastro.reurbModalidade && possuiAverbacao ? item.averbacao : !item.averbacao);
    return tabelaPrecoSelecionada ? tabelaPrecoSelecionada : this.tabelaPreco[0];
  }
}
