import { Observable } from 'rxjs';

export interface IAuthQuery {
  telefone$: Observable<string>;
  nome$: Observable<string>;
  foto$: Observable<string>;
}

export interface AuthState {
  connected?: boolean;
  token?: string;
  nome?: string;
  birthDate?: any;
  gender?: string;
  telefone?: string;
  email?: string;
  foto?: string;
  recaptcha?: any;
  code?: string;
}


export const AUTH_QUERY_INJECT_KEY = 'AuthQuery';
