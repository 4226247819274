import { HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { AuthQuery } from '../state/auth.query';
import { AuthService } from '../state/auth.service';
import { AuthGuard } from './auth.guard';

@Injectable({
  providedIn: 'root'
})
export class PossuiUsuarioGuard implements CanActivate {
  constructor(
    private router: Router,
    private authGuard: AuthGuard,
    private authQuery: AuthQuery,
    private authService: AuthService,
  ) { }

  async canActivate(): Promise<boolean> {
    const possuiToken = await this.authGuard.canActivate().toPromise();
    if (!possuiToken) {
      return false;
    }

    let possuiUsuario: boolean;
    try {
      possuiUsuario = await this.authQuery.possuiUsuario$
        .pipe(
          take(1),
          switchMap(possuiUsuario => {
            if (possuiUsuario) {
              return of(possuiUsuario);
            }
            // TODO: remover busca de usuário de guard
            return this.authService.buscarUsuario().pipe(
              map(usuario => !!usuario)
            );
          }),
        )
        .toPromise();
    } catch (error) {
      if (error?.status !== HttpStatusCode.Unauthorized) {
        throw error;
      }
    }

    if (!possuiUsuario) {
      this.router.navigateByUrl('criar-conta');
    }

    return possuiUsuario;
  }

}
