import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AddressDto,
  AlteracaoDto,
  DocumentFileDto,
  DocumentFileType,
  GetUpdatePropertyAdditionalInformationsDto,
  PropertyAcquisitionDocument,
  PropertyFillInStatusDto,
  PropertyOwnershipDocument,
  RegistrationStatus,
  STATUS_DOCUMENTOS_ENVIADOS,
  StatusPreenchimento
} from '@usucampeao/lib-reurb-simplificado';
import { Arquivo } from '../../../../components/upload-foto/upload-foto.component';
import { ImovelStateDto } from '../../../../domain';
import { DocumentoService, NavigationService } from '../../../../services';

@Component({
  selector: 'usucampeao-imoveis-editar',
  templateUrl: './imoveis-editar.component.html',
  styleUrls: ['./imoveis-editar.component.scss']
})
export class ImoveisEditarComponent {
  @Input() set imovel(imovel: ImovelStateDto) {
    this.endereco = imovel?.address;
    this.informacoesAdicionais = GetUpdatePropertyAdditionalInformationsDto.from(imovel);
    this.imovelStatus = imovel?.fillInStatus;
  }
  @Input() set documentos(documentos: DocumentFileDto[]) {
    this.documentoPosse = documentos?.find(documento => Object.values(PropertyOwnershipDocument).includes(documento.type as PropertyOwnershipDocument));
    this.documentoAquisicao = documentos?.find(documento => Object.values(PropertyAcquisitionDocument).includes(documento.type as PropertyAcquisitionDocument));
    this.configurarStatusDocumentos();
    this.configurarAccordionAberto();
  }
  @Input() alteracoes: AlteracaoDto[];
  @Input() formDesabilitado = false;

  @Output() aoAlterarEndereco = new EventEmitter<AddressDto>();
  @Output() aoAlterarInformacoesAdicionais = new EventEmitter<GetUpdatePropertyAdditionalInformationsDto>();

  public readonly rotaAnterior = '../';
  public readonly documentosAquisicaoLista = Object.values(PropertyAcquisitionDocument);
  public readonly documentosPosseLista = Object.values(PropertyOwnershipDocument);

  public readonly cadastroAlteracoesNecessarias = RegistrationStatus.CHANGES_REQUESTED;
  public imovelStatus: PropertyFillInStatusDto;
  public endereco: AddressDto;
  public accordionEnderecoEstaAberto = false;

  public informacoesAdicionais: GetUpdatePropertyAdditionalInformationsDto;
  public accordionInformacoesAdicionaisEstaAberto = false;

  public documentoAquisicao: DocumentFileDto;
  public documentoAquisicaoStatus: StatusPreenchimento;
  public documentoAquisicaoSelecionado: PropertyAcquisitionDocument;
  public accordionDocumentoAquisicaoEstaAberto = false;

  public documentoPosse: DocumentFileDto;
  public documentoPosseStatus: StatusPreenchimento;
  public documentoPosseSelecionado: PropertyOwnershipDocument;
  public accordionDocumentoPosseEstaAberto = false;

  constructor(
    private readonly documentoService: DocumentoService,
    private readonly navigationService: NavigationService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
  ) { }

  private configurarStatusDocumentos(): void {
    if (this.imovelStatus?.documents === StatusPreenchimento.ENVIADO) {
      this.documentoPosseStatus = StatusPreenchimento.ENVIADO;
      this.documentoAquisicaoStatus = StatusPreenchimento.ENVIADO;
    } else {
      const documentoPosseEnviado = this.documentoPosse && STATUS_DOCUMENTOS_ENVIADOS.includes(this.documentoPosse.status);
      this.documentoPosseStatus = documentoPosseEnviado ? StatusPreenchimento.ENVIADO : StatusPreenchimento.PENDENTE;

      const documentoAquisicaoEnviado = this.documentoAquisicao && STATUS_DOCUMENTOS_ENVIADOS.includes(this.documentoAquisicao.status);
      this.documentoAquisicaoStatus = documentoAquisicaoEnviado ? StatusPreenchimento.ENVIADO : StatusPreenchimento.PENDENTE;
    }
  }

  private configurarAccordionAberto(): void {
    if (this.imovelStatus?.addressData === StatusPreenchimento.PENDENTE) {
      this.accordionEnderecoEstaAberto = true;
      this.accordionInformacoesAdicionaisEstaAberto = false;
      this.accordionDocumentoAquisicaoEstaAberto = false;
      this.accordionDocumentoPosseEstaAberto = false;
    } else if (this.imovelStatus?.additionalInformationsData === StatusPreenchimento.PENDENTE) {
      this.accordionInformacoesAdicionaisEstaAberto = true;
      this.accordionEnderecoEstaAberto = false;
      this.accordionDocumentoAquisicaoEstaAberto = false;
      this.accordionDocumentoPosseEstaAberto = false;
    } else if (this.documentoPosseStatus === StatusPreenchimento.PENDENTE) {
      this.accordionDocumentoPosseEstaAberto = true;
      this.accordionEnderecoEstaAberto = false;
      this.accordionInformacoesAdicionaisEstaAberto = false;
      this.accordionDocumentoAquisicaoEstaAberto = false;
    } else if (this.documentoAquisicaoStatus === StatusPreenchimento.PENDENTE) {
      this.accordionDocumentoAquisicaoEstaAberto = true;
      this.accordionEnderecoEstaAberto = false;
      this.accordionInformacoesAdicionaisEstaAberto = false;
      this.accordionDocumentoPosseEstaAberto = false;
    } else {
      this.accordionEnderecoEstaAberto = false;
      this.accordionInformacoesAdicionaisEstaAberto = false;
      this.accordionDocumentoAquisicaoEstaAberto = false;
      this.accordionDocumentoPosseEstaAberto = false;
    }
  }


  public alterarEndereco(endereco: AddressDto): void {
    this.aoAlterarEndereco.emit(endereco);
    this.accordionInformacoesAdicionaisEstaAberto = true;
  }

  public alterarInformacoesAdicionais(informacoesAdicionais: GetUpdatePropertyAdditionalInformationsDto): void {
    this.aoAlterarInformacoesAdicionais.emit(informacoesAdicionais);
    this.accordionDocumentoPosseEstaAberto = true;
  }

  public selecionarDocumentoPosse(documentoSelecionado: DocumentFileType): void {
    this.documentoPosseSelecionado = documentoSelecionado as PropertyOwnershipDocument;
  }

  public selecionarDocumentoAquisicao(documentoSelecionado: DocumentFileType): void {
    this.documentoAquisicaoSelecionado = documentoSelecionado as PropertyAcquisitionDocument;
  }

  public irParaUploadDeDocumento(arquivos: Arquivo[], tipoDocumento: PropertyOwnershipDocument | PropertyAcquisitionDocument): void {
    const arquivo = arquivos[0];
    if (!arquivo) {
      return;
    }

    this.documentoService.salvarDocumentoStorage(arquivo);
    this.router.navigate(
      ['../envio-documentos/upload'],
      {
        queryParams: {
          tipo: tipoDocumento,
        },
        relativeTo: this.route,
      }
    );
  }

  public voltar(): void {
    this.navigationService.voltar(this.rotaAnterior);
  }
}
