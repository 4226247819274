import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlteracaoDto, GetUpdateOwnerContactDto, TipoAlteracao } from '@usucampeao/lib-reurb-simplificado';
import { BaseComponent, LoadingService, NavigationService, ToastService } from '@usucampeao/ui-mobile';
import { Observable, iif, of } from 'rxjs';
import { catchError, finalize, switchMap, takeUntil, tap } from 'rxjs/operators';
import { CadastrosQuery } from '../../state/cadastros.query';
import { CadastroService } from '../../state/cadastros.service';
import { ProprietariosQuery } from '../state/proprietarios.query';
import { ProprietariosService } from '../state/proprietarios.service';

@Component({
  templateUrl: './proprietarios-dados-contato.page.html',
  styleUrls: ['./proprietarios-dados-contato.page.scss']
})
export class ProprietariosDadosContatoPage extends BaseComponent implements OnInit {
  private proprietarioId: string;
  private cadastroId: string;
  public alteracoes$: Observable<AlteracaoDto[]>;
  public dadosContato$: Observable<GetUpdateOwnerContactDto>;
  public formDesabilitado$: Observable<boolean>;

  constructor(
    private cadastrosQuery: CadastrosQuery,
    private cadastroService: CadastroService,
    private proprietariosQuery: ProprietariosQuery,
    private proprietariosService: ProprietariosService,
    private loadingService: LoadingService,
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    private toastService: ToastService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.route.params
      .pipe(
        tap(params => this.cadastroId = params.cadastroId),
        tap(params => this.proprietarioId = params.proprietarioId),
        tap(() => this.carregarDadosContato()),
        tap(() => this.dadosContato$ = this.proprietariosQuery.buscarDadosContato(this.proprietarioId)),
        tap(() => this.formDesabilitado$ = this.cadastrosQuery.cadastroEstaDesabilitadoParaEdicao(this.cadastroId)),
        tap(() => this.alteracoes$ = this.cadastrosQuery.buscarAlteracoes$(this.cadastroId, [TipoAlteracao.PROPRIETARIO_DADOS_CONTATO], this.proprietarioId)),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe();
  }

  public async carregarDadosContato(event?: any): Promise<void> {
    if (!event) {
      await this.loadingService.createLoader();
    }

    this.proprietariosService.buscarDadosContato(this.cadastroId, this.proprietarioId)
      .pipe(
        switchMap(() => iif(() =>
          this.cadastrosQuery.cadastroEstaEmAlteracoesNecessarias(this.cadastroId),
          this.cadastroService.buscarUltimaRevisao(this.cadastroId, true),
          of()
        )),
        catchError(() => this.toastService.error('Erro ao buscar dados de contato do proprietário. Por favor tente novamente.')),
        finalize(async () => event ? event.target.complete() : await this.loadingService.dismiss()),
      )
      .subscribe();
  }

  public async alterarDadosContato(dadosContato: GetUpdateOwnerContactDto): Promise<void> {
    await this.loadingService.createLoader();
    this.proprietariosService.atualizarDadosContato(this.cadastroId, this.proprietarioId, dadosContato)
      .pipe(
        tap(() => this.navigationService.voltar()),
        catchError(() => this.toastService.error('Erro ao atualizar dados de contato do proprietário. Por favor tente novamente.')),
        finalize(async () => await this.loadingService.dismiss()),
      )
      .subscribe();
  }
}
