import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { ProprietariosFichaV2Module } from '@usucampeao/ui-mobile';
import { ProprietariosRoutingModule } from './proprietarios-routing.module';
import { ProprietariosPage } from './proprietarios.page';


@NgModule({
  declarations: [
    ProprietariosPage
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,

    // route
    ProprietariosRoutingModule,

    // components
    ProprietariosFichaV2Module,
  ]
})
export class ProprietariosPageModule { }
