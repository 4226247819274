import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AddressDto, AlteracaoDto } from '@usucampeao/lib-reurb-simplificado';
import { CadastroCardDto } from '../../domain';

@Component({
  selector: 'usucampeao-atendimento-card',
  templateUrl: 'atendimento-card.component.html',
  styleUrls: ['atendimento-card.component.scss'],
})
export class AtendimentoCardComponent {
  @Input() cadastro: CadastroCardDto
  @Input() statusPadrao = false;
  @Input() showImage = true;
  @Input() showOwnerProps = false;

  public loadingImage = 'assets/images/defaultImage.svg';
  public defaultImage = 'assets/images/placeholder.png';
  constructor(
    public router: Router,
  ) { }

  /**
  * Foto da fachada do imóvel
  */
  public get fotoFachadaImovel(): string {
    if (!this.cadastro?.files) {
      return '';
    }

    const [fotoFachada] = this.cadastro.files;
    if (fotoFachada && this.cadastro.propertyId) {
      return `/properties/${this.cadastro.propertyId}/photo/${fotoFachada.id}`;
    }
  }

  public get alteracoes(): AlteracaoDto[] {
    return this.cadastro?.revisao?.alteracoes || [];
  }

  /**
   * Formata texto para bairro, cidade e estado, incluindo dados default, caso ausentes.
   * @param address dados de endereço
   * @returns Bairro, cidade, estado, formatados
   */
  public formatLocation(address?: AddressDto): string {
    return [
      address?.neighborhood || '',
      address?.city || 'São Paulo',
      address?.state || 'SP'
    ].filter(v => !!v).join(', ');
  }

  /**
   * Navega para detalhes do cadastro
   */
  public navigateToInformationForm(): void {
    this.router.navigate([`cadastros`, this.cadastro.id]);
  }

}
