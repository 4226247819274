<ion-content class="ion-no-padding ion-no-border login" scrollY="false">
  <div class="login__content" *ngIf="!showCodePage">
    <ion-slides class="login__slides" [options]="slideOpts">
      <ion-slide class="login__carrossel-imagem" [style.background-image]="'url(' + imagem + ')'"
        *ngFor="let imagem of imagensCarrossel">
      </ion-slide>
    </ion-slides>

    <div class="login__card">
      <div class="login-card ion-padding">
        <div class="login-card__container">
          <span class="login-card__divider"></span>

          <form mode="md" [formGroup]="form" (ngSubmit)="submit()">
            <ion-text>
              <h1 class="title title-large">Bem Vindo! </h1>
              <p class="font-text">
                <ng-content></ng-content>
              </p>
            </ion-text>
            <ion-text>
              <p class="font-text ion-no-margin">
                {{ description }}
              </p>
            </ion-text>
            <usucampeao-input controlName="phone" type="tel" label="Telefone celular" mask="(00)00000-0000">
              <span usu-error>{{ messageError }}</span>
            </usucampeao-input>
            <ion-button expand="block " mode="md" type="submit">Entrar</ion-button>
          </form>

          <usucampeao-footer></usucampeao-footer>
        </div>
      </div>
    </div>
  </div>
  <div id="recaptcha-container"></div>

  <usucampeao-autenticar-numero *ngIf="showCodePage" [fone]="telefone" (onSetCode)="setCode($event)" (onResendCode)="resendCode()" (onBack)="backLoginPage()"></usucampeao-autenticar-numero>
</ion-content>
