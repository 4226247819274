import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';

import { ProjetosListarProximosPageModule } from '@usucampeao/ui-mobile';
import { ProjetosPageRoutingModule } from './projetos-routing.page.module';
import { ProjetosPage } from './projetos.page';

@NgModule({
  declarations: [
    ProjetosPage,
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,

    // route
    ProjetosPageRoutingModule,

    //others
    ProjetosListarProximosPageModule,
  ]
})
export class ProjetosPageModule { }
