import { UsuarioBackofficeStatus } from './usuario-backoffice-status.enum';
import { UsuarioBackofficeTipo } from './usuario-backoffice-tipo.enum';

export const ID_USUARIO_SISTEMA = '_SISTEMA';

export class UsuarioBackofficeDto {
  id: string;
  nome: string;
  telefone: string;
  email: string;
  tipo: UsuarioBackofficeTipo;
  foto: string;
  status: UsuarioBackofficeStatus;
  empresa: string;
  dataCriacao: Date;
  dataAtualizacao: Date;
}
