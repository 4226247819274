import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ProjetosListarComDistancia } from '@usucampeao/lib-reurb-simplificado';

@Component({
  selector: 'usucampeao-projeto-listagem-item',
  templateUrl: './projeto-listagem-item.component.html',
  styleUrls: ['./projeto-listagem-item.component.scss']
})
export class ProjetoListagemItemComponent {
  @Input() projeto: ProjetosListarComDistancia;
  @Input() mostrarLabelCompativel = false;
  @Output() aoSelecionarProjeto = new EventEmitter<string>();

  public selecionarProjeto(): void {
    this.aoSelecionarProjeto.emit(this.projeto.projeto_id);
  }
}
