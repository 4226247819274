import { Component, Input } from '@angular/core';
import { CadastroContratoDto, MaritalStatus, MeioPagamento, OwnerDto, PropertyDto, PropertyUsage } from '@usucampeao/lib-reurb-simplificado';

@Component({
  selector: 'usu-procuracao-averbacao',
  templateUrl: './procuracao-averbacao.html',
  styleUrls: ['./procuracao-averbacao.scss'],
})
export class ProcuracaoAverbacaoComponent {
  @Input() isPrint = false;
  @Input() cadastro!: CadastroContratoDto;
  @Input() proprietario!: Partial<OwnerDto>;
  @Input() conjuge!: Partial<OwnerDto>;
  @Input() imovel!: Partial<PropertyDto>;


  public get meioPagamento(): typeof MeioPagamento {
    return MeioPagamento
  }

  public get imovelTipo(): typeof PropertyUsage {
    return PropertyUsage
  }

  public get maritalStatus(): typeof MaritalStatus {
    return MaritalStatus
  }

  public get mostrarDadosConjuge(): boolean {
    return !!this.conjuge && this.proprietario?.maritalStatus === MaritalStatus.MARRIED;
  }
}
