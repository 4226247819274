import { UF } from '@usucampeao/interfaces';
import { IsEnum, IsIn, IsNotEmpty, IsOptional, IsString, ValidateIf } from 'class-validator';
import { GatewayPagamento } from '../pagamento';
import { ProjetoEditarFormDataConstructorProps, ProjetoEditarFormDataDto } from './projeto-editar-form-data.dto';
import { ProjetoTipo } from './projeto-tipo.enum';

type ProjetoCriarFormDataConstructorProps = ProjetoEditarFormDataConstructorProps & {
  tipo: ProjetoTipo
  nucleoId: string;
  semPagamento: boolean;
  reurbGratuita: boolean;
  cidade: string;
  estado: UF;
  importarQuadras?: boolean;
}

export class ProjetoCriarFormDataDto extends ProjetoEditarFormDataDto {
  @IsString()
  @IsNotEmpty()
  tipo: ProjetoTipo

  @IsString()
  @IsNotEmpty()
  nucleoId: string;

  @IsIn(['true', 'false'])
  @IsString()
  @IsOptional()
  semPagamento: string;

  @IsIn(['true', 'false'])
  @IsString()
  @IsOptional()
  reurbGratuita: string;

  @IsString()
  @IsNotEmpty()
  cidade: string;

  @IsString()
  @IsNotEmpty()
  estado: UF;

  @IsIn(['true', 'false'])
  @IsString()
  @IsOptional()
  importarQuadras: string;

  @IsEnum(GatewayPagamento)
  @IsNotEmpty()
  @ValidateIf((object) => object.semPagamento === 'false')
  gatewayPagamento: GatewayPagamento;

  static create(props: ProjetoCriarFormDataConstructorProps): ProjetoCriarFormDataDto {
    const projetoCriarFormDataDto: ProjetoCriarFormDataDto = ProjetoEditarFormDataDto.create(props) as ProjetoCriarFormDataDto;
    projetoCriarFormDataDto.tipo = props.tipo;
    projetoCriarFormDataDto.nucleoId = props.nucleoId;
    projetoCriarFormDataDto.semPagamento = props.semPagamento.toString();
    projetoCriarFormDataDto.reurbGratuita = props.reurbGratuita.toString();
    projetoCriarFormDataDto.cidade = props.cidade;
    projetoCriarFormDataDto.estado = props.estado;
    projetoCriarFormDataDto.importarQuadras = props.importarQuadras ? 'true' : 'false';
    projetoCriarFormDataDto.gatewayPagamento = props.gatewayPagamento;
    return projetoCriarFormDataDto;
  }
}
