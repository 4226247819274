import { CadastroContratoAssinaturaTipoEnvioLink, CadastroContratoTipoAssinatura } from '@usucampeao/interfaces';
import { ReurbModalidade } from '../../enums';
import { AddressDto } from '../address/address.dto';
import { CupomDescontoTipoDesconto } from '../cupom-desconto';
import { MeioPagamento } from '../pagamento';
import { DiaPagamento } from '../pagamento/dia-pagamento.enum';
import { CadastroCancelamentoMotivo } from './cadastro-cancelar.dto';
import { CadastroStatusVendaType } from './cadastro-status-venda.enum';
import { ContratoVersao } from './contrato-versao.enum';
import { FamilyDto } from './family.dto';
import { RegistrationStatus } from './registration-status.enum';

export const STATUS_CADASTROS_CANCELADOS = [
  RegistrationStatus.CANCELED,
  RegistrationStatus.DELETED,
  RegistrationStatus.INELIGIBLE,
  RegistrationStatus.WAIVERED,
  RegistrationStatus.DISTRATO,
];

export class RegistrationDto {
  id: string;
  projetoId: number;
  projetoFid: string;
  propertyId: string;
  reurbModalidade: ReurbModalidade;
  ownerId: string;
  proprietarioCadastroId: string;
  ownerName: string;
  owners: FamilyDto[];
  usersIds: string[];
  status: RegistrationStatus;
  statusVenda: CadastroStatusVendaType;
  observacao: string;
  schedulingDate: Date;
  deliveryDate: Date;
  createUser: string;
  createdAt: Date;
  updatedAt: Date;
  updateUser: string;
  diaVencimento: DiaPagamento;
  diaEntrada: DiaPagamento;
  valorEntrada: number;
  entradaVencimento: Date;
  valorContrato: number;
  valorServico: number;
  valorReurb: number;
  valorDesconto: number;
  descontoPorcentagem: number;
  totalParcelas: number;
  valorParcela: number;
  averbacao: boolean;
  valorAverbacao: number;
  jurosMes: number;
  multa: number;
  juros: number;
  meioPagamento: MeioPagamento;
  contractDate: Date;
  lotId: number;
  lote: string;
  blockId: number;
  quadra: string;
  usuarioIdResponsavel: number;
  usuarioIdInicioContrato: number;
  usuarioIdColetaDados: number
  usuarioIdAnalise: number
  usuarioFidResponsavel: string;
  usuarioResponsavelNome: string;
  usuarioFidInicioContrato: string;
  usuarioFidColetaDados: string;
  usuarioFidAnalise: string;
  usuarioIdFechamentoContrato: number;
  usuarioFidFechamentoContrato: string;
  usuarioIdGerarContrato: number;
  usuarioFidGerarContrato: string;
  diarioOficialLink?: string;
  prefeituraProtocolo?: string;
  cartorioProtocolo?: string;
  entregaDataPrevista?: Date;
  entregaLocal?: string;
  entregaData?: Date;
  contratoVersao: ContratoVersao;
  contratoDataAssinatura: Date;
  contratoDataExpiracao: Date;
  contratoExpirado: boolean;
  contratoTipoAssinatura: CadastroContratoTipoAssinatura;
  contratoTipoAssinaturaData: Date;
  contratoTipoAssinaturaUsuarioId: number;
  contratoTipoAssinaturaUsuarioFid: string;
  cancelamentoData?: Date;
  cancelamentoMotivo?: CadastroCancelamentoMotivo;
  cancelamentoObservacoes?: string;
  cancelamentoMotivoOutro?: string;
  cancelamentoUsuarioId?: number;
  cancelamentoUsuarioFid?: string;
  canceladoPeloCliente: boolean;
  distratoData?: Date;
  distratoMotivo?: CadastroCancelamentoMotivo;
  distratoObservacoes?: string;
  distratoMotivoOutro?: string;
  distratoUsuarioId?: number;
  distratoUsuarioFid?: string;
  distratoMulta: number;
  distratoReembolso: number;
  usuarioCriacaoId?: string;
  usuarioCriacaoNome?: string;
  address: AddressDto;
  dataAprovacao: Date;
  aprovado?: boolean;
  dataCriacao: Date;
  dataAtualizacao: Date;
  cupomDescontoId: number;
  cupomDescontoCodigo: string;
  cupomDescontoTipo: CupomDescontoTipoDesconto;
  cupomDescontoLimite: number;
  contratoLinkTipoEnvio?: CadastroContratoAssinaturaTipoEnvioLink;
  contratoLinkDocumentoId?: string;
  contratoLinkQuantidadeEnvios?: number;
  contratoLinkDataUltimoEnvio?: Date;
  contratoLinkContatoEnviado?: string;
  contratoLinkFalhaEnvio?: boolean;
  contratoLinkAssinadoPeloProprietario?: boolean;
  contratoLinkAssinadoPelaUsucampeao?: boolean;

  constructor(registration?: Partial<RegistrationDto>) {
    if (registration) {
      Object.keys(registration).forEach(key => this[key] = registration[key]);
    }
  }
}
