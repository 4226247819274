import { PagamentoDto, PagamentoStatus } from '@usucampeao/lib-reurb-simplificado';
import { Expose, plainToInstance } from 'class-transformer';
import { IsDateString, IsEnum, IsNotEmpty, IsNumber, IsOptional, IsString } from 'class-validator';
import { IntegrationOmieStatus } from './integration-omie-status.enum';

export class IntegrationOmieAccountReceivableDto {
  @Expose()
  @IsString()
  @IsNotEmpty()
  id: string;

  @Expose()
  @IsNumber()
  @IsOptional()
  omieId?: number;

  @Expose()
  @IsString()
  @IsOptional()
  omieClienteId?: number;

  @Expose()
  @IsString()
  @IsNotEmpty()
  clienteId: string;

  @Expose({ name: 'erpProjetoId' })
  @IsNumber()
  @IsNotEmpty()
  omieProjetoId: number;

  @Expose()
  @IsNumber()
  @IsNotEmpty()
  amount: number;

  @Expose()
  valorDevido: number;

  @Expose()
  valorPago?: number;

  // @IsEnum(PagamentoStatus)
  @IsNotEmpty()
  paymentStatus: PagamentoStatus;

  @Expose()
  @IsEnum(IntegrationOmieStatus)
  @IsNotEmpty()
  status: IntegrationOmieStatus;

  @Expose({ name: 'dataPagamento' })
  @IsDateString()
  @IsOptional()
  payDay?: Date;

  @Expose({ name: 'dataVencimento' })
  @IsDateString()
  @IsOptional()
  dueDate?: Date;

  @Expose({ name: 'parcela' })
  @IsNumber()
  @IsNotEmpty()
  installment: number;

  @Expose({ name: 'totalParcelas' })
  @IsNumber()
  @IsNotEmpty()
  totalInstallment: number;

  @Expose({ name: 'cadastroId' })
  @IsString()
  @IsNotEmpty()
  attendanceId: string;

  @Expose()
  dadoLegado: boolean;

  @Expose()
  @IsDateString()
  @IsOptional()
  createdAt?: Date;

  @Expose()
  @IsDateString()
  @IsOptional()
  updatedAt?: Date;

  error?: any;

  /**
   * Cria uma nova instância de IntegrationOmieAccountReceivableDto
   * @param props DTO de pagamento
   * @returns nova instância de IntegrationOmieAccountReceivableDto
   */
  static from(props: Partial<PagamentoDto>, omieClienteId?: number): IntegrationOmieAccountReceivableDto {
    const dadosIntegracao = plainToInstance(IntegrationOmieAccountReceivableDto, props, { exposeUnsetFields: false, excludeExtraneousValues: true });
    dadosIntegracao.paymentStatus = props.status;
    dadosIntegracao.clienteId = props.proprietarioId;

    return {
      ...dadosIntegracao,
      ...omieClienteId ? { omieClienteId } : {},
    };
  }
}
