import { Component } from '@angular/core';
import { LoadingService, NavigationService, ToastService } from '@usucampeao/ui-mobile';
import { catchError, finalize, switchMap, take, tap } from 'rxjs/operators';
import { AuthService } from '../../auth/state/auth.service';

@Component({
  selector: 'usucampeao-trocar-foto',
  templateUrl: './trocar-foto.component.html',
  styleUrls: ['./trocar-foto.component.scss']
})
export class TrocarFotoComponent {
  public fotoSelecionada: File;

  constructor(
    private authService: AuthService,
    private loadingService: LoadingService,
    private navigationService: NavigationService,
    private toastService: ToastService,
  ) { }

  /**
   * Seta a foto no component
   * @param photo foto selecionada
   */
  public selecionarImagem(photo: File[]): void {
    this.fotoSelecionada = photo[0];
  }

  /**
   * Atualiza a foto da fachada do imóvel
   */
  public async atualizarFotoPerfil(): Promise<void> {
    await this.loadingService.createLoader();
    this.authService
      .atualizarFotoDePerfil(this.fotoSelecionada)
      .pipe(
        switchMap(() => this.toastService.success('Imagem de perfil atualizada com sucesso.')),
        tap(() => this.navigationService.voltar()),
        catchError(error => {
          console.error(error)
          return this.toastService.error('Erro ao atualizar imagem de perfil. Por favor tente novamente.');
        }),
        finalize(async () => await this.loadingService.dismiss()),
        take(1)
      )
      .subscribe();
  }

  /**
   * Navega para a página anterior
   */
  public voltar(): void {
    this.navigationService.voltar();
  }
}
