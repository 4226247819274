import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { ContratoConfirmarInformacoesModule } from '@usucampeao/ui-mobile';
import { ContratoConfirmarInformacoesRoutingModule } from './contrato-confirmar-informacoes-routing.module';
import { ContratoConfirmarInformacoesPage } from './contrato-confirmar-informacoes.page';

@NgModule({
  declarations: [
    ContratoConfirmarInformacoesPage
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,

    // route
    ContratoConfirmarInformacoesRoutingModule,

    // components
    ContratoConfirmarInformacoesModule,
  ]
})
export class ContratoConfirmarInformacoesPageModule { }
