export class LoteListarDto {
  id: number;
  label: string;
  cadastrosQuantidade: number;
  coords: number[][];

  constructor(
    id: number,
    label: string,
    cadastrosQuantidade: number,
    coords: number[][]
  ) {
    this.id = id;
    this.label = label;
    this.cadastrosQuantidade = cadastrosQuantidade;
    this.coords = coords;
  }
}
