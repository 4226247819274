import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProprietarioDadosBasicosDto } from '@usucampeao/lib-reurb-simplificado';
import { BaseComponent, LoadingService, ToastService } from '@usucampeao/ui-mobile';
import { Observable } from 'rxjs';
import { catchError, finalize, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ProjetoService } from '../../../core/states/projeto/projeto.service';
import { ProprietariosQuery } from '../../cadastros/proprietarios/state/proprietarios.query';
import { ProprietariosService } from '../../cadastros/proprietarios/state/proprietarios.service';
import { CadastrosQuery } from '../../cadastros/state/cadastros.query';

@Component({
  templateUrl: './proprietarios-dados-basicos.page.html',
  styleUrls: ['./proprietarios-dados-basicos.page.scss']
})
export class ProprietariosDadosBasicosPage extends BaseComponent implements OnInit {
  private proprietarioId: string;
  private cadastroId: string;
  private projetoId: string;
  public dadosBasicos$: Observable<ProprietarioDadosBasicosDto>;
  public formDesabilitado$: Observable<boolean>;
  public possuiPagamento: boolean;

  constructor(
    private cadastrosQuery: CadastrosQuery,
    private proprietariosQuery: ProprietariosQuery,
    private proprietariosService: ProprietariosService,
    private projetoService: ProjetoService,
    private loadingService: LoadingService,
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.route.params
      .pipe(
        tap(params => {
          this.cadastroId = params.cadastroId;
          this.projetoId = params.id;
        }),
        tap(() => {
          const { cadastroEmRascunho } = this.cadastrosQuery.getValue();
          this.proprietarioId = cadastroEmRascunho.proprietarioId;
        }),
        tap(() => this.carregarDadosBasicos()),
        tap(() => this.dadosBasicos$ = this.proprietariosQuery.buscarDadosBasicos(this.proprietarioId)),
        tap(() => this.formDesabilitado$ = this.cadastrosQuery.cadastroEstaDesabilitadoParaEdicao(this.cadastroId)),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe();
  }

  public async carregarDadosBasicos(event?: any): Promise<void> {
    if (!event) {
      await this.loadingService.createLoader();
    }

    this.proprietariosService.buscarDadosBasicos(this.cadastroId, this.proprietarioId)
      .pipe(
        switchMap(() => this.projetoService.buscarDetalheProjeto(this.projetoId)),
        tap(projeto => this.possuiPagamento = !projeto.semPagamento),
        catchError(error => {
          this.toastService.error('Erro ao buscar dados básicos do proprietário. Por favor tente novamente mais tarde.');
          throw error;
        }),
        finalize(async () => event ? event.target.complete() : await this.loadingService.dismiss()),
      )
      .subscribe();
  }

  public async alterarDadosBasicos(dadosBasicos: ProprietarioDadosBasicosDto): Promise<void> {
    await this.loadingService.createLoader();
    this.proprietariosService.atualizarDadosBasicos(this.cadastroId, this.proprietarioId, dadosBasicos)
      .pipe(
        tap(() => this.router.navigate([this.possuiPagamento ? '../contrato' : '../contrato/selecionar-forma-pagamento'], { relativeTo: this.route })),
        catchError(error => {
          console.error(error);
          return this.toastService.error('Erro ao atualizar dados básicos do proprietário. Por favor tente novamente.')
        }),
        finalize(async () => await this.loadingService.dismiss()),
      )
      .subscribe();
  }

}
