import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AlteracaoDto, RegistrationStatus } from '@usucampeao/lib-reurb-simplificado';
import { NavigationService } from '../../services';
import { ModalDocumentosInstrucoesUploadComponent } from './modal-documentos-instrucoes-upload/modal-documentos-instrucoes-upload.component';

@Component({
  selector: 'usucampeao-documentos-lista',
  templateUrl: './documentos-lista.component.html',
  styleUrls: ['./documentos-lista.component.scss']
})
export class DocumentosListaComponent {
  @Input() title: string;
  @Input() alteracoes: AlteracaoDto[] = [];
  @Output() aoAdicionarDocumento = new EventEmitter<void>();
  @Output() aoContinuar = new EventEmitter<void>();

  constructor(
    private modalCtrl: ModalController,
    private navigationService: NavigationService,
  ) { }

  public get cadastroStatus(): RegistrationStatus {
    return RegistrationStatus.CHANGES_REQUESTED;
  }

  public get mostrarAlteracoes(): boolean {
    return this.alteracoes && this.alteracoes.length > 0;
  }

  public async abrirModalInstrucoes(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalDocumentosInstrucoesUploadComponent,
      cssClass: 'documentos-instrucoes-upload',
    });
    return await modal.present();
  }

  public adicionarDocumento(): void {
    this.aoAdicionarDocumento.emit();
  }

  public voltar(): void {
    this.navigationService.voltar();
  }
}
