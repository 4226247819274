
import { Expose, plainToInstance } from 'class-transformer';
import { IsBoolean, IsNotEmpty, IsNumber, IsString, ValidateIf } from 'class-validator';
import { DiaPagamento, MeioPagamento } from '../pagamento';
import { RegistrationDto } from './registration.dto';

export type CadastroSelecionarPagamentoProps = {
  totalParcelas: number;
  meioPagamento: MeioPagamento;
  diaVencimento: DiaPagamento;
  diaEntrada: DiaPagamento;
  averbacao?: boolean;
}

/**
 * Dados para selecionar os pagamentos do cadastro.
 * Essa classe possui as validações para projetos que possuem pagamento.
 */
export class CadastroSelecionarPagamentosDto {
  @IsNumber()
  @IsNotEmpty()
  @Expose()
  totalParcelas: number | null;

  @IsString()
  @IsNotEmpty()
  @Expose()
  meioPagamento: MeioPagamento | null;

  @IsString()
  @IsNotEmpty()
  @ValidateIf(dadosPagamento => dadosPagamento.totalParcelas > 0)
  @Expose()
  diaVencimento: DiaPagamento | null;

  @IsString()
  @IsNotEmpty()
  @ValidateIf(dadosPagamento => dadosPagamento.totalParcelas > 0)
  @Expose()
  diaEntrada: DiaPagamento | null;

  @IsBoolean()
  @IsNotEmpty()
  @Expose()
  averbacao: boolean;

  constructor(props?: CadastroSelecionarPagamentoProps) {
    this.totalParcelas = props?.totalParcelas ?? null;
    this.meioPagamento = props?.meioPagamento ?? null;
    this.diaVencimento = props?.diaVencimento ?? null;
    this.diaEntrada = props?.diaEntrada ?? null;
    this.averbacao = props?.averbacao ?? false;
  }


  static fromCadastro(props: Partial<RegistrationDto>): CadastroSelecionarPagamentosDto {
    return plainToInstance(CadastroSelecionarPagamentosDto, props, { excludeExtraneousValues: true, exposeUnsetFields: false });
  }
}

export type CadastroConstruirPagamentosDto = {
  valorContrato: number;
  saldoDevedorInicial: number;
  valorEntrada: number;
  valorParcela: number;
  totalParcelas: number;
  meioPagamento: MeioPagamento;
  diaVencimento: DiaPagamento;
  diaEntrada: DiaPagamento;
  jurosMes: number;
  valorDesconto?: number;
}
