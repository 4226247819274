<section class="i-documento-upload content-vertical-space-between">
  <ion-text>
    <h1 class="title title--large">
      Envio de {{ tipo | documentoTipo }}
    </h1>

    <p class="font-text">
      Tire foto do documento, de forma que ele fique totalmente legível. Adicione fotos de mais
      páginas, conforme necessário.
    </p>

    <p class="text-link" (click)="abrirModalProcuracao()" *ngIf="isProcuracao">
      Visualizar ou imprimir procuração
    </p>
  </ion-text>

  <usucampeao-upload-foto-documento [imagensBase64]="arquivosDocumento"
    (aoRealizarUpload)="adicionarArquivo($event)"
    (aoRemoverArquivo)="removerArquivo($event)"></usucampeao-upload-foto-documento>

  <usucampeao-footer>
    <ion-button class="ion-margin-bottom" mode="md" [disabled]="botaoSalvarDesativado" (click)="realizarUpload()">
      {{ textoBotaoSalvar }}
    </ion-button>
    <ion-button mode="md" fill="outline" (click)="voltar()">Cancelar</ion-button>
  </usucampeao-footer>
</section>
