export enum PagamentoStatus {
  CANCELADO = 'CANCELADO',
  CRIADO = 'CRIADO',
  EM_ANALISE = 'EM_ANALISE',
  ERRO = 'ERRO',
  MANUAL = 'MANUAL',
  NAO_PAGO = 'NAO_PAGO',
  SOLICITADO_EMISSAO_PAGAMENTO = 'SOLICITADO_EMISSAO_PAGAMENTO',
  PAGO = 'PAGO',
  PAGO_CANCELADO = 'PAGO_CANCELADO',
  PROGRAMADO = 'PROGRAMADO'
}

export const PAGAMENTOS_STATUS_PENDENTES = [
  PagamentoStatus.CRIADO,
  PagamentoStatus.ERRO,
  PagamentoStatus.SOLICITADO_EMISSAO_PAGAMENTO,
  PagamentoStatus.PROGRAMADO
];
export const PAGAMENTOS_EM_ABERTO = [
  ...PAGAMENTOS_STATUS_PENDENTES,
  PagamentoStatus.NAO_PAGO,
  PagamentoStatus.EM_ANALISE
];
export const PAGAMENTOS_STATUS_PAGOS = [
  PagamentoStatus.MANUAL,
  PagamentoStatus.PAGO,
  PagamentoStatus.PAGO_CANCELADO
];

export const PAGAMENTOS_STATUS_CANCELADOS = [PagamentoStatus.CANCELADO, PagamentoStatus.PAGO_CANCELADO];
