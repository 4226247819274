import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FileDto, PropertyAcquisitionDocument, PropertyOwnershipDocument } from '@usucampeao/lib-reurb-simplificado';
import { ArquivosEnviadosRemovidos, BaseComponent, LoadingService, NavigationService, ToastService } from '@usucampeao/ui-mobile';
import { catchError, filter, finalize, switchMap, takeUntil, tap } from 'rxjs/operators';
import { CadastroService } from '../../state/cadastros.service';
import { ImoveisService } from '../state/imoveis.service';

@Component({
  templateUrl: './imoveis-documentos-upload.page.html',
  styleUrls: ['./imoveis-documentos-upload.page.scss']
})
export class ImoveisDocumentosUploadPage extends BaseComponent implements OnInit {

  private cadastroId: string;
  private imovelId: string;
  private documentoNome: string;

  public arquivos: FileDto[];
  public documentoId: string;
  public tipo: PropertyAcquisitionDocument | PropertyOwnershipDocument;

  constructor(
    private cadastroService: CadastroService,
    private imoveisService: ImoveisService,
    private loadingService: LoadingService,
    private navigationService: NavigationService,
    private route: ActivatedRoute,
    private toastService: ToastService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.route.queryParams
      .pipe(
        tap(params => this.tipo = params.tipo),
        tap(params => this.documentoId = params.documentoId),
        tap(params => this.documentoNome = params.documentoNome),
        tap(() => {
          if (!this.documentoId && !this.tipo) {
            this.navigationService.voltar();
          }
        }),
        filter(() => !!this.documentoId || !!this.tipo),
        switchMap(() => this.route.params),
        tap(params => this.cadastroId = params.cadastroId),
        tap(params => this.imovelId = params.imovelId),
        tap(() => this.carregarDocumento()),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe();
  }

  private async carregarDocumento(): Promise<void> {
    if (!this.documentoId) {
      return;
    }

    await this.loadingService.createLoader();
    this.cadastroService.buscarArquivosDocumento(this.documentoId)
      .pipe(
        tap(arquivos => this.arquivos = arquivos),
        catchError(() => this.toastService.error('Erro ao carregar documento. Por favor tente novamente')),
        finalize(async () => await this.loadingService.dismiss()),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe();
  }

  public adicionarOuAtualizarDocumento(arquivos: ArquivosEnviadosRemovidos): void {
    if (this.documentoId) {
      this.atualizarDocumento(arquivos);
    } else {
      this.adicionarDocumento(arquivos.arquivosEnviados);
    }
  }

  private async adicionarDocumento(arquivos: File[]): Promise<void> {
    await this.loadingService.createLoader();

    this.imoveisService.adicionarDocumento(this.cadastroId, this.imovelId, arquivos, this.tipo, this.documentoNome)
      .pipe(
        switchMap(() => this.toastService.success('Documento adicionado com sucesso')),
        tap(() => this.navigationService.voltar()),
        catchError(() => this.toastService.error('Erro ao salvar documento. Por favor tente novamente')),
        finalize(async () => this.loadingService.dismiss()),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe();
  }

  private async atualizarDocumento(arquivos: ArquivosEnviadosRemovidos): Promise<void> {
    await this.loadingService.createLoader();

    this.imoveisService.atualizarDocumento(this.cadastroId, this.imovelId, this.documentoId, arquivos.arquivosEnviados, arquivos.idsArquivosRemovidos)
      .pipe(
        switchMap(() => this.toastService.success('Documento adicionado com sucesso')),
        tap(() => this.navigationService.voltar()),
        catchError(() => this.toastService.error('Erro ao salvar documento. Por favor tente novamente')),
        finalize(async () => this.loadingService.dismiss()),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe();
  }
}
