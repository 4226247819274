import { Injectable } from '@angular/core';
import { DBSchema, IDBPDatabase, openDB } from 'idb';

interface AuthDB extends DBSchema {
  auth: { key: string, value: string };
}

const INDEXED_DB_NAME = 'pode-regularizar';
const INDEXED_DB_VERSION = 1;
const INDEXED_DB_STORE_NAME = 'auth';
const INDEXED_DB_USER_ID_KEY = 'uuid';

/**
 * Serviço para manipulação do IndexedDB para autenticação.
 *
 * @remarks
 * Este serviço utiliza a biblioteca `idb` para interagir com o IndexedDB.
 *
 * @example
 * ```typescript
 * constructor(private indexedDBService: IndexedDBService) {}
 *
 * async salvarId() {
 *   await this.indexedDBService.salvarUsuarId('12345');
 * }
 *
 * async limparId() {
 *   await this.indexedDBService.limparUsuarioId();
 * }
 * ```
 *
 * @public
 */
@Injectable({
  providedIn: 'root'
})
export class IndexedDBService {
  private db: IDBPDatabase<AuthDB>;

  /**
   * Salva o ID do usuário no IndexedDB.
   *
   * @param usuarioId - O ID do usuário a ser salvo.
   * @returns Uma Promise que resolve quando o ID do usuário for salvo.
   */
  public async salvarUsuarId(usuarioId: string): Promise<void> {
    const indexedDB = await this.getDB();
    await indexedDB.put(INDEXED_DB_STORE_NAME, usuarioId, INDEXED_DB_USER_ID_KEY);
  }

  /**
   * Remove o ID do usuário do IndexedDB.
   *
   * @returns Uma Promise que resolve quando o identificador do usuário é removido.
   */
  public async limparUsuarioId(): Promise<void> {
    const indexedDB = await this.getDB();
    await indexedDB.delete(INDEXED_DB_STORE_NAME, INDEXED_DB_USER_ID_KEY);
  }

  /**
   * Obtém uma instância do banco de dados IndexedDB.
   * Se o banco de dados ainda não estiver aberto, ele será aberto e inicializado.
   *
   * @returns Uma Promise que resolve com a instância do banco de dados inicializada.
   */
  private async getDB(): Promise<IDBPDatabase<AuthDB>> {
    if (!this.db) {
      this.db = await openDB(INDEXED_DB_NAME, INDEXED_DB_VERSION, {
        upgrade(db) {
          db.createObjectStore(INDEXED_DB_STORE_NAME);
        }
      });
    }

    return this.db;
  }
}
