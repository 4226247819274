import { ReurbModalidade } from '../../enums';
import { RegistrationStatus } from './registration-status.enum';

export class ListaCadastroDto {
  id: string;
  ownerName: string;
  lote: string;
  quadra: string;
  status: RegistrationStatus;
  updatedAt: Date;
  reurbModalidade: ReurbModalidade | null;
  averbacao: boolean;

  constructor(
    id: string,
    ownerName: string,
    lote: string,
    quadra: string,
    status: RegistrationStatus,
    updatedAt: Date,
    reurbModalidade?: ReurbModalidade,
    averbacao?: boolean
  ) {
    this.id = id;
    this.ownerName = ownerName;
    this.lote = lote;
    this.quadra = quadra;
    this.status = status;
    this.updatedAt = updatedAt;
    this.reurbModalidade = reurbModalidade ?? null;
    this.averbacao = averbacao ?? false;
  }
}
