import { Expose, plainToInstance } from 'class-transformer';
import { ReurbModalidade } from '../../enums/reurb-modalidade.enum';
import { TabelaPrecoParcelaListaDto } from './tabela-preco-parcela-lista.dto';
import { TabelaPrecoDto } from './tabela-preco.dto';

export class TabelaPrecoDetalhesDto {
  parcelas: TabelaPrecoParcelaListaDto[];

  @Expose()
  multa: number;

  @Expose()
  desconto: number;

  @Expose()
  jurosMes: number;

  @Expose()
  parcelasSemJuros: number;

  @Expose()
  juros: number;

  @Expose()
  valorBase: number;

  @Expose()
  valorEfetivo: number;

  @Expose()
  valorAto: number;

  @Expose()
  valorAverbacao?: number;

  @Expose()
  modalidade?: ReurbModalidade;

  @Expose()
  averbacao?: boolean;

  @Expose()
  valorDesconto?: number;

  /**
   * Cria uma nova instância de TabelaPrecoDetalhesDto a partir de dtos
   * @param props DTO de TabelaPreco
   * @returns nova instância de TabelaPrecoDetalhesDto
   */
  static from(parcelas: TabelaPrecoParcelaListaDto[], props?: TabelaPrecoDto | Partial<TabelaPrecoDto>): TabelaPrecoDetalhesDto {
    const tabelaPrecoLista = plainToInstance(
      TabelaPrecoDetalhesDto,
      props,
      { exposeUnsetFields: false, excludeExtraneousValues: true }
    );
    tabelaPrecoLista.parcelas = parcelas;
    return tabelaPrecoLista;
  }
}
