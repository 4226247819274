import { IsOptional, IsString } from 'class-validator';
import { CadastroSelecionarPagamentoProps, CadastroSelecionarPagamentosDto } from './cadastro-construir-pagamento.dto';


export type CadastroSolicitarAssinaturaContratoProps = CadastroSelecionarPagamentoProps & {
  observacoes?: string;
}

export class CadastroSolicitarAssinaturaContratoDto extends CadastroSelecionarPagamentosDto {
  @IsString()
  @IsOptional()
  observacoes?: string;

  constructor(props?: CadastroSolicitarAssinaturaContratoProps) {
    super(props);
    this.observacoes = props?.observacoes;
  }
}
