<ion-text>
  <p class="font-text">
    O cartório terminou de avaliar o projeto de regularização e estamos trabalhando nos ajustes e padronizações
    solicitados.
  </p>
  <p class="font-text">
    Não se preocupe, esta é uma etapa normal no processo de regularização, em que atendemos às individualidades de cada
    prefeitura com que trabalhamos.
  </p>
</ion-text>
