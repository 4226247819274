import { Component, OnInit } from '@angular/core';
import { CadastroCardDto, LoadingService, ToastService } from '@usucampeao/ui-mobile';
import { catchError, finalize, map } from 'rxjs/operators';
import { CadastrosQuery } from '../cadastros/state/cadastros.query';
import { CadastroService } from '../cadastros/state/cadastros.service';

@Component({
  templateUrl: 'meus-imoveis.page.html',
  styleUrls: ['meus-imoveis.page.scss'],
})
export class MeusImoveisPage implements OnInit {
  public cadastros$ = this.cadastrosQuery.selectAll()
    .pipe(
      map(cadastros => cadastros.map(cadastro => CadastroCardDto.from(cadastro))),
    );

  constructor(
    private cadastrosQuery: CadastrosQuery,
    private cadastrosService: CadastroService,
    private loadingService: LoadingService,
    private toastService: ToastService,
  ) { }

  ngOnInit(): void {
    this.carregarCadastros();
  }

  public async carregarCadastros(event?: any): Promise<void> {
    if (!event) {
      await this.loadingService.createLoader();
    }

    this.cadastrosService.buscarCadastrosDoUsuario()
      .pipe(
        catchError(() => this.toastService.error('Erro ao carregar cadastros. Tente novamente mais tarde.')),
        finalize(() => event ? event.target.complete() : this.loadingService.dismiss()),
      )
      .subscribe();
  }
}
