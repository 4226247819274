<ion-content class="ion-padding">
  <ion-refresher slot="fixed" (ionRefresh)="carregarCadastros($event)">
    <ion-refresher-content>
    </ion-refresher-content>
  </ion-refresher>

  <section class="meus-imoveis">
    <usucampeao-header title="Meus imóveis"></usucampeao-header>

    <div class="meus-imoveis__content">
      <usucampeao-atendimento-card *ngFor="let cadastro of cadastros$  | async"
        [cadastro]="cadastro"></usucampeao-atendimento-card>
    </div>
  </section>
</ion-content>
