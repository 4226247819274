import { Component, Input } from '@angular/core';
import { RegistrationStatus } from '@usucampeao/lib-reurb-simplificado';
import { cadastroIconeCor, cadastroIconeNome } from '../../../shared';


@Component({
  selector: 'usucampeao-atendimento-status-badge',
  templateUrl: 'atendimento-status-badge.component.html',
  styleUrls: ['atendimento-status-badge.component.scss'],
})
export class AtendimentoStatusBadgeComponent {
  @Input() status: RegistrationStatus;

  public get cadastroStatus(): typeof RegistrationStatus {
    return RegistrationStatus;
  }

  public get icon(): string {
    return cadastroIconeNome(this.status);
  }

  public get iconColor(): string {
    return cadastroIconeCor(this.status);
  }

  public get textColor(): string {
    switch (this.status) {
      case RegistrationStatus.INCOMPLETE:
      case RegistrationStatus.READY_TO_SUBMIT:
      case RegistrationStatus.PRONTO_PARA_FECHAR_CONTRATO:
      case RegistrationStatus.AVAILABLE_SCHEDULE:
      case RegistrationStatus.AGUARDANDO_ASSINATURA_CONTRATO:
      case RegistrationStatus.CHANGES_REQUESTED:
      case RegistrationStatus.MISSED_SCHEDULE:
      case RegistrationStatus.VALIDATED:
      case RegistrationStatus.DELIVERY_SCHEDULED:
      case RegistrationStatus.DELIVERED_TITLE:
        return 'medium';

      default:
        return 'light';
    }
  }
}
