import { Component, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AlertController, ModalController } from '@ionic/angular';
import { ErrorCode } from '@usucampeao/lib-reurb-simplificado';
import { AbstractForm } from '@usucampeao/utils-frontend';
import { of } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { CupomDescontoService } from '../../../../services/cupom-desconto.service';
import { LoadingService } from '../../../../shared';

@Component({
  selector: 'usucampeao-modal-adicionar-cupom-desconto',
  templateUrl: './modal-adicionar-cupom-desconto.component.html',
  styleUrls: ['./modal-adicionar-cupom-desconto.component.scss']
})
export class ModalAdicionarCupomDescontoComponent extends AbstractForm {
  @Input() cadastroId: string;
  @Input() quantidadeParcelas: number;

  public mensagemErro: string;
  public resetarParcelas = false;

  constructor(
    private alertCtrl: AlertController,
    private cupomService: CupomDescontoService,
    private fb: FormBuilder,
    private loadingService: LoadingService,
    private modalCtrl: ModalController,
  ) {
    super();
    this.form = this.fb.group({
      codigo: [null, Validators.required]
    });
  }

  public async validarCupom(): Promise<void> {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }

    await this.loadingService.createLoader();

    const { codigo } = this.form.value;
    const codigoCupomDesconto = codigo.trim();
    this.cupomService.aplicarCupom({
      cadastroId: this.cadastroId,
      codigo: codigoCupomDesconto,
      parcelas_quantidade: this.quantidadeParcelas,
    })
      .pipe(
        tap(() => this.fecharModal(true)),
        catchError(err => {
          const { error } = err;
          this.mensagemErro = error.message;
          if (error.errorCode === ErrorCode.CUPOM_DESCONTO_FORMA_PAGAMENTO_INVALIDA) {
            this.mostrarAlertaFormaPagamentoInvalida();
          } else {
            this.mostrarAlertaErro();
          }
          return of();
        }),
        finalize(() => this.loadingService.dismiss()),
      )
      .subscribe();
  }

  private async mostrarAlertaFormaPagamentoInvalida(): Promise<void> {
    const alert = await this.alertCtrl.create({
      header: 'Atenção',
      message: this.mensagemErro,
      buttons: [
        {
          text: 'Manter forma de pagamento',
          role: 'confirm',
          handler: () => {
            this.fecharModal();
          },
        },
        {
          text: 'Alterar forma de pagamento',
          role: 'confirm',
          handler: () => {
            this.quantidadeParcelas = null;
            this.resetarParcelas = true;
            this.validarCupom();
          },
        },
      ],
    });

    await alert.present();
  }

  private async mostrarAlertaErro(): Promise<void> {
    const alert = await this.alertCtrl.create({
      header: 'Atenção',
      message: this.mensagemErro,
      buttons: ['OK'],
    });

    await alert.present();
  }

  public fecharModal(cupomAplicado = false): void {
    this.modalCtrl.dismiss({ cupomAplicado, resetarParcelas: this.resetarParcelas });
  }
}
