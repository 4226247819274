import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import {
  GetUpdateOwnerContactDto,
  OwnerFillInStatusDto,
  StatusPreenchimento
} from '@usucampeao/lib-reurb-simplificado';
import { AbstractForm } from '@usucampeao/utils-frontend';

@Component({
  selector: 'usucampeao-proprietarios-dados-contato-form',
  templateUrl: './proprietarios-dados-contato-form.component.html',
  styleUrls: ['./proprietarios-dados-contato-form.component.scss']
})
export class ProprietariosDadosContatoFormComponent extends AbstractForm {
  @Input() set dadosContato(dadosContato: GetUpdateOwnerContactDto) {
    this.preencherForm(dadosContato);
  }
  @Input() set status(status: OwnerFillInStatusDto) {
    this.statusPreenchimento = status?.contactData || StatusPreenchimento.PENDENTE;
  }
  @Input() formDesabilitado = false;
  @Input() accordionEstaAberto = false;

  @Output() aoSubmeter = new EventEmitter<GetUpdateOwnerContactDto>();

  public statusPreenchimento: StatusPreenchimento;

  constructor(
    private fb: FormBuilder
  ) {
    super();
    this.form = this.fb.group({
      email: [null, Validators.email],
      phones: this.fb.group({
        cellphone: [null, Validators.compose([Validators.required, Validators.minLength(14)])],
        home: [null, Validators.minLength(13)],
        message: [null, Validators.minLength(13)],
      }),
      contactName: [null]
    });
  }

  private preencherForm(dadosContato: GetUpdateOwnerContactDto): void {
    this.form.patchValue({
      email: dadosContato?.email || null,
      phones: {
        cellphone: dadosContato?.phones?.cellphone || null,
        home: dadosContato?.phones?.home || null,
        message: dadosContato?.phones?.message || null,
      },
    });
  }

  public submit(): void {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }

    this.atualizarDadosContato();
  }

  private atualizarDadosContato(): void {
    const dadosContato: GetUpdateOwnerContactDto = Object.assign({}, this.form.value);
    this.aoSubmeter.emit(dadosContato);
    this.alterarAberturaAccordion(false);
  }

  public alterarAberturaAccordion(estaAberto: boolean): void {
    this.accordionEstaAberto = estaAberto;
  }
}
