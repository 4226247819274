<ion-text>
  <p class="font-text">
    Parabéns, você deu início ao processo de regularização do seu imóvel!
  </p>
  <p class="font-text">
    Para dar continuidade ao processo, faça o upload de todos os documentos solicitados do seu imóvel e da sua família,
    e os envie para a UsuCampeão através do aplicativo.
  </p>
  <p class="font-text">
    Certifique-se de que todos os dados e documentos enviados estejam corretos para que esta etapa ocorra o mais
    rapidamente possível.
  </p>
</ion-text>
