export enum ComissaoStatus {
  PENDENTE = 'PENDENTE',
  EM_ANALISE = 'EM_ANALISE',
  APROVADO = 'APROVADO',
  PAGO = 'PAGO',
  CANCELADO = 'CANCELADO',
  BLOQUEADO = 'BLOQUEADO',
}

export enum ComissaoRegraTime {
  NENHUM_VENDEDOR = 'NENHUM_VENDEDOR',
  NENHUM_VENDEDOR_EXCETO = 'NENHUM_VENDEDOR_EXCETO',
  TODOS_VENDEDORES = 'TODOS_VENDEDORES',
  TODOS_VENDEDORES_EXCETO = 'TODOS_VENDEDORES_EXCETO',
}
