<section class="c-confirmar-infos">
  <usucampeao-header backRoute="../selecionar-forma-pagamento" title="Confirmar informações"></usucampeao-header>

  <ion-text>
    <p class="font-text font-text--medium">
      Confirme as informações que aparecerão no contrato:
    </p>
  </ion-text>

  <div class="c-confirmar-infos__container">
    <div class="c-confirmar-infos__flex">
      <ion-text>
        <h2 class="title">
          Quadra {{ cadastro?.quadra }} Lote {{ cadastro?.lote }}
        </h2>
      </ion-text>
      <ion-button size="small" fill="clear" color="secondary" (click)="navegarPara('../../selecionar-lote')">
        <ion-icon slot="start" name="pencil"></ion-icon>
        Editar
      </ion-button>
    </div>
  </div>

  <div class="c-confirmar-infos__container">
    <div class="c-confirmar-infos__flex ion-margin-bottom">
      <ion-text>
        <h2 class="title">Dados do imóvel</h2>
      </ion-text>
      <ion-button size="small" fill="clear" color="secondary" (click)="navegarPara('../../cadastrar-dados-imovel')">
        <ion-icon slot="start" name="pencil"></ion-icon>
        Editar
      </ion-button>
    </div>

    <ion-text>
      <p class="font-text font-text--medium-color">
        <ion-icon name="location-outline"></ion-icon>
        {{ imovel?.address | address }}
      </p>
      <p class="font-text font-text--medium-color" *ngIf="imovel?.situation === situacaoImovel.BUILT">
        Imovel construído
      </p>
      <p class="font-text font-text--medium-color" *ngIf="imovel?.possuiOutroImovel">
        Possui outra propriedade
      </p>
    </ion-text>
  </div>

  <div class="c-confirmar-infos__container">
    <div class="c-confirmar-infos__flex ion-margin-bottom">
      <ion-text>
        <h2 class="title">Dados pessoais</h2>
      </ion-text>
      <ion-button size="small" fill="clear" color="secondary"
        (click)="navegarPara('../../cadastrar-dados-proprietario')">
        <ion-icon slot="start" name="pencil"></ion-icon>
        Editar
      </ion-button>
    </div>

    <ion-text>
      <p class="font-text font-text--medium-color">{{ proprietario?. name }}</p>
      <p class="font-text font-text--medium-color">{{ proprietario?.cpf | mask: '000.000.000-00' }}</p>
      <p class="font-text font-text--medium-color">{{ proprietario?.birthDate | date: 'dd/MM/yyyy' }}</p>
      <p class="font-text font-text--medium-color">{{ proprietario?.rendaFamiliar | rendaFamiliar }}</p>
      <p class="font-text font-text--medium-color">{{ proprietario?.maritalStatus | estadoCivil }}</p>
      <p class="font-text font-text--medium-color">{{ proprietario?.phones?.cellphone | mask: '00 00000-0000' }}</p>
      <p class="font-text font-text--medium-color">{{ proprietario?.email }}</p>
    </ion-text>
  </div>

  <div class="c-confirmar-infos__container">
    <div class="c-confirmar-infos__flex ion-margin-bottom">
      <ion-text>
        <h2 class="title">
          REURB {{ cadastro?.averbacaoSelecionada ? '+ Averbação' : '' }}
        </h2>
      </ion-text>
      <ion-button *ngIf="possuiPagamento" size="small" fill="clear" color="secondary"
        (click)="navegarPara('../selecionar-averbacao')">
        <ion-icon slot="start" name="pencil"></ion-icon>
        Editar
      </ion-button>
    </div>

    <ion-text>
      <p class="font-text font-text--medium-color">
        <ion-icon name="grid-outline"></ion-icon>
        Regularização do terreno
      </p>
      <p class="font-text font-text--medium-color" *ngIf="cadastro?.averbacaoSelecionada">
        <ion-icon name="home-outline"></ion-icon>
        Regularização do imóvel
      </p>
    </ion-text>
  </div>

  <div class="c-confirmar-infos__container">
    <div class="c-confirmar-infos__flex ion-margin-bottom">
      <ion-text>
        <h2 class="title">Formas de pagamento</h2>
      </ion-text>
      <ion-button size="small" fill="clear" color="secondary" (click)="navegarPara('../selecionar-forma-pagamento')">
        <ion-icon slot="start" name="pencil"></ion-icon>
        Editar
      </ion-button>
    </div>

    <ion-text>
      <p class="font-text font-text--medium-color"
        *ngIf="!possuiPagamento || (reurbGratuito && !cadastro.averbacaoSelecionada); else formaPagamento">
        Contratação gratuita
      </p>

      <ng-template #formaPagamento>
        <p class="font-text font-text--medium-color"
          *ngIf="+cadastro?.totalParcelasSelecionada === 0; else pagamentoParcelado">
          Pagamento à vista
        </p>

        <ng-template #pagamentoParcelado>
          <p class="font-text font-text--medium-color">
            Entrada + {{ cadastro?.totalParcelasSelecionada }} parcelas
          </p>
          <p class="font-text font-text--medium-color">
            Vencimento todo dia {{ cadastro?.diaVencimentoSelecionado }}
          </p>
          <p class="font-text font-text--medium-color">
            Vencimento da entrada dia {{ cadastro?.diaEntradaSelecionado }}
          </p>
        </ng-template>
      </ng-template>
    </ion-text>

    <usucampeao-cadastro-resumo-pagamento [cadastro]="cadastro" [tabelaPreco]="tabelaPreco" [semPadding]="true">
    </usucampeao-cadastro-resumo-pagamento>
  </div>

  <ion-text>
    <p class="font-text ion-no-margin ion-margin-top">
      Comentário sobre o imóvel (opcional)
    </p>
  </ion-text>

  <form [formGroup]="form">
    <usucampeao-textarea label="Comentário" controlName="observacoes"></usucampeao-textarea>
  </form>

  <span class="text-link" (click)="visualizarPreviaContrato()">Visualizar prévia do contrato</span>

  <ion-button class="c-confirmar-infos__botao-gerar-contrato" mode="md" (click)="solicitarAssinaturaContrato()">
    Gerar contrato
  </ion-button>
</section>
