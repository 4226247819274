<section class="d-selecionar-tipo">
  <usucampeao-header title="Novo documento"></usucampeao-header>

  <ion-text>
    <p class="font-text">Qual o tipo de documento que você quer adicionar?</p>
    <p class="font-text">Quanto mais documentos enviados, melhor andará o seu processo.</p>
  </ion-text>

  <div class="d-selecionar-tipo__list" *ngIf="possuiDocumentoPosse">
    <ion-text>
      <p class="description-text description-text--medium">Documentos de posse</p>
    </ion-text>
    <ion-item class="ion-no-padding ion-margin-bottom" detail="true" *ngFor="let documento of documentosPosse" (click)="iaParaUploadDocumento(documento)">
      <ion-label>{{ documento | documentoTipo}}</ion-label>
    </ion-item>

    <ion-text class="ion-text-center">
      <p class="font-text font-text--small ion-no-margin ion-margin-top ion-padding-top">Não possui documento de posse?</p>
      <p class="text-link text-link--small text-link--primary">Adicione uma justificativa</p>
    </ion-text>
  </div>

  <div class="d-selecionar-tipo__list" *ngIf="possuiDocumentoAquisicao">
    <ion-text>
      <p class="description-text description-text--medium">Documentos de aquisição</p>
    </ion-text>
    <ion-item class="ion-no-padding ion-margin-bottom" detail="true" *ngFor="let documento of documentosAquisicao" (click)="iaParaUploadDocumento(documento)">
      <ion-label>{{ documento | documentoTipo }}</ion-label>
    </ion-item>

    <ion-text class="ion-text-center">
      <p class="font-text font-text--small ion-no-margin ion-margin-top ion-padding-top">Não possui documento de aquisição?</p>
      <p class="text-link text-link--small text-link--primary">Adicione uma justificativa</p>
    </ion-text>
  </div>

  <div class="d-selecionar-tipo__list" *ngIf="possuiDocumentoIdentidade">
    <ion-text>
      <p class="description-text description-text--medium">Documentos de identidade</p>
    </ion-text>
    <ion-item class="ion-no-padding ion-margin-bottom" detail="true" *ngFor="let documento of documentosIdentidade" (click)="iaParaUploadDocumento(documento)">
      <ion-label>{{ documento | documentoTipo  }}</ion-label>
    </ion-item>
  </div>

  <div class="d-selecionar-tipo__list" *ngIf="possuiDocumentoEstadoCivil">
    <ion-text>
      <p class="description-text description-text--medium">Documentos de estado civil</p>
    </ion-text>
    <ion-item class="ion-no-padding ion-margin-bottom" detail="true" *ngFor="let documento of documentosEstadoCivil" (click)="iaParaUploadDocumento(documento)">
      <ion-label>{{ documento | documentoTipo }}</ion-label>
    </ion-item>
  </div>


  <usucampeao-footer>
    <ion-button class="ion-margin-top" mode="md" fill="outline" (click)="cancelar()">Cancelar</ion-button>
  </usucampeao-footer>
</section>
