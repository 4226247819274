export function getDifferenceTwoObjects<T>(obj1: T, obj2: T): Partial<T> {
  return Object.keys(obj1)
    .reduce((acc, curr) => {
      const obj1Clone = Object.assign({}, obj1);
      const obj2Clone = Object.assign({}, obj2);

      if (!obj1Clone[curr]) {
        obj1Clone[curr] = null;
      }
      if (!obj2Clone[curr]) {
        obj2Clone[curr] = null;
      }

      if (obj1Clone[curr] !== obj2Clone[curr]) {
        acc[curr] = obj1[curr];
      }
      return acc;
    }, {} as Partial<T>);
}
