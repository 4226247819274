<section class="p-dados-basicos">
  <usucampeao-header backRoute="../cadastrar-dados-imovel" title="Dados Pessoais"></usucampeao-header>

  <ion-text>
    <p class="font-text">Dados pessoais que aparecerão no contrato </p>
  </ion-text>

  <form [formGroup]="form">
    <usucampeao-input label="Nome completo" controlName="name" [readonly]="formDesabilitado">
      <span usu-error>{{ gerarMensagemErro('name') }}</span>
    </usucampeao-input>

    <usucampeao-input label="CPF" controlName="cpf" type="tel" inputmode="numeric" mask="000.000.000-00"
      [readonly]="formDesabilitado || !!dadosBasicos?.cpf">
      <span usu-error>{{ gerarMensagemErro('cpf', 'CPF') }}</span>
    </usucampeao-input>

    <usucampeao-datepicker label="Data de nascimento" formControlName="birthDate"
      [maxDate]="dataMaximaCasamentoNascimento" [disabled]="formDesabilitado">
      <span usu-error>{{ gerarMensagemErro('birthDate') }}</span>
    </usucampeao-datepicker>

    <usucampeao-select label="Estado Civil" controlName="maritalStatus" [disabled]="formDesabilitado">
      <ion-select-option *ngFor="let estadoCivil of estadosCivis" [value]="estadoCivil">{{ estadoCivil | estadoCivil }}</ion-select-option>

      <span usu-error>{{ gerarMensagemErro('maritalStatus') }}</span>
    </usucampeao-select>

    <usucampeao-select label="Renda familiar aproximada" controlName="rendaFamiliar"
      [disabled]="formDesabilitado">
      <ion-select-option *ngFor="let rendaFamiliar of rendasFamiliares" [value]="rendaFamiliar">{{ rendaFamiliar | rendaFamiliar }}</ion-select-option>

      <span usu-error>{{ gerarMensagemErro('rendaFamiliar') }}</span>
    </usucampeao-select>

    <ng-container formGroupName="phones">
      <usucampeao-input label="Telefone celular" controlName="cellphone" type="tel" inputmode="numeric"
        mask="(00)00000-0000" [readonly]="formDesabilitado" [readonly]="formDesabilitado">
        <span usu-error>{{ gerarMensagemErro('phones', 'Celular', 'cellphone') }}</span>
      </usucampeao-input>
    </ng-container>

    <usucampeao-input label="Email (Opcional)" controlName="email" type="email" inputmode="email"
      [readonly]="formDesabilitado" [readonly]="formDesabilitado">
      <span usu-error>{{ gerarMensagemErro('email') }}</span>
    </usucampeao-input>
  </form>

  <usucampeao-footer>
    <ion-button *ngIf="!formDesabilitado" mode="md" (click)="submit()">Salvar</ion-button>
  </usucampeao-footer>
</section>
