import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'usucampeao-modal-politica-privacidade',
  templateUrl: './modal-politica-privacidade.component.html',
  styleUrls: ['./modal-politica-privacidade.component.scss']
})
export class ModalPoliticaPrivacidadeComponent {
  public email = 'ajuda@poderegularizar.com.br';
  public site = 'www.poderegularizar.com.br';

  constructor(
    public modalController: ModalController,
  ) { }

  public dismiss(): void {
    this.modalController.dismiss();
  }
}
