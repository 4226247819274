import { Expose, plainToInstance } from 'class-transformer';
import { TimeStatus } from '..';

export class TimeListarDto {
  @Expose()
  id: number;

  @Expose()
  nome: string;

  @Expose()
  descricao: string;

  @Expose()
  status: TimeStatus;

  @Expose({ name: 'quantidade_usuarios' })
  quantidadeUsuarios: number;

  @Expose({ name: 'quantidade_projetos' })
  quantidadeProjetos: number;

  @Expose()
  cidade?: string;

  @Expose({ name: 'usuario_responsavel_id' })
  usuarioResponsavelId: string;

  @Expose({ name: 'usuario_responsavel' })
  usuarioResponsavel: string;

  @Expose({ name: 'usuario_criacao' })
  usuarioCriacao: string;

  @Expose({ name: 'data_criacao' })
  dataCriacao: Date;

  /**
  * Cria uma nova instância de TimeListarDto a partir de dtos
  * @param props Interface de TimeListar
  * @returns nova instância de TimeListarDto
  */
  static from(props?: TimeListar): TimeListarDto {
    return plainToInstance(TimeListarDto, props, { exposeUnsetFields: false, excludeExtraneousValues: true });
  }
}

export interface TimeListar {
  id: number;
  nome: string;
  descricao: string;
  status: TimeStatus;
  quantidade_usuarios: number;
  quantidade_projetos: number
  cidade: string;
  usuario_responsavel_id: string;
  usuario_responsavel: string;
  usuario_criacao: string;
  data_criacao: Date;
}
