import { Expose, plainToInstance, Type } from 'class-transformer';
import { IsDate, IsEnum, IsNotEmpty, IsNumber, IsOptional, IsString } from 'class-validator';
import { Bs2IntegracaoDto } from '..';
import { PagamentoStatus } from '../../payment/pagamento-status.enum';

export class Bs2NotificacaoWebhookDto {
  @Expose()
  @IsString()
  @IsNotEmpty()
  pagamentoId: string;

  @Expose()
  @IsEnum(PagamentoStatus)
  @IsNotEmpty()
  status: PagamentoStatus;

  @Expose()
  @IsNumber()
  @IsNotEmpty()
  @IsOptional()
  valorPago?: number;

  @Expose()
  @Type(() => Date)
  @IsDate()
  @IsOptional()
  dataPagamento?: Date;

  @Expose()
  @Type(() => Date)
  @IsDate()
  @IsOptional()
  dataVencimento?: Date;

  @Expose()
  @Type(() => Date)
  @IsDate()
  @IsOptional()
  dataCancelamentoEfetuado?: Date;

  @Expose()
  @Type(() => Date)
  @IsDate()
  @IsOptional()
  dataEmissao?: Date;

  @Expose()
  @IsString()
  @IsOptional()
  codigoDeBarras?: string;

  @Expose()
  @IsString()
  @IsOptional()
  linhaDigitavel?: string;

  @Expose()
  @IsString()
  @IsOptional()
  boletoPdf?: string;

  @Expose()
  @IsString()
  @IsOptional()
  downloadUrl?: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  integracaoId: string;

  @Expose()
  @IsNumber()
  @IsNotEmpty()
  integracaoNumero: number;

  /**
   * Cria uma nova instância de Bs2IntegracaoDto
   * @param props DTO de Bs2IntegracaoDto
   * @returns nova instância de Bs2IntegracaoDto
   */
  static from(props: Bs2IntegracaoDto): Bs2NotificacaoWebhookDto {
    return plainToInstance(Bs2NotificacaoWebhookDto, props, { exposeUnsetFields: false, excludeExtraneousValues: true });
  }
}
