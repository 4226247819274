<ion-content class="ion-padding p-calendario">
  <ion-text class="ion-margin-bottom">
    <h1 class="title title--medium ion-margin-bottom">Calendario</h1>
  </ion-text>
  <ion-button mode="md" size="small" (click)="fecharModal()">
    Fechar calendário
  </ion-button>

  <ion-text class="ion-margin-bottom">
    <p class="font-text font-text-bold ion-no-margin ion-margin-top">
      Assembleia com moradores para esclarecimentos do processo
    </p>
    <p class="ion-no-margin font-text font-text--small font-primary">
      <span class="font-text--variant">{{ projeto?.assembleiaData | date: 'dd/MM/yyyy' }}</span>
      às
      <span class="font-text--variant">{{ projeto?.assembleiaData | date: 'hh:mm' }}</span>
    </p>
  </ion-text>
  <ion-text class="ion-margin-bottom">
    <p class="font-text font-text-bold ion-no-margin ion-margin-top">
      Ponto de apoio para auxílio com cadastro de imóveis e terrenos
    </p>
    <p class="ion-no-margin font-text font-text--small font-primary">
      <span class="font-text--variant">{{ projeto?.escritorioDataInicio | date: 'dd/MM/yyyy' }}</span>
      a
      <span class="font-text--variant">{{ projeto?.escritorioDataFim | date: 'hh:mm' }}</span>
    </p>
    <p class="description-text ion-no-margin">
      Local: {{ projeto?.escritorioLocal?.logradouro }},
      {{ projeto?.escritorioLocal?.numero }}{{ projeto?.escritorioLocal?.complemento ? ', ' + projeto?.escritorioLocal?.complemento : '' }}
    </p>
  </ion-text>
  <ion-text class="ion-margin-bottom">
    <p class="ion-no-margin description-text description-text--variant">Fase 1</p>
    <p class="font-text font-text-bold ion-no-margin">Cadastro de imóveis e seus proprietários e envio dos documentos
    </p>
    <p class="ion-no-margin font-text font-text--small font-primary">
      <span class="font-text--variant">{{ projeto?.cadastroDataInicio | date: 'dd/MM/yyyy' }}</span>
      a
      <span class="font-text--variant">{{ projeto?.cadastroDataFim | date: 'dd/MM/yyyy' }}</span>
    </p>
  </ion-text>
  <ion-text class="ion-margin-bottom">
    <p class="ion-no-margin description-text description-text--variant">Fase 2</p>
    <p class="font-text font-text-bold ion-no-margin">Pré-análise dos dados e documentos enviados</p>
    <p class="ion-no-margin font-text font-text--small font-primary">
      <span class="font-text--variant">{{ projeto?.preAnaliseDataInicio | date: 'dd/MM/yyyy' }}</span>
      a
      <span class="font-text--variant">{{ projeto?.preAnaliseDataFim | date: 'dd/MM/yyyy' }}</span>
    </p>
  </ion-text>
  <ion-text class="ion-margin-bottom">
    <p class="ion-no-margin description-text description-text--variant">Fase 3</p>
    <p class="font-text font-text-bold ion-no-margin">Visita para validação dos dados e documentos</p>
    <p class="ion-no-margin font-text font-text--small font-primary">
      <span class="font-text--variant">{{ projeto?.visitaDataInicio | date: 'dd/MM/yyyy' }}</span>
      a
      <span class="font-text--variant">{{ projeto?.visitaDataFim | date: 'dd/MM/yyyy' }}</span>
    </p>
  </ion-text>
  <ion-text class="ion-margin-bottom">
    <p class="ion-no-margin description-text description-text--variant">Fase 4</p>
    <p class="font-text font-text-bold ion-no-margin">Aprovação e publicação no Diário Oficial</p>
    <p class="ion-no-margin font-text font-text--small font-primary">
      <span class="font-text--variant">{{ projeto?.prefeituraDataInicio | date: 'dd/MM/yyyy' }}</span>
      a
      <span class="font-text--variant">{{ projeto?.prefeituraDataFim | date: 'dd/MM/yyyy' }}</span>
    </p>
  </ion-text>
  <ion-text class="ion-margin-bottom">
    <p class="ion-no-margin description-text description-text--variant">Fase 5</p>
    <p class="font-text font-text-bold ion-no-margin">Emissão de CRF simplificada pela SEHAB e do Registro do Imóvel no
      Cartório</p>
    <p class="ion-no-margin font-text font-text--small font-primary">
      <span class="font-text--variant">{{ projeto?.cartorioDataInicio | date: 'dd/MM/yyyy' }}</span>
      a
      <span class="font-text--variant">{{ projeto?.cartorioDataFim | date: 'dd/MM/yyyy' }}</span>
    </p>
  </ion-text>
  <ion-text class="ion-margin-bottom">
    <p class="ion-no-margin description-text description-text--variant">Fase 6</p>
    <p class="font-text font-text-bold ion-no-margin">Entrega pela Secretaria de Habitação</p>
    <p class="ion-no-margin font-text font-text--small font-primary">
      <span class="font-text--variant">{{ projeto?.matriculaDataInicio | date: 'dd/MM/yyyy' }}</span>
      a
      <span class="font-text--variant">{{ projeto?.matriculaDataFim | date: 'dd/MM/yyyy' }}</span>
    </p>
  </ion-text>
</ion-content>
