import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlteracaoDto, DocumentFileDto, TipoAlteracao } from '@usucampeao/lib-reurb-simplificado';
import { BaseComponent, LoadingService, ToastService } from '@usucampeao/ui-mobile';
import { Observable, iif, of } from 'rxjs';
import { catchError, finalize, switchMap, takeUntil, tap } from 'rxjs/operators';
import { CadastrosQuery } from '../../state/cadastros.query';
import { CadastroService } from '../../state/cadastros.service';
import { ProprietariosQuery } from '../state/proprietarios.query';
import { ProprietariosService } from '../state/proprietarios.service';

@Component({
  templateUrl: './proprietarios-documentos.page.html',
  styleUrls: ['./proprietarios-documentos.page.scss']
})
export class ProprietariosDocumentosPage extends BaseComponent implements OnInit {
  private cadastroId: string;
  private proprietarioId: string;

  public alteracoes$: Observable<AlteracaoDto[]>;
  public documentos$: Observable<DocumentFileDto[]>;

  constructor(
    private cadastrosQuery: CadastrosQuery,
    private cadastrosService: CadastroService,
    private proprietariosQuery: ProprietariosQuery,
    private proprietariosService: ProprietariosService,
    private loadingService: LoadingService,
    private route: ActivatedRoute,
    private toastService: ToastService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.route.params
      .pipe(
        tap(params => this.cadastroId = params.cadastroId),
        tap(params => this.proprietarioId = params.proprietarioId),
        tap(() => this.carregarDocumentos()),
        tap(() => this.documentos$ = this.proprietariosQuery.buscarDocumentos(this.proprietarioId)),
        tap(() => this.alteracoes$ = this.cadastrosQuery.buscarAlteracoes$(this.cadastroId, [TipoAlteracao.PROPRIETARIO_DOCUMENTO_ESTADO_CIVIL, TipoAlteracao.PROPRIETARIO_DOCUMENTO_PESSOAL], this.proprietarioId)),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe();
  }

  public async carregarDocumentos(event?: any): Promise<void> {
    if (!event) {
      await this.loadingService.createLoader();
    }

    this.proprietariosService.buscarDocumentos(this.cadastroId, this.proprietarioId)
      .pipe(
        switchMap(() => iif(() =>
          this.cadastrosQuery.cadastroEstaEmAlteracoesNecessarias(this.cadastroId),
          this.cadastrosService.buscarUltimaRevisao(this.cadastroId, true),
          of()
        )),
        catchError(() => this.toastService.error('Erro ao buscar documentos do proprietário. Por favor tente novamente.')),
        finalize(async () => event ? event.target.complete() : await this.loadingService.dismiss()),
      )
      .subscribe();
  }

}
