import { IsArray, IsNotEmpty, IsString } from 'class-validator';

export class NucleoEditarDto {
  @IsString()
  @IsNotEmpty()
  nome: string;

  @IsArray({ each: true })
  coords: number[][][];
}
