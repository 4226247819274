import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { ModalAjudaComponent } from './modal-ajuda/modal-ajuda.component';



@NgModule({
  declarations: [
    ModalAjudaComponent
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,
  ],
  exports: [
    ModalAjudaComponent,
  ]
})
export class ModalAjudaModule { }
