export enum RevisaoHistoricoTipo {
  ALTERACAO_DOCUMENTO_CONTRATO = 'ALTERACAO_DOCUMENTO_CONTRATO',
  ALTERACAO_DOCUMENTO_PROCURACAO = 'ALTERACAO_DOCUMENTO_PROCURACAO',
  ALTERACAO_IMOVEL_DADOS_ADICIONAIS = 'ALTERACAO_IMOVEL_DADOS_ADICIONAIS',
  ALTERACAO_IMOVEL_DOCUMENTO_AQUISICAO = 'ALTERACAO_IMOVEL_DOCUMENTO_AQUISICAO',
  ALTERACAO_IMOVEL_DOCUMENTO_POSSE = 'ALTERACAO_IMOVEL_DOCUMENTO_POSSE',
  ALTERACAO_IMOVEL_ENDERECO = 'ALTERACAO_IMOVEL_ENDERECO',
  ALTERACAO_IMOVEL_FOTO_FACHADA = 'ALTERACAO_IMOVEL_FACHADA',
  ALTEACAO_IMOVEL_QUADRA_LOTE = 'ALTEACAO_IMOVEL_QUADRA_LOTE',
  ALTERACAO_PROPRIETARIO_CONTATO = 'ALTERACAO_PROPRIETARIO_CONTATO',
  ALTERACAO_PROPRIETARIO_DADOS_BASICOS = 'ALTERACAO_PROPRIETARIO_DADOS_BASICOS',
  ALTERACAO_PROPRIETARIO_DOCUMENTOS = 'ALTERACAO_PROPRIETARIO_DOCUMENTOS',
  ALTERACAO_PROPRIETARIO_DOCUMENTO_ESTADO_CIVIL = 'ALTERACAO_PROPRIETARIO_DOCUMENTO_ESTADO_CIVIL',
  ALTERACAO_PROPRIETARIO_DOCUMENTO_PESSOAL = 'ALTERACAO_PROPRIETARIO_DOCUMENTO_PESSOAL',
}
