import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { PropertyFillInStatusDto } from '@usucampeao/lib-reurb-simplificado';
import { ImovelStateDto } from '@usucampeao/ui-mobile';

export interface ImovelState extends EntityState<ImovelStateDto> { }

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'imoveis',
  resettable: true,
})
export class ImoveisStore extends EntityStore<ImovelState> {
  constructor() {
    super();
  }

  public buscarImovel(id: string): ImovelStateDto {
    return this.getValue().entities[id];
  }

  public buscarStatusPreechimento(id: string): PropertyFillInStatusDto {
    const { fillInStatus } = this.buscarImovel(id) || {};
    return Object.assign({}, fillInStatus);
  }
}
