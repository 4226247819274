import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { ProprietariosDadosBasicosV2Module } from '@usucampeao/ui-mobile';
import { ProprietariosDadosBasicosRoutingModule } from './proprietarios-dados-basicos-routing.module';
import { ProprietariosDadosBasicosPage } from './proprietarios-dados-basicos.page';

@NgModule({
  declarations: [
    ProprietariosDadosBasicosPage
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,

    // route
    ProprietariosDadosBasicosRoutingModule,

    // components
    ProprietariosDadosBasicosV2Module,
  ]
})
export class ProprietariosDadosBasicosPageModule { }
