import { Component, Input, SkipSelf } from '@angular/core';
import { ControlContainer } from '@angular/forms';

@Component({
  selector: 'usucampeao-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  viewProviders: [{
    provide: ControlContainer,
    useFactory: (container: ControlContainer) => container,
    deps: [[new SkipSelf(), ControlContainer]],
  }]
})
export class TextareaComponent {
  @Input() label: string;
  @Input() type: string = 'text';
  @Input() inputmode = 'text';
  @Input() placeholder: string;
  @Input() controlName: string;
  @Input() readonly = false;
  @Input() mask?: string;
}
