<ion-content class="ion-padding">
  <section class="create-account">
    <ion-text>
      <h1 class="title title--large">Bem Vindo!</h1>
    </ion-text>

    <ion-text>
      <p class="font-text">Crie sua conta para cadastrar e acompanhar seus processos de regularização</p>
    </ion-text>

    <usucampeao-usuario-form (aoSubmeter)="onSubmit($event)">
      <div class="ion-margin-vertical">
        <ion-checkbox mode="md" [(ngModel)]="aceitePrivacidade"></ion-checkbox>

        <ion-text class="font-text font-text--small line-height">
          Eu li, estou ciente das condições de tratamento dos meus dados pessoais e dou o meu consentimento, quando
          aplicável, conforme descrito na <a (click)="abrirModalPoliticaPrivacidade()">Politica de Privacidade</a> e nos
          <a (click)="abrirModalTermosDeUso()">Termos de Uso</a>.
        </ion-text>
      </div>
    </usucampeao-usuario-form>
  </section>
</ion-content>
