/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { PipesModule } from '@usucampeao/utils-frontend';
import { FooterModule, HeaderModule, MenuItemV2Module, UploadFotoDocumentoModule, UploadFotoImovelModule } from '../../../components';
import { CadastroStatusModule } from '../../../components/cadastro-ficha-status/cadastro-status.module';
import { CadastrosFichaV2Component } from './cadastros-ficha-v2.component';
import { PagamentosResumoComponent } from './pagamentos-resumo/pagamentos-resumo.component';

@NgModule({
  declarations: [
    CadastrosFichaV2Component,
    PagamentosResumoComponent,
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,

    // components
    CadastroStatusModule,
    HeaderModule,
    FooterModule,
    MenuItemV2Module,
    UploadFotoImovelModule,
    UploadFotoDocumentoModule,

    // others
    PipesModule,
  ],
  exports: [
    CadastrosFichaV2Component
  ]
})
export class CadastrosFichaV2Module { }
