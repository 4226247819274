<ion-content class="ion-padding">
  <ion-refresher slot="fixed" (ionRefresh)="carregarDadosContato($event)">
    <ion-refresher-content>
    </ion-refresher-content>
  </ion-refresher>

  <usucampeao-proprietarios-dados-contato [dadosContato]="dadosContato$ | async" [alteracoes]="alteracoes$ | async"
    [formDesabilitado]="formDesabilitado$ | async"
    (aoSubmeter)="alterarDadosContato($event)"></usucampeao-proprietarios-dados-contato>
</ion-content>
