<div class="u-foto-documento u-foto-documento__flex">
  <div class="u-foto-documento__container  u-foto-documento__flex  u-foto-documento__flex--column" *ngIf="!possuiImagem"
    (click)="selecionarImagem()">
    <ion-icon class="u-foto-documento__icon" color="medium" name="camera-outline"></ion-icon>
    <ion-text>
      <p class="ion-no-margin">Tirar foto</p>
    </ion-text>
  </div>

  <div class="u-foto-documento__flex u-foto-documento__flex--column" *ngIf="possuiImagem">
    <div class="u-foto-documento__flex u-foto-documento__flex--space-between">

      <div class="u-foto-documento__button-navigation-container">
        <div class="u-foto-documento__button-navigation" *ngIf="mostrarBotaoVoltar" (click)="irParaImagemAnterior()">
          <ion-icon class="u-foto-documento__button-navigation__icon" color="primary"
            name="caret-back-circle-sharp"></ion-icon>

          <ion-text class="ion-text-center" color="primary">
            <span class="font-text font-text--extra-small">Voltar página</span>
          </ion-text>
        </div>
      </div>

      <div class="u-foto-documento__imagem-container ion-margin-horizontal">
        <div class="u-foto-documento__flex u-foto-documento__flex--end" *ngIf="!readonly && !modoSimplificado">
          <ion-button class="ion-no-margin ion-no-padding" fill="clear" color="danger" (click)="removerImagem()">
            Remover <ion-icon name="close-outline"></ion-icon>
          </ion-button>
        </div>

        <div class="u-foto-documento__imagem-container__content"
          [class.u-foto-documento__imagem-container__content--simplificado]="modoSimplificado">
          <img *ngIf="!arquivoPDF" class="u-foto-documento__imagem" [lazyLoad]="urlImagemSelecionada"
            [defaultImage]="imagemCarregando" [errorImage]="imagemDefault" alt="Foto enviada.">

          <div class="u-foto-documento__pdf" *ngIf="arquivoPDF">
            <ion-icon class="u-foto-documento__pdf-icon" color="medium" name="document-text-outline"></ion-icon>
            <ion-text class="ion-text-center">
              <p>Arquivo sem prévia de imagem</p>
            </ion-text>
          </div>
        </div>
      </div>

      <div class="u-foto-documento__button-navigation-container">
        <div class="u-foto-documento__button-navigation" *ngIf="mostrarBotaoAvancar" (click)="irParaProximaImagem()">
          <ion-icon class="u-foto-documento__button-navigation__icon" color="primary"
            name="caret-forward-circle-sharp"></ion-icon>

          <ion-text class="ion-text-center" color="primary">
            <span class="font-text font-text--extra-small">Avançar página</span>
          </ion-text>
        </div>
      </div>

    </div>
    <ion-button id="documento-adicionar-pagina" *ngIf="podeEnviarVariosArquivos && !readonly && !modoSimplificado"
      fill="clear" color="secondary" (click)="selecionarImagem()">
      Adicionar página
    </ion-button>
  </div>
</div>
