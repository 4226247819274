import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { NotificacaoIconeComponent } from './notificacao-icone.component';

@NgModule({
  declarations: [NotificacaoIconeComponent],
  imports: [
    // root
    CommonModule,
    IonicModule
  ],
  exports: [NotificacaoIconeComponent],
})
export class NotificacaoIconeModule { }
