import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { PipesModule } from '@usucampeao/utils-frontend';
import { FooterModule } from '../footer/footer.module';
import { HeaderModule } from '../header/header.module';
import { DocumentosSelecionarTipoComponent } from './documentos-selecionar-tipo.component';

@NgModule({
  declarations: [
    DocumentosSelecionarTipoComponent
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,

    // Components
    FooterModule,
    HeaderModule,

    // others
    PipesModule,
  ],
  exports: [
    DocumentosSelecionarTipoComponent
  ]
})
export class DocumentosSelecionarTipoModule { }
