import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProprietariosEditarPage } from './proprietarios-editar.page';

const routes: Routes = [{ path: '', component: ProprietariosEditarPage }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProprietariosEditarRoutingModule { }
