import { AddressDto } from '@usucampeao/lib-reurb-simplificado';
import { Expose, plainToInstance, Type } from 'class-transformer';
import { IsEmail, IsNotEmpty, IsNumber, IsNumberString, IsOptional, IsString, Length, ValidateNested } from 'class-validator';
import { IntegrationOmieCustomerDto } from './integration-omie-customer-dto.model';

/**
 * TODO: Alterar atributos para português após ajustar back antigo.
 * TODO: Provavelmente essa classe será removida pois podemos adaptar a classe IntegrationOmieCustomerDto para resolver todos os problemas.
 */
export class OmieCustomerDto {
  @Expose({ name: 'omieId' })
  @IsNumber()
  @IsOptional()
  //@ApiProperty()
  id?: number;

  @Expose({ name: 'id' })
  @IsString()
  @IsNotEmpty()
  //@ApiProperty()
  personId: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  //@ApiProperty()
  name: string;

  @Expose()
  @IsEmail()
  @IsNotEmpty()
  //@ApiProperty()
  email: string;

  @Expose()
  @IsNumberString()
  @Length(10, 11)
  @IsNotEmpty()
  //@ApiProperty()
  phone: string;

  @Expose({ name: 'cpf' })
  @IsNumberString()
  @Length(11, 14)
  @IsNotEmpty()
  //@ApiProperty()
  document: string;

  @Expose()
  @Type(() => AddressDto)
  @ValidateNested()
  @IsNotEmpty()
  //@ApiProperty()
  address: AddressDto;

  /**
   * Cria uma nova instância de OmieCustomerDto
   * @param props DTO de IntegrationOmieCustomerDto
   * @returns nova instância de OmieCustomerDto
   */
  static from(props?: Partial<IntegrationOmieCustomerDto>): OmieCustomerDto {
    return plainToInstance(OmieCustomerDto, props, { exposeUnsetFields: false, excludeExtraneousValues: true });
  }
}
