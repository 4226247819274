/**
 * Os códigos de erro devem ser únicos e não devem ser alterados.
 * Eles devem estar organizados de acordo com o contexto de cada erro.
 * Ex:
 *  - Erros de gateways
 *  - Erros de regra de negócio
 *  - Erros de validação
 *  - Erros de infraestrutura
 */
export enum ErrorCode {
  GENERIC_ERROR = 10,
  USUARIO_BACKOFFICE_CREATE_ERROR = 100,
  USUARIO_BACKOFFICE_UPDATE_ERROR = 101,
  USUARIO_BACKOFFICE_GET_ERROR = 102,
  USUARIO_BACKOFFICE_LIST_ERROR = 103,
  USUARIO_BACKOFFICE_DUPLICATE_ERROR = 104,
  USUARIO_BACKOFFICE_NOT_FOUND_ERROR = 105,
  USUARIO_BACKOFFICE_INVALID_STATUS = 106,
  USUARIO_BACKOFFICE_BAD_REQUEST_ERROR = 107,
  USUARIO_BACKOFFICE_PAST_DEADLINE_ERROR = 108,
  CIDADE_NOT_FOUND_ERROR = 205,
  NUCLEO_NOT_FOUND_ERROR = 305,
  NUCLEO_BAD_REQUEST_ERROR = 307,
  MAPA_SET_ERROR = 400,
  MAPA_GET_ERROR = 402,
  MAPA_NOT_FOUND_ERROR = 405,
  MAPA_BAD_REQUEST_ERROR = 407,
  QUADRA_DUPLICATE_ERROR = 504,
  QUADRA_NOT_FOUND_ERROR = 505,
  QUADRA_BAD_REQUEST_ERROR = 507,
  LOTE_CREATE_ERROR = 600,
  LOTE_DUPLICATE_ERROR = 604,
  LOTE_NOT_FOUND_ERROR = 605,
  LOTE_BAD_REQUEST_ERROR = 607,
  PROJETO_CREATE_ERROR = 700,
  PROJETO_UPDATE_ERROR = 701,
  PROJETO_GET_ERROR = 702,
  PROJETO_LIST_ERROR = 703,
  PROJETO_NOT_FOUND_ERROR = 705,
  PROJETO_INVALID_STATUS = 706,
  PROJETO_BAD_REQUEST_ERROR = 707,
  PROJETO_HISTORICO_CREATE_ERROR = 800,
  PROJETO_HISTORICO_GET_ERROR = 802,
  PROJETO_HISTORICO_LIST_ERROR = 803,
  PROJETO_HISTORICO_BAD_REQUEST_ERROR = 807,
  PROJETO_TIME_COMISSAO_BAD_REQUEST_ERROR = 810,
  PROJETO_FAVORITO_CREATE_ERROR = 900,
  PROJETO_FAVORITO_LIST_ERROR = 903,
  PROJETO_FAVORITO_BAD_REQUEST_ERROR = 907,
  PROJETO_QUADRA_NOT_FOUND_ERROR = 1007,
  TABELA_PRECO_CREATE_ERROR = 1100,
  TABELA_PRECO_UPDATE_ERROR = 1101,
  TABELA_PRECO_GET_ERROR = 1102,
  TABELA_PRECO_LIST_ERROR = 1103,
  TABELA_PRECO_NOT_FOUND_ERROR = 1105,
  TABELA_PRECO_BAD_REQUEST_ERROR = 1107,
  REGISTRATION_CREATE_ERROR = 1200,
  REGISTRATION_UPDATE_ERROR = 1201,
  REGISTRATION_GET_ERROR = 1202,
  REGISTRATION_LIST_ERROR = 1203,
  REGISTRATION_DUPLICATE_ERROR = 1204,
  REGISTRATION_NOT_FOUND_ERROR = 1205,
  REGISTRATION_INVALID_STATUS = 1206,
  REGISTRATION_BAD_REQUEST_ERROR = 1207,
  REGISTRATION_PAST_DEADLINE_ERROR = 1208,
  REGISTRATION_EXPIRED_CONTRACT = 1209,
  REGISTRATION_ALREADY_SIGNED_CONTRACT = 1210,
  CADASTRO_BUSCAR_DADOS_CONTRATO_ERROR = 1211,
  OWNER_CREATE_ERROR = 1300,
  OWNER_UPDATE_ERROR = 1301,
  OWNER_GET_ERROR = 1302,
  OWNER_LIST_ERROR = 1303,
  OWNER_DUPLICATE_ERROR = 1304,
  OWNER_NOT_FOUND_ERROR = 1305,
  OWNER_GET_BY_CPF_ERROR = 1306,
  OWNER_GET_BY_USER_ERROR = 1307,
  PROPERTY_CREATE_ERROR = 1400,
  PROPERTY_UPDATE_ERROR = 1401,
  PROPERTY_GET_ERROR = 1402,
  PROPERTY_LIST_ERROR = 1403,
  PROPERTY_DUPLICATE_ERROR = 1404,
  PROPERTY_NOT_FOUND_ERROR = 1405,
  PROPERTY_OCCUPATION_CREATE_ERROR = 1500,
  PROPERTY_OCCUPATION_UPDATE_ERROR = 1501,
  PROPERTY_OCCUPATION_GET_ERROR = 1502,
  PROPERTY_OCCUPATION_LIST_ERROR = 1503,
  PROPERTY_OCCUPATION_DUPLICATE_ERROR = 1504,
  PROPERTY_OCCUPATION_NOT_FOUND_ERROR = 1505,
  PROPRIETARIO_CADASTRO_CREATE_ERROR = 1600,
  PROPRIETARIO_CADASTRO_UPDATE_ERROR = 1601,
  PROPRIETARIO_CADASTRO_GET_ERROR = 1602,
  PROPRIETARIO_CADASTRO_LIST_ERROR = 1603,
  PROPRIETARIO_CADASTRO_NOT_FOUND_ERROR = 1605,
  PROPRIETARIO_CADASTRO_BAD_REQUEST_ERROR = 1607,
  DOCUMENT_CREATE_ERROR = 1700,
  DOCUMENT_UPDATE_ERROR = 1701,
  DOCUMENT_GET_ERROR = 1702,
  DOCUMENT_LIST_ERROR = 1703,
  DOCUMENT_DUPLICATE_ERROR = 1704,
  DOCUMENT_NOT_FOUND_ERROR = 1705,
  DOCUMENT_STATUS_INVALID_ERROR = 1706,
  DOCUMENT_BAD_REQUEST_ERROR = 1707,
  FILE_CREATE_ERROR = 1800,
  FILE_UPDATE_ERROR = 1801,
  FILE_GET_ERROR = 1802,
  FILE_LIST_ERROR = 1803,
  FILE_DUPLICATE_ERROR = 1804,
  FILE_NOT_FOUND_ERROR = 1805,
  FILE_NOT_FOUND_IN_BUCKET_ERROR = 1806,
  FILE_UPLOAD_IN_BUCKET_ERROR = 1807,
  PAGAMENTO_CREATE_ERROR = 1900,
  PAGAMENTO_UPDATE_ERROR = 1901,
  PAGAMENTO_GET_ERROR = 1902,
  PAGAMENTO_LIST_ERROR = 1903,
  PAGAMENTO_NOT_FOUND_ERROR = 1905,
  PAGAMENTO_BAD_REQUEST_ERROR = 1907,
  PAGAMENTO_GATEWAY_INVALID_ERROR = 1908,
  BS2_BOLETO_BAD_REQUEST_ERROR = 2007,
  REVISAO_CREATE_ERROR = 2100,
  REVISAO_UPDATE_ERROR = 2101,
  REVISAO_GET_ERROR = 2102,
  REVISAO_LIST_ERROR = 2103,
  REVISAO_NOT_FOUND_ERROR = 2105,
  REVISAO_BAD_REQUEST_ERROR = 2107,
  USER_CREATE_ERROR = 2200,
  USER_UPDATE_ERROR = 2201,
  USER_GET_ERROR = 2202,
  USER_LIST_ERROR = 2203,
  USER_DUPLICATE_ERROR = 2204,
  USER_NOT_FOUND_ERROR = 2205,
  DISPOSITIVO_CREATE_ERROR = 2300,
  DISPOSITIVO_UPDATE_ERROR = 2301,
  DISPOSITIVO_GET_ERROR = 2302,
  DISPOSITIVO_LIST_ERROR = 2303,
  DISPOSITIVO_DUPLICATE_ERROR = 2304,
  DISPOSITIVO_NOT_FOUND_ERROR = 2305,
  DISPOSITIVO_STATUS_INVALID_ERROR = 2306,
  NOTIFICACAO_CREATE_ERROR = 2400,
  NOTIFICACAO_UPDATE_ERROR = 2401,
  NOTIFICACAO_GET_ERROR = 2402,
  NOTIFICACAO_LIST_ERROR = 2403,
  NOTIFICACAO_NOT_FOUND_ERROR = 2405,
  NOTIFICACAO_BAD_REQUEST_ERROR = 2407,
  CADASTRO_HISTORICO_CREATE_ERROR = 2900,
  CADASTRO_HISTORICO_LIST_ERROR = 2503,
  TIME_NOT_FOUND_ERROR = 2605,
  TIME_BAD_REQUEST_ERROR = 2607,
  LEAD_CREATE_ERROR = 2700,
  LEAD_UPDATE_ERROR = 2701,
  LEAD_GET_ERROR = 2702,
  LEAD_LIST_ERROR = 2703,
  LEAD_NOT_FOUND_ERROR = 2705,
  LEAD_INVALID_STATUS = 2706,
  LEAD_STATUS_INVALID_ERROR = 2708,
  POLITICA_PERMISSAO_DUPLICATE_ERROR = 2804,
  POLITICA_USUARIO_DUPLICATE_ERROR = 2904,
  SHAPEFILE_PARSE_ERROR = 3008,
  SHAPEFILE_INVALID_ERROR = 3109,
  CUPOM_DESCONTO_INVALIDO = 3200,
  CUPOM_DESCONTO_FORMA_PAGAMENTO_INVALIDA = 3201,
  D4SIGN_GERAR_DOCUMENTO_ERROR = 4000,
  D4SIGN_ADICIONAR_WEBHOOK_DOCUMENTO_ERROR = 4001,
  D4SIGN_ADICIONAR_ASSINANTES_DOCUMENTO_ERROR = 4002,
  D4SIGN_ENVIAR_DOCUMENTO_PARA_ASSINATURA_ERROR = 4003,
  D4SIGN_REENVIAR_DOCUMENTO_PARA_ASSINATURA_ERROR = 4004,
  D4SIGN_CANCELAR_DOCUMENTO_ERROR = 4005,
  D4SIGN_ALTERAR_SIGNATARIO_ERROR = 4006,
  D4SIGN_INTEGRACAO_ID_JA_EXISTE_ERROR = 4020,
  D4SIGN_JA_EXISTE_ERROR = 4021,
  D4SIGN_DOCUMENTO_NAO_CRIADO_ERROR = 4022,
  D4SIGN_ID_INCONSISTENTE_ERROR = 4023,
  D4SIGN_ALTERAR_STATUS_PARA_CRIADO_ERROR = 4030,
  D4SIGN_ALTERAR_STATUS_PARA_ASSINADO_ERROR = 4031,
  D4SIGN_LINK_REENVIO_NAO_DISPONIVEL_ERROR = 4032,
  D4SIGN_STATUS_INVALIDO_PARA_ATUALIZAR_CADASTRO_ERROR = 4033,
  D4SIGN_STATUS_INVALIDO_PARA_FECHAR_CONTRATO_ERROR = 4034,
  D4SIGN_STATUS_INVALIDO_PARA_CANCELAMENTO_ERROR = 4035,
  D4SIGN_STATUS_INVALIDO_PARA_RESETAR_DADOS_TIPO_ASSINATURA_ERROR = 4036,
  D4SIGN_STATUS_INVALIDO_PARA_SINCRONIZAR_ERROR = 4037,
}
