<ion-text>
  <p class="font-text">
    O projeto urbanístico contendo todas as informações atualizadas que são necessárias ao processo de regularização
    está sendo elaborado.
  </p>
  <p class="font-text">
    Logo será montada a primeira listagem de beneficiários que terão a matrícula do seu lote emitida. Certifique-se de
    que sua documentação está completa e que todos os seus pagamentos estejam em dia para ser incluído nesta listagem.
  </p>
  <p class="font-text">
    Entre em contato com a nossa central de atendimento através do número 0800 591 1328 ou pelo email
    contato&#64;usucampeao.com.br para confirmar a sua situação.
  </p>
</ion-text>
