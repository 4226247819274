import { IsEnum, IsIn, IsNotEmpty, IsOptional, IsString } from 'class-validator';
import { StatusRevisao } from './status-revisao.enum';

export class RevisaoFinalizarDto {
  @IsEnum(StatusRevisao)
  @IsIn([StatusRevisao.APROVADO, StatusRevisao.APROVADO_COM_RESSALVAS, StatusRevisao.REPROVADO])
  @IsNotEmpty()
  status: StatusRevisao;

  @IsString()
  @IsOptional()
  comentario?: string;
}
