import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlteracaoDto, GetUpdatePropertyLocalizationDto, LoteListarMapaDto, MapaDto, TipoAlteracao } from '@usucampeao/lib-reurb-simplificado';
import { BaseComponent, CadastroStateDto, LoadingService, NavigationService, ToastService } from '@usucampeao/ui-mobile';
import { Observable, forkJoin } from 'rxjs';
import { catchError, concatMap, finalize, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { ProjetoQuery } from '../../../../core/states/projeto/projeto.query';
import { ProjetoService } from '../../../../core/states/projeto/projeto.service';
import { CadastrosQuery } from '../../state/cadastros.query';
import { CadastroService } from '../../state/cadastros.service';
import { ImoveisQuery } from '../state/imoveis.query';
import { ImoveisService } from '../state/imoveis.service';

@Component({
  templateUrl: './imoveis-localizacao.page.html',
  styleUrls: ['./imoveis-localizacao.page.scss']
})
export class ImoveisLocalizacaoPage extends BaseComponent implements OnInit {
  private imovelId: string;
  private cadastroId: string;
  public alteracoes$: Observable<AlteracaoDto[]>;
  public loteSelecionadoId$: Observable<number>;
  public mapa$: Observable<MapaDto>;
  public lotes$: Observable<LoteListarMapaDto[]>;
  public selecionarLoteDesabilitado$: Observable<boolean>;

  constructor(
    private cadastrosQuery: CadastrosQuery,
    private cadastroService: CadastroService,
    private imoveisQuery: ImoveisQuery,
    private imoveisService: ImoveisService,
    private loadingService: LoadingService,
    private projetoService: ProjetoService,
    private projetosQuery: ProjetoQuery,
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    private toastService: ToastService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.route.params
      .pipe(
        tap(params => this.cadastroId = params.cadastroId),
        tap(params => this.imovelId = params.imovelId),
        tap(() => this.carregarLocalizacao()),
        tap(() => this.loteSelecionadoId$ = this.imoveisQuery.buscarLoteId(this.imovelId)),
        tap(() => {
          this.mapa$ = this.cadastro$
            .pipe(
              concatMap(cadastro => this.projetosQuery.buscarMapa$(cadastro.projetoFid)),
            );
        }),
        tap(() => {
          this.lotes$ = this.cadastro$
            .pipe(
              concatMap(cadastro => this.projetosQuery.buscarLotes$(cadastro.projetoFid))
            )
        }),
        tap(() => this.selecionarLoteDesabilitado$ = this.cadastrosQuery.cadastroEstaDesabilitadoParaEdicao(this.cadastroId)),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe();
  }

  private get cadastro$(): Observable<CadastroStateDto> {
    return this.cadastrosQuery.selectEntity(this.cadastroId);
  }

  public async carregarLocalizacao(event?: any): Promise<void> {
    if (!event) {
      await this.loadingService.createLoader();
    }

    this.imoveisService.buscarLocalizacao(this.imovelId)
      .pipe(
        mergeMap(() => this.cadastroService.buscarCadastro(this.cadastroId)),
        concatMap(cadastro =>
          forkJoin([
            this.projetoService.buscarMapa(cadastro.projetoFid),
            this.projetoService.buscarLotes(cadastro.projetoFid),
          ])
        ),
        tap(() => this.alteracoes$ = this.cadastrosQuery.buscarAlteracoes$(this.cadastroId, [TipoAlteracao.IMOVEL_DADOS_LOCALIZACAO])),
        catchError(() => this.toastService.error('Erro ao buscar localização do imóvel. Por favor tente novamente.')),
        finalize(async () => event ? event.target.complete() : await this.loadingService.dismiss()),
      )
      .subscribe();
  }

  public async alterarLocalizacao(lote: LoteListarMapaDto): Promise<void> {
    const data: GetUpdatePropertyLocalizationDto = {
      lotId: lote.id,
      lote: lote.label,
      blockId: lote.quadra_id,
      quadra: lote.quadra,
    };
    await this.loadingService.createLoader();
    this.imoveisService.atualizarLocalizacao(this.cadastroId, this.imovelId, data)
      .pipe(
        tap(() => this.navigationService.voltar()),
        catchError((err => {
          console.error(err);
          return this.toastService.error('Erro ao atualizar localização do imóvel. Por favor tente novamente.')
        })),
        finalize(async () => await this.loadingService.dismiss()),
      )
      .subscribe();
  }

}
