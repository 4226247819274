import { UF } from '@usucampeao/interfaces';
import { Expose } from 'class-transformer';

export class CidadeDto {
  @Expose()
  id: number;

  @Expose()
  nome: string;

  @Expose()
  geocodigo?: string;

  @Expose()
  coords: number[][];

  @Expose()
  uf: UF;

  @Expose()
  ativo: boolean;
}
