import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { LoteListarMapaDto, MapaDto, ProjetoDto, TabelaPrecoDetalhesDto } from '@usucampeao/lib-reurb-simplificado';


export interface ProjetoStateDto extends ProjetoDto {
  projeto_id: string;
  nucleo_distancia: number;
  tabelaPreco: Partial<TabelaPrecoDetalhesDto>[];
  lotes: LoteListarMapaDto[];
  mapa: MapaDto;
  parcelamentoValorMinimo: number;
  parcelamentoMaximoSemJuros: number;
  parcelamentoMaximo: number;
  valorTotalMinimo: number;
}

export type ProjetoState = EntityState<Partial<ProjetoStateDto>>

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'projetos',
  resettable: true
})
export class ProjetoStore extends EntityStore<ProjetoState> {
  constructor() {
    super();
  }
}
