import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { Observable, from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(public auth: AngularFireAuth) { }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.getCurrentIdToken()).pipe(
      mergeMap(token => {
        if (!request.url.includes('http')) {
          const baseConfig = {
            url: environment.api.baseUrl + request.url,
          };
          if (token) {
            baseConfig['setHeaders'] = {
              Authorization: `Bearer ${token}`
            };
          }
          request = request.clone(baseConfig);
        }
        return next.handle(request);
      })
    )
  }

  private getCurrentIdToken(): Promise<unknown> {
    return new Promise((resolve, reject) => {
      const auth = firebase.auth();
      const unsubscribe = auth.onIdTokenChanged(user => {
        unsubscribe();
        if (user) {
          user.getIdToken().then(token => {
            resolve(token);
          });
        } else {
          resolve(null);
        }
      }, reject);
    });
  }
}
