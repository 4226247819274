<ion-content class="ion-padding">
  <section class="contrato-selecionar-tipo-assinatura">

    <usucampeao-header title="Assinaturas de contrato"></usucampeao-header>

    <div class="contrato-selecionar-tipo-assinatura__content">
      <div class="contrato-selecionar-tipo-assinatura__types">
        <contrato-tipo-assinatura *ngIf="mostrarAssinaturaDigital" titulo="Confirmação por link via email ou whatsapp"
          descricao1="Clique no botão abaixo para enviar um link de confirmação do aceite via email e whatsapp cadastrados."
          descricao2="Você deve clicar no link para confirmar o aceite da contratação."
          imageUrl="../../../../assets/images/link.svg">

          <ion-button id="selecionar-tipo-assinatura-fisico" mode="md"
            (click)="verificarSeTipoPorLinkESelecionarTipoAssinatura(cadastroContratoTipoAssinatura.LINK)">
            Enviar contrato e link
          </ion-button>
        </contrato-tipo-assinatura>

        <contrato-tipo-assinatura titulo="Fotografia do contrato físico assinado"
          descricao1="Tire ou faça o upload de uma foto do contrato físico de prestação de serviço assinado."
          imageUrl="../../../../assets/images/contrato_fisico.svg">

          <ion-button id="selecionar-tipo-assinatura-fisico" mode="md"
            (click)="verificarSeTipoPorLinkESelecionarTipoAssinatura(cadastroContratoTipoAssinatura.FISICO)">
            Tirar foto de contrato
          </ion-button>
        </contrato-tipo-assinatura>
      </div>

      <div class="contrato-selecionar-tipo-assinatura__actions">
        <ion-button id="selecionar-tipo-assinatura-revisar-formas-pagamento" mode="md" fill="outline"
          (click)="goTo('../selecionar-forma-pagamento')">
          Revisar Formas de pagamento
        </ion-button>
      </div>
    </div>
  </section>
</ion-content>
