<ion-text class="font-text">
  <p>
    Olá, {{ nomeUsuario$ | async }}
  </p>
  <p>
    A <strong>parcela {{ parcelaFormatada }}</strong> do processo de regularização do imóvel localizado em
    {{ endereco?.rua }} {{ endereco?.numero }}, {{ endereco?.bairro }}, {{ endereco?.cidade }},
    <strong>vence amanhã.</strong> O boleto possui o valor de
    <strong>{{ notificacaoSelecionada?.payload?.valorDevido | currency:'BRL' }}</strong>
  </p>
  <p>
    <a class="text-link" (click)="navegarParaPagamento()">Clique aqui</a> para navegar diretamente para o cadastro e
    acessar o boleto.
  </p>
  <p>
    Se você já realizou o pagamento deste boleto, pode desconsiderar esta mensagem.
  </p>
  <p>
    Você pode pagar este boleto através do aplicativo do seu banco, internet banking, em casas lotéricas, agências
    bancárias ou em caixas eletrônicos.
  </p>
</ion-text>
