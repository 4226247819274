import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { InputModule } from '../../forms';
import { ModalEditarInfosContatoComponent } from './modal-editar-infos-contato.component';

@NgModule({
  declarations: [
    ModalEditarInfosContatoComponent,
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,
    ReactiveFormsModule,

    // components
    InputModule,
  ],
  exports: [ModalEditarInfosContatoComponent],
})
export class ModalEditarInfosContatoModule { }
