import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
  GetUpdatePropertyAdditionalInformationsDto,
  PropertyFillInStatusDto,
  StatusPreenchimento
} from '@usucampeao/lib-reurb-simplificado';
import { AbstractForm } from '@usucampeao/utils-frontend';
import { ImovelInfosAdicionaisFormComponent } from '../../../../../components';

@Component({
  selector: 'usucampeao-imoveis-editar-infos-adicionais-form',
  templateUrl: './imoveis-editar-infos-adicionais-form.component.html',
  styleUrls: ['./imoveis-editar-infos-adicionais-form.component.scss']
})
export class ImoveisEditarInfosAdicionaisFormComponent extends AbstractForm implements OnInit {
  @ViewChild('informacoesAdicionaisForm', { static: true }) informacoesAdicionaisForm: ImovelInfosAdicionaisFormComponent;
  @Input() set informacoesAdicionais(informacoesAdicionais: GetUpdatePropertyAdditionalInformationsDto) {
    this.preencherForm(informacoesAdicionais);
  }
  @Input() set status(status: PropertyFillInStatusDto) {
    this.statusPreenchimento = status?.additionalInformationsData || StatusPreenchimento.PENDENTE;
  }
  @Input() formDesabilitado = false;
  @Input() accordionEstaAberto = false;

  @Output() aoSubmeter = new EventEmitter<GetUpdatePropertyAdditionalInformationsDto>();

  public statusPreenchimento: StatusPreenchimento;

  ngOnInit(): void {
    this.form = this.informacoesAdicionaisForm.buildFormGroup();
  }

  private preencherForm(informacoesAdicionais: GetUpdatePropertyAdditionalInformationsDto): void {
    this.form?.patchValue({
      usage: informacoesAdicionais?.usage || null,
      occupation: informacoesAdicionais?.occupation || null,
      situation: informacoesAdicionais?.situation || null,
      loteArea: informacoesAdicionais?.loteArea || null,
      possuiOutroImovel: informacoesAdicionais?.possuiOutroImovel ?? null
    });
  }

  public submit(): void {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }

    this.atualizarInformacoesAdicionais();
  }

  private atualizarInformacoesAdicionais(): void {
    const informacoesAdicionais: GetUpdatePropertyAdditionalInformationsDto = Object.assign({}, this.form.value);
    informacoesAdicionais.loteArea = informacoesAdicionais.loteArea ? Number(informacoesAdicionais.loteArea) : null;
    this.aoSubmeter.emit(informacoesAdicionais);
    this.alterarAberturaAccordion(false);
  }

  public alterarAberturaAccordion(estaAberto: boolean): void {
    this.accordionEstaAberto = estaAberto;
  }
}
