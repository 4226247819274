import { Polygon } from 'geojson';
import { LoteSituacao } from './lote-situacao.enum';

export class LoteListarSimplificadoDto {
  id: number;
  label: string;
  quadraId: number;
}

export class LoteListarMapaDto {
  id: number;
  label: string;
  quadra: string;
  quadra_id: number;
  coords: Polygon;
  situacao: LoteSituacao;
}
