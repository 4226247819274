import { Pipe, PipeTransform } from '@angular/core';
import { PropertySituation } from '@usucampeao/lib-reurb-simplificado';

@Pipe({
  name: 'imovelSituacao'
})
export class ImovelSituacaoPipe implements PipeTransform {

  transform(value: PropertySituation): string {
    switch (value) {
      case PropertySituation.BUILT:
        return 'Edificado';

      case PropertySituation.NO_BUILDING:
        return 'Sem edificação';

      case PropertySituation.UNDER_CONSTRUCTION:
        return 'Em construção';

      default:
        return value;
    }
  }

}
