import { Component, Inject, Input } from '@angular/core';
import { IMAGES_PATH } from '@usucampeao/utils-frontend';
import { UploadFotoComponent } from '../upload-foto.component';

@Component({
  selector: 'usucampeao-upload-foto-imovel',
  templateUrl: './upload-foto-imovel.component.html',
  styleUrls: ['./upload-foto-imovel.component.scss']
})
export class UploadFotoImovelComponent extends UploadFotoComponent {
  @Input() mostrarBotaoTrocarFoto = true;
  @Input() isV2 = false;

  constructor(@Inject(IMAGES_PATH) protected imagesPath: string) {
    super();
    this.podeEnviarVariosArquivos = false;
  }

  public get imagemDefault(): string {
    return `assets/images/placeholder.png`;
  }

  public get imagemCarregando(): string {
    return `assets/images/defaultImage.svg`;
  }
}
