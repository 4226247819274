/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { PipesModule } from '@usucampeao/utils-frontend';
import { NgxMaskModule } from 'ngx-mask';
import { FooterModule, HeaderModule, InputModule, SelectModule } from '../../../components';
import { CadastroResumoPagamentoModule } from '../../../components/cadastro-resumo-pagamento/cadastro-resumo-pagamento.module';
import { TextareaModule } from '../../../components/forms/textarea/textarea.module';
import { ContratoConfirmarInformacoesComponent } from './contrato-confirmar-informacoes.component';

@NgModule({
  declarations: [
    ContratoConfirmarInformacoesComponent,
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,

    // components
    CadastroResumoPagamentoModule,
    FooterModule,
    HeaderModule,
    InputModule,
    SelectModule,
    TextareaModule,

    // external libs
    NgxMaskModule.forRoot(),

    // others
    PipesModule,
  ],
  exports: [
    ContratoConfirmarInformacoesComponent,
  ],
})
export class ContratoConfirmarInformacoesModule { }
