<div class="c-resumo-pagamento ion-margin-top" [class.c-resumo-pagamento--no-padding]="semPadding" *ngIf="pagamentoSelecionado">
  <div class="c-resumo-pagamento__flex c-resumo-pagamento__flex--space-between">
    <ion-text>
      <p class="description-text">Entrada de</p>
    </ion-text>
    <ion-text class="c-resumo-pagamento__container-desconto">
      <p class="c-resumo-pagamento__valor-desconto c-resumo-pagamento__valor-riscado" *ngIf="descontoNaEntrada">
        {{ pagamentoSelecionado?.valorAtoSemDesconto | currency: 'BRL' }}
      </p>
      <p class="title">{{ valorEntrada | currency: 'BRL' }}</p>
    </ion-text>
  </div>

  <div class="c-resumo-pagamento__flex c-resumo-pagamento__flex--space-between" *ngIf="!pagamentoAVista">
    <ion-text>
      <p class="description-text">{{ pagamentoSelecionado?.parcela | parcela }} de</p>
    </ion-text>
    <ion-text class="c-resumo-pagamento__container-desconto">
      <p class="c-resumo-pagamento__valor-desconto c-resumo-pagamento__valor-riscado" *ngIf="descontoNoServico">
        {{ pagamentoSelecionado?.valorParcelaSemDesconto | currency: 'BRL' }}
      </p>
      <p class="title">{{ pagamentoSelecionado?.valorParcela | currency: 'BRL' }}</p>
    </ion-text>
  </div>

  <div class="c-resumo-pagamento__divider"></div>

  <ng-container *ngIf="cadastro?.cupomDescontoCodigo">
    <div class="c-resumo-pagamento__flex c-resumo-pagamento__flex--space-between">
      <ion-text>
        <p class="description-text">Subtotal</p>
      </ion-text>
      <ion-text>
        <p class="c-resumo-pagamento__valor-riscado">
          {{ pagamentoSelecionado?.valorTotalSemDesconto | currency: 'BRL' }}
        </p>
      </ion-text>
    </div>

    <div class="c-resumo-pagamento__flex c-resumo-pagamento__flex--space-between">
      <ion-text>
        <p class="font-text font-text--small font-text--success">Descontos</p>
      </ion-text>
      <ion-text>
        <p class="font-text font-text--bold font-text--success">
          {{ valorDesconto | currency: 'BRL' }}
        </p>
      </ion-text>
    </div>
  </ng-container>

  <div class="c-resumo-pagamento__flex c-resumo-pagamento__flex--space-between">
    <ion-text>
      <p class="description-text">Total</p>
    </ion-text>
    <ion-text>
      <p class="title">{{ valorTotal | currency: 'BRL' }}</p>
    </ion-text>
  </div>
</div>
