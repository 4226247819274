export class LoteDto {
  integracaoId: number;
  label: string;
  quadra: string;
}

export interface LoteDuplicado {
  quadra: string;
  lote: string;
}
