<div id="print-section" class="c-data-contract__text-terms">
  <div class="title">
    <h1><strong>PROCURAÇÃO</strong></h1>
  </div>
  <div class="personal-data">
    <table class="contract-table">
      <tr>
        <td>
          <p>
            <strong>OUTORGANTE 1:</strong> {{ proprietario?.name }}, nacionalidade
            <strong>brasileiro(a),</strong> natural de {{ proprietario?.naturalidade || '' }}, portador <strong>CPF
              nº</strong> {{
            proprietario?.cpf }}, e identidade {{ proprietario?.rg?.number || '' }}, domiciliado e residente à {{
            imovel?.address?.street
            }}, {{ imovel?.address?.number }}, {{imovel?.address?.zipCode}}, {{imovel?.address?.city}}, telefone:
            {{ proprietario?.phones?.cellphone | mask: '(00) 00000-0000' }}, E-mail: {{proprietario?.email}}
          </p>
        </td>
      </tr>
      <ng-container *ngIf="mostrarDadosConjuge">
        <tr>
          <td>
            <p>
              <strong>OUTORGANTE 2:</strong> {{ conjuge?.name }},
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p>
              <strong>brasileira,</strong> natural de {{ conjuge?.naturalidade || '' }}, portador <strong>CPF
                nº</strong> {{
              conjuge?.cpf }}, e identidade {{ conjuge?.rg?.number || '' }}, domiciliado e residente à {{
              imovel?.address?.street }}, {{ imovel?.address?.number
              }}, {{imovel?.address?.zipCode}}, {{imovel?.address?.city}} telefone: {{conjuge?.phones?.cellphone}},
              E-mail: {{conjuge?.email}}
            </p>
          </td>
        </tr>
      </ng-container>
      <tr>
        <td>
          <p>
            <strong>OUTORGADO:</strong> USU CAMPEAO TECNOLOGIA EM REGULARIZACAO IMOBILIARIA LTDA, devidamente inscrita
            no CNPJ/MF
            sob o nº 36.174.049/0001-08, com sede na Rua Cláudio Soares, 72, CJ 615, bairro Pinheiros, CEP 05422-030, na
            cidade de
            São Paulo, Estado de São Paulo, e filial a Rua José Rodrigues Pereira 524, Salas 204-208, Condomínio
            Datasinc, Buritis, Belo Horizonte – MG, com endereço eletrônico contato@usucampeao.com.br, telefone (48)
            99926-0832.
          </p>
        </td>
      </tr>
      <tr>
        <td>
          <p>
            <strong>PODERES:</strong> para representar o(s) outorgante(s) e defender seus interesses, com os poderes
            especiais
            para nos termos da Lei nº 13.465/17, propor, requerer e assinar pedidos de declaração de existência de
            edificação perante
            a Prefeitura do Município, e requerer, perante o Cartório de Registro de
            Imóveis, a averbação da construção nos termos da lei 13.465/17 referente ao imóvel localizado no cadastro
            anexo deste mandato,
            podendo ainda, apresentar e realizar defesas e recursos perante os mesmos órgãos, acompanhando os processos
            em todos os seus termos e instâncias administrativas com o fito do bom e fiel cumprimento do presente
            mandato, podendo
            substabelecer com ou sem reserva de iguais poderes e, ainda, usar de todos os meios admitidos em direito,
            para o bom e fiel cumprimento do presente mandato.
          </p>
        </td>
      </tr>
      <tr>
        <td>
          <p>
            {{ cadastro?.projetoMunicipio }}, {{cadastro?.contractDate | date: 'longDate'}}.
          </p>
        </td>
      </tr>
      <ng-container *ngIf="isPrint">
        <tr>
          <td>
            <p class="margin-top">
              __________________________________________________________________________________
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p>
              OUTORGANTE 1
            </p>
          </td>
        </tr>
        <ng-container *ngIf="conjuge">
          <tr>
            <td>
              <p class="margin-top">
                __________________________________________________________________________________
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                OUTORGANTE 2
              </p>
            </td>
          </tr>
        </ng-container>
      </ng-container>
    </table>
  </div>
