<ion-content class="ion-padding">
  <ion-refresher slot="fixed" (ionRefresh)="carregarProprietario($event)">
    <ion-refresher-content>
    </ion-refresher-content>
  </ion-refresher>

  <usucampeao-proprietarios-ficha-v2 [proprietarios]="proprietarios$ | async"
    (aoAdicionarConjuge)="adicionarConjuge($event)" (aoRemoverConjuge)="removerConjuge($event)">
  </usucampeao-proprietarios-ficha-v2>
</ion-content>
