import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent, LoadingService, ProprietarioFichaDto, ToastService } from '@usucampeao/ui-mobile';
import { Observable } from 'rxjs';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { ProprietariosQuery } from './state/proprietarios.query';
import { ProprietariosService } from './state/proprietarios.service';

@Component({
  templateUrl: './proprietarios.page.html',
  styleUrls: ['./proprietarios.page.scss']
})
export class ProprietariosPage extends BaseComponent implements OnInit {
  private cadastroId: string;
  public proprietarios$: Observable<ProprietarioFichaDto[]>;

  constructor(
    private proprietariosQuery: ProprietariosQuery,
    private proprietariosService: ProprietariosService,
    private loadingService: LoadingService,
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.route.params
      .pipe(
        tap(params => this.cadastroId = params.cadastroId),
        tap(() => this.carregarProprietario()),
        tap(() => this.proprietarios$ = this.proprietariosQuery.buscarFichaPorCadastroId(this.cadastroId)),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe();
  }

  public async carregarProprietario(event?: any): Promise<void> {
    if (!event) {
      await this.loadingService.createLoader();
    }

    this.proprietariosService.buscarPorCadastroId(this.cadastroId)
      .pipe(
        catchError(() => this.toastService.error('Erro ao buscar dados dos proprietários. Por favor tente novamente.')),
        finalize(async () => event ? event.target.complete() : await this.loadingService.dismiss()),
      )
      .subscribe();
  }

  /**
   * Adiciona cônjuge ao proprietário.
   * @param proprietarioId ID do proprietário
   */
  public adicionarConjuge(proprietarioId: string): void {
    this.proprietariosService.adicionarConjuge(this.cadastroId, proprietarioId)
      .pipe(
        tap(proprietarios => {
          const conjuge = proprietarios.find(proprietario => !proprietario.main);
          this.router.navigate([conjuge.id, 'editar'], { relativeTo: this.route });
        }),
        catchError(() => this.toastService.error('Erro ao adicionar cônjuge. Por favor tente novamente.')),
      )
      .subscribe();
  }

  /**
   * Removem o cônjuge do proprietário.
   * @param proprietarioId ID do proprietário
   */
  public async removerConjuge(proprietarioId: string): Promise<void> {
    await this.loadingService.createLoader();
    this.proprietariosService.removerConjuge(this.cadastroId, proprietarioId)
      .pipe(
        catchError(() => this.toastService.error('Erro ao remover o cônjuge. Por favor tente novamente.')),
        finalize(() => this.loadingService.dismiss()),
      )
      .subscribe();
  }
}
