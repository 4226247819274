import { DataFilter } from '@usucampeao/interfaces';
import { SearchProps } from '@usucampeao/utils';
import { Expose, plainToInstance } from 'class-transformer';
import { RevisaoDto } from './revisao.dto';
import { StatusRevisao } from './status-revisao.enum';

export interface RevisaoSearchParams extends SearchProps {
  status?: StatusRevisao[] | null;
  cidades?: number[] | null;
  vendedores?: string[] | null;
  projetos?: string[] | null;
  revisaoEmAberto?: boolean;
  buscarCidades?: boolean;
  buscarProjetos?: boolean;
  buscarVendedores?: boolean;
}

export type RevisaoSearchMetadata = {
  cidades?: DataFilter<number>[];
  vendedores?: DataFilter[];
  projetos?: DataFilter[];
}

export class RevisaoListarDto {
  @Expose()
  id: string;

  @Expose()
  usuarioResponsavelId?: string;

  @Expose()
  usuarioResponsavelNome?: string;

  @Expose()
  cidade: string;

  @Expose()
  cidadeId: number;

  @Expose()
  vendedor: string;

  @Expose()
  vendedorId: string;

  @Expose()
  proprietario: string;

  @Expose()
  projeto: string;

  @Expose()
  projetoId: string;

  @Expose()
  status: StatusRevisao;

  @Expose()
  dataFim?: Date;

  /**
   * Cria uma nova instância de RevisaoListarDto a partir de dtos
   * @param props DTO de Revisão
   * @returns nova instância de RevisaoListarDto
   */
  static from(props?: Partial<RevisaoDto>): RevisaoListarDto {
    return plainToInstance(RevisaoListarDto, props, { exposeUnsetFields: false, excludeExtraneousValues: true });
  }
}
