import { PagamentoStatus } from '../../payment/pagamento-status.enum';
import { Bs2BoletoStatus } from './bs2-boleto-status.enum';

export class Bs2BoletoDto {
  id: string;
  nossoNumero: number;
  cliente: string;
  clienteDocumento: string;
  valor: number;
  dataVencimento: Date;
  linhaDigitavel: string;
  codigoDeBarras: string;
  pagamentoStatus: PagamentoStatus;
  status: Bs2BoletoStatus;
  dataEmissao: Date;
  dataPagamento?: Date;
  valorPago?: number;
  pagamentoId?: string;

  constructor(
    id: string,
    nossoNumero: number,
    cliente: string,
    clienteDocumento: string,
    valor: number,
    dataVencimento: Date,
    linhaDigitavel: string,
    codigoDeBarras: string,
    pagamentoStatus: PagamentoStatus,
    status: Bs2BoletoStatus,
    dataEmissao: Date,
    dataPagamento?: Date,
    valorPago?: number
  ) {
    this.id = id;
    this.nossoNumero = nossoNumero;
    this.cliente = cliente;
    this.clienteDocumento = clienteDocumento;
    this.valor = valor;
    this.dataVencimento = dataVencimento;
    this.linhaDigitavel = linhaDigitavel;
    this.codigoDeBarras = codigoDeBarras;
    this.pagamentoStatus = pagamentoStatus;
    this.status = status;
    this.dataEmissao = dataEmissao;
    this.dataPagamento = dataPagamento;
    this.valorPago = valorPago;
  }
}
