<section class="projetos-l content-vertical-space-between">
  <div class="projetos-l__content">
    <usucampeao-header title="Escolha o seu bairro"></usucampeao-header>

    <ion-text class="font-text ion-no-margin">
      <ng-content select="[description]"></ng-content>
    </ion-text>

    <ng-container *ngIf="!localizacaoEncontrada">
      <ion-text>
        <h3 class="font-text font-text--medium font-text-bold">Encontre o seu bairro</h3>
      </ion-text>

      <ion-button class="projetos-l__botao-localizacao" mode="md" fill="outline" (click)="buscarLocalizacaoUsuario()">
        <ion-icon slot="start" name="location-outline"></ion-icon>
        Usar localização atual
      </ion-button>

      <ion-text>
        <p class="font-text">ou use o CEP da sua residência</p>
      </ion-text>

      <form [formGroup]="form" (ngSubmit)="submitForm()">
        <usucampeao-input controlName="cep" label="CEP" type="tel" mask="00000-000" [suffixCenter]="true"
          [readonly]="isLoading">
          <ion-button *ngIf="!isLoading" usu-suffix fill="clear" color="medium" (click)="submitForm()">
            <ion-icon slot="icon-only" name="search-outline"></ion-icon>
          </ion-button>

          <ion-spinner *ngIf="isLoading" usu-suffix name="circular"></ion-spinner>

          <span usu-error>{{ gerarMensagemErro('cep')}}</span>
        </usucampeao-input>
      </form>
    </ng-container>

    <ng-container *ngIf="localizacaoEncontrada">
      <usucampeao-localizacao-container [localizacao]="localizacaoEncontrada" [edicaoDesabilitada]="isLoading"
        (aoAlterarLocalizacao)="alterarLocalizacao()"></usucampeao-localizacao-container>

      <div *ngIf="!projetosMenos2Km.length && !projetosMais2Km.length && !projetos?.length" class="projetos-l__list">
        <ion-text>
          <p class="description-text ion-no-margin ion-margin-top">
            Infelizmente ainda não estamos em nenhum bairro próximo ao endereço informado. Mas você pode ver todos os
            bairros em que atuamos clicando no botão abaixo.
          </p>
        </ion-text>
      </div>

      <div *ngIf="projetosMenos2Km.length > 0" class="projetos-l__list">
        <ion-text>
          <h3 class="font-text font-text--medium font-text-bold">Seu imóvel pode estar nestes bairros</h3>
        </ion-text>

        <ion-list>
          <ng-container *ngFor="let projeto of projetosMenos2Km">
            <usucampeao-projeto-listagem-item [projeto]="projeto" [mostrarLabelCompativel]="true"
              (aoSelecionarProjeto)="selecionarProjeto($event)">
            </usucampeao-projeto-listagem-item>
          </ng-container>
        </ion-list>
      </div>

      <div *ngIf="projetosMais2Km.length > 0" class="projetos-l__list">
        <ion-text>
          <h3 class="font-text font-text--medium font-text-bold ion-margin-top">Bairros próximos a você</h3>
        </ion-text>

        <ion-list>
          <ng-container *ngFor="let projeto of projetosMais2Km">
            <usucampeao-projeto-listagem-item [projeto]="projeto" (aoSelecionarProjeto)="selecionarProjeto($event)">
            </usucampeao-projeto-listagem-item>
          </ng-container>
        </ion-list>
      </div>
    </ng-container>

    <div *ngIf="projetos.length > 0" class="projetos-l__list">
      <ion-text>
        <h3 class="font-text font-text--medium font-text-bold">Todos os bairros disponíveis</h3>
      </ion-text>

      <ion-list>
        <ng-container *ngFor="let projeto of projetos">
          <usucampeao-projeto-listagem-item [projeto]="projeto" (aoSelecionarProjeto)="selecionarProjeto($event)">
          </usucampeao-projeto-listagem-item>
        </ng-container>
      </ion-list>
      <ion-infinite-scroll (ionInfinite)="carregarNovaPaginaProjetos($event)">
        <ion-infinite-scroll-content></ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </div>

    <span *ngIf="!projetos?.length" class="text-link text-link--primary" (click)="buscarTodosOsProjetos()">
      Ver todos os bairros disponíveis
    </span>
  </div>

  <usucampeao-footer></usucampeao-footer>
</section>
