import { UsuarioBackofficeDto } from '../usuario-backoffice/usuario-backoffice.dto';

export enum UsuarioBackofficeHistoricoTipo {
  USUARIO_CRIAR = 'USUARIO_CRIAR',
  USUARIO_EDITAR = 'USUARIO_EDITAR',
  USUARIO_ATIVAR = 'USUARIO_ATIVAR',
  USUARIO_DESATIVAR = 'USUARIO_DESATIVAR',
}

export class UsuarioBackofficeHistoricoDto {
  id: string;
  tipo: UsuarioBackofficeHistoricoTipo;
  usuarioAlteracaoId: string;
  dataModificacao: Date;
}

export class UsuarioBackofficeHistoricoCriarDto {
  tipo: UsuarioBackofficeHistoricoTipo;
  dataModificacao?: Date;
  payload?: Partial<UsuarioBackofficeDto>;
}
