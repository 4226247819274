import { DataFilter, UF } from '@usucampeao/interfaces';
import { SearchProps } from '@usucampeao/utils';
import { Expose, plainToInstance } from 'class-transformer';
import { ProjetoModalidadeType } from './projeto-modalidade.enum';
import { ProjetoStatus } from './projeto-status.enum';
import { ProjetoTipo } from './projeto-tipo.enum';
import { ProjetoVendaStatus } from './projeto-venda-status.enum';
import { ProjetoDto } from './projeto.dto';

export enum ProjetoSortProps {
  NOME = 'nome',
  CIDADE = 'cidade',
  DATA_CRIACAO = 'data_criacao',
  STATUS = 'venda_status'
}

export interface ProjetoSearchParams extends SearchProps {
  status?: ProjetoVendaStatus[];
  cidades?: number[];
  tipos?: ProjetoTipo[];
}

export type ProjetoSearchMetadata = {
  cidades?: DataFilter<number>[];
  status?: DataFilter<ProjetoVendaStatus>[];
  tipos?: DataFilter<ProjetoTipo>[];
}

export class ProjetoListarDto {
  @Expose()
  id: string;

  @Expose()
  nome: string;

  @Expose()
  cidadeId?: number;

  @Expose()
  cidadeNome: string;

  @Expose()
  estadoNome: UF;

  @Expose()
  nucleoId: number;

  @Expose()
  loteQuantidade: number;

  @Expose()
  vendaQuantidade: number;

  @Expose()
  status: ProjetoStatus;

  @Expose()
  vendaStatus: ProjetoVendaStatus;

  @Expose()
  tipo: ProjetoTipo;

  @Expose()
  averbacao?: boolean;

  @Expose()
  modalidade?: ProjetoModalidadeType;

  @Expose()
  favorito: boolean;

  /**
   * Cria uma nova instância de ProjetoListarDto a partir de dtos
   * @param props DTO de ProjetoListarDto
   * @returns nova instância de ProjetoListarDto
   */
  static from(props?: ProjetoDto | Partial<ProjetoDto>): ProjetoListarDto {
    return plainToInstance(ProjetoListarDto, props, { exposeUnsetFields: false, excludeExtraneousValues: true });
  }
}

export type ProjetosListarComDistancia = {
  projeto_id: string;
  nome: string;
  nucleo_distancia: number;
  cidade: string;
  estado: string;
}
