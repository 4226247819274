import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { FooterModule, HeaderModule, InputModule, LocalizacaoContainerModule } from '../../../components';
import { ProjetoListagemItemModule } from '../../../components/projeto-listagem-item/projeto-listagem-item.module';
import { ProjetosListarProximosPage } from './projetos-listar-proximos.page';

@NgModule({
  declarations: [
    ProjetosListarProximosPage
  ],
  imports: [
    // root
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,

    // components
    HeaderModule,
    InputModule,
    LocalizacaoContainerModule,
    ProjetoListagemItemModule,

    // ModalProjetoNaoEncontradoModule,
    FooterModule,
  ],
  exports: [
    ProjetosListarProximosPage,
  ]
})
export class ProjetosListarProximosPageModule { }
