import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  NotificacaoSimplificadaDto,
  StatusNotificacao
} from '@usucampeao/lib-reurb-simplificado';
import { BaseComponent, LoadingService, NavigationService, ToastService } from '@usucampeao/ui-mobile';
import { catchError, finalize, takeUntil } from 'rxjs/operators';
import { NotificationsQuery } from './state/notifications.query';
import { NotificationsService } from './state/notifications.service';
@Component({
  selector: 'usucampeao-notification',
  templateUrl: 'notification.page.html',
  styleUrls: ['notification.page.scss'],
})
export class NotificationPage extends BaseComponent implements OnInit {
  public notifications$ = this.notificationsQuery.selectAll();

  constructor(
    private loadingService: LoadingService,
    private navigationService: NavigationService,
    private notificationsService: NotificationsService,
    private notificationsQuery: NotificationsQuery,
    public router: Router,
    private toastService: ToastService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.carregarNotificacoes();
  }

  public get statusNotificacao(): typeof StatusNotificacao {
    return StatusNotificacao;
  }

  public async carregarNotificacoes(event?: any): Promise<void> {
    if (!event) {
      await this.loadingService.createLoader();
    }

    this.notificationsService
      .listarNotificacaoesDoUsuario()
      .pipe(
        catchError(error => {
          this.toastService.error('Erro ao buscar notificações. Tente novamente mais tarde.');
          throw error;
        }),
        finalize(() => event ? event.target.complete() : this.loadingService.dismiss()),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe();
  }

  public voltar(): void {
    this.navigationService.voltar();
  }

  public irParaDetalhesDaNotificacao(notification: NotificacaoSimplificadaDto): void {
    this.router.navigate([`/notificacoes`, notification.id]);
  }
}
