import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { ProjetoListagemItemComponent } from './projeto-listagem-item.component';

@NgModule({
  declarations: [
    ProjetoListagemItemComponent
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,
  ],
  exports: [
    ProjetoListagemItemComponent
  ],
})
export class ProjetoListagemItemModule { }
