import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { InputModule } from '../input/input.module';
import { SelectModule } from '../select/select.module';
import { EnderecoFormComponent } from './endereco-form.component';

@NgModule({
  declarations: [
    EnderecoFormComponent
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,
    ReactiveFormsModule,

    // components
    InputModule,
    SelectModule,
  ],
  exports: [
    EnderecoFormComponent
  ]
})
export class EnderecoFormModule { }
