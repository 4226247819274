import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController, ModalController, ViewWillLeave } from '@ionic/angular';
import { ModalTermosUsoComponent } from '@usucampeao/ui-mobile';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AuthQuery } from '../../pages/auth/state/auth.query';
import { AuthService } from '../../pages/auth/state/auth.service';
import { ModalPoliticaPrivacidadeComponent } from '../../shared/components/modal-politica-privacidade/modal-politica-privacidade.component';
import { NotificationsQuery } from './../../pages/notifications/state/notifications.query';

@Component({
  selector: 'usucampeao-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit, OnDestroy, ViewWillLeave {
  private ngUnsubscribe = new Subject();
  public notifications$ = this.notificationsQuery.selectAll();
  public totalNotificacoesNaoLidas$ = this.notificationsQuery.quantidadeNotificacoesNaoLidas$;

  constructor(
    private authQuery: AuthQuery,
    private modalCtrl: ModalController,
    private notificationsQuery: NotificationsQuery,
    private router: Router,
    private authService: AuthService,
    private menuCtrl: MenuController,
  ) { }

  ngOnInit(): void {
    this.authQuery.possuiToken$
      .pipe(
        filter(possuiToken => possuiToken),
        // switchMap(() => this.notificationsService.getNotifications()),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe();
  }

  ionViewWillLeave(): void {
    this.ngUnsubscribe.next();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.complete();
  }

  public close(): void {
    this.menuCtrl.close();
  }

  public closeAndNavigate(route: string): void {
    this.close();
    this.router.navigate([route]);
  }

  public logout(): void {
    this.close();
    this.authService.logout();
  }

  public async abrirModalPoliticaPrivacidade() {
    const modal = await this.modalCtrl.create({
      component: ModalPoliticaPrivacidadeComponent,
    });
    return await modal.present();
  }

  public async abrirModalTermosDeUso() {
    const modal = await this.modalCtrl.create({
      component: ModalTermosUsoComponent,
    });
    return await modal.present();
  }
}
