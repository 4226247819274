<usucampeao-accordion titulo="Dados pessoais" [maxHeight]="1100" [estaAberto]="accordionEstaAberto" (aoAlterarAberturaAccordion)="alterarAberturaAccordion($event)">
  <usucampeao-status-preenchimento extras [status]="statusPreenchimento"></usucampeao-status-preenchimento>

  <form [formGroup]="form" (ngSubmit)="submit()">
    <usucampeao-input label="Nome completo*" controlName="name" [readonly]="formDesabilitado">
      <span usu-error>{{ gerarMensagemErro('name') }}</span>
    </usucampeao-input>

    <usucampeao-select label="Gênero*" controlName="gender" [disabled]="formDesabilitado">
      <ion-select-option *ngFor="let genero of generos" [value]="genero">{{ genero | genero }}</ion-select-option>

      <span usu-error>{{ gerarMensagemErro('gender') }}</span>
    </usucampeao-select>

    <usucampeao-datepicker label="Data de nascimento*" formControlName="birthDate"
      [maxDate]="dataMaximaCasamentoNascimento" [disabled]="formDesabilitado">
      <span usu-error>{{ gerarMensagemErro('birthDate') }}</span>
    </usucampeao-datepicker>

    <usucampeao-select label="Nacionalidade*" controlName="nationality" [disabled]="formDesabilitado">
      <ion-select-option *ngFor="let nacionalidade of nacionalidades" [value]="nacionalidade">{{ nacionalidade
        }}</ion-select-option>

      <span usu-error>{{ gerarMensagemErro('nationality') }}</span>
    </usucampeao-select>

    <ng-container *ngIf="mostrarDadosNaturalidade">
      <usucampeao-select label="Estado*" controlName="state" [disabled]="formDesabilitado">
        <ion-select-option *ngFor="let estado of estados" [value]="estado">{{ estado }}</ion-select-option>

        <span usu-error>{{ gerarMensagemErro('state') }}</span>
      </usucampeao-select>

      <usucampeao-input label="Naturalidade*" controlName="naturalidade" [readonly]="formDesabilitado">
        <span usu-error>{{ gerarMensagemErro('naturalidade') }}</span>
      </usucampeao-input>
    </ng-container>

    <usucampeao-select label="Renda familiar aproximada*" controlName="rendaFamiliar"
      [disabled]="formDesabilitado || !proprietarioPrincipal">
      <ion-select-option *ngFor="let rendaFamiliar of rendasFamiliares" [value]="rendaFamiliar">{{ rendaFamiliar | rendaFamiliar }}</ion-select-option>

      <span usu-error>{{ gerarMensagemErro('rendaFamiliar') }}</span>
    </usucampeao-select>

    <usucampeao-input label="Profissão*" controlName="profession" [readonly]="formDesabilitado">
      <span usu-error>{{ gerarMensagemErro('profession') }}</span>
    </usucampeao-input>

    <usucampeao-select label="Estado Civil*" controlName="maritalStatus" [disabled]="dadosCasamentoDesabilitados">
      <ion-select-option *ngFor="let estadoCivil of estadosCivis" [value]="estadoCivil">{{ estadoCivil | estadoCivil
        }}</ion-select-option>

      <span usu-error>{{ gerarMensagemErro('maritalStatus') }}</span>
    </usucampeao-select>

    <ng-container *ngIf="mostrarDadosCasamento">
      <usucampeao-select label="Regime de bens*" controlName="matrimonialRegime"
        [disabled]="dadosCasamentoDesabilitados">
        <ion-select-option *ngFor="let regimeDeBens of regimesDeBens" [value]="regimeDeBens">
          {{ regimeDeBens | regimeDeBens }}
        </ion-select-option>

        <span usu-error>{{ gerarMensagemErro('matrimonialRegime') }}</span>
      </usucampeao-select>

      <usucampeao-datepicker label="Data de casamento*" formControlName="weddingDate"
        [maxDate]="dataMaximaCasamentoNascimento" [disabled]="dadosCasamentoDesabilitados">
        <span usu-error>{{ gerarMensagemErro('weddingDate') }}</span>
      </usucampeao-datepicker>
    </ng-container>

    <ion-button mode="md" (click)="submit()">Salvar e continuar</ion-button>
  </form>
</usucampeao-accordion>
