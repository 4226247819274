import {
  AfterViewInit, Component, EventEmitter, Inject, Input,
  OnChanges,
  Output, SimpleChanges, ViewChild
} from '@angular/core';
import { LoteListarMapaDto, MapaDto } from '@usucampeao/lib-reurb-simplificado';
import * as L from 'leaflet';
import { COR_LOTE, COR_LOTE_SELECIONADO } from '../mapa.component';
import { MAP_BOX_TOKEN } from '../mapa.module';

@Component({
  selector: 'usucampeao-mapa-mapbox',
  templateUrl: './mapa-mapbox.component.html',
  styleUrls: ['./mapa-mapbox.component.scss'],
})
export class MapaMapbox implements AfterViewInit, OnChanges {
  @ViewChild('map') mapContainer!: any;

  @Input() lotes: LoteListarMapaDto[];
  @Input() mapa: MapaDto;
  @Input() lat: number;
  @Input() lng: number;
  @Input() loteSelecionadoId: number;
  @Input() mostrarMarker = false;
  @Input() desabilitado = false;

  @Output() aoSelecionarLote = new EventEmitter<LoteListarMapaDto>();

  private map!: L.Map;
  private selectedEl!: any;
  private zoomInicial = 12;
  private marker!: L.Marker;

  constructor(
    @Inject(MAP_BOX_TOKEN) public mapboxToken: string,
  ) { }

  ngAfterViewInit(): void {
    this.gerarMapa();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { lat, lng } = changes;
    if (lat && lng && ((!lat.firstChange && !lng.firstChange) || this.mostrarMarker)) {
      this.setMapToCurrentPosition();
    }
  }

  /**
   * Inicializa o mapa
   */
  private gerarMapa(): void {
    this.map = L.map(this.mapContainer.nativeElement, {
      center: [this.lat, this.lng],
      trackResize: true,
      maxBounds: [
        [this.mapa.latitudeMaxima, this.mapa.longitudeMaxima],
        [this.mapa.latitudeMinima, this.mapa.longitudeMinima],
      ],
      zoom: this.zoomInicial,
      layers: [
        L.tileLayer(`https://api.mapbox.com/styles/v1/usucampeaoti/${this.mapa.idMapa}/tiles/256/{z}/{x}/{y}@2x?access_token=${this.mapboxToken}`, {
          attribution: 'Dados de mapa <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagens &copy; <a href="https://www.mapbox.com/">Mapbox</a>',
          minZoom: this.mapa.zoomMinimo,
          maxZoom: this.mapa.zoomMaximo,
        }),
      ],
    });

    this.loadLots();
    this.map.whenReady(() => {
      setTimeout(() => {
        this.map.invalidateSize();
      }, 500);
    });
  }

  private async setMapToCurrentPosition() {
    this.map.panTo(new L.LatLng(this.lat, this.lng));
    this.addMarkerToMap();
  }

  public clearMarker(): void {
    if (this.marker != undefined) {
      this.map.removeLayer(this.marker);
    }

    this.marker = undefined as any;
  }

  /**
   * Adiciona o marcador no mapa
   */
  private addMarkerToMap(): void {
    if (this.marker) {
      this.marker.setLatLng(new L.LatLng(this.lat, this.lng));
      return;
    }
    this.marker = L.marker([this.lat, this.lng], {
      icon: L.icon({
        iconSize: [25, 41],
        iconAnchor: [13, 41],
        iconUrl: 'assets/marker-icon.png',
      }),
    }).addTo(this.map);
  }

  /**
   * Carrega os polígonos dos lotes e adiciona no mapa
   */
  private loadLots(): void {
    for (const lote of this.lotes) {
      const primaryColor = COR_LOTE;
      const selectedColor = COR_LOTE_SELECIONADO;
      const options = {
        weight: 2,
        color: lote.id === this.loteSelecionadoId ? selectedColor : primaryColor,
      };

      const lotePolygon = L.polygon(lote.coords.coordinates as any, options);

      lotePolygon.addEventListener('add', (ev: L.LeafletEvent) => {
        if (lote.id === this.loteSelecionadoId) {
          this.selectedEl = ev.target;
          this.selectedEl.bringToFront();
        } else {
          ev.target.bringToBack();
        }
      });

      if (!this.desabilitado) {
        lotePolygon.addEventListener('click', (ev: L.LeafletEvent) => {
          this.loteSelecionadoId = lote.id;
          if (this.selectedEl) {
            this.selectedEl.setStyle({ color: primaryColor });
          }
          this.selectedEl = ev.target;
          this.selectedEl.setStyle({ color: selectedColor });
          this.selectedEl.bringToFront();
          this.aoSelecionarLote.emit(lote);
        });
      }

      lotePolygon.addTo(this.map);
      if (lote.id === this.loteSelecionadoId) {
        this.map.setView(lotePolygon.getBounds().getCenter(), 20);
      }
    }
  }
}
