import { PagamentoStatus } from '@usucampeao/interfaces';
import { Expose, plainToInstance } from 'class-transformer';
import { PagamentoDto } from './pagamento.dto';

export class PagamentoDadosFaturamentoDto {
  @Expose()
  id: string;

  @Expose()
  valorDevido: number;

  @Expose()
  valorPago?: number;

  @Expose()
  status: PagamentoStatus;

  @Expose()
  dataPagamento?: Date;

  @Expose()
  dataVencimento: Date;

  @Expose()
  parcela: number;

  @Expose()
  totalParcelas: number;

  @Expose()
  cadastroId: string;

  @Expose({ name: 'proprietarioId' })
  clienteId: string;

  @Expose()
  erpProjetoId: number;

  /**
   * Cria uma nova instância de PagamentoDadosFaturamentoDto a partir de dtos
   * @param props DTO de Pagamento
   * @param erpProjetoId ID do projeto do ERP.
   * @param clienteId ID do cliente do pagamento.
   * @returns nova instância de PagamentoDadosFaturamentoDto
   */
  static from(props: Partial<PagamentoDto>, erpProjetoId: number): PagamentoDadosFaturamentoDto {
    const dadosFaturamento = plainToInstance(PagamentoDadosFaturamentoDto, props, { exposeUnsetFields: false, excludeExtraneousValues: true });
    dadosFaturamento.erpProjetoId = erpProjetoId;
    return dadosFaturamento;
  }
}
