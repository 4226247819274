export enum ComissaoUsuarioTipo {
  COORDENADOR = 'COORDENADOR',
  SUPERVISOR = 'SUPERVISOR',
  VENDEDOR = 'VENDEDOR',
}

export enum ComissaoCadastroEvento {
  CRIAR_CADASTRO = 'CRIAR_CADASTRO',
  ENVIAR_DADOS_MINIMOS = 'UPLOAD_DADOS_MINIMOS',
  GERAR_CONTRATO = 'GERAR_CONTRATO',
  ASSINAR_CONTRATO = 'ASSINAR_CONTRATO',
  ENVIAR_DADOS_COMPLETOS = 'ENVIO_DADOS_COMPLETOS',
  ENVIAR_ANALISE = 'ENVIAR_ANALISE',
}
