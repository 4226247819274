import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FileDto } from '@usucampeao/lib-reurb-simplificado';
import { Observable } from 'rxjs';
import { Arquivo } from '../components/upload-foto/upload-foto.component';

const DOCUMENTO_STORAGE_KEY = 'DOCUMENTO_STORAGE_KEY';
@Injectable({
  providedIn: 'root'
})
export class DocumentoService {

  constructor(
    private http: HttpClient,
  ) { }

  /**
   * Busca os arquivos de um documento
   * @param documentoId ID do documento
   */
  public buscarArquivosDocumento(documentoId: string): Observable<FileDto[]> {
    return this.http.get<FileDto[]>(`/documents/${documentoId}/files`);
  }

  public salvarDocumentoStorage(arquivo: Arquivo): void {
    const arquivoString = JSON.stringify(arquivo);
    sessionStorage.setItem(DOCUMENTO_STORAGE_KEY, arquivoString);
  }

  public obterDocumentoStorage(): Arquivo | null {
    const arquivo = sessionStorage.getItem(DOCUMENTO_STORAGE_KEY);
    return arquivo ? JSON.parse(arquivo) : null;
  }

  public removerDocumentoStorage(): void {
    sessionStorage.removeItem(DOCUMENTO_STORAGE_KEY);
  }
}
