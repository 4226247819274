
import { StatusPreenchimento } from '../../enums';
import { PropertyDto } from '../property';
import { PropertyFillInStatusDto } from '../property/property-fill-in-status.dto';

import { PendingOwnerData } from './pending-owner-data.model';

export class PendingSections {
  ownersData: PendingOwnerData[];
  propertyData: PendingPropertyData;
  cadastroDocumentos: StatusPreenchimento;

  constructor(
    ownersData: PendingOwnerData[],
    propertyData: PendingPropertyData,
    cadastroDocumentos = StatusPreenchimento.PENDENTE
  ) {
    this.ownersData = ownersData;
    this.propertyData = propertyData;
    this.cadastroDocumentos = cadastroDocumentos;
  }

}

export class PendingPropertyData {
  fillInStatus: PropertyFillInStatusDto;
  dadosFecharContrato: boolean;

  constructor(property: PropertyDto) {
    this.fillInStatus = property.fillInStatus;
    this.dadosFecharContrato = this.calcularDadosFecharContrato(property);
  }

  private calcularDadosFecharContrato(property: PropertyDto): boolean {
    return !!property &&
      !!property.address &&
      !!property.address.zipCode &&
      !!property.address.city &&
      !!property.address.neighborhood &&
      !!property.address.number &&
      !!property.address.state &&
      !!property.address.street &&
      property.possuiOutroImovel !== undefined &&
      property.possuiOutroImovel !== null &&
      !!property.situation;
  }
}
