<div class="localizacao-c">
  <div class="localizacao-c__header">
    <span class="font-text font-text-bold">Sua localização</span>
    <ion-button fill="clear" color="secondary" size="small" [disabled]="edicaoDesabilitada" (click)="alterarLocalizacao()">
      <ion-icon slot="start" name="pencil-outline"></ion-icon>
      Editar
    </ion-button>
  </div>

  <div class="localizacao-c__content">
    <ion-icon class="ion-margin-end" slot="start" name="location-outline"></ion-icon>
    <ion-text>
      <ng-container *ngIf="localizacao.logradouro; else semEndereco">
        <p class="font-text font-text--medium-color ion-no-margin">
          {{ localizacao.logradouro }}, {{ localizacao.numero ? localizacao.numero + ',':
          '' }}
          {{ localizacao.bairro }}
        </p>
        <p class="font-text font-text--medium-color ion-no-margin">
          {{ localizacao.cidade }}, {{ localizacao.estado }}, {{ localizacao.cep }}
        </p>
      </ng-container>

      <ng-template #semEndereco>
        <p class="font-text font-text--medium-color ion-no-margin">
          Sem informações de endereço da localização
        </p>
      </ng-template>
    </ion-text>
  </div>
</div>
