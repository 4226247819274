<ion-content class="ion-padding">
  <section class="c-assinatura-upload content-vertical-space-between">
    <usucampeao-header title="Contrato físico assinado"></usucampeao-header>

    <div class="c-assinatura-upload__content">
      <usucampeao-upload-foto-documento (aoRealizarUpload)="selecionarArquivos($event)"></usucampeao-upload-foto-documento>
    </div>


    <div class="c-assinatura-upload__actions ion-padding">
      <ion-button id="contrato-fisico-enviar-analise" mode="md" class="ion-margin-bottom" (click)="assinarContrato()" [disabled]="arquivos.length === 0">Enviar para pré-análise</ion-button>
      <ion-button mode="md" fill="outline" (click)="goTo('../')">Cancelar</ion-button>
    </div>
  </section>
</ion-content>
