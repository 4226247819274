import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { PagamentosListagemModule } from '@usucampeao/ui-mobile';
import { PagamentosRoutingModule } from './pagamentos-routing.module';
import { PagamentosComponent } from './pagamentos.component';


@NgModule({
  declarations: [
    PagamentosComponent
  ],
  imports: [
    // route
    CommonModule,
    IonicModule,

    // route
    PagamentosRoutingModule,

    // components
    PagamentosListagemModule,
  ]
})
export class PagamentosModule { }
