import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

const MAX_HEIGHT_DEFAULT = 1000;

@Component({
  selector: 'usucampeao-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent {
  @ViewChild('accordionContent') set content(content: ElementRef) {
    this.accordionContent = content;
    this.definirAlturaAccordion();
  }
  @Input() titulo: string;
  @Input() maxHeight: number;
  @Input() headerComDestaque = false;
  @Input() set estaAberto(estaAberto: boolean) {
    if (estaAberto === this.accordionEstaAberto) {
      return;
    }
    this.accordionEstaAberto = estaAberto ?? false;
    this.definirAlturaAccordion();
  }

  @Output() aoAlterarAberturaAccordion = new EventEmitter<boolean>();

  private accordionContent: ElementRef;
  public accordionEstaAberto = false;

  public toggleAccordion(): void {
    this.accordionEstaAberto = !this.accordionEstaAberto;
    this.aoAlterarAberturaAccordion.emit(this.accordionEstaAberto);
    this.definirAlturaAccordion();
  }

  private definirAlturaAccordion(): void {
    const contentElement = this.accordionContent?.nativeElement;
    if (!contentElement) {
      return;
    }

    if (this.accordionEstaAberto) {
      contentElement.style.maxHeight = (this.maxHeight || contentElement.scrollHeight || MAX_HEIGHT_DEFAULT) + 'px';
    } else {
      contentElement.style.maxHeight = '0px';
    }
  }
}
