import { Pipe, PipeTransform } from '@angular/core';
import { AddressDto } from '@usucampeao/lib-reurb-simplificado';

export enum ArgsAddressPipe {
  COMPLETE = 'COMPLETE',
  SIMPLIFIED = 'SIMPLIFIED',
  NEIGHBORHOOD_CITY_STATE_ONLY = 'NEIGHBORHOOD_CITY_STATE_ONLY'
}

@Pipe({
  name: 'address'
})
export class AddressPipe implements PipeTransform {

  transform(address: AddressDto, args: ArgsAddressPipe = ArgsAddressPipe.SIMPLIFIED): string {
    if (!address) {
      return `Endereço não cadastrado`
    }

    switch (args) {
      case ArgsAddressPipe.COMPLETE:
        return `${address.street}, ${address.number || 'S/N'} ${address.complement ? ', ' + address.complement : ''}, ${address.neighborhood}, ${address.city}, ${address.state}`;

      case ArgsAddressPipe.NEIGHBORHOOD_CITY_STATE_ONLY:
        return `${address.neighborhood}, ${address.city}, ${address.state}`;

      default:
        return `${address.street}, ${address.number || 'S/N'} ${address.complement ? ', ' + address.complement : ''}`;
    }
  }

}
