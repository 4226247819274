import { Component, Input } from '@angular/core';
import {
  CadastroContratoDto,
  ContratoVersao,
  OwnerDto,
  PropertyDto
} from '@usucampeao/lib-reurb-simplificado';
import { TipoContract } from '../enums/tipo-contract.enum';

@Component({
  selector: 'lib-print-contract',
  templateUrl: './print-contract.component.html',
  styleUrls: ['./print-contract.component.scss']
})
export class PrintContractComponent {
  @Input() cadastro!: CadastroContratoDto;
  @Input() proprietario!: Partial<OwnerDto>;
  @Input() conjuge!: Partial<OwnerDto>;
  @Input() imovel!: Partial<PropertyDto>;
  @Input() tipo: TipoContract;
  @Input() mostrarBotaoImprimir = true;

  public get contratoVersao(): typeof ContratoVersao {
    return ContratoVersao;
  }

  public get versao(): ContratoVersao {
    return this.cadastro.contratoVersao || ContratoVersao.V1;
  }

  public abrirImpressaoContrato(): void {
    const printContent = document.getElementById("print-contract");
    const WindowPrt = window.open('', 'PRINT', 'left=0,top=0,width=1920,height=1080,toolbar=0,scrollbars=0,status=0');
    WindowPrt.document.write(printContent.innerHTML);

    const styles = document.querySelectorAll('style');
    const head = WindowPrt.document.getElementsByTagName('head')[0];
    styles.forEach(style => {
      head.appendChild(document.importNode(style, true))
    });
    const printStyles = document.createElement('style');
    printStyles.innerHTML = `
    body {
      display: none;
    }
    @media print {
      body {
        display: block !important;
        position: initial !important;
        transform: initial !important;
      }
    }`;
    head.appendChild(printStyles);

    WindowPrt.document.close();
    WindowPrt.focus();
    setTimeout(() => {
      WindowPrt.print();
      WindowPrt.close();
    }, 500);
  }
}
