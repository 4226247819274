import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DocumentosSelecionarTipoModule } from 'libs/ui-mobile/src/lib/components';
import { ImoveisDocumentosSelecionarTipoComponent } from './imoveis-documentos-selecionar-tipo.component';



@NgModule({
  declarations: [
    ImoveisDocumentosSelecionarTipoComponent
  ],
  imports: [
    // root
    CommonModule,

    // Components
    DocumentosSelecionarTipoModule,
  ],
  exports: [
    ImoveisDocumentosSelecionarTipoComponent
  ]
})
export class ImoveisDocumentosSelecionarTipoModule { }
