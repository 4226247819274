import { Expose, plainToInstance } from 'class-transformer';
import { PagamentoDto } from '.';
import { PagamentoListagemDto } from './pagamento-listagem.dto';

export class PagamentoDetalhesDto extends PagamentoListagemDto {
  @Expose()
  linhaDigitavel: string;

  @Expose()
  downloadUrl: string;

  @Expose()
  dataPagamento?: Date;

  @Expose()
  dataEmissao?: Date;

  @Expose()
  valorPago?: number;

  /**
   * Cria uma nova instância de PagamentoDetalhesDto a partir de dtos
   * @param props DTO de Pagamento
   * @returns nova instância de PagamentoDetalhesDto
   */
  static from(props?: PagamentoDto | Partial<PagamentoDto>): PagamentoDetalhesDto {
    return plainToInstance(PagamentoDetalhesDto, props, { exposeUnsetFields: false, excludeExtraneousValues: true });
  }
}
