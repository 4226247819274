import { Nationality, UF } from '@usucampeao/interfaces';
import { IsCpf, RemoveSpecialCharacters } from '@usucampeao/utils';
import { Expose, Transform, Type, plainToInstance } from 'class-transformer';
import { IsDate, IsEmail, IsEnum, IsNotEmpty, IsNumber, IsOptional, IsString, Min, ValidateIf, ValidateNested } from 'class-validator';
import { Gender } from '../../enums/gender.enum';
import { GroupsClassTransformer } from '../../enums/groups-class-transformer.enum';
import { MaritalStatus } from './marital-status.enum';
import { MatrimonialRegime } from './matrimonial-regime.enum';
import { OwnerFillInStatusDto } from './owner-fill-in-status.dto';
import { OwnerDto } from './owner.dto';
import { PhoneDto } from './phone.dto';



export const STATUS_REGIME_BENS_DATA_CASAMENTO = [MaritalStatus.MARRIED, MaritalStatus.SEPARATED];

export class GetUpdateOwnerPersonalDataDto {
  @Expose()
  @IsString()
  @IsNotEmpty()
  name: string;

  @IsEnum(Gender)
  @IsNotEmpty()
  @Expose()
  gender: Gender;

  @Expose()
  @Type(() => Date)
  @IsDate()
  @IsNotEmpty()
  birthDate: Date;

  @Expose()
  @IsEnum(Nationality)
  @IsNotEmpty()
  nationality: Nationality;

  @Expose()
  @Transform(({ value, obj }) => {
    if (obj.nationality !== Nationality.BRASIL && obj.state) {
      return null;
    }
    return value;
  })
  @IsEnum(UF)
  @IsNotEmpty()
  @ValidateIf((object: GetUpdateOwnerPersonalDataDto) => object.nationality === Nationality.BRASIL)
  state: UF;

  @Expose()
  @IsString()
  @IsNotEmpty()
  @ValidateIf((object: GetUpdateOwnerPersonalDataDto) => object.nationality === Nationality.BRASIL)
  naturalidade: string;

  @Expose()
  @IsEnum(MaritalStatus)
  @IsNotEmpty()
  maritalStatus: MaritalStatus;

  @Expose()
  @Transform(({ value, obj }) => {
    if (!STATUS_REGIME_BENS_DATA_CASAMENTO.includes(obj.maritalStatus) && obj.matrimonialRegime) {
      return null;
    }
    return value;
  })
  @IsEnum(MatrimonialRegime)
  @IsNotEmpty()
  @ValidateIf((object: GetUpdateOwnerPersonalDataDto) => object && STATUS_REGIME_BENS_DATA_CASAMENTO.includes(object.maritalStatus))
  matrimonialRegime: MatrimonialRegime;

  @Expose()
  @Transform(({ value, obj }) => {
    if ((!STATUS_REGIME_BENS_DATA_CASAMENTO.includes(obj.maritalStatus) && obj.weddingDate) || !obj.weddingDate) {
      return null;
    }
    return value;
  })
  @IsDate()
  @IsNotEmpty()
  @Type(() => Date)
  @ValidateIf((object: GetUpdateOwnerPersonalDataDto) => object && STATUS_REGIME_BENS_DATA_CASAMENTO.includes(object.maritalStatus))
  weddingDate: Date;

  @Expose()
  @IsString()
  @IsNotEmpty()
  profession: string;

  @Expose({ groups: [GroupsClassTransformer.SHOW_MAIN] })
  main?: boolean;

  @Expose()
  @IsNumber()
  @Min(0)
  @IsNotEmpty()
  familyIncome: number;

  @Expose({ groups: [GroupsClassTransformer.SHOW_MAIN] })
  spouseId: string;

  fillInStatus: OwnerFillInStatusDto;

  /**
   * Cria uma nova instância de GetUpdateOwnerPersonalDataDto a partir de dtos
   * @param props DTO de Owner
   * @returns nova instância de GetUpdateOwnerPersonalDataDto
   */
  static from(props?: OwnerDto | Partial<OwnerDto>, groups: GroupsClassTransformer[] = []): GetUpdateOwnerPersonalDataDto {
    return plainToInstance(GetUpdateOwnerPersonalDataDto, props, { exposeUnsetFields: false, excludeExtraneousValues: true, groups });
  }
}


export class ProprietarioDadosBasicosPartialDto {
  @Expose()
  @IsString()
  @IsOptional()
  name?: string;

  @IsEnum(Gender)
  @Expose()
  @IsOptional()
  gender?: Gender;

  @Expose()
  @Type(() => Date)
  @IsDate()
  @IsOptional()
  birthDate?: Date;

  @Expose()
  @IsEnum(Nationality)
  @IsOptional()
  nationality?: Nationality;

  @Expose()
  @IsEnum(UF)
  @IsOptional()
  state?: UF;

  @Expose()
  @IsString()
  @IsOptional()
  naturalidade?: string;

  @Expose()
  @IsEnum(MaritalStatus)
  @IsOptional()
  maritalStatus?: MaritalStatus;

  @Expose()
  @IsEnum(MatrimonialRegime)
  @IsOptional()
  matrimonialRegime?: MatrimonialRegime;

  @Expose()
  @IsDate()
  @Type(() => Date)
  @IsOptional()
  weddingDate?: Date;

  @Expose()
  @IsString()
  @IsOptional()
  profession?: string;

  @Expose()
  @IsNumber()
  @Min(0)
  @IsOptional()
  familyIncome?: number;

  @Expose()
  @IsString()
  @IsNotEmpty()
  proprietarioId: string;
}

export enum RendaFamiliar {
  ATE_2_SALARIOS = 'ATE_2_SALARIOS',
  DE_2_A_3_SALARIOS = 'DE_2_A_3_SALARIOS',
  DE_3_A_4_SALARIOS = 'DE_3_A_4_SALARIOS',
  DE_4_A_5_SALARIOS = 'DE_4_A_5_SALARIOS',
  ACIMA_5_SALARIOS = 'ACIMA_5_SALARIOS',
}

export class ProprietarioDadosBasicosDto {
  @Expose()
  @IsString()
  @IsOptional()
  name?: string;

  @Expose()
  @RemoveSpecialCharacters()
  @IsString()
  @IsCpf()
  @IsNotEmpty()
  cpf: string;

  @Expose()
  @Type(() => Date)
  @IsDate()
  @IsOptional()
  birthDate?: Date;

  @Expose()
  @IsEnum(MaritalStatus)
  @IsOptional()
  maritalStatus?: MaritalStatus;

  @Expose()
  @Type(() => PhoneDto)
  @ValidateNested()
  @IsNotEmpty()
  phones: PhoneDto;

  @Expose()
  @IsEmail()
  @IsOptional()
  @ValidateIf(data => data.email)
  email?: string;

  @Expose()
  @IsEnum(RendaFamiliar)
  @IsNotEmpty()
  rendaFamiliar?: RendaFamiliar;

  @Expose()
  spouseId?: string;

  @Expose()
  main: boolean;

  fillInStatus: OwnerFillInStatusDto;


  /**
   * Cria uma instância de ProprietarioDadosBasicosDto a partir das propriedades fornecidas.
   *
   * @param props - As propriedades para inicializar a instância.
   * @param groups - Os grupos de transformação a serem aplicados durante a conversão.
   * @returns Uma instância de ProprietarioDadosBasicosDto.
   */
  static from(props?: OwnerDto | Partial<OwnerDto>): ProprietarioDadosBasicosDto {
    return plainToInstance(ProprietarioDadosBasicosDto, props, { exposeUnsetFields: false, excludeExtraneousValues: true });
  }
}

export class ProprietarioDadosPessoaisDto {
  @Expose()
  @IsString()
  @IsNotEmpty()
  name: string;

  @IsEnum(Gender)
  @IsNotEmpty()
  @Expose()
  gender: Gender;

  @Expose()
  @Type(() => Date)
  @IsDate()
  @IsNotEmpty()
  birthDate: Date;

  @Expose()
  @IsEnum(Nationality)
  @IsNotEmpty()
  nationality: Nationality;

  @Expose()
  @Transform(({ value, obj }) => {
    if (obj.nationality !== Nationality.BRASIL && obj.state) {
      return null;
    }
    return value;
  })
  @IsEnum(UF)
  @IsNotEmpty()
  @ValidateIf((object: GetUpdateOwnerPersonalDataDto) => object.nationality === Nationality.BRASIL)
  state: UF;

  @Expose()
  @IsString()
  @IsNotEmpty()
  @ValidateIf((object: GetUpdateOwnerPersonalDataDto) => object.nationality === Nationality.BRASIL)
  naturalidade: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  profession: string;

  @Expose()
  @IsEnum(RendaFamiliar)
  @IsNotEmpty()
  rendaFamiliar: RendaFamiliar;

  @Expose()
  @IsEnum(MaritalStatus)
  @IsOptional()
  maritalStatus?: MaritalStatus;

  @Expose()
  @Transform(({ value, obj }) => {
    if (!STATUS_REGIME_BENS_DATA_CASAMENTO.includes(obj.maritalStatus) && obj.matrimonialRegime) {
      return null;
    }
    return value;
  })
  @IsEnum(MatrimonialRegime)
  @IsNotEmpty()
  @ValidateIf((object: GetUpdateOwnerPersonalDataDto) => object && STATUS_REGIME_BENS_DATA_CASAMENTO.includes(object.maritalStatus))
  matrimonialRegime: MatrimonialRegime;

  @Expose()
  @Transform(({ value, obj }) => {
    if ((!STATUS_REGIME_BENS_DATA_CASAMENTO.includes(obj.maritalStatus) && obj.weddingDate) || !obj.weddingDate) {
      return null;
    }
    return value;
  })
  @IsDate()
  @IsNotEmpty()
  @Type(() => Date)
  @ValidateIf((object: GetUpdateOwnerPersonalDataDto) => object && STATUS_REGIME_BENS_DATA_CASAMENTO.includes(object.maritalStatus))
  weddingDate: Date;

  @Expose()
  main: boolean;

  @Expose()
  spouseId: string;

  fillInStatus: OwnerFillInStatusDto;

  /**
   * Cria uma instância de ProprietarioDadosPessoaisDto a partir das propriedades fornecidas.
   *
   * @param props - As propriedades para inicializar a instância.
   * @param groups - Os grupos de transformação a serem aplicados durante a conversão.
   * @returns Uma instância de ProprietarioDadosPessoaisDto.
   */
  static from(props?: OwnerDto | Partial<OwnerDto>): ProprietarioDadosPessoaisDto {
    return plainToInstance(ProprietarioDadosPessoaisDto, props, { exposeUnsetFields: false, excludeExtraneousValues: true });
  }
}
