import { Component, Input } from '@angular/core';
import { NotificacaoDto } from '@usucampeao/lib-reurb-simplificado';

@Component({
  selector: 'usucampeao-projeto-titulo-emitido',
  templateUrl: './projeto-titulo-emitido.component.html',
  styleUrls: ['./projeto-titulo-emitido.component.scss'],
})
export class ProjetoTituloEmitidoComponent {
  @Input() public notificacao: NotificacaoDto;
}
