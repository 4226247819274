import { IsArray, IsBoolean, IsEnum, IsNotEmpty, IsOptional, IsString } from 'class-validator';
import { LoteSituacao } from './lote-situacao.enum';

export class LoteCriarDto {
  @IsString()
  @IsNotEmpty()
  label: string;

  @IsString()
  @IsOptional()
  integracaoId?: number;

  @IsString()
  @IsOptional()
  logradouro?: string;

  @IsString()
  @IsOptional()
  numeroFachada?: string;

  @IsString()
  @IsOptional()
  logradouro2?: string;

  @IsString()
  @IsOptional()
  numeroFachada2?: string;

  @IsBoolean()
  @IsOptional()
  esquina: boolean;

  @IsString()
  @IsOptional()
  complemento?: string;

  @IsString()
  @IsOptional()
  arquivoNome: string;

  @IsString()
  @IsOptional()
  arquivoUrlStorage: string;

  @IsArray({ each: true })
  @IsNotEmpty()
  coords: number[][];

  @IsEnum(LoteSituacao)
  @IsOptional()
  status: LoteSituacao;

  constructor(
    label: string,
    coords: number[][],
    status: LoteSituacao,
    idIntegracao?: number,
    esquina?: boolean,
    arquivoNome?: string,
    arquivoUrlStorage?: string,
    logradouro?: string,
    numeroFachada?: string,
    logradouro2?: string,
    numeroFachada2?: string,
    complemento?: string,
  ) {
    this.label = label;
    this.integracaoId = idIntegracao;
    this.esquina = esquina;
    this.coords = coords;
    this.status = status;
    this.arquivoNome = arquivoNome;
    this.arquivoUrlStorage = arquivoUrlStorage;
    this.logradouro = logradouro;
    this.numeroFachada = numeroFachada;
    this.complemento = complemento;
    this.logradouro2 = logradouro2;
    this.numeroFachada2 = numeroFachada2;
  }
}
