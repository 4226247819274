import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Bs2BoletoDownloadUrl } from '@usucampeao/interfaces';
import { PagamentoDetalhesDto, PagamentoListagemDto } from '@usucampeao/lib-reurb-simplificado';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CadastrosStore } from '../../state/cadastros.store';


@Injectable({
  providedIn: 'root',
})
export class PagamentosService {

  constructor(
    private cadastroStore: CadastrosStore,
    private http: HttpClient,
  ) { }

  /**
   * Busca os pagamentos de um cadastro.
   * @param cadastroId ID do cadastro
   * @returns lista de pagamento
   */
  public buscarPagamentosPorCadastroId(cadastroId: string): Observable<PagamentoListagemDto[]> {
    return this.http.get<PagamentoListagemDto[]>(`/registrations/${cadastroId}/payments`)
      .pipe(
        tap(pagamentos => this.cadastroStore.update(cadastroId, { pagamentos })),
      );
  }

  /**
   * Lista os pagamentos com data de vencimento em 7 dias ou menos.
   * @returns Lista de pagamentos com data de vencimento em 7 dias ou menos
   */
  public buscarProximoVencimento(): Observable<PagamentoListagemDto[]> {
    return this.http.get<PagamentoListagemDto[]>('/registrations/pagamentos/proximo-vencimento');
  }

  /**
   * Busca os detalhes de um pagamento
   * @param cadastroId ID do cadastro
   * @param pagamentoId ID do pagamento
   * @returns Retorna os detalhes de um pagamento
   */
  public buscarPagamentosDetalhes(cadastroId: string, pagamentoId: string): Observable<PagamentoDetalhesDto> {
    return this.http.get<PagamentoDetalhesDto>(`/registrations/${cadastroId}/payments/${pagamentoId}`)
      .pipe(
        tap(pagamento => {
          const cadastro = this.cadastroStore.buscarCadastro(cadastroId);
          const pagamentos = cadastro.pagamentos || [];
          const novoArrayPagamentos = pagamentos.slice();
          const indicePagamentoAntigo = novoArrayPagamentos.findIndex(pagamento => pagamento.id === pagamentoId);

          if (indicePagamentoAntigo === -1) {
            novoArrayPagamentos.push(pagamento);
          } else {
            novoArrayPagamentos.splice(indicePagamentoAntigo, 1, pagamento);
          }
          this.cadastroStore.update(cadastroId, { pagamentos: novoArrayPagamentos });
        }),
      );
  }

  /**
   * Busca a URL do boleto de um pagamento.
   * @param id id do cadastro
   * @param pagamentoId id do pagamento
   * @returns url do boleto
   */
  public downloadBoletoUrl(id: string, pagamentoId: string): Observable<Bs2BoletoDownloadUrl> {
    return this.http.get<Bs2BoletoDownloadUrl>(`/registrations/${id}/payments/${pagamentoId}/download`);
  }
}
