<ion-text>
  <p class="font-text">
    Está em andamento o cadastro social dos moradores da sua área, que oficializa os dados e documentos dos moradores
    que serão enviados à prefeitura e cartório. Certifique-se de estar presente na data anunciada, é uma etapa essencial
    para regularizar o seu loteamento.
  </p>
  <p class="font-text">
    A equipe técnica também passará pela sua área para elaborar o mapa do perímetro final, que oficializa a
    região que passará pelo processo de regularização. O seu cadastro já faz parte do perímetro desenhado, esta é
    somente uma formalização necessária da área para que tudo esteja bem alinhado com a prefeitura.
  </p>
</ion-text>
