import { AlteracaoDto } from './alteracao.dto';
import { StatusRevisao } from './status-revisao.enum';

export class RevisaoDto {
  id: string;
  cadastroId: string;
  usuarioId: string;
  usuarioNome: string;
  alteracoes: AlteracaoDto[];
  status: StatusRevisao;
  comentario?: string;
  usuarioResponsavelId: string;
  usuarioResponsavelFoto?: string;
  usuarioResponsavelNome: string;
  dataInicio?: Date;
  dataFim?: Date;
  dataCancelamento?: Date;
  dataBloqueio?: Date;
  dataCriacao: Date;
  dataAtualizacao: Date;
  cidade: string;
  vendedor: string;
  proprietario: string;
  projeto: string;
  finalizada: boolean;
}
