import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { filter, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private readonly rotaDefault = 'home';
  private history: string[] = [];
  private route: ActivatedRoute;

  constructor(
    private location: Location,
    private navCtrl: NavController,
    private router: Router,
  ) { }

  /**
   * Inicia a observação do histórico de navegação.
   */
  public iniciarObservacaoRota(): void {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        tap(() => this.atualizarActivatedRoute(this.router.routerState.root)),
        map((event: NavigationEnd) => event.urlAfterRedirects),
        filter(url => !this.history.slice(-1).includes(url)),
        tap(url => this.history.push(url))
      )
      .subscribe();
  }

  /**
   * Atualiza a rota ativa.
   * Não é possível utilizar o ActivatedRoute via injeção de dependência, pois como estamos em um serviço, e os serviços são singleton, o ActivatedRoute injetado seria sempre o mesmo.
   * Para contornar isso, é necessário percorrer a árvore de rotas para encontrar a rota ativa.
   * @param route Rota ativa
   */
  private atualizarActivatedRoute(route: ActivatedRoute): void {
    let activeRoute = route;
    while (activeRoute.firstChild) {
      activeRoute = activeRoute.firstChild;
    }

    this.route = activeRoute;
  }

  /**
   * Navega para rota anterior, baseado no histórico de navegação.
   * Caso não exista histórico, navega para a rota informada.
   * @param fallbackUrl Rota para navegar caso não exista histórico de navegação. Se não informado, navega para 'home'.
   */
  public voltar(fallbackUrl?: string): void {
    this.history.pop();
    this.navCtrl.setDirection('back', true, 'back');
    if (this.history.length > 0) {
      this.location.back();
    } else {
      this.router.navigate(
        [fallbackUrl || this.rotaDefault],
        {
          replaceUrl: true,
          ...fallbackUrl ? { relativeTo: this.route } : {}
        }
      );
    }
  }

  /**
   * Limpa o histórico de navegação.
   */
  public limparHistoricoNavegacao(): void {
    this.history = []
  }
}
