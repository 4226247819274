import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, ModalController, ViewWillEnter } from '@ionic/angular';
import { CreateUserDto } from '@usucampeao/lib-reurb-simplificado';
import { ModalTermosUsoComponent, ToastService } from '@usucampeao/ui-mobile';
import { ModalPoliticaPrivacidadeComponent } from 'apps/reurb-simplificado/src/app/shared/components/modal-politica-privacidade/modal-politica-privacidade.component';
import { catchError, tap } from 'rxjs/operators';
import { AuthQuery } from '../../state/auth.query';
import { AuthService } from '../../state/auth.service';

@Component({
  selector: 'usucampeao-criar-conta',
  templateUrl: './criar-conta.component.html',
  styleUrls: ['./criar-conta.component.scss']
})
export class CriarContaComponent implements ViewWillEnter {
  public aceitePrivacidade = false;
  private telefone: string;

  constructor(
    private alertCtrl: AlertController,
    private authQuery: AuthQuery,
    private authService: AuthService,
    private modalCtrl: ModalController,
    public router: Router,
    private toastService: ToastService,
  ) { }

  ionViewWillEnter(): void {
    const { telefone } = this.authQuery.getValue();

    if (!telefone) {
      this.authService.logout();
    } else {
      this.telefone = telefone.replace(/\D/g, '');
    }
  }

  public async abrirModalPoliticaPrivacidade(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalPoliticaPrivacidadeComponent,
    });
    return await modal.present();
  }

  public async abrirModalTermosDeUso() {
    const modal = await this.modalCtrl.create({
      component: ModalTermosUsoComponent,
    });
    return await modal.present();
  }

  public onSubmit(usuario: CreateUserDto): void {
    if (!this.aceitePrivacidade) {
      this.mostrarAlertaPoliticaPrivacidade();
      return;
    }
    this.criarUsuario(usuario);
  }

  private criarUsuario(usuario: CreateUserDto): void {
    this.authService.criarConta({ ...usuario, phone: this.telefone })
      .pipe(
        tap(() => this.router.navigate([`criar-conta/cadastrar-foto`])),
        catchError(error => {
          console.error(error);
          return this.toastService.error('Erro ao criar de conta. Por favor tente novamente.');
        })
      )
      .subscribe();
  }

  private async mostrarAlertaPoliticaPrivacidade() {
    const alert = await this.alertCtrl.create({
      header: 'Atenção',
      message: 'Você precisa aceitar a política de privacidade e os termos de uso para continuar com a criação da conta.',
      buttons: [
        {
          text: 'Entendi',
        },
      ],
    });
    await alert.present();
  }
}
