export enum PermissaoSubgrupo {
  CIDADES = 'CIDADES',
  COMISSOES = 'COMISSOES',
  COMENTARIOS = 'COMENTARIOS',
  CUPOM_DESCONTO = 'CUPONS_DESCONTO',
  DOCUMENTOS = 'DOCUMENTOS',
  LOTES = 'LOTES',
  MAPA = 'MAPA',
  NUCLEOS = 'NUCLEOS',
  PROGRAMACOES = 'PROGRAMACOES',
  QUADRAS = 'QUADRAS',
  REVISOES = 'REVISOES',
  VALORES = 'VALORES',
  TIMES = 'TIMES',
  USUARIOS = 'USUARIOS',
  POLITICAS = 'POLITICAS',
}
