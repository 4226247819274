import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CadastroContratoDto, CadastroDocumentoTipo, FileDto } from '@usucampeao/lib-reurb-simplificado';
import { ArquivosEnviadosRemovidos, BaseComponent, LoadingService, NavigationService, ToastService } from '@usucampeao/ui-mobile';

import { catchError, filter, finalize, switchMap, takeUntil, tap } from 'rxjs/operators';
import { CadastroService } from '../../state/cadastros.service';

@Component({
  templateUrl: './outros-documentos-upload.page.html',
  styleUrls: ['./outros-documentos-upload.page.scss']
})
export class OutrosDocumentosUploadPage extends BaseComponent implements OnInit {

  public tipo: CadastroDocumentoTipo;
  private cadastroId: string;
  public documentoId: string;
  public arquivos: FileDto[] = [];
  public dadosContrato: CadastroContratoDto;

  constructor(
    private cadastroService: CadastroService,
    private loadingService: LoadingService,
    private navigationService: NavigationService,
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.route.queryParams
      .pipe(
        tap(params => {
          this.tipo = params.tipo;

          if (!this.tipo) {
            this.router.navigate(['..'], { relativeTo: this.route });
          }
        }),
        tap(params => this.documentoId = params.documentoId),
        tap(() => this.carregarDocumento()),
        switchMap(() => this.route.params),
        tap(params => this.cadastroId = params.cadastroId),
        filter(() => this.tipo === CadastroDocumentoTipo.PROCURACAO),
        switchMap(() => this.cadastroService.buscarContrato(this.cadastroId)),
        tap(dadosContrato => this.dadosContrato = dadosContrato),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe();
  }

  private async carregarDocumento(): Promise<void> {
    if (!this.documentoId) {
      return;
    }

    await this.loadingService.createLoader();
    this.cadastroService.buscarArquivosDocumento(this.documentoId)
      .pipe(
        tap(arquivos => this.arquivos = arquivos),
        catchError(() => this.toastService.error('Erro ao carregar documento. Por favor tente novamente')),
        finalize(async () => await this.loadingService.dismiss()),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe();
  }

  public adicionarOuAtualizarDocumento(arquivos: ArquivosEnviadosRemovidos): void {
    if (this.documentoId) {
      this.atualizarDocumento(arquivos);
    } else {
      this.adicionarDocumento(arquivos.arquivosEnviados);
    }
  }

  private async adicionarDocumento(arquivos: File[]): Promise<void> {
    await this.loadingService.createLoader();

    this.cadastroService.uploadDocumentosDoCadastro(this.cadastroId, arquivos, this.tipo)
      .pipe(
        switchMap(() => this.toastService.success('Documento adicionado com sucesso')),
        tap(() => this.navigationService.voltar()),
        catchError(async error => {
          await this.toastService.error('Erro ao salvar documento. Por favor tente novamente').toPromise();
          throw error;
        }),
        finalize(async () => this.loadingService.dismiss()),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe();
  }

  private async atualizarDocumento(arquivos: ArquivosEnviadosRemovidos): Promise<void> {
    await this.loadingService.createLoader();

    this.cadastroService.atualizarDocumento(this.cadastroId, this.documentoId, arquivos.arquivosEnviados, arquivos.idsArquivosRemovidos)
      .pipe(
        switchMap(() => this.toastService.success('Documento atualizado com sucesso')),
        tap(() => this.navigationService.voltar()),
        catchError(async error => {
          await this.toastService.error('Erro ao atualizar documento. Por favor tente novamente').toPromise();
          throw error;
        }),
        finalize(async () => this.loadingService.dismiss()),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe();
  }
}
