<section class="p-ficha">
  <usucampeao-header title="Proprietários" backRoute="../"></usucampeao-header>

  <div class="p-ficha__proprietario">
    <ion-text>
      <p class="font-text font-text--medium ">
        Lista de benenficiários que terão seus nomes na matrícula do imóvel.
      </p>
    </ion-text>
    <usucampeao-proprietario-ficha titulo="1º Proprietário" [proprietario]="proprietarioPrincipal"
      (aoAdicionarConjuge)="adicionarConjuge($event)">
    </usucampeao-proprietario-ficha>
  </div>

  <div class="p-ficha__proprietario p-ficha__proprietario--conjuge" *ngIf="conjuge">
    <ion-text>
      <h3 class="title">Cônjuge 1º Proprietário</h3>
    </ion-text>
    <usucampeao-proprietario-ficha [proprietario]="conjuge" (aoRemoverConjuge)="removerConjuge($event)"></usucampeao-proprietario-ficha>
  </div>
</section>
