import { Pipe, PipeTransform } from '@angular/core';
import { CadastroContratoTipoAssinatura } from '@usucampeao/interfaces';

@Pipe({
  name: 'tipoAssinatura'
})
export class ContratoTipoAssinaturaPipe implements PipeTransform {

  transform(value: CadastroContratoTipoAssinatura): string {
    switch (value) {
      case CadastroContratoTipoAssinatura.FISICO:
        return 'Foto do contrato';

      case CadastroContratoTipoAssinatura.CODIGO_CONFIRMACAO:
        return 'Código numérico';

      case CadastroContratoTipoAssinatura.FOTO_COM_DOCUMENTO:
        return 'Foto com o documento';

      case CadastroContratoTipoAssinatura.LINK:
        return 'D4Sign';

      case null:
      case undefined:
        return ' - ';

      default:
        return value;
    }
  }

}
