<ion-content class="ion-padding">
  <ion-item lines="none" class="ion-no-padding">
    <ion-text slot="start" class="title">Política de Privacidade</ion-text>
    <ion-icon (click)="dismiss()" slot="end" name="close-outline"></ion-icon>
  </ion-item>
  <ion-text class="description-text ion-margin-bottom">
    <p>Última atualização em 19 de novembro de 2021.</p>
  </ion-text>
  <h1 class="title title--medium">Introdução</h1>
  <ion-text class="ion-text-justify font-text font-text--small">
    <p>A Lei Geral de Proteção de Dados (“LGPD”) e o Regulamento Geral sobre a Proteção de Dados (“RGPD” ou “GDPR”)
      constituem, respectivamente, as novas normas de proteção de dados do Brasil – LGPD – e da União Europeia (“UE”) e
      Espaço Econômico Europeu
      (“EEE”) – GDPR. Tanto a LGPD quanto o GDPR têm por objetivo a proteção dos direitos fundamentais da pessoal
      natural, notadamente a sua privacidade, por meio da instituição de regras claras sobre como as empresas devem
      realizar qualquer atividade
      de tratamento de dados pessoais.
    </p>
    <p>Constitui dados pessoais (“dados”) qualquer informação relacionada a pessoa natural identificada ou
      identificável, como por exemplo o nome completo, endereço de <i>e-mail</i>, telefone de contato etc. O tratamento
      de dados pessoais (“tratamento”),
      por sua vez, é definido como toda operação realizada por pessoa jurídica ou física com dados pessoais, tal como a
      coleta, recepção, classificação, utilização, acesso, reprodução, transferência, compartilhamento, armazenamento,
      exclusão etc.
    </p>
    <p> Dada a importância dos referidos diplomas legais e considerando que a visitação de nosso <i>site</i> e/ou a
      utilização de nossos serviços (aplicativos, softwares, plataformas digitais etc. – coletiva e indistintamente
      denominados “Serviços”) implicam
      em tratamento de dados, esta Política de Privacidade (“Política”) tem por objetivo apresentar todas as
      características e informações sobre como a USUCAMPEÃO coleta, utiliza, armazena e trata os seus dados pessoais e
      como seus funcionários,
      agentes, terceirizados e parceiros que tenham acesso a estes dados deverão agir.
    </p>
  </ion-text>
  <h1 class="title title--medium">Vigência</h1>
  <ion-text class="ion-text-justify font-text font-text--small">
    <p>A presente Política entrará em vigor no momento da ciência e concordância com os termos desta Política;</p>
    <p>Reservamo-nos o direito de modificar a presente Política de Privacidade a qualquer tempo diante de eventuais
      alterações legislativas ou jurisprudenciais. Mas, fique tranquilo, em caso de modificações dos termos desta
      Política, a <strong>USUCAMPEÃO</strong> enviará um <i>e-mail</i> apresentando a nova versão desta Política para o
      seu endereço de <i>e-mail</i> cadastrado em nosso <i>site</i>, serviços ou contrato.</p>
    <p><strong>A CONTINUIDADE DO USO DOS SERVIÇOS PODERÁ SUJEITAR-SE AO SEU CONSENTIMENTO EXPRESSO E INEQUÍVOCO COM AS
        NOVAS VERSÕES DESTA POLÍTICA. </strong></p>
    <p>Por isso, é importante que, de tempos em tempos, você visite nosso <i>site</i> ou acesse nossos Serviços para
      obter as últimas informações sobre nossas práticas relacionadas à privacidade e proteção de dados pessoais.</p>
  </ion-text>
  <h1 class="title title--medium">Não Concordância</h1>
  <ion-text class="ion-text-justify font-text font-text--small">
    <p><strong>EM CASO DE NÃO CONCORDÂNCIA COM QUALQUER DISPOSIÇÃO OU MODIFICAÇÃO DESTA POLÍTICA, POR FAVOR, CESSE
        IMEDIATAMENTE O USO DE NOSSOS SERVIÇOS, SISTEMAS E PLATAFORMAS.</strong></p>
  </ion-text>
  <h1 class="title title--medium">Dúvidas & Sugestões</h1>
  <ion-text class="ion-text-justify font-text font-text--small">
    <p>Acreditamos que você deve sempre compreender nossas práticas de privacidade, segurança e proteção de dados.</p>
    <p>Por isso, em caso de quaisquer dúvidas ou sugestões relacionadas à esta Política, sinta-se à vontade para
      contatar-nos pelo <i>e-mail</i>: <strong>{{email}}</strong></p>
    <p>PARA A SUA SEGURANÇA E DA <strong>USUCAMPEÃO</strong>, EM CASO DE SOLICITAÇÃO DE ASSISTÊNCIA OU CONTATO, PODERÁ
      SER NECESSÁRIO A AUTENTICAÇÃO DE SUA IDENTIDADE ANTES DE ATENDERMOS À SUA SOLICITAÇÃO.</p>
  </ion-text>
  <h1 class="title title--medium">Dúvidas & Sugestões</h1>
  <ion-text class="ion-text-justify font-text font-text--small">
    <p>Ao visitar nosso <i>site</i>, aplicativo e/ou utilizar nossos Serviços, nossas bases de dados e nossas
      plataformas, você terá contato com o compartilhamento de informações valiosas e pessoais sobre as pessoas que
      utilizam nossos serviços e que
      foram devidamente cadastradas em nossos sistemas. À vista disso, a <strong>USUCAMPEÃO</strong> envida todos os
      esforços para garantir o respeito aos direitos de seus usuários e, é por isso, que a <strong>USUCAMPEÃO</strong>
      garante que você
      tenha ciência do controle substancial sobre as informações pessoais aqui administradas. A seguir, conheça um pouco
      mais sobre nossas práticas para garantir o devido cumprimento da nossa Política de Privacidade Interna:
    </p>
    <p><strong>(a)</strong> nossos Serviços são desenvolvidos para minimizar o uso de dados pessoais. Isso significa que
      nós apenas tratamos os dados necessários para atingir as finalidades dispostas nesta Política;<br>
      <strong>(b)</strong> a <strong>USUCAMPEÃO</strong> toma todas as medidas de segurança da informação, tecnológica e
      legalmente disponíveis, para proteger os seus dados contra incidentes de segurança, tais como acessos não
      autorizados, vírus,
      vazamento de informações etc.;<br>
      <strong>(c)</strong> a <strong>USUCAMPEÃO</strong> não armazena os dados pessoais de modo que, a partir deles, o
      usuário possa ser identificado por um período maior do que o necessário para prestarmos os Serviços. Para isso,
      adotamos as práticas
      de anonimização de dados;<br>
      <strong>(d)</strong> nós não realizamos atividades de tomada de decisão automatizada ou de criação de perfis –
      <i>profiling</i>;<br>
      <strong>(e)</strong> nós não compartilhamos dados pessoais com empregadores, seguradoras ou parentes e amigos.
      Nenhum deles terá acesso aos dados, exceto se o usuário cadastrado expressamente autorizar ou, ainda, se forem
      compartilhados por
      ele próprio de maneira pública ou com terceiros.<br>
    </p>
    <p><strong>AO COMPARTILHAR COM TERCEIROS E/OU PUBLICAMENTE OS DADOS PESSOAIS, A USUCAMPEÃO NÃO PODERÁ EXCLUÍ-LOS DOS
        MECANISMOS DE BUSCA E/OU DOS DEMAIS APLICATIVOS, PLATAFORMAS OU BANCOS DE DADOS, DE PROPRIEDADE DE
        TERCEIROS.</strong></p>
    <p>Além das nossas práticas acima relacionadas, a <strong>USUCAMPEÃO</strong> também garante aos seus usuários:</p>
    <p><strong>(a)</strong> a solicitação de esclarecimentos e informações a respeito do tratamento de seus dados
      pessoais pela <strong>USUCAMPEÃO</strong> por meio do <i>e-mail: </i> <strong>{{email}}</strong>;<br>
      <strong>(b)</strong> o acesso às informações por meio da solicitação de <i>backup</i> de dados – portabilidade de
      dados;<br>
      <strong>(c)</strong> a revogação do consentimento para o tratamento de dados a qualquer momento, bastando para
      tanto: (i) a exclusão da respectiva conta no aplicativo da <strong>USUCAMPEÃO</strong>; e/ou (ii) excluir nossos
      Serviços dos dispositivos;<br> e/ou, ainda, (iii) cancelar a assinatura de nossa <i>newsletter</i>, clicando no
      <i>link</i> disponibilizado no rodapé dos <i>e-mails</i> por nós enviados ou simplesmente entrando em contato
      conosco por meio do <i>e-mail: </i> <strong>{{email}}</strong>;<br>
      <strong>(d)</strong> a retificação das informações armazenadas nas configurações dos Serviços;
      <strong>(e)</strong> a solicitação da exclusão absoluta de dados por meio do <i>e-mail: </i>
      <strong>{{email}}</strong>. Uma vez deferida a exclusão, os dados serão excluídos permanentemente em até 60
      (sessenta) dias contados da data do deferimento;<br>
      <strong>(f)</strong> a apresentação de reclamação perante a autoridade competente nos casos em que o usuário
      acreditar que estamos tratando seus dados em desconformidade com a legislação aplicável. (Para saber quem é a
      autoridade competente,
      consulte a legislação pertinente – LGPD e seus regulamentos.)<br>
    </p>
  </ion-text>
  <h1 class="title title--medium">Dados de Crianças & Adolescentes</h1>
  <ion-text class="ion-text-justify font-text font-text--small">
    <p>Os Serviços da <strong>USUCAMPEÃO</strong>, destinam-se a pessoas maiores de 18 (dezoito) anos, deste modo nós
      não tratamos dados pessoais de crianças e adolescentes.</p>
    <p><strong>NÓS NÃO TRATAMOS INFORMAÇÕES DE CRIANÇAS E ADOLESCENTE INTENCIONALMENTE E NÃO PERMITIMOS O USO DE NOSSOS
        SERVIÇOS POR MENORES DE 18 (DEZOITO) ANOS.</strong></p>
  </ion-text>
  <h1 class="title title--medium">Dados tratados pela USUCAMPEÃO</h1>
  <ion-text class="ion-text-justify font-text font-text--small">
    <p>O tratamento de dados está relacionado a todos os Serviços prestados pela <strong>USUCAMPEÃO</strong>.</p>
    <p>Por isso, para que possamos sempre aprimorar nossos Serviços, bem como nossos serviços comerciais e de suporte ao
      cliente, nós coletamos, recebemos, armazenamos algumas informações sobre nossos usuários, como por exemplo:</p>
    <p><strong>(a) Dados de Contato:</strong> são os dados que os usuários voluntariamente nos fornecem, que incluem:
      nome, endereço de <i>e-mail</i>, código postal, número de telefone;<br>
      <strong>(b) Dados de Uso:</strong> são os dados relativos ao uso dos serviços, tais como: idioma de preferência,
      localização, tipo de dispositivo, tipo de navegador, endereço IP, dados de desempenho;<br>
      <strong>(c) Dados de Terceiros:</strong> são os dados que o usuário fornece publicamente ou a terceiros, que podem
      ser de natureza de contato, de uso etc.;<br>
      <strong>(d) <i>Cookies</i>:</strong> utilizamos <i>cookies</i> próprios e de terceiros para coletar dados sobre os
      visitantes de nosso <i>site</i> ou aplicativo. Os <i>cookies</i> podem incluir: Dados de Contato, Dados de Uso.
      Mais informações
      sobre <i>cookies</i> abaixo.<br>
    </p>
  </ion-text>
  <h1 class="title title--medium">Cookies</h1>
  <ion-text class="ion-text-justify font-text font-text--small">
    <p>Nosso <i>site </i> <strong>{{site}}</strong> é desenvolvido e operado pela <strong>USUCAMPEÃO</strong>.</p>
    <p>A transparência e confiança são elementos fundamentais para o nosso relacionamento. E é por essa razão, que a
      seguir explicaremos como utilizamos a tecnologia dos <i>cookies</i> em nosso <i>site</i> e Serviços.</p>
    <p>As disposições sobre <i>cookies</i> abaixo aplicam se a todo e qualquer Serviço da <strong>USUCAMPEÃO</strong>:
    </p>
    <p><u>Antes de tudo, o que são <i>cookies</i>?</u></p>
    <p><i>Cookies</i> são pequenos arquivos em formato de texto, armazenados no navegador que os usuários utilizam para
      acessar nosso <i>site</i> e Serviços, que permite à <strong>USUCAMPEÃO</strong> ou terceiros reconhecer quem eles
      são.</p>
    <p><u>Qual a utilidade dos <i>cookies</i>?</u></p>
    <p>Geralmente, os <i>cookies</i> são utilizados para coletar, armazenar e compartilhar informações sobre suas
      atividades em sites, tais como o nosso. Durante um certo período, para que os usuários não tenham que inserir
      novamente suas preferências
      e ações, os <i>cookies</i> permitem que os sites lembrem de suas preferências e atividades, como por exemplo, seu
      login, idioma de preferência, localização etc.
    </p>
    <p><u>Quais os fundamentos para o uso de <i>cookies</i>?</u></p>
    <p>Os <i>cookies</i> podem ser utilizados para:<br>
      <strong>(a)</strong> permitir certa funcionalidade do <i>site</i> ou Serviço;<br>
      <strong>(b)</strong> análises e estatísticas de uso do <i>site</i> ou Serviço;<br>
      <strong>(c)</strong> armazenamento de atividades e preferências;<br>
      <strong>(d)</strong> permitir a publicidade e <i>marketing</i> personalizados.<br>
    </p>
    <p><u>Quais os <i>cookies</i> que a <strong>USUCAMPEÃO</strong> utiliza?</u></p>
    <p>Utilizamos os seguintes tipos de <i>cookies</i>:</p>
    <p>
      <strong>(a) <i>Cookies</i> de Sessão.</strong> São aqueles utilizados para reconhecer um visitante em específico
      no nosso <i>site</i> e Serviços. Nós os usamos para identificar o visitante durante uma única sessão de navegação
      e quando o visitante
      efetua o <i>login</i> em nosso <i>site</i> ou Serviço. Os <i>Cookies</i> de Sessão têm um período de vida curto e
      determinado, e expiram quando o visitante encerra sua sessão em seu navegador da <i>web</i> (Internet Explorer,
      Mozila Firefox,
      Google Chrome etc.);<br>
      <strong>(b) <i>Cookies</i> Permanentes.</strong> Estes <i>cookies</i> são aqueles que têm um período de vida um
      pouco maior do que os <i>Cookies</i> de Sessão, pois remanescem nos dispositivos por um pequeno período
      especificado no próprio
      Cookie Permanente. A USUCAMPEÃO utiliza <i>Cookies</i> Permanentes quando necessários para identificar visitantes
      por um período maior do que o permitido por um Cookie de Sessão, como nas hipóteses em que o visitante solicita
      para permanecer
      logado em nosso <i>site</i>.<br>
    </p>
    <p>Dito isso, <u>seguem outros <i>cookies</i> que utilizamos abaixo:</u></p>
    <p><strong>(a) <i>Cookies</i> de Autenticação.</strong> Quando o visitante efetua login em nosso <i>site</i> e
      Serviços, os <i>Cookies</i> de Autenticação nos ajudam a personalizar sua experiência, de acordo com as suas ações
      e preferências;<br>
      <strong>(b) <i>Cookies</i> de Segurança.</strong> São os <i>cookies</i> que utilizamos para habilitar e suportar
      nossos recursos de segurança, ajudando ainda na detecção de atividades maliciosas em nosso <i>site</i> e
      Serviços;<br>
      <strong>(c) <i>Cookies</i> de Desempenho e Análise.</strong> São aqueles que nos ajudam a compreendem o desempenho
      de nosso <i>site</i> e Serviços em localizações distintas, bem como possibilitam o entendimento, aprimoramento e
      análise de
      nossos Serviços e recursos.<br>
    </p>
  </ion-text>
  <h1 class="title title--medium">Fundamentos do Tratamento de Dados pela USUCAMPEÃO</h1>
  <ion-text class="ion-text-justify font-text font-text--small">
    <p>De acordo com as preferências dos usuários e com o Serviço que este utiliza, a <strong>USUCAMPEÃO</strong>
      basicamente trata seus dados para oferecer, analisar, aprimorar e personalizar os Serviços e esforços de
      <i>marketing</i>, além de processar
      seu cadastro, solicitações e eventuais pagamentos, bem como para se comunicar com eles.
    </p>
  </ion-text>
  <h1 class="title title--medium">Compartilhamento de Dados pela USUCAMPEÃO para terceiros</h1>
  <ion-text class="ion-text-justify font-text font-text--small">
    <p>Nós não compartilhamos os dados de usuários com terceiros, exceto quando estritamente necessário:</p>
    <p><strong>(a)</strong> para facilitar o envio de notificações e e-mails para ele;<br>
      <strong>(b)</strong> para fornecer conteúdos de <i>marketing</i> e de publicidade;<br>
      <strong>(c)</strong> os dados forem armazenados em banco de dados de terceiros;<br>
      <strong>(d)</strong> nos ajudar a acompanhar as métricas de sucesso de conversão do <i>site</i>;<br>
      <strong>(e)</strong> gerenciar nossas vendas e nosso serviço de atendimento ao cliente.<br>
    </p>
    <p>A fim de garantir que os terceiros com quem compartilhamos os dados estejam em conformidade com esta Política,
      bem como os altos níveis de confidencialidade e boas práticas de privacidade, proteção de dados e segurança da
      informação, celebramos
      junto a eles acordos de confidencialidade, os quais tratam também da importância de estar em conformidade com a
      LGPD e GDPR.
    </p>
    <p>Outrossim, estamos sempre revisando nossos padrões de privacidade, proteção de dados e de segurança da informação
      por meio das atualizações de nossas políticas, testes de vulnerabilidade, implementação de ferramentas e soluções
      contra vírus e
      malware etc.
    </p>
  </ion-text>
  <h1 class="title title--medium">Compartilhamento de Dados pela USUCAMPEÃO internamente</h1>
  <ion-text class="ion-text-justify font-text font-text--small">
    <p>Em acordo com a Lei Geral de Proteção de Dados Lei nº 13.709/2018, o funcionário, agente, terceirizado, prestador
      de serviços ou parceiro da <strong>USUCAMPEÃO</strong> é proibido de praticar os seguintes atos com os dados
      captados e armazenados
      pela <strong>USUCAMPEÃO</strong>:
    </p>
    <p><strong>(a) </strong><u>utilizar</u> os dados para função divergente da captação;<br>
      <strong>(b) </strong><u>compartilhar</u> os dados fora dos sistemas da <strong>USUCAMPEÃO</strong>;<br>
      <strong>(c) </strong><u>armazenar</u> pessoalmente, seja em computador próprio, <i>e-mail</i> pessoal, pen drive,
      HD externo, <i>site</i>, drive, link ou qualquer outra forma de armazenamento de dados que não seja o próprio
      sistema da <strong>USUCAMPEÃO</strong>;<br>
    </p>
  </ion-text>
  <h1 class="title title--medium">Declaração e assinatura
  </h1>
  <ion-text class="ion-text-justify font-text font-text--small">
    <p>Ao assinar este termo, você informa que compreendeu como a Política de Privacidade geral da
      <strong>USUCAMPEÃO</strong> funciona, como ela armazena os dados e quais as finalidades.
    </p>
    <p>As Partes envolvidas neste instrumento afirmam e declaram que o presente documento poderá ser assinado por meio
      eletrônico, sendo consideradas válidas as referidas assinaturas, através da plataforma <strong>Pode Regularizar
      </strong> ({{email}}),
      todos para fins deste documento, nos termos do art. 10 parágrafo 2º da MP2200-2/2001.
    </p>
  </ion-text>
  <div class="ion-text-center">
    <ion-button (click)="dismiss()">Entendi</ion-button>
  </div>
  <br>
</ion-content>
