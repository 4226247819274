import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { CadastroStatusModule, FooterModule, HeaderModule } from '../../../../components';
import { EnderecoFormModule, ImovelInfosAdicionaisFormModule } from '../../../../components/forms';
import { ImoveisEnderecoComponent } from './imoveis-endereco.component';

@NgModule({
  declarations: [
    ImoveisEnderecoComponent,
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,
    ReactiveFormsModule,

    // components
    CadastroStatusModule,
    HeaderModule,
    FooterModule,
    EnderecoFormModule,
    ImovelInfosAdicionaisFormModule,
  ],
  exports: [
    ImoveisEnderecoComponent
  ]
})
export class ImoveisEnderecoModule { }
