<section class="p-dados-basicos">
  <usucampeao-header title="Proprietário"></usucampeao-header>

  <usucampeao-cadastro-status *ngIf="mostrarAlteracoes" [status]="cadastroStatus"
    [alteracoes]="alteracoes"></usucampeao-cadastro-status>

  <ion-text>
    <p class="font-text">Informe os dados de contato do proprietário</p>
  </ion-text>

  <form [formGroup]="form">
    <usucampeao-input label="Email" controlName="email" type="email" inputmode="email" [readonly]="formDesabilitado"
      [readonly]="formDesabilitado">
      <span usu-error>{{ gerarMensagemErro('email') }}</span>
    </usucampeao-input>

    <ng-container formGroupName="phones">
      <usucampeao-input label="Telefone celular" controlName="cellphone" type="tel" inputmode="numeric"
        mask="(00)00000-0000" [readonly]="formDesabilitado" [readonly]="formDesabilitado">
        <span usu-error>{{ gerarMensagemErro('phones', 'Celular', 'cellphone') }}</span>
      </usucampeao-input>

      <usucampeao-input label="Telefone residencial" controlName="home" type="tel" inputmode="numeric"
        mask="(00)00000-0000" [readonly]="formDesabilitado" [readonly]="formDesabilitado">
        <span usu-error>{{ gerarMensagemErro('phones', 'Telefone residencial', 'home') }}</span>
      </usucampeao-input>

      <usucampeao-input label="Telefone Recado" controlName="message" type="tel" inputmode="numeric"
        mask="(00)00000-0000" [readonly]="formDesabilitado" [readonly]="formDesabilitado">
        <span usu-error>{{ gerarMensagemErro('phones', 'Telefone de recado', 'message') }}</span>
      </usucampeao-input>
    </ng-container>

    <usucampeao-input label="Nome do contato para recado" controlName="contactName" [readonly]="formDesabilitado"
      [readonly]="formDesabilitado">
      <span usu-error>{{ gerarMensagemErro('contactName') }}</span>
    </usucampeao-input>
  </form>

  <usucampeao-footer>
    <ion-button *ngIf="!formDesabilitado" mode="md" (click)="submit()">Salvar</ion-button>
  </usucampeao-footer>
</section>
