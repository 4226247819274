import { Expose, plainToInstance } from 'class-transformer';
import { PoliticaDto } from './politica.dto';

export class PoliticaListarDto {
  @Expose()
  id: number;

  @Expose()
  nome: string;

  @Expose()
  descricao: string;

  /**
  * Cria uma nova instância de PoliticaListarDto a partir de dtos
  * @param props DTO de Politica
  * @returns nova instância de PoliticaListarDto
  */
  static from(props?: PoliticaDto): PoliticaListarDto {
    return plainToInstance(PoliticaListarDto, props, { exposeUnsetFields: false, excludeExtraneousValues: true });
  }
}
