<section class="lote-s content-vertical-space-between">
  <div class="ion-padding flex-column">
    <usucampeao-header [backRoute]="rotaAnterior" title="Selecione o seu imóvel"></usucampeao-header>

    <ng-container *ngIf="!mapaVisivel; else mapaContent">
      <ion-text>
        <p class="font-text">
          Digite o endereço do terreno do seu imóvel que será regularizado.
        </p>
      </ion-text>

      <ion-button class="lote-s__botao-localizacao" mode="md" fill="outline" (click)="buscarLocalizacaoUsuario()">
        <ion-icon slot="start" name="location-outline"></ion-icon>
        Usar localização atual
      </ion-button>

      <form [formGroup]="form" (ngSubmit)="submitForm()">
        <usucampeao-input controlName="cep" label="CEP" type="tel" mask="00000-000">

          <span usu-error>{{ gerarMensagemErro('cep')}}</span>
        </usucampeao-input>

        <usucampeao-input controlName="logradouro" label="Logradouro">

          <span usu-error>{{ gerarMensagemErro('logradouro')}}</span>
        </usucampeao-input>

        <usucampeao-input controlName="numero" label="Numero">

          <span usu-error>{{ gerarMensagemErro('numero')}}</span>
        </usucampeao-input>

        <ion-button mode="md" expand="block" (click)="submitForm()">
          Localizar imóvel
        </ion-button>
      </form>
    </ng-container>

    <ng-template #mapaContent>
      <usucampeao-localizacao-container [localizacao]="localizacaoEncontrada" (aoAlterarLocalizacao)="alterarLocalizacao()">
      </usucampeao-localizacao-container>

      <ion-text>
        <h2 class="title title--medium">Selecione o seu imóvel no mapa</h2>
        <p class="font-text">
          <b>Clique no mapa</b> abaixo para selecionar o contorno do lote que corresponde ao seu imóvel.
        </p>
      </ion-text>

      <a class="lote-s__whatsapp"
        href="https://wa.me/5511971233055?text=Olá! Estou me cadastrando no aplicativo da UsuCampeão para regularizar meu imóvel, e preciso de ajuda para encontrar meu lote."
        target="blank">
        <ion-icon name="logo-whatsapp"></ion-icon>
        Preciso de ajuda
      </a>

      <span class="text-link lote-s__botao-tutorial" (click)="abrirModalTutorialSelecaoLote()">Como utilizar o mapa?</span>

      <usucampeao-mapa [mapa]="mapa" [lotes]="lotes" [lat]="localizacaoEncontrada.lat"
        [lng]="localizacaoEncontrada.long" [mostrarBusca]="false" [buscarLocalizacao]="false" [mostrarMarker]="true"
        (aoSelecionarLote)="selecionarLote($event)">
      </usucampeao-mapa>
    </ng-template>
  </div>
</section>
