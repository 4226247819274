import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingService, ToastService } from '@usucampeao/ui-mobile';
import { catchError, finalize, take, tap } from 'rxjs/operators';
import { AuthService } from '../../../state/auth.service';

@Component({
  selector: 'usucampeao-cadastrar-foto',
  templateUrl: './cadastrar-foto.component.html',
  styleUrls: ['./cadastrar-foto.component.scss']
})
export class CadastrarFotoComponent {
  public base64Image!: string;
  public foto: File;

  constructor(
    private authService: AuthService,
    private loadingService: LoadingService,
    public router: Router,
    private toastService: ToastService,
  ) { }

  public selecionarImagem(fotos: File[]): void {
    this.foto = fotos[0];
  }

  public irParaCadastroFinalizado(): void {
    this.router.navigate([`/criar-conta/cadastro-finalizado/`]);
  }

  public async cadastrarFotoDePerfil(): Promise<void> {
    await this.loadingService.createLoader();
    this.authService
      .atualizarFotoDePerfil(this.foto)
      .pipe(
        tap(() => this.irParaCadastroFinalizado()),
        catchError(error => {
          console.error(error);
          return this.toastService.error('Erro ao atualizar foto de perfil. Por favor tente novamente.');
        }),
        take(1),
        finalize(() => this.loadingService.dismiss()),
      )
      .subscribe();
  }
}
