import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { PagamentoListagemDto, PagamentoStatus } from '@usucampeao/lib-reurb-simplificado';
import { isAfter } from 'date-fns';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'usucampeao-pagamento-card',
  templateUrl: './pagamento-card.component.html',
  styleUrls: ['./pagamento-card.component.scss'],
})
export class PagamentoCardComponent {
  @Input() set pagamento(pagamento: PagamentoListagemDto) {
    this.pagamentoInformado = pagamento;
    this.configurarPropriedades();
  }

  constructor(private readonly router: Router) { }

  public pagamentoInformado: PagamentoListagemDto;
  public titulo: string;
  public descricao: string;
  public boletoNaoVencido: boolean;

  public get pagamentoStatus(): typeof PagamentoStatus {
    return PagamentoStatus;
  }

  private configurarPropriedades(): void {
    this.boletoNaoVencido = isAfter(new Date(this.pagamentoInformado.dataVencimento), new Date());
    if (this.boletoNaoVencido) {
      this.titulo = 'Boleto disponível';
      this.descricao = 'Vencimento em:';
    } else if (this.pagamentoInformado.status === PagamentoStatus.NAO_PAGO) {
      this.titulo = 'Boleto expirado';
      this.descricao = 'Expirado em:';
    } else {
      this.titulo = 'Boleto atrasado';
      this.descricao = 'Vencido em:';
    }
  }

  public navegarParaDetalhesDoPagamentoOuAbrirWhatsapp(): void {
    if (this.pagamentoInformado.status === PagamentoStatus.NAO_PAGO) {
      const textoWhatsapp = `Olá, gostaria de solicitar reemisão do boleto da parcela ${this.pagamentoInformado.parcela} do meu cadastro.`;
      window.open(`https://api.whatsapp.com/send?phone=${environment.whatsappUsucampeao}&text=${textoWhatsapp}`, '_blank');
      return;
    }

    this.router.navigate(['cadastros', this.pagamentoInformado.cadastroId, 'pagamentos', this.pagamentoInformado.id]);
  }
}
