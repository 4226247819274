import { Injectable } from "@angular/core";
import { QueryEntity } from '@datorama/akita';
import { StatusNotificacao } from '@usucampeao/lib-reurb-simplificado';
import { INotificacoesQuery } from '@usucampeao/ui-mobile';
import { map } from 'rxjs/operators';
import { NotificationsState, NotificationsStore } from './notification.store';

@Injectable({ providedIn: 'root' })
export class NotificationsQuery extends QueryEntity<NotificationsState> implements INotificacoesQuery {
  public areLoaded$ = this.select(state => {
    return state.loaded;
  });

  public quantidadeNotificacoesNaoLidas$ = this.selectAll()
    .pipe(
      map(notificacoes =>
        notificacoes.filter(notificacao => notificacao.status !== StatusNotificacao.LIDA).length
      )
    );

  constructor(protected store: NotificationsStore) {
    super(store);
  }
}
