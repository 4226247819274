import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { UsuarioAvatarModule } from '../usuario-avatar/usuario-avatar.module';
import { HeaderComponent } from './header.component';

@NgModule({
  declarations: [
    HeaderComponent
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,
    RouterModule,

    // components
    UsuarioAvatarModule,

    // external libs
    LazyLoadImageModule,
  ],
  exports: [
    HeaderComponent
  ]
})
export class HeaderModule { }
