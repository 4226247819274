import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { Observable, from } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(private toastController: ToastController) { }

  /**
   * Exibe um toast de sucesso
   * @param mensagem mensagem a ser exibida
   */
  public success(mensagem: string): Observable<void> {
    const toast$ = from(this.toastController.create({
      message: mensagem,
      icon: 'checkmark-circle-outline',
      position: 'top',
      color: 'success',
      duration: 8000,
      buttons: [
        {
          text: 'Fechar',
          role: 'cancel',
        }
      ]
    }));

    return toast$
      .pipe(
        switchMap(toast => from(toast.present()))
      );
  }

  /**
   * Exibe um toast de erro
   * @param mensagem mensagem a ser exibida
   */
  public error(mensagem: string): Observable<void> {
    const toast$ = from(this.toastController.create({
      header: 'Atenção',
      message: mensagem,
      icon: 'warning',
      position: 'top',
      color: 'danger',
      duration: 8000,
      buttons: [
        {
          text: 'Fechar',
          role: 'cancel',
        }
      ]
    }));

    return toast$
      .pipe(
        switchMap(toast => from(toast.present()))
      );
  }

  /**
  * Exibe um toast de warning
  * @param mensagem mensagem a ser exibida
  */
  public warning(mensagem: string): Observable<void> {
    const toast$ = from(this.toastController.create({
      header: 'Atenção',
      message: mensagem,
      icon: 'warning',
      position: 'top',
      color: 'warning',
      duration: 8000,
      buttons: [
        {
          text: 'Fechar',
          role: 'cancel',
        }
      ]
    }));

    return toast$
      .pipe(
        switchMap(toast => from(toast.present()))
      );
  }
}
