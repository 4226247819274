export function setConvertArray(target: any, propertyName: string, descriptor: any) {
  const originalSet = descriptor.set;

  descriptor.set = (value: any) => {
    if (!value || value === 'undefined' || value === 'null') { // TODO: precisamos validar de uma forma melhor. Diferenciar string undefined de undefined
      value = [];
    }

    if (!Array.isArray(value)) {
      value = [value];
    }

    originalSet.apply(target, [value]);
  }

  return descriptor;
}
