import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CadastroContratoTipoAssinatura } from '@usucampeao/interfaces';
import { AlteracaoDto, CadastroContratoDto, CadastroDocumentoTipo, DocumentFileDto, RegistrationStatus, StatusPreenchimento, converterDocumentoStatusParaStatusPreenchimento } from '@usucampeao/lib-reurb-simplificado';

@Component({
  selector: 'usucampeao-outros-documentos',
  templateUrl: './outros-documentos.component.html',
  styleUrls: ['./outros-documentos.component.scss']
})
export class OutrosDocumentosComponent implements OnChanges {
  @Input() dadosContrato: CadastroContratoDto;
  @Input() procuracaoDocumento: DocumentFileDto;
  @Input() contratoDocumento: DocumentFileDto;
  @Input() alteracoes: AlteracaoDto[];

  public possuiTodosOsDocumentosDosProprietarios = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dadosContrato) {
      this.possuiTodosOsDocumentosDosProprietarios = this.dadosContrato?.proprietarios?.every(proprietario => proprietario.rg?.number) ?? false;
    }
  }

  public get cadastroStatus(): RegistrationStatus {
    return RegistrationStatus.CHANGES_REQUESTED;
  }

  public get mostrarAlteracoes(): boolean {
    return this.alteracoes && this.alteracoes.length > 0;
  }

  public get statusContrato(): StatusPreenchimento {
    if (this.contratoPorLinkEAssinado) {
      return StatusPreenchimento.ENVIADO;
    }

    return converterDocumentoStatusParaStatusPreenchimento(this.contratoDocumento?.status);
  }

  public get contratoPorLinkEAssinado(): boolean {
    return this.dadosContrato?.contratoTipoAssinatura === CadastroContratoTipoAssinatura.LINK && !!this.dadosContrato?.contratoDataAssinatura;
  }

  public get statusProcuracao(): StatusPreenchimento {
    return converterDocumentoStatusParaStatusPreenchimento(this.procuracaoDocumento?.status);
  }

  public get iconStatus(): string {
    if (this.statusContrato === StatusPreenchimento.PENDENTE) {
      return "warning";
    }

    return null;
  }

  public get iconStatusColor(): string {
    if (this.statusContrato === StatusPreenchimento.PENDENTE) {
      return "warning";
    }

    return null;
  }

  public visualizarContrato(): void {
    if (this.contratoPorLinkEAssinado) {
      return;
    }

    if (!this.dadosContrato.contratoTipoAssinatura) {
      this.router.navigate([`../contrato/selecionar-tipo-assinatura`], { relativeTo: this.route });
    } else if (!this.dadosContrato.contratoDataAssinatura) {
      this.router.navigate(['../contrato/assinar'], { relativeTo: this.route })
    } else {
      this.navegarParaUpload(CadastroDocumentoTipo.CONTRATO, this.contratoDocumento?.id);
    }
  }

  public async visualizarProcuracao(): Promise<void> {
    if (!this.possuiTodosOsDocumentosDosProprietarios) {
      return;
    }

    this.navegarParaUpload(CadastroDocumentoTipo.PROCURACAO, this.procuracaoDocumento?.id);
  }

  private navegarParaUpload(tipo: CadastroDocumentoTipo, documentoId?: string): void {
    this.router.navigate(
      ['upload'],
      {
        relativeTo: this.route,
        queryParams: {
          tipo,
          ...documentoId ? { documentoId } : {}
        }
      }
    )
  }
}
