<ion-content class="ion-padding m-selecao-tipo-envio-link">
  <div class="m-selecao-tipo-envio-link__header">
    <ion-text class="ion-margin-vertical">
      <h1 class="title ion-no-margin">Envio de contrato digital</h1>
    </ion-text>
    <ion-icon name="close-outline" (click)="fecharModal()"></ion-icon>
  </div>

  <ion-text>
    <p class="font-text font-text--medium">
      Escolha para qual email ou número de whatsapp o link para assinatura de contrato será enviado:
    </p>
  </ion-text>

  <img class="m-selecao-tipo-envio-link__img" src="assets/images/contrato_link.svg" alt="Assinar contrato por link">

  <form [formGroup]="form">
    <ion-text>
      <p class="font-text font-text--medium font-text--bold">Receber o contrato por:</p>
    </ion-text>
    <ion-item class="ion-no-padding" mode="md" lines="none">
      <ion-radio-group class="m-selecao-tipo-envio-link__radio-group" formControlName="tipoEnvioLink">
        <ion-item class="ion-no-padding">
          <ion-radio color="secondary" [value]="cadastroContratoAssinaturaTipoEnvioLink.WHATSAPP" class="ion-margin-horizontal"></ion-radio>
          <ion-label>
            Whatsapp <br>
            <span class="description-text">
              {{ proprietarioWhatsapp ? '+55 ' + (proprietarioWhatsapp | mask:'(00) 00000-0000') : '-' }}
            </span>
          </ion-label>
        </ion-item>
        <ion-item class="ion-no-padding">
          <ion-radio color="secondary" [value]="cadastroContratoAssinaturaTipoEnvioLink.EMAIL" class="ion-margin-horizontal"></ion-radio>
          <ion-label>
            Email <br>
            <span class="description-text">{{ proprietarioEmail || '-' }}</span>
          </ion-label>
        </ion-item>
      </ion-radio-group>
    </ion-item>
  </form>

  <ion-text class="font-text--bold" color="primary">
    <a class="m-selecao-tipo-envio-link__botao-editar" (click)="mostrarModalEditarInfosContato()">
      <ion-icon class="ion-margin-end" name="pencil-outline"></ion-icon>
      Editar informações de contato
    </a>
  </ion-text>

  <ion-button mode="md" color="secondary" expand="full" (click)="submitForm()" >Iniciar assinatura de contrato</ion-button>
</ion-content>
