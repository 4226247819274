import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AlteracaoDto,
  DOCUMENTOS_ESTADO_CIVIL,
  DOCUMENTOS_IDENTIDADE,
  DocumentFileDto,
  DocumentFileSubtype,
  OwnerDocument
} from '@usucampeao/lib-reurb-simplificado';

@Component({
  selector: 'usucampeao-proprietarios-documentos',
  templateUrl: './proprietarios-documentos.component.html',
  styleUrls: ['./proprietarios-documentos.component.scss']
})
export class ProprietariosDocumentosComponent {
  @Input() documentos: DocumentFileDto[] = [];
  @Input() alteracoes: AlteracaoDto[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  public get documentosIdentidade(): DocumentFileDto[] {
    return this.documentos?.filter(documento => DOCUMENTOS_IDENTIDADE.includes(documento.type as OwnerDocument));
  }

  public get documentosEstadoCivil(): DocumentFileDto[] {
    return this.documentos?.filter(documento => DOCUMENTOS_ESTADO_CIVIL.includes(documento.type as OwnerDocument));
  }

  public get possuiDocumentoIdentidade(): boolean {
    return this.documentosIdentidade?.length > 0;
  }

  public get possuiDocumentoEstadoCivil(): boolean {
    return this.documentosEstadoCivil?.length > 0;
  }

  public get documentoTipo(): typeof DocumentFileSubtype {
    return DocumentFileSubtype;
  }

  public buscarNomeDocumento(documento: DocumentFileDto): string {
    if (documento.name) {
      return documento.name;
    }

    const isDocumentoIdentidade = DOCUMENTOS_IDENTIDADE.includes(documento.type as OwnerDocument);
    if (isDocumentoIdentidade) {
      return 'Documento de identidade';
    }

    return 'Comprovante de estado civil';
  }

  public selecionarDocumento(documento: DocumentFileDto): void {
    this.navegarPara('upload', documento.type, documento.id);
  }

  public navegarPara(rota: string, tipo?: string, documentoId?: string): void {
    this.router.navigate([rota], {
      relativeTo: this.route,
      ...tipo || documentoId ?
        {
          queryParams: {
            ...tipo ? { tipo } : {},
            ...documentoId ? { documentoId } : {}
          }
        }
        : {}
    });
  }
}
