import { Injectable } from '@angular/core';
import { addMinutes } from 'date-fns';

export interface LoginStorageData {
  [key: string]: {
    dataExpiracao: Date;
    confirmationResult?: any;
  }
}

@Injectable({
  providedIn: 'root'
})
export class SmsLoginService {
  private telefonesLista: LoginStorageData = {};

  public getTelefoneDataNovoCodigo(telefone: string): Date {
    return this.telefonesLista[telefone]?.dataExpiracao;
  }

  public setTelefoneDataNovoCodigo(telefone: string): void {
    const dataExpiracao = addMinutes(new Date(), 5);
    this.telefonesLista[telefone] = { dataExpiracao };
  }

  public getTelefoneConfirmationResult(telefone: string): any {
    return this.telefonesLista[telefone]?.confirmationResult;
  }

  public setTelefoneConfirmationResult(telefone: string, confirmationResult: any): void {
    this.telefonesLista[telefone].confirmationResult = confirmationResult;
  }

  public removeTelefoneDataNovoCodigo(telefone: string): void {
    delete this.telefonesLista[telefone];
  }

  public removeAll(): void {
    this.telefonesLista = {};
  }
}
