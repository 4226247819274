import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ViewWillLeave } from '@ionic/angular';
import { intervalToDuration, isAfter, isValid, subSeconds } from 'date-fns';
import { SmsLoginService } from '../../../services/sms-login.service';
import { AUTH_QUERY_INJECT_KEY, IAuthQuery } from '../../../shared';

@Component({
  selector: 'usucampeao-autenticar-numero',
  templateUrl: './autenticar-numero.component.html',
  styleUrls: ['./autenticar-numero.component.scss']
})
export class AutenticarNumeroComponent implements OnInit, ViewWillLeave {
  @Input() fone: string;

  @Output() onSetCode = new EventEmitter<string>();
  @Output() onBack = new EventEmitter<void>();
  @Output() onResendCode = new EventEmitter<void>();

  public form: FormGroup;
  public showCodePage = false;

  public pedirNovoCodigoInterval;
  public pedirNovoCodigoMinutos: number;
  public pedirNovoCodigoSegundos: number;

  constructor(
    @Inject(AUTH_QUERY_INJECT_KEY) public authQuery: IAuthQuery,
    private fb: FormBuilder,
    private smsLoginService: SmsLoginService,
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      code: [null, Validators.required]
    });

    this.iniciaContagemNovoCodigo();
  }

  ionViewWillLeave(): void {
    clearInterval(this.pedirNovoCodigoInterval);
  }

  private get pedirNovoCodigoData(): Date {
    const dataNovoPedidoCodigo = this.smsLoginService.getTelefoneDataNovoCodigo(this.fone);
    return dataNovoPedidoCodigo ? new Date(dataNovoPedidoCodigo) : null;
  }

  public get reenviarCodigoDesabilitado(): boolean {
    return !!this.pedirNovoCodigoData && isValid(this.pedirNovoCodigoData) && isAfter(this.pedirNovoCodigoData, new Date());
  }

  private iniciaContagemNovoCodigo(): void {
    if (this.reenviarCodigoDesabilitado) {
      const dataInicio = new Date();
      let dataFim = this.pedirNovoCodigoData;
      this.setarMinutoESegundo(dataInicio, dataFim);

      this.pedirNovoCodigoInterval = setInterval(() => {
        dataFim = subSeconds(dataFim, 1);
        this.setarMinutoESegundo(dataInicio, dataFim);

        if (!this.reenviarCodigoDesabilitado) {
          this.smsLoginService.removeTelefoneDataNovoCodigo(this.fone);
          clearInterval(this.pedirNovoCodigoInterval);
        }
      }, 1000);
    }
  }

  private setarMinutoESegundo(dataInicio: Date, dataFim: Date): void {
    const duracao = intervalToDuration({
      start: dataInicio,
      end: dataFim
    });
    this.pedirNovoCodigoMinutos = duracao.minutes;
    this.pedirNovoCodigoSegundos = duracao.seconds;
  }

  public async onSubmit(): Promise<void> {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }

    const { code } = this.form.value;
    this.onSetCode.emit(code);
  }

  public goBack(): void {
    this.onBack.emit();
  }

  public resendCode(): void {
    this.smsLoginService.setTelefoneDataNovoCodigo(this.fone);
    this.iniciaContagemNovoCodigo();
    this.onResendCode.emit();
  }
}
