import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProjetoSimplificadoDto, RegistrationStatus, RevisaoDto } from '@usucampeao/lib-reurb-simplificado';
import { BaseComponent, CadastroFichaDto, ImovelStateDto, LoadingService, ToastService } from '@usucampeao/ui-mobile';
import { Observable } from 'rxjs';
import { catchError, filter, finalize, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ProjetoService } from '../../core/states/projeto/projeto.service';
import { ImoveisQuery } from './imoveis/state/imoveis.query';
import { ImoveisService } from './imoveis/state/imoveis.service';
import { CadastrosQuery } from './state/cadastros.query';
import { CadastroService } from './state/cadastros.service';

@Component({
  selector: 'usucampeao-cadastros',
  templateUrl: './cadastros.component.html',
  styleUrls: ['./cadastros.component.scss']
})
export class CadastrosComponent extends BaseComponent implements OnInit {

  private id: string;
  public cadastro$: Observable<CadastroFichaDto>;
  public imovel$: Observable<ImovelStateDto>;
  public possuiPagamento: boolean;
  public quantidadePagamentosNaoPagos$: Observable<number>;
  public revisao$: Observable<RevisaoDto>;
  public projeto: ProjetoSimplificadoDto;

  constructor(
    private cadastroService: CadastroService,
    private cadastrosQuery: CadastrosQuery,
    private projetoService: ProjetoService,
    private imoveisQuery: ImoveisQuery,
    private imoveisService: ImoveisService,
    private loadingService: LoadingService,
    private route: ActivatedRoute,
    private toastService: ToastService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.route.params
      .pipe(
        tap(params => this.id = params.cadastroId),
        tap(() => this.cadastro$ = this.cadastrosQuery.buscarCadastroFicha(this.id)),
        tap(() => {
          this.imovel$ = this.cadastro$
            .pipe(
              filter(cadastro => !!cadastro),
              switchMap(cadastro => this.imoveisQuery.selectEntity(cadastro.propertyId))
            );
        }),
        tap(() => {
          this.quantidadePagamentosNaoPagos$ = this.cadastrosQuery.buscarPagamentosNaoPagos(this.id)
            .pipe(
              map(pagamentos => pagamentos.length)
            );
        }),
        tap(() => this.carregarCadastro()),
        switchMap(() => this.cadastro$
          .pipe(
            take(1),
          )
        ),
        filter(cadastro => !!cadastro && cadastro.status === RegistrationStatus.CHANGES_REQUESTED),
        switchMap(cadastro => this.cadastroService.buscarUltimaRevisao(cadastro.id, true)),
        tap(() => this.revisao$ = this.cadastrosQuery.buscarRevisao(this.id)),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe();
  }

  public async carregarCadastro(event?: any): Promise<void> {
    if (!event) {
      await this.loadingService.createLoader();
    }

    this.cadastroService.carregarCadastro(this.id)
      .pipe(
        switchMap(() => this.cadastro$.pipe(take(1))),
        switchMap(cadastro => this.projetoService.buscarDadosSimplificados(cadastro.projetoFid)),
        tap(projeto => this.projeto = projeto),
        catchError(() => this.toastService.error('Erro ao buscar cadastro. Por favor tente novamente.')),
        finalize(async () => event ? event.target.complete() : await this.loadingService.dismiss()),
      )
      .subscribe();
  }

  public async enviarParaAnalise(): Promise<void> {
    await this.loadingService.createLoader();

    this.cadastroService.enviarParaAnalise(this.id)
      .pipe(
        switchMap(() => this.toastService.success('Atendimento enviado para análise com sucesso.')),
        catchError(() => this.toastService.error('Erro ao reenviar dados para análise. Tente novamente mais tarde.')),
        finalize(() => this.loadingService.dismiss()),
      )
      .subscribe();
  }

  public async atualizarFotoFachada(arquivo: File): Promise<void> {
    await this.loadingService.createLoader();
    this.cadastro$
      .pipe(
        take(1),
        switchMap(cadastro => this.imoveisService.atualizarFotoFachada(cadastro.id, cadastro.propertyId, arquivo)),
        switchMap(() => this.toastService.success('Foto da fachada adicionada com sucesso')),
        catchError(() => this.toastService.error('Erro ao atualizar foto da fachada. Por favor tente novamente.')),
        finalize(() => this.loadingService.dismiss())
      )
      .subscribe();
  }
}
