import { Component, OnInit } from '@angular/core';
import { LoadingService, ToastService } from '@usucampeao/ui-mobile';
import { forkJoin } from 'rxjs';
import { catchError, finalize, switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { PagamentosService } from '../cadastros/pagamentos/state/pagamentos.service';
import { CadastrosQuery } from '../cadastros/state/cadastros.query';
import { CadastroService } from '../cadastros/state/cadastros.service';

@Component({
  templateUrl: 'boletos.page.html',
  styleUrls: ['boletos.page.scss'],
})
export class BoletosPage implements OnInit {
  public cadastrosDoUsuario$ = this.cadastrosQuery.cadastrosComContrato$;

  constructor(
    private cadastrosQuery: CadastrosQuery,
    private cadastrosService: CadastroService,
    private loadingService: LoadingService,
    private pagamentoService: PagamentosService,
    private toastService: ToastService,
  ) { }

  ngOnInit(): void {
    this.carregarCadastros();
  }

  public async carregarCadastros(event?: any): Promise<void> {
    if (!event) {
      await this.loadingService.createLoader();
    }

    this.cadastrosService.buscarCadastrosDoUsuario()
      .pipe(
        switchMap(cadastros => {
          const observables = cadastros
            .filter(cadastro => !!cadastro.contratoDataAssinatura)
            .map(cadastro => this.pagamentoService.buscarPagamentosPorCadastroId(cadastro.id));
          return forkJoin(observables);
        }),
        catchError(() => this.toastService.error('Erro ao carregar cadastros. Tente novamente mais tarde.')),
        finalize(() => event ? event.target.complete() : this.loadingService.dismiss()),
      )
      .subscribe();
  }

  public abrirWhatsapp(): void {
    const textoWhatsapp = `Olá! Gostaria de tirar uma dúvida sobre os meus boletos.`;
    window.open(`https://api.whatsapp.com/send?phone=${environment.whatsappUsucampeao}&text=${textoWhatsapp}`, '_blank');
  }
}
