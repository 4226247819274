import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContratoAssinaturaTipoFisicoUploadPage } from './contrato-assinatura-tipo-fisico-upload.page';

const routes: Routes = [{ path: '', component: ContratoAssinaturaTipoFisicoUploadPage }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ContratoAssinaturaTipoFisicoUploadRoutingModule { }
