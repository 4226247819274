import { Endereco, PropertyDto } from '@usucampeao/interfaces';
import { Expose, plainToInstance, Type } from 'class-transformer';
import { IsBoolean, IsEnum, IsNotEmpty, IsNumber, IsOptional, IsString, ValidateNested } from 'class-validator';
import { AddressDto } from '../address/address.dto';
import { CadastroCriarDto } from '../registration';
import { PropertySituation } from './property-situation.enum';

export class CreatePropertyDto {
  @IsString()
  @IsOptional()
  id?: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  projetoId: string;

  @Expose()
  @IsNumber()
  @IsNotEmpty()
  blockId: number;

  @Expose()
  @IsNumber()
  @IsNotEmpty()
  lotId: number;

  @Type(() => Endereco)
  @ValidateNested()
  @IsOptional()
  address?: AddressDto;

  constructor(projetoId: string, blockId: number, lotId: number, address?: AddressDto) {
    this.projetoId = projetoId;
    this.blockId = blockId;
    this.lotId = lotId;
    this.address = address;
  }
}


export class ImovelCriarDto {
  @Expose()
  @IsString()
  @IsNotEmpty()
  projetoId: string;

  @Expose()
  @IsNumber()
  @IsNotEmpty()
  blockId: number;

  @Expose()
  @IsNumber()
  @IsNotEmpty()
  lotId: number;

  @Expose()
  @Type(() => AddressDto)
  @IsNotEmpty()
  address: AddressDto;

  @Expose()
  @IsBoolean()
  @IsNotEmpty()
  possuiOutroImovel: boolean;

  @Expose()
  @IsEnum(PropertySituation)
  @IsNotEmpty()
  situation: PropertySituation;

  /**
   * Cria uma nova instância de GetUpdatePropertyLocalization a partir de dtos
   * @param props DTO de Owner
   * @returns nova instância de GetUpdatePropertyLocalization
   */
  static from(props?: PropertyDto | Partial<PropertyDto> | CadastroCriarDto): ImovelCriarDto {
    return plainToInstance(ImovelCriarDto, props, { exposeUnsetFields: false, excludeExtraneousValues: true });
  }
}
