/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { PipesModule } from '@usucampeao/utils-frontend';
import { ModalSelecaoTipoEnvioLinkModule } from '../modal-selecao-tipo-envio-link/modal-selecao-tipo-envio-link.module';
import { CadastroFichaStatusDescricaoPipe } from './cadastro-ficha-status-descricao.pipe';
import { CadastroFichaTituloPipe } from './cadastro-ficha-status-titulo.pipe';
import { CadastroStatusComponent } from './cadastro-status.component';

@NgModule({
  declarations: [
    CadastroFichaStatusDescricaoPipe,
    CadastroFichaTituloPipe,
    CadastroStatusComponent,
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,
    ModalSelecaoTipoEnvioLinkModule,

    // others
    PipesModule,
  ],
  providers: [DatePipe],
  exports: [
    CadastroStatusComponent,
  ]
})
export class CadastroStatusModule { }
