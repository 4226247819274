<section class="p-documentos">
  <usucampeao-documentos-lista title="Documentos do proprietário" [alteracoes]="alteracoes" (aoAdicionarDocumento)="navegarPara('selecionar-tipo')">
    <span description>Lista de fotos dos documentos do proprietário</span>

    <ng-container list>
      <usucampeao-menu-item *ngIf="!possuiDocumentoIdentidade" icon="document-attach-outline" title="Documento de identidade"
        comments="Enviar" [showIconStatus]="true" (click)="navegarPara('selecionar-tipo', documentoTipo.IDENTITY)"></usucampeao-menu-item>

      <ng-container *ngFor="let documento of documentosIdentidade">
        <usucampeao-menu-item icon="document-attach-outline"
          [title]="(documento.type  | documentoTipo) || buscarNomeDocumento(documento)" [showIconStatus]="true"
          [status]="documento.status" description="Documento de identidade" (click)="selecionarDocumento(documento)"></usucampeao-menu-item>
      </ng-container>

      <usucampeao-menu-item *ngIf="!possuiDocumentoEstadoCivil" icon="document-attach-outline"
        title="Comprovante de estado civil" comments="Enviar" [showIconStatus]="true" (click)="navegarPara('selecionar-tipo', documentoTipo.MARITAL_STATUS)"></usucampeao-menu-item>

      <ng-container *ngFor="let documento of documentosEstadoCivil">
        <usucampeao-menu-item icon="document-attach-outline"
          [title]="(documento.type  | documentoTipo) || buscarNomeDocumento(documento)" [showIconStatus]="true"
          [status]="documento.status" description="Comprovante de estado civil" (click)="selecionarDocumento(documento)"></usucampeao-menu-item>
      </ng-container>
    </ng-container>
  </usucampeao-documentos-lista>
</section>
