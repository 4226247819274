export enum ProjetoHistoricoTipo {
  CRIAR_PROJETO = 'PROJETO_CRIADO',
  EDITAR_DADOS_BASICOS = 'EDITAR_DADOS_BASICOS',
  EDITAR_DADOS_COMERCIAIS = 'EDITAR_DADOS_COMERCIAIS',
  EDITAR_IMAGEM = 'EDITAR_IMAGEM',
  EDITAR_MAPA = 'EDITAR_MAPA',
  CRIAR_TABELA_PRECO = 'CRIAR_TABELA_PRECO',
  EDITAR_TABELA_PRECO = 'EDITAR_TABELA_PRECO',
  HABILITAR_VENDAS = 'HABILITAR_VENDAS',
  DESABILITAR_VENDAS = 'DESABILITAR_VENDAS',
  EDITAR_QUADRAS = 'EDITAR_QUADRAS',
  EDITAR_COMISSAO_TIME = 'EDITAR_COMISSAO_TIME',
  EDITAR_COMISSAO_PARCEIRO = 'EDITAR_COMISSAO_PARCEIRO',
  ADICIONAR_CUPOM_DESCONTO = 'ADICIONAR_CUPOM_DESCONTO',
  ALTERAR_QUANTIDADE_CUPOM_DESCONTO = 'ALTERAR_QUANTIDADE_CUPOM_DESCONTO',
  AVANCAR_STATUS = 'AVANCAR_STATUS',
  ALTERAR_STATUS = 'ALTERAR_STATUS',
  REMOVER_CUPOM_DESCONTO = 'REMOVER_CUPOM_DESCONTO',
  DESATIVAR_CUPOM_DESCONTO = 'DESATIVAR_CUPOM_DESCONTO',
}
