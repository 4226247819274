export class RelatorioVendasProjetosDto {
  quantidades: number[];
  valores: number[];
  projetos: string[];

  constructor() {
    this.quantidades = [];
    this.valores = [];
    this.projetos = [];
  }
}
