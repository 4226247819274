import { Component, Inject, Input } from '@angular/core';
import { IMAGES_PATH } from '@usucampeao/utils-frontend';

@Component({
  selector: 'usucampeao-usuario-avatar',
  templateUrl: './usuario-avatar.component.html',
  styleUrls: ['./usuario-avatar.component.scss']
})
export class UsuarioAvatarComponent {
  @Input() size: 'small' | 'medium' | 'large';
  @Input() src: string;

  constructor(
    @Inject(IMAGES_PATH) protected imagesPath: string
  ) { }

  public get imagemDefault(): string {
    return `${this.imagesPath}/person-circle-outline.svg`;
  }
}
