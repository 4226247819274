import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PagamentoListagemDto } from '@usucampeao/lib-reurb-simplificado';
import { BaseComponent, CadastroFichaDto, LoadingService, ToastService } from '@usucampeao/ui-mobile';
import { Observable } from 'rxjs';
import { catchError, finalize, take, takeUntil, tap } from 'rxjs/operators';
import { CadastrosQuery } from '../state/cadastros.query';
import { PagamentosService } from './state/pagamentos.service';

@Component({
  selector: 'usucampeao-pagamentos',
  templateUrl: './pagamentos.component.html',
  styleUrls: ['./pagamentos.component.scss']
})
export class PagamentosComponent extends BaseComponent implements OnInit {
  private cadastroId: string;
  public cadastro$: Observable<CadastroFichaDto>;
  public pagamentos$: Observable<PagamentoListagemDto[]>;

  constructor(
    private cadastrosQuery: CadastrosQuery,
    private loadingService: LoadingService,
    private pagamentosService: PagamentosService,
    private route: ActivatedRoute,
    private toastService: ToastService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.route.params
      .pipe(
        tap(params => this.cadastroId = params.cadastroId),
        tap(() => this.cadastro$ = this.cadastrosQuery.buscarCadastroFicha(this.cadastroId)),
        tap(() => this.pagamentos$ = this.cadastrosQuery.buscarPagamentos(this.cadastroId)),
        tap(() => this.buscarPagamentos()),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe()
  }

  public async buscarPagamentos(event?: any): Promise<void> {
    if (!event) {
      await this.loadingService.createLoader();
    }

    this.pagamentosService.buscarPagamentosPorCadastroId(this.cadastroId)
      .pipe(
        catchError(() => this.toastService.error('Erro ao buscar pagamentos. Por favor tente novamente')),
        take(1),
        finalize(async () => event ? event.target.complete() : await this.loadingService.dismiss()),
      )
      .subscribe();
  }

}
