<ion-content class="ion-padding" scrollY="false">
  <ion-text>
    <h1 class="title ion-no-margin">Outro bairro</h1>
  </ion-text>

  <ion-text class="font-text">
    <p class="ion-margin-top">Infelizmente no momento o seu bairro não está disponível na plataforma.</p>
    <p>Para mais informações envie um e-mail para
      <a href="mailto:ajuda@poderegularizar.com.br" target="_blank">ajuda@poderegularizar.com.br</a>.
    </p>
  </ion-text>

  <ion-grid>
    <ion-row class="ion-justify-content-end">
      <ion-col size="auto">
        <a (click)="dismiss()"><b>Entendi</b></a>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
