<div class="t-valores">
  <table>
    <tbody>
      <tr>
        <td class="t-valores__column" colspan="2">
          <span class="t-valores__subtitle">Valor do serviço</span>
        </td>
        <td class="t-valores__column">{{ cadastro.valorServico | currency: 'BRL' }}
        </td>
      </tr>
      <tr>
        <td class="t-valores__column" colspan="2">
          <span class="t-valores__subtitle">Desconto</span>
        </td>
        <td class="t-valores__column">{{ cadastro.valorDesconto ? (cadastro.valorDesconto | currency: 'BRL') : '-' }}
        </td>
      </tr>

      <ng-container *ngIf="pagamentoAVista">
        <tr>
          <td class="t-valores__column" rowspan="2" valign="top">
            <span class="t-valores__subtitle">Parcela única</span>
          </td>
          <td class="t-valores__column">
            <span class="t-valores__subtitle">Valor</span>
          </td>
          <td class="t-valores__column">{{ (cadastro.valorServico - cadastro.valorDesconto) | currency: 'BRL' }}</td>
        </tr>
        <tr>
          <td class="t-valores__column">
            <span class="t-valores__subtitle">Vencimento</span>
          </td>
          <td class="t-valores__column">{{ cadastro.entradaVencimento | date: 'dd/MM/yyyy' }}</td>
        </tr>
      </ng-container>

      <ng-container *ngIf="!pagamentoAVista">
        <tr>
          <td class="t-valores__column" rowspan="2" valign="top">
            <span class="t-valores__subtitle">Entrada</span>
          </td>
          <td class="t-valores__column">
            <span class="t-valores__subtitle">Valor</span>
          </td>
          <td class="t-valores__column">{{ cadastro.valorEntrada | currency: 'BRL' }}</td>
        </tr>
        <tr>
          <td class="t-valores__column">
            <span class="t-valores__subtitle">Vencimento</span>
          </td>
          <td class="t-valores__column">{{ cadastro.entradaVencimento | date: 'dd/MM/yyyy' }}</td>
        </tr>
        <tr>
          <td class="t-valores__column" rowspan="3" valign="top">
            <span class="t-valores__subtitle">Parcelas</span>
          </td>
          <td class="t-valores__column">
            <span class="t-valores__subtitle">Valor</span>
          </td>
          <td class="t-valores__column">{{ cadastro.valorParcela | currency: 'BRL' }}</td>
        </tr>
        <tr>
          <td class="t-valores__column">
            <span class="t-valores__subtitle">Vencimento</span>
          </td>
          <td class="t-valores__column">Dia {{ cadastro.diaVencimento }}, mensal</td>
        </tr>
        <tr>
          <td class="t-valores__column">
            <span class="t-valores__subtitle">Qtde.</span>
          </td>
          <td class="t-valores__column">{{ cadastro.totalParcelas }}</td>
        </tr>
        <tr>
          <td class="t-valores__column" rowspan="2" valign="top">
            <span class="t-valores__subtitle">Parcelamento</span>
          </td>
          <td class="t-valores__column">
            <span class="t-valores__subtitle">Tabela</span>
          </td>
          <td class="t-valores__column">PRICE</td>
        </tr>
        <tr>
          <td class="t-valores__column">
            <span class="t-valores__subtitle">Juros</span>
          </td>
          <td class="t-valores__column">{{ cadastro.jurosMes || 0 }}% a.m.</td>
        </tr>
      </ng-container>
      <tr>
        <td class="t-valores__column" rowspan="2" valign="top">
          <span class="t-valores__subtitle">Atraso</span>
        </td>
        <td class="t-valores__column">
          <span class="t-valores__subtitle">Multa</span>
        </td>
        <td class="t-valores__column">{{ cadastro.multa || 0 }}%</td>
      </tr>
      <tr>
        <td class="t-valores__column">
          <span class="t-valores__subtitle">Juros</span>
        </td>
        <td class="t-valores__column">{{ cadastro.juros || 0 }}% a.m.</td>
      </tr>
      <!-- <tr>
        <td class="t-valores__column" colspan="2">
          <span class="t-valores__subtitle">Valor a ser pago na entrega</span>
        </td>
        <td class="t-valores__column">R$ 5.000,00</td>
      </tr>
      <tr>
        <td class="t-valores__column" rowspan="2" valign="top">
          <span class="t-valores__subtitle">Correção monetária</span>
        </td>
        <td class="t-valores__column">
          <span class="t-valores__subtitle">Índice</span>
        </td>
        <td class="t-valores__column">IPCA</td>
      </tr>
      <tr>
        <td class="t-valores__column">
          <span class="t-valores__subtitle">Periodicidade</span>
        </td>
        <td class="t-valores__column">A cada 12 meses</td>
      </tr> -->
    </tbody>
  </table>
</div>
