import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { LocalizacaoContainerComponent } from './localizacao-container.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
  ],
  declarations: [LocalizacaoContainerComponent],
  exports: [LocalizacaoContainerComponent],
})
export class LocalizacaoContainerModule { }
