import { Expose, plainToInstance } from 'class-transformer';
import { IsBoolean, IsEnum, IsNumber, IsOptional, ValidateIf } from 'class-validator';
import { MeioPagamento } from '../pagamento';
import { DiaPagamento } from '../pagamento/dia-pagamento.enum';
import { RegistrationDto } from './registration.dto';

export class CadastraDadosPagamentoDto {
  @Expose()
  @IsNumber()
  @IsOptional()
  valorContrato: number;

  @Expose()
  @IsNumber()
  @IsOptional()
  valorServico: number;

  @Expose()
  @IsNumber()
  @IsOptional()
  valorEntrada: number;

  @Expose()
  @IsNumber()
  @IsOptional()
  valorAverbacao?: number;

  @Expose()
  @IsNumber()
  @IsOptional()
  valorParcela: number;

  @Expose()
  @IsNumber()
  @IsOptional()
  totalParcelas: number;

  @Expose()
  @IsEnum(MeioPagamento)
  @IsOptional()
  meioPagamento: MeioPagamento;

  @Expose()
  @IsEnum(DiaPagamento)
  @IsOptional()
  @ValidateIf(data => data.totalParcelas)
  diaVencimento: DiaPagamento;

  @Expose()
  @IsEnum(DiaPagamento)
  @IsOptional()
  diaEntrada: DiaPagamento;

  @Expose()
  @IsBoolean()
  @IsOptional()
  averbacao?: boolean;

  static from(props: Partial<RegistrationDto>): CadastraDadosPagamentoDto {
    return plainToInstance(CadastraDadosPagamentoDto, props, { excludeExtraneousValues: true, exposeUnsetFields: false });
  }
}

export interface CadastroPagamentosResumo {
  valorPendente: number;
  valorVencido: number;
  valorEmAnalise: number;
  valorPago: number;
  saldoDevedor: number;
}
