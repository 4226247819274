import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { FooterModule } from '@usucampeao/ui-mobile';
import { CadastroCompletoRoutingModule } from './cadastro-completo-routing.module';
import { CadastroCompletoComponent } from './cadastro-completo.component';


@NgModule({
  declarations: [
    CadastroCompletoComponent
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,

    // route
    CadastroCompletoRoutingModule,

    // components
    FooterModule,
  ]
})
export class CadastroCompletoModule { }
