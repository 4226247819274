<div class="p-ficha">
  <ion-text>
    <h2 class="title title--medium ion-margin-vertical">{{ titulo }}</h2>
  </ion-text>

  <div class="p-ficha__row p-ficha__row--space-between">
    <div class="p-ficha__row">
      <ion-icon class="p-ficha__icon" name="person-outline"></ion-icon>
      <ion-text>
        <p class="font-text p-ficha__nome">{{ proprietarioSelecionado?.name }}</p>
        <p class="font-text font-text--extra-small ion-no-margin" [class.font-text--error]="mostrarFlagAlteracoesNecessarias">
          {{ mostrarFlagAlteracoesNecessarias ? 'Alteração necessária' : 'Informações pessoais e documentos' }}
        </p>
      </ion-text>
    </div>
    <ion-button *ngIf="!proprietarioSelecionado?.main" class="p-ficha__remover" fill="clear" color="danger"
      (click)="mostrarAlertaRemocaoConjuge()">
      <ion-icon slot="icon-only" name="trash"></ion-icon>
    </ion-button>
  </div>

  <div class="p-ficha__actions">
    <ion-button mode="md" (click)="editarProprietario()">Editar</ion-button>
    <ion-button *ngIf="mostrarBotaoAdicionarConjuge" class="ion-margin-top" mode="md" fill="outline"
      (click)="adicionarConjuge()">
      <ion-icon slot="start" name="person-add"></ion-icon>
      Adicionar cônjuge
    </ion-button>
  </div>
</div>
