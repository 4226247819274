<ion-item class="projeto-item ion-no-padding" lines="full">
  <ion-label>
    <div class="projeto-item__endereco">
      <ion-text>
        <p class="projeto-item__rua ion-no-margin">{{ projeto.nome }}</p>
      </ion-text>
      <ion-note color="medium" class="font-text--small ion-margin-bottom">
        {{ projeto.cidade }}, {{ projeto.estado }}
      </ion-note>
    </div>

    <ion-text *ngIf="projeto.nucleo_distancia">
      <p class="ion-no-margin">
        Distância: {{
        projeto.nucleo_distancia > 1 ?
        (projeto.nucleo_distancia | number: '1.1-1') + ' km' :
        ((projeto.nucleo_distancia * 1000)) + ' m'
        }}
      </p>
    </ion-text>
    <ion-text color="primary" *ngIf="mostrarLabelCompativel">
      <span class="font-text-bold font-text--small">Compatível com seu local</span>
    </ion-text>
  </ion-label>


  <ion-button slot="end" (click)="selecionarProjeto()"> Escolher </ion-button>
</ion-item>
