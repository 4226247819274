import { Component, Input } from '@angular/core';
import { StatusPreenchimento } from '@usucampeao/lib-reurb-simplificado';


@Component({
  selector: 'usucampeao-status-preenchimento',
  templateUrl: 'status-preenchimento.component.html',
  styleUrls: ['status-preenchimento.component.scss'],
})
export class StatusPreenchimentoComponent {
  @Input() set status(status: StatusPreenchimento) {
    this.statusPreenchimento = status;
    this.configurarIconeStatus();
  }

  public statusPreenchimento: StatusPreenchimento;
  public iconeStatus = 'ellipse-outline';
  public iconeStatusCor = 'medium';

  private configurarIconeStatus(): void {
    switch (this.statusPreenchimento) {
      case StatusPreenchimento.APROVADO:
      case StatusPreenchimento.ENVIADO:
        this.iconeStatus = 'checkmark-circle';
        this.iconeStatusCor = 'success';
        break;

      case StatusPreenchimento.ALTERACAO_NECESSARIA:
        this.iconeStatus = 'close-circle';
        this.iconeStatusCor = 'danger';
        break;

      default:
        this.iconeStatus = 'alert-circle';
        this.iconeStatusCor = 'warning';
        break;
    }
  }
}
