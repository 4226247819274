import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'editar',
    pathMatch: 'full',
  },
  {
    path: 'editar',
    loadChildren: () => import('./imoveis-editar/imoveis-editar.page.module').then(m => m.ImoveisEditarPageModule)
  },
  {
    path: 'mapa',
    loadChildren: () => import('./imoveis-localizacao/imoveis-localizacao.page.module').then(m => m.ImoveisLocalizacaoPageModule)
  },
  {
    path: 'envio-documentos/upload',
    loadChildren: () => import('./imoveis-documentos-upload/imoveis-documentos-upload.page.module').then(m => m.ImoveisDocumentosUploadPageModule)
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ImoveisRoutingModule { }
