import { Component, Input } from '@angular/core';
import { StatusPreenchimento } from '@usucampeao/lib-reurb-simplificado';

@Component({
  selector: 'usucampeao-menu-item-v2',
  templateUrl: 'menu-item-v2.component.html',
  styleUrls: ['menu-item-v2.component.scss'],
})
export class MenuItemV2Component {
  @Input() icone: string;
  @Input() titulo: string;
  @Input() status: StatusPreenchimento;
}
