import { Endereco } from '@usucampeao/interfaces';
import { Expose, plainToInstance } from 'class-transformer';
import { TabelaPrecoDetalhesDto } from '../tabela-preco';
import { ProjetoConfigurarDetalhesComerciaisDto } from './projeto-configurar-detalhes-comerciais.dto';
import { ProjetoVendaStatus } from './projeto-venda-status.enum';
import { ProjetoDto } from './projeto.dto';

export class ProjetoDetalhesComerciaisDto extends ProjetoConfigurarDetalhesComerciaisDto {
  @Expose()
  vendaStatus: ProjetoVendaStatus;

  /**
   * Cria uma nova instância de ProjetoDetalhesComerciaisDto a partir de dtos
   * @param props DTO de ProjetoDetalhesComerciaisDto
   * @returns nova instância de ProjetoDetalhesComerciaisDto
   */
  static from(props?: ProjetoDto | Partial<ProjetoDto>): ProjetoDetalhesComerciaisDto {
    return plainToInstance(ProjetoDetalhesComerciaisDto, props, { exposeUnsetFields: false, excludeExtraneousValues: true });
  }
}

export class ProjetoDetalhesDto extends ProjetoDetalhesComerciaisDto {
  @Expose()
  id: string;

  @Expose()
  nome: string;

  @Expose()
  localizacao: Endereco;

  @Expose()
  parcelamentoValorMinimo: number;

  @Expose()
  parcelamentoMaximoSemJuros: number;

  @Expose()
  parcelamentoMaximo: number;

  @Expose()
  valorTotalMinimo: number;

  @Expose()
  semPagamento?: boolean;

  @Expose()
  reurbGratuito?: boolean;

  /**
   * Cria uma nova instância de ProjetoDetalhesDto a partir de dtos
   * @param props DTO de Projeto
   * @returns nova instância de ProjetoDetalhesComerciaisDto
   */
  static from(props?: ProjetoDto | Partial<ProjetoDto>, tabelasPreco: TabelaPrecoDetalhesDto[] = []): ProjetoDetalhesDto {
    const projetoDetalhes = plainToInstance(ProjetoDetalhesDto, props, { exposeUnsetFields: false, excludeExtraneousValues: true });
    if (!tabelasPreco?.length) {
      return projetoDetalhes;
    }

    const valorTotalMinimo = tabelasPreco.reduce((valorEfetivo, tabela) => {
      if (!valorEfetivo || tabela.valorEfetivo < valorEfetivo) {
        return tabela.valorEfetivo;
      }
      return valorEfetivo;
    }, 0);

    const parcelamentoMaximoSemJuros = tabelasPreco.reduce((parcelamento, tabela) => {
      if (tabela.parcelasSemJuros > parcelamento) {
        return tabela.parcelasSemJuros;
      }
      return parcelamento;
    }, 0);

    const parcelamentoValorMinimo = tabelasPreco.reduce((valorEfetivo, tabela) => {
      const valorParcelaMinima = tabela.parcelas.reduce((valorParcela, parcela) => {
        if (!valorParcela || parcela.valorParcela < valorParcela) {
          return parcela.valorParcela;
        }

        return valorParcela;
      }, Infinity);

      if (valorParcelaMinima < valorEfetivo) {
        return valorParcelaMinima;
      }

      return valorEfetivo;
    }, Infinity);

    const parcelamentoMaximo = tabelasPreco.reduce((parcelamento, tabela) => {
      const quantidadeParcelasMaxima = tabela.parcelas.reduce((quantidadeParcelas, parcela) => {
        const parcelaAtual = +parcela.parcela;
        if (parcelaAtual > quantidadeParcelas) {
          return parcelaAtual;
        }
        return quantidadeParcelas;
      }, 0);

      if (quantidadeParcelasMaxima > parcelamento) {
        return quantidadeParcelasMaxima;
      }

      return parcelamento;
    }, 0);

    projetoDetalhes.valorTotalMinimo = valorTotalMinimo;
    projetoDetalhes.parcelamentoMaximoSemJuros = parcelamentoMaximoSemJuros;
    projetoDetalhes.parcelamentoValorMinimo = parcelamentoValorMinimo;
    projetoDetalhes.parcelamentoMaximo = parcelamentoMaximo;
    return projetoDetalhes;
  }
}
