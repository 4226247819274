import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlteracaoDto, GetUpdateOwnerDocumentsDto, TipoAlteracao } from '@usucampeao/lib-reurb-simplificado';
import { BaseComponent, LoadingService, NavigationService, ToastService } from '@usucampeao/ui-mobile';
import { Observable, iif, of } from 'rxjs';
import { catchError, finalize, switchMap, takeUntil, tap } from 'rxjs/operators';
import { CadastrosQuery } from '../../state/cadastros.query';
import { CadastroService } from '../../state/cadastros.service';
import { ProprietariosQuery } from '../state/proprietarios.query';
import { ProprietariosService } from '../state/proprietarios.service';

@Component({
  templateUrl: './proprietarios-dados-documentos.page.html',
  styleUrls: ['./proprietarios-dados-documentos.page.scss']
})
export class ProprietariosDadosDocumentosPage extends BaseComponent implements OnInit {
  private proprietarioId: string;
  private cadastroId: string;

  public alteracoes$: Observable<AlteracaoDto[]>;
  public dadosDocumentos$: Observable<GetUpdateOwnerDocumentsDto>;
  public formDesabilitado$: Observable<boolean>;

  constructor(
    private cadastrosQuery: CadastrosQuery,
    private cadastroService: CadastroService,
    private proprietariosQuery: ProprietariosQuery,
    private proprietariosService: ProprietariosService,
    private loadingService: LoadingService,
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    private toastService: ToastService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.route.params
      .pipe(
        tap(params => this.cadastroId = params.cadastroId),
        tap(params => this.proprietarioId = params.proprietarioId),
        tap(() => this.carregarDadosDocumentos()),
        tap(() => this.dadosDocumentos$ = this.proprietariosQuery.buscarDadosDocumentos(this.proprietarioId)),
        tap(() => this.formDesabilitado$ = this.cadastrosQuery.cadastroEstaDesabilitadoParaEdicao(this.cadastroId)),
        tap(() => this.alteracoes$ = this.cadastrosQuery.buscarAlteracoes$(this.cadastroId, [TipoAlteracao.PROPRIETARIO_DADOS_DOCUMENTOS], this.proprietarioId)),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe();
  }

  public async carregarDadosDocumentos(event?: any): Promise<void> {
    if (!event) {
      await this.loadingService.createLoader();
    }

    this.proprietariosService.buscarDadosDocumentos(this.cadastroId, this.proprietarioId)
      .pipe(
        switchMap(() => iif(() =>
          this.cadastrosQuery.cadastroEstaEmAlteracoesNecessarias(this.cadastroId),
          this.cadastroService.buscarUltimaRevisao(this.cadastroId, true),
          of()
        )),
        catchError(() => this.toastService.error('Erro ao buscar documentos do proprietário. Por favor tente novamente.')),
        finalize(async () => event ? event.target.complete() : await this.loadingService.dismiss()),
      )
      .subscribe();
  }

  public async alterarDadosDocumentos(dadosContato: GetUpdateOwnerDocumentsDto): Promise<void> {
    await this.loadingService.createLoader();
    this.proprietariosService.atualizarDadosDocumentos(this.cadastroId, this.proprietarioId, dadosContato)
      .pipe(
        tap(() => this.navigationService.voltar()),
        catchError(() => this.toastService.error('Erro ao atualizar documentos do proprietário. Por favor tente novamente.')),
        finalize(async () => await this.loadingService.dismiss()),
      )
      .subscribe();
  }
}
