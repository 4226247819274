import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { InfiniteScrollCustomEvent } from '@ionic/angular';
import { ProjetosListarComDistancia } from '@usucampeao/lib-reurb-simplificado';
import { BaseComponent, CarregarNovaPaginaProjetosOutput, LoadingService, LocalizacaoLatLong, ToastService } from '@usucampeao/ui-mobile';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { ProjetoService } from '../../../core/states/projeto/projeto.service';

const TAMANHO_PAGINA = 20;
@Component({
  templateUrl: 'projetos.page.html',
  styleUrls: ['projetos.page.scss'],
})
export class ProjetosPage extends BaseComponent {
  public projetosProximos: ProjetosListarComDistancia[] = [];
  public projetos: ProjetosListarComDistancia[] = [];
  private paginaAtual = 1;

  constructor(
    private loadingService: LoadingService,
    private projetoService: ProjetoService,
    private router: Router,
    private toastService: ToastService,
  ) {
    super();
  }

  public async carregarProjetosProximos(localizacao: LocalizacaoLatLong): Promise<void> {
    if (!localizacao) {
      return;
    }

    await this.loadingService.createLoader();
    this.projetoService.listarProjetosProximos(localizacao.lat, localizacao.long)
      .pipe(
        tap(projetos => this.projetosProximos = projetos),
        finalize(async () => await this.loadingService.dismiss()),
        catchError(error => {
          this.toastService.error('Erro ao buscar projetos. Por favor, tente novamente.').toPromise();
          throw error;
        }),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe();
  }

  public async carregarNovaPaginaProjetos(params?: CarregarNovaPaginaProjetosOutput): Promise<void> {
    this.paginaAtual++;
    this.carregarTodosOsProjetos({ lat: params?.lat, long: params?.long }, params?.eventoScrollInfinito);
  }

  /**
   * Realiza uma nova busca de todos os projetos.
   * Os projetos anteriores serão removidos e a busca será realizada novamente.
   *
   * @param params Parâmetros de busca de projetos
   * @param params.lat Latitude do ponto de referência
   * @param params.long Longitude do ponto de referência
   */
  public realizarNovaBuscaDeTodosOsProjetos(params?: Partial<LocalizacaoLatLong>): void {
    this.projetos = [];
    this.carregarTodosOsProjetos(params);
  }

  private async carregarTodosOsProjetos(params?: Partial<LocalizacaoLatLong>, eventoScrollInfinito?: InfiniteScrollCustomEvent): Promise<void> {
    if (!eventoScrollInfinito) {
      await this.loadingService.createLoader();
    }

    this.projetoService.listarTodosOsProjetosDisponiveis({
      latitude: params.lat,
      longitude: params.long,
      pagina: this.paginaAtual,
      tamanho_pagina: TAMANHO_PAGINA,
    })
      .pipe(
        tap(projetos => this.projetos = this.projetos.concat(projetos.items)),
        finalize(async () => {
          if (eventoScrollInfinito) {
            eventoScrollInfinito.target.complete();
          } else {
            await this.loadingService.dismiss();
          }
        }),
        catchError(async error => {
          this.toastService.error('Erro ao buscar projetos. Por favor, tente novamente.').toPromise();
          throw error;
        }),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe();
  }

  public irParaDetalhesDoProjeto(projetoId: string): void {
    this.router.navigate(['novo-cadastro/bairro', projetoId]);
  }
}
