import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProprietariosPage } from './proprietarios.page';

const routes: Routes = [
  { path: '', component: ProprietariosPage },
  {
    path: ':proprietarioId/editar',
    loadChildren: () => import('./proprietarios-editar/proprietarios-editar.page.module').then(m => m.ProprietariosEditarPageModule)
  },
  {
    path: ':proprietarioId/documentos',
    loadChildren: () => import('./proprietarios-dados-documentos/proprietarios-dados-documentos.page.module').then(m => m.ProprietariosDadosDocumentosPageModule)
  },
  {
    path: ':proprietarioId/contato',
    loadChildren: () => import('./proprietarios-dados-contato/proprietarios-dados-contato.page.module').then(m => m.ProprietariosDadosContatoPageModule)
  },
  {
    path: ':proprietarioId/envio-documentos',
    loadChildren: () => import('./proprietarios-documentos/proprietarios-documentos.page.module').then(m => m.ProprietariosDocumentosPageModule)
  },
  {
    path: ':proprietarioId/envio-documentos/selecionar-tipo',
    loadChildren: () => import('./proprietarios-documentos-selecionar-tipo/proprietarios-documentos-selecionar-tipo.page.module').then(m => m.ProprietariosDocumentosSelecionarTipoPageModule)
  },
  {
    path: ':proprietarioId/envio-documentos/upload',
    loadChildren: () => import('./proprietarios-documentos-upload/proprietarios-documentos-upload.page.module').then(m => m.ProprietariosDocumentosUploadPageModule)
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProprietariosRoutingModule { }
