import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { ContractModule } from '@usucampeao/lib-contratos';
import { ModalContratoProcuracaoComponent } from './modal-contrato-procuracao.component';

@NgModule({
  declarations: [
    ModalContratoProcuracaoComponent
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,

    // components
    ContractModule,
  ],
  exports: [
    ModalContratoProcuracaoComponent
  ]
})
export class ModalContratoProcuracaoModule { }
