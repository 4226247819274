<div class="p-ficha">
  <div class="p-ficha__header">
    <usucampeao-usuario-avatar></usucampeao-usuario-avatar>
    <ion-text>
      <h2 class="p-ficha__name title title--medium ion-margin-vertical">{{ proprietario?.name || 'Não informado' }}</h2>
    </ion-text>
  </div>

  <div class="p-ficha__content">
    <usucampeao-menu-item icon="person-outline" title="Dados básicos"
      description="Informações pessoais, estado civil e profissão" [status]="proprietario?.fillInStatus?.personalData"
      [showIconStatus]="true" (click)="navegarPara('dados-basicos')"></usucampeao-menu-item>

    <usucampeao-menu-item icon="id-card-outline" title="Documentos" description="Informações dos seus documentos"
      [status]="proprietario?.fillInStatus?.documentsData" [showIconStatus]="true"
      (click)="navegarPara('documentos')"></usucampeao-menu-item>

    <usucampeao-menu-item icon="call-outline" title="Contatos" description="Informações de contato"
      [status]="proprietario?.fillInStatus?.contactData" [showIconStatus]="true"
      (click)="navegarPara('contato')"></usucampeao-menu-item>

    <usucampeao-menu-item icon="document-attach-outline" title="Documentos enviados"
      description="Fotos dos seus documentos" [status]="proprietario?.fillInStatus?.documents" [showIconStatus]="true"
      (click)="navegarPara('envio-documentos')">
    </usucampeao-menu-item>
  </div>
</div>
