<div class="contrato-tipo-assinatura">
  <h2 class="contrato-tipo-assinatura__title">{{ titulo }}</h2>

  <div class="contrato-tipo-assinatura__content">
    <img class="contrato-tipo-assinatura__img" [src]="imageUrl">

    <div class="contrato-tipo-assinatura__description">
      <p>{{ descricao1 }}</p>
      <p>{{ descricao2 }}</p>

      <div class="contrato-tipo-assinatura__actions contrato-tipo-assinatura__actions--desktop" *ngIf="!isMobile">
        <ng-container *ngTemplateOutlet="actions"></ng-container>
      </div>
    </div>
  </div>

  <div class="contrato-tipo-assinatura__actions contrato-tipo-assinatura__actions--mobile" *ngIf="isMobile">
    <ng-container *ngTemplateOutlet="actions"></ng-container>
  </div>
</div>

<ng-template #actions>
  <ng-content></ng-content>
</ng-template>
