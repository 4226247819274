<ion-content class="ion-padding">
  <section class="content-vertical-space-between">
    <usucampeao-header mode="md" backRoute=".." title="Ajuda"></usucampeao-header>

    <div class="help__content">
      <ion-text>
        <h1 class="title title--large">Precisa de ajuda?</h1>
        <p>Entre em contato gratuitamente através do telefone ou do email abaixo</p>
      </ion-text>

      <div class="help__flex ion-margin-vertical">
        <ion-icon class="icon-phone" color="primary" name="phone-portrait-outline"></ion-icon>
        <a class="font-text font-text--extra-large color" href="tel:08005911328">
          0800 591 1328
        </a>
      </div>

      <div class="help__flex">
        <ion-icon color="primary" class="icon-mail" name="mail-outline"></ion-icon>
        <a class="font-text font-text--large color" href="mailto:ajuda@poderegularizar.com.br">
          ajuda@poderegularizar.com.br
        </a>
      </div>
    </div>

    <usucampeao-footer>
      <ion-button mode="md" fill="outline" expand="block" (click)="navigateBack()">Voltar</ion-button>
    </usucampeao-footer>
  </section>
</ion-content>
