import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private isLoading = false;
  constructor(private loadingCtrl: LoadingController) { }

  public async createLoader(): Promise<void> {
    if (!this.isLoading) {
      this.isLoading = true;
      const loader = await this.loadingCtrl.create();
      await loader.present();
      if (!this.isLoading) {
        await loader.dismiss();
      }
    }
  }

  public async dismiss(): Promise<void> {
    if (this.isLoading) {
      const top = await this.loadingCtrl.getTop();
      if (top) {
        await top.dismiss();
      }
      this.isLoading = false;
    }
  }
}
