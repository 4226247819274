import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContratoFechadoGuard } from '../auth/guard/contrato-fechado.guard';

const routes: Routes = [
  { path: '', redirectTo: 'selecionar-bairro', pathMatch: 'full' },
  {
    path: 'selecionar-bairro',
    loadChildren: () =>
      import('./projetos/projetos.page.module').then((m) => m.ProjetosPageModule)
  },
  {
    path: 'bairro/:id',
    loadChildren: () =>
      import('./projeto-detalhes/projeto-detalhes.page.module').then((m) => m.ProjetoDetalhesPageModule),
  },
  {
    path: 'bairro/:id/selecionar-lote',
    loadChildren: () =>
      import('./projetos-mapa/projeto-mapa.page.module').then((m) => m.ProjetoMapaPageModule),
  },
  {
    path: 'bairro/:id/cadastro/:cadastroId/selecionar-lote',
    canActivate: [ContratoFechadoGuard],
    loadChildren: () =>
      import('./projetos-mapa/projeto-mapa.page.module').then((m) => m.ProjetoMapaPageModule),
  },
  {
    path: 'bairro/:id/cadastrar-dados-imovel',
    loadChildren: () =>
      import('./endereco-infos-adicionais/endereco-infos-adicionais.page.module').then((m) => m.EnderecoInfosAdicionaisPageModule)
  },
  {
    path: 'bairro/:id/cadastro/:cadastroId/cadastrar-dados-imovel',
    canActivate: [ContratoFechadoGuard],
    loadChildren: () =>
      import('./endereco-infos-adicionais/endereco-infos-adicionais.page.module').then((m) => m.EnderecoInfosAdicionaisPageModule)
  },
  {
    path: 'bairro/:id/cadastro/:cadastroId/cadastrar-dados-proprietario',
    canActivate: [ContratoFechadoGuard],
    loadChildren: () =>
      import('./proprietarios-dados-basicos/proprietarios-dados-basicos.page.module').then((m) => m.ProprietariosDadosBasicosPageModule),
  },
  {
    path: 'bairro/:id/cadastro/:cadastroId/contrato',
    canActivate: [ContratoFechadoGuard],
    loadChildren: () =>
      import('../cadastros/contrato/contrato.page.module').then((m) => m.ContratoModule),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CadastrosNovoRoutingModule { }
