import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CadastroComentarioListaDto } from '@usucampeao/lib-reurb-simplificado';

@Component({
  selector: 'usucampeao-comentarios',
  templateUrl: './comentarios.component.html',
  styleUrls: ['./comentarios.component.scss']
})
export class ComentariosComponent {
  @Input() comentarios: CadastroComentarioListaDto[] = [];
  @Input() set resetarForm(resetarForm: boolean) {
    if (resetarForm) {
      this.form.reset();
    }
  };
  @Output() aoSubmeter = new EventEmitter<string>();

  public form: FormGroup;

  constructor(
    private fb: FormBuilder,
  ) {
    this.form = this.fb.group({
      comentario: [null, Validators.required],
    });
  }

  public submit(): void {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    const { comentario } = this.form.value;
    this.aoSubmeter.emit(comentario);
  }
}
