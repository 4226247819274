import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from '@usucampeao/utils-frontend';
import { NgxMaskModule } from 'ngx-mask';
import { ContratosV1Component } from './contratos/contratos-v1/contratos-v1.component';
import { DeclaracaoDeautonomoRendaComponent } from './contratos/contratos-v1/declaracao-de-autonomo-e-renda/declaracao-de-autonomo-e-renda';
import { DeclaracaoDeDomicilioComponent } from './contratos/contratos-v1/declaracao-de-domicilio';
import { DeclaracaoDeVizinhosComponent } from './contratos/contratos-v1/declaracao-de-vizinhos';
import { MoradorAverbacaoComponent } from './contratos/contratos-v1/morador-averbacao';
import { MoradorUsucampeaoReurbAverbacaoComponent } from './contratos/contratos-v1/morador-usucampeao-reurb-averbacao';
import { MoradorUsucampeao2021Component } from './contratos/contratos-v1/morador-usucampeao_2021';
import { ProcuracaoAverbacaoComponent } from './contratos/contratos-v1/procuracao-averbacao';
import { ProcuracaoReurbAverbacaoComponent } from './contratos/contratos-v1/procuracao-reurb-averbacao';
import { QuestionarioJuridicoComponent } from './contratos/contratos-v1/questionario-juridico';
import { ContratosV2Component } from './contratos/contratos-v2/contratos-v2.component';
import { RegularizacaoFundiariaV2Component } from './contratos/contratos-v2/regularizacao-fundiaria-v2/regularizacao-fundiaria-v2.component';
import { TabelaPagamentosComponent } from './contratos/contratos-v2/regularizacao-fundiaria-v2/tabela-pagamentos/tabela-pagamentos.component';
import { TabelaValoresComponent } from './contratos/contratos-v2/regularizacao-fundiaria-v2/tabela-valores/tabela-valores.component';
import { ReurbGratuitoV2Component } from './contratos/contratos-v2/reurb-gratuito-v2/reurb-gratuito-v2.component';
import { PrintContractComponent } from './print-contract/print-contract.component';

@NgModule({
  imports: [
    CommonModule,
    NgxMaskModule.forRoot(),
    PipesModule,
  ],
  declarations: [
    ContratosV1Component,
    DeclaracaoDeautonomoRendaComponent,
    DeclaracaoDeDomicilioComponent,
    DeclaracaoDeVizinhosComponent,
    MoradorAverbacaoComponent,
    MoradorUsucampeao2021Component,
    MoradorUsucampeaoReurbAverbacaoComponent,
    ProcuracaoAverbacaoComponent,
    ProcuracaoReurbAverbacaoComponent,
    QuestionarioJuridicoComponent,
    PrintContractComponent,
    ContratosV2Component,
    RegularizacaoFundiariaV2Component,
    ReurbGratuitoV2Component,
    TabelaValoresComponent,
    TabelaPagamentosComponent,
  ],
  exports: [
    PrintContractComponent,
  ],
})
export class ContractModule { }
