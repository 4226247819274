import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomePage } from './home.page';

const routes: Routes = [
  {
    path: '',
    component: HomePage,
  },
  // {
  //   path: 'pagamento/:id/:registration',
  //   loadChildren: () =>
  //     import('../../pages/registration/pagamento/detalhes/detalhes.module').then((m) => m.DetalhesPageModule),
  // }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomePageRoutingModule { }
