import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { ProjetosMapaPageModule } from '@usucampeao/ui-mobile';
import { ImoveisLocalizacaoRoutingModule } from './imoveis-localizacao-routing.module';
import { ImoveisLocalizacaoPage } from './imoveis-localizacao.page';


@NgModule({
  declarations: [
    ImoveisLocalizacaoPage
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,

    // route
    ImoveisLocalizacaoRoutingModule,

    // components
    ProjetosMapaPageModule,
  ]
})
export class ImoveisLocalizacaoPageModule { }
