<div id="print-section" class="contract">
  <div class="contract__title">
    <h1>CONTRATO DE ADESÃO PARA A PRESTAÇÃO DE SERVIÇOS DE REGULARIZAÇÃO FUNDIÁRIA</h1>
  </div>
  <div class="contract__personal-data">
    <table class="contract__table">
      <tr>
        <td>
          <p>
            Por este instrumento particular, de um lado,
            <span class="contract__subtitle">NOME:</span> {{ proprietario?.name }},
          </p>
        </td>
      </tr>
      <tr>
        <td>
          <p>
            <span class="contract__subtitle">CPF:</span> {{ proprietario?.cpf }}, <span
              class="contract__subtitle">RG:</span> {{ proprietario?.rg?.number || '' }},
            <span class="contract__subtitle">EMISSOR/UF:</span> {{ proprietario?.rg?.issuer || '' }}/{{
            proprietario?.rg?.state || ''}}
          </p>
        </td>
      </tr>
      <tr *ngIf="(proprietario?.maritalStatus === estadoCivil.MARRIED) && conjuge ">
        <td>
          <p>
            <span class="contract__subtitle">CÔNJUGE/COMPANHEIRO:</span> {{ conjuge?.name }}, <span
              class="contract__subtitle">CPF:</span> {{
            conjuge?.cpf }}
          </p>
        </td>
      </tr>
      <tr>
        <td>
          <p>
            <span class="contract__subtitle">Rua:</span> {{ imovel?.address?.street }} Nº.{{ imovel?.address?.number }}
            <ng-container *ngIf="imovel?.address?.complement">
              <span class="contract__subtitle">Complemento: </span> {{ imovel?.address?.complement }}
            </ng-container>
            <span class="contract__subtitle">Bairro: </span> {{ imovel?.address?.neighborhood }} <span
              class="contract__subtitle">Cidade: </span> {{
            imovel?.address?.city }} <span class="contract__subtitle">UF: </span> {{ imovel?.address?.state }}
          </p>
        </td>
      </tr>
      <tr *ngIf="cadastro?.reurbModalidade">
        <td>
          <p>
            <span class="contract__subtitle">Condições REURB:</span> Renda Familiar menor que 5(cinco) salários mínimos
            e não ter sido
            beneficiado pela REURB
            anteriormente. <br />
            (&nbsp; <ng-container *ngIf="cadastro.reurbModalidade === reurbModalidade.REURB_S">X </ng-container>)
            REURB–S ou (&nbsp;
            <ng-container *ngIf="cadastro.reurbModalidade === reurbModalidade.REURB_E">X </ng-container>) REURB–E
          </p>
        </td>
      </tr>
      <tr>
        <td>
          <p>
            <span class="contract__subtitle">Qual o segmento de uso do imóvel:</span> (&nbsp;
            <ng-container *ngIf="imovel?.usage === imovelTipo.RESIDENTIAL">X </ng-container>) Residencial (&nbsp;
            <ng-container *ngIf="imovel?.usage === imovelTipo.COMMERCIAL">X </ng-container>) Comercial (&nbsp;
            <ng-container *ngIf="imovel?.usage === imovelTipo.MIXED">X </ng-container>) Misto
          </p>
        </td>
      </tr>
      <tr>
        <td>
          <p>
            <span class="contract__subtitle">Estado Civil:</span> (&nbsp;
            <ng-container *ngIf="proprietario?.maritalStatus === estadoCivil.MARRIED">X</ng-container>&nbsp;)
            Casado(a) (&nbsp;
            <ng-container *ngIf="proprietario?.maritalStatus === estadoCivil.SINGLE">X</ng-container>&nbsp;)
            Solteiro(a) (&nbsp;
            <ng-container *ngIf="proprietario?.maritalStatus === estadoCivil.WIDOWED">X</ng-container>&nbsp;) Viúvo(a)
            (&nbsp;
            <ng-container *ngIf="proprietario?.maritalStatus === estadoCivil.SEPARATED">X</ng-container>&nbsp;)
            Separado(a)
          </p>
        </td>
      </tr>
      <tr>
        <td>
          <p>
            <span class="contract__subtitle">E-mail: </span> {{ proprietario?.email }} <span class="contract__subtitle">
              Telefone: </span> {{
            proprietario?.phones?.cellphone | mask: '(00) 00000-0000' || ''}}
          </p>
        </td>
      </tr>
      <tr>
        <td>
          <p>
            <span class="contract__subtitle">Observações: </span>
          </p>
        </td>
      </tr>
    </table>
  </div>
  <div class="contract__content">
    <p>
      Doravante
      <ng-container *ngIf="!conjuge"> denominado <span class="contract__subtitle">"CONTRATANTE"</span></ng-container>
      <ng-container *ngIf="conjuge"> denominados <span class="contract__subtitle">"CONTRATANTES"</span></ng-container>;
      e, de outro lado,
      <span class="contract__subtitle">USUCAMPEÃO TECNOLOGIA EM REGULARIZAÇÃO IMOBILIÁRIA LTDA.</span>, pessoa jurídica
      de
      direito privado, com SEDE na Rua Claudio Soares 72, cj 614/618 Pinheiros, São Paulo – SP, inscrita no CNPJ/MF sob
      o nº 36.174.049/0001-08, e FILIAL Rua José Rodrigues Pereira 514, Estoril, Belo Horizonte – MG inscrita no CNPJ/MF
      <span class="contract__subtitle">36.174.049/0002-99</span>, neste ato representada de acordo com seus atos
      constitutivos, doravante
      denominado <span class="contract__subtitle"><u>"USUCAMPEÃO"</u></span>,
      <span class="contract__subtitle">CONTRATO DE PRESTAÇÃO DE SERVIÇOS DE
        REGULARIZAÇÃO
        FUNDIÁRIA</span>, regido pelas seguintes cláusulas:
    </p>

    <div class="contract__clausula">
      <p>
        <span class="contract__subtitle">Cláusula Primeira – OBJETO</span><br />
        <span class="contract__subtitle">1.1.</span> Este Contrato estabelece as condições pelas quais a <span
          class="contract__subtitle">USUCAMPEÃO</span> prestará
        <ng-container *ngIf="!conjuge"> ao <span class="contract__subtitle">CONTRATANTE</span> </ng-container>
        <ng-container *ngIf="conjuge"> aos <span class="contract__subtitle">CONTRATANTES</span> </ng-container> serviços
        de regularização
        fundiária “REURB”, em três etapas, visando realizar os procedimentos necessários para obtenção da titulação da
        unidade imobiliária da qual
        <ng-container *ngIf="!conjuge"> o <span class="contract__subtitle">CONTRATANTE</span> </ng-container>
        <ng-container *ngIf="conjuge"> os <span class="contract__subtitle">CONTRATANTES</span> </ng-container> detém a
        posse, nos termos da Lei
        Federal 13.465/17 e Decreto Federal n° 9.310/2018.
        <br />
        <span class="contract__subtitle">1.1.1.</span> Os serviços serão realizados conforme as seguintes etapas:
        <br />
        <span class="contract__subtitle">a.</span> PRIMEIRA ETAPA: Coleta de dados e pré-análise - 10% (dez porcento)
        dos serviços;
        <br />
        <span class="contract__subtitle">b.</span> SEGUNDA ETAPA: Engenharia, Memorial do Perímetro, Levantamento
        Planialtimétrico Cadastral do
        Núcleo e Projeto Urbanístico do Núcleo - 20% (vinte porcento) dos serviços;
        <br />
        <span class="contract__subtitle">c.</span> TERCEIRA ETAPA: Protocolo REURB e entrega da Certidão de
        Regularização Fundiária (CRF) - 70%
        (setenta porcento) dos serviços.
      </p>
    </div>
    <div class="contract__clausula">
      <p>
        <span class="contract__subtitle">Cláusula Segunda – RESPONSABILIDADE DAS PARTES</span><br />
        <span class="contract__subtitle">2.1.</span> São obrigações
        <ng-container *ngIf="!conjuge"> do <span class="contract__subtitle">CONTRATANTE</span> </ng-container>
        <ng-container *ngIf="conjuge"> dos <span class="contract__subtitle">CONTRATANTES</span> </ng-container> efetuar
        o pagamento na forma da
        Cláusula Terceira e fornecer todas as informações e proprietarios solicitados pela
        <span class="contract__subtitle">USUCAMPEÃO</span> na execução dos Serviços.<br />
        <span class="contract__subtitle">2.2.</span> São obrigações da <span
          class="contract__subtitle">USUCAMPEÃO</span> a execução dos Serviços constantes na
        Cláusula Primeira e no Anexo 1 (escopo do serviço).
      </p>
    </div>
    <div class="contract__clausula">
      <p>
        <span class="contract__subtitle">Cláusula Terceira – REMUNERAÇÃO E FORMA DE PAGAMENTO</span><br />
        <ng-container *ngIf="cadastro?.meioPagamento">
          <span class="contract__subtitle">3.1.</span> Pela prestação dos Serviços objeto deste Contrato,
          <ng-container *ngIf="!conjuge"> o <span class="contract__subtitle">CONTRATANTE</span> pagará </ng-container>
          <ng-container *ngIf="conjuge"> os <span class="contract__subtitle">CONTRATANTES</span> pagarão </ng-container>
          a quantia <u>total</u> de {{ (cadastro?.valorContrato | currency: 'BRL') }} ({{cadastro.valorContrato |
          numeroExtenso: numeroExtensoTipo.MONETARIO}}) a
          ser paga da seguinte forma:
          <ng-container *ngIf="cadastro?.meioPagamento === meioPagamento.BOLETO">
            <ng-container *ngIf="cadastro.valorEntrada && cadastro?.totalParcelas === 0">
              No boleto à vista.
            </ng-container>

            <ng-container *ngIf="cadastro.valorEntrada && cadastro?.totalParcelas">
              No boleto com entrada de {{ (cadastro.valorEntrada | currency: 'BRL') }} ({{ cadastro.valorEntrada|
              numeroExtenso: numeroExtensoTipo.MONETARIO }}) e
              em mais {{ cadastro?.totalParcelas }}x parcelas de {{ (cadastro.valorParcela | currency: 'BRL') }}
              ({{cadastro.valorParcela | numeroExtenso: numeroExtensoTipo.MONETARIO }}).
            </ng-container>

            <ng-container *ngIf="!cadastro.valorEntrada && cadastro?.totalParcelas">
              No boleto em {{ cadastro?.totalParcelas }}x de {{ (cadastro.valorParcela | currency: 'BRL') }} ({{
              cadastro.valorParcela | numeroExtenso }}).
            </ng-container>
          </ng-container>
          <ng-container *ngIf="cadastro?.meioPagamento === meioPagamento.CARTAO_CREDITO">
            No cartão de crédito em até {{ cadastro?.totalParcelas }}x.
          </ng-container>
        </ng-container>
        <br />

        <span class="contract__subtitle">3.2.</span> Havendo impontualidade no cumprimento da obrigação
        <ng-container *ngIf="!conjuge"> pelo <span class="contract__subtitle">CONTRATANTE</span> </ng-container>
        <ng-container *ngIf="conjuge"> pelos <span class="contract__subtitle">CONTRATANTES</span> </ng-container>, este
        incorrerá em multa de
        2% (dois por cento) sobre o valor em atraso corrigido e juros moratórios de 1% (um por cento) ao mês, sob o
        valor devido. <br />
        <span class="contract__subtitle">3.3.</span> Os direitos reais a serem atribuídos
        <ng-container *ngIf="!conjuge"> ao <span class="contract__subtitle">CONTRATANTE</span>, estarão definidos
        </ng-container>
        <ng-container *ngIf="conjuge"> aos <span class="contract__subtitle">CONTRATANTES</span>, estarão definidos
        </ng-container> na Certidão
        de Regularização Fundiária, a ser expedida pela Prefeitura, na forma do artigo 41 da lei 13.465/17. Para ser
        beneficiário dos citados direitos e receber a titulação de sua unidade imobiliária, o nome e a unidade
        imobiliária
        <ng-container *ngIf="!conjuge">do <span class="contract__subtitle">CONTRATANTE</span> </ng-container>
        <ng-container *ngIf="conjuge"> dos <span class="contract__subtitle">CONTRATANTES</span> </ng-container>
        deverão constar na CRF, conforme artigo 41, inciso VI da lei 13.465/17. Assim sendo,
        <ng-container *ngIf="!conjuge"> fica o <span class="contract__subtitle">CONTRATANTE</span> ciente</ng-container>
        <ng-container *ngIf="conjuge"> ficam os <span class="contract__subtitle">CONTRATANTES</span>
          cientes</ng-container>
        de que, tanto seu nome, como a descrição de sua unidade imobiliária, somente constarão da CRF após quitação
        total do preço pactuado no item 3.1. Com fulcro no parágrafo primeiro do artigo 14 da lei 13.465/17, a
        <span class="contract__subtitle">USUCAMPEÃO</span> entregará, para a Prefeitura, todos os projetos necessários e
        previstos nos artigos
        35 e 36 da mesma lei, acompanhados da lista dos ocupantes de cada unidade imobiliária que tenham quitado o preço
        estipulado no item 3.1; mais o memorial descritivo de cada unidade. Quando todos esses proprietarios técnicos
        estiverem devidamente concluídos, ANTES de encaminhar para a Prefeitura, a <span
          class="contract__subtitle">USUCAMPEÃO</span>, entrará
        em contato com
        <ng-container *ngIf="!conjuge"> o <span class="contract__subtitle">CONTRATANTE</span> </ng-container>
        <ng-container *ngIf="conjuge"> os <span class="contract__subtitle">CONTRATANTES</span> para oferecer
        </ng-container>
        <ng-container *ngIf="!conjuge"> para oferecer ao <span class="contract__subtitle"> mesmo</span>, as seguintes
          opções: </ng-container>
        <ng-container *ngIf="conjuge"> para oferecer aos <span class="contract__subtitle">mesmos</span>, as seguintes
          opções: </ng-container>
        <br />
        <span class="contract__subtitle">3.3.1.</span>&nbsp;<u>Efetuar a quitação total do preço pactuado.</u> Nesse
        caso,
        <ng-container *ngIf="!conjuge">o nome do <span class="contract__subtitle">CONTRATANTE</span> já constará
        </ng-container>
        <ng-container *ngIf="conjuge"> os nomes dos <span class="contract__subtitle">CONTRATANTES</span> já constarão
        </ng-container>
        da primeira lista a ser a ser apresentada para a Prefeitura para inserir na CRF, a fim de que, após o registro
        da CRF, seja
        <u>aberta matrícula individualizada da unidade imobiliária em nome
          <ng-container *ngIf="!conjuge"> do <span class="contract__subtitle">CONTRATANTE</span>. </ng-container>
          <ng-container *ngIf="conjuge"> dos <span class="contract__subtitle">CONTRATANTES</span>.</ng-container>
        </u>
        <br />
        <span class="contract__subtitle">3.3.2.</span>&nbsp;<u>Continuar pagando o preço de forma parcelada,</u> como
        pactuado na assinatura do
        presente contrato. Nesse caso,
        <u>
          <ng-container *ngIf="!conjuge">o nome do <span class="contract__subtitle">CONTRATANTE</span> somente será
            informado </ng-container>
          <ng-container *ngIf="conjuge">
            os nomes dos <span class="contract__subtitle">CONTRATANTES</span> somente serão informados
          </ng-container>
          para a Prefeitura após a quitação do preço total
        </u>, ou seja, após a finalização do parcelamento. Assim sendo, após essa finalização, a
        <span class="contract__subtitle">USUCAMPEÃO</span> informará a Prefeitura, solicitando que
        <ng-container *ngIf="!conjuge">o nome do <span class="contract__subtitle">CONTRATANTE</span> seja inserido
        </ng-container>
        <ng-container *ngIf="conjuge"> os nomes dos <span class="contract__subtitle">CONTRATANTES</span> sejam inseridos
        </ng-container>
        em lista complementar da CRF, na forma do artigo 23, §6º da lei 13.465/17 (cadastro complementar).<br />
        <span class="contract__subtitle">3.3.3.</span> Em ambos os casos, a unidade imobiliária será titulada em nome
        <ng-container *ngIf="!conjuge">do <span class="contract__subtitle">CONTRATANTE</span> </ng-container>
        <ng-container *ngIf="conjuge"> dos <span class="contract__subtitle">CONTRATANTES</span> </ng-container>,
        variando apenas o momento que
        essa titulação ocorrerá.<br />
        <span class="contract__subtitle">3.3.4.</span> Poderá, se entender viável, após análise técnica e financeira,
        dispensar a tramitação
        prevista nos itens 3.3, 3.3.1,3.3.2 e 3.3.3, já inserindo
        <ng-container *ngIf="!conjuge"> o nome do <span class="contract__subtitle">CONTRATANTE</span> </ng-container>
        <ng-container *ngIf="conjuge"> os nomes dos <span class="contract__subtitle">CONTRATANTES</span> </ng-container>
        na primeira lista a
        ser apresentada para a Prefeitura.<br />
        <span class="contract__subtitle">3.4.</span>
        <ng-container *ngIf="!conjuge"> Fica o <span class="contract__subtitle">CONTRATANTE</span> ciente</ng-container>
        <ng-container *ngIf="conjuge"> Ficam os <span class="contract__subtitle">CONTRATANTES</span> cientes
        </ng-container>
        de que todos os projetos técnicos que serão apresentados para a Prefeitura, são de uso exclusivo da
        <span class="contract__subtitle">USUCAMPEÃO</span>, a qual detém os direitos autorais dos mesmos.
      </p>
    </div>
    <div class="contract__clausula">
      <p>
        <span class="contract__subtitle">Cláusula Quarta – RESCISÃO</span><br />
        <span class="contract__subtitle">4.1.</span> Este Contrato poderá ser rescindido imotivadamente
        <ng-container *ngIf="!conjuge"> pelo <span class="contract__subtitle">CONTRATANTE</span> </ng-container>
        <ng-container *ngIf="conjuge"> pelos <span class="contract__subtitle">CONTRATANTES</span> </ng-container>, desde
        que seja enviada
        notificação prévia, com antecedência mínima de 15 (quinze) dias, gerando as seguintes consequências:
        <br />
        <span class="contract__subtitle">4.1.1.</span> Se a rescisão ocorrer por parte
        <ng-container *ngIf="!conjuge"> do <span class="contract__subtitle">CONTRATANTE</span> </ng-container>
        <ng-container *ngIf="conjuge"> dos <span class="contract__subtitle">CONTRATANTES</span> </ng-container> durante
        a PRIMEIRA ETAPA citada
        no item 1.1.2.a, os seguintes valores deverão ser pagos à <span class="contract__subtitle">USUCAMPEÃO</span>
        <br />
        <span class="contract__subtitle">4.1.1.1.</span> 20% (vinte porcento) do valor global do contrato por multa
        contratual;
        <br />
        <span class="contract__subtitle">4.1.1.2.</span> 10% (dez porcento) do valor global do contrato referente aos
        serviços da primeira
        etapa.
        <br />
        <span class="contract__subtitle">4.1.2.</span> Se a rescisão ocorrer por parte
        <ng-container *ngIf="!conjuge"> do <span class="contract__subtitle">CONTRATANTE</span> </ng-container>
        <ng-container *ngIf="conjuge"> dos <span class="contract__subtitle">CONTRATANTES</span> </ng-container> durante
        a SEGUNDA ETAPA citada
        no item 1.1.2.2. , os seguintes valores deverão ser pagos à <span class="contract__subtitle">USUCAMPEÃO</span>
        <br />
        <span class="contract__subtitle">4.1.2.1.</span> 20% (vinte porcento) do valor global do contrato por multa
        contratual;
        <br />
        <span class="contract__subtitle">4.1.2.2.</span> 10% (dez porcento) do valor global do contrato referente aos
        serviços da primeira
        etapa;
        <br />
        <span class="contract__subtitle">4.1.2.3.</span> 20% (vinte porcento) do valor global do contrato referente aos
        serviços prestados na
        segunda etapa.
        <br />
        <span class="contract__subtitle">4.1.3.</span> Se a rescisão ocorrer por parte
        <ng-container *ngIf="!conjuge"> do <span class="contract__subtitle">CONTRATANTE</span> </ng-container>
        <ng-container *ngIf="conjuge"> dos <span class="contract__subtitle">CONTRATANTES</span> </ng-container> após o
        protocolo de REURB junto
        a municipalidade, os seguintes valores deverão ser pagos à <span class="contract__subtitle">USUCAMPEÃO</span>
        <br />
        <span class="contract__subtitle">4.1.3.1.</span> 20% (vinte porcento) do valor global do contrato por multa
        contratual;
        <br />
        <span class="contract__subtitle">4.1.3.2.</span> 10% (dez porcento) do valor global do contrato referente aos
        serviços da primeira
        etapa;
        <br />
        <span class="contract__subtitle">4.1.3.3.</span> 20% (vinte porcento) do valor global do contrato referentes aos
        serviços prestados na
        segunda etapa;
        <br />
        <span class="contract__subtitle">4.1.3.4.</span> 20% (vinte porcento) do valor global do contrato referentes aos
        custos da terceira
        etapa.
        <br />
        <span class="contract__subtitle">4.2.</span> No caso de rescisão por motivo de indeferimento do processo
        administrativo de REURB pela
        Prefeitura, <span class="contract__subtitle">por fato não atribuível à USUCAMPEÃO,</span> não haverá a
        restituição de nenhum dos
        valores pagos até o momento do indeferimento. A mesma condição se aplica em caso da negativa de registro do
        projeto de regularização fundiária por parte do Cartório de Registro de Imóveis, por fato também não atribuível
        à USUCAMPEÃO.
        <br />
      </p>
    </div>
    <div class="contract__clausula">
      <p>
        <span class="contract__subtitle">Cláusula Quinta – DISPOSIÇÕES GERAIS</span><br />
        <span class="contract__subtitle">5.1.</span> A <span class="contract__subtitle">USUCAMPEÃO</span> poderá ceder o
        recebível para instituições financeiras,
        ficando expressamente autorizada para tanto
        <ng-container *ngIf="!conjuge">pelo <span class="contract__subtitle">CONTRATANTE.</span></ng-container>
        <ng-container *ngIf="conjuge">pelos <span class="contract__subtitle">CONTRATANTES.</span></ng-container>
        <br />
        <span class="contract__subtitle">5.2.</span> Nenhuma das Partes poderá ser considerada como inadimplente de suas
        obrigações contratuais
        se tal fato for resultante de caso fortuito ou força maior.<br />
        <span class="contract__subtitle">5.3.</span> O Presente Contrato se refere exclusivamente ao serviço de
        regularização fundiária na
        esfera administrativa perante a Prefeitura.<br />
        <span class="contract__subtitle">5.3.1.</span> Não estão contempladas neste contrato, despesas com tributos
        inerentes à unidade
        imobiliária, taxas/preços públicos municipais e emolumentos de cartórios.
        <br />
        <span class="contract__subtitle">5.3.2.</span> Não estão contemplados neste contrato, eventuais procedimentos
        judiciais que sejam
        necessários para a viabilização da regularização.
        <br />
        <span class="contract__subtitle">5.3.3.</span> Conforme itens 3.3.1 e 3.3.2, a entrega da Certidão de
        Regularização Fundiária (CRF)
        está condicionada ao pagamento total dos serviços
        <ng-container *ngIf="!conjuge"> pelo <span class="contract__subtitle">CONTRATANTE</span> </ng-container>
        <ng-container *ngIf="conjuge"> pelos <span class="contract__subtitle">CONTRATANTES</span> </ng-container>.
        <br />
      </p>
    </div>
    <div class="contract__clausula">
      <p>
        <span class="contract__subtitle">Cláusula Sexta – FORO</span><br />
        <span class="contract__subtitle">6.1.</span> Para dirimir quaisquer controvérsias oriundas deste Contrato, as
        Partes elegem o Foro da
        Comarca de São Paulo, Estado de São Paulo. E, por estarem assim, justas e contratadas, firmam o presente
        Contrato em 2 (duas) vias de igual teor e forma, na presença das testemunhas abaixo, para que produza seus
        regulares efeitos de direito.
      </p>
      <p>&nbsp;</p>
      <p>{{ cadastro?.projetoMunicipio }}, {{ cadastro?.contractDate | date: 'longDate'}}.</p>
    </div>

    <div class="contract__subscription" *ngIf="isPrint">
      <div class="contract__subscription__table">
        <div class="contract__subscription__column">
          <p>______________________________________________</p>
          <p>CONTRATANTE/OUTORGANTE</p>
          <div class="contract__subscription__column__infos">
            <p>Representante: {{ proprietario?.name }}</p>
            <p>CPF: {{ proprietario?.cpf }}</p>
          </div>
        </div>
        <div class="contract__subscription__column contract__subscription__column--no-border-left">
          <img class="contract__seo-subscription" alt="Assinatura CEO" src="/assets/image/assinatura.svg" />
          <p>______________________________________________</p>
          <p>NATHALIE</p>
          <div class="contract__subscription__column__infos">
            <p>USUCAMPEÃO</p>
            <p>CNPJ: 36.174.049/0001-08</p>
          </div>
        </div>

        <div class="contract__subscription__column contract__subscription__column--no-border-top" *ngIf="conjuge">
          <p>______________________________________________</p>
          <p>CONTRATANTE/OUTORGANTE</p>
          <div class="contract__subscription__column__infos">
            <p>Representante: {{ conjuge?.name }}</p>
            <p>CPF: {{ conjuge?.cpf }}</p>
          </div>
        </div>

        <div class="contract__subscription__column contract__subscription__column--no-border-top">
          <p>______________________________________________</p>
          <div class="contract__subscription__column__infos">
            <p>Testemunhas</p>
            <p>CPF:</p>
          </div>
        </div>

        <div class="contract__subscription__column contract__subscription__column--no-border-top">
          <p>______________________________________________</p>
          <div class="contract__subscription__column__infos">
            <p>Testemunhas</p>
            <p>CPF:</p>
          </div>
        </div>
      </div>
    </div>

    <div class="contract__attachment" style="margin-top: 2rem;">
      <h1>
        ANEXO I<br />
        ESCOPO DO SERVIÇO
      </h1>

      <p>
        <span class="contract__subtitle">1.1.</span> Análise detalhada do imóvel e da posse, as medidas jurídicas,
        urbanísticas, ambientais e
        sociais com a finalidade de incorporar o imóvel informal ao ordenamento territorial urbano do município com a
        sua consequente titulação.
      </p>
      <p><span class="contract__subtitle">1.2.</span> Ingresso com processo administrativo de REURB, perante a
        municipalidade;</p>
      <p>
        <span class="contract__subtitle">1.3.</span> Elaboração do Projeto de Regularização Fundiária, conforme o artigo
        30 do Decreto Federal
        nº 9.310 de 2018, e artigo 35 da Lei Federal 13.465 de 16 de julho de 2017:
      </p>
      <p>
        <span class="contract__subtitle">1.3.1.</span> Levantamento planialtimétrico e cadastral com
        georreferenciamento, que demonstrará as
        unidades, as construções, o sistema viário, as áreas públicas, os acidentes geográficos e os demais elementos
        caracterizadores do núcleo a ser regularizado;
      </p>
      <p><span class="contract__subtitle">1.3.2.</span> Estudo das desconformidades e da situação jurídica, urbanística
        e ambiental;</p>
      <p><span class="contract__subtitle">1.3.3.</span> Projeto urbanístico;</p>
      <p><span class="contract__subtitle">1.3.4.</span> Memorial descritivo do imóvel;</p>
      <p>
        <span class="contract__subtitle"> 1.3.5.</span> Elaboração de cronograma físico de serviços e de obras de
        infraestrutura essencial,
        compensações urbanísticas, ambientais, com orientação referente às responsabilidades decorrentes da Assinatura
        de Termo de Compromisso se for o caso;
      </p>
      <p>
        <span class="contract__subtitle">1.3.6.</span> Toda a <span class="contract__subtitle"><u>gestão de processo
            administrativo</u></span> poderá ser acompanhada
        pelo site: <span class="contract__subtitle"><u>www.usucampeao.com.br</u></span> com as seguintes
        funcionalidades:
      </p>
      <p><span class="contract__subtitle">1.3.6.1.</span> Sistema de gestão seguro com <i>login</i> e senha na
        Plataforma digital;</p>
      <p>
        <span class="contract__subtitle">1.3.6.2.</span> Possibilidade de realizar o cadastramento dos dados pessoais e
        da posse da
        propriedade;
      </p>
      <p>
        <span class="contract__subtitle">1.3.6.3.</span> Serviços de mensagem para fornecer informações sobre o
        andamento do processo com os
        ocupantes;
      </p>
      <p>
        <span class="contract__subtitle">1.3.6.4.</span> Serviços de inclusão de proprietarios pessoais e que comprovam
        a posse das
        propriedades;
      </p>
      <p>
        <span class="contract__subtitle"> 1.3.6.5.</span> Sistema de meios de pagamento dos valores do serviço através
        da plataforma, através
        do cartão de crédito e boleto bancário;
      </p>
    </div>
  </div>
</div>
