import { SearchProps } from '@usucampeao/utils';
import { Expose, plainToInstance } from 'class-transformer';
import { UsuarioBackofficeDto, UsuarioBackofficeStatus, UsuarioBackofficeTipo } from '.';

export type UsuarioBackofficeSearchParams = SearchProps & {
  status?: UsuarioBackofficeStatus[];
}

export interface UsuarioBackofficeListaSimplificadaDto {
  id: string;
  nome: string;
}

export class UsuarioBackofficeListaDto {
  @Expose()
  id: string;

  @Expose()
  nome: string;

  @Expose()
  foto?: string;

  @Expose()
  telefone?: string;

  @Expose()
  email: string;

  @Expose()
  tipo: UsuarioBackofficeTipo;

  /**
   * Cria uma nova instância de UsuarioBackofficeListaDto a partir de dtos
   * @param props DTO de Owner
   * @returns nova instância de UsuarioBackofficeListaDto
   */
  static from(props?: UsuarioBackofficeDto | Partial<UsuarioBackofficeDto>): UsuarioBackofficeListaDto {
    return plainToInstance(UsuarioBackofficeListaDto, props, { exposeUnsetFields: false, excludeExtraneousValues: true });
  }
}
