import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Query } from '@datorama/akita';
import { CreateUserDto } from '@usucampeao/lib-reurb-simplificado';
import { IAuthQuery } from '@usucampeao/ui-mobile';
import { Observable, iif, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AuthState, AuthStore } from './auth.store';

@Injectable({ providedIn: 'root' })
export class AuthQuery extends Query<AuthState> implements IAuthQuery {
  public nome$ = this.select(state => state.name);
  public cpf$ = this.select(state => state.cpf);
  public photoId$ = this.select(state => state.photoId);
  public telefone$ = this.select(state => state.telefone);
  public token$ = this.afAuth.idToken;

  constructor(protected store: AuthStore, private afAuth: AngularFireAuth,) {
    super(store);
  }

  public get foto$(): Observable<string> {
    return this.select()
      .pipe(
        switchMap(usuario =>
          iif(() =>
            !!usuario.foto,
            of(usuario.foto),
            this.photoId$.pipe(
              map(id => id ? `/users/my-photo/${id}` : null)
            )
          )
        )
      );
  }

  public get possuiToken$(): Observable<boolean> {
    return this.token$
      .pipe(
        map(token => !!token)
      );
  }

  public get possuiUsuario$(): Observable<boolean> {
    return this.select()
      .pipe(
        map(state => !!state.id && !!state.cpf)
      );
  }

  public get dadosUsuarioCriacao$(): Observable<CreateUserDto> {
    return this.select()
      .pipe(
        map(usuario => ({
          name: usuario.name,
          cpf: usuario.cpf,
          birthDate: usuario.birthDate,
          gender: usuario.gender,
          phone: usuario.telefone,
          ...usuario.email ? { email: usuario.email } : {}
        }))
      );
  }
}
