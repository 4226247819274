import { UF } from '@usucampeao/interfaces';
import { RemoveSpecialCharacters } from '@usucampeao/utils';
import { Expose, Type } from 'class-transformer';
import { IsDate, IsEnum, IsNotEmpty, IsOptional, IsString } from 'class-validator';

export class RgDto {
  @Expose()
  @RemoveSpecialCharacters()
  @IsString()
  @IsNotEmpty()
  number: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  issuer: string;

  @Expose()
  @IsEnum(UF)
  @IsNotEmpty()
  state: UF;

  @Expose()
  @Type(() => Date)
  @IsDate()
  @IsNotEmpty()
  issueDate: Date;
}


export class RgPartialDto {
  @Expose()
  @RemoveSpecialCharacters()
  @IsString()
  @IsOptional()
  number: string;

  @Expose()
  @IsString()
  @IsOptional()
  issuer: string;

  @Expose()
  @IsEnum(UF)
  @IsOptional()
  state: UF;

  @Expose()
  @Type(() => Date)
  @IsDate()
  @IsOptional()
  issueDate: Date;
}
