import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { FooterModule, HeaderModule } from '../../../../components';
import { PagamentosDetalhesComponent } from './pagamentos-detalhes.component';

@NgModule({
  declarations: [
    PagamentosDetalhesComponent
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,

    // components
    FooterModule,
    HeaderModule,
  ],
  exports: [
    PagamentosDetalhesComponent
  ]
})
export class PagamentosDetalhesModule { }
