import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { UsuarioAvatarModule } from '@usucampeao/ui-mobile';
import { NgxMaskModule } from 'ngx-mask';
import { ToolbarComponent } from '../../components/toolbar/toolbar.component';

@NgModule({
  declarations: [ToolbarComponent],
  imports: [
    // root
    CommonModule,
    IonicModule,

    // components
    UsuarioAvatarModule,

    // external libs
    NgxMaskModule,
  ],
  exports: [ToolbarComponent],
})
export class ToolbarModule { }
