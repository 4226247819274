import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { ProjetosDetalhesV2PageModule, ProjetosListarProximosPageModule } from '@usucampeao/ui-mobile';
import { ProjetoDetalhesPageRoutingModule } from './projeto-detalhes-routing.page.module';
import { ProjetoDetalhesPage } from './projeto-detalhes.page';

@NgModule({
  declarations: [
    ProjetoDetalhesPage,
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,

    // components
    ProjetosDetalhesV2PageModule,

    // route
    ProjetoDetalhesPageRoutingModule,

    //others
    ProjetosListarProximosPageModule,
  ]
})
export class ProjetoDetalhesPageModule { }
