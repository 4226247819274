import { IsArray, IsNotEmpty, IsNumber, IsOptional, IsString } from 'class-validator';

export class TimeCriarDto {
  @IsString()
  @IsNotEmpty()
  nome: string;

  @IsString()
  @IsOptional()
  descricao?: string;

  @IsNumber()
  @IsOptional()
  cidadeId: number;

  @IsString()
  @IsOptional()
  projetoId: string;

  @IsArray()
  @IsString({ each: true })
  @IsOptional()
  usuariosIds: string[];

  @IsString()
  @IsNotEmpty()
  usuarioResponsavelId: string;

  constructor(time?: Partial<TimeCriarDto>) {
    if (time) {
      Object.keys(time).forEach(key => this[key] = time[key]);
    }
  }
}
