import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { Gender } from '@usucampeao/lib-reurb-simplificado';

export interface AuthState {
  id?: string;
  name?: string;
  telefone?: string;
  cpf?: string;
  birthDate?: any;
  gender?: Gender;
  email?: string;
  foto?: string;
  recaptcha?: any;
  code?: string;
  photoId?: string;
}

export function createInitialState(): AuthState {
  return {
    id: ''
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'auth',
  resettable: true
})
export class AuthStore extends Store<AuthState> {
  constructor() {
    super(createInitialState());
  }
}
