<ion-content class="c-completo ion-padding">
  <section class="content-vertical-space-between">
    <ion-text>
      <h1 class="title title">Pronto!</h1>
      <p class="ion-text-left font-text">Agora você já pode cadastrar e consultar processo de regularização.</p>
    </ion-text>

    <div class="c-completo__flex c-completo__flex--column">
      <div class="c-completo__image">
        <ion-icon name="checkmark-sharp"></ion-icon>
      </div>

      <ion-text>
        <p class="font-text ion-margin-top">
          Sua conta foi criada
        </p>
      </ion-text>
    </div>

    <usucampeao-footer>
      <ion-button mode="md" expand="block" (click)="irParaHome()">Continuar</ion-button>
    </usucampeao-footer>
  </section>
</ion-content>
