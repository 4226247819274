import { TabelaPrecoParcela } from './tabela-preco-parcela.enum';

type TabelaPrecoParcelaListaProps = {
  parcela: TabelaPrecoParcela;
  valorAto: number;
  valorAtoSemDesconto: number;
  valorParcela: number;
  valorParcelaSemDesconto: number;
  valorTotal: number;
  valorTotalSemDesconto: number;
  valorDesconto: number;
};

export class TabelaPrecoParcelaListaDto {
  parcela: TabelaPrecoParcela;
  valorAto: number;
  valorAtoSemDesconto: number;
  quantidadeParcela: number;
  valorParcela: number;
  valorParcelaSemDesconto: number;
  valorTotal: number;
  valorTotalSemDesconto: number;
  valorDesconto: number;

  constructor(props: TabelaPrecoParcelaListaProps) {
    this.parcela = props.parcela;
    this.valorAto = +props.valorAto.toFixed(2);
    this.valorAtoSemDesconto = +props.valorAtoSemDesconto.toFixed(2);
    this.quantidadeParcela = +this.parcela;
    this.valorParcela = this.parcela === TabelaPrecoParcela.PARCELA_0 ? 0 : +props.valorParcela.toFixed(2);
    this.valorParcelaSemDesconto = this.parcela === TabelaPrecoParcela.PARCELA_0 ? 0 : +props.valorParcelaSemDesconto.toFixed(2);
    this.valorTotal = +props.valorTotal.toFixed(2);
    this.valorTotalSemDesconto = +props.valorTotalSemDesconto.toFixed(2);
    this.valorDesconto = +props.valorDesconto.toFixed(2);
  }
}
