export enum TipoAlteracao {
  CONTRATO = 'CONTRATO',
  IMOVEL_DADOS_ADICIONAIS = 'IMOVEL_DADOS_ADICIONAIS',
  IMOVEL_DADOS_ENDERECO = 'IMOVEL_DADOS_ENDERECO',
  IMOVEL_DADOS_LOCALIZACAO = 'IMOVEL_DADOS_LOCALIZACAO',
  IMOVEL_DOCUMENTO_AQUISICAO = 'IMOVEL_DOCUMENTO_AQUISICAO',
  IMOVEL_DOCUMENTO_POSSE = 'IMOVEL_DOCUMENTO_POSSE',
  IMOVEL_FOTO_FACHADA = 'IMOVEL_FOTO_FACHADA',
  PROPRIETARIO_DADOS_BASICOS = 'PROPRIETARIO_DADOS_BASICOS',
  PROPRIETARIO_DADOS_CONTATO = 'PROPRIETARIO_DADOS_CONTATO',
  PROPRIETARIO_DADOS_DOCUMENTOS = 'PROPRIETARIO_DADOS_DOCUMENTOS',
  PROPRIETARIO_DOCUMENTO_PESSOAL = 'PROPRIETARIO_DOCUMENTO_PESSOAL',
  PROPRIETARIO_DOCUMENTO_ESTADO_CIVIL = 'PROPRIETARIO_DOCUMENTO_ESTADO_CIVIL',
  PROCURACAO = 'PROCURACAO',
}

export const TIPOS_ALTERACAO_IMOVEL = [
  TipoAlteracao.IMOVEL_DADOS_ADICIONAIS,
  TipoAlteracao.IMOVEL_DADOS_ENDERECO,
  TipoAlteracao.IMOVEL_DADOS_LOCALIZACAO,
  TipoAlteracao.IMOVEL_DOCUMENTO_AQUISICAO,
  TipoAlteracao.IMOVEL_DOCUMENTO_POSSE,
  TipoAlteracao.IMOVEL_FOTO_FACHADA,
];

export const TIPOS_ALTERACAO_PROPRIETARIO = [
  TipoAlteracao.PROPRIETARIO_DADOS_BASICOS,
  TipoAlteracao.PROPRIETARIO_DADOS_CONTATO,
  TipoAlteracao.PROPRIETARIO_DADOS_DOCUMENTOS,
  TipoAlteracao.PROPRIETARIO_DOCUMENTO_ESTADO_CIVIL,
  TipoAlteracao.PROPRIETARIO_DOCUMENTO_PESSOAL,
];
