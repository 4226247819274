import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'usucampeao-modal-projeto-nao-encontrado.component',
  templateUrl: 'modal-projeto-nao-encontrado.component.html',
  styleUrls: ['modal-projeto-nao-encontrado.component.scss'],
})
export class ModalProjetoNaoEncontradoComponent {

  constructor(
    private modalController: ModalController,
  ) { }

  public dismiss(): void {
    this.modalController.dismiss();
  }
}
