import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DocumentFileDto,
  GetUpdateOwnerContactDto,
  GetUpdateOwnerDocumentsDto,
  ListFamilyDto,
  MaritalStatus,
  OwnerDocument,
  ProprietarioDadosBasicosDto,
  ProprietarioDadosPessoaisDto,
  ProprietariosCadastroDto,
  StatusPreenchimento
} from '@usucampeao/lib-reurb-simplificado';
import { ProprietarioStateDto } from '@usucampeao/ui-mobile';
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { CadastroService } from '../../state/cadastros.service';
import { ProprietariosStore } from './proprietarios.store';

@Injectable({
  providedIn: 'root',
})
export class ProprietariosService {

  constructor(
    private cadastroService: CadastroService,
    private http: HttpClient,
    private proprietariosStore: ProprietariosStore,
  ) { }

  /**
   * Busca os proprietários de um atendimento.
   * @param cadastroId ID do atendimento
   * @returns lista de proprietários
   */
  public buscarPorCadastroId(cadastroId: string): Observable<ProprietarioStateDto[]> {
    return this.http.get<ListFamilyDto[]>(`/registrations/${cadastroId}/families`)
      .pipe(
        map(familias => familias[0].owners),
        map(proprietarios => proprietarios.map(proprietario => ({ cadastroId, ...proprietario } as ProprietarioStateDto))),
        tap(proprietarios => this.proprietariosStore.upsertMany(proprietarios)),
        tap(proprietarios => this.proprietariosStore.removerProprietariosQueNaoEstaoNoCadastro(cadastroId, proprietarios)),
      );
  }

  public buscarProprietario(cadastroId: string, proprietarioId: string): Observable<ProprietariosCadastroDto> {
    return this.http.get<ProprietariosCadastroDto>(`/registrations/${cadastroId}/owners/${proprietarioId}`)
      .pipe(
        tap(proprietario => this.proprietariosStore.upsert(proprietarioId, { ...proprietario })),
      );
  }

  /**
   * Busca os dados básicos do proprietário e os atualiza na store.
   * @param id id do proprietário
   * @returns dados básicos do proprietário
   */
  public buscarDadosBasicos(cadastroId: string, id: string): Observable<ProprietarioDadosBasicosDto> {
    return this.http.get<ProprietarioDadosBasicosDto>(`/v2/registrations/${cadastroId}/owners/${id}/basic-data`)
      .pipe(
        tap(dadosBasicos => this.proprietariosStore.upsert(id, { ...dadosBasicos })),
      );
  }

  /**
   * Busca os dados de documentos do proprietário e os atualiza na store.
   * @param id id do proprietário
   * @returns dados de documentos do proprietário
   */
  public buscarDadosDocumentos(cadastroId: string, id: string): Observable<GetUpdateOwnerDocumentsDto> {
    return this.http.get<GetUpdateOwnerDocumentsDto>(`/registrations/${cadastroId}/owners/${id}/documents-data`)
      .pipe(
        tap(documentos => this.proprietariosStore.add({ id, cadastroId, ...documentos })),
        tap(documentos => this.proprietariosStore.update(id, { ...documentos })),
      );
  }

  /**
   * Busca os dados de contato do proprietário e os atualiza na store.
   * @param id id do proprietário
   * @returns dados de contato do proprietário
   */
  public buscarDadosContato(cadastroId: string, id: string): Observable<GetUpdateOwnerContactDto> {
    return this.http.get<GetUpdateOwnerContactDto>(`/registrations/${cadastroId}/owners/${id}/contact`)
      .pipe(
        tap(contatoDados => this.proprietariosStore.add({ id, cadastroId, ...contatoDados })),
        tap(contatoDados => this.proprietariosStore.update(id, { ...contatoDados })),
      );
  }

  /**
   * Atualiza os dados básicos do proprietário.
   * @param cadastroId id do atendimento
   * @param id id do proprietário
   * @param personalData dados a serem atualizados
   */
  public atualizarDadosBasicos(cadastroId: string, id: string, personalData: ProprietarioDadosBasicosDto): Observable<ProprietarioStateDto[]> {
    return this.http.put<void>(`/v2/registrations/${cadastroId}/owners/${id}/basic-data`, personalData)
      .pipe(
        tap(() => {
          const fillInStatus = this.proprietariosStore.buscarStatusPreechimento(id);
          fillInStatus.personalData = StatusPreenchimento.ENVIADO;
          this.proprietariosStore.update(id, { ...personalData, fillInStatus });
        }),
        tap(() => {
          const proprietario = this.proprietariosStore.getValue().entities[id];
          if (proprietario?.spouseId && personalData.maritalStatus !== MaritalStatus.MARRIED) {
            this.proprietariosStore.remove(proprietario.spouseId);
            this.proprietariosStore.update(id, { spouseId: null });
          }
        }),
        switchMap(() => this.buscarPorCadastroId(cadastroId)),
        tap(() => this.cadastroService.buscarCadastro(cadastroId).subscribe()),
      );
  }

  /**
   * Atualiza os dados pessoais do proprietário
   * @param cadastroId ID do cadastro
   * @param proprietarioId ID do proprietário
   * @param dadosPessoais Dados pessoais a serem atualizados
   */
  public atualizarDadosPessoais(cadastroId: string, proprietarioId: string, dadosPessoais: ProprietarioDadosPessoaisDto): Observable<ProprietarioStateDto[]> {
    return this.http.put<void>(`/v2/registrations/${cadastroId}/owners/${proprietarioId}/dados-pessoais`, dadosPessoais)
      .pipe(
        tap(() => {
          const fillInStatus = this.proprietariosStore.buscarStatusPreechimento(proprietarioId);
          fillInStatus.personalData = StatusPreenchimento.ENVIADO;
          this.proprietariosStore.upsert(proprietarioId, { ...dadosPessoais, fillInStatus });
        }),
        switchMap(() => this.buscarPorCadastroId(cadastroId)),
        tap(() => this.cadastroService.buscarCadastro(cadastroId).subscribe()),
      );
  }

  /**
   * Atualiza os dados de documento do proprietário.
   * @param cadastroId id do atendimento
   * @param id id do proprietário
   * @param documentsData dados a serem atualizados
   */
  public atualizarDadosDocumentos(cadastroId: string, id: string, documentsData: GetUpdateOwnerDocumentsDto): Observable<void> {
    return this.http.put<void>(`/registrations/${cadastroId}/owners/${id}/documents-data`, documentsData)
      .pipe(
        tap(() => {
          const fillInStatus = this.proprietariosStore.buscarStatusPreechimento(id);
          fillInStatus.documentsData = StatusPreenchimento.ENVIADO;
          this.proprietariosStore.update(id, { ...documentsData, fillInStatus });
        }),
        tap(() => this.cadastroService.buscarCadastro(cadastroId).subscribe()),
      );
  }

  /**
   * Atualiza os dados de contato do proprietário.
   * @param cadastroId id do atendimento
   * @param id id do proprietário
   * @param contactData dados a serem atualizados
   */
  public atualizarDadosContato(cadastroId: string, id: string, contactData: GetUpdateOwnerContactDto): Observable<void> {
    return this.http.put<void>(`/registrations/${cadastroId}/owners/${id}/contact`, contactData)
      .pipe(
        tap(() => {
          const fillInStatus = this.proprietariosStore.buscarStatusPreechimento(id);
          fillInStatus.contactData = StatusPreenchimento.ENVIADO;
          this.proprietariosStore.update(id, { ...contactData, fillInStatus });
        }),
        tap(() => this.cadastroService.buscarCadastro(cadastroId).subscribe()),
      );
  }

  /**
   * Busca os documentos de um proprietário
   * @param cadastroId ID do cadastro
   * @param proprietarioId ID do proprietario
   * @returns Lista de documentos do proprietário
   */
  public buscarDocumentos(cadastroId: string, proprietarioId: string): Observable<DocumentFileDto[]> {
    return this.http
      .get<DocumentFileDto[]>(`/registrations/${cadastroId}/owners/${proprietarioId}/documents`)
      .pipe(
        tap(documentos => this.proprietariosStore.update(proprietarioId, { documentos }))
      );
  }

  /**
   * Adiciona um novo documento e carrega a lista de documentos atualizados na store.
   * @param cadastroId ID do cadastro
   * @param proprietarioId ID do proprietario
   * @param arquivos Lista de arquivos do documento
   * @param tipo Tipo do documento
   * @returns Lista atualizada de documentos do proprietario
   */
  public adicionarDocumento(cadastroId: string, proprietarioId: string, arquivos: File[], tipo: OwnerDocument): Observable<DocumentFileDto[]> {
    const formData = new FormData();

    arquivos.forEach(item => formData.append('files', item));
    formData.append('type', tipo);

    return this.http.post(`/registrations/${cadastroId}/owners/${proprietarioId}/documents`, formData)
      .pipe(
        switchMap(() => this.buscarPorCadastroId(cadastroId)),
        switchMap(() => this.buscarDocumentos(cadastroId, proprietarioId)),
        tap(() => this.cadastroService.buscarCadastro(cadastroId).subscribe()),
      );
  }

  /**
   * Atualiza um documento do proprietário e carrega a lista de documentos atualizados na store.
   * Esse método adiciona e/ou remove arquivos de um documento.
   * @param cadastroId ID do cadastro
   * @param proprietarioId ID do proprietario
   * @param documentoId ID do documento
   * @param arquivos Lista de arquivos do documento
   * @param arquivosRemovidos Arquivos a serem removidos
   * @returns Lista atualizada de documentos do proprietário
   */
  public atualizarDocumento(cadastroId: string, proprietarioId: string, documentoId: string, arquivos: File[], arquivosRemovidos: string[]): Observable<DocumentFileDto[]> {
    const formData = new FormData();

    if (arquivosRemovidos) {
      arquivosRemovidos.map(id => formData.append('arquivosRemovidos', id));
    }

    if (arquivos) {
      arquivos.forEach(arquivo => formData.append('files', arquivo));
    }

    return this.http.put(`/registrations/${cadastroId}/owners/${proprietarioId}/documents/${documentoId}/files`, formData)
      .pipe(
        switchMap(() => this.buscarPorCadastroId(cadastroId)),
        switchMap(() => this.buscarDocumentos(cadastroId, proprietarioId)),
        tap(() => this.cadastroService.buscarCadastro(cadastroId).subscribe()),
      );
  }

  /**
   * Adiciona cônjuge ao proprietário.
   * @param cadastroId ID do cadastro
   * @param proprietarioId ID do proprietario
   * @returns Dados dos proprietários atualizados
   */
  public adicionarConjuge(cadastroId: string, proprietarioId: string): Observable<ProprietarioStateDto[]> {
    return this.http.put<{ conjugeId: string }>(`/v2/registrations/${cadastroId}/owners/${proprietarioId}/adicionar-conjuge`, {})
      .pipe(
        switchMap(() => this.buscarPorCadastroId(cadastroId)),
        tap(() => this.cadastroService.buscarCadastro(cadastroId).subscribe()),
      );
  }

  /**
   * Remove cônjuge do proprietário.
   * @param cadastroId ID do cadastro
   * @param proprietarioId ID do proprietario que terá o cônjuge removido
   * @returns Dados dos proprietários atualizados
   */
  public removerConjuge(cadastroId: string, proprietarioId: string): Observable<ProprietarioStateDto[]> {
    return this.http.delete<void>(`/v2/registrations/${cadastroId}/owners/${proprietarioId}/remover-conjuge`)
      .pipe(
        switchMap(() => this.buscarPorCadastroId(cadastroId)),
        tap(() => this.cadastroService.buscarCadastro(cadastroId).subscribe()),
      );
  }
}
