import { Pipe, PipeTransform } from '@angular/core';
import { StatusPreenchimento } from '@usucampeao/lib-reurb-simplificado';

@Pipe({
  name: 'statusPreenchimento'
})
export class StatusPreenchimentoPipe implements PipeTransform {
  transform(value: StatusPreenchimento): string {
    switch (value) {
      case StatusPreenchimento.PENDENTE:
        return 'Incompleto';
      case StatusPreenchimento.APROVADO:
      case StatusPreenchimento.ENVIADO:
        return 'Completo';
      case StatusPreenchimento.ALTERACAO_NECESSARIA:
        return 'Alteração necessária';
      case StatusPreenchimento.JUSTIFICADO:
        return 'Justificado';
    }
  }
}
