import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { ModalPoliticaPrivacidadeComponent } from './modal-politica-privacidade.component';

@NgModule({
  declarations: [ModalPoliticaPrivacidadeComponent],
  imports: [
    // root
    CommonModule,
    IonicModule,
  ],
  exports: [ModalPoliticaPrivacidadeComponent]
})
export class ModalPoliticaPrivacidadeModule { }
