import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CadastrosComponent } from './cadastros.component';

const routes: Routes = [
  { path: '', component: CadastrosComponent },
  {
    path: 'imovel/:imovelId',
    loadChildren: () => import('./imoveis/imoveis.page.module').then(m => m.ImoveisPageModule)
  },
  {
    path: 'proprietarios',
    loadChildren: () => import('./proprietarios/proprietarios.page.module').then(m => m.ProprietariosPageModule)
  },
  {
    path: 'observacoes',
    redirectTo: 'comentarios',
    pathMatch: 'full'
  },
  {
    path: 'comentarios',
    loadChildren: () => import('./comentarios/comentarios.page.module').then(m => m.ComentariosPageModule)
  },
  {
    path: 'pagamentos',
    loadChildren: () => import('./pagamentos/pagamentos.module').then(m => m.PagamentosModule)
  },
  {
    path: 'contrato',
    loadChildren: () => import('./contrato/contrato.page.module').then(m => m.ContratoModule)
  },
  {
    path: 'documentos',
    loadChildren: () =>
      import('./outros-documentos/outros-documentos.page.module').then(m => m.OutrosDocumentosPageModule)
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CadastrosRoutingModule { }
