import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';


import { ContratoSelecionarAverbacaoModule } from '@usucampeao/ui-mobile';
import { ContratoSelecionarAverbacaoRoutingModule } from './contrato-selecionar-averbacao-routing.module';
import { ContratoSelecionarAverbacaoPage } from './contrato-selecionar-averbacao.page';


@NgModule({
  declarations: [
    ContratoSelecionarAverbacaoPage
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,

    // route
    ContratoSelecionarAverbacaoRoutingModule,

    // components
    ContratoSelecionarAverbacaoModule,
  ]
})
export class ContratoSelecionarAverbacaoPageModule { }
