<section class="p-listagem">
  <usucampeao-header *ngIf="!dadosSimplificados" title="Pagamentos"></usucampeao-header>

  <usucampeao-cadastro-pagamentos-resumo [pagamentos]="pagamentos"></usucampeao-cadastro-pagamentos-resumo>

  <ng-container *ngIf="!dadosSimplificados">
    <div class="p-listagem__resumo ion-padding ion-margin-top">
      <ion-icon class="ion-margin-end" name="cash-sharp" color="medium"></ion-icon>

      <ion-text>
        <p class="font-text font-text--large ion-no-margin">{{ cadastro?.valorContrato | currency: 'BRL' }}</p>
        <p class="description-text ion-no-margin">
          Entrada de {{ cadastro?.valorEntrada | currency: 'BRL' }} + {{ cadastro?.totalParcelas }} parcelas de {{
          cadastro?.valorParcela | currency: 'BRL' }}
        </p>
        <p class="description-text">Pagamento via boleto</p>
      </ion-text>
    </div>

    <ion-text>
      <p class="font-text">
        Acompanhe, abaixo, a situação de cada parcela do seu processo. Atente-se às parcelas com boletos já disponíveis.
      </p>
    </ion-text>
  </ng-container>

  <div class="p-listagem__pagamentos">
    <ion-text *ngIf="!dadosSimplificados">
      <h2 class="title title--medium">Parcelas em aberto</h2>
    </ion-text>

    <usucampeao-pagamento-item *ngFor="let pagamento of pagamentosPendentes" [pagamento]="pagamento"
      (click)="irParaDetalhesDoPagamento(pagamento.id)"></usucampeao-pagamento-item>
  </div>
</section>
