/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { catchError, finalize, tap } from 'rxjs/operators';
import { ProprietarioService } from '../../../services/proprietario.service';
import { LoadingService, ToastService } from '../../../shared';


export type ModalEditarInfosContatoOutput = {
  whatsapp: string;
  email: string;
}

@Component({
  templateUrl: './modal-editar-infos-contato.component.html',
  styleUrls: ['./modal-editar-infos-contato.component.scss']
})
export class ModalEditarInfosContatoComponent implements OnInit {
  @Input() cadastroId: string;
  @Input() proprietarioId: string;
  @Input() proprietarioEmail: string;
  @Input() proprietarioWhatsapp: string;

  public form: FormGroup;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly modalController: ModalController,
    private readonly loadingService: LoadingService,
    private readonly proprietarioService: ProprietarioService,
    private readonly toastService: ToastService,
  ) {
    this.form = this.formBuilder.group({
      whatsapp: [null, [Validators.required, Validators.minLength(11)]],
      email: [null, [Validators.email]],
    });

  }

  ngOnInit() {
    this.form.setValue({
      whatsapp: this.proprietarioWhatsapp || null,
      email: this.proprietarioEmail || null,
    });
  }

  public async submitForm(): Promise<void> {
    if (this.form.invalid) {
      return;
    }

    await this.loadingService.createLoader();
    this.proprietarioService.atualizarEmailECelular(
      this.cadastroId,
      this.proprietarioId,
      {
        email: this.form.value.email,
        whatsapp: this.form.value.whatsapp,
      }
    )
      .pipe(
        tap(() => this.fecharModal()),
        catchError(async error => {
          await this.toastService.error('Erro ao atualizar dados de contato do proprietário. Por favor tente novamente.').toPromise();
          throw error;
        }),
        finalize(async () => await this.loadingService.dismiss()),
      )
      .subscribe();
  }

  public fecharModal(): void {
    const { whatsapp, email } = this.form.value;
    this.modalController.dismiss({ whatsapp, email });
  }
}
