<div class="input">
  <ion-item mode="md" fill="solid">
    <ion-label mode="md" position="floating" [class.input__label--readonly]="readonly">
      <ion-text class="input-text">
        {{ label }}
      </ion-text>
    </ion-label>

    <ion-input  mode="md" fill="solid" [type]="type" [placeholder]="placeholder" [formControlName]="controlName" [inputMask]="mask" [inputmode]="inputmode"
      [readonly]="readonly" [class.input--readonly]="readonly">
    </ion-input>

    <div class="input__suffix-icon" [class.input__suffix-icon--center]="suffixCenter" slot="end">
     <ng-content select="[usu-suffix]"></ng-content>
    </div>
  </ion-item>

  <div class="input__error">
    <ng-content select="[usu-error]"></ng-content>
  </div>
</div>
