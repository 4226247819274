import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { DocumentosUploadModule } from '@usucampeao/ui-mobile';
import { ProprietariosDocumentosUploadRoutingModule } from './proprietarios-documentos-upload-routing.module';
import { ProprietariosDocumentosUploadPage } from './proprietarios-documentos-upload.page';


@NgModule({
  declarations: [
    ProprietariosDocumentosUploadPage
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,

    // route
    ProprietariosDocumentosUploadRoutingModule,

    // components
    DocumentosUploadModule,
  ]
})
export class ProprietariosDocumentosUploadPageModule { }
