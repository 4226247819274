import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ViewWillLeave } from '@ionic/angular';
import {
  AlteracaoDto,
  MaritalStatus,
  ProprietarioDadosBasicosDto,
  RendaFamiliar
} from '@usucampeao/lib-reurb-simplificado';
import { AbstractForm, validateCPF } from '@usucampeao/utils-frontend';
import { subDays } from 'date-fns';
import { Subject } from 'rxjs';

@Component({
  selector: 'usucampeao-proprietarios-dados-basicos-v2',
  templateUrl: './proprietarios-dados-basicos-v2.component.html',
  styleUrls: ['./proprietarios-dados-basicos-v2.component.scss']
})
export class ProprietariosDadosBasicosV2Component extends AbstractForm implements OnInit, OnChanges, ViewWillLeave, OnDestroy {
  @Input() dadosBasicos: ProprietarioDadosBasicosDto;
  @Input() alteracoes: AlteracaoDto[];
  @Input() formDesabilitado = false;

  @Output() aoSubmeter = new EventEmitter<ProprietarioDadosBasicosDto>();

  private readonly ngUnsubscribe$ = new Subject<void>();

  public readonly estadosCivis = Object.values(MaritalStatus);
  public readonly rendasFamiliares = Object.values(RendaFamiliar);
  public readonly dataMaximaCasamentoNascimento = subDays(new Date(), 1);

  constructor(
    private fb: FormBuilder
  ) {
    super();
    this.form = this.fb.group({
      name: [null, Validators.required],
      cpf: [null, [Validators.required, validateCPF]],
      birthDate: [null, Validators.required],
      maritalStatus: [null, Validators.required],
      rendaFamiliar: [null, Validators.required],
      phones: this.fb.group({
        cellphone: [null, Validators.compose([Validators.required, Validators.minLength(14)])],
      }),
      email: [null, Validators.email]
    });
  }

  ngOnInit(): void {
    this.preencherForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dadosBasicos) {
      this.preencherForm();
    }
  }

  ionViewWillLeave(): void {
    this.ngUnsubscribe$.next();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.complete();
  }

  private preencherForm(): void {
    const dadosBasicos = Object.assign({}, this.dadosBasicos);
    delete dadosBasicos.main;
    this.form.patchValue(dadosBasicos);
  }

  public submit(): void {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }

    this.aoSubmeter.emit(this.form.value);
  }
}
