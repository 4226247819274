import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Endereco } from '@usucampeao/interfaces';
import { AddressDto } from '@usucampeao/lib-reurb-simplificado';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface ViaCepEndereco {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
  unidade: string;
  ibge: string;
  gia: string;
}

@Injectable({
  providedIn: 'root'
})
export class EnderecoService {

  constructor(
    private http: HttpClient
  ) { }

  /**
   * Busca endereço pelo CEP
   * @param cep CEP selecionado
   * @param isAddressDto Flag para indicar se retorna um Endereco ou AddressDto. Default: false
   * @returns endereço do cep
   */
  public buscarCep(cep: string, isAddressDto = false): Observable<AddressDto | Endereco> {
    cep = cep.replace(/\D/g, '');
    return this.http.get<ViaCepEndereco>(`https://viacep.com.br/ws/${cep}/json/`)
      .pipe(
        map(endereco => {
          if (isAddressDto) {
            return {
              street: endereco.logradouro,
              neighborhood: endereco.bairro,
              city: endereco.localidade,
              state: endereco.uf
            } as AddressDto;
          }

          return {
            rua: endereco.logradouro,
            bairro: endereco.bairro,
            cidade: endereco.localidade,
            estado: endereco.uf
          } as Endereco;
        })
      );
  }
}
