import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { IonicModule } from '@ionic/angular';
import { MapaGoogleComponent } from './mapa-google/mapa-google.component';
import { MapaMapbox } from './mapa-mapbox/mapa-mapbox.component';
import { MapaComponent } from './mapa.component';
import { ModalAjudaSelecaoLoteComponent } from './modal-ajuda-selecao-lote/modal-ajuda-selecao-lote.component';

export const MAP_BOX_TOKEN = 'MAP_BOX_TOKEN';

@NgModule({
  declarations: [
    MapaComponent,
    MapaGoogleComponent,
    MapaMapbox,
    ModalAjudaSelecaoLoteComponent,
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,

    // libs
    GoogleMapsModule
  ],
  exports: [MapaComponent],
})
export class MapaModule { }
