import { ReurbModalidade } from '../../enums';
import { RegistrationStatus } from '../registration';

export class LoteCadastrosLitarDto {
  id: number;
  proprietario: string;
  modalidade: ReurbModalidade;
  projeto: string;
  projetoId: number;
  status: RegistrationStatus;
  dataContrato: Date;
  valorContrato: number;

  constructor(
    id: number,
    proprietario: string,
    modalidade: ReurbModalidade,
    projeto: string,
    projetoId: number,
    status: RegistrationStatus,
    dataContrato: Date,
    valorContrato: number
  ) {
    this.id = id;
    this.proprietario = proprietario;
    this.modalidade = modalidade;
    this.projeto = projeto;
    this.projetoId = projetoId;
    this.status = status;
    this.dataContrato = dataContrato;
    this.valorContrato = valorContrato;
  }
}
