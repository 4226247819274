<section class="p-dados-documentos">
  <usucampeao-header title="Proprietário"></usucampeao-header>

  <usucampeao-cadastro-status *ngIf="mostrarAlteracoes" [status]="cadastroStatus"
  [alteracoes]="alteracoes"></usucampeao-cadastro-status>

  <ion-text>
    <p class="font-text">Informe os dados de contato do proprietário</p>
  </ion-text>

  <form [formGroup]="form">
    <usucampeao-input label="CPF" controlName="cpf" type="tel" inputmode="numeric" mask="000.000.000-00" [readonly]="formDesabilitado || !!dadosDocumentos.cpf">
      <span usu-error>{{ gerarMensagemErro('cpf', 'CPF') }}</span>
    </usucampeao-input>

    <ng-container formGroupName="rg">
      <usucampeao-input label="RG" controlName="number" [readonly]="formDesabilitado"></usucampeao-input>

      <usucampeao-input label="Orgão emissor" controlName="issuer" [readonly]="formDesabilitado"></usucampeao-input>

      <usucampeao-select label="Estado" controlName="state" [disabled]="formDesabilitado">
        <ion-select-option *ngFor="let estado of estados" [value]="estado">{{ estado }}</ion-select-option>
      </usucampeao-select>

      <usucampeao-datepicker label="Data de emissão" formControlName="issueDate" [maxDate]="dataMaximaRg" [disabled]="formDesabilitado"></usucampeao-datepicker>
    </ng-container>
  </form>

  <usucampeao-footer>
    <ion-button *ngIf="!formDesabilitado" mode="md" (click)="submit()">Salvar</ion-button>
  </usucampeao-footer>
</section>
