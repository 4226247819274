
import { AddressDto } from '@usucampeao/lib-reurb-simplificado';
import { Expose, plainToInstance } from 'class-transformer';
import { IsDateString, IsEmail, IsEnum, IsNotEmpty, IsNumber, IsOptional, IsString } from 'class-validator';
import { IntegrationOmieStatus } from './integration-omie-status.enum';
import { OmieClienteDto } from './omie-contrato-criar.dto';

/**
 * TODO: Alterar atributos para português após ajustar back antigo
 */
export class IntegrationOmieCustomerDto {
  @Expose()
  @IsString()
  @IsNotEmpty()
  id: string;

  @Expose()
  @IsNumber()
  @IsOptional()
  omieId?: number;

  @Expose()
  @IsString()
  @IsNotEmpty()
  name: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  phone: string;

  @Expose()
  @IsEmail()
  @IsOptional()
  email?: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  cpf: string;

  @Expose()
  @IsDateString()
  @IsOptional()
  createdAt?: Date;

  @Expose()
  @IsDateString()
  @IsOptional()
  updatedAt?: Date;

  @Expose()
  @IsEnum(IntegrationOmieStatus)
  @IsNotEmpty()
  status: IntegrationOmieStatus;

  @Expose()
  address?: AddressDto;

  @Expose()
  dadoLegado: boolean;

  @Expose()
  error?: any;

  /**
   * Cria uma nova instância de IntegrationOmieCustomerDto
   * @param props DTO de proprietário
   * @returns nova instância de IntegrationOmieCustomerDto
   */
  static from(props?: Partial<OmieClienteDto>): IntegrationOmieCustomerDto {
    return plainToInstance(IntegrationOmieCustomerDto, props, { exposeUnsetFields: false, excludeExtraneousValues: true });
  }
}
