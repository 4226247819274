import { ProjetoHistoricoTipo } from './projeto-historico-tipo.enum';

export class ProjetoHistoricoDto {
  tipo: ProjetoHistoricoTipo;
  usuarioId: string;
  usuarioNome: string;
  dataCriacao: Date;
  payload?: unknown;

  constructor(
    tipo: ProjetoHistoricoTipo,
    usuarioId: string,
    usuarioNome: string,
    dataCriacao: Date,
    payload?: unknown
  ) {
    this.tipo = tipo;
    this.usuarioId = usuarioId;
    this.usuarioNome = usuarioNome;
    this.dataCriacao = dataCriacao;
    this.payload = payload;
  }
}
