import { CurrencyPipe } from '@angular/common';
import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

@Directive({
  selector: '[inputMask]',
})
export class MaskDirective implements OnInit, OnDestroy {

  @Input('inputMask') inputMask?: string;

  private inputElem: HTMLInputElement;
  private _lastMaskedValue = '';
  private subscription$: Subscription;

  constructor(
    private el: ElementRef,
    private control: NgControl,
    private currencyPipe: CurrencyPipe,
  ) { }

  ngOnInit() {
    this.inputElem = this.el.nativeElement;
    this.inputElem.value = this._maskValue(this.inputElem.value);
    this.subscription$ = this.control.control.valueChanges
      .pipe(
        filter(() => !!this.inputMask),
        tap(() => this.inputElem.value = this._maskValue(this.inputElem.value)),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }


  private _maskValue(val: string): string {
    if (this.inputMask === 'currency') {
      if (Number(val) || !val) {
        return this.currencyPipe.transform(val || 0, 'BRL');
      }

      const value = +val?.toString()?.replace(/\D/g, '');
      return this.currencyPipe.transform(value / 100, 'BRL');
    }

    if (!val || !this.inputMask || val === this._lastMaskedValue) {
      return val;
    }

    const maskedVal = this._lastMaskedValue =
      valueToFormat(
        val,
        this.inputMask,
        this._lastMaskedValue.length > val.length,
        this._lastMaskedValue);

    return maskedVal;
  }

}

const _formatToRegExp: any = {
  '0': /[0-9]/, 'a': /[a-z]/, 'A': /[A-Z]/, 'B': /[a-zA-Z]/,
};

const _allFormatsStr = '(' +
  Object.keys(_formatToRegExp)
    .map(key => _formatToRegExp[key].toString())
    .map(regexStr => regexStr.substr(1, regexStr.length - 2))
    .join('|')
  + ')';

const _allFormatsGlobal = getAllFormatRegexp('g');

/**
 * Apply format to a value string
 *
 * Format can be constructed from next symbols:
 *  - '0': /[0-9]/,
 *  - 'a': /[a-z]/,
 *  - 'A': /[A-Z]/,
 *  - 'B': /[a-zA-Z]/
 *
 * Example: 'AAA-00BB-aaaa'
 * will accept 'COD-12Rt-efww'
 *
 * @param value Current value
 * @param format Format
 * @param goingBack Indicates if change was done by BackSpace
 * @param prevValue Pass to precisely detect formatter chars
 */
function valueToFormat(value: string, format: string, goingBack = false, prevValue?: string): string {

  let maskedValue = '';
  const unmaskedValue = unmaskValue(value);

  const isLastCharFormatter = !getAllFormatRegexp().test(value[value.length - 1]);
  const isPrevLastCharFormatter = prevValue && !getAllFormatRegexp().test(prevValue[prevValue.length - 1]);

  let formatOffset = 0;
  for (let i = 0, maxI = Math.min(unmaskedValue.length, format.length); i < maxI; ++i) {
    const valueChar = unmaskedValue[i];
    let formatChar = format[formatOffset + i];
    let formatRegex = getFormatRegexp(formatChar);

    if (formatChar && !formatRegex) {
      maskedValue += formatChar;
      formatChar = format[++formatOffset + i];
      formatRegex = getFormatRegexp(formatChar);
    }

    if (valueChar && formatRegex) {
      if (formatRegex && formatRegex.test(valueChar)) {
        maskedValue += valueChar;
      } else {
        break;
      }
    }

    const nextFormatChar = format[formatOffset + i + 1];
    const nextFormatRegex = getFormatRegexp(nextFormatChar);
    const isLastIteration = i === maxI - 1;

    if (isLastIteration && nextFormatChar && !nextFormatRegex) {
      if (!isLastCharFormatter && goingBack) {
        if (prevValue && !isPrevLastCharFormatter) {
          continue;
        }
        maskedValue = maskedValue.substr(0, formatOffset + i);
      } else {
        maskedValue += nextFormatChar;
      }
    }
  }

  return maskedValue;
}

function unmaskValue(value: string): string {
  const unmaskedMathes = value ? `${value}`.replace(' ', '').match(_allFormatsGlobal) : null;
  return unmaskedMathes ? unmaskedMathes.join('') : '';
}

function getAllFormatRegexp(flags?: string) {
  return new RegExp(_allFormatsStr, flags);
}

function getFormatRegexp(formatChar: string): RegExp | null {
  return formatChar && _formatToRegExp[formatChar] ? _formatToRegExp[formatChar] : null;
}
