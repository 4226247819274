import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { FooterModule, HeaderModule } from '../../../components';
import { ProjetosSelecionarPage } from './projetos-selecionar.page';

@NgModule({
  declarations: [
    ProjetosSelecionarPage
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,

    // components
    HeaderModule,
    // ModalProjetoNaoEncontradoModule,
    FooterModule,
  ],
  exports: [
    ProjetosSelecionarPage,
  ]
})
export class ProjetosSelecionarPageModule { }
