import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController, ViewWillEnter } from '@ionic/angular';
import { CadastroContratoTipoAssinatura } from '@usucampeao/interfaces';
import { catchError, finalize, take, tap } from 'rxjs/operators';


import { BaseComponent, CadastroFichaDto, LoadingService, ModalContratoProcuracaoComponent, ToastService } from '@usucampeao/ui-mobile';
import { isBefore } from 'date-fns';
import { Observable } from 'rxjs';
import { CadastrosQuery } from '../../state/cadastros.query';
import { CadastroService } from '../../state/cadastros.service';

@Component({
  templateUrl: 'contrato-assinatura.page.html',
  styleUrls: ['contrato-assinatura.page.scss'],
})
export class ContratoAssinaturaPage extends BaseComponent implements ViewWillEnter {
  private cadastroId: string;
  public tipo: CadastroContratoTipoAssinatura;
  public contratoExpirado: boolean;
  public readonly titulos = {
    [CadastroContratoTipoAssinatura.FISICO]: 'Fotografia do contrato físico',
    [CadastroContratoTipoAssinatura.LINK]: 'Link de confirmação',
  };

  public cadastro$: Observable<CadastroFichaDto>;

  constructor(
    private cadastroService: CadastroService,
    private readonly cadastrosQuery: CadastrosQuery,
    private loadingService: LoadingService,
    private modalCtrl: ModalController,
    private router: Router,
    private route: ActivatedRoute,
    private toastService: ToastService,
  ) {
    super();
  }

  ionViewWillEnter(): void {
    this.route.params
      .pipe(
        tap(params => this.cadastroId = params.cadastroId),
        tap(() => this.cadastro$ = this.cadastrosQuery.buscarCadastroFicha(this.cadastroId)),
        tap(() => this.carregarCadastro()),
        take(1),
      )
      .subscribe();
  }

  public get cadastroContratoTipoAssinatura(): typeof CadastroContratoTipoAssinatura {
    return CadastroContratoTipoAssinatura;
  }

  public goTo(route: string): void {
    this.router.navigate([route], { relativeTo: this.route })
  }

  public async abrirModalContrato(): Promise<void> {
    await this.loadingService.createLoader();

    this.cadastroService.buscarContrato(this.cadastroId)
      .pipe(
        tap(async dadosContrato => {
          const modal = await this.modalCtrl.create({
            component: ModalContratoProcuracaoComponent,
            cssClass: 'contrato-modal',
            componentProps: {
              dadosContrato
            },
          });
          await modal.present();
        }),
        finalize(() => this.loadingService.dismiss())
      )
      .subscribe();
  }

  public async carregarCadastro(event?: any): Promise<void> {
    if (!event) {
      await this.loadingService.createLoader();
    }

    this.cadastroService.buscarCadastro(this.cadastroId)
      .pipe(
        tap(cadastro => this.tipo = cadastro.contratoTipoAssinatura),
        tap(cadastro => this.contratoExpirado = cadastro.contratoDataExpiracao && isBefore(new Date(cadastro.contratoDataExpiracao), new Date())),
        catchError(async error => {
          await this.toastService.error('Erro ao buscar dados do cadastro. Por favor tente novamente.').toPromise();
          throw error;
        }),
        finalize(async () => event ? event.target.complete() : await this.loadingService.dismiss()),
      )
      .subscribe();
  }
}
