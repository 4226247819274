import { PoliticaStatus } from './politica-status.enum';

export class PoliticaDto {
  id: number;
  nome: string;
  descricao: string;
  status: PoliticaStatus;
  dataCriacao: Date;
  usuarioCriacaoId: number;
  dataAtualizacao: Date;
  usuarioAtualizacaoId: number;
}
