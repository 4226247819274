import { IsEnum, IsNotEmpty } from 'class-validator';

export enum CadastroContratoTipoAssinatura {
  FISICO = 'FISICO',
  LINK = 'LINK',
  CODIGO_CONFIRMACAO = 'CODIGO_CONFIRMACAO',
  FOTO_COM_DOCUMENTO = 'FOTO_COM_DOCUMENTO',
}

/**
 * Tipo de envio do contrato quando o tipo de assinatura é LINK
 */
export enum CadastroContratoAssinaturaTipoEnvioLink {
  EMAIL = 'EMAIL',
  WHATSAPP = 'WHATSAPP',
}

export class CadastroContratoEscolherTipoAssinaturaDto {
  @IsEnum(CadastroContratoTipoAssinatura)
  @IsNotEmpty()
  tipo: CadastroContratoTipoAssinatura;
}

export class CadastroContratoEscolherTipoAssinaturaLinkDto {
  @IsEnum(CadastroContratoAssinaturaTipoEnvioLink)
  @IsNotEmpty()
  tipoEnvio: CadastroContratoAssinaturaTipoEnvioLink;
}
