import { Expose, plainToInstance, Type } from 'class-transformer';
import { MaritalStatus } from './marital-status.enum';
import { OwnerFillInStatusDto } from './owner-fill-in-status.dto';
import { OwnerStatus } from './owner-status.enum';
import { OwnerDto } from './owner.dto';

export class OwnerSimplifiedDto {
  @Expose()
  id: string;

  @Expose()
  proprietarioId: string;

  @Expose()
  name: string;

  @Expose()
  maritalStatus: MaritalStatus;

  @Expose()
  main?: boolean;

  @Expose()
  spouseId?: string;

  @Expose()
  status: OwnerStatus;

  @Expose()
  @Type(() => OwnerFillInStatusDto)
  fillInStatus: OwnerFillInStatusDto;

  /**
   * Cria uma nova instância de OwnerSimplifiedDto a partir de dtos
   * @param props DTO de Owner
   * @returns nova instância de OwnerSimplifiedDto
   */
  static from(props?: OwnerDto | Partial<OwnerDto>): OwnerSimplifiedDto {
    return plainToInstance(OwnerSimplifiedDto, props, { exposeUnsetFields: false, excludeExtraneousValues: true });
  }
}
