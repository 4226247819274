import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { FooterModule, UploadFotoDocumentoModule } from '@usucampeao/ui-mobile';
import { CadastrarFotoRoutingModule } from './cadastrar-foto-routing.module';
import { CadastrarFotoComponent } from './cadastrar-foto.component';


@NgModule({
  declarations: [
    CadastrarFotoComponent
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,

    // route
    CadastrarFotoRoutingModule,

    // components
    UploadFotoDocumentoModule,
    FooterModule,
  ]
})
export class CadastrarFotoModule { }
