import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
  AddressDto,
  PropertyFillInStatusDto,
  StatusPreenchimento
} from '@usucampeao/lib-reurb-simplificado';
import { AbstractForm } from '@usucampeao/utils-frontend';
import { EnderecoFormComponent } from '../../../../../components';

@Component({
  selector: 'usucampeao-imoveis-editar-endereco-form',
  templateUrl: './imoveis-editar-endereco-form.component.html',
  styleUrls: ['./imoveis-editar-endereco-form.component.scss']
})
export class ImoveisEditarEnderecoFormComponent extends AbstractForm implements OnInit {
  @ViewChild('enderecoForm', { static: true }) enderecoForm: EnderecoFormComponent;

  @Input() set endereco(endreco: AddressDto) {
    this.preencherForm(endreco);
  }
  @Input() set status(status: PropertyFillInStatusDto) {
    this.statusPreenchimento = status?.addressData || StatusPreenchimento.PENDENTE;
  }
  @Input() formDesabilitado = false;
  @Input() accordionEstaAberto = true;

  @Output() aoSubmeter = new EventEmitter<AddressDto>();

  public statusPreenchimento: StatusPreenchimento;

  ngOnInit(): void {
    this.form = this.enderecoForm.buildFormGroup();
  }

  private preencherForm(endereco: AddressDto): void {
    this.form?.patchValue({
      zipCode: endereco?.zipCode || null,
      street: endereco?.street || null,
      number: endereco?.number || null,
      complement: endereco?.complement || null,
      neighborhood: endereco?.neighborhood || null,
      city: endereco?.city || null,
      state: endereco?.state || null,
    });
  }


  public submit(): void {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }

    this.atualizarEndereco();
  }

  private atualizarEndereco(): void {
    const dadosBasicos: AddressDto = Object.assign({}, this.form.value);
    this.aoSubmeter.emit(dadosBasicos);
    this.alterarAberturaAccordion(false);
  }

  public alterarAberturaAccordion(estaAberto: boolean): void {
    this.accordionEstaAberto = estaAberto;
  }
}
