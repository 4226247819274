import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DocumentosSelecionarTipoModule } from 'libs/ui-mobile/src/lib/components';
import { ProprietariosDocumentosSelecionarTipoComponent } from './proprietarios-documentos-selecionar-tipo.component';

@NgModule({
  declarations: [
    ProprietariosDocumentosSelecionarTipoComponent
  ],
  imports: [
    // root
    CommonModule,

    // Components
    DocumentosSelecionarTipoModule,
  ],
  exports: [
    ProprietariosDocumentosSelecionarTipoComponent
  ]
})
export class ProprietariosDocumentosSelecionarTipoModule { }
