import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgxMaskModule } from 'ngx-mask';
import { DatepickerModalComponent } from './datepicker-modal/datepicker-modal.component';
import { DatepickerComponent } from './datepicker.component';

@NgModule({
  declarations: [DatepickerComponent, DatepickerModalComponent],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
  ],
  exports: [DatepickerComponent]
})
export class DatepickerModule { }
