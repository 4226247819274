import { Component, Inject, Input } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { NavigationService } from '../../services/navigation.service';
import { AUTH_QUERY_INJECT_KEY, IAuthQuery, INotificacoesQuery, NOTIFICACOES_QUERY_INJECT_KEY } from '../../shared';

@Component({
  selector: 'usucampeao-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @Input() showBackButton = true;
  @Input() title!: string;
  @Input() backRoute!: string;

  public foto$ = this.authQuery.foto$;
  public quantidadeNotificacoesNaoLidas$ = this.notificacoesQuery.quantidadeNotificacoesNaoLidas$;

  constructor(
    @Inject(AUTH_QUERY_INJECT_KEY) private authQuery: IAuthQuery,
    @Inject(NOTIFICACOES_QUERY_INJECT_KEY) private notificacoesQuery: INotificacoesQuery,
    private menu: MenuController,
    private navigationService: NavigationService
  ) { }

  public abrirMenu(): void {
    this.menu.open('menu');
  }

  public voltar(): void {
    this.navigationService.voltar(this.backRoute);
  }
}
