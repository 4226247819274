/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { RegistrationStatus } from '@usucampeao/lib-reurb-simplificado';

/**
 * Retorna o nome do ícone correspondente ao status de cadastro.
 * Se o link do contrato foi enviado, alterará o ícone do status de aguardando assinatura.
 * Se o contrato tiver sido assinado parcialmente, ou seja, somente por uma das partes, alterará o ícone do status de aguardando assinatura.
 *
 * @param status O status de cadastro.
 * @param linkEnviado Indica se o link de assinatura do contrato foi enviado (opcional, padrão: false).
 * @param assinadoParcialmente Indica se o contrato foi assinado parcialmente (opcional, padrão: false).
 * @returns O nome do ícone correspondente ao status de cadastro.
 */
export function cadastroIconeNome(status: RegistrationStatus, linkEnviado = false, assinadoParcialmente = false): string {
  switch (status) {
    case RegistrationStatus.AVAILABLE_SCHEDULE:
    case RegistrationStatus.VALIDATION_SCHEDULED:
    case RegistrationStatus.MISSED_SCHEDULE:
    case RegistrationStatus.DELIVERY_SCHEDULED:
      return 'calendar-sharp';

    case RegistrationStatus.INCOMPLETE:
    case RegistrationStatus.CHANGES_REQUESTED:
    case RegistrationStatus.CONTRATO_FECHADO:
      return 'warning-sharp';

    case RegistrationStatus.AGUARDANDO_ASSINATURA_CONTRATO:
      if (assinadoParcialmente) {
        return 'information-circle';
      }
      if (linkEnviado) {
        return 'document-text-outline';
      }
      return 'warning-sharp';

    case RegistrationStatus.READY_TO_SUBMIT:
    case RegistrationStatus.PRONTO_PARA_FECHAR_CONTRATO:
      return 'send-sharp';

    case RegistrationStatus.IN_ANALYSIS:
      return 'information-circle-sharp';

    case RegistrationStatus.VALIDATED:
      return 'checkmark-done-circle-sharp';

    case RegistrationStatus.ENVIADO_PARA_PREFEITURA:
      return 'information-circle-sharp';

    case RegistrationStatus.PUBLISHED_OFFICIAL_JOURNAL:
      return 'newspaper-sharp';

    case RegistrationStatus.REGISTERED:
      return 'folder-sharp';

    case RegistrationStatus.DELIVERED_TITLE:
      return 'ribbon-sharp';

    case RegistrationStatus.DISTRATO:
    case RegistrationStatus.CANCELED:
      return 'close-circle';

    default:
      return 'checkmark-circle-sharp';
  }
}


/**
 * Retorna a cor do ícone de status de cadastro com base no status fornecido.
 * Se o link do contrato foi enviado, alterará a cor do ícone do status de aguardando assinatura.
 *
 * @param status O status do cadastro.
 * @param linkEnviado Indica se o link de assinatura do contrato foi enviado (opcional, padrão: false).
 * @returns O nome do ícone correspondente ao status.
 */
export function cadastroIconeCor(status: RegistrationStatus, linkEnviado = false): string {
  switch (status) {
    case RegistrationStatus.INCOMPLETE:
    case RegistrationStatus.READY_TO_SUBMIT:
    case RegistrationStatus.PRONTO_PARA_FECHAR_CONTRATO:
    case RegistrationStatus.AVAILABLE_SCHEDULE:
      return 'warning';

    case RegistrationStatus.AGUARDANDO_ASSINATURA_CONTRATO:
      if (linkEnviado) {
        return 'medium';
      }

      return 'warning';

    case RegistrationStatus.CHANGES_REQUESTED:
    case RegistrationStatus.MISSED_SCHEDULE:
    case RegistrationStatus.DISTRATO:
    case RegistrationStatus.CANCELED:
      return 'danger';

    case RegistrationStatus.VALIDATED:
    case RegistrationStatus.DELIVERY_SCHEDULED:
    case RegistrationStatus.DELIVERED_TITLE:
      return 'success';

    default:
      return 'medium';
  }
}
