import { IonicModule } from '@ionic/angular';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ModalProjetoNaoEncontradoComponent } from './modal-projeto-nao-encontrado.component';

@NgModule({
  declarations: [ModalProjetoNaoEncontradoComponent],
  imports: [
    CommonModule,
    IonicModule,
  ],
  exports: [ModalProjetoNaoEncontradoComponent],
})
export class ModalProjetoNaoEncontradoModule { }
