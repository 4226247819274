import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ModalPoliticaPrivacidadeModule } from 'apps/reurb-simplificado/src/app/shared/components/modal-politica-privacidade/modal-politica-privacidade.module';
import { UsuarioFormModule } from '../../../../shared/components/usuario-form/usuario-form.module';
import { CriarContaRoutingModule } from './criar-conta-routing.module';
import { CriarContaComponent } from './criar-conta.component';


@NgModule({
  declarations: [
    CriarContaComponent
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,
    FormsModule,

    // route
    CriarContaRoutingModule,

    // components
    ModalPoliticaPrivacidadeModule,
    UsuarioFormModule,
  ]
})
export class CriarContaModule { }
