import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CadastroComentarioListaDto } from '@usucampeao/lib-reurb-simplificado';
import { BaseComponent, LoadingService, ToastService } from '@usucampeao/ui-mobile';
import { Observable } from 'rxjs';
import { catchError, finalize, switchMap, takeUntil, tap } from 'rxjs/operators';
import { CadastrosQuery } from '../state/cadastros.query';
import { CadastroService } from '../state/cadastros.service';

@Component({
  templateUrl: './comentarios.page.html',
  styleUrls: ['./comentarios.page.scss']
})
export class ComentariosPage extends BaseComponent implements OnInit {
  private cadastroId: string;
  public comentarios$: Observable<CadastroComentarioListaDto[]>;
  /**
   * Flag para controlar o form do componente filho.
   * Quando o comentário é adicionado com sucesso, o form é resetado.
   */
  public resetarForm = false;

  constructor(
    private cadastrosQuery: CadastrosQuery,
    private cadastrosService: CadastroService,
    private loadingService: LoadingService,
    private route: ActivatedRoute,
    private toastService: ToastService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.route.params
      .pipe(
        tap(params => this.cadastroId = params.cadastroId),
        tap(() => this.comentarios$ = this.cadastrosQuery.buscarComentarios(this.cadastroId)),
        tap(() => this.carregarComentariosDoCadastro()),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe()
  }

  public async carregarComentariosDoCadastro(event?: any): Promise<void> {
    if (!event) {
      await this.loadingService.createLoader();
    }

    this.cadastrosService.buscarComentariosDoCadastro(this.cadastroId)
      .pipe(
        catchError(error => {
          this.toastService.error('Erro ao carregar comentários. Por favor tente novamente.').toPromise();
          throw error;
        }),
        finalize(async () => event ? event.target.complete() : await this.loadingService.dismiss()),
      )
      .subscribe();
  }

  public adicionarComentario(mensagem: string): void {
    this.loadingService.createLoader();

    this.cadastrosService.adicionarComentarioAoCadastro(this.cadastroId, mensagem)
      .pipe(
        switchMap(() => this.toastService.success('Comentário adicionado com sucesso.')),
        tap(() => {
          this.resetarForm = true;
          setTimeout(() => {
            this.resetarForm = false;
          }, 1000);
        }),
        catchError(async error => {
          await this.toastService.error('Erro ao adicionar comentário. Por favor tente novamente.').toPromise();
          throw error;
        }),
        finalize(() => this.loadingService.dismiss()),
      )
      .subscribe();

  }
}
