import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { PipesModule } from '@usucampeao/utils-frontend';
import { CadastroStatusModule } from 'libs/ui-mobile/src/lib/components/cadastro-ficha-status/cadastro-status.module';
import { DocumentosListaModule, MenuItemModule } from '../../../../components';
import { ImoveisDocumentosComponent } from './imoveis-documentos.component';


@NgModule({
  declarations: [
    ImoveisDocumentosComponent
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,

    // components
    CadastroStatusModule,
    DocumentosListaModule,
    MenuItemModule,

    // others
    PipesModule,
  ],
  exports: [
    ImoveisDocumentosComponent
  ]
})
export class ImoveisDocumentosModule { }
