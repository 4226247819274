import { Component, Input, Optional, Self } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NgControl } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { format, formatISO, isAfter, isBefore, isValid, parse } from 'date-fns';
import { DatepickerModalComponent } from './datepicker-modal/datepicker-modal.component';

@Component({
  selector: 'usucampeao-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
})
export class DatepickerComponent implements ControlValueAccessor {
  @Input() label: string;
  @Input() placeholder: string;
  @Input() formControlName: string;
  @Input() maxDate: Date;
  @Input() minDate: Date = new Date(1900, 0, 1);
  @Input() showTime = false;
  @Input() disabled = false;

  public dataFormatada: string;
  public onChange: Function;
  public onTouched: Function;

  private readonly formatoData = 'dd/MM/yyyy';

  constructor(
    @Self() @Optional() private control: NgControl,
    private modalCtrl: ModalController
  ) {
    if (this.control) {
      this.control.valueAccessor = this;
    }
  }

  public get dataSelecionada(): Date {
    const dataSelecionada = parse(this.dataFormatada, this.formatoData, new Date());
    const dataValida = isValid(dataSelecionada);

    return dataValida ? dataSelecionada : null;
  }

  public get formControl(): AbstractControl {
    return this.control.control;
  }

  public get errorMessage(): string {
    if (!this.formControl || !this.formControl.touched || this.formControl.valid) {
      return '';
    }

    if (this.formControl.hasError('required')) {
      return this.label + ' obrigatória';
    }

    if (this.formControl.hasError('data-invalida')) {
      return 'Data informada está inválida';
    }

    if (this.formControl.hasError('data-min')) {
      return `Data deve ser depois de ${format(this.minDate, this.formatoData)}`;
    }

    if (this.formControl.hasError('data-max')) {
      return `Data deve ser antes de ${format(this.maxDate, this.formatoData)}`;
    }
  }

  public writeValue(date: Date): void {
    if (date) {
      this.dataFormatada = format(new Date(date), 'dd/MM/yyyy');
    }
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }


  /**
   * Seta a data selecionada na variável de controle.
   * Após a data estar completa, adiciona o valor digitado no form, obedecendo as regras
   * de data minima e máxima.
   * Caso a data seja menor do que a data mínima, será alterada para a data mínima.
   * Caso a data seja maior do que a data maxima, será alterada para a data máxima.
   * @param event evento de mudança do ion input
   */
  public setDate(event: any): void {
    this.dataFormatada = event.detail.value;

    if (!this.dataFormatada) {
      this.formControl.setValue(null);
      return;
    }

    const dataInvalida = !isValid(this.dataSelecionada);
    if (dataInvalida) {
      this.onTouched();
      this.formControl?.setErrors({ 'data-invalida': true });
      return;
    }

    const dataSelecionada = this.dataSelecionada;
    if (this.minDate && isBefore(dataSelecionada, this.minDate)) {
      this.onTouched();
      this.formControl?.setErrors({ 'data-min': true });
      return;
    }

    if (this.maxDate && isAfter(dataSelecionada, this.maxDate)) {
      this.onTouched();
      this.formControl?.setErrors({ 'data-max': true });
      return;
    }

    this.formControl?.setValue(dataSelecionada);
  }

  public async openDatepickerModal(): Promise<void> {
    if (this.disabled) {
      return;
    }

    this.onTouched();
    const value = formatISO(this.dataSelecionada ?? this.maxDate ?? this.minDate ?? new Date());
    const datePickerModal = await this.modalCtrl.create({
      component: DatepickerModalComponent,
      cssClass: 'datepicker-modal',
      backdropDismiss: true,
      componentProps: {
        label: this.label,
        value: value,
        minDate: this.minDate,
        maxDate: this.maxDate,
        showTime: this.showTime
      }
    });
    await datePickerModal.present();

    const { data } = await datePickerModal.onWillDismiss();

    if (data) {
      const value = new Date(data);
      this.dataFormatada = format(value, 'dd/MM/yyyy');
      this.onChange(value);
    }
  }

  public markAsTouched(): void {
    this.formControl?.markAsTouched();
  }
}
