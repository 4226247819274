import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';

import { PipesModule } from '@usucampeao/utils-frontend';
import { CadastroCardComponent } from './cadastro-card.component';

@NgModule({
  declarations: [CadastroCardComponent],
  imports: [
    // root
    CommonModule,
    IonicModule,

    // others
    PipesModule,
  ],
  exports: [CadastroCardComponent],
})
export class CadastroCardModule { }
