import { CadastroHistoricoTipo } from "./cadastro-historico-tipo.enum";

export class CadastroHistoricoDto {
  id: string;
  cadastroId: string;
  payload: any;
  tipo: CadastroHistoricoTipo;
  usuarioId: string;
  usuarioNome: string;
  usuarioFoto: string;
  dataModificacao: Date;
  usuarioBackoffice: boolean;
}
