import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { ImoveisEditarModule } from '@usucampeao/ui-mobile';
import { ImoveisEditarRoutingModule } from './imoveis-editar-routing.module';
import { ImoveisEditarPage } from './imoveis-editar.page';

@NgModule({
  declarations: [
    ImoveisEditarPage,
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,

    // route
    ImoveisEditarRoutingModule,

    // components
    ImoveisEditarModule,
  ]
})
export class ImoveisEditarPageModule { }
