import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProprietariosDocumentosSelecionarTipoPage } from './proprietarios-documentos-selecionar-tipo.page';

const routes: Routes = [{ path: '', component: ProprietariosDocumentosSelecionarTipoPage }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProprietariosDocumentosSelecionarTipoRoutingModule { }
