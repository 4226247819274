/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import Bugsnag from '@bugsnag/js';
import { AlertController, Platform, ToastController, ViewWillLeave } from '@ionic/angular';
import { BaseComponent, NavigationService } from '@usucampeao/ui-mobile';
import { CookieService } from 'ngx-cookie-service';
import { of } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { AuthQuery } from './pages/auth/state/auth.query';
import { IndexedDBService } from './pages/auth/state/indexed-db.service';
import { NotificationsService } from './pages/notifications/state/notifications.service';
import { PushNotificationService } from './shared/services/push-notification.service';

@Component({
  selector: 'usucampeao-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent extends BaseComponent implements OnInit, ViewWillLeave, OnDestroy {
  public deferredPrompt: any;

  @HostListener('window:beforeinstallprompt', ['$event'])
  async onbeforeinstallprompt(e: any) {
    e.preventDefault();
    this.deferredPrompt = e;
    await this.showAlertInstallPwa();
  }

  constructor(
    private readonly authQuery: AuthQuery,
    private readonly alertCtrl: AlertController,
    private readonly cookiesService: CookieService,
    private readonly indexedDbService: IndexedDBService,
    private readonly navigationService: NavigationService,
    private readonly notificacaoService: NotificationsService,
    private readonly platform: Platform,
    private readonly pushNotificationService: PushNotificationService,
    private readonly swUpdate: SwUpdate,
    private readonly toastCtrl: ToastController,
  ) {
    super();
    this.inicializarAtualizacaoPwa();
    this.inicializarPushNotification();
  }

  ngOnInit(): void {
    this.navigationService.iniciarObservacaoRota();
    this.salvarUsuarioIdNoIndexedDB();
    this.showToastIos();
  }

  private inicializarAtualizacaoPwa(): void {
    this.swUpdate.available
      .pipe(
        takeUntil(this.ngUnsubscribe$)
      ).subscribe(() => {
        this.showAlertUpdatePwa();
      });
  }

  private inicializarPushNotification(): void {
    this.authQuery.possuiToken$
      .pipe(
        tap(possuiToken => {
          if (possuiToken) {
            this.pushNotificationService.inicializar();
            this.buscarNotificacoesNaoLidas();
          }
        }),
      )
      .subscribe();
  }

  /**
   * Exibe um alerta de atualização do aplicativo
   */
  private async showAlertUpdatePwa(): Promise<void> {
    const alert = await this.alertCtrl.create({
      header: 'Atenção',
      message: 'Há uma nova atualização do aplicativo.',
      buttons: [
        {
          text: 'Atualizar',
          handler: () => {
            this.cookiesService.delete(environment.cookieInstallName);
            window.location.reload();
          },
        },
      ],
    });
    await alert.present();
  }

  private buscarNotificacoesNaoLidas(): void {
    this.notificacaoService.buscarNotificacaoNaoLidas()
      .pipe(
        catchError(error => {
          const { name, telefone } = this.authQuery.getValue();
          Bugsnag.notify(error, event => {
            event.setUser('newUser', telefone, name);
            event.addMetadata('error response', error.response);
            event.addMetadata('error payload', error.payload);
          });
          return of(null);
        })
      ).subscribe();
  }

  /**
   * Salva o id do usuário no indexedDB.
   * O id é utilizado para identificar o usuário no envio de notificações.
   * Se o usuário não estiver logado, o id não é salvo.
   */
  private salvarUsuarioIdNoIndexedDB(): void {
    const { id } = this.authQuery.getValue();
    if (id) {
      this.indexedDbService.salvarUsuarId(id);
    }
  }

  /**
   * Controle para exibir o toast de instalação do app no iphone
   */
  private async showToastIos(): Promise<void> {
    const isInStandaloneMode: boolean =
      'standalone' in window.navigator && (window.navigator as any).standalone;
    if (this.platform.is('ios') && !isInStandaloneMode) {
      await this.showToastInstallPwa();
    }
  }

  async verifyInternet() {
    const toast = await this.toastCtrl.create({
      message:
        'Verifique sua conexão de internet!',
      position: 'bottom',
    });
    await toast.present();
  }

  /**
   * Exibe um toast explicando como instalar o app no Safari
   */
  private async showToastInstallPwa(): Promise<void> {
    const installCookie = this.cookiesService.get(
      environment.cookieInstallName
    );

    if (!installCookie) {
      const toast = await this.toastCtrl.create({
        message:
          '<p>Instale nosso app no seu Iphone. Clique no ícone <ion-icon name="share-outline"></ion-icon> no Safari e depois em "Adicionar à Tela de Início".</p>',
        position: 'bottom',
        buttons: [
          {
            text: 'Fechar',
            role: 'cancel',
            handler: () => {
              this.cookiesService.set(environment.cookieInstallName, 'true', 3);
            },
          },
        ],
      });
      await toast.present();
    }
  }

  /**
   * Mostra alerta para instalar PWA
   */
  private async showAlertInstallPwa(): Promise<void> {
    const installCookie = this.cookiesService.get(
      environment.cookieInstallName
    );
    if (!installCookie) {
      const alert = await this.alertCtrl.create({
        header: 'Atenção',
        message:
          'Instale nosso aplicativo para facilitar o acesso aos seus processos de regularização.',
        backdropDismiss: false,
        buttons: [
          {
            text: 'Cancelar',
            role: 'cancel',
            handler: () => {
              this.cookiesService.set(environment.cookieInstallName, 'true', 3);
            },
          },
          {
            text: 'Instalar agora',
            handler: () => {
              this.deferredPrompt.prompt();
              this.deferredPrompt = null;
            },
          },
        ],
      });
      await alert.present();
    }
  }
}
