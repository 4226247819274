<section class="projetos-mapa">
  <div class="ion-padding">
    <usucampeao-header [title]="title"></usucampeao-header>
  </div>

  <div class="projetos-mapa__content flex-column">
    <ion-text class="font-text">
      <div class="ion-padding-start ion-padding-end ion-no-margin">
        <ng-content></ng-content>
      </div>
    </ion-text>

    <usucampeao-cadastro-status *ngIf="mostrarAlteracoes" [status]="cadastroStatus" [alteracoes]="alteracoes"></usucampeao-cadastro-status>
    <div class="projetos-mapa__container">
      <usucampeao-mapa [lotes]="lotes" [loteSelecionadoId]="loteSelecionadoId" [mapa]="mapa" [desabilitado]="desabilitado"
        (aoSelecionarLote)="confirmarSelecaoLote($event)"></usucampeao-mapa>
    </div>
  </div>
</section>
