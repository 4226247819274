<ion-content class="ion-padding-horizontal ion-padding-bottom" [scrollY]="false">
  <ion-text>
    <h1 class="title">Aplicar cupom</h1>
  </ion-text>

  <form [formGroup]="form" class="ion-margin-top">
    <p class="font-text">Insira o código do cupom</p>

    <usucampeao-input label="Código do cupom" controlName="codigo">
      <span usu-error>{{ gerarMensagemErro('codigo') }}</span>
    </usucampeao-input>
  </form>

  <div class="m-confirmar-fechamento-contrato__actions">
    <ion-button class="ion-margin-end" mode="md" fill="clear" (click)="fecharModal()">Voltar</ion-button>
    <ion-button mode="md" (click)="validarCupom()">Salvar</ion-button>
  </div>
</ion-content>
