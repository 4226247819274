import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProprietariosDadosContatoPage } from './proprietarios-dados-contato.page';

const routes: Routes = [{ path: '', component: ProprietariosDadosContatoPage }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProprietariosDadosContatoRoutingModule { }
