import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { FooterModule, HeaderModule, UploadFotoDocumentoModule } from '@usucampeao/ui-mobile';
import { TrocarFotoRoutingModule } from './trocar-foto-routing.module';
import { TrocarFotoComponent } from './trocar-foto.component';


@NgModule({
  declarations: [
    TrocarFotoComponent
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,

    // route
    TrocarFotoRoutingModule,

    // components
    FooterModule,
    HeaderModule,
    UploadFotoDocumentoModule,
  ]
})
export class TrocarFotoModule { }
