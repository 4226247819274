import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { PipesModule } from '@usucampeao/utils-frontend';
import { StatusPreenchimentoComponent } from './status-preenchimento.component';

@NgModule({
  imports: [
    // root
    CommonModule,
    IonicModule,

    // others
    PipesModule,
  ],
  declarations: [StatusPreenchimentoComponent],
  exports: [StatusPreenchimentoComponent],
})
export class StatusPreenchimentoModule { }
