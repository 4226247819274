export enum ProjetoUsuarioCategoriaEquipe {
  VENDAS = 1,
  CALL_CENTER = 2,
}

export enum ProjetoUsuarioCategoriaEquipeLabel {
  VENDAS = 'VENDAS',
  CALL_CENTER = 'CALL_CENTER',
}

export enum ProjetoUsuarioCategoria {
  COORDENADOR = 1,
  SUPERVISOR = 2,
  MEMBRO = 3,
  COORDENADOR_CALL_CENTER = 4,
  SUPERVISOR_CALL_CENTER = 5,
  ATENDENTE_CALL_CENTER = 6,
}

export enum ProjetoUsuarioCategoriaLabel {
  COORDENADOR = 'COORDENADOR',
  SUPERVISOR = 'SUPERVISOR',
  MEMBRO = 'MEMBRO',
  COORDENADOR_CALL_CENTER = 'COORDENADOR_CALL_CENTER',
  SUPERVISOR_CALL_CENTER = 'SUPERVISOR_CALL_CENTER',
  ATENDENTE_CALL_CENTER = 'ATENDENTE_CALL_CENTER',
}

export type ProjetoUsuario = {
  id: number | null;
  usuario_id: number;
  projeto_id: number;
  ativo: boolean;
  categoria: ProjetoUsuarioCategoria;
  usuario_criacao_id: number;
  data_criacao: Date;
  usuario_remocao_id: number | null;
  data_remocao: Date | null;
}
