import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProprietariosDocumentosPage } from './proprietarios-documentos.page';

const routes: Routes = [{ path: '', component: ProprietariosDocumentosPage }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProprietariosDocumentosRoutingModule { }
