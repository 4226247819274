import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProprietarioFichaDto } from '@usucampeao/ui-mobile';

@Component({
  selector: 'usucampeao-proprietario-ficha',
  templateUrl: './proprietario-ficha.component.html',
  styleUrls: ['./proprietario-ficha.component.scss']
})
export class ProprietarioFichaComponent {
  @Input() proprietario: ProprietarioFichaDto;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  public navegarPara(rota: string): void {
    this.router.navigate([this.proprietario.id, rota], { relativeTo: this.route });
  }
}
