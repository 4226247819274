import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { AuthQuery } from '../state/auth.query';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(private router: Router, private authQuery: AuthQuery) { }

  canActivate(): Observable<boolean> {
    return this.authQuery.possuiToken$
      .pipe(
        tap(possuiToken => {
          if (!possuiToken) {
            this.router.navigateByUrl('login');
          }
        }),
        take(1)
      );

  }
}
