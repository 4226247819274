import { Component, Inject, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Endereco } from '@usucampeao/interfaces';
import { NotificacaoDto } from '@usucampeao/lib-reurb-simplificado';
import { AUTH_QUERY_INJECT_KEY, IAuthQuery } from '../../../../shared';


@Component({
  selector: 'usucampeao-vencimento-proximo',
  templateUrl: './vencimento-proximo.component.html',
  styleUrls: ['./vencimento-proximo.component.scss'],
})
export class VencimentoProximoComponent {
  @Input() set notificacao(notificacao: NotificacaoDto) {
    this.notificacaoSelecionada = notificacao;
    this.endereco = this.notificacaoSelecionada?.payload?.endereco;
  }

  public notificacaoSelecionada: NotificacaoDto;
  public endereco: Endereco;
  public nomeUsuario$ = this.authQuery.nome$;

  constructor(
    @Inject(AUTH_QUERY_INJECT_KEY)
    private readonly authQuery: IAuthQuery,
    private readonly router: Router,
  ) { }

  public navegarParaPagamento(): void {
    this.router.navigate(['cadastros', this.notificacaoSelecionada?.cadastroId, 'pagamentos', this.notificacaoSelecionada?.payload?.pagamentoId]);
  }
}
