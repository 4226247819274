import * as numero from 'numero-por-extenso';

export const numeroExtenso = (value: string | number, tipo?: NumeroExtensoTipo) => {
  if (value !== 0 && !value) {
    return '';
  }

  return numero.porExtenso(value, tipo);
}

export enum NumeroExtensoTipo {
  MONETARIO = numero.estilo.monetario,
  PORCENTAGEM = numero.estilo.porcentagem
}
