import { IsNotEmpty, IsString } from 'class-validator';

export class CidadeUsuarioCriarDto {
  @IsString()
  @IsNotEmpty()
  usuarioId: string;
}


export const EMAIL_USU = '@usucampeao.com.br';
