import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AlertController, ViewWillLeave } from '@ionic/angular';
import { Nationality, UF } from '@usucampeao/interfaces';
import {
  Gender,
  MaritalStatus,
  MatrimonialRegime,
  OwnerFillInStatusDto,
  ProprietarioDadosPessoaisDto,
  RegistrationStatus,
  RendaFamiliar,
  STATUS_REGIME_BENS_DATA_CASAMENTO,
  StatusPreenchimento
} from '@usucampeao/lib-reurb-simplificado';
import { AbstractForm } from '@usucampeao/utils-frontend';
import { subDays } from 'date-fns';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'usucampeao-proprietarios-dados-basicos-form',
  templateUrl: './proprietarios-dados-basicos-form.component.html',
  styleUrls: ['./proprietarios-dados-basicos-form.component.scss']
})
export class ProprietariosDadosBasicosFormComponent extends AbstractForm implements OnChanges, ViewWillLeave, OnDestroy {
  @Input() set dadosPessoais(dadosPessoais: ProprietarioDadosPessoaisDto) {
    this.preencherForm(dadosPessoais);
    this.proprietarioPrincipal = dadosPessoais?.main;
    this.possuiConjuge = !!dadosPessoais?.spouseId;
  }
  @Input() set status(status: OwnerFillInStatusDto) {
    this.statusPreenchimento = status?.personalData || StatusPreenchimento.PENDENTE;
  }
  @Input() accordionEstaAberto = false;
  @Input() formDesabilitado = false;

  @Output() aoSubmeter = new EventEmitter<ProprietarioDadosPessoaisDto>();

  private readonly ngUnsubscribe$ = new Subject<void>();
  public proprietarioPrincipal: boolean;
  private possuiConjuge: boolean;
  public statusPreenchimento: StatusPreenchimento;
  public dadosCasamentoDesabilitados = false;

  public readonly generos = Object.values(Gender);
  public readonly nacionalidades = Object.values(Nationality);
  public readonly estados = Object.values(UF);
  public readonly estadosCivis = Object.values(MaritalStatus);
  public readonly regimesDeBens = Object.values(MatrimonialRegime);
  public readonly rendasFamiliares = Object.values(RendaFamiliar);
  public readonly dataMaximaCasamentoNascimento = subDays(new Date(), 1);

  constructor(
    private alertCtrl: AlertController,
    private fb: FormBuilder
  ) {
    super();
    this.form = this.fb.group({
      name: [null, Validators.required],
      gender: [null, Validators.required],
      birthDate: [null, Validators.required],
      nationality: [null, Validators.required],
      state: [null, Validators.required],
      naturalidade: [null, Validators.required],
      rendaFamiliar: [null, Validators.required],
      profession: [null, Validators.required],
      maritalStatus: [null, Validators.required],
      matrimonialRegime: [null, Validators.required],
      weddingDate: [null, Validators.required],
    });

    this.inicializarValidacoesForm();
  }

  ngOnChanges(): void {
    this.dadosCasamentoDesabilitados = !this.proprietarioPrincipal || this.formDesabilitado;
  }

  ionViewWillLeave(): void {
    this.ngUnsubscribe$.next();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.complete();
  }

  public get cadastroStatus(): RegistrationStatus {
    return RegistrationStatus.CHANGES_REQUESTED;
  }

  public get mostrarDadosCasamento(): boolean {
    const estavoCivil = this.form.get('maritalStatus').value;
    return STATUS_REGIME_BENS_DATA_CASAMENTO.includes(estavoCivil);
  }

  public get mostrarDadosNaturalidade(): boolean {
    const nacionalidade = this.form.get('nationality').value;
    return nacionalidade === Nationality.BRASIL;
  }

  private inicializarValidacoesForm(): void {
    this.inicializarValidacaoNacionalidade();
    this.inicializarValidacaoEstadoCivil();
  }

  private inicializarValidacaoNacionalidade(): void {
    this.form.get('nationality').valueChanges
      .pipe(
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe(() => {
        const estadoControl = this.form.get('state');
        const naturalidadeControl = this.form.get('naturalidade');

        if (this.mostrarDadosNaturalidade) {
          estadoControl.setValidators(Validators.required);
          naturalidadeControl.setValidators(Validators.required);
        } else {
          estadoControl.clearValidators();
          estadoControl.setValue(null);
          naturalidadeControl.clearValidators();
          naturalidadeControl.setValue(null);
        }

        estadoControl.updateValueAndValidity();
        naturalidadeControl.updateValueAndValidity();
      });
  }

  private inicializarValidacaoEstadoCivil(): void {
    this.form.get('maritalStatus').valueChanges
      .pipe(
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe(() => {
        const regimeDeBensControl = this.form.get('matrimonialRegime');
        const dataCasamentoControl = this.form.get('weddingDate');
        if (this.mostrarDadosCasamento) {
          regimeDeBensControl.setValidators(Validators.required);
          dataCasamentoControl.setValidators(Validators.required);
        } else {
          regimeDeBensControl.clearValidators();
          regimeDeBensControl.setValue(null);
          dataCasamentoControl.clearValidators();
          dataCasamentoControl.setValue(null);
        }

        regimeDeBensControl.updateValueAndValidity();
        dataCasamentoControl.updateValueAndValidity();
      });
  }

  private preencherForm(dadosBasicos: ProprietarioDadosPessoaisDto): void {
    this.form.patchValue({
      name: dadosBasicos?.name || null,
      gender: dadosBasicos?.gender || null,
      birthDate: dadosBasicos?.birthDate || null,
      nationality: dadosBasicos?.nationality || null,
      state: dadosBasicos?.state || null,
      naturalidade: dadosBasicos?.naturalidade || null,
      profession: dadosBasicos?.profession || null,
      rendaFamiliar: dadosBasicos?.rendaFamiliar || null,
      maritalStatus: dadosBasicos?.maritalStatus || null,
      matrimonialRegime: dadosBasicos?.matrimonialRegime || null,
      weddingDate: dadosBasicos?.weddingDate || null,
    });
  }

  public submit(): void {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }

    const { maritalStatus } = this.form.value;
    if (this.possuiConjuge && maritalStatus !== MaritalStatus.MARRIED) {
      this.mostrarAlertaTrocaEstadoCivil();
    } else {
      this.atualizarDadosPessoais();
    }
  }

  private async mostrarAlertaTrocaEstadoCivil(): Promise<void> {
    const alert = await this.alertCtrl.create({
      header: 'Atenção!',
      message: 'Alterando o estado civil, todos os dados do cônjuge cadastrado serão perdidos.',
      buttons: [
        {
          text: 'Cancelar',
        }, {
          text: 'Confirmar',
          handler: () => {
            this.atualizarDadosPessoais();
          }
        }
      ]
    });

    await alert.present();
  }

  private atualizarDadosPessoais(): void {
    const dadosBasicos: ProprietarioDadosPessoaisDto = Object.assign({}, this.form.value);
    this.aoSubmeter.emit(dadosBasicos);
    this.alterarAberturaAccordion(false);
  }

  public alterarAberturaAccordion(estaAberto: boolean): void {
    this.accordionEstaAberto = estaAberto;
  }
}
