<section class="contrato">
  <usucampeao-header [backRoute]="rotaAnterior" title="Forma de pagamento"></usucampeao-header>

  <ng-container *ngIf="possuiPagamento && (!reurbGratuito || cadastro.averbacaoSelecionada); else templateSelecionarPagamento">
    <ion-text>
      <p class="font-text font-text--medium font-text-bold ion-margin-vertical">
        Indique como gostaria de parcelar o pagamento do serviço:
      </p>
    </ion-text>

    <form [formGroup]="form" class="ion-margin-top">
      <usucampeao-select controlName="totalParcelas" label="Número de parcelas">
        <ion-select-option *ngFor="let opcaoParcelamento of opcoesParcelamento" [value]="opcaoParcelamento.parcela">
          {{ opcaoParcelamento.parcela | parcela }}
        </ion-select-option>

        <span usu-error>{{ gerarMensagemErro('totalParcelas') }}</span>
      </usucampeao-select>

      <usucampeao-select controlName="diaEntrada" label="Preferência de entrada" *ngIf="!pagamentoAVista">
        <ion-select-option *ngFor="let diaPagamento of diasPagamento" [value]="diaPagamento">
          Dia {{ diaPagamento }}
        </ion-select-option>

        <span usu-error>{{ gerarMensagemErro('diaEntrada') }}</span>
      </usucampeao-select>

      <usucampeao-select controlName="diaVencimento" label="Preferência de vencimento" *ngIf="!pagamentoAVista">
        <ion-select-option *ngFor="let diaPagamento of diasPagamento" [value]="diaPagamento">
          Todo dia {{ diaPagamento }}
        </ion-select-option>

        <span usu-error>{{ gerarMensagemErro('diaVencimento') }}</span>
      </usucampeao-select>

      <div class="contrato__flex contrato__flex--space-between">
        <span *ngIf="!cadastro?.cupomDescontoCodigo" class="text-link" (click)="abrirModalAdicionarCupom()">Aplicar
          cupom</span>

        <ng-container *ngIf="cadastro?.cupomDescontoCodigo">
          <p class="font-text">{{cadastro.cupomDescontoCodigo}}</p>
          <span class="text-link text-link--error" (click)="removerCupom()">Remover cupom</span>
        </ng-container>

      </div>
    </form>

    <usucampeao-cadastro-resumo-pagamento *ngIf="pagamentoSelecionado" [cadastro]="cadastro" [totalParcelas]="form?.get('totalParcelas')?.value" [tabelaPreco]="tabelaPreco"></usucampeao-cadastro-resumo-pagamento>

    <ion-text>
      <p class="font-text">Os boletos estarão disponíveis após a assinatura do contrato.</p>
    </ion-text>
  </ng-container>

  <ng-template #templateSelecionarPagamento>
    <ion-text>
      <h1 class="title">Serviço gratuito</h1>
      <p class="font-text font-text--medium ion-no-margin">
        Você está adquirindo um serviço gratuito.
      </p>
      <p class="font-text font-text--medium ion-no-margin">
        Por favor, complete a sua inscrição assinando o termo de adesão.
      </p>
      <p class="font-text font-text--medium ion-margin-top">
        Outros serviços complementares opcionais estarão disponíveis para aquisição no futuro mediante pagamento.
      </p>
    </ion-text>

    <div class="contrato__container-valor ion-margin-bottom">
      <ion-text>
        <span class="font-text">Valor total</span>
      </ion-text>
      <ion-text>
        <span class="title">Gratuito</span>
      </ion-text>
    </div>
  </ng-template>

  <usucampeao-footer>
    <ion-button id="forma-pagamento-aceitar-contrato" class="ion-margin-bottom" mode="md" (click)="submit()">
      Confirmar formas de pagamento
    </ion-button>
  </usucampeao-footer>
</section>
