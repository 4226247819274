export enum RegistrationStatus {
  VALIDATION_SCHEDULED = 'AGENDADO',
  AVAILABLE_SCHEDULE = 'AGENDAMENTO_DISPONIVEL',
  MISSED_SCHEDULE = 'AGENDAMENTO_PERDIDO',
  CHANGES_REQUESTED = 'ALTERACOES_NECESSARIAS',
  CANCELED = 'CANCELADO',
  WAIVERED = 'DESISTENCIA',
  IN_ANALYSIS = 'EM_ANALISE',
  DELIVERY_SCHEDULED = 'ENTREGA_DOS_TITULOS',
  INCOMPLETE = 'INCOMPLETO',
  INELIGIBLE = 'INELEGIVEL',
  NOT_VALIDATED = 'NAO_VALIDADO',
  READY_TO_SUBMIT = 'PRONTO_PARA_ENVIO',
  PUBLISHED_OFFICIAL_JOURNAL = 'PUBLICADO_NO_DIARIO_OFICIAL',
  REGISTERED = 'REGISTRADO_EM_CARTORIO',
  DELETED = 'REMOVIDO',
  DELIVERED_TITLE = 'TITULO_ENTREGUE',
  VALIDATED = 'VALIDADO',
  ENVIADO_PARA_PREFEITURA = 'ENVIADO_PARA_PREFEITURA',
  AGUARDANDO_ASSINATURA_CONTRATO = 'AGUARDANDO_ASSINATURA_CONTRATO',
  CONTRATO_FECHADO = 'CONTRATO_FECHADO',
  PRONTO_PARA_FECHAR_CONTRATO = 'PRONTO_PARA_FECHAR_CONTRATO',
  DISTRATO = 'DISTRATO',
}

export const RegistrationStatusArray = [
  RegistrationStatus.VALIDATION_SCHEDULED,
  RegistrationStatus.AVAILABLE_SCHEDULE,
  RegistrationStatus.MISSED_SCHEDULE,
  RegistrationStatus.CHANGES_REQUESTED,
  RegistrationStatus.WAIVERED,
  RegistrationStatus.IN_ANALYSIS,
  RegistrationStatus.DELIVERY_SCHEDULED,
  RegistrationStatus.INCOMPLETE,
  RegistrationStatus.INELIGIBLE,
  RegistrationStatus.NOT_VALIDATED,
  RegistrationStatus.READY_TO_SUBMIT,
  RegistrationStatus.PUBLISHED_OFFICIAL_JOURNAL,
  RegistrationStatus.REGISTERED,
  RegistrationStatus.DELETED,
  RegistrationStatus.DELIVERED_TITLE,
  RegistrationStatus.VALIDATED
]

export const CADASTRO_STATUS_DISPONIVEL_CANCELAMENTO = [
  RegistrationStatus.INCOMPLETE,
  RegistrationStatus.READY_TO_SUBMIT,
  RegistrationStatus.PRONTO_PARA_FECHAR_CONTRATO,
  RegistrationStatus.AGUARDANDO_ASSINATURA_CONTRATO,
];

export const CADASTRO_STATUS_DISPONIVEL_APLICAR_CUPOM = [RegistrationStatus.PRONTO_PARA_FECHAR_CONTRATO, RegistrationStatus.AGUARDANDO_ASSINATURA_CONTRATO, RegistrationStatus.READY_TO_SUBMIT];

export const CADASTRO_STATUS_SEM_CONTRATO = [
  RegistrationStatus.INCOMPLETE,
  RegistrationStatus.PRONTO_PARA_FECHAR_CONTRATO,
  RegistrationStatus.AGUARDANDO_ASSINATURA_CONTRATO,
  RegistrationStatus.READY_TO_SUBMIT,
];
