import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { AccordionModule, HeaderModule, PagamentosListagemModule } from '@usucampeao/ui-mobile';
import { BoletosPageRoutingModule } from './boletos-routing.module';
import { BoletosPage } from './boletos.page';

@NgModule({
  declarations: [BoletosPage],
  imports: [
    // root
    CommonModule,
    IonicModule,

    // route
    BoletosPageRoutingModule,

    // components
    HeaderModule,
    AccordionModule,
    PagamentosListagemModule,
  ],
})
export class BoletosPageModule { }
