import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FileDto, OwnerDocument } from '@usucampeao/lib-reurb-simplificado';
import { ArquivosEnviadosRemovidos, BaseComponent, LoadingService, NavigationService, ToastService } from '@usucampeao/ui-mobile';
import { catchError, filter, finalize, switchMap, takeUntil, tap } from 'rxjs/operators';
import { CadastroService } from '../../state/cadastros.service';
import { ProprietariosService } from '../state/proprietarios.service';

@Component({
  templateUrl: './proprietarios-documentos-upload.page.html',
  styleUrls: ['./proprietarios-documentos-upload.page.scss']
})
export class ProprietariosDocumentosUploadPage extends BaseComponent implements OnInit {

  private cadastroId: string;
  private proprietarioId: string;

  public arquivos: FileDto[];
  public documentoId: string;
  public tipo: OwnerDocument;

  constructor(
    private cadastroService: CadastroService,
    private proprietariosService: ProprietariosService,
    private loadingService: LoadingService,
    private navigationService: NavigationService,
    private route: ActivatedRoute,
    private toastService: ToastService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.route.queryParams
      .pipe(
        tap(params => this.tipo = params.tipo),
        tap(params => this.documentoId = params.documentoId),
        tap(() => {
          if (!this.documentoId && !this.tipo) {
            this.navigationService.voltar();
          }
        }),
        filter(() => !!this.documentoId || !!this.tipo),
        switchMap(() => this.route.params),
        tap(params => this.cadastroId = params.cadastroId),
        tap(params => this.proprietarioId = params.proprietarioId),
        tap(() => this.carregarDocumento()),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe();
  }

  private async carregarDocumento(): Promise<void> {
    if (!this.documentoId) {
      return;
    }

    await this.loadingService.createLoader();
    this.cadastroService.buscarArquivosDocumento(this.documentoId)
      .pipe(
        tap(arquivos => this.arquivos = arquivos),
        catchError(() => this.toastService.error('Erro ao carregar documento. Por favor tente novamente')),
        finalize(async () => await this.loadingService.dismiss()),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe();
  }

  public adicionarOuAtualizarDocumento(arquivos: ArquivosEnviadosRemovidos): void {
    if (this.documentoId) {
      this.atualizarDocumento(arquivos);
    } else {
      this.adicionarDocumento(arquivos.arquivosEnviados);
    }
  }

  private async adicionarDocumento(arquivos: File[]): Promise<void> {
    await this.loadingService.createLoader();

    this.proprietariosService.adicionarDocumento(this.cadastroId, this.proprietarioId, arquivos, this.tipo)
      .pipe(
        switchMap(() => this.toastService.success('Documento adicionado com sucesso')),
        tap(() => this.navigationService.voltar()),
        catchError(() => this.toastService.error('Erro ao salvar documento. Por favor tente novamente')),
        finalize(async () => this.loadingService.dismiss()),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe();
  }

  private async atualizarDocumento(arquivos: ArquivosEnviadosRemovidos): Promise<void> {
    await this.loadingService.createLoader();

    this.proprietariosService.atualizarDocumento(this.cadastroId, this.proprietarioId, this.documentoId, arquivos.arquivosEnviados, arquivos.idsArquivosRemovidos)
      .pipe(
        switchMap(() => this.toastService.success('Documento adicionado com sucesso')),
        tap(() => this.navigationService.voltar()),
        catchError(() => this.toastService.error('Erro ao salvar documento. Por favor tente novamente')),
        finalize(async () => this.loadingService.dismiss()),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe();
  }
}
