import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProprietariosDadosDocumentosPage } from './proprietarios-dados-documentos.page';

const routes: Routes = [{ path: '', component: ProprietariosDadosDocumentosPage }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProprietariosDadosDocumentosRoutingModule { }
