import { IsEnum, IsNotEmpty, IsString } from 'class-validator';
import { GatewayPagamento } from './gateway-pagamento.enum';
import { PagamentoStatus } from './pagamento-status.enum';

export class PagamentoConectarBoletoDto {
  @IsString()
  @IsNotEmpty()
  boletoId: string;

  @IsEnum(GatewayPagamento)
  @IsNotEmpty()
  gatewayPagamento: GatewayPagamento;
}

export const PAGAMENTO_STATUS_CONECTAR_BOLETO = [PagamentoStatus.CRIADO, PagamentoStatus.ERRO];
