import { RemoveSpecialCharacters } from '@usucampeao/utils';
import { Exclude, Expose, plainToInstance, Type } from 'class-transformer';
import { IsBoolean, IsEnum, IsNotEmpty, IsNumber, IsOptional, IsString } from 'class-validator';
import { AddressDto } from '../address/address.dto';
import { ProjetoTipo } from '../projeto/projeto-tipo.enum';
import { PropertySituation } from '../property';
import { RegistrationDto } from './registration.dto';

export class CreateRegistrationDto {
  id?: string;

  @Expose()
  @IsNumber()
  @IsNotEmpty()
  blockId: number;

  @Expose()
  @IsNumber()
  @IsNotEmpty()
  lotId: number;

  @Expose()
  @IsString()
  @IsOptional()
  leadId?: string;

  @Expose()
  @IsString()
  @RemoveSpecialCharacters()
  @IsOptional()
  cpfCliente?: string;

  @Expose()
  @IsBoolean()
  @IsOptional()
  isUsuarioBackoffice?: boolean;

  projetoFid?: string;

  @Exclude()
  projetoId?: number;

  propertyId?: string;

  @Exclude()
  usuarioIdResponsavel: number;

  @Exclude()
  usuarioFidResponsavel: string;

  @Exclude()
  usuarioIdInicioContrato: number;

  @Exclude()
  usuarioFidInicioContrato: string;

  @Exclude()
  projetoTipo: ProjetoTipo;

  /**
   * Cria uma nova instância de CreateRegistrationDto a partir de dtos
   * @param props DTO de Cadastro
   * @returns nova instância de CreateRegistrationDto
   */
  static from(props?: RegistrationDto | Partial<RegistrationDto>): CreateRegistrationDto {
    return plainToInstance(CreateRegistrationDto, props, { exposeUnsetFields: false, excludeExtraneousValues: true });
  }
}


export class CadastroCriarDto {
  id?: string;

  propertyId?: string;

  proprietarioId?: string;

  @Expose()
  @IsNumber()
  @IsNotEmpty()
  blockId: number;

  @Expose()
  @IsNumber()
  @IsNotEmpty()
  lotId: number;

  @Expose()
  @IsString()
  @IsNotEmpty()
  projetoFid: string;

  @Exclude()
  projetoId?: number;

  @Exclude()
  projetoTipo?: ProjetoTipo;

  @Expose()
  @Type(() => AddressDto)
  @IsNotEmpty()
  address: AddressDto;

  @Expose()
  @IsBoolean()
  @IsNotEmpty()
  possuiOutroImovel: boolean;

  @Expose()
  @IsEnum(PropertySituation)
  @IsNotEmpty()
  situation: PropertySituation;
}
