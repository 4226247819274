import { Injectable } from "@angular/core";
import { QueryEntity } from '@datorama/akita';
import { LoteListarMapaDto, MapaDto, ProjetoDetalhesComerciaisDto, ProjetoSimplificadoDto, ProjetoTipo, ProjetoVendaStatus, ReurbModalidade, TabelaPrecoDetalhesDto } from '@usucampeao/lib-reurb-simplificado';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ProjetoState, ProjetoStore } from './projeto.store';

@Injectable({ providedIn: 'root' })
export class ProjetoQuery extends QueryEntity<ProjetoState> {
  constructor(protected store: ProjetoStore) {
    super(store);
  }

  public get projetosDisponiveisVenda$(): Observable<ProjetoSimplificadoDto[]> {
    return this.selectAll({ filterBy: projeto => projeto.vendaStatus === ProjetoVendaStatus.EM_ANDAMENTO })
      .pipe(
        map(projetos => projetos.map(projeto => ProjetoSimplificadoDto.from(projeto)))
      );
  };

  public buscarDadosComerciais$(id: string): Observable<ProjetoDetalhesComerciaisDto> {
    return this.selectEntity(id)
      .pipe(
        filter(projeto => !!projeto),
        map(projeto => ProjetoDetalhesComerciaisDto.from(projeto))
      )
  }

  public buscarTabelaPreco$(id: string): Observable<Partial<TabelaPrecoDetalhesDto>[]> {
    return this.selectEntity(id)
      .pipe(
        filter(projeto => !!projeto),
        map(projeto => projeto.tabelaPreco)
      )
  }

  public buscarTabelaPrecoSelecionada$(id: string, reurbModalidade: ReurbModalidade): Observable<Partial<TabelaPrecoDetalhesDto>> {
    return this.selectEntity(id)
      .pipe(
        filter(projeto => !!projeto),
        map(projeto => {
          const { tabelaPreco } = projeto;
          if (!tabelaPreco) {
            return null;
          }
          const tabelaPrecoSelecionada = tabelaPreco.find((item) => item.modalidade === reurbModalidade && (projeto.averbacao ? item.averbacao : !item.averbacao));
          return tabelaPrecoSelecionada ? tabelaPrecoSelecionada : tabelaPreco[0];
        })
      );
  }

  public buscarLotes$(id: string): Observable<LoteListarMapaDto[]> {
    return this.selectEntity(id)
      .pipe(
        filter(projeto => !!projeto),
        map(projeto => projeto.lotes)
      )
  }

  public buscarMapa$(id: string): Observable<MapaDto> {
    return this.selectEntity(id)
      .pipe(
        filter(projeto => !!projeto),
        map(projeto => projeto.mapa)
      )
  }

  public buscarTipo(id: string): Observable<ProjetoTipo> {
    return this.selectEntity(id)
      .pipe(
        filter(projeto => !!projeto),
        map(projeto => projeto.tipo)
      );
  }
}
