/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { PipesModule } from '@usucampeao/utils-frontend';
import { FooterModule, HeaderModule, InputModule, SelectModule } from '../../../components';
import { CadastroResumoPagamentoModule } from '../../../components/cadastro-resumo-pagamento/cadastro-resumo-pagamento.module';
import { ContratoSelecionarFormaPagamentoComponent } from './contrato-selecionar-forma-pagamento.component';
import { ModalAdicionarCupomDescontoComponent } from './modal-adicionar-cupom-desconto/modal-adicionar-cupom-desconto.component';

@NgModule({
  declarations: [
    ContratoSelecionarFormaPagamentoComponent,
    ModalAdicionarCupomDescontoComponent,
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,

    // components
    CadastroResumoPagamentoModule,
    HeaderModule,
    InputModule,
    SelectModule,
    FooterModule,

    // others
    PipesModule,
  ],
  exports: [
    ContratoSelecionarFormaPagamentoComponent,
  ],
})
export class ContratoSelecionarFormaPagamentoModule { }
