import { Component, Input } from '@angular/core';
import { PropertyDto } from '@usucampeao/interfaces';
import { CadastroContratoDto, OwnerDto } from '@usucampeao/lib-reurb-simplificado';
import { TipoContract } from '../../enums';

@Component({
  selector: 'usucampeao-contratos-v2',
  templateUrl: './contratos-v2.component.html',
  styleUrls: ['./contratos-v2.component.scss']
})
export class ContratosV2Component {
  @Input() cadastro: CadastroContratoDto;
  @Input() proprietario: Partial<OwnerDto>;
  @Input() conjuge: Partial<OwnerDto>;
  @Input() imovel: Partial<PropertyDto>;
  @Input() tipo: TipoContract;
  @Input() isPrint: boolean;

  public get tiposContrato(): typeof TipoContract {
    return TipoContract;
  }
}
