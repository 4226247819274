import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { LazyLoadImageHooks } from '@usucampeao/utils-frontend';
import { LAZYLOAD_IMAGE_HOOKS, LazyLoadImageModule } from 'ng-lazyload-image';
import { NgxMaskModule } from 'ngx-mask';
import { CadastroStatusModule } from '../cadastro-ficha-status/cadastro-status.module';
import { AtendimentoCardComponent } from './atendimento-card.component';
import { AtendimentoStatusBadgeModule } from './atendimento-status-badge/atendimento-status-badge.module';

@NgModule({
  declarations: [
    AtendimentoCardComponent,
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,

    // components
    AtendimentoStatusBadgeModule,
    CadastroStatusModule,

    // external libs
    LazyLoadImageModule,
    NgxMaskModule,
  ],
  exports: [AtendimentoCardComponent],
  providers: [
    {
      provide: LAZYLOAD_IMAGE_HOOKS,
      useClass: LazyLoadImageHooks,
    }
  ]
})
export class AtendimentoCardModule { }
