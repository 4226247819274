import { IsCpf, RemoveSpecialCharacters } from '@usucampeao/utils';
import { Type } from 'class-transformer';
import { IsDate, IsEmail, IsEnum, IsNotEmpty, IsOptional, IsString, ValidateIf } from 'class-validator';
import { Gender } from '../../enums/gender.enum';

export class CreateUserDto {
  @IsString()
  @IsNotEmpty()
  name: string;

  @RemoveSpecialCharacters()
  @IsString()
  @IsCpf()
  cpf: string;

  @IsDate()
  @IsNotEmpty()
  @Type(() => Date)
  birthDate: Date;

  @IsEnum(Gender)
  @IsNotEmpty()
  gender: Gender;

  @IsEmail()
  @IsOptional()
  @ValidateIf(data => data.email)
  email?: string;

  @IsString()
  @IsNotEmpty()
  phone: string;
}
