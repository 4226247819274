import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UF } from '@usucampeao/interfaces';
import { AddressDto } from '@usucampeao/lib-reurb-simplificado';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const VIA_CEP_API_URL = 'https://viacep.com.br/ws';

type Endereco = {
  cep: string
  logradouro: string
  complemento: string
  bairro: string
  localidade: string
  uf: UF
  ibge: string
  gia: string
  ddd: string
  siafi: string
};

@Injectable({
  providedIn: 'root'
})
export class EnderecoService {
  constructor(
    private http: HttpClient
  ) { }

  public buscarCep(cep: string): Observable<Partial<AddressDto>> {
    return this.http.get<Endereco>(`${VIA_CEP_API_URL}/${cep}/json`)
      .pipe(
        map(endereco => ({
          street: endereco.logradouro,
          neighborhood: endereco.bairro,
          city: endereco.localidade,
          state: endereco.uf
        })),
      );
  }
}
