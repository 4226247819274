import { IsArray, IsNotEmpty, IsNumber, IsOptional, IsString } from 'class-validator';

export class QuadraCriarDto {
  @IsNumber()
  @IsOptional()
  integracaoId?: number;

  @IsString()
  @IsNotEmpty()
  label: string;

  @IsString()
  @IsOptional()
  arquivoNome?: string;

  @IsString()
  @IsOptional()
  arquivoUrlStorage?: string;

  @IsArray({ each: true })
  @IsOptional()
  coords?: number[][];

  constructor(
    label: string,
    arquivoNome: string,
    arquivoUrlStorage: string,
    integracaoId?: number,
  ) {
    this.integracaoId = integracaoId;
    this.label = label;
    this.arquivoNome = arquivoNome;
    this.arquivoUrlStorage = arquivoUrlStorage;
  }
}
