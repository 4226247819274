import { Clipboard } from '@angular/cdk/clipboard';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PagamentoStatus } from '@usucampeao/lib-reurb-simplificado';
import { NavigationService } from 'libs/ui-mobile/src/lib/services';
import { PagamentoStateDto } from '../../../../domain';
import { ToastService } from '../../../../shared';

@Component({
  selector: 'usucampeao-pagamentos-detalhes',
  templateUrl: './pagamentos-detalhes.component.html',
  styleUrls: ['./pagamentos-detalhes.component.scss']
})
export class PagamentosDetalhesComponent {
  @Input() pagamento: PagamentoStateDto;
  @Output() aoVisualizarBoleto = new EventEmitter<void>();

  constructor(
    private clipboard: Clipboard,
    private toastService: ToastService,
    private navigationService: NavigationService,
  ) { }

  public get pagamentoEstaPago(): boolean {
    return this.pagamento?.status === PagamentoStatus.PAGO;
  }

  public get textoParcela(): string {
    if (this.pagamento?.parcela === 0) {
      return 'Entrada';
    }

    return 'Parcela ' + this.pagamento?.parcela;
  }

  public visualizarBoleto(): void {
    this.aoVisualizarBoleto.emit();
  }

  public async copiarCodigoBarras(): Promise<void> {
    await this.toastService.success('Código de barras copiado com sucesso.').toPromise();
    this.clipboard.copy(this.pagamento.linhaDigitavel);
  }

  public voltar(): void {
    this.navigationService.voltar();
  }
}
