import { Pipe, PipeTransform } from '@angular/core';
import { RendaFamiliar } from '@usucampeao/lib-reurb-simplificado';

@Pipe({
  name: 'rendaFamiliar'
})
export class RendaFamiliarPipe implements PipeTransform {
  transform(value: RendaFamiliar): string {
    switch (value) {
      case RendaFamiliar.ATE_2_SALARIOS:
        return 'Até 2 salários mínimos';
      case RendaFamiliar.DE_2_A_3_SALARIOS:
        return 'De 2 a 3 salários mínimos';
      case RendaFamiliar.DE_3_A_4_SALARIOS:
        return 'De 3 a 4 salários mínimos';
      case RendaFamiliar.DE_4_A_5_SALARIOS:
        return 'De 4 a 5 salários mínimos';
      case RendaFamiliar.ACIMA_5_SALARIOS:
        return 'Acima de 5 salários mínimos';
    }
  }
}
