<ng-container *ngIf="cadastro">
  <button class="ant-btn" *ngIf="mostrarBotaoImprimir" (click)="abrirImpressaoContrato()">Imprimir </button>

  <div #printContract id="print-contract" class="contrato_impressao">
    <table>
      <thead>
        <tr>
          <td>
            <div class="header-space">&nbsp;</div>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <div class="content" [ngSwitch]="versao">
              <usucampeao-contratos-v1 *ngSwitchCase="contratoVersao.V1" [cadastro]="cadastro"
                [proprietario]="proprietario" [conjuge]="conjuge" [imovel]="imovel" [tipo]="tipo"
                [isPrint]="true"></usucampeao-contratos-v1>

              <usucampeao-contratos-v2 *ngSwitchCase="contratoVersao.V2" [cadastro]="cadastro"
                [proprietario]="proprietario" [conjuge]="conjuge" [imovel]="imovel" [tipo]="tipo"
                [isPrint]="true"></usucampeao-contratos-v2>
            </div>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td>
            <div class="footer-space">&nbsp;</div>
          </td>
        </tr>
      </tfoot>
    </table>
    <div class="header">
      <img src="/assets/images/logo.png" alt="UsuCampeão logo">
    </div>
    <div class="footer">
      <p>
        SP - Rua Claudio Soares, 72, 6º andar, bairro Pinheiros, CEP 05.422-030, São Paulo - SP, inscrita no CNPJ/MF sob
        o nº 36.174.049/0001-08. - MG – Rua Jose Rodrigues Pereira, 514, 2˚ andar – Ed Datasinc – Buritis – BH/MG -
        fones: SP: (11) 2537-9430 MG:
        (31) 7576-99950 <br> e-mail: reurb@usucampeao.com.br
      </p>
    </div>
  </div>

  <div [ngSwitch]="versao">
    <usucampeao-contratos-v1 *ngSwitchCase="contratoVersao.V1" [cadastro]="cadastro" [proprietario]="proprietario"
      [conjuge]="conjuge" [imovel]="imovel" [tipo]="tipo"></usucampeao-contratos-v1>

    <usucampeao-contratos-v2 *ngSwitchCase="contratoVersao.V2" [cadastro]="cadastro" [proprietario]="proprietario"
      [conjuge]="conjuge" [imovel]="imovel" [tipo]="tipo"></usucampeao-contratos-v2>
  </div>

</ng-container>
