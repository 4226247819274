import { IsArray, IsNotEmpty, IsNumber, IsOptional, IsString } from 'class-validator';

export class PoliticaCriarEditarDto {
  @IsString()
  @IsNotEmpty()
  nome: string;

  @IsString()
  @IsOptional()
  descricao?: string;

  @IsArray()
  @IsNumber({}, { each: true })
  @IsNotEmpty()
  permissoesIds: number[];
}
