
import { Component, OnDestroy } from '@angular/core';
import { ViewWillLeave } from '@ionic/angular';
import { Subject } from 'rxjs';

@Component({
  template: ''
})
export class BaseComponent implements ViewWillLeave, OnDestroy {

  public readonly ngUnsubscribe$ = new Subject<void>();

  ionViewWillLeave(): void {
    this.ngUnsubscribe$.next();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.complete();
  }
}
