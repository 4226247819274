import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { PipesModule } from '@usucampeao/utils-frontend';

import { DatepickerModule, FooterModule, HeaderModule, InputModule, SelectModule } from '../../../../components';
import { ProprietariosDadosBasicosV2Component } from './proprietarios-dados-basicos-v2.component';

@NgModule({
  declarations: [
    ProprietariosDadosBasicosV2Component
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,
    ReactiveFormsModule,

    // components
    FooterModule,
    HeaderModule,
    InputModule,
    DatepickerModule,
    SelectModule,

    // others
    PipesModule,
  ],
  exports: [
    ProprietariosDadosBasicosV2Component
  ]
})
export class ProprietariosDadosBasicosV2Module { }
