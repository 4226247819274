import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { PAGAMENTOS_STATUS_PAGOS, PagamentoListagemDto } from '@usucampeao/lib-reurb-simplificado';
import { CadastroFichaDto } from '@usucampeao/ui-mobile';

@Component({
  selector: 'usucampeao-pagamentos-listagem',
  templateUrl: './pagamentos-listagem.component.html',
  styleUrls: ['./pagamentos-listagem.component.scss']
})
export class PagamentosListagemComponent {
  @Input() isAgente = false;
  @Input() pagamentos: PagamentoListagemDto[] = [];
  @Input() cadastro: Partial<CadastroFichaDto>;
  @Input() dadosSimplificados = false;

  constructor(
    private router: Router,
  ) { }

  public get pagamentosPagos(): PagamentoListagemDto[] {
    return this.pagamentos.filter(pagamento => PAGAMENTOS_STATUS_PAGOS.includes(pagamento.status));
  }

  public get pagamentosPendentes(): PagamentoListagemDto[] {
    return this.pagamentos.filter(pagamento => !PAGAMENTOS_STATUS_PAGOS.includes(pagamento.status));
  }

  public irParaDetalhesDoPagamento(pagamentoId: string): void {
    this.router.navigate([this.isAgente ? 'atendimentos' : 'cadastros', this.cadastro.id, 'pagamentos', pagamentoId]);
  }
}
