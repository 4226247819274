/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController, NavController } from '@ionic/angular';
import { CadastroContratoAssinaturaTipoEnvioLink } from '@usucampeao/interfaces';
import { addHours, isBefore } from 'date-fns';
import { catchError, finalize, tap } from 'rxjs/operators';
import { ModalSelecaoTipoEnvioLinkComponent, ModalSelecaoTipoEnvioLinkOutput } from '../../../components';
import { CadastroFichaDto } from '../../../domain';
import { NavigationService } from '../../../services';
import { ContratoService } from '../../../services/contrato.service';
import { LoadingService, ToastService } from '../../../shared';

@Component({
  selector: 'usucampeao-contrato-assinatura-tipo-link',
  templateUrl: './contrato-assinatura-tipo-link.component.html',
  styleUrls: ['./contrato-assinatura-tipo-link.component.scss']
})
export class ContratoAssinaturaTipoLinkComponent {
  @Input() cadastro: CadastroFichaDto;

  @Output() aoReenviarLink = new EventEmitter<void>();
  @Output() aoAlterarTipoAssinatura = new EventEmitter<void>();

  constructor(
    private readonly contratoService: ContratoService,
    private readonly loadingService: LoadingService,
    private readonly navCtrl: NavController,
    private readonly navigationService: NavigationService,
    private readonly modalCtrl: ModalController,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly toastService: ToastService,
  ) { }

  public get contratoLinkTipoEnvio(): typeof CadastroContratoAssinaturaTipoEnvioLink {
    return CadastroContratoAssinaturaTipoEnvioLink;
  }

  public get contratoLinkDataProximoEnvio(): Date {
    const data = this.cadastro?.contratoLinkDataUltimoEnvio ? new Date(this.cadastro.contratoLinkDataUltimoEnvio) : new Date();
    return addHours(data, 1);
  }

  public get mostrarLinkReenvio(): boolean {
    return isBefore(this.contratoLinkDataProximoEnvio, new Date());
  }

  public async mostrarModalSelecaoTipoEnvioLink(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalSelecaoTipoEnvioLinkComponent,
      componentProps: {
        cadastroId: this.cadastro.id,
        proprietarioId: this.cadastro.proprietarioId,
        proprietarioEmail: this.cadastro?.proprietarioEmail,
        proprietarioWhatsapp: this.cadastro?.proprietarioWhatsapp,
        falhaEnvio: this.cadastro?.contratoLinkFalhaEnvio,
        contatoEnviado: this.cadastro?.contratoLinkContatoEnviado,
      },
      cssClass: 'modal-selecionar-tipo-envio-link'
    });

    await modal.present();
    const { data } = await modal.onDidDismiss<ModalSelecaoTipoEnvioLinkOutput>();

    if (data.confirmarSelecaoTipo) {
      return this.reenviarLinkAssinatura(data.tipoEnvioLink);
    }
  }

  private async reenviarLinkAssinatura(contratoLinkTipoEnvio: CadastroContratoAssinaturaTipoEnvioLink): Promise<void> {
    await this.loadingService.createLoader();
    this.contratoService.reenviarLinkParaAssinaturaDeContrato(this.cadastro.id, contratoLinkTipoEnvio)
      .pipe(
        tap(() => this.toastService.success('Link reenviado com sucesso').toPromise()),
        tap(() => this.irParaAFichaDoCadastro()),
        catchError(error => {
          this.toastService.error('Erro ao reenviar link para assinatura de contrato').toPromise();
          throw error;
        }),
        finalize(() => this.loadingService.dismiss()),
      )
      .subscribe();
  }

  public irParaAFichaDoCadastro(): void {
    this.navigationService.limparHistoricoNavegacao();
    this.navCtrl.setDirection('back', true, 'back');
    this.router.navigate(['/cadastros', this.cadastro.id], { replaceUrl: true });
  }

  public irParaSelecionarTipoAssinatura(): void {
    this.router.navigate(['../selecionar-tipo-assinatura'], { relativeTo: this.route });
  }
}
