import { IsArray, IsNotEmpty, IsOptional, IsString } from 'class-validator';

export type CadastroAlterarUsuarioResponsavelProps = {
  usuarioId: string;
  cadastroIds: string[];
}

export class CadastroAlterarUsuarioResponsavelDto {
  @IsString()
  @IsNotEmpty()
  usuarioId: string;

  @IsArray()
  @IsString({ each: true })
  @IsOptional()
  cadastrosIds?: string[];
}
