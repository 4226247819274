import { CadastroContratoTipoAssinatura } from '@usucampeao/interfaces';
import { Expose, Type } from 'class-transformer';
import { ReurbModalidade } from '../../enums';
import { AddressDto } from '../address/address.dto';
import { FileDto } from '../file';
import { RevisaoDto } from '../revisao';
import { CadastroStatusVendaType } from './cadastro-status-venda.enum';
import { RegistrationStatus } from './registration-status.enum';

export class ListRegistration {
  @Expose()
  id: string;

  @Expose()
  status: RegistrationStatus;

  @Expose()
  statusVenda: CadastroStatusVendaType;

  @Expose()
  projetoFid: string;

  @Expose()
  projetoNome: string;

  @Expose()
  @Type(() => AddressDto)
  address: AddressDto;

  @Expose()
  propertyId: string;

  @Expose()
  files: FileDto[];

  @Expose()
  reurbModalidade?: ReurbModalidade;

  @Expose()
  contractDate?: Date;

  @Expose()
  contratoDataAssinatura: Date;

  @Expose()
  contratoTipoAssinatura: CadastroContratoTipoAssinatura;

  @Expose()
  contratoDataExpiracao?: Date;

  @Expose({ name: 'ownerId' })
  proprietarioId?: string;

  @Expose()
  proprietarioNome?: string;

  @Expose()
  proprietarioCpf?: string;

  @Expose()
  revisao?: RevisaoDto;

  @Expose()
  quadra: string;

  @Expose()
  lote: string;

  constructor(
    id: string,
    status: RegistrationStatus,
    statusVenda: CadastroStatusVendaType,
    projetoFid: string,
    projetoNome: string,
    address: AddressDto,
    propertyId: string,
    quadra: string,
    lote: string,
    files: FileDto[],
    reurbModalidade?: ReurbModalidade,
    contractDate?: Date,
    contratoDataAssinatura?: Date,
    contratoTipoAssinatura?: CadastroContratoTipoAssinatura,
    contratoDataExpiracao?: Date,
    proprietarioId?: string,
    proprietarioNome?: string,
    proprietarioCpf?: string,
    revisao?: RevisaoDto,
  ) {
    this.id = id;
    this.status = status;
    this.statusVenda = statusVenda;
    this.projetoFid = projetoFid;
    this.projetoNome = projetoNome;
    this.address = address;
    this.propertyId = propertyId;
    this.quadra = quadra;
    this.lote = lote;
    this.files = files;
    this.contractDate = contractDate;
    this.reurbModalidade = reurbModalidade;
    this.contratoDataAssinatura = contratoDataAssinatura;
    this.contratoTipoAssinatura = contratoTipoAssinatura;
    this.contratoDataExpiracao = contratoDataExpiracao;
    this.proprietarioId = proprietarioId;
    this.proprietarioNome = proprietarioNome;
    this.proprietarioCpf = proprietarioCpf;
    this.revisao = revisao;
  }
}
