<ion-text class="a-necessarias">
  <p class="font-text ion-margin-bottom a-necessarias__margin-bottom">
    O nosso time verificou que o seu cadastro necessita de alterações para que possamos continuar com o processo.
  </p>
  <p class="font-text ion-no-margin">
    As seguintes pendências foram encontradas:
  </p>
  <ng-container *ngFor="let alteracao of notificacao?.payload?.alteracoes">
    <p class="font-text ion-no-margin">
      {{ alteracao.tipo | alteracaoTipo }}
    </p>
    <p class="font-text ion-no-margin a-necessarias__margin-bottom">
      {{ alteracao.descricao }}
    </p>
  </ng-container>

  <p class="font-text a-necessarias__margin-bottom">
    Verifique as pendências levantadas e reenvie seu cadastro para análise para continuar com o processo.
  </p>
  <p class="font-text">
    Você também poderá ver os comentários sobre correções na ficha do cadastro.
  </p>
</ion-text>
