<div class="cadastro-c-aviso ion-padding" [class.cadastro-c-aviso--red]="cardVermelho">
  <ion-text>
    <h2 class="title title--variant ion-no-margin ion-margin-bottom">{{ titulo }}</h2>
    <p class="font-text">{{ descricao }}</p>
  </ion-text>

  <ion-button class="cadastro-c-aviso__button ion-no-padding" [class.cadastro-c-aviso__button--red]="cardVermelho"
    size="small" fill="clear" (click)="navegarParaFichaDoCadastro()">
    {{ textoBotao }}
  </ion-button>
</div>
