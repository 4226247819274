import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContratoSelecionarAverbacaoPage } from './contrato-selecionar-averbacao.page';

const routes: Routes = [{ path: '', component: ContratoSelecionarAverbacaoPage }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ContratoSelecionarAverbacaoRoutingModule { }
