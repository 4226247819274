<ion-content class="ion-padding">
  <section class="content-vertical-space-between">
    <usucampeao-header title="Minha conta"></usucampeao-header>

    <div class="perfil__content">
      <div class="perfil__foto">
        <usucampeao-usuario-avatar [src]="foto$ | async" size="large"></usucampeao-usuario-avatar>
        <ion-text color="primary" (click)="irParaEdicaoDeFoto()">
          <p>Trocar foto</p>
        </ion-text>
      </div>

      <usucampeao-usuario-form [editavel]="estaEditavel" [usuario]="usuario$ | async"
        (aoSubmeter)="onSubmit($event)"></usucampeao-usuario-form>

      <div class="perfil__actions">
        <ng-container *ngIf="!estaEditavel">
          <ion-button expand="block" type="submit" mode="md" (click)="habilitarEditarUsuario()">
            Editar
          </ion-button>

          <ion-button *ngIf="!estaEditavel" class="ion-margin-top" mode="md" fill="outline" (click)="voltar()">
            Voltar
          </ion-button>
        </ng-container>

        <ion-button *ngIf="estaEditavel" class="ion-margin-top" mode="md" fill="outline"
          (click)="desabilitarEditarUsuario()">
          Cancelar
        </ion-button>
      </div>
    </div>
    <usucampeao-footer></usucampeao-footer>
  </section>
</ion-content>
