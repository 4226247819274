import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { ProprietariosDadosBasicosV2Module, ProprietariosEditarModule } from '@usucampeao/ui-mobile';
import { ProprietariosEditarRoutingModule } from './proprietarios-editar-routing.module';
import { ProprietariosEditarPage } from './proprietarios-editar.page';

@NgModule({
  declarations: [
    ProprietariosEditarPage
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,

    // route
    ProprietariosEditarRoutingModule,

    // components
    ProprietariosDadosBasicosV2Module,
    ProprietariosEditarModule,
  ]
})
export class ProprietariosEditarPageModule { }
