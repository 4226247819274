import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import {
  AlteracaoDto,
  CadastroComentarioListaDto,
  PAGAMENTOS_STATUS_PAGOS,
  RegistrationStatus,
  RevisaoDto,
  STATUS_CADASTROS_CANCELADOS,
  TipoAlteracao
} from '@usucampeao/lib-reurb-simplificado';
import { CadastroCardDto, CadastroFichaDto, CadastroStateDto, PagamentoStateDto } from '@usucampeao/ui-mobile';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { CadastroState, CadastrosStore } from './cadastros.store';

const STATUS_CADASTROS_COM_PENDENCIAS = [
  RegistrationStatus.CONTRATO_FECHADO,
  RegistrationStatus.CHANGES_REQUESTED,
  RegistrationStatus.READY_TO_SUBMIT,
];

@Injectable({ providedIn: 'root' })
export class CadastrosQuery extends QueryEntity<CadastroState> {

  constructor(protected store: CadastrosStore) {
    super(store);
  }

  /**
   * Retorna uma lista de dados para serem utilizado nos cards do cadastro
   */
  public get cadastrosCard$(): Observable<CadastroCardDto[]> {
    return this.selectAll()
      .pipe(
        map(cadastros => cadastros.map(cadastro => CadastroCardDto.from(cadastro)))
      );
  }

  /**
   * Retorna uma lista de cadastros que tem contrato assinado
   */
  public get cadastrosComContrato$(): Observable<Partial<CadastroStateDto>[]> {
    return this.selectAll({
      filterBy: ({ contratoDataAssinatura }) => !!contratoDataAssinatura
    });
  }

  /**
   * Retorna uma lista de cadastros que não tem contrato assinado
   */
  public get cadastrosSemContrato$(): Observable<Partial<CadastroStateDto>[]> {
    return this.selectAll({
      filterBy: ({ contratoDataAssinatura }) => !contratoDataAssinatura
    });
  }

  /**
   * Retorna uma lista de cadastros que estão com contrato gerado mas possui pendências.
   * Os status que são considerados com pendências são: CONTRATO_FECHADO, CHANGES_REQUESTED e READY_TO_SUBMIT
   */
  public get cadastrosComPendencias$(): Observable<Partial<CadastroStateDto>[]> {
    return this.selectAll({
      filterBy: ({ status, contractDate }) => !!contractDate && STATUS_CADASTROS_COM_PENDENCIAS.includes(status)
    });
  }


  /**
   * Busca a revisão atual de um cadastro
   * @param cadastroId - ID do cadastro
   */
  public buscarRevisao(cadastroId: string): Observable<RevisaoDto> {
    return this.selectEntity(cadastroId)
      .pipe(
        filter(cadastro => !!cadastro),
        map(cadastro => cadastro.revisao),
      );
  }

  /**
   * Busca os dados a serem exibidos na ficha do cadastro
   * @param id ID do cadastro
   */
  public buscarCadastroFicha(id: string): Observable<CadastroFichaDto> {
    return this.selectEntity(id)
      .pipe(
        filter(cadastro => !!cadastro),
        map(cadastro => CadastroFichaDto.from(cadastro))
      )
  }

  /**
   * Busca observação do cadastro
   * @param id ID do cadastro
   */
  public buscarObservacao(id: string): Observable<string> {
    return this.selectEntity(id)
      .pipe(
        filter(cadastro => !!cadastro),
        map(cadastro => cadastro.observacao)
      );
  }

  /**
   * Busca os comentários do cadastro
   *
   * @param cadastroId ID do cadastro
   */
  public buscarComentarios(cadastroId: string): Observable<CadastroComentarioListaDto[]> {
    return this.selectEntity(cadastroId)
      .pipe(
        filter(cadastro => !!cadastro),
        map(cadastro => cadastro.comentarios)
      );
  }

  /**
   * Busca as alteracoes do cadastro, filtrando por tipo e ID do proprietário, caso informado.
   * @param cadastroId - ID do cadastro
   * @param tipo - Tipo da alteração
   * @param proprietarioCadastroId - ID do subcadastro do proprietário
   */
  public buscarAlteracoes$(cadastroId: string, tipo: TipoAlteracao[] = [], proprietarioCadastroId?: string): Observable<AlteracaoDto[]> {
    return this.selectEntity(cadastroId)
      .pipe(
        filter(cadastro => !!cadastro && !!cadastro.revisao),
        map(cadastro => cadastro.revisao),
        map(revisao => {
          if (tipo.length === 0) {
            return revisao.alteracoes;
          }

          return revisao.alteracoes?.filter(alteracao => tipo.includes(alteracao.tipo) && (!proprietarioCadastroId || alteracao.proprietarioCadastroId === proprietarioCadastroId));
        }),
      );
  }

  /**
   * Retorna a flag para indicar se o cadastro está disponível ou não para edição.
   * Verifica se o status do cadastro está EM_ANALISE ou se o cadastro está com algum status de cancelamento.
   * @param id ID do cadastro
   */
  public cadastroEstaDesabilitadoParaEdicao(id: string): Observable<boolean> {
    return this.selectEntity(id)
      .pipe(
        filter(cadastro => !!cadastro),
        map(({ status }) => status === RegistrationStatus.IN_ANALYSIS || STATUS_CADASTROS_CANCELADOS.includes(status))
      );
  }

  /**
   * Retorna a flag para indicar se o cadastro está com status de Alterações Necessárias.
   * @param id - ID do cadastro
   */
  public cadastroEstaEmAlteracoesNecessarias(id: string): boolean {
    const cadastro = this.getEntity(id);
    return cadastro?.status === RegistrationStatus.CHANGES_REQUESTED;
  }

  /**
   * Busca os pagamentos de um cadastro
   * @param cadastroId - ID do cadastro
   */
  public buscarPagamentos(cadastroId: string): Observable<PagamentoStateDto[]> {
    return this.selectEntity(cadastroId)
      .pipe(
        filter(cadastro => !!cadastro),
        map(cadastro => cadastro.pagamentos || [])
      );
  }

  /**
   * Busca os pagamentos que não foram pagos de um cadastro.
   * @param cadastroId - ID do cadastro
   */
  public buscarPagamentosNaoPagos(cadastroId: string): Observable<PagamentoStateDto[]> {
    return this.buscarPagamentos(cadastroId)
      .pipe(
        map(pagamentos => pagamentos.filter(pagamento => !PAGAMENTOS_STATUS_PAGOS.includes(pagamento.status)))
      );
  }

  /**
   * Busca detalhes de um pagamento de um cadastro.
   * @param cadastroId - ID do cadastro
   * @param pagamentoId - ID do pagamento
   */
  public buscarPagamento(cadastroId: string, pagamentoId: string): Observable<PagamentoStateDto> {
    return this.selectEntity(cadastroId)
      .pipe(
        filter(cadastro => !!cadastro && !!cadastro.pagamentos),
        map(cadastro => cadastro.pagamentos.find(pagamento => pagamento.id === pagamentoId)),
      )
  }
}
