import { Expose, plainToClass, Type } from 'class-transformer';
import { IsDate, IsEnum, IsNotEmpty, IsNumber, IsOptional, IsPositive, IsString, Min } from 'class-validator';
import { MeioPagamento, PagamentoDto } from '.';

export class PagamentoCriarDto {
  @Expose()
  @IsNumber()
  @IsNotEmpty()
  parcela: number;

  @Expose()
  @IsNumber()
  @IsNotEmpty()
  totalParcelas: number;

  @Expose()
  @IsString()
  @IsOptional()
  descricao?: string;

  @Expose()
  @IsEnum(MeioPagamento)
  @IsNotEmpty()
  meioPagamento: MeioPagamento;

  @Expose()
  @IsNumber()
  @IsNotEmpty()
  valorDevido: number;

  @Expose()
  @IsDate()
  @IsNotEmpty()
  dataVencimento: Date;

  @Expose()
  @IsString()
  @IsNotEmpty()
  proprietarioId: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  imovelId: string;

  @Expose()
  @IsNumber()
  @IsNotEmpty()
  juros: number;

  @Expose()
  @IsNumber()
  @IsNotEmpty()
  valorAmortizado: number;

  @Expose()
  @IsNumber()
  @IsOptional()
  saldoDevedor?: number;

  @Expose()
  @IsString()
  @IsOptional()
  pagamentoAntigoId?: string;

  @Expose()
  @Type(() => Date)
  @IsDate()
  @IsOptional()
  pagamentoAntigoVencimento?: Date;

  constructor(
    parcela: number,
    totalParcelas: number,
    meioPagamento: MeioPagamento,
    valorDevido: number,
    dataVencimento: Date,
    proprietarioId: string,
    imovelId: string,
    juros: number,
    valorAmortizado: number,
    saldoDevedor?: number,
    descricao?: string,
    pagamentoAntigoId?: string,
    pagamentoAntigoVencimento?: Date
  ) {
    this.parcela = parcela;
    this.totalParcelas = totalParcelas;
    this.meioPagamento = meioPagamento;
    this.valorDevido = valorDevido;
    this.dataVencimento = new Date(dataVencimento);
    this.proprietarioId = proprietarioId;
    this.imovelId = imovelId;
    this.juros = juros;
    this.valorAmortizado = valorAmortizado;
    this.saldoDevedor = saldoDevedor;
    this.descricao = descricao;
    this.pagamentoAntigoId = pagamentoAntigoId;
    this.pagamentoAntigoVencimento = pagamentoAntigoVencimento ? new Date(pagamentoAntigoVencimento) : null;
  }

  /**
   * Cria uma nova instância de PagamentoCriarDto a partir de dtos
   * @param props DTO de Cadastro
   * @returns nova instância de PagamentoCriarDto
   */
  static from(props?: PagamentoDto | Partial<PagamentoDto>): PagamentoCriarDto {
    return plainToClass(PagamentoCriarDto, props, { exposeUnsetFields: false, excludeExtraneousValues: true });
  }
}

export class PagamentoCriarPubSubDto {
  @Expose()
  @IsString()
  @IsNotEmpty()
  cadastroId: string;

  @Expose()
  @Min(0)
  @IsNumber()
  @IsNotEmpty()
  parcela: number;

  @Expose()
  @Min(0)
  @IsNumber()
  @IsNotEmpty()
  totalParcelas: number;

  @Expose()
  @IsEnum(MeioPagamento)
  @IsOptional()
  meioPagamento?: MeioPagamento;

  @Expose()
  @IsPositive()
  @IsNumber()
  @IsNotEmpty()
  valorDevido: number;

  @Expose()
  @Type(() => Date)
  @IsDate()
  @IsNotEmpty()
  dataVencimento: Date;

  @IsNumber()
  @IsOptional()
  juros?: number;

  @IsNumber()
  @IsPositive()
  @IsOptional()
  valorAmortizado?: number;

  @Expose()
  @IsNumber()
  @IsOptional()
  saldoDevedor?: number;

  constructor(props?: {
    cadastroId: string;
    parcela: number;
    totalParcelas: number;
    meioPagamento?: MeioPagamento;
    valorDevido: number;
    dataVencimento: Date;
    juros?: number;
    valorAmortizado?: number;
    saldoDevedor?: number;
  }) {
    this.cadastroId = props?.cadastroId || null;
    this.parcela = props?.parcela ? +props.parcela : null;
    this.totalParcelas = props?.totalParcelas ? +props.totalParcelas : null;
    this.valorDevido = props?.valorDevido ? +props.valorDevido : null;
    this.dataVencimento = props?.dataVencimento ? new Date(props.dataVencimento) : null;
    this.meioPagamento = props?.meioPagamento || MeioPagamento.BOLETO;
    this.juros = props?.juros ? +props.juros : 0;
    this.valorAmortizado = props?.valorAmortizado ? +props.valorAmortizado : this.valorDevido;
    this.saldoDevedor = props?.saldoDevedor ? +props.saldoDevedor : null;
  }
}
