import { Endereco } from '@usucampeao/interfaces';
import { Expose, Type } from 'class-transformer';
import { ProjetoTipo } from '../projeto';

class Proprietario {
  @Expose()
  id: string;

  @Expose()
  name: string;

  @Expose()
  cpf: string;
}

class Imovel {
  @Expose()
  id: string;

  @Expose()
  quadra: string;

  @Expose({ name: 'quadra_id' })
  quadraId: number;

  @Expose()
  lote: string;

  @Expose({ name: 'lote_id' })
  loteId: string;

  @Type(() => Endereco)
  @Expose()
  endereco: Endereco;
}

export class ContratoAlgoliaDto {
  @Expose({ name: 'objectID' })
  id: string;

  @Type(() => Proprietario)
  @Expose()
  proprietarios: Proprietario[];

  @Type(() => Imovel)
  @Expose()
  imovel: Imovel;

  @Expose()
  projeto: string;

  @Expose({ name: 'projeto_id' })
  projetoId: string;

  @Expose({ name: 'projeto_tipo' })
  projetoTipo: ProjetoTipo;
}
