import { Component, Input } from '@angular/core';
import { NotificacaoDto } from '@usucampeao/lib-reurb-simplificado';

@Component({
  selector: 'usucampeao-alteracoes-necessarias',
  templateUrl: './alteracoes-necessarias.component.html',
  styleUrls: ['./alteracoes-necessarias.component.scss'],
})
export class AlteracoesNecessariasComponent {
  @Input() public notificacao: NotificacaoDto;
}
