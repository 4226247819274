<ion-item class="m-item ion-no-padding ion-margin-bottom" [button]="isNavigation" [detail]="isNavigation" [class.m-item--disabled]="isDisabled">
  <div class="m-item__container">
    <div class="m-item__content">
      <ion-icon class="m-item__icon" color="medium" [name]="icon"></ion-icon>

      <div class="m-item__infos ion-margin-horizontal">
        <ion-text>
          <h2 class="m-item__title">{{ title }}</h2>
        </ion-text>

        <ion-text>
          <p class="m-item__description">{{ description }}</p>
        </ion-text>

        <ion-text color="primary" *ngIf="comments">
          <p class="m-item__comments"
            [class.m-item__comments--invalid]="isStatusPendenteOuAlteracaoNecessaria">
            {{ comments }}
          </p>
        </ion-text>
      </div>

      <div class="m-item__status" *ngIf="showIconStatus">
        <ion-icon class="m-item__icon-status" [name]="iconStatus" [color]="iconStatusColor"></ion-icon>
      </div>
    </div>

    <div class="m-item__footer">
      <ng-content></ng-content>
    </div>
  </div>
</ion-item>
