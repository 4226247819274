import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { FooterModule, HeaderModule, MenuItemModule, UsuarioAvatarModule } from '../../../../components';
import { ProprietarioFichaComponent } from './proprietario-ficha/proprietario-ficha.component';
import { ProprietariosFichaComponent } from './proprietarios-ficha.component';

@NgModule({
  declarations: [
    ProprietariosFichaComponent,
    ProprietarioFichaComponent
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,

    // components
    FooterModule,
    HeaderModule,
    MenuItemModule,
    UsuarioAvatarModule,
  ],
  exports: [
    ProprietariosFichaComponent
  ]
})
export class ProprietariosFichaModule { }
