import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { FooterModule, HeaderModule, UploadFotoDocumentoModule } from '@usucampeao/ui-mobile';


import { ContratoAssinaturaTipoFisicoUploadRoutingModule } from './contrato-assinatura-tipo-fisico-upload-routing.module';
import { ContratoAssinaturaTipoFisicoUploadPage } from './contrato-assinatura-tipo-fisico-upload.page';


@NgModule({
  declarations: [
    ContratoAssinaturaTipoFisicoUploadPage
  ],
  imports: [
    // root modules
    CommonModule,
    IonicModule,

    // route
    ContratoAssinaturaTipoFisicoUploadRoutingModule,

    // components
    HeaderModule,
    FooterModule,
    UploadFotoDocumentoModule,
  ]
})
export class ContratoAssinaturaTipoFisicoUploadModule { }
