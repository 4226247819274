import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { FooterModule, HeaderModule, ModalSelecaoTipoEnvioLinkModule } from '@usucampeao/ui-mobile';
import { ContratoTipoAssinaturaModule } from '@usucampeao/utils-frontend';
import { ContratoSelecionarTipoAssinaturaPageRoutingModule } from './contrato-selecionar-tipo-assinatura-routing.module';
import { ContratoSelecionarTipoAssinaturaPage } from './contrato-selecionar-tipo-assinatura.page';

@NgModule({
  imports: [
    // root modules
    CommonModule,
    IonicModule,

    // route
    ContratoSelecionarTipoAssinaturaPageRoutingModule,

    // components modules
    ContratoTipoAssinaturaModule,
    FooterModule,
    HeaderModule,
    ModalSelecaoTipoEnvioLinkModule,
  ],
  providers: [],
  declarations: [ContratoSelecionarTipoAssinaturaPage],
})
export class ContratoSelecionarTipoAssinaturaPageModule { }
