import { Type } from 'class-transformer';
import { IsBoolean, IsDate, IsNotEmpty } from 'class-validator';
import { UsuarioBackofficeTipo } from '../usuario-backoffice';
import { PagamentoStatus } from './pagamento-status.enum';

export class PagamentoAlterarVencimentoDto {
  @Type(() => Date)
  @IsDate()
  @IsNotEmpty()
  dataVencimento: Date;

  @IsBoolean()
  @IsNotEmpty()
  multa: boolean;
}

export const PAGAMENTO_STATUS_ALTERAR_VENCIMENTO = [PagamentoStatus.CRIADO, PagamentoStatus.SOLICITADO_EMISSAO_PAGAMENTO, PagamentoStatus.PROGRAMADO, PagamentoStatus.NAO_PAGO, PagamentoStatus.ERRO];

export const TIPO_USUARIO_PERMITIDO_ALTERAR_PAGAMENTOS = [UsuarioBackofficeTipo.SUPER_ADMIN, UsuarioBackofficeTipo.ADMINISTRADOR, UsuarioBackofficeTipo.GERENTE_FINANCEIRO, UsuarioBackofficeTipo.ANALISTA_FINANCEIRO];
