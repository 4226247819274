<section class="i-editar">
  <usucampeao-header title="Imóvel"></usucampeao-header>

  <div class="ion-margin-bottom" *ngIf="alteracoes?.length">
    <usucampeao-cadastro-status [status]="cadastroAlteracoesNecessarias"
      [alteracoes]="alteracoes"></usucampeao-cadastro-status>
  </div>

  <div class="i-editar__item">
    <usucampeao-imoveis-editar-endereco-form [accordionEstaAberto]="accordionEnderecoEstaAberto" [endereco]="endereco"
      [formDesabilitado]="formDesabilitado" [status]="imovelStatus"
      (aoSubmeter)="alterarEndereco($event)"></usucampeao-imoveis-editar-endereco-form>
  </div>

  <div class="i-editar__item">
    <usucampeao-imoveis-editar-infos-adicionais-form [accordionEstaAberto]="accordionInformacoesAdicionaisEstaAberto"
      [informacoesAdicionais]="informacoesAdicionais" [formDesabilitado]="formDesabilitado" [status]="imovelStatus"
      (aoSubmeter)="alterarInformacoesAdicionais($event)"></usucampeao-imoveis-editar-infos-adicionais-form>
  </div>

  <div class="i-editar__item">
    <usucampeao-upload-foto-documento-v2 titulo="Foto de comprovante de posse"
      [accordionEstaAberto]="accordionDocumentoPosseEstaAberto" [listaDeDocumentos]="documentosPosseLista"
      [documento]="documentoPosse" [statusPreenchimento]="documentoPosseStatus" [readonly]="formDesabilitado"
      (aoSelecionarDocumento)="selecionarDocumentoPosse($event)"
      (aoRealizarUploadBase64)="irParaUploadDeDocumento($event, documentoPosseSelecionado)">
      <ion-text class="font-text i-editar__documentos-lista">
        Documentos aceitos
        <ul>
          <li *ngFor="let documento of documentosPosseLista">{{ documento | documentoTipo }}</li>
        </ul>
      </ion-text>
    </usucampeao-upload-foto-documento-v2>
  </div>

  <div class="i-editar__item">
    <usucampeao-upload-foto-documento-v2 titulo="Foto de documento de aquisição"
      [accordionEstaAberto]="accordionDocumentoAquisicaoEstaAberto" [listaDeDocumentos]="documentosAquisicaoLista"
      [documento]="documentoAquisicao" [statusPreenchimento]="documentoAquisicaoStatus" [readonly]="formDesabilitado"
      (aoSelecionarDocumento)="selecionarDocumentoAquisicao($event)"
      (aoRealizarUploadBase64)="irParaUploadDeDocumento($event, documentoAquisicaoSelecionado)">
      <ion-text class="font-text i-editar__documentos-lista">
        Documentos aceitos
        <ul>
          <li *ngFor="let documento of documentosAquisicaoLista">{{ documento | documentoTipo }}</li>
        </ul>
      </ion-text>
    </usucampeao-upload-foto-documento-v2>
  </div>

  <div class="i-editar__actions">
    <ion-button mode="md" fill="outline" (click)="voltar()">Voltar</ion-button>
  </div>
</section>
