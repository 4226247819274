import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AlertController, InfiniteScrollCustomEvent } from '@ionic/angular';
import { ProjetosListarComDistancia } from '@usucampeao/lib-reurb-simplificado';
import { AbstractForm } from '@usucampeao/utils-frontend';
import { LocalizacaoLatLong, LocalizacaoService } from '../../../services/localizacao.service';

const KM_PROJETOS_PROXIMOS = 2;

export type CarregarNovaPaginaProjetosOutput = Partial<LocalizacaoLatLong> & {
  eventoScrollInfinito: InfiniteScrollCustomEvent;
};

@Component({
  selector: 'usucampeao-projetos-listar-proximos',
  templateUrl: 'projetos-listar-proximos.page.html',
  styleUrls: ['projetos-listar-proximos.page.scss'],
})
export class ProjetosListarProximosPage extends AbstractForm implements OnChanges {
  @Input() projetosProximos: ProjetosListarComDistancia[] = [];
  @Input() projetos: ProjetosListarComDistancia[] = [];
  @Output() aoBuscarLocalizacao = new EventEmitter<LocalizacaoLatLong | null>();
  @Output() aoBuscarTodosOsProjetos = new EventEmitter<Partial<LocalizacaoLatLong>>();
  @Output() aoCarregarNovaPaginaProjetos = new EventEmitter<CarregarNovaPaginaProjetosOutput>();
  @Output() aoSelecionarProjeto = new EventEmitter<string>();

  public isLoading = false;
  public localizacaoEncontrada: LocalizacaoLatLong;
  public projetosMenos2Km: ProjetosListarComDistancia[] = [];
  public projetosMais2Km: ProjetosListarComDistancia[] = [];

  constructor(
    private alertCtrl: AlertController,
    private localizacaoService: LocalizacaoService,
    private formBuilder: FormBuilder,
  ) {
    super();
    this.form = this.formBuilder.group({
      cep: [null, [Validators.required, Validators.minLength(9)]],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { projetosProximos } = changes;
    if (projetosProximos) {
      this.projetosMenos2Km = this.projetosProximos?.filter(projeto => projeto.nucleo_distancia <= KM_PROJETOS_PROXIMOS);
      this.projetosMais2Km = this.projetosProximos?.filter(projeto => projeto.nucleo_distancia > KM_PROJETOS_PROXIMOS);
    }
  }

  public submitForm(): void {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }

    this.buscarLocalizacaoPeloCep();
  }

  /**
   * Busca a localização pelo CEP informado no formulário.
   */
  private async buscarLocalizacaoPeloCep(): Promise<void> {
    this.isLoading = true;
    const { cep } = this.form.value;
    this.localizacaoEncontrada = await this.localizacaoService.buscarLocalizacaoPeloEndereco({ cep });
    this.isLoading = false;
    this.limparProjetos();
    this.aoBuscarLocalizacao.emit(this.localizacaoEncontrada);
  }

  /**
   * Busca a localização do usuário.
   */
  public async buscarLocalizacaoUsuario() {
    this.isLoading = true;
    this.limparProjetos();
    navigator.geolocation.getCurrentPosition(async (resp) => {
      this.localizacaoEncontrada = await this.localizacaoService.buscarLocalizacaoPorLatLong(resp.coords.latitude, resp.coords.longitude);
      this.isLoading = false;
      this.aoBuscarLocalizacao.emit(this.localizacaoEncontrada);
    }, async () => {
      this.isLoading = false;
      const alert = await this.alertCtrl.create({
        header: 'Atenção',
        message: 'Não foi possível obter sua localização. Conceda a permissão de acesso à sua localização e tente novamente.',
        backdropDismiss: true,
        buttons: [
          {
            text: 'Ok',
          },
        ],
      });
      await alert.present();
    });
  }

  /**
   * Altera a localização do usuário.
   * Limpa a localização encontrada e os projetos próximos.
   */
  public alterarLocalizacao(): void {
    this.localizacaoEncontrada = null;
    this.localizacaoService.limparLocalizacaoSelecionadaPeloUsuario();
    this.limparProjetos();
  }

  public limparProjetos(): void {
    this.projetosMenos2Km = [];
    this.projetosMais2Km = [];
    this.projetos = [];
  }

  /**
   * Emite o evento para buscar todos os projetos disponíveis para venda.
   */
  public buscarTodosOsProjetos(): void {
    this.aoBuscarTodosOsProjetos.emit({ lat: this.localizacaoEncontrada?.lat, long: this.localizacaoEncontrada?.long });
  }

  /**
   * Emite o evento para carregar uma nova página de projetos.
   *
   * @param eventoScrollInfinito Evento de scroll infinito.
   */
  public carregarNovaPaginaProjetos(eventoScrollInfinito: any): void {
    this.aoCarregarNovaPaginaProjetos.emit({
      lat: this.localizacaoEncontrada?.lat,
      long: this.localizacaoEncontrada?.long,
      eventoScrollInfinito,
    });
  }

  public selecionarProjeto(projetoId: string): void {
    this.aoSelecionarProjeto.emit(projetoId);
  }
}
