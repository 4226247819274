import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { CadastroSelecionarPagamentosDto, TabelaPrecoDetalhesDto } from '@usucampeao/lib-reurb-simplificado';
import { BaseComponent, CadastroStateDto, ImovelStateDto, LoadingService, ModalContratoProcuracaoComponent, ProprietarioStateDto, ToastService } from '@usucampeao/ui-mobile';
import { Observable, of } from 'rxjs';
import { catchError, finalize, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ProjetoQuery } from '../../../../core/states/projeto/projeto.query';
import { ProjetoService } from '../../../../core/states/projeto/projeto.service';
import { ProjetoStateDto } from '../../../../core/states/projeto/projeto.store';
import { ImoveisQuery } from '../../imoveis/state/imoveis.query';
import { ImoveisService } from '../../imoveis/state/imoveis.service';
import { ProprietariosQuery } from '../../proprietarios/state/proprietarios.query';
import { ProprietariosService } from '../../proprietarios/state/proprietarios.service';
import { CadastrosQuery } from '../../state/cadastros.query';
import { CadastroService } from '../../state/cadastros.service';

@Component({
  templateUrl: './contrato-confirmar-informacoes.page.html',
  styleUrls: ['./contrato-confirmar-informacoes.page.scss']
})
export class ContratoConfirmarInformacoesPage extends BaseComponent implements OnInit {
  private cadastroId: string;
  public cadastro$: Observable<CadastroStateDto>;
  public imovel$: Observable<ImovelStateDto>;
  public proprietario$: Observable<ProprietarioStateDto>;
  public projeto$: Observable<Partial<ProjetoStateDto>>;
  public tabelaPreco: TabelaPrecoDetalhesDto[];
  public cadastroPagamentoSelecionado: CadastroSelecionarPagamentosDto;

  constructor(
    private readonly cadastrosQuery: CadastrosQuery,
    private readonly cadastrosService: CadastroService,
    private readonly imoveisService: ImoveisService,
    private readonly imoveisQuery: ImoveisQuery,
    private readonly loadingService: LoadingService,
    private readonly modalCtrl: ModalController,
    private readonly projetoQuery: ProjetoQuery,
    private readonly projetoService: ProjetoService,
    private readonly proprietariosQuery: ProprietariosQuery,
    private readonly proprietariosService: ProprietariosService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly toastService: ToastService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.route.params
      .pipe(
        tap(params => this.cadastroId = params.cadastroId),
        tap(() => {
          this.cadastro$ = this.cadastrosQuery.selectEntity(this.cadastroId)
            .pipe(
              tap(cadastro => this.configurarPagamentoSelecionado(cadastro)),
              tap(cadastro => {
                this.projeto$ = this.projetoQuery.selectEntity(cadastro.projetoFid);
                this.imovel$ = this.imoveisQuery.selectEntity(cadastro.propertyId);
                this.proprietario$ = this.proprietariosQuery.selectEntity(cadastro.proprietarioId);
              }),
            );
        }),
        tap(() => this.carregarCadastro()),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe();
  }

  /**
   * Configura o pagamento selecionado com base no cadastro.
   * @param cadastro Dados do cadastro.
   */
  private configurarPagamentoSelecionado(cadastro: CadastroStateDto) {
    this.cadastroPagamentoSelecionado = {
      averbacao: cadastro.averbacaoSelecionada,
      diaEntrada: cadastro.diaEntradaSelecionado,
      diaVencimento: cadastro.diaVencimentoSelecionado,
      meioPagamento: cadastro.meioPagamento,
      totalParcelas: +cadastro.totalParcelasSelecionada,
    }
  }

  /**
   * Carrega os dados completos do cadastro e tabela de preço do projeto.
   * @param event - Evento de loader do ion-refresh. Se não for informado é utilizado o loader normal.
   */
  public async carregarCadastro(event?: any): Promise<void> {
    if (!event) {
      await this.loadingService.createLoader();
    }

    this.cadastrosService.buscarCadastro(this.cadastroId)
      .pipe(
        switchMap(cadastro => this.carregarProjetoEFormaDePagamento(cadastro.projetoFid)),
        switchMap(() => this.imoveisService.buscarImovelPorCadastroId(this.cadastroId)),
        switchMap(() => this.proprietariosService.buscarPorCadastroId(this.cadastroId)),
        catchError(() => this.toastService.error('Erro ao buscar dados do contrato. Por favor tente novamente.')),
        finalize(async () => event ? event.target.complete() : await this.loadingService.dismiss()),
      )
      .subscribe();
  }

  private carregarProjetoEFormaDePagamento(projetoId: string) {
    return this.projetoService.buscarDadosSimplificados(projetoId)
      .pipe(
        switchMap(projeto => {
          if (projeto.semPagamento) {
            return of(null);
          }

          return this.buscarFormasDePagamentoDoCadastro();
        }),
      );
  }

  public buscarFormasDePagamentoDoCadastro() {
    return this.cadastrosService.buscarFormasDePagamentoDisponiveisParaOCadastro(this.cadastroId)
      .pipe(
        tap(tabelaPreco => this.tabelaPreco = tabelaPreco),
      );
  }

  /**
   * Busca a prévia do contrato com os dados de pagamento selecionados.
   */
  public async visualizarPreviaContrato(): Promise<void> {
    await this.loadingService.createLoader();

    this.cadastrosService.buscarPreviaContrato(this.cadastroId, this.cadastroPagamentoSelecionado)
      .pipe(
        tap(async dadosContrato => {
          const modal = await this.modalCtrl.create({
            component: ModalContratoProcuracaoComponent,
            cssClass: 'contrato-modal',
            componentProps: {
              dadosContrato
            },
          });
          await modal.present();
        }),
        finalize(() => this.loadingService.dismiss())
      )
      .subscribe();
  }

  public async solicitarAssinaturaContrato(observacoes?: string): Promise<void> {
    await this.loadingService.createLoader();

    this.cadastrosService.solicitarAssinaturaContrato(
      this.cadastroId,
      {
        ...this.cadastroPagamentoSelecionado,
        observacoes,
      }
    ).pipe(
      tap(() => this.router.navigate(['../selecionar-tipo-assinatura'], { relativeTo: this.route })),
      catchError(async error => {
        await this.toastService.error('Erro ao fechar contrato. Por favor tente novamente mais tarde.').toPromise();
        throw error;
      }),
      finalize(() => this.loadingService.dismiss())
    )
      .subscribe();
  }
}
