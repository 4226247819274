import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'usucampeao-cadastro-completo',
  templateUrl: './cadastro-completo.component.html',
  styleUrls: ['./cadastro-completo.component.scss']
})
export class CadastroCompletoComponent {

  constructor(public router: Router) { }

  public irParaHome(): void {
    this.router.navigate([`/home`]);
  }
}
