import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Bugsnag from '@bugsnag/js';
import { AlertController } from '@ionic/angular';
import { LoadingService, SmsLoginService, ToastService } from '@usucampeao/ui-mobile';
import { FirebaseErrorAuth } from '@usucampeao/utils';
import { isAfter } from 'date-fns';
import * as firebase from 'firebase/app';
import { catchError, finalize, tap } from 'rxjs/operators';
import { AuthService } from '../../state/auth.service';
import { WindowsService } from '../../state/windows.service';

@Component({
  selector: 'usucampeao-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public windowRef: any;
  public showCodePage = false;
  public phone: string;

  constructor(
    private alertCtrl: AlertController,
    private authService: AuthService,
    private loadingService: LoadingService,
    private router: Router,
    private smsLoginService: SmsLoginService,
    private toastService: ToastService,
    public windowService: WindowsService,
  ) { }

  ngOnInit() {
    firebase.auth().useDeviceLanguage();
    this.windowRef = this.windowService.win;
    this.recaptchaInit();
  }

  ionViewWillLeave(): void {
    this.showCodePage = false;
  }

  private async recaptchaInit(): Promise<void> {
    this.windowRef.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      'recaptcha-container',
      {
        'size': 'invisible',
      }
    );

    await this.windowRef.recaptchaVerifier.render();
  }

  public async setPhone(phone: string): Promise<void> {
    if (!phone) {
      return;
    }

    this.phone = '+55' + phone.replace(/\D/g, '');
    this.authService.atualizarEstado({ telefone: this.phone });

    const dataNovoCodigo = this.smsLoginService.getTelefoneDataNovoCodigo(this.phone);
    if (dataNovoCodigo) {
      const dataNovoCodigoExpirada = isAfter(new Date(), dataNovoCodigo);

      if (!dataNovoCodigoExpirada) {
        this.showCodePage = true;
        return;
      }
    }

    const code = this.windowRef.recaptchaVerifier;
    await this.loadingService.createLoader();

    try {
      const recaptchaResponse = await this.authService.login(this.phone, code);
      this.smsLoginService.setTelefoneDataNovoCodigo(this.phone);
      this.smsLoginService.setTelefoneConfirmationResult(this.phone, recaptchaResponse);
      this.windowService.setCode(code);
      this.showCodePage = true;
    } catch (error) {
      let message = 'Não foi possível fazer o login. Tente novamente mais tarde.';
      if (error.code === FirebaseErrorAuth.TOO_MANY_ATTEMPTS_TRY_LATER) {
        message = 'Dispositivo bloqueado por inúmeras tentativas incorretas. Tente novamente em algumas horas.';
      } else if (error.code === FirebaseErrorAuth.NETWORK_REQUEST_FAILED) {
        message = 'Erro de conexão. Verifique sua conexão de internet e tente novamente.';
      } else if (error.code === FirebaseErrorAuth.CAPTCHA_CHECK_FAILED) {
        message = 'Erro na validação do captcha. Atualize a página e tente novamente.';
      } else if (error.code === FirebaseErrorAuth.QUOTA_EXCEEDED) {
        message = 'Sistema sobrecarregado. Tente novamente mais tarde.';
      }

      await this.toastService.error(message).toPromise();
      Bugsnag.notify(error, (event) => {
        event.context = 'Inserção de telefone';
        event.addMetadata('metadata', {
          phone: this.phone,
          code
        });
      });

      throw error;
    } finally {
      await this.loadingService.dismiss();
    }
  }

  public async setCode(code: string): Promise<void> {
    this.loadingService.createLoader();
    const confirmationResult = this.smsLoginService.getTelefoneConfirmationResult(this.phone);
    confirmationResult.confirm(code)
      .then(() => {
        this.buscarUsuario();
      })
      .catch(async error => {
        await this.loadingService.dismiss();
        let message = 'Erro ao verificar telefone. Verifique o código e tente novamente.';
        if (error.code === FirebaseErrorAuth.INVALID_VERIFICATION_CODE) {
          message = 'Código inválido. Verifique o último código recebido e tente novamente.';
        } else if (error.code === FirebaseErrorAuth.TOO_MANY_ATTEMPTS_TRY_LATER) {
          message = 'Dispositivo bloqueado por inúmeras tentativas incorretas. Tente novamente em algumas horas.';
        } else if (error.code === FirebaseErrorAuth.NETWORK_REQUEST_FAILED) {
          message = 'Erro de conexão. Verifique sua conexão de internet e tente novamente.';
        } else if (error.code === FirebaseErrorAuth.CAPTCHA_CHECK_FAILED) {
          message = 'Erro na validação do captcha. Atualize a página e tente novamente.';
        } else if (error.code === FirebaseErrorAuth.QUOTA_EXCEEDED) {
          message = 'Sistema sobrecarregado. Tente novamente mais tarde.';
        }

        await this.toastService.error(message).toPromise();
        Bugsnag.notify(error, (event) => {
          event.context = 'Inserção do código';
          event.addMetadata('metadata', {
            phone: this.phone,
            code,
            errorCode: error.code,
          });
        });

        throw error;
      });
  }

  private buscarUsuario(): void {
    this.authService.buscarUsuario()
      .pipe(
        tap(usuario => {
          if (usuario) {
            this.router.navigate([''], { replaceUrl: true });
          } else {
            this.router.navigate(['/criar-conta']);
          }
        }),
        catchError(async error => {
          await this.toastService.error('Erro ao buscar dados do usuário. Por favor tente novamente.').toPromise();
          throw error;
        }),
        finalize(() => this.loadingService.dismiss()),
      )
      .subscribe();
  }

  public backPhonePage(): void {
    this.showCodePage = false;
  }

  public async resendCode(): Promise<void> {
    await this.loadingService.createLoader();
    const code = this.windowService.getCode();
    const phone = '+' + this.phone.replace(/\D/g, "");
    const recaptchaResponse = await this.authService.login(phone, code);

    this.smsLoginService.setTelefoneConfirmationResult(this.phone, recaptchaResponse);
    await this.loadingService.dismiss();
    const alert = await this.alertCtrl.create({
      header: 'Mensagem',
      message: 'Enviamos um novo codigo.',
      buttons: ['OK'],
    });
    await alert.present();
  }
}
