import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { OwnerFillInStatusDto } from '@usucampeao/lib-reurb-simplificado';
import { ProprietarioStateDto } from '@usucampeao/ui-mobile';

export type ProprietarioState = EntityState<ProprietarioStateDto>

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'proprietarios',
  resettable: true,
})
export class ProprietariosStore extends EntityStore<ProprietarioState> {
  constructor() {
    super();
  }

  public buscarProprietario(id: string): ProprietarioStateDto {
    return this.getValue().entities[id];
  }

  public buscarStatusPreechimento(id: string): OwnerFillInStatusDto {
    const { fillInStatus } = this.buscarProprietario(id);
    return Object.assign({}, fillInStatus);
  }

  /**
   * Remove da store todos os proprietarios que não estão no cadastro.
   * @param cadastroId ID do cadastro
   * @param proprietariosDoCadastro Lista de proprietários que estão no cadastro
   */
  public removerProprietariosQueNaoEstaoNoCadastro(cadastroId: string, proprietariosDoCadastro: ProprietarioStateDto[]): void {
    const proprietariosDoCadastroSalvoNoEstado = this.buscarProprietariosDoCadastro(cadastroId);
    const proprietariosFaltantes = proprietariosDoCadastroSalvoNoEstado
      .filter(proprietario => !proprietariosDoCadastro.some(prop => prop.id === proprietario.id));
    if (proprietariosFaltantes.length) {
      proprietariosFaltantes.forEach(proprietario => this.remove(proprietario.id));
    }
  }

  private buscarProprietariosDoCadastro(cadastroId: string): ProprietarioStateDto[] {
    return Object.values(this.getValue().entities).filter(proprietario => proprietario.cadastroId === cadastroId);
  }

}

