<ion-content class="ion-padding">
  <ion-refresher slot="fixed" (ionRefresh)="carregarImovel($event)">
    <ion-refresher-content>
    </ion-refresher-content>
  </ion-refresher>

  <usucampeao-imoveis-editar [imovel]="imovel$ | async" [alteracoes]="alteracoes$ | async" [documentos]="documentos"
    [formDesabilitado]="formDesabilitado$ | async" (aoAlterarEndereco)="alterarEndereco($event)"
    (aoAlterarInformacoesAdicionais)="alterarInformacoesAdicionais($event)">
  </usucampeao-imoveis-editar>
</ion-content>
