import { TimeStatus } from './time-status.enum';

export class TimeDto {
  id: number;
  nome: string;
  descricao: string;
  status: TimeStatus;
  usuarioResponsavelId: string | number;
  dataCriacao: Date;
  dataAtualizacao: Date;
}
