import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { OutrosDocumentosModule } from '@usucampeao/ui-mobile';
import { OutrosDocumentosRoutingModule } from './outros-documentos-routing.module';
import { OutrosDocumentosPage } from './outros-documentos.page';

@NgModule({
  declarations: [
    OutrosDocumentosPage
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,

    // route
    OutrosDocumentosRoutingModule,

    // components
    OutrosDocumentosModule,
  ]
})
export class OutrosDocumentosPageModule { }
