import { Aplicacao } from '.';
import { IntegracaoStatus } from '../../integracao/integracao-status.enum';
import { PagamentoStatus } from '../../payment/pagamento-status.enum';
import { Bs2Cliente } from '../cliente';

export class Bs2IntegracaoDto {
  id: string;
  bs2Id: string;
  bs2Numero: number;
  pagamentoId: string;
  valor: number;
  cliente: Bs2Cliente;
  codigoDeBarras?: string;
  linhaDigitavel?: string;
  boletoPdf?: string;
  pagamentoStatus: PagamentoStatus;
  valorPago?: number;
  dataPagamento?: Date;
  dataVencimento: Date;
  dataEmissao: Date;
  parcela: number;
  totalParcelas: number;
  contratoId: string;
  dataCriacao?: Date;
  dataAtualizacao?: Date;
  status: IntegracaoStatus;
  statusBs2: IntegracaoStatus;
  dataCancelamentoEfetuado?: Date;
  justificativaCancelamento?: string;
  aplicacao: Aplicacao;
}
