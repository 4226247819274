import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';

import { PipesModule } from '@usucampeao/utils-frontend';
import { AtendimentoStatusBadgeComponent } from './atendimento-status-badge.component';

@NgModule({
  declarations: [AtendimentoStatusBadgeComponent],
  imports: [
    // root
    CommonModule,
    IonicModule,

    // others
    PipesModule,
  ],
  exports: [AtendimentoStatusBadgeComponent],
})
export class AtendimentoStatusBadgeModule { }
