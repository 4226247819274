import { Expose, plainToInstance } from 'class-transformer';
import { TimeUsuarioStatus } from '..';

export class TimeUsuarioListarDto {
  @Expose()
  id: string;

  @Expose()
  nome: string;

  @Expose()
  email: string;

  @Expose()
  telefone: string;

  @Expose()
  status: TimeUsuarioStatus;

  @Expose({ name: 'usuario_criacao' })
  usuarioCriacao: string;

  @Expose({ name: 'data_criacao' })
  dataCriacao: Date;

  /**
  * Cria uma nova instância de TimeUsuarioListarDto a partir de dtos
  * @param props Interface de TimeUsuarioListar
  * @returns nova instância de TimeUsuarioListarDto
  */
  static from(props?: TimeUsuarioListar): TimeUsuarioListarDto {
    return plainToInstance(TimeUsuarioListarDto, props, { exposeUnsetFields: false, excludeExtraneousValues: true });
  }
}

export interface TimeUsuarioListar {
  id: string;
  nome: string;
  email: string;
  telefone: string;
  status: TimeUsuarioStatus;
  usuario_criacao: string;
  data_criacao: string;
}
