import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CadastroStatusModule } from '../cadastro-ficha-status/cadastro-status.module';
import { FooterModule } from '../footer/footer.module';
import { HeaderModule } from '../header/header.module';
import { DocumentosListaComponent } from './documentos-lista.component';
import { ModalDocumentosInstrucoesUploadComponent } from './modal-documentos-instrucoes-upload/modal-documentos-instrucoes-upload.component';

@NgModule({
  declarations: [
    DocumentosListaComponent,
    ModalDocumentosInstrucoesUploadComponent,
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,

    // components
    CadastroStatusModule,
    HeaderModule,
    FooterModule,
  ],
  exports: [
    DocumentosListaComponent
  ]
})
export class DocumentosListaModule { }
