<section class="p-detalhes">
  <div class="ion-padding">
    <usucampeao-header [title]="textoParcela"></usucampeao-header>

    <ion-text>
      <p class="ion-margin-top font-text">Aqui estão os dados do boleto referente à {{ textoParcela }}.</p>
      <p class="font-text">
        Fique atento à data de vencimento, para evitar a incidência de juros e multa. Caso o boleto
        esteja vencido há mais de 30 dias, entre em contato e solicite um novo boleto.
      </p>
    </ion-text>
  </div>

  <div class="p-detalhes__card ion-margin-bottom">
    <div class="p-detalhes__flex" *ngIf="pagamentoEstaPago">
      <ion-icon name="checkmark-circle" color="success"></ion-icon>
      <ion-text>
        <p class="title ion-no-margin ion-margin-horizontal">PAGO</p>
      </ion-text>
    </div>
    <div class="p-detalhes__flex">
      <ion-icon name="calendar" color="medium"></ion-icon>
      <ion-text>
        <p class="title ion-no-margin ion-margin-horizontal">{{ pagamento?.dataVencimento | date: 'dd/MM/yyyy'}}</p>
      </ion-text>
    </div>
    <div class="p-detalhes__flex">
      <ion-icon name="cash" color="medium"></ion-icon>
      <ion-text>
        <p class="title ion-no-margin ion-margin-horizontal">{{ pagamento?.valorDevido| currency: 'BRL' }}</p>
      </ion-text>
    </div>
    <div class="p-detalhes__flex" *ngIf="pagamento?.linhaDigitavel">
      <ion-icon name="barcode-outline" color="medium"></ion-icon>
      <ion-text>
        <p class="title title--small ion-no-margin ion-margin-horizontal">{{ pagamento.linhaDigitavel }}</p>
      </ion-text>
      <ion-button fill="clear" size="small" class="ion-no-padding ion-margin-start" (click)="copiarCodigoBarras()">
        <ion-icon slot="icon-only" name="copy-outline"></ion-icon>
      </ion-button>
    </div>
  </div>

  <div class="ion-padding">
    <ion-button *ngIf="pagamento?.boletoPdf" mode="md" class="ion-margin-bottom" (click)="visualizarBoleto()">Visualizar boleto</ion-button>
    <ion-button mode="md" fill="outline" (click)="voltar()">Voltar</ion-button>
  </div>
</section>
