import { Pipe, PipeTransform } from '@angular/core';
import { TipoAlteracao } from '@usucampeao/lib-reurb-simplificado';

@Pipe({
  name: 'alteracaoTipo'
})
export class AlteracaoTipoPipe implements PipeTransform {
  transform(value: TipoAlteracao): string {

    switch (value) {
      case TipoAlteracao.IMOVEL_DADOS_ADICIONAIS:
        return 'Dados do imóvel';

      case TipoAlteracao.IMOVEL_DADOS_LOCALIZACAO:
        return 'Quadra e lote do imóvel';

      case TipoAlteracao.IMOVEL_DADOS_ENDERECO:
        return 'Endereço do imóvel';

      case TipoAlteracao.IMOVEL_FOTO_FACHADA:
        return 'Foto da fachada do imóvel';

      case TipoAlteracao.IMOVEL_DOCUMENTO_AQUISICAO:
        return 'Documento de aquisição do imóvel';

      case TipoAlteracao.IMOVEL_DOCUMENTO_POSSE:
        return 'Documento de posse do imóvel';

      case TipoAlteracao.PROPRIETARIO_DADOS_BASICOS:
        return 'Dados básicos do proprietário';

      case TipoAlteracao.PROPRIETARIO_DADOS_DOCUMENTOS:
        return 'Documentos do proprietário';

      case TipoAlteracao.PROPRIETARIO_DADOS_CONTATO:
        return 'Dados de contato do proprietário';

      case TipoAlteracao.PROPRIETARIO_DOCUMENTO_ESTADO_CIVIL:
        return 'Documento de estado civil do proprietário';

      case TipoAlteracao.PROPRIETARIO_DOCUMENTO_PESSOAL:
        return 'Documento pessoal do proprietário';

      case TipoAlteracao.CONTRATO:
        return 'Contrato';

      case TipoAlteracao.PROCURACAO:
        return 'Procuração';

      default:
        return value;
    }
  }
}
