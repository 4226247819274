import { Pipe, PipeTransform } from '@angular/core';
import { Gender } from '@usucampeao/lib-reurb-simplificado';

@Pipe({
  name: 'genero'
})
export class GeneroPipe implements PipeTransform {
  transform(value: Gender): string {
    switch (value) {
      case Gender.MASCULINE:
        return 'Masculino';

      case Gender.FEMININE:
        return 'Feminino';

      default:
        return 'Outro';
    }
  }

}
