import { Expose, plainToInstance } from 'class-transformer';
import { PermissaoGrupo } from './permissao-grupo.enum';
import { PermissaoSubgrupo } from './permissao-subgrupo.enum';
import { PermissaoDto } from './permissao.dto';
import { Permissoes } from './permissoes.enum';

export class PermissaoListarDto {
  @Expose()
  id: number;

  @Expose()
  nome: string;

  @Expose()
  descricao: string;

  @Expose()
  codigo: Permissoes;

  @Expose()
  grupo: PermissaoGrupo;

  @Expose()
  subgrupo: PermissaoSubgrupo;

  /**
  * Cria uma nova instância de PermissaoListarDto a partir de dtos
  * @param props DTO de Permissão
  * @returns nova instância de PermissaoListarDto
  */
  static from(props?: PermissaoDto): PermissaoListarDto {
    return plainToInstance(PermissaoListarDto, props, { exposeUnsetFields: false, excludeExtraneousValues: true });
  }
}
