import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { AtendimentoCardModule, HeaderModule, MenuItemV2Module } from '@usucampeao/ui-mobile';
import { SwiperModule } from 'swiper/angular';

import { PagamentoCardModule } from '../../components/pagamento-card/pagamento-card.module';
import { TutorialModule } from '../../shared/components/tutorial/tutorial.module';
import { MeusImoveisPageRoutingModule } from './meus-imoveis-routing.module';
import { MeusImoveisPage } from './meus-imoveis.page';

@NgModule({
  declarations: [MeusImoveisPage],
  imports: [
    // root
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,

    // route
    MeusImoveisPageRoutingModule,

    // components
    AtendimentoCardModule,
    HeaderModule,
    PagamentoCardModule,
    TutorialModule,
    MenuItemV2Module,

    // external libs
    SwiperModule,
  ],
})
export class MeusImoveisPageModule { }
