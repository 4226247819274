import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DocumentFileDto, DocumentFileSubtype, MaritalStatus, ProjetoSimplificadoDto } from '@usucampeao/lib-reurb-simplificado';
import { BaseComponent, LoadingService, ToastService } from '@usucampeao/ui-mobile';
import { Observable } from 'rxjs';
import { catchError, finalize, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ProprietariosQuery } from '../state/proprietarios.query';
import { ProprietariosService } from '../state/proprietarios.service';

@Component({
  templateUrl: './proprietarios-documentos-selecionar-tipo.page.html',
  styleUrls: ['./proprietarios-documentos-selecionar-tipo.page.scss']
})
export class ProprietariosDocumentosSelecionarTipoPage extends BaseComponent implements OnInit {
  private cadastroId: string;
  private proprietarioId: string;

  public documentos$: Observable<DocumentFileDto[]>;
  public projeto: ProjetoSimplificadoDto;
  public proprietarioEstadoCivil$: Observable<MaritalStatus>;
  public tipo: DocumentFileSubtype;

  constructor(
    private proprietariosService: ProprietariosService,
    private proprietariosQuery: ProprietariosQuery,
    private loadingService: LoadingService,
    private route: ActivatedRoute,
    private toastService: ToastService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.route.queryParams
      .pipe(
        tap(params => this.tipo = params.tipo),
        switchMap(() => this.route.params),
        tap(params => this.cadastroId = params.cadastroId),
        tap(params => this.proprietarioId = params.proprietarioId),
        tap(() => {
          this.proprietarioEstadoCivil$ = this.proprietariosQuery.buscarDadosBasicos(this.proprietarioId)
            .pipe(
              map(proprietario => proprietario.maritalStatus)
            );
        }),
        tap(() => this.carregarDocumentos()),
        tap(() => this.documentos$ = this.proprietariosQuery.buscarDocumentos(this.proprietarioId)),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe();
  }

  public async carregarDocumentos(event?: any): Promise<void> {
    if (!event) {
      await this.loadingService.createLoader();
    }

    this.proprietariosService.buscarDadosBasicos(this.cadastroId, this.proprietarioId)
      .pipe(
        switchMap(() => this.proprietariosService.buscarDocumentos(this.cadastroId, this.proprietarioId)),
        catchError(() => this.toastService.error('Erro ao carregar documentos do proprietário. Por favor tente novamente')),
        finalize(async () => event ? event.target.complete() : await this.loadingService.dismiss()),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe();
  }

}
