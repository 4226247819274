<section class="comentarios ion-padding">
  <usucampeao-header title="Comentários"></usucampeao-header>

  <form [formGroup]="form" (ngSubmit)="submit()">
    <ion-text>
      <p class="font-text">Utilize este campo para adicionar observações sobre o cadastro:</p>
    </ion-text>

    <usucampeao-textarea label="Comentário" controlName="comentario"></usucampeao-textarea>

    <ion-button id="usu-observacoes" mode="md" expand="block" (click)="submit()">
      Adicionar
    </ion-button>
  </form>

  <div class="comentarios__lista">
    <div class="comentarios__item" *ngFor="let comentario of comentarios">
      <div class="comentarios__avatar">
        <usucampeao-usuario-avatar [src]="comentario.usuarioFoto"></usucampeao-usuario-avatar>
      </div>
      <div class="comentarios__item__content">
        <div class="comentarios__item__header">
          <ion-text>
            <h2 class="font-text font-text-bold ion-no-margin">
              {{comentario.usuarioNome}}
            </h2>
          </ion-text>
          <ion-text>
            <p class="description-text ion-no-margin">
              {{comentario.dataCriacao | date: 'dd/MM/yyyy HH:mm'}}
            </p>
          </ion-text>
        </div>

        <ion-text>
          <p class="font-text font-text--medium ion-no-margin">
            {{ comentario.mensagem }}
          </p>
        </ion-text>
      </div>
    </div>
  </div>
</section>
