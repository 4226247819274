/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CadastroContratoAssinaturaTipoEnvioLink } from '@usucampeao/interfaces';
import { AlteracaoDto, PROJETO_STATUS_EM_ANDAMENTO, ProjetoSimplificadoDto, ProjetoStatus, RegistrationStatus } from '@usucampeao/lib-reurb-simplificado';
import { catchError, finalize, tap } from 'rxjs/operators';
import { CadastroFichaDto } from '../../domain';
import { ContratoService } from '../../services/contrato.service';
import { cadastroIconeCor, cadastroIconeNome, LoadingService, ToastService } from '../../shared';
import { ModalSelecaoTipoEnvioLinkComponent, ModalSelecaoTipoEnvioLinkOutput } from '../modal-selecao-tipo-envio-link/modal-selecao-tipo-envio-link.component';

@Component({
  selector: 'usucampeao-cadastro-status',
  templateUrl: './cadastro-status.component.html',
  styleUrls: ['./cadastro-status.component.scss']
})
export class CadastroStatusComponent implements OnInit, OnChanges {
  @Input() cadastro?: CadastroFichaDto;
  @Input() projeto?: ProjetoSimplificadoDto;
  @Input() status: RegistrationStatus;
  @Input() revisaoComentario: string;
  @Input() alteracoes: AlteracaoDto[] = [];
  @Input() statusSimplificado = false;

  @Output() aoAlterarContato = new EventEmitter<void>();

  public mostrarReenvioLinkContrato = false;
  public icone: string;
  public iconeCor: string;

  constructor(
    private readonly contratoService: ContratoService,
    private readonly loadingService: LoadingService,
    private readonly modalCtrl: ModalController,
    private readonly toastService: ToastService,
  ) { }

  ngOnInit(): void {
    this.inicializarIcone();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { cadastro, status, projeto } = changes;
    if (cadastro || status || projeto) {
      this.inicializarIcone();
    }
  }

  private get cadastroStatus(): RegistrationStatus {
    return this.cadastro?.status || this.status;
  }

  public get alteracoesComDescricao(): AlteracaoDto[] {
    return this.alteracoes.filter(alteracao => !!alteracao.descricao);
  }

  private inicializarIcone(): void {
    if (
      PROJETO_STATUS_EM_ANDAMENTO.includes(this.projeto?.status) &&
      this.cadastroStatus === RegistrationStatus.VALIDATED
    ) {
      this.configurarIconeDeAcordoComOProjeto();
      return;
    }

    this.icone = cadastroIconeNome(
      this.cadastroStatus,
      !!this.cadastro?.contratoLinkContatoEnviado,
      this.cadastro?.contratoLinkAssinadoPelaUsucampeao || this.cadastro?.contratoLinkAssinadoPeloProprietario,
    );

    this.iconeCor = cadastroIconeCor(this.cadastroStatus, !!this.cadastro?.contratoLinkContatoEnviado);
  }

  private configurarIconeDeAcordoComOProjeto(): void {
    this.iconeCor = 'medium';

    switch (this.projeto?.status) {
      case ProjetoStatus.APROVADO_PELA_PREFEITURA:
        this.icone = 'newspaper';
        this.iconeCor = 'medium';
        break;

      case ProjetoStatus.REGISTRADO_EM_CARTORIO:
      case ProjetoStatus.ATENDENDO_DEVOLUTIVAS_DO_CARTORIO:
        this.icone = 'folder';
        this.iconeCor = 'medium';
        break;

      case ProjetoStatus.TITULO_EMITIDO:
      case ProjetoStatus.TITULO_ENTREGUE:
        this.icone = 'calendar';
        this.iconeCor = 'success';
        break;

      default:
        this.icone = 'information-circle';
        this.iconeCor = 'medium';
        break;
    }
  }

  public async mostrarModalSelecaoTipoEnvioLink(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalSelecaoTipoEnvioLinkComponent,
      componentProps: {
        cadastroId: this.cadastro.id,
        proprietarioId: this.cadastro.proprietarioId,
        proprietarioEmail: this.cadastro?.proprietarioEmail,
        proprietarioWhatsapp: this.cadastro?.proprietarioWhatsapp,
      },
      cssClass: 'modal-selecionar-tipo-envio-link'
    });

    await modal.present();
    const { data } = await modal.onDidDismiss<ModalSelecaoTipoEnvioLinkOutput>();
    if (data.realizouAlteracaoContato) {
      this.aoAlterarContato.emit();
    }

    if (data.confirmarSelecaoTipo) {
      return this.reenviarLinkAssinatura(data.tipoEnvioLink);
    }
  }

  private async reenviarLinkAssinatura(contratoLinkTipoEnvio: CadastroContratoAssinaturaTipoEnvioLink): Promise<void> {
    await this.loadingService.createLoader();
    this.contratoService.reenviarLinkParaAssinaturaDeContrato(this.cadastro.id, contratoLinkTipoEnvio)
      .pipe(
        tap(() => this.toastService.success('Link reenviado com sucesso').toPromise()),
        tap(() => this.mostrarReenvioLinkContrato = false),
        catchError(error => {
          this.toastService.error('Erro ao reenviar link para assinatura de contrato').toPromise();
          throw error;
        }),
        finalize(() => this.loadingService.dismiss()),
      )
      .subscribe();
  }
}
