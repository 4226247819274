import { Pipe, PipeTransform } from '@angular/core';
import { CadastroDocumentoTipo, OwnerDocument, PropertyAcquisitionDocument, PropertyOwnershipDocument } from '@usucampeao/lib-reurb-simplificado';

@Pipe({
  name: 'documentoTipo'
})
export class DocumentoTipoPipe implements PipeTransform {

  transform(value: string): string {
    switch (value) {
      case PropertyOwnershipDocument.HEALTH_CARE_CARD:
        return 'Carteira de posto de saúde';

      case PropertyOwnershipDocument.IPTU:
        return 'IPTU';

      case PropertyOwnershipDocument.SCHOOL_ENROLLMENT:
        return 'Matrícula escolar de familiares';

      case PropertyOwnershipDocument.WATER_OR_LIGHT_BILL:
        return 'Recibo ou conta de água ou luz';

      case PropertyOwnershipDocument.PROPERTY_REGISTRATION:
        return 'Matrícula do imóvel';

      case PropertyAcquisitionDocument.ASSIGNMENT_OF_RIGHTS_AGREEMENT:
        return 'Contrato de cessão de direitos';

      case PropertyAcquisitionDocument.DEED:
        return 'Escritura';

      case PropertyAcquisitionDocument.DONATION_CONTRACT:
        return 'Contrato de doação';

      case PropertyAcquisitionDocument.DONATION_DEED:
        return 'Escritura de doação';

      case PropertyAcquisitionDocument.FORMAL_SHARING:
        return 'Formal de partilha';

      case PropertyAcquisitionDocument.EXCHANGE_CONTRACT:
        return 'Contrato de permuta';

      case PropertyAcquisitionDocument.LAW_SUIT:
        return 'Ação judicial de usucapião';

      case PropertyAcquisitionDocument.LEASE_AGREEMENT:
        return 'Contrato de Comodato';

      case PropertyAcquisitionDocument.OTHERS:
        return 'Outros';

      case PropertyAcquisitionDocument.PUBLIC_CONCESSION:
        return 'Concessão do poder público';

      case PropertyAcquisitionDocument.PURCHASE_AND_SALE_AGREEMENT:
        return 'Contrato de compra e venda';

      case PropertyAcquisitionDocument.PURCHASE_RECEIPT:
        return 'Recibo de compra';

      case OwnerDocument.BIRTH_CERTIFICATE:
        return 'Certidão de nascimento';

      case OwnerDocument.DEATH_CERTIFICATE:
        return 'Certidão de óbito';

      case OwnerDocument.DIVORCE_CERTIFICATE:
        return 'Certidão de divórcio';

      case OwnerDocument.MARRIAGE_CERTIFICATE:
        return 'Certidão de casamento';

      case CadastroDocumentoTipo.PROCURACAO:
        return 'Procuração';

      case CadastroDocumentoTipo.CONTRATO:
        return 'Contrato';

      default:
        return value;
    }
  }

}
