import { IsNotEmpty, IsString } from 'class-validator';

export class TimeEditarDto {
  @IsString()
  @IsNotEmpty()
  nome: string;

  @IsString()
  @IsNotEmpty()
  descricao: string;

  @IsString()
  @IsNotEmpty()
  usuarioResponsavelId: string | number;
}
