import { OwnerFillInStatusDto } from '../owner/owner-fill-in-status.dto';
import { ProprietariosCadastroDto } from '../proprietario-cadastro';

export class PendingOwnerData {
  id: string;
  fillInStatus: OwnerFillInStatusDto;
  dadosFecharContrato: boolean;

  constructor(proprietario: ProprietariosCadastroDto) {
    this.id = proprietario.id;
    this.fillInStatus = proprietario.fillInStatus;
    this.dadosFecharContrato = this.calcularDadosFecharContrato(proprietario);
  }

  private calcularDadosFecharContrato(proprietario: ProprietariosCadastroDto): boolean {
    return !!proprietario &&
      !!proprietario.name &&
      !!proprietario.cpf &&
      !!proprietario.birthDate &&
      !!proprietario.rendaFamiliar &&
      !!proprietario.maritalStatus &&
      !!proprietario.phones?.cellphone;
  }

}
