import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { FooterModule, HeaderModule, UsuarioAvatarModule } from '@usucampeao/ui-mobile';
import { UsuarioFormModule } from '../../shared/components/usuario-form/usuario-form.module';
import { PerfilRoutingModule } from './perfil-routing.module';
import { PerfilComponent } from './perfil.component';


@NgModule({
  declarations: [
    PerfilComponent
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,

    // route
    PerfilRoutingModule,

    // components
    FooterModule,
    HeaderModule,
    UsuarioAvatarModule,
    UsuarioFormModule,
  ]
})
export class PerfilModule { }
