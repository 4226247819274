import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { FooterModule, HeaderModule, InputModule, LocalizacaoContainerModule, MapaModule } from '../../../components';
import { LoteSelecionarTutorialComponent } from './lote-selecionar-tutorial/lote-selecionar-tutorial.component';
import { LoteSelecionarPage } from './lote-selecionar.page';

@NgModule({
  declarations: [
    LoteSelecionarPage,
    LoteSelecionarTutorialComponent,
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,
    ReactiveFormsModule,

    // components
    HeaderModule,
    InputModule,
    FooterModule,
    LocalizacaoContainerModule,
    MapaModule,
  ],
  exports: [
    LoteSelecionarPage,
  ]
})
export class LoteSelecionarPageModule { }
