import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AlteracaoDto, LoteListarMapaDto, MapaDto, RegistrationStatus } from '@usucampeao/lib-reurb-simplificado';

@Component({
  selector: 'usucampeao-projetos-mapa',
  templateUrl: 'projetos-mapa.page.html',
  styleUrls: ['projetos-mapa.page.scss'],
})

export class ProjetosMapaPage {
  @Input() alteracoes: AlteracaoDto[];
  @Input() lotes: LoteListarMapaDto[] = [];
  @Input() loteSelecionadoId: number;
  @Input() mapa: MapaDto;
  @Input() title: string = 'Lote do imóvel';
  @Input() desabilitado = false;
  @Output() aoSelecionarLote = new EventEmitter<LoteListarMapaDto>();

  public get cadastroStatus(): RegistrationStatus {
    return RegistrationStatus.CHANGES_REQUESTED;
  }

  public get mostrarAlteracoes(): boolean {
    return this.alteracoes?.length > 0;
  }

  public async confirmarSelecaoLote(loteSelecionado: LoteListarMapaDto): Promise<void> {
    this.aoSelecionarLote.emit(loteSelecionado);
  }
}
