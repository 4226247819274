import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { CadastroContratoTipoAssinatura } from '@usucampeao/interfaces';
import { LoadingService, ModalContratoProcuracaoComponent } from '@usucampeao/ui-mobile';
import { finalize, take, tap } from 'rxjs/operators';
import { CadastroService } from '../../../state/cadastros.service';

@Component({
  selector: 'usucampeao-contrato-assinatura-tipo-fisico',
  templateUrl: 'contrato-assinatura-tipo-fisico.component.html',
  styleUrls: ['contrato-assinatura-tipo-fisico.component.scss'],
})
export class ContratoAssinaturaTipoFisicoComponent implements OnInit {
  @Input() cadastroId: string;

  constructor(
    private cadastroService: CadastroService,
    private modalCtrl: ModalController,
    private loadingService: LoadingService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.route.params
      .pipe(
        tap(params => this.cadastroId = params.cadastroId),
        take(1),
      )
      .subscribe();
  }

  public get cadastroContratoTipoAssinatura(): typeof CadastroContratoTipoAssinatura {
    return CadastroContratoTipoAssinatura;
  }

  public goTo(route: string): void {
    this.router.navigate([route], { relativeTo: this.route })
  }

  public async abrirModalContrato(): Promise<void> {
    await this.loadingService.createLoader();

    this.cadastroService.buscarContrato(this.cadastroId)
      .pipe(
        tap(async dadosContrato => {
          const modal = await this.modalCtrl.create({
            component: ModalContratoProcuracaoComponent,
            cssClass: 'contrato-modal',
            componentProps: {
              dadosContrato
            },
          });
          await modal.present();
        }),
        finalize(() => this.loadingService.dismiss())
      )
      .subscribe();
  }
}
