import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import Bugsnag from '@bugsnag/browser';
import { persistState } from '@datorama/akita';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

const storage = persistState({ key: environment.persistName });

const providers = [{ provide: 'persistStorage', useValue: storage }];
if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic(providers)
  .bootstrapModule(AppModule)
  .catch((err) => {
    Bugsnag.notify(err);
  });
defineCustomElements(window);
