import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'usucampeao-login-page',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss']
})
export class LoginPage implements OnInit {
  @Input() telefone: string;
  @Input() description: string;
  @Input() showCodePage = false;

  @Output() onSetPhone = new EventEmitter<string>();
  @Output() onSetCode = new EventEmitter<string>();
  @Output() onResendCode = new EventEmitter<void>();
  @Output() onBackPhonePage = new EventEmitter<void>();

  public form: FormGroup;
  public imagensCarrossel = [
    `assets/images/login/image_01.jpg`,
    `assets/images/login/image_02.jpg`,
    `assets/images/login/image_03.jpg`,
    `assets/images/login/image_04.jpg`,
    `assets/images/login/image_05.jpg`,
    `assets/images/login/image_06.jpg`,
    `assets/images/login/image_07.jpg`,
    `assets/images/login/image_08.jpg`,
    `assets/images/login/image_09.jpg`,
    `assets/images/login/image_10.jpg`,
  ];
  public slideOpts = {
    initialSlide: 0,
    slidesPerView: 1,
    autoplay: true,
    loop: true,
  };

  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      phone: [null, Validators.compose([Validators.required, Validators.minLength(13)])]
    });
  }

  public get phoneControl(): AbstractControl {
    return this.form.get('phone') as AbstractControl;
  }

  public get messageError(): string {
    if (!this.phoneControl.touched) {
      return '';
    }

    if (this.phoneControl.hasError('required')) {
      return 'Telefone obrigatório';
    }

    if (this.phoneControl.hasError('minlength')) {
      return 'Telefone inválido';
    }

    return '';
  }

  public setCode(code: string): void {
    this.onSetCode.emit(code);
  }

  public resendCode(): void {
    this.onResendCode.emit();
  }

  public backLoginPage(): void {
    this.onBackPhonePage.emit();
    this.form.reset();
  }

  public submit(): void {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }

    this.telefone = this.phoneControl.value;
    this.onSetPhone.emit(this.telefone);
  }
}
