/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import {
  CadastroContratoAssinaturaTipoEnvioLink, CadastroContratoTipoAssinatura,
} from '@usucampeao/interfaces';
import {
  AddressDto,
  CadastroComentarioListaDto,
  CadastroStatusPreenchimento,
  CadastroStatusVendaType,
  ContratoVersao,
  CupomDescontoTipoDesconto,
  DiaPagamento,
  FileDto,
  MeioPagamento,
  PendingSections,
  RegistrationStatus,
  ReurbModalidade,
  RevisaoDto
} from '@usucampeao/lib-reurb-simplificado';
import { Expose, plainToInstance } from 'class-transformer';
import { PagamentoStateDto } from './pagamento-state.interface';

export interface CadastroStateDto {
  id: string;
  status?: RegistrationStatus;
  statusVenda?: CadastroStatusVendaType;
  projetoFid?: string;
  projetoNome?: string;
  reurbModalidade?: ReurbModalidade;
  blockId?: number;
  lotId?: number;
  quadra?: string;
  address?: AddressDto;
  lote?: string;
  propertyId?: string;
  contractDate?: Date;
  cpfCliente?: string;
  contratoDataAssinatura?: Date;
  contratoTipoAssinatura?: CadastroContratoTipoAssinatura;
  fillInStatus?: PendingSections;
  schedulingDate?: Date;
  valorContrato?: number;
  totalParcelas?: number;
  valorParcela?: number;
  meioPagamento?: MeioPagamento;
  valorEntrada?: number;
  diaVencimento?: DiaPagamento;
  diaEntrada?: DiaPagamento;
  averbacao?: boolean;
  diarioOficialLink?: string;
  prefeituraProtocolo?: string;
  cartorioProtocolo?: string;
  entregaDataPrevista?: Date;
  entregaLocal?: string;
  entregaData?: Date;
  contratoVersao?: ContratoVersao;
  proprietarioNome?: string;
  proprietarioCpf?: string;
  files?: FileDto[];
  pagamentos?: PagamentoStateDto[];
  revisao?: RevisaoDto;
  observacao?: string;
  cupomDescontoCodigo?: string;
  cupomDescontoTipo?: CupomDescontoTipoDesconto;
  cupomDescontoLimite?: number;
  proprietarioEmail?: string;
  proprietarioWhatsapp?: string;
  contratoLinkDataUltimoEnvio?: Date;
  contratoLinkTipoEnvio?: CadastroContratoAssinaturaTipoEnvioLink;
  contratoLinkContatoEnviado?: string;
  contratoLinkFalhaEnvio?: boolean;
  contratoLinkAssinadoPeloProprietario?: boolean;
  contratoLinkAssinadoPelaUsucampeao?: boolean;
  proprietarioId?: string;
  diaEntradaSelecionado?: DiaPagamento;
  diaVencimentoSelecionado?: DiaPagamento;
  totalParcelasSelecionada?: number;
  valorEntradaSelecionado?: number;
  averbacaoSelecionada?: boolean;
  comentarios?: CadastroComentarioListaDto[];
}

export class CadastroFichaDto {
  @Expose()
  id: string;

  @Expose()
  proprietarioId: string;

  @Expose()
  propertyId: string;

  @Expose()
  files: FileDto[];

  @Expose()
  projetoFid: string;

  @Expose()
  projetoNome: string;

  @Expose()
  quadra: string;

  @Expose()
  lote: string;

  @Expose()
  address: AddressDto;

  @Expose()
  status: RegistrationStatus;

  @Expose()
  contractDate: Date;

  @Expose()
  fillInStatus: PendingSections;

  @Expose()
  valorContrato: number;

  @Expose()
  contratoDataAssinatura: Date;

  @Expose()
  contratoDataExpiracao: Date;

  @Expose()
  contratoTipoAssinatura?: CadastroContratoTipoAssinatura;

  @Expose()
  totalParcelas?: number;

  @Expose()
  valorParcela?: number;

  @Expose()
  valorEntrada?: number;

  @Expose()
  reurbModalidade?: ReurbModalidade;

  @Expose()
  averbacao?: boolean;

  @Expose()
  diaEntrada?: DiaPagamento;

  @Expose()
  diaVencimento?: DiaPagamento;

  @Expose()
  statusPreenchimento: CadastroStatusPreenchimento;

  @Expose()
  cupomDescontoCodigo?: string;

  @Expose()
  cupomDescontoTipo?: CupomDescontoTipoDesconto;

  @Expose()
  cupomDescontoLimite?: number;

  @Expose()
  contratoLinkDataUltimoEnvio?: Date;

  @Expose()
  contratoLinkTipoEnvio?: CadastroContratoAssinaturaTipoEnvioLink;

  @Expose()
  contratoLinkContatoEnviado?: string;

  @Expose()
  entregaDataPrevista?: Date;

  @Expose()
  entregaLocal?: string;

  @Expose()
  prefeituraProtocolo?: string;

  @Expose()
  entregaData?: Date;

  @Expose()
  cartorioProtocolo?: string;

  @Expose()
  proprietarioEmail?: string;

  @Expose()
  proprietarioWhatsapp?: string;

  @Expose()
  contratoLinkFalhaEnvio?: boolean;

  @Expose()
  contratoLinkAssinadoPeloProprietario?: boolean;

  @Expose()
  contratoLinkAssinadoPelaUsucampeao?: boolean;

  static from(props: Partial<CadastroStateDto>): CadastroFichaDto {
    return plainToInstance(CadastroFichaDto, props, { exposeUnsetFields: false, excludeExtraneousValues: true });
  }
}

export class CadastroCardDto {
  @Expose()
  id: string;

  @Expose()
  status: RegistrationStatus;

  @Expose()
  address: AddressDto;

  @Expose()
  proprietarioNome: string;

  @Expose()
  proprietarioCpf: string;

  @Expose()
  propertyId: string;

  @Expose()
  projetoNome?: string;

  @Expose()
  files?: FileDto[];

  @Expose()
  revisao?: RevisaoDto;

  static from(props: Partial<CadastroStateDto>): CadastroCardDto {
    return plainToInstance(CadastroCardDto, props, { exposeUnsetFields: false, excludeExtraneousValues: true });
  }
}

export class CadastroCardV2Dto {
  @Expose()
  id: string;

  @Expose()
  status: RegistrationStatus;

  @Expose()
  address: AddressDto;

  @Expose()
  projetoNome: string;

  @Expose()
  contractDate?: Date;

  @Expose()
  contratoDataExpiracao?: Date;

  @Expose()
  contratoDataAssinatura?: Date;

  @Expose()
  projetoFid: string;

  @Expose()
  proprietarioId?: string;

  @Expose()
  blockId?: number;

  @Expose()
  lotId?: number;

  @Expose()
  propertyId: string;

  @Expose()
  contratoTipoAssinatura?: CadastroContratoTipoAssinatura;

  static from(props: Partial<CadastroStateDto>): CadastroCardV2Dto {
    return plainToInstance(CadastroCardV2Dto, props, { exposeUnsetFields: false, excludeExtraneousValues: true });
  }
}
