import { Component, Input, OnInit } from '@angular/core';
import { ProprietarioFichaDto } from '../../../../domain';

@Component({
  selector: 'usucampeao-proprietarios-ficha',
  templateUrl: './proprietarios-ficha.component.html',
  styleUrls: ['./proprietarios-ficha.component.scss']
})
export class ProprietariosFichaComponent implements OnInit {
  @Input() proprietarios: ProprietarioFichaDto[] = [];

  constructor() { }

  ngOnInit(): void {
  }

  public get proprietarioPrincipal(): ProprietarioFichaDto {
    return this.proprietarios.find(proprietario => proprietario.main);
  }

  public get conjuge(): ProprietarioFichaDto {
    return this.proprietarios.find(proprietario => !proprietario.main);
  }
}
