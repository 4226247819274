import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { PipesModule } from '@usucampeao/utils-frontend';
import { InputModule } from '../input/input.module';
import { SelectModule } from '../select/select.module';
import { ImovelInfosAdicionaisFormV2Component } from './imovel-infos-adicionais-form-v2.component';

@NgModule({
  declarations: [
    ImovelInfosAdicionaisFormV2Component
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,
    ReactiveFormsModule,

    // components
    InputModule,
    SelectModule,

    // others
    PipesModule,
  ],
  exports: [
    ImovelInfosAdicionaisFormV2Component
  ]
})
export class ImovelInfosAdicionaisFormV2Module { }
