import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { formatISO } from 'date-fns';

@Component({
  selector: 'usucampeao-datepicker-modal',
  templateUrl: './datepicker-modal.component.html',
  styleUrls: ['./datepicker-modal.component.scss'],
})
export class DatepickerModalComponent {
  @Input() label: string;
  @Input() value: string;
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() showTime = false;

  constructor(private modalController: ModalController) { }

  public get max(): string {
    return this.maxDate ? formatISO(this.maxDate) : undefined;
  }

  public get min(): string {
    return this.minDate ? formatISO(this.minDate) : undefined;
  }

  public async close(): Promise<void> {
    await this.modalController.dismiss(this.value || new Date());
  }

}
