import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { FooterModule, HeaderModule, NotificacaoIconeModule, NotificacoesDetalhesModule } from '@usucampeao/ui-mobile';
import { NotificationPageRoutingModule } from './notification-routing.module';
import { NotificationPage } from './notification.page';
import { NotificacoesDetalhesPage } from './pages/notificacoes-detalhes/notificacoes-detalhes.page';


@NgModule({
  declarations: [
    NotificationPage,
    NotificacoesDetalhesPage,
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,

    // route
    NotificationPageRoutingModule,

    // components
    NotificacoesDetalhesModule,
    NotificacaoIconeModule,
    HeaderModule,
    FooterModule,
  ],
})
export class NotificationPageModule { }
