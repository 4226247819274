import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProjetoDetalhesDto } from '@usucampeao/lib-reurb-simplificado';
import { BaseComponent, LoadingService, ToastService } from '@usucampeao/ui-mobile';
import { Observable } from 'rxjs';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { ProjetoQuery } from '../../../core/states/projeto/projeto.query';
import { ProjetoService } from '../../../core/states/projeto/projeto.service';

@Component({
  templateUrl: 'projeto-detalhes.page.html',
  styleUrls: ['projeto-detalhes.page.scss'],
})
export class ProjetoDetalhesPage extends BaseComponent implements OnInit {
  public projetoId: string;
  public projeto$: Observable<ProjetoDetalhesDto>;

  constructor(
    private readonly loadingService: LoadingService,
    private readonly projetoService: ProjetoService,
    private readonly projetosQuery: ProjetoQuery,
    private readonly route: ActivatedRoute,
    private readonly toastService: ToastService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.route.params
      .pipe(
        tap(params => this.projetoId = params.id),
        tap(() => this.projeto$ = this.projetosQuery.selectEntity(this.projetoId) as Observable<ProjetoDetalhesDto>),
        tap(() => this.carregarProjeto()),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe();
  }

  private async carregarProjeto(): Promise<void> {
    await this.loadingService.createLoader();
    this.projetoService.buscarDetalheProjeto(this.projetoId)
      .pipe(
        finalize(async () => await this.loadingService.dismiss()),
        catchError(() => this.toastService.error('Erro ao buscar bairro. Por favor tente novamente.')),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe();
  }
}
