import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { HeaderModule, MenuItemV2Module } from '@usucampeao/ui-mobile';
import { SwiperModule } from 'swiper/angular';
import { CadastroCardAvisoModule } from '../../components/cadastro-card-aviso/cadastro-card-aviso.module';
import { CadastroCardModule } from '../../components/cadastro-card/cadastro-card.module';
import { PagamentoCardModule } from '../../components/pagamento-card/pagamento-card.module';
import { TutorialModule } from '../../shared/components/tutorial/tutorial.module';
import { HomePageRoutingModule } from './home-routing.module';
import { HomePage } from './home.page';

@NgModule({
  declarations: [HomePage],
  imports: [
    // root
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,

    // route
    HomePageRoutingModule,

    // components
    CadastroCardAvisoModule,
    CadastroCardModule,
    HeaderModule,
    PagamentoCardModule,
    TutorialModule,
    MenuItemV2Module,

    // external libs
    SwiperModule,
  ],
})
export class HomePageModule { }
