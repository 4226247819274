import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { FooterModule, HeaderModule, InputModule } from '../../../components';
import { EnderecoFormModule } from '../../../components/forms/endereco-form/endereco-form.module';
import { DadosImovelComponent } from './dados-imovel.component';

@NgModule({
  declarations: [
    DadosImovelComponent,
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,
    ReactiveFormsModule,

    // components
    EnderecoFormModule,
    FooterModule,
    InputModule,
    HeaderModule,
  ],
  exports: [
    DadosImovelComponent,
  ]
})
export class DadosImovelModule { }
