import { Component, Input } from '@angular/core';
import { CadastroContratoDto } from '@usucampeao/lib-reurb-simplificado';

@Component({
  selector: 'usucampeao-tabela-valores',
  templateUrl: './tabela-valores.component.html',
  styleUrls: ['./tabela-valores.component.scss']
})
export class TabelaValoresComponent {
  @Input() cadastro: CadastroContratoDto;

  public get pagamentoAVista(): boolean {
    return this.cadastro?.totalParcelas === 0;
  }
}
