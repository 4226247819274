<div class="cadastro-card ion-padding">
  <div class="cadastro-card__header">
    <ion-text>
      <h2 class="font-text font-text--medium font-text--bold ion-no-margin">
        {{ cadastro.contractDate ? 'Finalize a assinatura do contrato' : 'Criação de cadastro em andamento' }}
      </h2>
    </ion-text>

    <ion-icon [id]="cadastro.id" class="ion-margin-start cadastro-card__icone" slot="icon-only" name="ellipsis-vertical"></ion-icon>
    <ion-popover [trigger]="cadastro.id" triggerAction="click" size="auto" [dismissOnSelect]="true">
      <ng-template>
        <ion-content class="ion-padding">
          <ion-button fill="clear" color="medium" (click)="cancelarCadastro()">
            <ion-icon slot="start" name="trash-outline" color="danger"></ion-icon>
            Cancelar cadastro
          </ion-button>
        </ion-content>
      </ng-template>
    </ion-popover>
  </div>
  <div class="cadastro-card__content">
    <ion-text>
      <h3 class="title title--variant ion-no-margin"> {{ cadastro.projetoNome }} </h3>
      <p class="font-text ion-no-margin">{{ cadastro.address | address }}</p>
      <p class="description-text ion-no-margin">{{ cadastro.address | address: pipeEnderecoArgs  }}</p>
    </ion-text>

    <ion-button class="ion-margin-top" mode="md" (click)="continuarCadastro()">Continuar</ion-button>
  </div>
</div>
