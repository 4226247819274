import { SearchProps } from '@usucampeao/utils';


export enum CupomDescontoTipo {
  /**
   * Cupom geral, pode ser usado por todos
   */
  GERAL = 'GERAL',
  /**
   * Cupom de indicação
   */
  INDICACAO = 'INDICACAO',
  /**
   * Cupom que só pode ser usado por um morador específico
   */
  MORADOR = 'MORADOR',
  /**
   * Cupom que só pode ser usado por consultores
   */
  CONSULTOR = 'CONSULTOR'
}

export enum CupomDescontoTipoDesconto {
  /**
   * Desconto será aplicado somente no valor da entrada
   */
  ENTRADA = 'ENTRADA',
  /**
   * Desconto será aplicado no valor do serviço.
   * O valor da entrada não será alterado.
   */
  SERVICO = 'SERVICO',
}


export type CupomDesconto = {
  id: number | null;
  nome: string;
  codigo: string;
  tipo_desconto: CupomDescontoTipoDesconto;
  porcentagem_desconto: number;
  data_inicio: Date;
  data_fim: Date;
  quantidade: number;
  valor_maximo_unitario: number;
  valor_maximo_total: number;
  parcelas_quantidade_minima: number | null;
  parcelas_quantidade_maxima: number | null;
  habilitado_cliente: boolean;
  habilitado_consultor: boolean;
  tipo: CupomDescontoTipo;
  observacoes: string | null;
  observacoes_url: string | null;
  ativo: boolean;
  data_criacao: Date;
  usuario_criacao_id: number | null;
  data_atualizacao: Date;
  usuario_atualizacao_id: number | null;
}

export type CupomDescontoSelectDto = {
  id: number;
  nome: string;
}

export type CupomDescontoUtilizacaoDto = {
  valor_total_aplicado: number;
  quantidade_total_aplicada: number;
}

export type CupomDescontoUtilizacaoPorProjetoDto = {
  projeto_nome: string;
  quantidade_utilizada: number;
  valor_total_utilizado: number;
  valor_medio_utilizado: number;
}

export type CupomDescontoUtilizacaoCompletaDto = CupomDescontoUtilizacaoDto & {
  utilizacao_projetos: CupomDescontoUtilizacaoPorProjetoDto[];
}

export type CupomDescontoSearchParamsProps = SearchProps & {
  ativo?: boolean;
  tipo?: CupomDescontoTipo;
}
