/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { HeaderModule } from '../../../components';
import { ContratoSelecionarAverbacaoComponent } from './contrato-selecionar-averbacao.component';

@NgModule({
  declarations: [
    ContratoSelecionarAverbacaoComponent,
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,

    // components
    HeaderModule,
  ],
  exports: [
    ContratoSelecionarAverbacaoComponent,
  ],
})
export class ContratoSelecionarAverbacaoModule { }
