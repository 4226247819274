import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { PipesModule } from '@usucampeao/utils-frontend';
import { CadastroCardAvisoComponent } from './cadastro-card-aviso.component';

@NgModule({
  declarations: [CadastroCardAvisoComponent],
  imports: [
    // root
    CommonModule,
    IonicModule,

    // others
    PipesModule,
  ],
  exports: [CadastroCardAvisoComponent],
})
export class CadastroCardAvisoModule { }
