import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AlteracaoDto, GetUpdateOwnerDocumentsDto, RegistrationStatus } from '@usucampeao/lib-reurb-simplificado';

import { UF } from '@usucampeao/interfaces';
import { AbstractForm } from '@usucampeao/utils-frontend';
import { validateCPF } from '../../../../components';

@Component({
  selector: 'usucampeao-proprietarios-dados-documentos',
  templateUrl: './proprietarios-dados-documentos.component.html',
  styleUrls: ['./proprietarios-dados-documentos.component.scss']
})
export class ProprietariosDadosDocumentosComponent extends AbstractForm implements OnInit, OnChanges {
  @Input() dadosDocumentos: GetUpdateOwnerDocumentsDto;
  @Input() alteracoes: AlteracaoDto[];
  @Input() formDesabilitado = false;

  @Output() aoSubmeter = new EventEmitter<GetUpdateOwnerDocumentsDto>();

  public readonly estados = Object.values(UF);
  public readonly dataMaximaRg = new Date();

  constructor(private fb: FormBuilder) {
    super();
    this.form = this.fb.group({
      cpf: [null, [Validators.required, validateCPF]],
      rg: this.fb.group({
        number: [null, Validators.required],
        issuer: [null, Validators.required],
        state: [null, Validators.required],
        issueDate: [null, Validators.required]
      })
    });
  }

  ngOnInit(): void {
    this.preencherForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dadosDocumentos) {
      this.preencherForm();
    }
  }

  public get cadastroStatus(): RegistrationStatus {
    return RegistrationStatus.CHANGES_REQUESTED;
  }

  public get mostrarAlteracoes(): boolean {
    return this.alteracoes && this.alteracoes.length > 0;
  }

  private preencherForm(): void {
    const dadosDocumentos = Object.assign({}, this.dadosDocumentos);
    this.form.patchValue(dadosDocumentos);
  }

  public submit(): void {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }

    const dadosDocumentos: GetUpdateOwnerDocumentsDto = Object.assign({}, this.form.value);
    this.aoSubmeter.emit(dadosDocumentos);
  }

}
