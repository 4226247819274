import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { RegistrationStatus } from '@usucampeao/lib-reurb-simplificado';
import { CadastroCardV2Dto } from '@usucampeao/ui-mobile';
import { isBefore } from 'date-fns';

@Component({
  selector: 'usucampeao-cadastro-card-aviso',
  templateUrl: './cadastro-card-aviso.component.html',
  styleUrls: ['./cadastro-card-aviso.component.scss'],
})
export class CadastroCardAvisoComponent {
  @Input() set cadastro(cadastro: CadastroCardV2Dto) {
    this.cadastroInformado = cadastro;
    this.configurarPropriedades();
  }

  constructor(private readonly router: Router) { }

  private cadastroInformado: CadastroCardV2Dto;
  public titulo: string;
  public descricao: string;
  public cardVermelho = false;
  public textoBotao: string;

  public get contratoExpirado(): boolean {
    return !this.cadastroInformado.contratoDataAssinatura &&
      !!this.cadastroInformado.contratoDataExpiracao &&
      isBefore(new Date(this.cadastroInformado.contratoDataExpiracao), new Date());
  }

  private configurarPropriedades(): void {
    if (this.contratoExpirado) {
      this.titulo = 'Prazo de assinatura expirado';
      this.descricao = 'Atenção, o prazo para assinar o contrato expirou. Para continuar a contratação, clique no botão abaixo para gerar outro contrato.';
      this.cardVermelho = true;
      this.textoBotao = 'Gerar contrato novamente';
      return;
    }

    switch (this.cadastroInformado.status) {
      case RegistrationStatus.CONTRATO_FECHADO:
        this.titulo = 'Pendências no cadastro';
        this.descricao = 'Atenção, você possui um cadastro com pendências que precisam ser resolvidas para que o seu imóvel seja regularizado.';
        this.cardVermelho = false;
        this.textoBotao = 'Ver cadastro com pendências';
        break;

      case RegistrationStatus.READY_TO_SUBMIT:
        this.titulo = 'Envie seu cadastro para análise';
        this.descricao = 'Envie o seu cadastro para análise para que o nosso time verifique se todos os documentos estão em ordem para serem enviados à prefeitura.';
        this.cardVermelho = false;
        this.textoBotao = 'Ver cadastro';
        break;

      case RegistrationStatus.CHANGES_REQUESTED:
        this.titulo = 'Alterações necessárias';
        this.descricao = 'Identificamos algumas pendências no seu cadastro. Faça as correções nos dados e documentos solicitados, e envie o seu cadastro para análise novamente.';
        this.cardVermelho = true;
        this.textoBotao = 'Ver cadastro';
        break;
    }
  }

  public navegarParaFichaDoCadastro(): void {
    this.router.navigate(['cadastros', this.cadastroInformado.id]);
  }
}
