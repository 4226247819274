import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';

import { PipesModule } from '@usucampeao/utils-frontend';
import { PagamentoCardComponent } from './pagamento-card.component';

@NgModule({
  declarations: [PagamentoCardComponent],
  imports: [
    // root
    CommonModule,
    IonicModule,

    // others
    PipesModule,
  ],
  exports: [PagamentoCardComponent],
})
export class PagamentoCardModule { }
