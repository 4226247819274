import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { LazyLoadImageHooks } from '@usucampeao/utils-frontend';
import { LAZYLOAD_IMAGE_HOOKS, LazyLoadImageModule } from 'ng-lazyload-image';
import { UsuarioAvatarComponent } from './usuario-avatar.component';

@NgModule({
  declarations: [
    UsuarioAvatarComponent
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,

    // external libs
    LazyLoadImageModule,
  ],
  exports: [
    UsuarioAvatarComponent
  ],
  providers: [
    {
      provide: LAZYLOAD_IMAGE_HOOKS,
      useClass: LazyLoadImageHooks
    }
  ],
})
export class UsuarioAvatarModule { }
