import { Component, Input } from '@angular/core';
import { PagamentoListagemDto } from '@usucampeao/lib-reurb-simplificado';

@Component({
  selector: 'usucampeao-tabela-pagamentos',
  templateUrl: './tabela-pagamentos.component.html',
  styleUrls: ['./tabela-pagamentos.component.scss']
})
export class TabelaPagamentosComponent {
  @Input() pagamentos: PagamentoListagemDto[] = [];
  @Input() valorServico: number;
  @Input() valorDesconto: number;

  public get pagamentoAVista(): boolean {
    return this.pagamentos?.length === 1;
  }

  public get textoPrimeiraParcela(): string {
    if (this.pagamentoAVista) {
      return 'Parcela única';
    }

    return 'Entrada';
  }
}
