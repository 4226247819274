import { AddressDto, OwnerDto, PagamentoDto, PagamentoStatus } from '@usucampeao/lib-reurb-simplificado';
import { Expose, plainToInstance, Type } from 'class-transformer';
import { IsDate, IsNotEmpty, IsNumber, IsOptional, IsString, ValidateNested } from 'class-validator';

export class OmieClienteDto {
  @Expose()
  @IsString()
  @IsNotEmpty()
  id: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  name: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  cpf: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  phone: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  email: string;

  /**
   * Cria uma nova instância de OmieCliente
   * @param props DTO de proprietário
   * @returns nova instância de OmieCliente
   */
  static from(props?: Partial<OwnerDto>): OmieClienteDto {
    return plainToInstance(OmieClienteDto, props, { exposeUnsetFields: false, excludeExtraneousValues: true });
  }
}

export class OmiePagamentoDto {
  @Expose()
  @IsString()
  @IsNotEmpty()
  id: string;

  @Expose()
  @IsNumber()
  @IsNotEmpty()
  valorDevido: number;

  @Expose()
  @IsNumber()
  @IsOptional()
  valorPago?: number;

  @Expose()
  // @IsEnum(PagamentoStatus)
  @IsNotEmpty()
  status: PagamentoStatus;

  @Expose()
  @Type(() => Date)
  @IsDate()
  @IsOptional()
  dataPagamento?: Date;

  @Expose()
  @Type(() => Date)
  @IsDate()
  @IsNotEmpty()
  dataVencimento: Date;

  @Expose()
  @IsNumber()
  @IsNotEmpty()
  parcela: number;

  @Expose()
  @IsNumber()
  @IsNotEmpty()
  totalParcelas: number;

  @Expose()
  @IsString()
  @IsNotEmpty()
  cadastroId: string;

  /**
   * Cria uma nova instância de OmiePagamentoDto
   * @param props DTO de pagamento
   * @returns nova instância de OmiePagamentoDto
   */
  static from(props?: Partial<PagamentoDto>): OmiePagamentoDto {
    return plainToInstance(OmiePagamentoDto, props, { exposeUnsetFields: false, excludeExtraneousValues: true });
  }
}

export class OmieContratoCriarDto {
  @Type(() => OmieClienteDto)
  @ValidateNested()
  cliente: OmieClienteDto;

  @Type(() => AddressDto)
  @ValidateNested()
  @IsNotEmpty()
  endereco: AddressDto;

  constructor(props?: Partial<OmieContratoCriarDto>) {
    if (props) {
      Object.keys(props).forEach(key => this[key] = props[key]);
    }
  }
}
