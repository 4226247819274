import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';

import { ReactiveFormsModule } from '@angular/forms';
import { HeaderModule, InputModule, LoteSelecionarPageModule } from '@usucampeao/ui-mobile';
import { ProjetoMapaPageRoutingModule } from './projeto-mapa-routing.page.module';
import { ProjetoMapaPage } from './projeto-mapa.page';

@NgModule({
  declarations: [
    ProjetoMapaPage
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,
    ReactiveFormsModule,

    // route
    ProjetoMapaPageRoutingModule,

    // components
    HeaderModule,
    InputModule,
    LoteSelecionarPageModule,
  ]
})
export class ProjetoMapaPageModule { }
