import { Expose, plainToInstance } from 'class-transformer';
import { ProjetoModalidadeType } from './projeto-modalidade.enum';
import { ProjetoStatus } from './projeto-status.enum';
import { ProjetoTipo } from './projeto-tipo.enum';
import { ProjetoVendaStatus } from './projeto-venda-status.enum';

export class ProjetoNucleoListarDto {
  @Expose()
  id: string;

  @Expose()
  nome: string;

  @Expose({ name: 'lote_quantidade' })
  loteQuantidade: number;

  @Expose({ name: 'quadra_quantidade' })
  quadraQuantidade: number;

  @Expose()
  status: ProjetoStatus;

  @Expose({ name: 'venda_status' })
  vendaStatus: ProjetoVendaStatus;

  @Expose()
  tipo: ProjetoTipo;

  @Expose()
  averbacao?: boolean;

  @Expose()
  modalidade?: ProjetoModalidadeType;

  /**
   * Cria uma nova instância de ProjetoNucleoListarDto a partir da interface ProjetoNucleoListar
   * @param props Interface ProjetoNucleoListar
   * @returns nova instância de ProjetoNucleoListarDto
   */
  static from(props?: ProjetoNucleoListar): ProjetoNucleoListarDto {
    return plainToInstance(ProjetoNucleoListarDto, props, { exposeUnsetFields: false, excludeExtraneousValues: true });
  }
}


export interface ProjetoNucleoListar {
  id: string;
  nome: string;
  lote_quantidade: number;
  quadra_quantidade: number;
  status: ProjetoStatus;
  venda_status: ProjetoVendaStatus;
  tipo: ProjetoTipo;
  averbacao?: boolean;
  modalidade?: ProjetoModalidadeType;
}
