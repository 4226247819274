import { UF } from '@usucampeao/interfaces';
import { MapaDto } from '../mapa';

export class NucleoDetalhesDto {
  id: number;
  nome: string;
  coords: number[][];
  centro: number[];
  cidade_id: number;
  cidade: string;
  estado: UF;
  mapa: MapaDto;
}
