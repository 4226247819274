<ion-text>
  <p class="font-text">
    A documentação e os dados enviados foram aprovados pelo nosso time de controle de qualidade!
  </p>
  <p class="font-text">
    Acompanhe a evolução do projeto de regularização através do próprio app ou através dos nossos canais oficiais de comunicação.
  </p>
</ion-text>


