<ion-header class="ion-no-border header">
  <ion-toolbar mode="md" class="header__toolbar ion-no-padding">
    <ion-buttons mode="ios" slot="start" class="ion-no-margin" *ngIf="showBackButton">
      <ion-button class="ion-no-padding ion-no-margin" icon="arrow-back-outline" color="medium" (click)="voltar()">
        <ion-icon name="arrow-back"></ion-icon>
      </ion-button>
    </ion-buttons>

    <ion-title class="ion-no-border ion-no-padding ion-margin-start">
      {{ title }}
    </ion-title>

    <div class="header__extras" slot="end">
      <div class="header__notificacao-icone">
        <ion-icon name="notifications-outline" routerLink="/notificacoes"></ion-icon>
        <ion-badge color="danger" *ngIf="(quantidadeNotificacoesNaoLidas$ | async) > 0">
          {{ quantidadeNotificacoesNaoLidas$ | async }}
        </ion-badge>
      </div>
      <usucampeao-usuario-avatar size="small" [src]="foto$ | async" (click)="abrirMenu()"></usucampeao-usuario-avatar>
    </div>
  </ion-toolbar>
</ion-header>
