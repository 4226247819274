import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';

import { FooterModule } from '@usucampeao/ui-mobile';
import { ModalPoliticaPrivacidadeModule } from '../../shared/components/modal-politica-privacidade/modal-politica-privacidade.module';
import { MenuItemModule } from '../menu-item/menu-item.module';
import { ToolbarModule } from '../toolbar/toolbar.module';
import { MenuComponent } from './menu.component';

@NgModule({
  declarations: [MenuComponent],
  imports: [
    // root
    CommonModule,
    IonicModule,

    // components
    FooterModule,
    MenuItemModule,
    ModalPoliticaPrivacidadeModule,
    ToolbarModule,
  ],
  exports: [MenuComponent],
})
export class MenuModule { }
