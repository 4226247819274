import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgxMaskModule } from 'ngx-mask';
import { FooterModule, InputModule } from '../../components';
import { AutenticarNumeroComponent } from './autenticar-numero/autenticar-numero.component';
import { LoginPage } from './login.page';

@NgModule({
  declarations: [
    LoginPage,
    AutenticarNumeroComponent,
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,
    ReactiveFormsModule,

    // libs
    NgxMaskModule.forRoot(),

    // components
    FooterModule,
    InputModule,
  ],
  exports: [
    LoginPage,
  ]
})
export class LoginPageModule { }
