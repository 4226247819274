export enum PermissaoGrupo {
  CADASTROS = 'CADASTROS',
  CUPOM_DESCONTO = 'CUPONS_DESCONTO',
  DOCUMENTOS = 'DOCUMENTOS',
  LOCAIS = 'LOCAIS',
  PROJETOS = 'PROJETOS',
  DASHBOARDS = 'DASHBOARDS',
  PAGAMENTOS = 'PAGAMENTOS',
  USUARIOS = 'USUARIOS',
  TIMES = 'TIMES',
  POLITICAS = 'POLITICAS',
  VENDAS = 'VENDAS',
}
