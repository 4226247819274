import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { ComentariosModule } from '@usucampeao/ui-mobile';
import { ComentariosRoutingModule } from './comentarios-routing.module';
import { ComentariosPage } from './comentarios.page';


@NgModule({
  declarations: [
    ComentariosPage
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,

    // route
    ComentariosRoutingModule,

    // components
    ComentariosModule,
  ]
})
export class ComentariosPageModule { }
