import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewWillEnter } from '@ionic/angular';
import { UF } from '@usucampeao/interfaces';
import { CadastroCriarDto, ImovelEnderecoInformacoesAdicionaisDto } from '@usucampeao/lib-reurb-simplificado';
import { BaseComponent, LoadingService, LocalizacaoService, ToastService } from '@usucampeao/ui-mobile';
import { Observable, of } from 'rxjs';
import { catchError, finalize, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ProjetoStateDto } from '../../../core/states/projeto/projeto.store';
import { ImoveisService } from '../../cadastros/imoveis/state/imoveis.service';
import { CadastroService } from '../../cadastros/state/cadastros.service';
import { CadastroRascunho } from '../../cadastros/state/cadastros.store';

@Component({
  templateUrl: './endereco-infos-adicionais.page.html',
  styleUrls: ['./endereco-infos-adicionais.page.scss']
})
export class EnderecoInfosAdicionaisPage extends BaseComponent implements OnInit, ViewWillEnter {
  public imovelId: string;
  public projeto$: Observable<Partial<ProjetoStateDto>>;
  private cadastroId: string;
  public dadosImovel: ImovelEnderecoInformacoesAdicionaisDto;

  constructor(
    private cadastroService: CadastroService,
    private imoveisService: ImoveisService,
    private loadingService: LoadingService,
    private localizacaoService: LocalizacaoService,
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
  ) {
    super();
  }

  ngOnInit(): void {
    const localizacaoSalva = this.localizacaoService.buscarLocalizacaoSelecionadaPeloUsuario();
    const cadastroEmRascunho = this.cadastroService.buscarCadastroEmRascunho();
    if (localizacaoSalva) {
      this.dadosImovel = {
        address: {
          zipCode: localizacaoSalva.cep,
          street: localizacaoSalva.logradouro,
          number: localizacaoSalva.numero,
          neighborhood: localizacaoSalva.bairro,
          city: localizacaoSalva.cidade,
          state: localizacaoSalva.estado as UF,
        },
        possuiOutroImovel: cadastroEmRascunho.possuiOutroImovel,
        situation: cadastroEmRascunho.situation,
      };
    }
  }

  ionViewWillEnter(): void {
    this.route.params
      .pipe(
        tap(params => {
          const cadastroEmRascunho = this.cadastroService.buscarCadastroEmRascunho();
          this.cadastroId = cadastroEmRascunho?.id;
          if (this.cadastroId && !params.cadastroId) {
            this.router.navigate(['../cadastro', this.cadastroId, 'cadastrar-dados-imovel'], { relativeTo: this.route, replaceUrl: true });
          }
        }),
        switchMap(params => {
          if (params.cadastroId) {
            this.cadastroId = params.cadastroId;
            return this.imoveisService.buscarImovelPorCadastroId(params.cadastroId)
              .pipe(
                tap(imovel => this.dadosImovel = ImovelEnderecoInformacoesAdicionaisDto.from(imovel)),
              );
          }

          return of(null);
        }),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe();
  }

  public criarCadastroOuAtualizarDadosImovel(props: ImovelEnderecoInformacoesAdicionaisDto): Promise<void> {
    const cadastroEmRascunho = this.cadastroService.buscarCadastroEmRascunho();
    if (this.cadastroId) {
      this.atualizarDadosImovel(props, cadastroEmRascunho);
      return;
    }

    this.criarCadastro(props, cadastroEmRascunho);
  }

  public async atualizarDadosImovel(props: ImovelEnderecoInformacoesAdicionaisDto, cadastroEmRascunho: CadastroRascunho): Promise<void> {
    await this.loadingService.createLoader();
    const { imovelId } = cadastroEmRascunho;
    this.imoveisService.atualizarEnderecoEInfosAdicionais(this.cadastroId, imovelId, props)
      .pipe(
        tap(() => {
          this.localizacaoService.salvarLocalizacaoSelecionadaPeloUsuario({
            cep: props.address.zipCode,
            logradouro: props.address.street,
            numero: props.address.number,
            bairro: props.address.neighborhood,
            cidade: props.address.city,
            estado: props.address.state,
          });
          this.cadastroService.salvarRascunhoCadastro({
            situation: props.situation,
            possuiOutroImovel: props.possuiOutroImovel,
          });
        }),
        tap(() => this.router.navigate(['../cadastrar-dados-proprietario'], { relativeTo: this.route })),
        catchError(error => {
          this.toastService.error('Erro ao atualizar dados do imóvel. Por favor tente novamente mais tarde.').toPromise();
          throw error;
        }),
        finalize(async () => await this.loadingService.dismiss())
      )
      .subscribe();
  }

  public async criarCadastro(props: ImovelEnderecoInformacoesAdicionaisDto, cadastroEmRascunho: CadastroRascunho): Promise<void> {
    await this.loadingService.createLoader();
    const cadastroCriarUsuarioLogado: CadastroCriarDto = {
      blockId: cadastroEmRascunho.blockId,
      lotId: cadastroEmRascunho.lotId,
      projetoFid: cadastroEmRascunho.projetoId,
      ...props,
    };
    this.cadastroService.create(cadastroEmRascunho.projetoId, cadastroCriarUsuarioLogado)
      .pipe(
        tap(cadastroCriado => this.router.navigate(['../cadastro', cadastroCriado.id, 'cadastrar-dados-proprietario'], { relativeTo: this.route })),
        catchError(error => {
          this.toastService.error('Erro ao criar cadastro. Por favor tente novamente mais tarde.').toPromise();
          throw error;
        }),
        finalize(async () => await this.loadingService.dismiss())
      )
      .subscribe();
  }

}
