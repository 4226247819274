import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CadastroCardV2Dto, LoadingService, ToastService } from '@usucampeao/ui-mobile';
import { ArgsAddressPipe } from '@usucampeao/utils-frontend';
import { catchError, finalize } from 'rxjs/operators';
import { CadastroService } from '../../pages/cadastros/state/cadastros.service';

@Component({
  selector: 'usucampeao-cadastro-card',
  templateUrl: './cadastro-card.component.html',
  styleUrls: ['./cadastro-card.component.scss'],
})
export class CadastroCardComponent {
  @Input() cadastro: CadastroCardV2Dto;

  constructor(
    private readonly cadastroService: CadastroService,
    private readonly loadingService: LoadingService,
    private readonly router: Router,
    private readonly toastService: ToastService,
  ) { }

  public pipeEnderecoArgs = ArgsAddressPipe.NEIGHBORHOOD_CITY_STATE_ONLY;

  public continuarCadastro(): void {
    this.cadastroService.salvarRascunhoCadastro({
      id: this.cadastro.id,
      projetoId: this.cadastro.projetoFid,
      imovelId: this.cadastro.propertyId,
      proprietarioId: this.cadastro.proprietarioId,
      blockId: this.cadastro.blockId,
      lotId: this.cadastro.lotId,
    });

    const rota = ['novo-cadastro/bairro', this.cadastro.projetoFid, 'cadastro', this.cadastro.id];
    if (!this.cadastro.contractDate) {
      rota.push('cadastrar-dados-proprietario');
    } else if (!this.cadastro.contratoTipoAssinatura) {
      rota.push('contrato', 'selecionar-tipo-assinatura');
    } else {
      rota.push('contrato', 'assinar');
    }

    this.router.navigate(rota);
  }

  public async cancelarCadastro(): Promise<void> {
    await this.loadingService.createLoader();

    this.cadastroService.cancelarCadastro(this.cadastro.id)
      .pipe(
        catchError(error => {
          this.toastService.error('Ocorreu um erro ao cancelar o cadastro. Tente novamente mais tarde.').toPromise();
          throw error;
        }),
        finalize(() => this.loadingService.dismiss()),
      )
      .subscribe();
  }
}
