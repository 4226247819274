import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { PipesModule } from '@usucampeao/utils-frontend';
import { StatusPreenchimentoModule } from '../status-preenchimento/status-preenchimento.module';
import { MenuItemV2Component } from './menu-item-v2.component';

@NgModule({
  imports: [
    // root
    CommonModule,
    IonicModule,

    // components
    StatusPreenchimentoModule,

    // others
    PipesModule,
  ],
  declarations: [MenuItemV2Component],
  exports: [MenuItemV2Component],
})
export class MenuItemV2Module { }
