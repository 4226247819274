import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'usucampeao-modal-documentos-instrucoes-upload',
  templateUrl: './modal-documentos-instrucoes-upload.component.html',
  styleUrls: ['./modal-documentos-instrucoes-upload.component.scss']
})
export class ModalDocumentosInstrucoesUploadComponent {

  constructor(private modalCtrl: ModalController) { }

  public fechar(): void {
    this.modalCtrl.dismiss();
  }
}
