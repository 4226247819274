<ion-content class="ion-padding" [scrollY]="false">
  <ion-text class="ion-text-center">
    <h1 class="title">Confirmar o envio?</h1>
  </ion-text>

  <ion-text>
    <p class="font-text ion-margin-top">
      Ao clicar em <b>ENVIAR</b>, você está aceitando os termos do contrato apresentado e realizando a contratação deste
      serviço de regularização imobiliária. Seu cadastro será enviado para análise pela nossa equipe.
    </p>
    <p class="font-text">
      Enquanto o cadastro estiver em análise, não será possível alterá-lo.
    </p>
  </ion-text>

  <div class="m-confirmar-fechamento-contrato__actions">
    <ion-button class="ion-margin-end" mode="md" fill="clear" (click)="fecharModal()">Voltar</ion-button>
    <ion-button class="ion-no-padding" mode="md" fill="clear" (click)="fecharModal(true)">Confirmar</ion-button>
  </div>
</ion-content>
