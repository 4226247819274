<ng-container *ngIf="cadastro?.semPagamento; else contratoGratuito">
  <usucampeao-reurb-gratuito-v2 [cadastro]="cadastro" [proprietario]="proprietario" [conjuge]="conjuge"
    [imovel]="imovel" [isPrint]="isPrint">
  </usucampeao-reurb-gratuito-v2>
</ng-container>
<ng-template #contratoGratuito>
  <ng-container [ngSwitch]="tipo">
    <usu-procuracao-averbacao *ngSwitchCase="tiposContrato.PROCURACAO_AVERBACAO" [cadastro]="cadastro"
      [proprietario]="proprietario" [conjuge]="conjuge" [imovel]="imovel"
      [isPrint]="isPrint"></usu-procuracao-averbacao>

    <usu-procuracao-reurb-averbacao *ngSwitchCase="tiposContrato.PROCURACAO_REURB_AVERBACAO" [cadastro]="cadastro"
      [proprietario]="proprietario" [conjuge]="conjuge" [imovel]="imovel"
      [isPrint]="isPrint"></usu-procuracao-reurb-averbacao>

    <usucampeao-regularizacao-fundiaria-v2 *ngSwitchDefault [cadastro]="cadastro" [proprietario]="proprietario"
      [conjuge]="conjuge" [imovel]="imovel" [isPrint]="isPrint"></usucampeao-regularizacao-fundiaria-v2>
  </ng-container>
</ng-template>
