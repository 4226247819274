export type SortDirection = 'ASC' | 'DESC';

export interface SearchProps<Filter = string> {
  page?: number;
  per_page?: number;
  sort?: string;
  sort_dir?: SortDirection;
  filter?: Filter;
}

export class SearchParams<Filter = string> {
  protected _page: number;
  protected _per_page = 10;
  protected _sort: string | null;
  protected _sort_dir: SortDirection | null;
  protected _filter: Filter | null;

  constructor(props: SearchProps<Filter> = {}) {
    this.page = props.page;
    this.per_page = props.per_page;
    this.sort = props.sort;
    this.sort_dir = props.sort_dir;
    this.filter = props.filter;
  }

  public get page(): number {
    return this._page;
  }

  public set page(value: number) {
    let _page = +value;

    if (Number.isNaN(_page) || _page <= 0 || parseInt(_page.toString()) !== _page) {
      _page = 1;
    }

    this._page = _page;
  }

  public get per_page(): number {
    return this._per_page;
  }

  public set per_page(value: number) {
    let _per_page = value === true as any ? this._per_page : +value;

    const isInvalidValue = Number.isNaN(_per_page) || _per_page <= 0 || parseInt(_per_page.toString()) !== _per_page;
    if (isInvalidValue) {
      _per_page = this._per_page;
    }

    if (_per_page > 200) {
      _per_page = 200;
    }

    this._per_page = _per_page;
  }

  public get sort(): string {
    return this._sort;
  }

  public set sort(value: string | null) {
    const isEmptyValue = value === null || value === undefined || value === '';
    this._sort = isEmptyValue ? null : `${value}`;
  }

  public get sort_dir(): SortDirection {
    return this._sort_dir;
  }

  public set sort_dir(value: SortDirection | null) {
    if (!this.sort) {
      this._sort_dir = null;
      return;
    }

    const dir = `${value}`.toUpperCase();
    this._sort_dir = dir !== 'ASC' && dir !== 'DESC' ? 'ASC' : dir;
  }

  public get filter(): Filter | null {
    return this._filter;
  }

  public set filter(value: Filter | null) {
    const isEmptyValue = value === null || value === undefined || (value as unknown) === '';
    this._filter = isEmptyValue ? null : `${value}` as any;
  }
}
