import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { NotificacaoDto, NotificacaoSimplificadaDto } from '@usucampeao/lib-reurb-simplificado';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { NotificationsStore } from './notification.store';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  constructor(
    private http: HttpClient,
    private notificationStore: NotificationsStore,
  ) { }

  /**
   * Lista todas as notificações do usuário.
   *
   * @returns Uma promise que se resolve com a lista das notificações.
   */
  public listarNotificacaoesDoUsuario(): Observable<NotificacaoSimplificadaDto[]> {
    return this.http.get<NotificacaoSimplificadaDto[]>(`/users/me/notificacoes`)
      .pipe(
        tap((res: NotificacaoSimplificadaDto[]) => this.notificationStore.set(res)),
      );
  }

  /**
   * Busca uma notificação por ID.
   *
   * @param notificacaoId ID da notificação
   * @returns Uma promise que se resolve com os detalhes da notificação.
   */
  public buscarNotificacao(notificacaoId: string): Observable<NotificacaoDto> {
    return this.http.get<NotificacaoDto>(`/users/me/notificacoes/${notificacaoId}`)
      .pipe(
        tap((res: NotificacaoDto) => this.notificationStore.upsert(notificacaoId, res)),
      );
  }

  /**
   * Busca as notificações não lidas do usuário.
   *
   * @returns Uma promise que se resolve com a lista das notificações não lidas.
   */
  public buscarNotificacaoNaoLidas(): Observable<NotificacaoSimplificadaDto[]> {
    return this.http.get<NotificacaoSimplificadaDto[]>(`/users/me/notificacoes/nao-lidas`)
      .pipe(
        tap(notificacoes => this.notificationStore.upsertMany(notificacoes)),
      );
  }

  /**
   * Marca uma notificação como lida.
   *
   * @returns Uma promise que se resolve com os detalhes da notificação atualizada.
   */
  public lerNotificacao(notificacaoId: string): Observable<NotificacaoDto> {
    return this.http.put<NotificacaoDto>(`/users/me/notificacoes/${notificacaoId}/ler`, {})
      .pipe(
        tap(notificacao => this.notificationStore.upsert(notificacaoId, notificacao)),
      );
  }
}
