import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AddressDto,
  DocumentFileDto,
  FileDto,
  GetUpdatePropertyAdditionalInformationsDto,
  GetUpdatePropertyLocalizationDto,
  ImovelEnderecoInformacoesAdicionaisDto,
  PropertyAcquisitionDocument,
  PropertyDto,
  PropertyOwnershipDocument,
  PropertySimplifiedDto,
  StatusPreenchimento
} from '@usucampeao/lib-reurb-simplificado';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { CadastroService } from '../../state/cadastros.service';
import { ImoveisStore } from './imoveis.store';

@Injectable({
  providedIn: 'root',
})
export class ImoveisService {

  constructor(
    private cadastroService: CadastroService,
    private http: HttpClient,
    private imoveisStore: ImoveisStore,
  ) { }

  /**
   * Busca os dados simplificados do imóvel
   * @param id id do imóvel
   * @returns dados simplificados do imóvel
   */
  public buscarDadosImovelSimplificado(id: string): Observable<PropertySimplifiedDto> {
    return this.http.get<PropertySimplifiedDto>(`/properties/${id}/simplified`)
      .pipe(
        tap(property => this.imoveisStore.upsert(id, { id, ...property })),
      );
  }

  /**
   * Busca dados completo do imóvel
   * @param cadastroId ID do cadastro
   * @param imovelId ID do imóvel
   */
  public buscarImovelPorCadastroId(cadastroId: string): Observable<PropertyDto> {
    return this.http.get<PropertyDto>(`/registrations/${cadastroId}/properties`)
      .pipe(
        tap(imovel => this.imoveisStore.upsert(imovel.id, imovel)),
      );
  }

  /**
   * Busca os dados de localização do imóvel
   * @param id id do imóvel
   * @returns dados de localização do imóvel
   */
  public buscarLocalizacao(id: string): Observable<GetUpdatePropertyLocalizationDto> {
    return this.http.get<GetUpdatePropertyLocalizationDto>(`/properties/${id}/localization`)
      .pipe(
        tap(data => this.imoveisStore.add({ id, ...data })),
        tap(data => this.imoveisStore.update(id, data)),
      );
  }

  /**
   * Busca os dados de endereço do imóvel
   * @param id id do imóvel
   * @returns dados de endereço do imóvel
   */
  public buscarEndereco(id: string): Observable<AddressDto> {
    return this.http.get<AddressDto>(`/properties/${id}/address`)
      .pipe(
        tap(address => this.imoveisStore.add({ id, address })),
        tap(address => this.imoveisStore.update(id, { ...{ address } })),
      );
  }

  /**
   * Busca as informações adicionais do imóvel
   * @param id id do imóvel
   * @returns informações adicionais do imóvel
   */
  public buscarInformacoesAdicionais(id: string): Observable<GetUpdatePropertyAdditionalInformationsDto> {
    return this.http.get<GetUpdatePropertyAdditionalInformationsDto>(`/properties/${id}/additional-informations`)
      .pipe(
        tap(data => this.imoveisStore.add({ id, ...data })),
        tap(data => this.imoveisStore.update(id, data)),
      );
  }

  /**
   * Atualiza dados de localização do imóvel
   * @param cadastroId id do atendimento
   * @param id id do imóvel
   * @param localizacao dados de localização
   */
  public atualizarLocalizacao(cadastroId: string, id: string, localizacao: GetUpdatePropertyLocalizationDto): Observable<void> {
    return this.http.put<void>(`/registrations/${cadastroId}/properties/${id}/localization`, localizacao)
      .pipe(
        tap(() => {
          const fillInStatus = this.imoveisStore.buscarStatusPreechimento(id) || {};
          fillInStatus.localizationData = StatusPreenchimento.ENVIADO;
          this.imoveisStore.upsert(id, { ...localizacao, fillInStatus });
        }),
        tap(() => this.cadastroService.buscarCadastro(cadastroId).subscribe()),
      );
  }

  /**
   * Atualiza dados de localização do imóvel
   * @param cadastroId id do atendimento
   * @param id id do imóvel
   * @param address dados de endereço
   */
  public atualizarEndereco(cadastroId: string, id: string, address: AddressDto): Observable<void> {
    return this.http.put<void>(`/registrations/${cadastroId}/properties/${id}/address`, address)
      .pipe(
        tap(() => {
          const fillInStatus = this.imoveisStore.buscarStatusPreechimento(id);
          fillInStatus.addressData = StatusPreenchimento.ENVIADO;
          this.imoveisStore.update(id, { address, fillInStatus });
        }),
        tap(() => this.cadastroService.buscarCadastro(cadastroId).subscribe()),
      );
  }

  /**
   * Atualiza dados adicionais do imóvel
   * @param cadastroId id do atendimento
   * @param id id do imóvel
   * @param informacoesAdicionais dados adicionais
   */
  public atualizarInformacoesAdicionais(cadastroId: string, id: string, informacoesAdicionais: GetUpdatePropertyAdditionalInformationsDto): Observable<void> {
    return this.http.put<void>(`/registrations/${cadastroId}/properties/${id}/additional-informations`, informacoesAdicionais)
      .pipe(
        tap(() => {
          const fillInStatus = this.imoveisStore.buscarStatusPreechimento(id);
          fillInStatus.additionalInformationsData = StatusPreenchimento.ENVIADO;
          this.imoveisStore.update(id, { ...informacoesAdicionais, fillInStatus });
        }),
        tap(() => this.cadastroService.buscarCadastro(cadastroId).subscribe()),
      );
  }

  /**
   * Adiciona endereço e informações adicionais no imóvel
   * @param cadastroId id do cadastro
   * @param imovelId id do imóvel
   * @param data dados de endereço e informações adicionais do imóvel
   */
  public atualizarEnderecoEInfosAdicionais(cadastroId: string, imovelId: string, data: ImovelEnderecoInformacoesAdicionaisDto): Observable<void> {
    return this.http.put<void>(
      `/v2/registrations/${cadastroId}/properties/${imovelId}/address-additional-information`,
      data
    ).pipe(
      tap(() => this.imoveisStore.add({ id: imovelId, ...data })),
    );
  }

  /**
 * Altera foto da fachada do imóvel
 * @param cadastroId ID do cadastro
 * @param imovelId ID do imóvel
 * @param file foto da fachada
 */
  public atualizarFotoFachada(cadastroId: string, imovelId: string, file: File): Observable<FileDto> {
    const formData = new FormData();
    formData.append('photo', file);
    return this.http.put<FileDto>(`/registrations/${cadastroId}/properties/${imovelId}/photo`, formData)
      .pipe(
        tap(file => this.imoveisStore.update(imovelId, { files: [file] })),
        tap(() => this.cadastroService.buscarCadastro(cadastroId).subscribe()),
      );
  }

  /**
   * Busca documentos do imóvel
   * @param cadastroId ID do cadastro
   * @param imovelId ID do imóvel
   */
  public buscarDocumentos(cadastroId: string, imovelId: string): Observable<DocumentFileDto[]> {
    return this.http.get<DocumentFileDto[]>(`/registrations/${cadastroId}/properties/${imovelId}/documents`)
      .pipe(
        tap(documentos => this.imoveisStore.update(imovelId, { documentos }))
      );
  }

  /**
   * Adiciona um novo documento e carrega a lista de documentos atualizados na store.
   * @param cadastroId ID do cadastro
   * @param imovelId ID do imóvel
   * @param arquivos Lista de arquivos do documento
   * @param tipo Tipo do documento. Pode ser algum documento de posse ou aquisição
   * @param documentName Nome do documento (opcional)
   * @returns Lista atualizada de documentos do imóvel
   */
  public adicionarDocumento(cadastroId: string, imovelId: string, arquivos: File[], tipo: PropertyAcquisitionDocument | PropertyOwnershipDocument, documentName?: string): Observable<DocumentFileDto[]> {
    const formData = new FormData();

    if (documentName) {
      formData.append('name', documentName);
    }

    arquivos.forEach((item) => {
      formData.append('files', item);
    });

    formData.append('type', tipo);
    return this.http.post(`/registrations/${cadastroId}/properties/${imovelId}/documents`, formData, { responseType: 'text' })
      .pipe(
        switchMap(() => this.buscarDadosImovelSimplificado(imovelId)),
        switchMap(() => this.buscarDocumentos(cadastroId, imovelId)),
        tap(() => this.cadastroService.buscarCadastro(cadastroId).subscribe()),
      );
  }

  /**
   * Atualiza um documento de imóvel e carrega a lista de documentos atualizados na store.
   * Esse método adiciona e/ou remove arquivos de um documento.
   * @param cadastroId ID do cadastro
   * @param imovelId ID do imóvel
   * @param documentoId ID do documento
   * @param arquivos Arquivos a serem enviados
   * @param arquivosRemovidos Arquivos a serem removidos
   * @returns Lista atualizada de documentos do imóvel
   */
  public atualizarDocumento(cadastroId: string, imovelId: string, documentoId: string, arquivos: File[], arquivosRemovidos: string[]): Observable<DocumentFileDto[]> {
    const formData = new FormData();

    if (arquivosRemovidos) {
      arquivosRemovidos.map(id => formData.append('arquivosRemovidos', id));
    }

    if (arquivos) {
      arquivos.forEach(arquivo => formData.append('files', arquivo));
    }

    return this.http.put(`/registrations/${cadastroId}/properties/${imovelId}/documents/${documentoId}/files`, formData, { responseType: 'text' })
      .pipe(
        switchMap(() => this.buscarDocumentos(cadastroId, imovelId)),
        tap(() => this.cadastroService.buscarCadastro(cadastroId).subscribe())
      );
  }
}
