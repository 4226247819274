<ion-content class="ion-padding">
  <section class="content-vertical-space-between">
    <usucampeao-header title="Minha foto"></usucampeao-header>

    <usucampeao-upload-foto-documento [podeEnviarVariosArquivos]="false" [podeEnviarPDF]="false" (aoRealizarUpload)="selecionarImagem($event)"></usucampeao-upload-foto-documento>

    <ion-text class="ion-text-center">
      <p class="font-text">Tire uma foto para ajudar nossos assistentes a te identificar</p>
    </ion-text>

    <usucampeao-footer>
      <ion-button *ngIf="fotoSelecionada" class="ion-margin-bottom" mode="md" expand="block" (click)="atualizarFotoPerfil()">Continuar</ion-button>
      <ion-button mode="md" fill="outline" expand="block" (click)="voltar()">Cancelar</ion-button>
    </usucampeao-footer>
  </section>
</ion-content>
