<ion-text>
  <p class="font-text">
    Você enviou todos os documentos e dados solicitados para a UsuCampeão.
  </p>
  <p class="font-text">
    Nosso time verificará as informações e os documentos fornecidos, indicando se alguma alteração será necessária.
  </p>
  <p class="font-text">
    Atente-se à visitas presenciais realizadas pelos nossos técnicos, que serão agendadas pelos nossos canais de
    comunicação oficiais.
  </p>
</ion-text>
