export class NumberUtils {
  /**
   * Formata um número em reais brasileiros.
   * Caso o valor seja inválido, isto é, não seja um número, retornará 'R$ 0,00'.
   * Exemplo: 5000 -> R$ 5.000,00 / 475.5 -> R$ 475,50 / 789658785.58 -> R$ 789.658.785,58
   */
  static formatarNumeroEmRealBrasileiro(valor: number): string {
    if (typeof valor !== 'number' || isNaN(valor)) {
      return 'R$ 0,00';
    }

    const numeroFormatado = new Intl.NumberFormat(
      'pt-BR',
      {
        style: 'currency',
        currency: 'BRL',
      }
    )
      .format(valor)
      .replace(/\s/g, ' ');

    return numeroFormatado;
  }
}
