import { Expose, Type } from 'class-transformer';
import { IsNotEmpty, IsNumber, ValidateNested } from 'class-validator';
import { AddressDto } from '../address/address.dto';

export class ImovelAlterarLocalizacaoEnderecoDto {
  @Expose()
  @IsNumber()
  @IsNotEmpty()
  blockId: number;

  @Expose()
  @IsNumber()
  @IsNotEmpty()
  lotId: number;

  @Expose()
  @Type(() => AddressDto)
  @ValidateNested()
  @IsNotEmpty()
  address: AddressDto;
}
