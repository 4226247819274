import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { PagamentosDetalhesModule } from '@usucampeao/ui-mobile';
import { PagamentosDetalhesRoutingModule } from './pagamentos-detalhes-routing.module';
import { PagamentosDetalhesPage } from './pagamentos-detalhes.page';


@NgModule({
  declarations: [
    PagamentosDetalhesPage
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,

    // route
    PagamentosDetalhesRoutingModule,

    // components
    PagamentosDetalhesModule,
  ]
})
export class PagamentosDetalhesPageModule { }
