<ion-text>
  <p class="font-text">
    Estão sendo elaborados o relatório técnico da infraestrutura e o desenho do LEPAC, contendo as informações
    topográficas da sua área.
  </p>
  <p class="font-text">
    Estas peças técnicas são parte dos estudos preliminares necessários para garantir que todo o projeto de
    regularização esteja nos conformes da prefeitura.
  </p>
</ion-text>
