<div class="accordion">
  <div class="accordion__header" [class.accordion__header--destaque]="headerComDestaque" (click)="toggleAccordion()">
    <ion-text class="accordion__titulo">
      <p class="font-text font-text--medium font-text-bold ion-no-margin">{{ titulo }}</p>
    </ion-text>

    <ng-content select="[extras]"></ng-content>
    <ion-icon class="accordion__icon" [name]="accordionEstaAberto ? 'chevron-up-outline' : 'chevron-down-outline'"></ion-icon>
  </div>
</div>
<div class="accordion__content" #accordionContent>
  <ng-content></ng-content>
</div>
