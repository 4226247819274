<ion-menu side="start" menuId="menu" contentId="main-content" type="overlay">
  <ion-content scrollY="false">
    <div class="menu__container">
      <div class="menu__content">
        <div class="ion-padding-bottom">
          <usucampeao-toolbar></usucampeao-toolbar>
        </div>
        <ion-grid class="ion-padding">
          <ion-row>
            <ion-col>
              <usucampeao-menu-item icon="person-outline" title="Minha conta" subtitle="Visualize e atualize seus dados"
                (click)="closeAndNavigate('/perfil')">
              </usucampeao-menu-item>
              <usucampeao-menu-item icon="file-tray-full-outline" title="Meus cadastros"
                subtitle="Cadastre e consulte seus processos" (click)="closeAndNavigate('/')"></usucampeao-menu-item>
              <usucampeao-menu-item icon="notifications-outline" title="Notificações"
                [notificationNumber]="(totalNotificacoesNaoLidas$ | async)"
                subtitle="Verifique as notificações recebidas" (click)="closeAndNavigate('/notificacoes')">
              </usucampeao-menu-item>
              <usucampeao-menu-item icon="help-circle-outline" title="Ajuda" subtitle="Alguma dúvida? Nós te ajudamos"
                (click)="closeAndNavigate('/ajuda')">
              </usucampeao-menu-item>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="">
              <ion-text class="font-text font-text--small line-height">
                Veja aqui as nossas <a (click)="abrirModalPoliticaPrivacidade()">Politicas de Privacidade</a> e nossos
                <a (click)="abrirModalTermosDeUso()">Termos de Uso</a> atualizados.
              </ion-text>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>

      <div class="menu__footer ion-padding">
        <ion-button fill="outline" mode="md" expand="block" (click)="logout()" class="button-outline">
          Sair
        </ion-button>
        <usucampeao-footer></usucampeao-footer>
      </div>
    </div>

  </ion-content>
</ion-menu>
