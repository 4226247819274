import { IsNotEmpty, IsString } from 'class-validator';

export class NotificacaoTopicDto {
  @IsNotEmpty()
  @IsString()
  usuarioId: string;

  @IsNotEmpty()
  @IsString()
  notificacaoId: string;

  @IsNotEmpty()
  @IsString()
  usuarioBackoffice: string;
}
