import { Expose, Type } from 'class-transformer';
import { IsArray, ValidateNested } from 'class-validator';
import { AlteracaoDto } from './alteracao.dto';

export class CreateRevisaoDto {
  @Expose()
  @Type(() => AlteracaoDto)
  @IsArray()
  @ValidateNested({ each: true })
  alteracoes: AlteracaoDto[];

  usuarioResponsavelId?: string;
}
