import { RemoveSpecialCharacters } from '@usucampeao/utils';
import { Expose, plainToInstance, Type } from 'class-transformer';
import { IsEmail, IsNotEmpty, IsNumberString, IsOptional, IsString, Length, ValidateIf, ValidateNested } from 'class-validator';
import { OwnerFillInStatusDto } from './owner-fill-in-status.dto';
import { OwnerDto } from './owner.dto';
import { PhoneDto, PhonePartialDto } from './phone.dto';

export class ProprietarioEditarEmailEWhatsappDto {
  @Expose()
  @IsEmail()
  @IsOptional()
  email?: string;

  @RemoveSpecialCharacters()
  @IsNumberString()
  @Length(11, 11)
  @IsNotEmpty()
  whatsapp: string;
}

export class GetUpdateOwnerContactDto {
  @Expose()
  @IsEmail()
  @IsOptional()
  @ValidateIf(data => data.email)
  email?: string;

  @Expose()
  @Type(() => PhoneDto)
  @ValidateNested()
  @IsNotEmpty()
  phones: PhoneDto;

  @Expose()
  @IsString()
  @IsOptional()
  contactName: string;

  fillInStatus?: OwnerFillInStatusDto;

  /**
   * Cria uma nova instância de GetUpdateOwnerContactDto a partir de dtos
   * @param props DTO de Owner
   * @returns nova instância de GetUpdateOwnerContactDto
   */
  static from(props?: OwnerDto | Partial<OwnerDto>): GetUpdateOwnerContactDto {
    return plainToInstance(GetUpdateOwnerContactDto, props, { exposeUnsetFields: false, excludeExtraneousValues: true });
  }
}

export class ProprietarioDadosContatoPartialDto {
  @Expose()
  @IsEmail()
  @IsOptional()
  email?: string;

  @Expose()
  @Type(() => PhonePartialDto)
  @ValidateNested()
  @IsOptional()
  phones: PhonePartialDto;

  @Expose()
  @IsString()
  @IsOptional()
  contactName: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  proprietarioId: string;
}
