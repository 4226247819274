import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { CadastrosFichaV2Module } from '@usucampeao/ui-mobile';
import { CadastrosRoutingModule } from './cadastros-routing.module';
import { CadastrosComponent } from './cadastros.component';

@NgModule({
  declarations: [
    CadastrosComponent
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,

    // route
    CadastrosRoutingModule,

    // components
    CadastrosFichaV2Module,
  ]
})
export class CadastrosModule { }
