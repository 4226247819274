import { Component, Input } from '@angular/core';

@Component({
  selector: 'contrato-tipo-assinatura',
  templateUrl: './contrato-tipo-assinatura.component.html',
  styleUrls: ['./contrato-tipo-assinatura.component.scss']
})
export class ContratoTipoAssinaturaComponent {
  @Input() titulo: string;
  @Input() descricao1: string;
  @Input() descricao2: string;
  @Input() imageUrl: string;

  public get isMobile(): boolean {
    return window.screen.width <= 600;
  }
}
