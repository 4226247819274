import { Transform, Type } from 'class-transformer';
import { IsDate, IsEnum, IsNotEmpty, ValidateIf, ValidateNested } from 'class-validator';
import { ProjetoEndereco } from './projeto-endereco.model';

export enum ProjetoStatus {
  EM_PREPARACAO = 'EM_PREPARACAO',
  INSTAURACAO_NUCLEO = 'INSTAURACAO_NUCLEO',
  RELATORIO_TECNICO_E_LEPAC = 'RELATORIO_TECNICO_E_LEPAC',
  CADASTRO_SOCIAL_E_PERIMETRO_FINAL = 'CADASTRO_SOCIAL_E_PERIMETRO_FINAL',
  PROJETO_URBANISTICO = 'PROJETO_URBANISTICO',
  PROJETO_ENVIADO_A_PREFEITURA = 'PROJETO_ENVIADO_A_PREFEITURA',
  ATENDENDO_DEVOLUTIVAS_DA_PREFEITURA = 'ATENDENDO_DEVOLUTIVAS_DA_PREFEITURA',
  APROVADO_PELA_PREFEITURA = 'APROVADO_PELA_PREFEITURA',
  REGISTRADO_EM_CARTORIO = 'REGISTRADO_EM_CARTORIO',
  ATENDENDO_DEVOLUTIVAS_DO_CARTORIO = 'ATENDENDO_DEVOLUTIVAS_DO_CARTORIO',
  TITULO_EMITIDO = 'TITULO_EMITIDO',
  TITULO_ENTREGUE = 'TITULO_ENTREGUE',
  ENCERRADO = 'ENCERRADO',
  CANCELADO = 'CANCELADO',
}

export const PROJETO_STATUS_EM_ANDAMENTO = [
  ProjetoStatus.INSTAURACAO_NUCLEO,
  ProjetoStatus.RELATORIO_TECNICO_E_LEPAC,
  ProjetoStatus.CADASTRO_SOCIAL_E_PERIMETRO_FINAL,
  ProjetoStatus.PROJETO_URBANISTICO,
  ProjetoStatus.PROJETO_ENVIADO_A_PREFEITURA,
  ProjetoStatus.ATENDENDO_DEVOLUTIVAS_DA_PREFEITURA,
  ProjetoStatus.APROVADO_PELA_PREFEITURA,
  ProjetoStatus.REGISTRADO_EM_CARTORIO,
  ProjetoStatus.ATENDENDO_DEVOLUTIVAS_DO_CARTORIO,
  ProjetoStatus.TITULO_EMITIDO,
  ProjetoStatus.TITULO_ENTREGUE,
];

export const PROJETO_STATUS_HABILITADO_PARA_MUDANCA_EM_ORDEM = [
  ProjetoStatus.EM_PREPARACAO,
  ...PROJETO_STATUS_EM_ANDAMENTO,
];

export type ProjetoAvancarStatusOutput = { projetoId: string; novoStatus: ProjetoStatus };

export class ProjetoAlterarStatusDto {
  @IsEnum(ProjetoStatus)
  @IsNotEmpty()
  status: ProjetoStatus;

  @Type(() => Date)
  @Transform(({ value, obj }) => {
    if (obj.status !== ProjetoStatus.APROVADO_PELA_PREFEITURA) {
      return undefined;
    }
    return value;
  })
  @IsDate()
  @IsNotEmpty()
  @ValidateIf((object) => object.novoStatus === ProjetoStatus.APROVADO_PELA_PREFEITURA)
  publicadoDiarioOficialData?: Date;

  @Type(() => Date)
  @Transform(({ value, obj }) => {
    if (obj.status !== ProjetoStatus.REGISTRADO_EM_CARTORIO) {
      return undefined;
    }
    return value;
  })
  @IsDate()
  @IsNotEmpty()
  @ValidateIf((object) => object.novoStatus === ProjetoStatus.REGISTRADO_EM_CARTORIO)
  cartorioRegistroData?: Date;

  @Type(() => Date)
  @Transform(({ value, obj }) => {
    if (obj.status !== ProjetoStatus.TITULO_EMITIDO) {
      return undefined;
    }
    return value;
  })
  @IsDate()
  @IsNotEmpty()
  @ValidateIf((object) => object.novoStatus === ProjetoStatus.TITULO_EMITIDO)
  tituloEntregaData?: Date;

  @Type(() => ProjetoEndereco)
  @Transform(({ value, obj }) => {
    if (obj.status !== ProjetoStatus.TITULO_EMITIDO) {
      return undefined;
    }
    return value;
  })
  @ValidateNested()
  @IsNotEmpty()
  @ValidateIf((object) => object.novoStatus === ProjetoStatus.TITULO_EMITIDO)
  tituloEntregaLocalizacao?: ProjetoEndereco;
}
