import { Endereco } from '../endereco/endereco';
import { PagamentoStatus } from '../pagamento';

export enum ClienteTipo {
  PESSOA_FISICA = 'PESSOA_FISICA',
  PESSOA_JURIDICA = 'PESSOA_JURIDICA',
}

export type BoletoCliente = {
  nome: string;
  tipo: ClienteTipo;
  documento: string;
  endereco: Endereco;
}

export type Boleto = {
  integracaoId: string;
  integracaoNumero: number;
  status: PagamentoStatus;
  dataEmissao: Date;
  dataVencimento: Date;
  valorDevido: number;
  codigoDeBarras: string;
  linhaDigitavel: string;
  cliente: BoletoCliente;
  dataPagamento: Date | null;
  valorPago: number | null;
}

export type BoletoConsultarDto = Boleto & {
  possuiDadosCadastrados: boolean;
};
