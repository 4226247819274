<div id="print-section" class="contract">
  <div class="contract__title">
    <h1>{{ titulo }}</h1>
  </div>
  <div class="contract__personal-data">
    <table class="contract__table">
      <tr>
        <td>
          <p>
            Por este instrumento particular, de um lado,
            <span class="contract__subtitle">NOME:</span> {{ proprietario?.name }},
          </p>
        </td>
      </tr>
      <tr>
        <td>
          <p>
            <span class="contract__subtitle">CPF:</span> {{ proprietario?.cpf | mask: '000.000.000-00' }}, <span
              class="contract__subtitle">RG:</span> {{ proprietario?.rg?.number || '' }},
            <span class="contract__subtitle">EMISSOR/UF:</span> {{ proprietario?.rg?.issuer || '' }}/{{
            proprietario?.rg?.state || ''}}
          </p>
        </td>
      </tr>
      <tr *ngIf="(proprietario?.maritalStatus === estadoCivil.MARRIED) && conjuge ">
        <td>
          <p>
            <span class="contract__subtitle">CÔNJUGE/COMPANHEIRO:</span> {{ conjuge?.name }}, <span
              class="contract__subtitle">CPF:</span> {{
            conjuge?.cpf | mask: '000.000.000-00' }}
          </p>
        </td>
      </tr>
      <tr>
        <td>
          <p>
            <span class="contract__subtitle">Rua:</span> {{ imovel?.address?.street }} Nº.{{ imovel?.address?.number }}
            <ng-container *ngIf="imovel?.address?.complement">
              <span class="contract__subtitle">Complemento: </span> {{ imovel?.address?.complement }}
            </ng-container>
            <span class="contract__subtitle">Bairro: </span> {{ imovel?.address?.neighborhood }} <span
              class="contract__subtitle">Cidade: </span> {{
            imovel?.address?.city }} <span class="contract__subtitle">UF: </span> {{ imovel?.address?.state }}
          </p>
        </td>
      </tr>
      <tr *ngIf="cadastro?.reurbModalidade">
        <td>
          <p>
            <span class="contract__subtitle">Condições REURB:</span> Renda Familiar menor que 5(cinco) salários mínimos
            e não ter sido beneficiado pela REURB anteriormente. <br />
            (&nbsp; <ng-container *ngIf="cadastro.reurbModalidade === reurbModalidade.REURB_S">X </ng-container>)
            REURB–S ou (&nbsp;
            <ng-container *ngIf="cadastro.reurbModalidade === reurbModalidade.REURB_E">X </ng-container>) REURB–E
          </p>
        </td>
      </tr>
      <tr>
        <td>
          <p>
            <span class="contract__subtitle">Qual o segmento de uso do imóvel:</span> (&nbsp;
            <ng-container *ngIf="imovel?.usage === imovelTipo.RESIDENTIAL">X </ng-container>) Residencial (&nbsp;
            <ng-container *ngIf="imovel?.usage === imovelTipo.COMMERCIAL">X </ng-container>) Comercial (&nbsp;
            <ng-container *ngIf="imovel?.usage === imovelTipo.MIXED">X </ng-container>) Misto
          </p>
        </td>
      </tr>
      <tr>
        <td>
          <p>
            <span class="contract__subtitle">Estado Civil:</span> (&nbsp;
            <ng-container *ngIf="proprietario?.maritalStatus === estadoCivil.MARRIED">X</ng-container>&nbsp;)
            Casado(a) (&nbsp;
            <ng-container *ngIf="proprietario?.maritalStatus === estadoCivil.SINGLE">X</ng-container>&nbsp;)
            Solteiro(a) (&nbsp;
            <ng-container *ngIf="proprietario?.maritalStatus === estadoCivil.WIDOWED">X</ng-container>&nbsp;) Viúvo(a)
            (&nbsp;
            <ng-container *ngIf="proprietario?.maritalStatus === estadoCivil.SEPARATED">X</ng-container>&nbsp;)
            Separado(a)
          </p>
        </td>
      </tr>
      <tr>
        <td>
          <p>
            <span class="contract__subtitle">E-mail: </span> {{ proprietario?.email }} <span class="contract__subtitle">
              Telefone: </span> {{
            proprietario?.phones?.cellphone | mask: '(00) 00000-0000' || ''}}
          </p>
        </td>
      </tr>
      <tr>
        <td>
          <p>
            <span class="contract__subtitle">Observações: </span>
          </p>
        </td>
      </tr>
    </table>
  </div>
  <div class="contract__content">
    <p>
      Doravante
      <ng-container *ngIf="!conjuge"> denominado <span class="contract__subtitle">"CONTRATANTE"</span></ng-container>
      <ng-container *ngIf="conjuge"> denominados <span class="contract__subtitle">"CONTRATANTES"</span></ng-container>;
      e, de outro lado,
      <span class="contract__subtitle">USUCAMPEÃO TECNOLOGIA EM REGULARIZAÇÃO IMOBILIÁRIA LTDA.</span>, pessoa jurídica
      de
      direito privado, com SEDE na Rua Claudio Soares 72, cj 614/618 Pinheiros, São Paulo – SP, inscrita no CNPJ/MF sob
      o nº 36.174.049/0001-08, e FILIAL Rua José Rodrigues Pereira 514, Estoril, Belo Horizonte – MG inscrita no CNPJ/MF
      <span class="contract__subtitle">36.174.049/0002-99</span>, neste ato representada de acordo com seus atos
      constitutivos, doravante
      denominado <span class="contract__subtitle"><u>"USUCAMPEÃO"</u></span>,
      <span class="contract__subtitle">CONTRATO DE PRESTAÇÃO DE SERVIÇOS DE
        REGULARIZAÇÃO
        FUNDIÁRIA</span>, regido pelas seguintes cláusulas:
    </p>

    <div class="contract__clausula">
      <p>
        <span class="contract__subtitle">Cláusula Primeira – OBJETO</span><br />
        <span class="contract__subtitle">1.1.</span> Este Contrato estabelece as condições pelas quais a <span
          class="contract__subtitle">USUCAMPEÃO</span> prestará
        <ng-container *ngIf="!conjuge"> ao <span class="contract__subtitle">CONTRATANTE</span> </ng-container>
        <ng-container *ngIf="conjuge"> aos <span class="contract__subtitle">CONTRATANTES</span> </ng-container>
        serviços
        de regularização
        fundiária “REURB” <ng-container *ngIf="cadastro.averbacao">mais “Averbação Edilícia”</ng-container>, em três
        etapas, visando realizar os procedimentos necessários para obtenção da titulação da
        unidade imobiliária da qual
        <ng-container *ngIf="!conjuge"> o <span class="contract__subtitle">CONTRATANTE</span> </ng-container>
        <ng-container *ngIf="conjuge"> os <span class="contract__subtitle">CONTRATANTES</span> </ng-container> detém a
        posse, nos termos da Lei
        Federal 13.465/17 e Decreto Federal n° 9.310/2018.
      </p>
      <ul>
        <li><span class="contract__subtitle">1.1.1.</span> Os serviços serão realizados conforme as seguintes etapas:
        </li>
        <ul>
          <li>
            <span class="contract__subtitle">a.</span> PRIMEIRA ETAPA: Coleta de dados e pré-análise - 10% (dez
            porcento)
            dos serviços;
          </li>
          <li>
            <span class="contract__subtitle">b.</span> SEGUNDA ETAPA: Engenharia, Memorial do Perímetro, Levantamento
            Planialtimétrico Cadastral do
            Núcleo e Projeto Urbanístico do Núcleo - 20% (vinte porcento) dos serviços;
          </li>
          <li>
            <span class="contract__subtitle">c.</span> TERCEIRA ETAPA: Protocolo REURB e entrega da Certidão de
            Regularização Fundiária (CRF) - 70%
            (setenta porcento) dos serviços.
          </li>
        </ul>
      </ul>
    </div>
    <div class="contract__clausula">
      <p>
        <span class="contract__subtitle">Cláusula Segunda – RESPONSABILIDADE DAS PARTES</span><br />
        <span class="contract__subtitle">2.1.</span> São obrigações
        <ng-container *ngIf="!conjuge"> do <span class="contract__subtitle">CONTRATANTE</span> </ng-container>
        <ng-container *ngIf="conjuge"> dos <span class="contract__subtitle">CONTRATANTES</span> </ng-container>
        efetuar
        o pagamento na forma da
        Cláusula Terceira e fornecer todas as informações e proprietarios solicitados pela
        <span class="contract__subtitle">USUCAMPEÃO</span> na execução dos Serviços.<br />
        <span class="contract__subtitle">2.2.</span> São obrigações da <span
          class="contract__subtitle">USUCAMPEÃO</span> a execução dos Serviços constantes na
        Cláusula Primeira e no Anexo 1 (escopo do serviço).
      </p>
    </div>
    <div class="contract__clausula">
      <p>
        <span class="contract__subtitle">Cláusula Terceira – REMUNERAÇÃO E FORMA DE PAGAMENTO</span><br />
        <ng-container *ngIf="cadastro?.meioPagamento">
          <span class="contract__subtitle">3.1.</span> Pela prestação dos Serviços objeto deste Contrato,
          <ng-container *ngIf="!conjuge"> o <span class="contract__subtitle">CONTRATANTE</span> pagará </ng-container>
          <ng-container *ngIf="conjuge"> os <span class="contract__subtitle">CONTRATANTES</span> pagarão
          </ng-container>
          a quantia certa e líquida de {{ (cadastro?.valorServico | currency: 'BRL') }} ({{cadastro.valorServico |
          numeroExtenso: numeroExtensoTipo.MONETARIO}}) podendo ser parcelada ou no ato da assinatura deste contrato,
          conforme <span class="contract__subtitle">ANEXO II – DISPOSIÇÕES FINANCEIRAS</span> do presente contrato.
        </ng-container>
      </p>

      <p>
        <span class="contract__subtitle">3.2.</span> Os direitos reais a serem atribuídos
        <ng-container *ngIf="!conjuge"> ao <span class="contract__subtitle">CONTRATANTE</span>, estarão definidos
        </ng-container>
        <ng-container *ngIf="conjuge"> aos <span class="contract__subtitle">CONTRATANTES</span>, estarão definidos
        </ng-container> na Certidão
        de Regularização Fundiária, a ser expedida pela Prefeitura, na forma do artigo 41 da lei 13.465/17. Para ser
        beneficiário dos citados direitos e receber a titulação de sua unidade imobiliária, o nome e a unidade
        imobiliária
        <ng-container *ngIf="!conjuge">do <span class="contract__subtitle">CONTRATANTE</span> </ng-container>
        <ng-container *ngIf="conjuge"> dos <span class="contract__subtitle">CONTRATANTES</span> </ng-container>
        deverão constar na CRF, conforme artigo 41, inciso VI da lei 13.465/17. Assim sendo,
        <ng-container *ngIf="!conjuge"> fica o <span class="contract__subtitle">CONTRATANTE</span>
          ciente</ng-container>
        <ng-container *ngIf="conjuge"> ficam os <span class="contract__subtitle">CONTRATANTES</span>
          cientes</ng-container>
        de que, tanto seu nome, como a descrição de sua unidade imobiliária, somente constarão da CRF após quitação
        total do preço pactuado conforme <span class="contract__subtitle">ANEXO II – DISPOSIÇÕES FINANCEIRAS</span>.
        Com
        fulcro no parágrafo primeiro do artigo 14 da lei 13.465/17, a
        <span class="contract__subtitle">USUCAMPEÃO</span> entregará, para a Prefeitura, todos os projetos necessários
        e
        previstos nos artigos
        35 e 36 da mesma lei, acompanhados da lista dos ocupantes de cada unidade imobiliária que tenham quitado o
        preço
        estipulado no item 3.1; mais o memorial descritivo de cada unidade. Quando todos esses proprietarios técnicos
        estiverem devidamente concluídos, ANTES de encaminhar para a Prefeitura, a <span
          class="contract__subtitle">USUCAMPEÃO</span>, entrará
        em contato com
        <ng-container *ngIf="!conjuge"> o <span class="contract__subtitle">CONTRATANTE</span> </ng-container>
        <ng-container *ngIf="conjuge"> os <span class="contract__subtitle">CONTRATANTES</span> para oferecer
        </ng-container>
        <ng-container *ngIf="!conjuge"> para oferecer ao <span class="contract__subtitle"> mesmo</span>, as seguintes
          opções: </ng-container>
        <ng-container *ngIf="conjuge"> para oferecer aos <span class="contract__subtitle">mesmos</span>, as seguintes
          opções: </ng-container>
      </p>

      <ul>
        <li>
          <p>
            <span class="contract__subtitle">3.2.1.</span>&nbsp;<u>Efetuar a quitação total do preço pactuado.</u> Nesse
            caso,
            <ng-container *ngIf="!conjuge">o nome do <span class="contract__subtitle">CONTRATANTE</span> já constará
            </ng-container>
            <ng-container *ngIf="conjuge"> os nomes dos <span class="contract__subtitle">CONTRATANTES</span> já
              constarão
            </ng-container>
            da primeira lista a ser a ser apresentada para a Prefeitura para inserir na CRF, a fim de que, após o
            registro
            da CRF, seja
            <u>aberta matrícula individualizada da unidade imobiliária em nome
              <ng-container *ngIf="!conjuge"> do <span class="contract__subtitle">CONTRATANTE</span>. </ng-container>
              <ng-container *ngIf="conjuge"> dos <span class="contract__subtitle">CONTRATANTES</span>.</ng-container>
            </u>
          </p>
        </li>
        <li>
          <p>
            <span class="contract__subtitle">3.2.2.</span>&nbsp;<u>Continuar pagando o preço de forma parcelada,</u>
            como
            pactuado no <span class="contract__subtitle">ANEXO II – DISPOSIÇÕES FINANCEIRAS</span> na assinatura do
            presente contrato. Nesse caso,
            <u>
              <ng-container *ngIf="!conjuge">o nome do <span class="contract__subtitle">CONTRATANTE</span> somente será
                informado </ng-container>
              <ng-container *ngIf="conjuge">
                os nomes dos <span class="contract__subtitle">CONTRATANTES</span> somente serão informados
              </ng-container>
              para a Prefeitura após a quitação do preço total
            </u>, ou seja, após a finalização do parcelamento. Assim sendo, após essa finalização, a
            <span class="contract__subtitle">USUCAMPEÃO</span> informará a Prefeitura, solicitando que
            <ng-container *ngIf="!conjuge">o nome do <span class="contract__subtitle">CONTRATANTE</span> seja inserido
            </ng-container>
            <ng-container *ngIf="conjuge"> os nomes dos <span class="contract__subtitle">CONTRATANTES</span> sejam
              inseridos
            </ng-container>
            em lista complementar da CRF, na forma do artigo 23, §6º da lei 13.465/17 (cadastro complementar).
          </p>
        </li>
        <li>
          <p>
            <span class="contract__subtitle">3.2.3.</span> Em ambos os casos, a unidade imobiliária será titulada em
            nome
            <ng-container *ngIf="!conjuge">do <span class="contract__subtitle">CONTRATANTE</span> </ng-container>
            <ng-container *ngIf="conjuge"> dos <span class="contract__subtitle">CONTRATANTES</span> </ng-container>,
            variando apenas o momento que
            essa titulação ocorrerá.
          </p>
        </li>
        <li>
          <p>
            <span class="contract__subtitle">3.2.4.</span> A <span class="contract__subtitle">USUCAMPEÃO</span> poderá,
            se entender viável, após análise técnica e financeira, dispensar a tramitação prevista no iteM 3.2, já
            inserindo <ng-container *ngIf="!conjuge"> o nome do <span class="contract__subtitle">CONTRATANTE</span>
            </ng-container>
            <ng-container *ngIf="conjuge"> os nomes dos <span class="contract__subtitle">CONTRATANTES</span>
            </ng-container>
            na primeira lista a ser apresentada para a Prefeitura.
          </p>
        </li>
      </ul>

      <p>
        <span class="contract__subtitle">3.3.</span>
        <ng-container *ngIf="!conjuge"> Fica o <span class="contract__subtitle">CONTRATANTE</span>
          ciente</ng-container>
        <ng-container *ngIf="conjuge"> Ficam os <span class="contract__subtitle">CONTRATANTES</span> cientes
        </ng-container>
        de que todos os projetos técnicos que serão apresentados para a Prefeitura, são de uso exclusivo da
        <span class="contract__subtitle">USUCAMPEÃO</span>, a qual detém os direitos autorais dos mesmos.
      </p>
    </div>
    <div class="contract__clausula">
      <p>
        <span class="contract__subtitle">Cláusula Quarta – RESCISÃO</span><br />
        <span class="contract__subtitle">4.1.</span> Este Contrato poderá ser rescindido imotivadamente
        <ng-container *ngIf="!conjuge"> pelo <span class="contract__subtitle">CONTRATANTE</span> </ng-container>
        <ng-container *ngIf="conjuge"> pelos <span class="contract__subtitle">CONTRATANTES</span> </ng-container>,
        desde
        que seja enviada
        notificação prévia, com antecedência mínima de 15 (quinze) dias, gerando as seguintes consequências:
      </p>
      <ul>
        <li>
          <p>
            <span class="contract__subtitle">4.1.1.</span> Se a rescisão ocorrer por parte
            <ng-container *ngIf="!conjuge"> do <span class="contract__subtitle">CONTRATANTE</span> </ng-container>
            <ng-container *ngIf="conjuge"> dos <span class="contract__subtitle">CONTRATANTES</span> </ng-container>
            durante
            a PRIMEIRA ETAPA citada
            no item 1.1.2.a, os seguintes valores deverão ser pagos à <span class="contract__subtitle">USUCAMPEÃO</span>
          </p>

          <ul>
            <li>
              <p>
                <span class="contract__subtitle">4.1.1.1.</span> 20% (vinte porcento) do valor global do contrato por
                multa
                contratual;
              </p>
            </li>
            <li>
              <p>
                <span class="contract__subtitle">4.1.1.2.</span> 10% (dez porcento) do valor global do contrato
                referente aos
                serviços da primeira etapa.
              </p>
            </li>
          </ul>
        </li>
        <li>
          <p>
            <span class="contract__subtitle">4.1.2.</span> Se a rescisão ocorrer por parte
            <ng-container *ngIf="!conjuge"> do <span class="contract__subtitle">CONTRATANTE</span> </ng-container>
            <ng-container *ngIf="conjuge"> dos <span class="contract__subtitle">CONTRATANTES</span> </ng-container>
            durante
            a SEGUNDA ETAPA citada
            no item 1.1.2.2. , os seguintes valores deverão ser pagos à <span
              class="contract__subtitle">USUCAMPEÃO</span>
          </p>

          <ul>
            <li>
              <p>
                <span class="contract__subtitle">4.1.2.1.</span> 20% (vinte porcento) do valor global do contrato por
                multa contratual;
              </p>
            </li>
            <li>
              <p>
                <span class="contract__subtitle">4.1.2.2.</span> 10% (dez porcento) do valor global do contrato
                referente aos serviços da primeira etapa;
              </p>
            </li>
            <li>
              <p>
                <span class="contract__subtitle">4.1.2.3.</span> 20% (vinte porcento) do valor global do contrato
                referente aos serviços prestados na segunda etapa.
              </p>
            </li>
          </ul>
        </li>
        <li>
          <p>
            <span class="contract__subtitle">4.1.3.</span> Se a rescisão ocorrer por parte
            <ng-container *ngIf="!conjuge"> do <span class="contract__subtitle">CONTRATANTE</span> </ng-container>
            <ng-container *ngIf="conjuge"> dos <span class="contract__subtitle">CONTRATANTES</span> </ng-container> após
            o protocolo de REURB junto a municipalidade, os seguintes valores deverão ser pagos à
            <span class="contract__subtitle">USUCAMPEÃO</span>
          </p>

          <ul>
            <li>
              <p>
                <span class="contract__subtitle">4.1.3.1.</span> 20% (vinte porcento) do valor global do contrato por
                multa contratual;
              </p>
            </li>
            <li>
              <p>
                <span class="contract__subtitle">4.1.3.2.</span> 10% (dez porcento) do valor global do contrato
                referente aos serviços da primeira etapa;
              </p>
            </li>
            <li>
              <p>
                <span class="contract__subtitle">4.1.3.3.</span> 20% (vinte porcento) do valor global do contrato
                referentes aos serviços prestados na segunda etapa;
              </p>
            </li>
            <li>
              <p>
                <span class="contract__subtitle">4.1.3.4.</span> 20% (vinte porcento) do valor global do contrato
                referentes aos custos da terceira etapa.
              </p>
            </li>
          </ul>
        </li>
      </ul>
      <p>
        <span class="contract__subtitle">4.2.</span> No caso de rescisão por motivo de indeferimento do processo
        administrativo de REURB pela
        Prefeitura, <span class="contract__subtitle">por fato não atribuível à USUCAMPEÃO,</span> não haverá a
        restituição de nenhum dos
        valores pagos até o momento do indeferimento. A mesma condição se aplica em caso da negativa de registro do
        projeto de regularização fundiária por parte do Cartório de Registro de Imóveis, por fato também não
        atribuível
        à USUCAMPEÃO.
        <br />
      </p>
    </div>
    <div class="contract__clausula">
      <p>
        <span class="contract__subtitle">Cláusula Quinta – DISPOSIÇÕES GERAIS</span><br />
        <span class="contract__subtitle">5.1.</span> A <span class="contract__subtitle">USUCAMPEÃO</span> poderá ceder
        o
        recebível para instituições financeiras, ficando expressamente autorizada para tanto
        <ng-container *ngIf="!conjuge">pelo <span class="contract__subtitle">CONTRATANTE.</span></ng-container>
        <ng-container *ngIf="conjuge">pelos <span class="contract__subtitle">CONTRATANTES.</span></ng-container>
      </p>
      <p>
        <span class="contract__subtitle">5.2.</span> Nenhuma das Partes poderá ser considerada como inadimplente de
        suas
        obrigações contratuais se tal fato for resultante de caso fortuito ou força maior.
      </p>
      <p>


        <span class="contract__subtitle">5.3.</span> O Presente Contrato se refere exclusivamente ao serviço de
        regularização fundiária na esfera administrativa perante a Prefeitura.
      </p>
      <ul>
        <li>
          <p>
            <span class="contract__subtitle">5.3.1.</span> Não estão contempladas neste contrato, despesas com tributos
            inerentes à unidade imobiliária, taxas/preços públicos municipais e emolumentos de cartórios.
          </p>
        </li>
        <li>
          <p>
            <span class="contract__subtitle">5.3.2.</span> Não estão contemplados neste contrato, eventuais
            procedimentos
            judiciais que sejam necessários para a viabilização da regularização.
          </p>
        </li>
        <li>
          <p>
            <span class="contract__subtitle">5.3.3.</span> Conforme itens 3.2.1 e 3.2.2, a entrega da Certidão de
            Regularização Fundiária (CRF) está condicionada ao pagamento total dos serviços
            <ng-container *ngIf="!conjuge"> pelo <span class="contract__subtitle">CONTRATANTE</span> </ng-container>
            <ng-container *ngIf="conjuge"> pelos <span class="contract__subtitle">CONTRATANTES</span> </ng-container>.
          </p>
        </li>
      </ul>
    </div>
    <div class="contract__clausula">
      <p>
        <span class="contract__subtitle">Cláusula Sexta – FORO e Assinatura</span><br />
        <span class="contract__subtitle">6.1.</span> Para dirimir quaisquer controvérsias oriundas deste Contrato, as
        Partes elegem o Foro da Comarca de São Paulo, Estado de São Paulo.
      </p>
      <p>
        <span class="contract__subtitle">6.2.</span> As <span class="contract__subtitle">PARTES</span>, de forma
        irrevogável e irretratável, afirmam e declaram que o presente instrumento poderá ser assinado eletronicamente
        mediante: (i) impressão, assinatura e digitalização e reenvio deste termo; (ii) envio de código por e-mail
        cadastrado; (iii) envio de código por mensagem de celular ou Whatsapp cadastrado; ou (iv) envio de foto
        segurando o documento pessoal. Seja qual for a forma escolhida <ng-container *ngIf="!conjuge"> pelo <span
            class="contract__subtitle">CONTRATANTE</span> </ng-container> <ng-container *ngIf="conjuge"> pelos <span
            class="contract__subtitle">CONTRATANTES</span> </ng-container>, as <span
          class="contract__subtitle">PARTES</span> consideram ela válida e aceita,
        com fundamento no art. 10º, §2º da MP 2200-2/2001, e do art. 6º do Decreto 10.278/2020, sendo a forma que for,
        as assinaturas serão consideradas válidas, vinculantes e executáveis, desde que firmadas pelos representantes
        legais das <span class="contract__subtitle">PARTES</span>. As Partes renunciam à possibilidade de exigir a
        troca, envio ou entrega das vias originais
        (não-eletrônicas) assinadas do instrumento, bem como renunciam ao direito de recusar ou contestar a validade
        das
        assinaturas eletrônicas, na medida máxima permitida pela legislação aplicável reconhecem como válidas e
        eficazes
        as ferramentas de assinatura eletrônica aqui decididas e patcuadas. Adicionalmente, as Partes declaram-se
        cientes e concordam que este Instrumento assinado eletronicamente será considerado, para todos os efeitos,
        exequível, renunciando expressamente ao direito de impugnação de que trata o art. 225 do Código Civil.
      </p>
      <p>
        <span class="contract__subtitle">6.3.</span> As <span class="contract__subtitle">PARTES</span> declaram que o
        início efetivo da prestação de serviços, bem como de todas as demias obrigações das duas <span
          class="contract__subtitle">PARTES</span>, será contado da
        data do pagamento da primeira parcela, nos termos do <span class="contract__subtitle">ANEXO II</span>.
      </p>
      <p>{{ cadastro?.projetoMunicipio }}, {{ cadastro?.contractDate | date: 'longDate'}}.</p>
    </div>

    <div class="contract__subscription" *ngIf="isPrint">
      <div class="contract__subscription__table">
        <div class="contract__subscription__column">
          <p>______________________________________________</p>
          <p>CONTRATANTE/OUTORGANTE</p>
          <div class="contract__subscription__column__infos">
            <p>Representante: {{ proprietario?.name }}</p>
            <p>CPF: {{ proprietario?.cpf }}</p>
          </div>
        </div>
        <div class="contract__subscription__column contract__subscription__column--no-border-left">
          <img class="contract__ceo-subscription" alt="Assinatura CEO" src="/assets/images/assinatura.svg" />
          <p>______________________________________________</p>
          <p>NATHALIE ROMANO</p>
          <div class="contract__subscription__column__infos">
            <p>USUCAMPEÃO</p>
            <p>CNPJ: 36.174.049/0001-08</p>
          </div>
        </div>
        <div class="contract__subscription__column contract__subscription__column--no-border-top">
          <p>______________________________________________</p>
          <div class="contract__subscription__column__infos">
            <p>Testemunha 1: _______________________________</p>
            <p>CPF:</p>
          </div>
        </div>

        <div class="contract__subscription__column contract__subscription__column--no-border-top">
          <p>______________________________________________</p>
          <div class="contract__subscription__column__infos">
            <p>Testemunha 2: _______________________________</p>
            <p>CPF:</p>
          </div>
        </div>
      </div>
    </div>

    <div class="contract__attachment">
      <h1>
        ANEXO I<br />
        ESCOPO DO SERVIÇO
      </h1>

      <p>
        <span class="contract__subtitle">1.1.</span> Análise detalhada do imóvel e da posse, as medidas jurídicas,
        urbanísticas, ambientais e sociais com a finalidade de incorporar o imóvel informal ao ordenamento territorial
        urbano do município com a sua consequente titulação.
      </p>
      <p><span class="contract__subtitle">1.2.</span> Ingresso com processo administrativo de REURB, perante a
        municipalidade;</p>
      <p>
        <span class="contract__subtitle">1.3.</span> Elaboração do Projeto de Regularização Fundiária, conforme o artigo
        30 do Decreto Federal nº 9.310 de 2018, e artigo 35 da Lei Federal 13.465 de 16 de julho de 2017:
      </p>
      <ul>
        <li>
          <p>
            <span class="contract__subtitle">1.3.1.</span> Levantamento planialtimétrico e cadastral com
            georreferenciamento, que demonstrará as unidades, as construções, o sistema viário, as áreas públicas, os
            acidentes geográficos e os demais elementos caracterizadores do núcleo a ser regularizado;
          </p>
        </li>
        <li>
          <p>
            <span class="contract__subtitle">1.3.2.</span> Estudo das desconformidades e da situação jurídica,
            urbanística
            e ambiental;
          </p>
        </li>
        <li>
          <p>
            <span class="contract__subtitle">1.3.3.</span> Projeto urbanístico;
          </p>
        </li>
        <li>
          <p>
            <span class="contract__subtitle">1.3.4.</span> Memorial descritivo do imóvel;
          </p>
        </li>
        <li>
          <p>
            <span class="contract__subtitle"> 1.3.5.</span> Elaboração de cronograma físico de serviços e de obras de
            infraestrutura essencial, compensações urbanísticas, ambientais, com orientação referente às
            responsabilidades
            decorrentes da Assinatura de Termo de Compromisso se for o caso;
          </p>
        </li>
        <li>
          <p>
            <span class="contract__subtitle">1.3.6.</span> Toda a <span class="contract__subtitle"><u>gestão de processo
                administrativo</u></span> poderá ser acompanhada
            pelo site: <span class="contract__subtitle"><u>www.usucampeao.com.br</u></span> com as seguintes
            funcionalidades:
          </p>

          <ul>
            <li>
              <p>
                <span class="contract__subtitle">1.3.6.1.</span> Sistema de gestão seguro com <i>login</i> e senha na
                Plataforma digital;
              </p>
            </li>
            <li>
              <p>
                <span class="contract__subtitle">1.3.6.2.</span> Possibilidade de realizar o cadastramento dos dados
                pessoais
                e
                da posse da
                propriedade;
              </p>
            </li>
            <li>
              <p>
                <span class="contract__subtitle">1.3.6.3.</span> Serviços de mensagem para fornecer informações sobre o
                andamento do processo com os
                ocupantes;
              </p>
            </li>
            <li>
              <p>
                <span class="contract__subtitle">1.3.6.4.</span> Serviços de inclusão de proprietarios pessoais e que
                comprovam
                a posse das
                propriedades;
              </p>
            </li>
            <li>
              <p>
                <span class="contract__subtitle"> 1.3.6.5.</span> Sistema de meios de pagamento dos valores do serviço
                através
                da plataforma, através
                do cartão de crédito e boleto bancário;
              </p>
            </li>
          </ul>
        </li>
      </ul>



    </div>

    <div class="contract__attachment">
      <h1>
        ANEXO II<br />
        DISPOSIÇÕES FINANCEIRAS
      </h1>

      <div class="contract__clausula">
        <p class="contract__subtitle">Cláusula Primeira – OBJETO </p>

        <p>
          <span class="contract__subtitle">1.1</span> Este Termo estabelece as DISPOSIÇÕES FINANCEIRAS entre
          USUCAMPEÃO e CONTRATANTES para realização dos serviços de regularização fundiária “REURB”, celebrados no
          respectivo CONTRATO.
        </p>

        <p>
          <span class="contract__subtitle">1.2</span> Pela prestação dos Serviços objeto do CONTRATO firmado entre as
          PARTES, os CONTRATANTES pagarão a quantia
          total de {{ cadastro.valorServico | currency: 'BRL' }}
          ({{cadastro?.valorServico |
          numeroExtenso: numeroExtensoTipo.MONETARIO}}).
        </p>

        <p>
          <span class="contract__subtitle">1.3</span> Será aplicado o DESCONTO de {{ cadastro.descontoPorcentagem || 0
          }}% <ng-container *ngIf="cadastro.cupomDescontoCodigo">
          {{ cadastro.cupomDescontoTipo === 'ENTRADA' ? 'na entrada' : 'no serviço' }} limitado a {{ cadastro.cupomDescontoLimite | currency: 'BRL' }}
        </ng-container>,
          referente a:
        </p>
        <ul>
          <li>
            Evento [ ]
          </li>
          <li>
            Campanha [ ]
          </li>
          <li>
            Cupom [ <ng-container *ngIf="cadastro.cupomDescontoCodigo">X</ng-container> ] <ng-container *ngIf="cadastro.cupomDescontoCodigo"> <span class="contract__cupom-codigo">{{ cadastro.cupomDescontoCodigo }}</span></ng-container>
          </li>
          <li>
            Pagamento à vista [ <ng-container *ngIf="isPagamentoAVista">X</ng-container> ]
          </li>
        </ul>

        <p>
          <span class="contract__subtitle">1.4</span> A quantia paga seguirá a a forma de cálculo da Tabela de
          Amortização (Tabela PRICE) apresentada no item 2.2
          deste anexo e acessivel no app e site.
        </p>

        <p>
          <span class="contract__subtitle">1.5</span> A forma de pagamento será:
        </p>

        <ul>
          <li>
            Boleto Bancário [ <ng-container *ngIf="cadastro.meioPagamento === meioPagamento.BOLETO">X</ng-container> ]
          </li>
          <li>
            Carnê [ ]
          </li>
          <li>
            Dinheiro [ <ng-container *ngIf="cadastro.meioPagamento === meioPagamento.PIX">X</ng-container> ]
          </li>
          <li>
            Cartão [ <ng-container *ngIf="cadastro.meioPagamento === meioPagamento.CARTAO_CREDITO">X</ng-container> ]
          </li>
        </ul>

        <p>
          <span class="contract__subtitle">1.6</span> A consulta da situação financeira para com os pagamentos poderá
          ser acessada pelo app ou site da empresa ou
          ainda, a pedido dos <span class="contract__subtitle">CONTRATANTES</span> via:
        </p>

        <ul>
          <li>Whatsapp para o número (11) 97123-3055; ou</li>
          <li>E-mail para o endereço contato@usucampeao.com.br</li>
        </ul>

      </div>

      <div class="contract__clausula">
        <p class="contract__subtitle">
          Cláusula Segunda – PARÂMETROS
        </p>

        <p>
          <span class="contract__subtitle">2.1.</span> Tabela de valores:
        </p>

        <usucampeao-tabela-valores [cadastro]="cadastro"></usucampeao-tabela-valores>
      </div>

      <div class="contract__attachment">
        <p>
          <span class="contract__subtitle">2.2.</span> Tabela de amortização
        </p>

        <usucampeao-tabela-pagamentos [pagamentos]="cadastro.pagamentos" [valorServico]="cadastro.valorServico"
          [valorDesconto]="cadastro.valorDesconto"></usucampeao-tabela-pagamentos>
      </div>
    </div>
  </div>
</div>
