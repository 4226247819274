import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ImovelEnderecoInformacoesAdicionaisDto, ProjetoDto } from '@usucampeao/lib-reurb-simplificado';
import { AbstractForm } from '@usucampeao/utils-frontend';
import { EnderecoFormComponent } from '../../../components/forms/endereco-form/endereco-form.component';

@Component({
  selector: 'usucampeao-dados-imovel',
  templateUrl: './dados-imovel.component.html',
  styleUrls: ['./dados-imovel.component.scss']
})
export class DadosImovelComponent extends AbstractForm implements OnInit, OnChanges {
  @ViewChild('enderecoForm', { static: true }) enderecoForm: EnderecoFormComponent;

  @Input() dadosImovel: ImovelEnderecoInformacoesAdicionaisDto;
  @Input() projeto: Partial<ProjetoDto>;

  @Output() aoSubmeter = new EventEmitter<ImovelEnderecoInformacoesAdicionaisDto>();

  public rotaAnterior = '../selecionar-lote';

  constructor(
    private fb: FormBuilder,
  ) {
    super();
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      address: this.enderecoForm.buildFormGroup(),
      possuiOutroImovel: [null, Validators.required],
      situation: [null, Validators.required],
    });
    if (this.dadosImovel && this.dadosImovel.address) {
      this.preencherForm();
    }
  }

  ngOnChanges(): void {
    if (this.form) {
      this.preencherForm();
    }
  }

  private preencherForm(): void {
    this.form.patchValue({
      address: this.dadosImovel.address,
      possuiOutroImovel: this.dadosImovel.possuiOutroImovel,
      situation: this.dadosImovel.situation,
    });
  }

  public submit(): void {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }

    this.aoSubmeter.emit(this.form.value);
  }
}
