export enum OwnerDocument {
  RG = 'RG',
  CPF = 'CPF',
  CNH = 'CNH',
  BIRTH_CERTIFICATE = 'CERTIDAO_DE_NASCIMENTO',
  MARRIAGE_CERTIFICATE = 'CERTIDAO_DE_CASAMENTO',
  DIVORCE_CERTIFICATE = 'CERTIDAO_DE_DIVORCIO',
  DEATH_CERTIFICATE = 'CERTIDAO_DE_OBITO',
}

export const DOCUMENTOS_IDENTIDADE: OwnerDocument[] = [
  OwnerDocument.RG,
  OwnerDocument.CPF,
  OwnerDocument.CNH,
];

export const DOCUMENTOS_ESTADO_CIVIL: OwnerDocument[] = [
  OwnerDocument.BIRTH_CERTIFICATE,
  OwnerDocument.MARRIAGE_CERTIFICATE,
  OwnerDocument.DIVORCE_CERTIFICATE,
  OwnerDocument.DEATH_CERTIFICATE,
];
