import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GetUpdatePropertyAdditionalInformationsDto, PropertyOccupancy, PropertySituation, PropertyUsage } from '@usucampeao/lib-reurb-simplificado';
import { AbstractForm, OnlyNumbers } from '@usucampeao/utils-frontend';

@Component({
  selector: 'usucampeao-imovel-infos-adicionais-form-v2',
  templateUrl: './imovel-infos-adicionais-form-v2.component.html',
  styleUrls: ['./imovel-infos-adicionais-form-v2.component.scss']
})
export class ImovelInfosAdicionaisFormV2Component extends AbstractForm {
  @Input() readonly = false;

  public imovelUsos = Object.values(PropertyUsage);
  public imovelOcupacoes = Object.values(PropertyOccupancy);

  constructor(
    private fb: FormBuilder
  ) {
    super();
  }

  public get imovelSituacao(): typeof PropertySituation {
    return PropertySituation;
  }

  public buildFormGroup(props?: GetUpdatePropertyAdditionalInformationsDto): FormGroup {
    this.form = this.fb.group({
      usage: [props?.usage, Validators.required],
      occupation: [props?.occupation, Validators.required],
      situation: [props?.situation, Validators.required],
      loteArea: [props?.loteArea, [OnlyNumbers]],
      possuiOutroImovel: [props?.possuiOutroImovel, Validators.required]
    });

    return this.form;
  }
}
