import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { DadosImovelModule } from '@usucampeao/ui-mobile';
import { EnderecoInfosAdicionaisRoutingModule } from './endereco-infos-adicionais-routing.module';
import { EnderecoInfosAdicionaisPage } from './endereco-infos-adicionais.page';


@NgModule({
  declarations: [
    EnderecoInfosAdicionaisPage
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,

    // route
    EnderecoInfosAdicionaisRoutingModule,

    // components
    DadosImovelModule,
  ]
})
export class EnderecoInfosAdicionaisPageModule { }
