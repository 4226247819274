<section class="endereco-infos">
  <usucampeao-header [backRoute]="rotaAnterior" title="Novo Cadastro"></usucampeao-header>

  <form [formGroup]="form" (ngSubmit)="submit()">
    <ion-text>
      <h3 class="description-text description-text--medium">Endereço</h3>
    </ion-text>

    <ng-container formGroupName="address">
      <usucampeao-endereco-form #enderecoForm [bairro]="projeto?.localizacao?.bairro"
        [cidade]="projeto?.localizacao?.cidade" [estado]="projeto?.localizacao?.estado"></usucampeao-endereco-form>
    </ng-container>

    <div class="radio">
      <ion-label class="description-text description-text--medium">
        Possui imóvel construído no terreno?
      </ion-label>
      <ion-item class="ion-no-padding" mode="md" lines="none">
        <ion-radio-group formControlName="situation">
          <ion-item class="ion-no-padding" lines="none">
            <ion-radio color="secondary" value="EDIFICADO" class="ion-margin-end"></ion-radio>
            <ion-label class="ion-text-wrap">Sim</ion-label>
          </ion-item>
          <ion-item class="ion-no-padding" lines="none">
            <ion-radio color="secondary" value="SEM_EDIFICACAO" class="ion-margin-end"></ion-radio>
            <ion-label class="ion-text-wrap">Não</ion-label>
          </ion-item>
          <ion-item class="ion-no-padding" lines="none">
            <ion-radio color="secondary" value="EM_CONSTRUCAO" class="ion-margin-end"></ion-radio>
            <ion-label class="ion-text-wrap">Em construção</ion-label>
          </ion-item>
        </ion-radio-group>
      </ion-item>
      <span class="form-message-error form-message-error--radio-button">{{ gerarMensagemErro('situation') }}</span>
    </div>

    <div class="radio">
      <ion-label class="description-text description-text--medium">
        Possui outra propriedade no bairro?
      </ion-label>
      <ion-item class="ion-no-padding" mode="md" lines="none">
        <ion-radio-group formControlName="possuiOutroImovel">
          <ion-item class="ion-no-padding" lines="none">
            <ion-radio color="secondary" [value]="true" class="ion-margin-end"></ion-radio>
            <ion-label>Sim</ion-label>
          </ion-item>
          <ion-item class="ion-no-padding" lines="none">
            <ion-radio color="secondary" [value]="false" class="ion-margin-end"></ion-radio>
            <ion-label>Não</ion-label>
          </ion-item>
        </ion-radio-group>
      </ion-item>
      <span class="form-message-error form-message-error--radio-button">{{ gerarMensagemErro('possuiOutroImovel') }}</span>
    </div>
  </form>

  <usucampeao-footer>
    <ion-button id="novo-cadastro-endereco-enviar" mode="md" class="ion-margin-top" (click)="submit()">
      Continuar
    </ion-button>
  </usucampeao-footer>
</section>
