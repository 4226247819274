import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ProprietarioFichaDto } from '../../../../domain';

@Component({
  selector: 'usucampeao-proprietarios-ficha-v2',
  templateUrl: './proprietarios-ficha-v2.component.html',
  styleUrls: ['./proprietarios-ficha-v2.component.scss']
})
export class ProprietariosFichaV2Component {
  @Input() set proprietarios(proprietarios: ProprietarioFichaDto[]) {
    this.proprietarioPrincipal = proprietarios?.find(proprietario => proprietario.main);
    this.conjuge = proprietarios?.find(proprietario => !proprietario.main);
  }

  @Output() aoAdicionarConjuge = new EventEmitter<string>();
  @Output() aoRemoverConjuge = new EventEmitter<string>();

  public proprietarioPrincipal: ProprietarioFichaDto;
  public conjuge: ProprietarioFichaDto;

  public adicionarConjuge(proprietarioId: string): void {
    this.aoAdicionarConjuge.emit(proprietarioId);
  }

  public removerConjuge(proprietarioId: string): void {
    this.aoRemoverConjuge.emit(proprietarioId);
  }
}
