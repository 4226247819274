import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'usucampeao-modal-termos-uso',
  templateUrl: './modal-termos-uso.component.html',
  styleUrls: ['./modal-termos-uso.component.scss']
})
export class ModalTermosUsoComponent {

  constructor(
    private modalCtrl: ModalController,
  ) { }

  public dismiss(): void {
    this.modalCtrl.dismiss();
  }
}
