<section class="d-lista">
  <usucampeao-header [title]="title"></usucampeao-header>

  <div class="d-lista__alteracoes">
    <usucampeao-cadastro-status *ngIf="mostrarAlteracoes" [status]="cadastroStatus" [alteracoes]="alteracoes"></usucampeao-cadastro-status>
  </div>

  <ion-text class="font-text">
    <ng-content select="[description]"></ng-content>
  </ion-text>

  <div class="d-lista__items">
    <ng-content select="[list]"></ng-content>
  </div>

  <ion-text class="ion-text-center">
    <p class="font-text font-text--small">Precisa de ajuda? <span class="d-lista__link" (click)="abrirModalInstrucoes()">Veja as instruções</span></p>
  </ion-text>

  <usucampeao-footer>
    <ion-button mode="md" class="ion-margin-bottom" (click)="adicionarDocumento()">Adicionar documento</ion-button>
    <ion-button mode="md" fill="outline" (click)="voltar()">Continuar</ion-button>
  </usucampeao-footer>
</section>
