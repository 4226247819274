import { PermissaoStatus } from './permissao-status.enum';

export class PermissaoDto {
  id: number;
  nome: string;
  descricao: string;
  status: PermissaoStatus;
  dataCriacao: Date;
  usuarioCriacaoId: number;
  dataAtualizacao: Date;
  usuarioAtualizacaoId: number;
}
