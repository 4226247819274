import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { FooterModule, HeaderModule, InputModule } from '../../../components';
import { ImovelInfosAdicionaisFormModule } from '../../../components/forms';
import { EnderecoFormModule } from '../../../components/forms/endereco-form/endereco-form.module';
import { EnderecoInfosAdicionaisComponent } from './endereco-infos-adicionais.component';

@NgModule({
  declarations: [
    EnderecoInfosAdicionaisComponent,
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,
    ReactiveFormsModule,

    // components
    EnderecoFormModule,
    FooterModule,
    ImovelInfosAdicionaisFormModule,
    InputModule,
    HeaderModule,
  ],
  exports: [
    EnderecoInfosAdicionaisComponent,
  ]
})
export class EnderecoInfosAdicionaisModule { }
