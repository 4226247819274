import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CreateUserDto, Gender } from '@usucampeao/lib-reurb-simplificado';
import { validateCPF } from '@usucampeao/ui-mobile';
import { AbstractForm } from '@usucampeao/utils-frontend';
import { subYears } from 'date-fns';


@Component({
  selector: 'usucampeao-usuario-form',
  templateUrl: './usuario-form.component.html',
  styleUrls: ['./usuario-form.component.scss']
})
export class UsuarioFormComponent extends AbstractForm implements OnInit, OnChanges {
  @Input() usuario: CreateUserDto;
  @Input() editavel = true;
  @Output() aoSubmeter = new EventEmitter<CreateUserDto>();

  public readonly maxDate = subYears(new Date(), 18);
  public readonly generos = [
    { value: Gender.MASCULINE, label: 'Masculino' },
    { value: Gender.FEMININE, label: 'Feminino' },
    { value: Gender.OTHERS, label: 'Outro' },
  ];

  constructor(private fb: FormBuilder) {
    super();

    this.form = this.fb.group({
      name: [null, Validators.compose([Validators.required, Validators.pattern('^[A-zÀ-ú ]*$')])],
      cpf: [null, Validators.compose([Validators.required, validateCPF])],
      birthDate: [null, Validators.required],
      gender: [null, Validators.required],
      email: [null, Validators.email],
    });
  }

  ngOnInit(): void {
    this.preencherForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { usuario } = changes;
    if (usuario && usuario.currentValue) {
      this.preencherForm();
    }
  }

  private preencherForm(): void {
    if (!this.usuario) {
      return;
    }

    this.form.patchValue({
      name: this.usuario.name,
      cpf: this.usuario.cpf,
      birthDate: this.usuario.birthDate,
      gender: this.usuario.gender,
      email: this.usuario.email
    });
  }

  public onSubmit(): void {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }

    this.aoSubmeter.emit(this.form.value);
  }
}
