import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { ImoveisFichaModule } from '@usucampeao/ui-mobile';
import { ImoveisRoutingModule } from './imoveis-routing.module';
import { ImoveisPage } from './imoveis.page';


@NgModule({
  declarations: [
    ImoveisPage
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,

    // route
    ImoveisRoutingModule,

    // components
    ImoveisFichaModule,
  ]
})
export class ImoveisPageModule { }
