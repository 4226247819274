import { Nationality, UF } from '@usucampeao/interfaces';
import {
  DocumentFileDto,
  Gender,
  MaritalStatus,
  MatrimonialRegime,
  OwnerFillInStatusDto,
  OwnerStatus,
  PhoneDto,
  RendaFamiliar,
  RgDto
} from '@usucampeao/lib-reurb-simplificado';
import { Expose, plainToInstance } from 'class-transformer';

export interface ProprietarioStateDto {
  id: string;
  cadastroId: string;
  name?: string;
  cpf?: string;
  rg?: RgDto;
  email?: string;
  phones?: PhoneDto;
  contactName?: string;
  gender?: Gender;
  birthDate?: Date;
  nationality?: Nationality;
  state?: UF;
  naturalidade?: string;
  maritalStatus?: MaritalStatus;
  matrimonialRegime?: MatrimonialRegime;
  weddingDate?: Date;
  profession?: string;
  familyIncome?: number;
  spouseId?: string;
  userId?: string;
  photoId?: string;
  status?: OwnerStatus;
  main?: boolean;
  fillInStatus?: OwnerFillInStatusDto;
  proprietarioId?: string;
  documentos?: DocumentFileDto[];
  rendaFamiliar?: RendaFamiliar;
}

export class ProprietarioFichaDto {
  @Expose()
  id: string;

  @Expose()
  name: string;

  @Expose()
  maritalStatus?: MaritalStatus;

  @Expose()
  fillInStatus: OwnerFillInStatusDto;

  @Expose()
  main?: boolean;

  @Expose()
  spouseId?: string;

  static from(props: Partial<ProprietarioStateDto>): ProprietarioFichaDto {
    return plainToInstance(ProprietarioFichaDto, props, { exposeUnsetFields: false, excludeExtraneousValues: true });
  }
}
