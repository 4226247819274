import { PaginationLinks } from './pagination-links.interface';
import { PaginationMeta } from './pagination-meta.interface';

export class Pagination<Item, Metadata = any> {
  items: Item[];
  links: PaginationLinks;
  meta: PaginationMeta<Metadata>;

  constructor(items: Item[], meta: PaginationMeta<Metadata>, links: PaginationLinks) {
    this.items = items;
    this.meta = meta;
    this.links = links;
  }
}
