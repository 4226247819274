import { Pipe, PipeTransform } from '@angular/core';
import { PROJETO_STATUS_EM_ANDAMENTO, ProjetoSimplificadoDto, ProjetoStatus, RegistrationStatus } from '@usucampeao/lib-reurb-simplificado';
import { CadastroFichaDto } from '../../domain';

@Pipe({
  name: 'cadastroFichaStatusTitulo'
})
export class CadastroFichaTituloPipe implements PipeTransform {

  transform(value: RegistrationStatus, cadastro?: CadastroFichaDto, projeto?: ProjetoSimplificadoDto): string {
    switch (value) {
      case RegistrationStatus.INCOMPLETE:
        return 'Cadastro incompleto';

      case RegistrationStatus.CHANGES_REQUESTED:
        return 'Alterações necessárias';

      case RegistrationStatus.READY_TO_SUBMIT:
        return 'Envie cadastro preenchido';

      case RegistrationStatus.PRONTO_PARA_FECHAR_CONTRATO:
        return 'Pronto para fechar contrato';

      case RegistrationStatus.AGUARDANDO_ASSINATURA_CONTRATO:
        if (cadastro?.contratoLinkAssinadoPelaUsucampeao) {
          return 'Aguardando assinatura do proprietário';
        }

        if (cadastro?.contratoLinkAssinadoPeloProprietario) {
          return 'Aguardando assinatura da UsuCampeão';
        }

        return 'Aceite de contrato pendente';

      case RegistrationStatus.CONTRATO_FECHADO:
        return 'Contrato fechado';

      case RegistrationStatus.IN_ANALYSIS:
        return 'Cadastro em análise';

      case RegistrationStatus.DISTRATO:
        return 'Distrato de contrato';

      case RegistrationStatus.CANCELED:
        return 'Cadastro cancelado';

      default:
        if (PROJETO_STATUS_EM_ANDAMENTO.includes(projeto?.status)) {
          return this.definirTituloPeloStatusDoProjeto(projeto);
        }

        return 'Cadastro validado';
    }
  }

  private definirTituloPeloStatusDoProjeto(projetoStatus: ProjetoSimplificadoDto): string {
    switch (projetoStatus.status) {
      case ProjetoStatus.INSTAURACAO_NUCLEO:
        return 'Instauração do núcleo';

      case ProjetoStatus.RELATORIO_TECNICO_E_LEPAC:
        return 'Relatório técnico e LEPAC';

      case ProjetoStatus.CADASTRO_SOCIAL_E_PERIMETRO_FINAL:
        return 'Cadastro social e perímetro final';

      case ProjetoStatus.PROJETO_URBANISTICO:
        return 'Projeto urbanístico';

      case ProjetoStatus.PROJETO_ENVIADO_A_PREFEITURA:
        return 'Projeto enviado à prefeitura';

      case ProjetoStatus.ATENDENDO_DEVOLUTIVAS_DA_PREFEITURA:
        return 'Atendendo devolutivas da prefeitura';

      case ProjetoStatus.APROVADO_PELA_PREFEITURA:
        return 'Aprovado na prefeitura';

      case ProjetoStatus.REGISTRADO_EM_CARTORIO:
        return 'Registrado em cartório';

      case ProjetoStatus.ATENDENDO_DEVOLUTIVAS_DO_CARTORIO:
        return 'Atendendo devolutivas do cartório';

      case ProjetoStatus.TITULO_EMITIDO:
        return 'Venha buscar seu título!';

      case ProjetoStatus.TITULO_ENTREGUE:
        return 'Títulos entregues';
    }
  }
}
