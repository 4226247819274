import { IsEmail, IsEnum, IsNotEmpty, IsOptional, IsString, Length } from 'class-validator';
import { UsuarioBackofficeTipo } from '.';

export class UsuarioBackofficeUpdateDto {
  @IsString()
  @IsNotEmpty()
  nome: string;

  @IsString()
  @Length(10, 13)
  @IsNotEmpty()
  telefone?: string;

  @IsEmail()
  @IsOptional()
  email: string;

  @IsEnum(UsuarioBackofficeTipo)
  @IsNotEmpty()
  tipo: UsuarioBackofficeTipo;
}
