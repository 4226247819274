import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';

import { ProprietariosDocumentosModule } from '@usucampeao/ui-mobile';
import { ProprietariosDocumentosRoutingModule } from './proprietarios-documentos-routing.module';
import { ProprietariosDocumentosPage } from './proprietarios-documentos.page';

@NgModule({
  declarations: [
    ProprietariosDocumentosPage
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,

    // route
    ProprietariosDocumentosRoutingModule,

    // components
    ProprietariosDocumentosModule,
  ]
})
export class ProprietariosDocumentosPageModule { }
