export enum CadastroHistoricoTipo {
  CADASTRO_CRIAR = 'CADASTRO_CRIAR',
  CADASTRO_ALTERAR = 'CADASTRO_ALTERAR',
  CADASTRO_ALTERAR_VENDEDOR_RESPONSAVEL = 'CADASTRO_ALTERAR_VENDEDOR_RESPONSAVEL',
  CADASTRO_CANCELAR = 'CADASTRO_CANCELAR',
  CADASTRO_CONTRATO_ASSINATURA_SOLICITADA = 'CADASTRO_CONTRATO_ASSINATURA_SOLICITADA',
  CADASTRO_CONTRATO_ASSINATURA_TIPO = 'CADASTRO_CONTRATO_ASSINATURA_TIPO',
  CADASTRO_CONTRATO_LINK_TIPO_ENVIO = 'CADASTRO_CONTRATO_LINK_TIPO_ENVIO',
  CADASTRO_CONTRATO_LINK_DOCUMENTO_CRIADO = 'CADASTRO_CONTRATO_LINK_DOCUMENTO_CRIADO',
  CADASTRO_CONTRATO_LINK_REENVIADO = 'CADASTRO_CONTRATO_LINK_REENVIADO',
  CADASTRO_CONTRATO_LINK_ASSINADO_PELO_PROPRIETARIO = 'CADASTRO_CONTRATO_LINK_ASSINADO_PELO_PROPRIETARIO',
  CADASTRO_CONTRATO_LINK_ASSINADO_PELA_USUCAMPEAO = 'CADASTRO_CONTRATO_LINK_ASSINADO_PELA_USUCAMPEAO',
  CADASTRO_CONTRATO_ASSINADO = 'CADASTRO_CONTRATO_ASSINADO',
  CADASTRO_DISTRATO = 'CADASTRO_DISTRATO',
  CADASTRO_PAGAMENTO_EMITIR = 'CADASTRO_PAGAMENTO_EMITIR',
  CADASTRO_PAGAMENTO_ALTERAR = 'CADASTRO_PAGAMENTO_ALTERAR',
  CADASTRO_PAGAMENTO_ALTERAR_VENCIMENTO = 'CADASTRO_PAGAMENTO_ALTERAR_VENCIMENTO',
  CADASTRO_REVISAO_CANCELAR = 'CADASTRO_REVISAO_CANCELAR',
  CADASTRO_REVISAO_ENVIAR = 'CADASTRO_REVISAO_ENVIAR',
  CADASTRO_REVISAO_INICIAR = 'CADASTRO_REVISAO_INICIAR',
  CADASTRO_REVISAO_FINALIZAR = 'CADASTRO_REVISAO_FINALIZAR',
  CADASTRO_STATUS_ALTERAR = 'CADASTRO_STATUS_ALTERAR',
  DOCUMENTO_ARQUIVO_ADICIONAR = 'DOCUMENTO_ARQUIVO_ADICIONAR',
  DOCUMENTO_ARQUIVO_REMOVER = 'DOCUMENTO_ARQUIVO_REMOVER',
  IMOVEL_ALTERAR = 'IMOVEL_ALTERAR',
  IMOVEL_DOCUMENTO_CRIAR = 'IMOVEL_DOCUMENTO_CRIAR',
  IMOVEL_DOCUMENTO_REMOVER = 'IMOVEL_DOCUMENTO_REMOVER',
  PROPRIETARIO_ALTERAR = 'PROPRIETARIO_ALTERAR',
  PROPRIETARIO_REMOVER = 'PROPRIETARIO_REMOVER',
  PROPRIETARIO_DOCUMENTO_CRIAR = 'PROPRIETARIO_DOCUMENTO_CRIAR',
  PROPRIETARIO_DOCUMENTO_REMOVER = 'PROPRIETARIO_DOCUMENTO_REMOVER',
}

export enum CadastroHistoricoTipoLegacy { // TODO: Realizar migração de dados legados
  CRIAR_CADASTRO = 'CRIAR_CADASTRO',
  ALTERAR_CADASTRO = 'ALTERAR_CADASTRO',
  CANCELAR_CADASTRO = 'CANCELAR_CADASTRO',
  ALTERAR_PROPRIETARIO = 'ALTERAR_PROPRIETARIO',
  ALTERAR_IMOVEL = 'ALTERAR_IMOVEL',
  CRIAR_DOCUMENTO = 'CRIAR_DOCUMENTO',
  ALTERAR_DOCUMENTO = 'ALTERAR_DOCUMENTO',
}
