<section class="cadastro-ficha">
  <div class="ion-padding">
    <usucampeao-header title="Ver cadastro"></usucampeao-header>

    <usucampeao-upload-foto-imovel [urlImagem]="fotoFachadaImovel" [isV2]="true"
      (aoRealizarUpload)="adicionarFotoFachada($event)"></usucampeao-upload-foto-imovel>

    <div class="cadastro-ficha__infos ion-margin-top">
      <ion-text>
        <h2 class="title title--medium">{{ cadastro.projetoNome }}</h2>
      </ion-text>

      <div class="cadastro-ficha__flex ion-margin-bottom">
        <ion-icon class="cadastro-ficha__infos__icon" name="map-outline" color="medium"></ion-icon>
        <ion-text>
          <span class="description-text description-text--medium">
            Quadra {{ cadastro.quadra }} Lote {{ cadastro.lote }}</span>
        </ion-text>
      </div>

      <div class="cadastro-ficha__flex ion-margin-bottom">
        <ion-icon class="cadastro-ficha__infos__icon" name="location-outline" color="medium"></ion-icon>
        <ion-text>
          <span class="description-text description-text--medium">
            {{ imovel?.address | address }}
          </span>
        </ion-text>
      </div>

      <div *ngIf="cadastro.status === cadastroStatusLista.VALIDATED" class="cadastro-ficha__flex ion-margin-bottom">
        <ion-icon class="cadastro-ficha__infos__icon" name="checkmark-circle" color="success"></ion-icon>
        <ion-text>
          <span class="description-text description-text--medium">
            Cadastro validado
          </span>
        </ion-text>
      </div>
    </div>

    <usucampeao-pagamentos-resumo *ngIf="mostrarResumoPagamento" [valorEntrada]="cadastro.valorEntrada"
      [valorContrato]="cadastro.valorContrato" [valorParcela]="cadastro.valorParcela"
      [quantidadeParcelas]="cadastro.totalParcelas" [quantidadePagamentosNaoPagos]="quantidadePagamentosNaoPagos">
    </usucampeao-pagamentos-resumo>
  </div>

  <usucampeao-cadastro-status [cadastro]="cadastro" [projeto]="projeto" [status]="cadastro.status" [revisaoComentario]="revisao?.comentario"
    [alteracoes]="revisao?.alteracoes" (aoAlterarContato)="aoAlterarContato.emit()"></usucampeao-cadastro-status>

  <div class="ion-padding">
    <div class="cadastro-ficha__items ion-margin-bottom">
      <usucampeao-menu-item-v2 icone="home" titulo="Imóvel" [status]="imovelStatus"
        (click)="navegarPara('imovel/' + cadastro.propertyId)">
      </usucampeao-menu-item-v2>

      <usucampeao-menu-item-v2 icone="people" titulo="Proprietários" [status]="proprietariosStatus"
        (click)="navegarPara('proprietarios')">
      </usucampeao-menu-item-v2>

      <usucampeao-menu-item-v2 *ngIf="cadastro?.contractDate" icone="document" titulo="Outros Documentos"
        [status]="cadastroStatus" (click)="navegarPara('documentos')">
      </usucampeao-menu-item-v2>

      <usucampeao-menu-item-v2  icone="map" [titulo]="'Quadra ' + cadastro.quadra + ' Lote ' + cadastro.lote"
      [status]="quadraLoteStatus" (click)="navegarPara('imovel/' + cadastro.propertyId + '/mapa')">
    </usucampeao-menu-item-v2>

      <usucampeao-menu-item-v2 icone="chatbubble-ellipses" titulo="Observação"
        (click)="navegarPara('comentarios')">
      </usucampeao-menu-item-v2>
    </div>

    <usucampeao-footer>
      <ion-button [id]="idBotaoFluxoContinuar" mode="md" expand="block" *ngIf="textoBotao" (click)="continuar()">
        {{ textoBotao }}
      </ion-button>
      <ion-text class="ion-text-center">
        <p class="font-text font-text--small ion-no-margin ion-margin-top">
          Continue preenchendo o cadastro.
        </p>
        <p class="font-text font-text--small">
          Em caso de dúvida, <b>entre em contato:</b>
        </p>
      </ion-text>

      <a class="cadastro-ficha__whatsapp"
        href="https://wa.me/5511971233055?text=Olá! Estou me cadastrando no aplicativo da UsuCampeão para regularizar meu imóvel, e preciso de ajuda."
        target="blank">
        <ion-icon name="logo-whatsapp"></ion-icon>
        Preciso de ajuda
      </a>
    </usucampeao-footer>
  </div>
</section>
