export enum TipoNotificacao {
  CADASTRO_PRONTO_PARA_FECHAR_CONTRATO = 'CADASTRO_PRONTO_PARA_FECHAR_CONTRATO',
  CADASTRO_CONTRATO_EXPIRADO = 'CADASTRO_CONTRATO_EXPIRADO',
  CADASTRO_CONTRATO_FECHADO = 'CADASTRO_CONTRATO_FECHADO',
  CADASTRO_EM_ANALISE = 'CADASTRO_EM_ANALISE',
  CADASTRO_ALTERACOES_NECESSARIAS = 'CADASTRO_ALTERACOES_NECESSARIAS',
  CADASTRO_VALIDADO = 'CADASTRO_VALIDADO',
  BOLETO_VENCE_AMANHA = 'BOLETO_VENCE_AMANHA',
  BOLETO_VENCE_AMANHA_DEPRECATED = 'VENCE_AMANHA',
  BOLETO_VENCE_PROXIMO = 'BOLETO_VENCE_PROXIMO',
  BOLETO_VENCE_PROXIMO_DEPRECATED = 'VENCE_PROXIMO',
  PROJETO_INSTAURACAO_NUCLEO = 'PROJETO_INSTAURACAO_NUCLEO',
  PROJETO_RELATORIO_TECNICO_E_LEPAC = 'PROJETO_RELATORIO_TECNICO_E_LEPAC',
  PROJETO_CADASTRO_SOCIAL_E_PERIMETRO_FINAL = 'PROJETO_CADASTRO_SOCIAL_E_PERIMETRO_FINAL',
  PROJETO_PROJETO_URBANISTICO = 'PROJETO_PROJETO_URBANISTICO',
  PROJETO_ENVIADO_A_PREFEITURA = 'PROJETO_ENVIADO_A_PREFEITURA',
  PROJETO_ATENDENDO_DEVOLUTIVAS_DA_PREFEITURA = 'PROJETO_ATENDENDO_DEVOLUTIVAS_DA_PREFEITURA',
  PROJETO_APROVADO_PELA_PREFEITURA = 'PROJETO_APROVADO_PELA_PREFEITURA',
  PROJETO_REGISTRADO_EM_CARTORIO = 'PROJETO_REGISTRADO_EM_CARTORIO',
  PROJETO_ATENDENDO_DEVOLUTIVAS_DO_CARTORIO = 'PROJETO_ATENDENDO_DEVOLUTIVAS_DO_CARTORIO',
  PROJETO_TITULO_EMITIDO = 'PROJETO_TITULO_EMITIDO',
}

export const TIPOS_NOTIFICACOES_ENVIADAS_PARA_VENDEDOR = [
  TipoNotificacao.CADASTRO_CONTRATO_EXPIRADO,
  TipoNotificacao.CADASTRO_ALTERACOES_NECESSARIAS,
];
