import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { MaskPipe, NgxMaskModule } from 'ngx-mask';
import { EmptyListModule } from './components/empty-list/empty-list.module';
import { BuildFormComponent } from './components/form/build-component/build-form.component';
import { DatePickerModalComponent } from './components/form/date-picker-modal/date-picker-modal.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgxMaskModule.forRoot(),
    NzDatePickerModule,
    IonicModule,
    EmptyListModule
  ],
  declarations: [
    BuildFormComponent,
    DatePickerModalComponent,
  ],
  exports: [
    BuildFormComponent,
  ],
  providers: [
    MaskPipe
  ]
})
export class UiMobileModule { }
