import { Pipe, PipeTransform } from '@angular/core';
import { RegistrationStatus } from '@usucampeao/lib-reurb-simplificado';

@Pipe({
  name: 'cadastroStatus',
})
export class CadastroStatusPipe implements PipeTransform {
  transform(value: RegistrationStatus): string {
    switch (value) {
      case RegistrationStatus.INCOMPLETE:
        return 'Incompleto';
      case RegistrationStatus.PRONTO_PARA_FECHAR_CONTRATO:
        return 'Pronto para fechar contrato';
      case RegistrationStatus.READY_TO_SUBMIT:
        return 'Pronto para envio';
      case RegistrationStatus.AGUARDANDO_ASSINATURA_CONTRATO:
        return 'Aceite de contrato pendente';
      case RegistrationStatus.CONTRATO_FECHADO:
        return 'Contrato fechado';
      case RegistrationStatus.IN_ANALYSIS:
        return 'Em análise';
      case RegistrationStatus.CHANGES_REQUESTED:
        return 'Alterações necessárias';
      case RegistrationStatus.AVAILABLE_SCHEDULE:
        return 'Agendamento disponível';
      case RegistrationStatus.VALIDATION_SCHEDULED:
        return 'Agendado';
      case RegistrationStatus.MISSED_SCHEDULE:
        return 'Agendamento perdido';
      case RegistrationStatus.VALIDATED:
        return 'Validado';
      case RegistrationStatus.ENVIADO_PARA_PREFEITURA:
        return 'Enviado para prefeitura';
      case RegistrationStatus.PUBLISHED_OFFICIAL_JOURNAL:
        return 'Publicado no Diário Oficial';
      case RegistrationStatus.REGISTERED:
        return 'Registrado em cartório';
      case RegistrationStatus.DELIVERY_SCHEDULED:
        return 'Entrega dos títulos';
      case RegistrationStatus.DELIVERED_TITLE:
        return 'Título entregue';
      case RegistrationStatus.CANCELED:
        return 'Cancelado';
      case RegistrationStatus.WAIVERED:
        return 'Desistência';
      case RegistrationStatus.INELIGIBLE:
        return 'Inelegível';
      case RegistrationStatus.NOT_VALIDATED:
        return 'Não validado';
      case RegistrationStatus.DELETED:
        return 'Removido';
      case RegistrationStatus.DISTRATO:
        return 'Distrato de contrato';
      default:
        return 'Status não cadastrado';
    }
  }
}
