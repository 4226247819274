import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'usucampeao-pronto-para-fechar-contrato',
  templateUrl: './pronto-para-fechar-contrato.component.html',
  styleUrls: ['./pronto-para-fechar-contrato.component.scss'],
})
export class ProntoParaFecharContratoComponent {
  @Input() projetoId: string;
  @Input() cadastroId: string;

  constructor(private readonly router: Router) { }

  public navegarParaFecharContrato(): void {
    this.router.navigate(['novo-cadastro/bairro', this.projetoId, 'cadastro', this.cadastroId, 'contrato'])
  }
}
