<ion-content>
  <ion-refresher slot="fixed" (ionRefresh)="carregarComentariosDoCadastro($event)">
    <ion-refresher-content>
    </ion-refresher-content>
  </ion-refresher>

  <usucampeao-comentarios [comentarios]="comentarios$ | async" [resetarForm]="resetarForm"
    (aoSubmeter)="adicionarComentario($event)">
  </usucampeao-comentarios>
</ion-content>
