import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingController, ModalController } from '@ionic/angular';
import { PagamentoListagemDto } from '@usucampeao/lib-reurb-simplificado';
import { catchError, finalize, map, switchMap, tap } from 'rxjs/operators';


import { CadastroCardV2Dto, ToastService } from '@usucampeao/ui-mobile';
import { TutorialModal } from '../../shared/components/tutorial/tutorial.modal';
import { AuthQuery } from '../auth/state/auth.query';
import { PagamentosService } from '../cadastros/pagamentos/state/pagamentos.service';
import { CadastrosQuery } from '../cadastros/state/cadastros.query';
import { CadastroService } from '../cadastros/state/cadastros.service';

@Component({
  selector: 'usucampeao-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss'],
})
export class HomePage implements OnInit {
  public cadastros$ = this.cadastrosQuery.selectAll();
  public pagamentos: PagamentoListagemDto[] = [];
  public nomeUsuario$ = this.authQuery.nome$;
  public cadastrosSemContrato$ = this.cadastrosQuery.cadastrosSemContrato$
    .pipe(
      map(cadastros => cadastros.map(cadastro => CadastroCardV2Dto.from(cadastro)))
    );

  public cadastrosComPendencias$ = this.cadastrosQuery.cadastrosComPendencias$
    .pipe(
      map(cadastros => cadastros.map(cadastro => CadastroCardV2Dto.from(cadastro)))
    );

  public isLoading = false;

  constructor(
    private authQuery: AuthQuery,
    private cadastrosQuery: CadastrosQuery,
    private cadastrosService: CadastroService,
    private loadingCtrl: LoadingController,
    private modalController: ModalController,
    private pagamentoService: PagamentosService,
    private router: Router,
    private toastService: ToastService,
  ) { }

  ngOnInit(): void {
    this.carregarCadastros();
  }

  public async carregarCadastros(event?: any): Promise<void> {
    const loader = await this.loadingCtrl.create();

    if (!event) {
      this.isLoading = true;
      await loader.present();
    }

    this.cadastrosService.buscarCadastrosDoUsuario()
      .pipe(
        switchMap(() => this.pagamentoService.buscarProximoVencimento()),
        tap(pagamentos => this.pagamentos = pagamentos || []),
        catchError(() => this.toastService.error('Erro ao carregar cadastros. Tente novamente mais tarde.')),
        finalize(() => {
          if (event) {
            event.target.complete();
          } else {
            loader.dismiss();
            this.isLoading = false;
          }
        }),
      )
      .subscribe();
  }

  public async iniciarNovoCadastro(): Promise<void> {
    this.router.navigate([`novo-cadastro`]);
    const modal = await this.modalController.create({
      component: TutorialModal,
      cssClass: 'poderegularizar-tutorial',
    });
    return await modal.present();
  }

  public irParaMeusImoveis(): void {
    this.router.navigate(['meus-imoveis']);
  }

  public irParaMeusBoletos(): void {
    this.router.navigate(['meus-boletos']);
  }
}
