import { Expose, plainToInstance } from 'class-transformer';
import { IsEnum, IsNotEmpty, IsNumberString, IsOptional, IsString, Length, Matches, MaxLength } from 'class-validator';

import { UF } from '@usucampeao/interfaces';

import { REGEX_ALFANUMERICO_ESPACO_PONTO, RemoveSpecialCharacters } from '@usucampeao/utils';
import { PropertyDto } from '../property/property.dto';

export class AddressDto {
  @RemoveSpecialCharacters()
  @Expose()
  @IsNumberString()
  @IsNotEmpty()
  @Length(8, 8)
  zipCode: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  street: string;

  @Expose()
  @IsNumberString()
  @IsNotEmpty()
  number: string;

  @Expose()
  @IsString()
  @MaxLength(50)
  @Matches(REGEX_ALFANUMERICO_ESPACO_PONTO)
  @IsOptional()
  complement?: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  neighborhood: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  city: string;

  @Expose()
  @IsEnum(UF)
  @IsNotEmpty()
  state: UF;

  /**
   * Cria uma nova instância de AddressDto a partir de dtos
   * @param props DTO de Owner
   * @returns nova instância de AddressDto
   */
  static from(props: PropertyDto | Partial<PropertyDto>): AddressDto {
    return plainToInstance(AddressDto, props.address, { exposeUnsetFields: false, excludeExtraneousValues: true });
  }
}


export class EnderecoPartialDto {
  @RemoveSpecialCharacters()
  @Expose()
  @IsNumberString()
  @IsOptional()
  @Length(8, 8)
  zipCode: string;

  @Expose()
  @IsString()
  @IsOptional()
  street: string;

  @Expose()
  @IsNumberString()
  @IsOptional()
  number: string;

  @Expose()
  @IsString()
  @IsOptional()
  complement?: string;

  @Expose()
  @IsString()
  @IsOptional()
  neighborhood: string;

  @Expose()
  @IsString()
  @IsOptional()
  city: string;

  @Expose()
  @IsEnum(UF)
  @IsOptional()
  state: UF;
}
