<ion-content class="ion-padding">
  <ion-refresher slot="fixed" (ionRefresh)="carregarProprietario($event)">
    <ion-refresher-content>
    </ion-refresher-content>
  </ion-refresher>

  <usucampeao-proprietarios-editar [proprietario]="proprietario$ | async" [alteracoes]="alteracoes$ | async"
    [formDesabilitado]="formDesabilitado$ | async" [documentos]="documentos"
    (aoAlterarDadosPessoais)="alterarDadosPessoais($event)" (aoAlterarDadosDocumento)="alterarDadosDocumento($event)"
    (aoAlterarDadosContato)="alterarDadosContato($event)">
  </usucampeao-proprietarios-editar>
</ion-content>
