import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'usucampeao-modal-ajuda-selecao-lote.component',
  templateUrl: 'modal-ajuda-selecao-lote.component.html',
  styleUrls: ['modal-ajuda-selecao-lote.component.scss'],
})
export class ModalAjudaSelecaoLoteComponent {
  constructor(
    public modalController: ModalController
  ) { }

  public dismiss(): void {
    this.modalController.dismiss();
  }
}
