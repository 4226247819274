import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CadastroContratoAssinaturaTipoEnvioLink } from '@usucampeao/interfaces';

@Injectable({
  providedIn: 'root'
})
export class ContratoService {
  constructor(
    private http: HttpClient,
  ) { }

  /**
   * Reenvia o link para assinatura de contrato para o proprietário principal do cadastro.
   *
   * @param cadastroId ID do cadastro
   * @param contratoLinkTipoEnvio Tipo de envio do link. (EMAIL ou WHATSAPP)
   */
  public reenviarLinkParaAssinaturaDeContrato(cadastroId: string, contratoLinkTipoEnvio: CadastroContratoAssinaturaTipoEnvioLink) {
    return this.http.post(`/cadastros/${cadastroId}/reenviar-link-assinatura`, { contratoLinkTipoEnvio });
  }
}
