<section class="outros-documentos">
  <usucampeao-header title="Outros documentos"></usucampeao-header>

  <div class="outros-documentos__alteracoes" *ngIf="mostrarAlteracoes">
    <usucampeao-cadastro-status [status]="cadastroStatus" [alteracoes]="alteracoes"></usucampeao-cadastro-status>
  </div>

  <ion-text class="font-text">
    Visualize seu contrato e sua procuração.
  </ion-text>

  <div class="ion-margin-top" *ngIf="dadosContrato">
    <usucampeao-menu-item icon="document-attach-outline" title="Contrato" comments="Visualizar" [showIconStatus]="true"
      [status]="statusContrato" [iconStatus]="iconStatus" [iconStatusColor]="iconStatusColor"
      (click)="visualizarContrato()">
      <ion-text class="font-text font-text-small">
        <p class="ion-no-margin" *ngIf="dadosContrato.contratoDataAssinatura">
          <strong>Assinado em:</strong>
          {{ dadosContrato.contratoDataAssinatura ?
          ((dadosContrato.contratoDataAssinatura | date: 'dd/MM/yyyy') + ' às ' +
          (dadosContrato.contratoDataAssinatura | date: 'HH:mm'))
          : ' - ' }}
        </p>

        <p>
          <strong>Formato de aceite:</strong> {{ dadosContrato.contratoTipoAssinatura ?
          (dadosContrato.contratoTipoAssinatura | tipoAssinatura ) : 'Não selecionado' }}
        </p>
        <p class="text-link" *ngIf="!dadosContrato.contratoDataAssinatura">Finalizar aceite de contrato</p>
      </ion-text>
    </usucampeao-menu-item>

    <usucampeao-menu-item icon="document-attach-outline" title="Procuração"
      [comments]="possuiTodosOsDocumentosDosProprietarios ? 'Enviar' : 'Complete seus dados para enviar'"
      [showIconStatus]="true" [status]="statusProcuracao" [isDisabled]="!possuiTodosOsDocumentosDosProprietarios"
      (click)="visualizarProcuracao()">
      <ion-text *ngIf="!possuiTodosOsDocumentosDosProprietarios">
        <span class="description-text">Informe o seu RG para poder gerar o modelo da procuração</span>
      </ion-text>
    </usucampeao-menu-item>
  </div>
</section>
