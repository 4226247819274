<ion-text>
  <p class="font-text">
    Participe da cerimônia de entrega dos títulos dos imóveis do loteamento Jardim Elizabeth com a prefeitura.
  </p>
  <p class="font-text">
    A entrega está marcada para {{ notificacao?.payload?.tituloEntregaData | date }} às
    {{ notificacao?.payload?.tituloEntregaData | date: 'HH:mm' }}, no seguinte endereço:
  </p>
  <p class="font-text">
    {{ notificacao?.payload?.tituloEntregaLocalizacao?.logradouro }},
    {{ notificacao?.payload?.tituloEntregaLocalizacao?.numero }}
    {{ notificacao?.payload?.tituloEntregaLocalizacao?.complemento }}
  </p>
  <p class="font-text">
    Traga um documento pessoal com foto, e participe da cerimônia com os demais moradores do bairro. Você receberá
    seu título em mãos.
  </p>
  <p class="font-text">
    Te aguardamos lá!
  </p>
</ion-text>
