export enum StatusPreenchimento {
  PENDENTE = 'PENDENTE',
  ENVIADO = 'ENVIADO',
  APROVADO = 'APROVADO',
  ALTERACAO_NECESSARIA = 'ALTERACAO_NECESSARIA',
  JUSTIFICADO = 'JUSTIFICADO'
}

export const STATUS_PREENCHIMENTO_PENDENTES = [StatusPreenchimento.PENDENTE, StatusPreenchimento.ALTERACAO_NECESSARIA];
export const STATUS_PREENCHIMENTO_COMPLETOS = [StatusPreenchimento.ENVIADO, StatusPreenchimento.APROVADO, StatusPreenchimento.JUSTIFICADO];
