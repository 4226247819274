import { Directive } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Directive()
export class AbstractForm {
  public form: FormGroup;

  public gerarMensagemErro(campo: string, campoLabel = 'Campo', subCampoNome?: string): string {
    let control = this.form.get(campo);

    if (subCampoNome) {
      control = control.get(subCampoNome);
    }

    if (!control || !control.touched || control.valid) {
      return '';
    }

    if (control?.hasError('required')) {
      return `${campoLabel} obrigatório`;
    }

    if (control?.hasError('minlength') || control?.hasError('maxlength')) {
      if (campo === 'complement') {
        return 'Limite de caracteres excedido. Máximo de 50 caracteres.';
      }
      return `${campoLabel} inválido`;
    }

    if (control?.hasError('cpf_invalido')) {
      return 'CPF inválido';
    }

    if (control?.hasError('email')) {
      return 'Email inválido';
    }

    if (control?.hasError('pattern')) {
      if (campo === 'complement') {
        return 'Digite somente letras, números e pontos';
      }
      return `${campoLabel} inválido`;
    }

    if (control?.hasError('only_numbers')) {
      return `${campoLabel} inválido. Digite somente números.`;
    }
  }
}
