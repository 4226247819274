import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import localePT from '@angular/common/locales/pt';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import Bugsnag from '@bugsnag/js';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AUTH_QUERY_INJECT_KEY, GOOGLE_MAPS_API_KEY, IS_PROD_KEY, MAP_BOX_TOKEN, NOTIFICACOES_QUERY_INJECT_KEY, UiMobileModule } from '@usucampeao/ui-mobile';
import { IMAGES_PATH } from '@usucampeao/utils-frontend';
import 'firebase/analytics';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import { CookieService } from 'ngx-cookie-service';
import { NgxMaskModule } from 'ngx-mask';
import * as packageJson from 'package.json';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { pageTransition } from './app.transition';
import { MenuModule } from './components/menu/menu.module';
import { AuthInterceptor } from './pages/auth/interceptors/auth.interceptor';
import { AuthQuery } from './pages/auth/state/auth.query';
import { NotificationsQuery } from './pages/notifications/state/notifications.query';
registerLocaleData(localePT, 'pt-br')

firebase.initializeApp(environment.firebase);
!environment.production ? firebase.auth().useEmulator('http://localhost:9099') : null;
const analytics = firebase.analytics();
firebase.auth().onIdTokenChanged(user => {
  if (user) {
    analytics.setUserId(user.uid);
    Bugsnag.setUser(user.uid, user.email, user.displayName);
  }
});

// configure Bugsnag asap
Bugsnag.start({
  apiKey: environment.bugsnag.apiKey,
  appVersion: packageJson.version,
  enabledReleaseStages: ['production', 'homolog'],
  releaseStage: environment.bugsnag.releaseStage,
});

// create a factory which will return the Bugsnag error handler
export function errorHandlerFactory() {
  return new BugsnagErrorHandler();
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    // root
    HttpClientModule,
    HttpClientJsonpModule,
    IonicModule.forRoot({ navAnimation: pageTransition }),
    BrowserModule,

    // route
    AppRoutingModule,

    // components
    MenuModule,

    // external libs
    AkitaNgRouterStoreModule,
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    AngularFireAnalyticsModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    UiMobileModule,
    NgxMaskModule.forRoot(),
    ServiceWorkerModule.register(environment.combined, {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:1000',
    }),
  ],
  providers: [
    CookieService,
    ScreenTrackingService,
    UserTrackingService,
    {
      provide: AUTH_QUERY_INJECT_KEY,
      useClass: AuthQuery
    },
    {
      provide: IS_PROD_KEY,
      useValue: environment.production
    },
    {
      provide: ErrorHandler,
      useFactory: errorHandlerFactory,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: IMAGES_PATH,
      useValue: '../assets/images'
    },
    {
      provide: GOOGLE_MAPS_API_KEY,
      useValue: environment.googleMaps.apiKey,
    },
    {
      provide: LOCALE_ID,
      useValue: 'pt-br'
    },
    {
      provide: MAP_BOX_TOKEN,
      useValue: environment.mapbox.token
    },
    {
      provide: NOTIFICACOES_QUERY_INJECT_KEY,
      useClass: NotificationsQuery,
    },
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
