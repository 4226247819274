<div class="datepicker-modal">
  <div class="datepicker-modal__header ion-padding">
    <h2 class="datepicker-modal__title">{{ label }}</h2>
  </div>

  <ion-datetime mode="md" color="primary" [presentation]="showTime ? 'date-time' : 'date'" [preferWheel]="true" [(ngModel)]="value" [max]="max" [min]="min"></ion-datetime>

  <div class="datepicker-modal__actions ion-padding">
    <ion-button fill="clear" mode="md" (click)="close()">Cancelar</ion-button>
    <ion-button fill="clear" mode="md" (click)="close()">Selecionar</ion-button>
  </div>
</div>
