export class DashboardResumoDto {
  totalLotes: number;
  contratosVendidos: number;
  vendasValor: number;
  contratosCanceladosQuantidade: number;
  contratosCanceladosValor: number;

  constructor(
    totalLotes?: number,
    contratosVendidos?: number,
    contratosCanceladosQuantidade?: number,
    vendasValor?: number,
    contratosCanceladosValor?: number,
  ) {
    this.totalLotes = totalLotes || 0;
    this.contratosVendidos = contratosVendidos || 0;
    this.contratosCanceladosQuantidade = contratosCanceladosQuantidade || 0;
    this.vendasValor = vendasValor || 0;
    this.contratosCanceladosValor = contratosCanceladosValor || 0;
  }
}
