import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LocalizacaoLatLong } from '../../services';

@Component({
  selector: 'usucampeao-localizacao-container',
  templateUrl: './localizacao-container.component.html',
  styleUrls: ['./localizacao-container.component.scss'],
})
export class LocalizacaoContainerComponent {
  @Input() localizacao: LocalizacaoLatLong;
  @Input() edicaoDesabilitada = false;
  @Output() aoAlterarLocalizacao = new EventEmitter<void>();

  public alterarLocalizacao(): void {
    this.aoAlterarLocalizacao.emit();
  }
}
