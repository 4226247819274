import { PermissaoGrupo, PermissaoListarDto } from '../permissao';

export type PermissaoLista = {
  [k in PermissaoGrupo]?: PermissaoListarDto[]
}

export class PoliticaDetalhesDto {
  id: number;
  nome: string;
  descricao: string;
  permissoes: PermissaoLista;

  constructor(
    id: number,
    nome: string,
    descricao: string,
    permissoes: PermissaoListarDto[],
  ) {
    this.id = id;
    this.nome = nome;
    this.descricao = descricao;
    this.permissoes = permissoes
      .reduce((acc, curr) => {
        if (!acc[curr.grupo]) {
          acc[curr.grupo] = [];
        }
        acc[curr.grupo].push(curr);
        return acc;
      }, {});
  }
}
