/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NotificacaoDto, RegistrationSimplifiedDto, TipoNotificacao } from '@usucampeao/lib-reurb-simplificado';
import { NavigationService } from '../../../services';

@Component({
  selector: 'usucampeao-notificacoes-detalhes',
  templateUrl: './notificacoes-detalhes.component.html',
  styleUrls: ['./notificacoes-detalhes.component.scss']
})
export class NotificacoesDetalhesComponent {
  @Input() notificacao: NotificacaoDto;
  @Input() cadastro: RegistrationSimplifiedDto | null;

  constructor(
    private readonly navigationService: NavigationService,
    private readonly router: Router,
  ) { }

  public get notificacaoTipo(): typeof TipoNotificacao {
    return TipoNotificacao;
  }

  public navegarParaFicharDoCadastro(): void {
    this.router.navigate(['cadastros', this.cadastro.id]);
  }

  public voltar(): void {
    this.navigationService.voltar('../');
  }
}
