import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { ProprietariosDadosContatoModule } from '@usucampeao/ui-mobile';
import { ProprietariosDadosContatoRoutingModule } from './proprietarios-dados-contato-routing.module';
import { ProprietariosDadosContatoPage } from './proprietarios-dados-contato.page';


@NgModule({
  declarations: [
    ProprietariosDadosContatoPage
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,

    // route
    ProprietariosDadosContatoRoutingModule,

    // components
    ProprietariosDadosContatoModule,
  ]
})
export class ProprietariosDadosContatoPageModule { }
