import { Expose, plainToInstance } from 'class-transformer';
import { GatewayPagamento, MeioPagamento, PagamentoDto, PagamentoStatus } from '.';

export class PagamentoListagemDto {
  @Expose()
  id: string;

  @Expose()
  parcela: number;

  @Expose()
  integracaoId?: string;

  @Expose()
  boletoPdf?: string;

  @Expose()
  valorDevido: number;

  @Expose()
  dataVencimento: Date;

  @Expose()
  status: PagamentoStatus;

  @Expose()
  meioPagamento: MeioPagamento;

  @Expose()
  cadastroId: string;

  @Expose()
  juros: number;

  @Expose()
  valorAmortizado: number;

  @Expose()
  saldoDevedor: number;

  @Expose()
  dataPagamento?: Date;

  @Expose()
  dataEmissao?: Date;

  @Expose()
  gatewayPagamento?: GatewayPagamento;

  /**
   * Cria uma nova instância de PagamentoListagemDto a partir de dtos
   * @param props DTO de Pagamento
   * @returns nova instância de PagamentoListagemDto
   */
  static from(props?: PagamentoDto | Partial<PagamentoDto>): PagamentoListagemDto {
    return plainToInstance(PagamentoListagemDto, props, { exposeUnsetFields: false, excludeExtraneousValues: true });
  }
}
