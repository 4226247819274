import { IsDate, IsOptional } from 'class-validator';
export class BaseEntity {
  @IsDate()
  @IsOptional()
  data_criacao: Date;

  @IsDate()
  @IsOptional()
  data_atualizacao: Date;
}
