import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { ProjetoDetalhesDto } from '@usucampeao/lib-reurb-simplificado';
import { ProjetosCalendarioComponent } from './projetos-calendario/projetos-calendario.component';

@Component({
  selector: 'usucampeao-projetos-detalhes-v2',
  templateUrl: 'projetos-detalhes-v2.page.html',
  styleUrls: ['projetos-detalhes-v2.page.scss'],
})
export class ProjetosDetalhesV2Page {
  @Input() projeto: ProjetoDetalhesDto;

  constructor(
    private router: Router,
    private modalCtrl: ModalController,
  ) { }

  public irParaMapa(): void {
    this.router.navigate(['novo-cadastro/bairro/', this.projeto.id, 'selecionar-lote']);
  }

  public abrirModalCalendario(): void {
    this.modalCtrl.create({
      component: ProjetosCalendarioComponent,
      componentProps: {
        projeto: this.projeto,
      }
    }).then(modal => modal.present());
  }
}
