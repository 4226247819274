export enum Permissoes {
  CADASTROS_ALTERAR_VENDEDOR_RESPONSAVEL = 'CADASTROS_ALTERAR_VENDEDOR_RESPONSAVEL',
  CADASTROS_EDITAR = 'CADASTROS_EDITAR',
  CADASTROS_DOCUMENTO_CRIAR = 'CADASTROS_DOCUMENTO_CRIAR',
  CADASTROS_DOCUMENTO_EDITAR = 'CADASTROS_DOCUMENTO_EDITAR',
  CADASTROS_DOCUMENTO_REMOVER = 'CADASTROS_DOCUMENTO_REMOVER',
  CADASTROS_CANCELAR = 'CADASTROS_CANCELAR',
  CADASTROS_DISTRATO = 'CADASTROS_DISTRATO',
  CADASTROS_VISUALIZAR = 'CADASTROS_VISUALIZAR',
  CADASTROS_REVISAO_EDITAR = 'CADASTROS_REVISAO_EDITAR',
  CADASTROS_REVISAO_LISTAR = 'CADASTROS_REVISAO_LISTAR',
  CADASTROS_REVISAO_VISUALIZAR = 'CADASTROS_REVISAO_VISUALIZAR',
  CADASTROS_STATUS_ALTERAR = 'CADASTROS_STATUS_ALTERAR',
  CADASTROS_COMENTARIOS_ADICIONAR = 'CADASTROS_COMENTARIOS_ADICIONAR',
  CADASTROS_COMENTARIOS_LISTAR = 'CADASTROS_COMENTARIOS_LISTAR',
  CUPONS_DESCONTO_ATIVAR = 'CUPONS_DESCONTO_ATIVAR',
  CUPONS_DESCONTO_CRIAR = 'CUPONS_DESCONTO_CRIAR',
  CUPONS_DESCONTO_DESATIVAR = 'CUPONS_DESCONTO_DESATIVAR',
  CUPONS_DESCONTO_LISTAR = 'CUPONS_DESCONTO_LISTAR',
  CUPONS_DESCONTO_ALTERAR_QUANTIDADE = 'CUPONS_DESCONTO_ALTERAR_QUANTIDADE',
  CUPONS_DESCONTO_VISUALIZAR = 'CUPONS_DESCONTO_VISUALIZAR',
  CUPONS_DESCONTO_PROJETO_ADICIONAR = 'CUPONS_DESCONTO_PROJETO_ADICIONAR',
  CUPONS_DESCONTO_PROJETO_REMOVER = 'CUPONS_DESCONTO_PROJETO_REMOVER',
  COMISSOES_VISUALIZAR = 'COMISSOES_VISUALIZAR',
  DASHBOARDS_VISUALIZAR = 'DASHBOARDS_VISUALIZAR',
  DASHBOARDS_FILTRAR_PERIODO = 'DASHBOARDS_FILTRAR_PERIODO',
  DASHBOARDS_FILTRAR_PROJETOS = 'DASHBOARDS_FILTRAR_PROJETOS',
  DASHBOARDS_COMERCIAL_TICKET_MEDIO = 'DASHBOARDS_COMERCIAL_TICKET_MEDIO',
  DASHBOARDS_COMERCIAL_VALORES = 'DASHBOARDS_COMERCIAL_VALORES',
  DASHBOARDS_COMERCIAL_VISUALIZAR = 'DASHBOARDS_COMERCIAL_VISUALIZAR',
  DASHBOARDS_FINANCEIRO_VALORES = 'DASHBOARDS_FINANCEIRO_VALORES',
  DASHBOARDS_FINANCEIRO_VISUALIZAR = 'DASHBOARDS_FINANCEIRO_VISUALIZAR',
  DOCUMENTOS_DOWNLOAD = 'DOCUMENTOS_DOWNLOAD',
  LOCAIS_CIDADES_HABILITAR = 'LOCAIS_CIDADES_HABILITAR',
  LOCAIS_CIDADES_VISUALIZAR = 'LOCAIS_CIDADES_VISUALIZAR',
  LOCAIS_LOTES_CRIAR = 'LOCAIS_LOTES_CRIAR',
  LOCAIS_LOTES_EDITAR = 'LOCAIS_LOTES_EDITAR',
  LOCAIS_LOTES_VISUALIZAR = 'LOCAIS_LOTES_VISUALIZAR',
  LOCAIS_NUCLEOS_CRIAR = 'LOCAIS_NUCLEOS_CRIAR',
  LOCAIS_NUCLEOS_EDITAR = 'LOCAIS_NUCLEOS_EDITAR',
  LOCAIS_NUCLEOS_PERIMETRO_EDITAR = 'LOCAIS_NUCLEOS_PERIMETRO_EDITAR',
  LOCAIS_NUCLEOS_QUADRAS_REMOVER = 'LOCAIS_NUCLEOS_QUADRAS_REMOVER',
  LOCAIS_NUCLEOS_VISUALIZAR = 'LOCAIS_NUCLEOS_VISUALIZAR',
  LOCAIS_QUADRAS_CRIAR = 'LOCAIS_QUADRAS_CRIAR',
  LOCAIS_QUADRAS_EDITAR = 'LOCAIS_QUADRAS_EDITAR',
  LOCAIS_QUADRAS_VISUALIZAR = 'LOCAIS_QUADRAS_VISUALIZAR',
  PAGAMENTOS_CONECTAR = 'PAGAMENTOS_CONECTAR',
  PAGAMENTOS_DOWNLOAD = 'PAGAMENTOS_DOWNLOAD',
  PAGAMENTOS_EMITIR = 'PAGAMENTOS_EMITIR',
  PAGAMENTOS_VENCIMENTO_ALTERAR = 'PAGAMENTOS_VENCIMENTO_ALTERAR',
  PAGAMENTOS_VISUALIZAR = 'PAGAMENTOS_VISUALIZAR',
  PAGAMENTOS_MENSAL_DOWNLOAD = 'PAGAMENTOS_MENSAL_DOWNLOAD',
  PAGAMENTOS_MENSAL_VISUALIZAR = 'PAGAMENTOS_MENSAL_VISUALIZAR',
  POLITICAS_CRIAR = 'POLITICAS_CRIAR',
  POLITICAS_EDITAR = 'POLITICAS_EDITAR',
  POLITICAS_VISUALIZAR = 'POLITICAS_VISUALIZAR',
  PROJETOS_COMISSAO_TIMES_EDITAR = 'PROJETOS_COMISSAO_TIMES_EDITAR',
  PROJETOS_COMISSAO_PARCEIROS_EDITAR = 'PROJETOS_COMISSAO_PARCEIROS_EDITAR',
  PROJETOS_CRIAR = 'PROJETOS_CRIAR',
  PROJETOS_DOCUMENTOS_CRIAR = 'PROJETOS_DOCUMENTOS_CRIAR',
  PROJETOS_DOCUMENTOS_EDITAR = 'PROJETOS_DOCUMENTOS_EDITAR',
  PROJETOS_DOCUMENTOS_VISUALIZAR = 'PROJETOS_DOCUMENTOS_VISUALIZAR',
  PROJETOS_EDITAR = 'PROJETOS_EDITAR',
  PROJETOS_MAPA_EDITAR = 'PROJETOS_MAPA_EDITAR',
  PROJETOS_MAPA_VISUALIZAR = 'PROJETOS_MAPA_VISUALIZAR',
  PROJETOS_QUADRAS_EDITAR = 'PROJETOS_QUADRAS_EDITAR',
  PROJETOS_VISUALIZAR = 'PROJETOS_VISUALIZAR',
  PROJETOS_PROGRAMACAO_EDITAR = 'PROJETOS_PROGRAMACAO_EDITAR',
  PROJETOS_PROGRAMACAO_VISUALIZAR = 'PROJETOS_PROGRAMACAO_VISUALIZAR',
  PROJETOS_VALORES_EDITAR = 'PROJETOS_VALORES_EDITAR',
  PROJETOS_VALORES_VISUALIZAR = 'PROJETOS_VALORES_VISUALIZAR',
  PROJETOS_USUARIOS_ADICIONAR_REMOVER = 'PROJETOS_USUARIOS_ADICIONAR_REMOVER',
  PROJETOS_USUARIOS_LISTAR = 'PROJETOS_USUARIOS_LISTAR',
  PROJETOS_STATUS_ALTERAR = 'PROJETOS_STATUS_ALTERAR',
  PROJETOS_STATUS_AVANCAR = 'PROJETOS_STATUS_AVANCAR',
  USUARIOS_ATIVAR_DESATIVAR = 'USUARIOS_ATIVAR_DESATIVAR',
  USUARIOS_CRIAR = 'USUARIOS_CRIAR',
  USUARIOS_EDITAR = 'USUARIOS_EDITAR',
  USUARIOS_VISUALIZAR = 'USUARIOS_VISUALIZAR',
  USUARIOS_POLITICAS_VISUALIZAR = 'USUARIOS_POLITICAS_VISUALIZAR',
  USUARIOS_POLITICAS_EDITAR = 'USUARIOS_POLITICAS_EDITAR',
  TIMES_CRIAR = 'TIMES_CRIAR',
  TIMES_EDITAR = 'TIMES_EDITAR',
  TIMES_DESABILITAR = 'TIMES_DESABILITAR',
  TIMES_REMOVER = 'TIMES_REMOVER',
  TIMES_VISUALIZAR = 'TIMES_VISUALIZAR',
  TIMES_USUARIOS_ADICIONAR = 'TIMES_USUARIOS_ADICIONAR',
  TIMES_USUARIOS_REMOVER = 'TIMES_USUARIOS_REMOVER',
  VENDAS_DOWNLOAD = 'VENDAS_DOWNLOAD',
  VENDAS_VISUALIZAR = 'VENDAS_VISUALIZAR',
}
