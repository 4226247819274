<usucampeao-accordion titulo="Outras informações do imóvel" [estaAberto]="accordionEstaAberto"
  (aoAlterarAberturaAccordion)="alterarAberturaAccordion($event)">
  <usucampeao-status-preenchimento extras [status]="statusPreenchimento"></usucampeao-status-preenchimento>

  <form [formGroup]="form">
    <usucampeao-imovel-infos-adicionais-form-v2 [readonly]="formDesabilitado"
      #informacoesAdicionaisForm></usucampeao-imovel-infos-adicionais-form-v2>
    <ion-button mode="md" (click)="submit()">Salvar e continuar</ion-button>
  </form>
</usucampeao-accordion>
