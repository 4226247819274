export class RelatorioTicketMedioEParcelasCidadesDto {
  ticketMedio: number[];
  quantidadeParcelas: number[];
  cidades: string[];
  estados: string[];

  constructor() {
    this.ticketMedio = [];
    this.quantidadeParcelas = [];
    this.cidades = [];
    this.estados = [];
  }
}
