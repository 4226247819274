import { PermissaoListarDto } from '../permissao';
import { PoliticaDto } from '../politica';

export class UsuarioBackofficePoliticaPermissoesDto {
  politicas: PoliticaDto[];
  permissoes: PermissaoListarDto[];

  constructor(politicas: PoliticaDto[], permissoes: PermissaoListarDto[]) {
    this.politicas = politicas;
    this.permissoes = permissoes;
  }
}
