<ion-content class="ion-padding" [scrollY]="false">
  <ion-text>
    <h1 class="font-text font-text--medium font-text--medium-weight">Documentos Necessários</h1>

    <p class="description-text description-text--extra-medium">
      São necessários, pelo menos, os seguintes documentos pessoais:
    </p>
  </ion-text>

  <ul class="ion-margin-bottom">
    <li class="description-text description-text--extra-medium">
      1 documento de identidade com foto e números de RG e CPF
    </li>
    <li class="description-text description-text--extra-medium">
      1 comprovante de estado civil
    </li>
  </ul>

  <ion-text>
    <p class="description-text description-text--extra-medium">
      Também são necessários, pelo menos, os seguintes documentos do imóvel:
    </p>
  </ion-text>

  <ul>
    <li class="description-text description-text--extra-medium">1 documento de comprovação de aquisição do imóvel</li>
    <li class="description-text description-text--extra-medium">1 documento de comprovação de posse do imóvel</li>
  </ul>

  <ion-text>
    <p class="description-text description-text--extra-medium">Quanto mais documentos enviar, melhor será para o
      andamento do processo.</p>
  </ion-text>

  <div class="ion-text-end">
    <a class="text-link" (click)="fechar()">Entendi</a>
  </div>
</ion-content>
