<usucampeao-accordion titulo="Documentos pessoais" [estaAberto]="accordionEstaAberto" (aoAlterarAberturaAccordion)="alterarAberturaAccordion($event)">
  <usucampeao-status-preenchimento extras [status]="statusPreenchimento"></usucampeao-status-preenchimento>

  <form [formGroup]="form">
    <usucampeao-input label="CPF*" controlName="cpf" type="tel" inputmode="numeric" mask="000.000.000-00" [readonly]="formDesabilitado || possuiCPF">
      <span usu-error>{{ gerarMensagemErro('cpf', 'CPF') }}</span>
    </usucampeao-input>

    <ng-container formGroupName="rg">
      <usucampeao-input label="RG*" controlName="number" [readonly]="formDesabilitado"></usucampeao-input>

      <usucampeao-input label="Orgão emissor*" controlName="issuer" [readonly]="formDesabilitado"></usucampeao-input>

      <usucampeao-select label="Estado*" controlName="state" [disabled]="formDesabilitado">
        <ion-select-option *ngFor="let estado of estados" [value]="estado">{{ estado }}</ion-select-option>
      </usucampeao-select>

      <usucampeao-datepicker label="Data de emissão*" formControlName="issueDate" [maxDate]="dataMaximaRg" [disabled]="formDesabilitado"></usucampeao-datepicker>
    </ng-container>
    <ion-button mode="md" (click)="submit()">Salvar e continuar</ion-button>
  </form>
</usucampeao-accordion>
