<div class="c-ficha-status ion-padding">
  <div class="c-ficha-status__header" [class.c-ficha-status__header--start]="statusSimplificado">
    <ion-icon class="c-ficha-status__icon ion-margin-end" [class.c-ficha-status__icon--small]="statusSimplificado" [name]="icone"
      [color]="iconeCor"></ion-icon>

    <div class="c-ficha-status__infos">
      <ion-text>
        <p class="font-text font-text--large ion-no-margin" [class.font-text-bold]="statusSimplificado"
          [class.title--variant]="statusSimplificado">
          {{ cadastro?.status || status | cadastroFichaStatusTitulo: cadastro : projeto }}
        </p>
        <p class="description-text ion-no-margin ion-margin-bottom" *ngIf="!statusSimplificado">
          <span [innerHTML]="cadastro?.status || status | cadastroFichaStatusDescricao: cadastro : projeto"></span>
        </p>
        <span *ngIf="mostrarReenvioLinkContrato" class="text-link" (click)="mostrarModalSelecaoTipoEnvioLink()">Reenviar link</span>
      </ion-text>
    </div>
  </div>

  <ion-text *ngIf="alteracoes?.length > 0">
    <p *ngIf="revisaoComentario" class="font-text font-text--small">"{{ revisaoComentario }}"</p>
    <ul class="ion-no-padding ion-padding-start">
      <li class="c-ficha-status__alteracao font-text font-text--small" *ngFor="let alteracao of alteracoesComDescricao">
        {{ alteracao.tipo | alteracaoTipo }} <br>
        "{{ alteracao.descricao }}"
      </li>
    </ul>
  </ion-text>
</div>
