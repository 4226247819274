<div class="pagamento-card ion-padding">
  <div class="pagamento-card__titulo">
    <ion-icon name="barcode"></ion-icon>
    <span class="font-text font-text--medium">{{ titulo }}</span>
  </div>
  <ion-text>
    <br>
    <h3 class="title title--medium ion-no-margin"  [class.pagamento-card__text-red]="!boletoNaoVencido">
      {{ pagamentoInformado.valorDevido | currency: 'BRL' }}
    </h3>
    <p class="pagamento-card__descricao description-text ion-no-margin">
      {{ descricao }} {{ pagamentoInformado.dataVencimento | date: 'dd/MM/yyyy' }}
    </p>
  </ion-text>

  <ion-button mode="md" (click)="navegarParaDetalhesDoPagamentoOuAbrirWhatsapp()">
    {{ pagamentoInformado.status === pagamentoStatus.NAO_PAGO ? 'ENTRE EM CONTATO' : 'PAGAR' }}
  </ion-button>
</div>
