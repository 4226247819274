import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { ProprietariosDadosDocumentosModule } from '@usucampeao/ui-mobile';
import { ProprietariosDadosDocumentosRoutingModule } from './proprietarios-dados-documentos-routing.module';
import { ProprietariosDadosDocumentosPage } from './proprietarios-dados-documentos.page';

@NgModule({
  declarations: [
    ProprietariosDadosDocumentosPage
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,

    // route
    ProprietariosDadosDocumentosRoutingModule,

    // components
    ProprietariosDadosDocumentosModule
  ]
})
export class ProprietariosDadosDocumentosPageModule { }
