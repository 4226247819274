<usucampeao-accordion class="u-foto-documento" [titulo]="titulo" [estaAberto]="accordionEstaAberto" [maxHeight]="500" (aoAlterarAberturaAccordion)="alterarAberturaAccordion($event)">
  <usucampeao-status-preenchimento extras [status]="statusPreenchimento"></usucampeao-status-preenchimento>

  <h3 class="u-foto-documento__titulo" *ngIf="documentoTipo">
    {{ documentoTipo | documentoTipo }}
  </h3>

  <ng-container *ngIf="!arquivosDocumento.length; else exibirFotosTemplate">
    <ng-content></ng-content>

    <form [formGroup]="form" *ngIf="listaDeDocumentos?.length">
      <usucampeao-select controlName="documento" label="Escolha o tipo do documento">
        <ion-select-option *ngFor="let documento of listaDeDocumentos" [value]="documento">
          {{ documento | documentoTipo }}
        </ion-select-option>
        <span usu-error>{{ mensagemErro }}</span>
      </usucampeao-select>
    </form>

    <div class="u-foto-documento__button" (click)="abrirCamera()">
      <ion-icon name="camera-outline"></ion-icon>
      <span>Tirar foto</span>
    </div>
  </ng-container>

  <ng-template #exibirFotosTemplate>
    <usucampeao-upload-foto-documento [modoSimplificado]="true"
      [imagensBase64]="arquivosDocumento"></usucampeao-upload-foto-documento>
    <ion-button mode="md" size="small" fill="outline"
      (click)="irParaUploadDeDocumento()" *ngIf="!readonly">Adicionar ou remover foto</ion-button>
  </ng-template>
</usucampeao-accordion>
