<div class="c-pagamentos-resumo usu-scroll">
  <div class="c-pagamentos-resumo__item">
    <ion-badge mode="md" color="medium">
      <ion-icon name="calendar-outline"></ion-icon>
    </ion-badge>

    <ion-text class="ion-text-center">
      <p class="description-text description-text--small">Pendente</p>
      <p class="title title--small">{{ resumo.pendentes.valor | currency: 'BRL' }}</p>
      <p class="description-text description-text--small">{{ resumo.pendentes.quantidade }} parcela(s)</p>
    </ion-text>
  </div>

  <div class="c-pagamentos-resumo__item">
    <ion-badge mode="md" color="success">
      <ion-icon name="checkmark-circle-outline"></ion-icon>
    </ion-badge>

    <ion-text class="ion-text-center">
      <p class="description-text description-text--small">Pago</p>
      <p class="title title--small">{{ resumo.pagos.valor | currency: 'BRL' }}</p>
      <p class="description-text description-text--small">{{ resumo.pagos.quantidade }} parcela(s)</p>
    </ion-text>
  </div>

  <div class="c-pagamentos-resumo__item">
    <ion-badge mode="md" color="danger">
      <ion-icon name="alert-circle-outline"></ion-icon>
    </ion-badge>

    <ion-text class="ion-text-center">
      <p class="description-text description-text--small">Não Pago</p>
      <p class="title title--small">{{ resumo.vencidos.valor | currency: 'BRL' }}</p>
      <p class="description-text description-text--small">{{ resumo.vencidos.quantidade }} parcela(s)</p>
    </ion-text>
  </div>

  <div class="c-pagamentos-resumo__item">
    <ion-badge mode="md" color="warning">
      <ion-icon name="hourglass-outline"></ion-icon>
    </ion-badge>

    <ion-text class="ion-text-center">
      <p class="description-text description-text--small">Em Análise</p>
      <p class="title title--small">{{ resumo.emAnalise.valor | currency: 'BRL' }}</p>
      <p class="description-text description-text--small">{{ resumo.emAnalise.quantidade }} parcela(s)</p>
    </ion-text>
  </div>
</div>
