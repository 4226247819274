import { Inject, Injectable } from '@angular/core';
import Bugsnag from '@bugsnag/js';
import { Loader } from '@googlemaps/js-api-loader';
import { ToastService } from '../shared';

export const GOOGLE_MAPS_API_KEY = 'GOOGLE_MAPS_API_KEY';

export type LocalizacaoLatLong = {
  cep?: string;
  logradouro?: string;
  numero?: string;
  bairro?: string;
  cidade?: string;
  estado?: string;
  lat: number;
  long: number;
}

type EnderecoBuscarLocalizacaoGoogle = {
  cep: string;
  logradouro?: string;
  numero?: string;
  bairro?: string;
  cidade?: string;
  estado?: string;
}

const LOCALIZACAO_SESSION_STORAGE_KEY = 'LOCALIZACAO_KEY';

/**
 * Serviço para buscar localizações no Google Maps.
 */
@Injectable({
  providedIn: 'root'
})
export class LocalizacaoService {
  private readonly _loaderGoogleMaps: Loader;

  constructor(
    @Inject(GOOGLE_MAPS_API_KEY)
    private readonly googleApi: string,
    private readonly toastService: ToastService,
  ) {
    this._loaderGoogleMaps = new Loader({
      apiKey: this.googleApi,
      language: 'pt-BR',
      region: 'BR',
      libraries: ['places', 'geocoding']
    });
  }

  public get loaderGoogleMaps(): Loader {
    return this._loaderGoogleMaps;
  }

  /**
   * Busca a localização de um endereço no Google Maps.
   *
   * @param endereco Endereço para buscar a localização no Google Maps.
   * @param endereco.cep CEP do endereço - Exemplo: 01310-100
   * @param endereco.logradouro Logradouro do endereço - Exemplo: Avenida Paulista
   * @param endereco.numero Número do endereço - Exemplo: 100
   * @param endereco.bairro Bairro do endereço - Exemplo: Bela Vista
   * @param endereco.cidade Cidade do endereço - Exemplo: São Paulo
   * @param endereco.estado Estado do endereço - Exemplo: SP
   * @returns Uma promise que resolve em um objeto LocalizacaoLatLong com a localização do CEP.
   */
  public async buscarLocalizacaoPeloEndereco(endereco: EnderecoBuscarLocalizacaoGoogle): Promise<LocalizacaoLatLong> {
    const enderecoFormatado = [
      endereco.cep,
      endereco.logradouro,
      endereco.numero,
      endereco.bairro,
      endereco.cidade,
      endereco.estado
    ]
      .filter(value => !!value)
      .join(', ');

    return this.buscarLocalizacaoGoogle({ address: enderecoFormatado });
  }

  /**
   * Busca a localização de uma latitude e longitude no Google Maps.
   *
   * @param lat Latitude - Exemplo: -23.5505199
   * @param lng Longitude - Exemplo: -46.6333094
   * @returns Uma promise que resolve em um objeto LocalizacaoLatLong com a localização da latitude e longitude.
   */
  public async buscarLocalizacaoPorLatLong(lat: number, lng: number): Promise<LocalizacaoLatLong> {
    return this.buscarLocalizacaoGoogle({ location: { lat, lng } });
  }

  /**
   * Busca a localização no Google Maps.
   * A busca pode ser realizada por todas as opções disponíveis em google.maps.GeocoderRequest.
   * A busca priorizada será do tipo street_address, seguida de route, postal_code e premise.
   * A localização encontrada será salva no sessionStorage.
   * @see {@link https://developers.google.com/maps/documentation/javascript/geocoding#GeocodingAddressTypes Tipos de endereços disponíveis}
   * @see {@link https://developers.google.com/maps/documentation/javascript/reference/geocoder#GeocoderRequest Maps JavaScript API}
   * @param request Objeto de requisição para buscar a localização no Google Maps. {@link google.maps.Geocoder Geocoder}.
   * @returns
   */
  private async buscarLocalizacaoGoogle(request: google.maps.GeocoderRequest): Promise<LocalizacaoLatLong> {
    const { Geocoder, GeocoderStatus } = await this._loaderGoogleMaps.importLibrary('geocoding');
    const geocoder = new Geocoder();

    try {
      const geocoderResponse = await geocoder.geocode(request);
      const enderecosEncontrados = geocoderResponse.results;
      // Prioriza o endereço de rua, se não encontrar, pega o endereço de rota, se não encontrar, pega o endereço postal.
      // É necessário fazer isso pois os endereços do tipo street_address são mais precisos, seguidos de route e postal_code.
      const enderecoEncontrado = enderecosEncontrados.find(endereco => endereco.types.includes('street_address')) ||
        enderecosEncontrados.find(endereco => endereco.types.includes('route')) ||
        enderecosEncontrados.find(endereco => endereco.types.includes('postal_code')) ||
        enderecosEncontrados.find(endereco => endereco.types.includes('premise'));
      const localizacao: LocalizacaoLatLong = {
        cep: enderecoEncontrado?.address_components?.find(endereco => endereco.types.includes('postal_code'))?.long_name,
        logradouro: enderecoEncontrado?.address_components?.find(endereco => endereco.types.includes('route'))?.long_name,
        numero: enderecoEncontrado?.address_components?.find(endereco => endereco.types.includes('street_number'))?.long_name,
        bairro: enderecoEncontrado?.address_components?.find(endereco => endereco.types.includes('sublocality'))?.long_name,
        cidade: enderecoEncontrado?.address_components?.find(endereco => endereco.types.includes('administrative_area_level_2'))?.long_name,
        estado: enderecoEncontrado?.address_components?.find(endereco => endereco.types.includes('administrative_area_level_1'))?.short_name,
        lat: enderecoEncontrado?.geometry?.location?.lat() || enderecosEncontrados[0].geometry.location.lat(),
        long: enderecoEncontrado?.geometry?.location?.lng() || enderecosEncontrados[0].geometry.location.lng(),
      };

      this.salvarLocalizacaoSelecionadaPeloUsuario(localizacao);
      return localizacao;
    } catch (error) {
      const codigoErro = error.code;
      let bugsnagGravidade: 'info' | 'warning' | 'error' = 'warning';
      switch (codigoErro) {
        case GeocoderStatus.ZERO_RESULTS:
        case GeocoderStatus.INVALID_REQUEST:
          this.toastService.warning('Nenhum endereço encontrato para este cep. Verifique o cep e tente novamente.').toPromise();
          break;

        default:
          this.toastService.error('Tivemos um problema ao buscar endereço. Por favor tente novamente mais tarde.').toPromise();
          if (codigoErro === GeocoderStatus.OVER_QUERY_LIMIT) {
            bugsnagGravidade = 'info';
          }

          Bugsnag.notify(new Error('Erro ao carregar a API do Google Maps'), event => {
            event.context = 'Erro ao carregar biblioteca de Geocoding do Google Maps';
            event.addMetadata('status', { status: codigoErro });
            event.severity = bugsnagGravidade;
          });
          break;
      }

      return null;
    }
  }

  /**
   * Salva a localização selecionada pelo usuário no sessionStorage.
   *
   * @param localizacao Localização a ser salva.
   */
  public salvarLocalizacaoSelecionadaPeloUsuario(localizacao: Partial<LocalizacaoLatLong>): void {
    const localizacaoSalva = this.buscarLocalizacaoSelecionadaPeloUsuario() || {};
    const localizacaoFinal = { ...localizacaoSalva, ...localizacao };
    sessionStorage.setItem(LOCALIZACAO_SESSION_STORAGE_KEY, JSON.stringify(localizacaoFinal));
  }

  /**
   * Busca a localização salva no sessionStorage.
   *
   * @returns A localização salva no sessionStorage ou null se não houver localização salva.
   */
  public buscarLocalizacaoSelecionadaPeloUsuario(): LocalizacaoLatLong | null {
    const localizacao = sessionStorage.getItem(LOCALIZACAO_SESSION_STORAGE_KEY);
    return localizacao ? JSON.parse(localizacao) : null;
  }

  /**
   * Limpa a localização salva no sessionStorage.
   */
  public limparLocalizacaoSelecionadaPeloUsuario(): void {
    sessionStorage.removeItem(LOCALIZACAO_SESSION_STORAGE_KEY);
  }
}
