import { Formatador } from './formatadores';


/**
 * Classe utilitária para manipulação de strings.
 */
export class StringUtils {
  /**
   * Adiciona formatação conforme o formatador informado.
   * Para a lista completa de formatações, consulte a constante FORMATADORES, em formatadores.ts.
   * Algumas formatações disponíveis:
   * - CPF
   * - CNPJ
   * - Telefone
   * - Remover caracteres especiais
   *
   * @param texto Texto a ser formatado.
   * @param formatador Formatador a ser utilizado.
   * @returns Texto formatado.
   * @throws {FormatadorInputInvalidoError} Se o texto informado for inválido.
   */
  public static adicionarFormatacao(texto: string, formatador: Formatador): string {
    return formatador.formatar(texto);
  }
}
