
export class NucleoListarDto {
  id: number;
  nome: string;
  projetosQuantidade: number;
  quadrasQuantidade: number;
  lotesQuantidade: number;
  coords: number[][];

  constructor(
    id: number,
    nome: string,
    projetosQuantidade: number,
    quadrasQuantidade: number,
    lotesQuantidade: number,
    coords: number[][]
  ) {
    this.id = id;
    this.nome = nome;
    this.projetosQuantidade = projetosQuantidade;
    this.quadrasQuantidade = quadrasQuantidade;
    this.lotesQuantidade = lotesQuantidade;
    this.coords = coords;
  }
}
