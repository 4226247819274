<div class="footer">

  <ng-content></ng-content>

  <ion-footer class="ion-no-border ">
    <ion-toolbar class=" ion-no-padding">
      <ion-grid class="ion-no-margin ion-no-padding ion-padding-top">
        <ion-row class="ion-justify-content-center">
          <ion-col size="auto">
            <ion-img src="assets/images/logo.svg"></ion-img>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-toolbar>
  </ion-footer>
</div>
