import { UF } from '@usucampeao/interfaces';

export class CidadeListDto {
  id: number;
  nome: string;
  uf: UF;
  nucleosQuantidade: number;
  projetosQuantidade: number;
  lotesQuantidade: number;

  constructor(
    id: number,
    nome: string,
    uf: UF,
    nucleosQuantidade: number,
    projetosQuantidade: number,
    lotesQuantidade: number,
  ) {
    this.id = id;
    this.nome = nome;
    this.uf = uf;
    this.nucleosQuantidade = nucleosQuantidade;
    this.projetosQuantidade = projetosQuantidade;
    this.lotesQuantidade = lotesQuantidade;
  }
}
