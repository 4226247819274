import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewWillEnter, ViewWillLeave } from '@ionic/angular';
import { LoteListarMapaDto, MapaDto } from '@usucampeao/lib-reurb-simplificado';
import { BaseComponent, LoadingService, ToastService } from '@usucampeao/ui-mobile';
import { Observable, forkJoin } from 'rxjs';
import { catchError, finalize, take, takeUntil, tap } from 'rxjs/operators';
import { ProjetoQuery } from '../../../core/states/projeto/projeto.query';
import { ProjetoService } from '../../../core/states/projeto/projeto.service';
import { ImoveisService } from '../../cadastros/imoveis/state/imoveis.service';
import { CadastrosQuery } from '../../cadastros/state/cadastros.query';
import { CadastroService } from '../../cadastros/state/cadastros.service';

@Component({
  templateUrl: 'projeto-mapa.page.html',
  styleUrls: ['projeto-mapa.page.scss'],
})
export class ProjetoMapaPage extends BaseComponent implements ViewWillEnter, ViewWillLeave {

  private projetoId: string;
  public mapa$: Observable<MapaDto>;
  public lotes$: Observable<LoteListarMapaDto[]>;
  public cadastroId: string;

  constructor(
    private cadastroService: CadastroService,
    private cadastroQuery: CadastrosQuery,
    private imoveisService: ImoveisService,
    private loadingService: LoadingService,
    private projetoQuery: ProjetoQuery,
    private projetoService: ProjetoService,
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
  ) {
    super();
  }

  ionViewWillEnter(): void {
    this.route.params
      .pipe(
        tap(params => {
          const { cadastroEmRascunho } = this.cadastroQuery.getValue();
          if (cadastroEmRascunho?.id && !params.cadastroId) {
            this.router.navigate(['../cadastro', cadastroEmRascunho.id, 'selecionar-lote'], { relativeTo: this.route, replaceUrl: true });
          }
        }),
        tap(params => this.projetoId = params.id),
        tap(() => this.mapa$ = this.projetoQuery.buscarMapa$(this.projetoId)),
        tap(() => this.lotes$ = this.projetoQuery.buscarLotes$(this.projetoId)),
        tap(() => this.carregarLotesEMapa(this.projetoId)),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe();

    const cadastroRascunho = this.cadastroService.buscarCadastroEmRascunho();
    this.cadastroId = cadastroRascunho?.id;
  }


  private async carregarLotesEMapa(projetoId: string): Promise<void> {
    await this.loadingService.createLoader();
    forkJoin([
      this.projetoService.buscarMapa(projetoId),
      this.projetoService.buscarLotes(projetoId),
    ])
      .pipe(
        take(1),
        catchError(() => this.toastService.error('Erro ao carregar mapa. Por favor tente novamente.')),
        finalize(() => this.loadingService.dismiss()),
      )
      .subscribe();
  }

  public selecionarLote(lote: LoteListarMapaDto): void {
    this.cadastroService.salvarRascunhoCadastro({
      blockId: +lote.quadra_id,
      lotId: +lote.id,
      projetoId: this.projetoId,
    });

    const { cadastroEmRascunho } = this.cadastroQuery.getValue();
    if (cadastroEmRascunho?.id) {
      this.atualizarQuadraELote(lote);
      return;
    }

    this.salvarRascunhoCadastro(lote);
  }

  private async atualizarQuadraELote(lote: LoteListarMapaDto): Promise<void> {
    await this.loadingService.createLoader();

    const { cadastroEmRascunho } = this.cadastroQuery.getValue();
    this.imoveisService.atualizarLocalizacao(
      cadastroEmRascunho.id,
      cadastroEmRascunho.imovelId,
      {
        blockId: +lote.quadra_id,
        lotId: +lote.id,
      }
    )
      .pipe(
        tap(() => this.router.navigate(['../cadastrar-dados-imovel'], { relativeTo: this.route })),
        catchError(error => {
          this.toastService.error('Erro ao atualizar quadra e lote. Por favor tente novamente mais tarde.').toPromise();
          throw error;
        }),
        finalize(() => this.loadingService.dismiss()),
      )
      .subscribe();
  }

  private salvarRascunhoCadastro(lote: LoteListarMapaDto): void {
    this.cadastroService.salvarRascunhoCadastro({
      blockId: +lote.quadra_id,
      lotId: +lote.id,
      projetoId: this.projetoId,
    });
    this.router.navigate(['../cadastrar-dados-imovel'], { relativeTo: this.route });
  }
}
