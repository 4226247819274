import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { FooterModule, HeaderModule } from 'libs/ui-mobile/src/lib/components';
import { CadastroStatusModule } from 'libs/ui-mobile/src/lib/components/cadastro-ficha-status/cadastro-status.module';
import { ImovelInfosAdicionaisFormModule } from 'libs/ui-mobile/src/lib/components/forms';
import { ImoveisInformacoesAdicionaisComponent } from './imoveis-informacoes-adicionais.component';



@NgModule({
  declarations: [
    ImoveisInformacoesAdicionaisComponent
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,
    ReactiveFormsModule,

    // components
    CadastroStatusModule,
    FooterModule,
    HeaderModule,
    ImovelInfosAdicionaisFormModule
  ],
  exports: [
    ImoveisInformacoesAdicionaisComponent
  ]
})
export class ImoveisInformacoesAdicionaisModule { }
