<ion-content>
  <ion-refresher slot="fixed" (ionRefresh)="carregarCadastro($event)">
    <ion-refresher-content>
    </ion-refresher-content>
  </ion-refresher>

  <ng-container *ngIf="(cadastro$ | async) && (imovel$ | async)">
    <usucampeao-cadastros-ficha-v2 [cadastro]="cadastro$ | async" [imovel]="imovel$ | async"
      [revisao]="revisao$ | async" [quantidadePagamentosNaoPagos]="quantidadePagamentosNaoPagos$ | async"
      [projeto]="projeto" (aoEnviarParaAnalise)="enviarParaAnalise()"
      (aoAdicionarFotoFachada)="atualizarFotoFachada($event)"
      (aoAlterarContato)="carregarCadastro()"></usucampeao-cadastros-ficha-v2>
  </ng-container>
</ion-content>
