import { Nationality, UF } from '@usucampeao/interfaces';
import { Expose } from 'class-transformer';
import { Gender } from '../../enums';
import { RendaFamiliar } from './get-update-owner-personal-data.dto';
import { MaritalStatus } from './marital-status.enum';
import { MatrimonialRegime } from './matrimonial-regime.enum';
import { OwnerStatus } from './owner-status.enum';
import { PhoneDto } from './phone.dto';
import { RgDto } from './rg.dto';

export class OwnerDto {
  @Expose()
  id: string;

  @Expose()
  name: string;

  @Expose()
  cpf: string;

  @Expose()
  rg: RgDto;

  @Expose()
  email: string;

  @Expose()
  phones: PhoneDto;

  @Expose()
  contactName: string;

  @Expose()
  gender: Gender;

  @Expose()
  birthDate: Date;

  @Expose()
  nationality: Nationality;

  @Expose()
  state?: UF;

  @Expose()
  naturalidade?: string;

  @Expose()
  maritalStatus: MaritalStatus;

  @Expose()
  matrimonialRegime?: MatrimonialRegime;

  @Expose()
  weddingDate: Date;

  @Expose()
  profession: string;

  @Expose()
  familyIncome: number;

  @Expose()
  spouseId?: string;

  @Expose()
  userId?: string;

  @Expose()
  photoId: string;

  @Expose()
  status: OwnerStatus;

  @Expose()
  rendaFamiliar?: RendaFamiliar;

  constructor(owner?: Partial<OwnerDto>) {
    if (owner) {
      Object.keys(owner).forEach(key => this[key] = owner[key]);
    }
  }
}
