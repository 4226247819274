<ion-content class="ion-padding">
  <ion-refresher slot="fixed" (ionRefresh)="carregarCadastro($event)">
    <ion-refresher-content>
    </ion-refresher-content>
  </ion-refresher>

  <section class="c-assinatura">
    <usucampeao-header [title]="titulos[tipo]"></usucampeao-header>

    <div class="c-assinatura__contrato-expirado" *ngIf="contratoExpirado">
      <div class="c-assinatura__flex">
        <ion-icon name="warning" color="danger"></ion-icon>
        <ion-text color="danger">
          <p class="ion-no-margin">Atenção!</p>
        </ion-text>
      </div>

      <ion-text>
        <p>
          O prazo para assinatura expirou, e o contrato não pode mais ser assinado.
        </p>
        <p>
          Clique no botão abaixo para redefinir as formas de pagamento e gerar um novo contrato.
        </p>
      </ion-text>

      <ion-button class="ion-margin-top" mode="md" routerLink="../selecionar-forma-pagamento">Gerar um novo contrato</ion-button>
    </div>

    <ng-container *ngIf="!contratoExpirado">
      <ng-container [ngSwitch]="tipo">
        <usucampeao-contrato-assinatura-tipo-fisico
          *ngSwitchCase="cadastroContratoTipoAssinatura.FISICO"></usucampeao-contrato-assinatura-tipo-fisico>
        <usucampeao-contrato-assinatura-tipo-link [cadastro]="cadastro$ | async"
          *ngSwitchCase="cadastroContratoTipoAssinatura.LINK">
        </usucampeao-contrato-assinatura-tipo-link>
      </ng-container>
    </ng-container>
  </section>
</ion-content>
