import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { PropertySituation } from '@usucampeao/lib-reurb-simplificado';
import { CadastroStateDto } from '@usucampeao/ui-mobile';

export type CadastroRascunho = {
  id?: string;
  imovelId?: string;
  proprietarioId?: string;
  blockId?: number;
  lotId?: number;
  projetoId?: string;
  possuiOutroImovel?: boolean;
  situation?: PropertySituation;
}

export interface CadastroState extends EntityState<CadastroStateDto> {
  cadastroEmRascunho: CadastroRascunho;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'cadastros',
  resettable: true,
})
export class CadastrosStore extends EntityStore<CadastroState> {
  constructor() {
    super();
  }

  public buscarCadastro(id: string): CadastroStateDto {
    const cadastro = this.getValue().entities[id];
    return Object.assign({}, cadastro);
  }
}
