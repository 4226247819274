import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { PipesModule } from '@usucampeao/utils-frontend';
import { CadastroStatusModule } from 'libs/ui-mobile/src/lib/components/cadastro-ficha-status/cadastro-status.module';
import {
  DatepickerModule,
  FooterModule,
  HeaderModule,
  InputModule,
  SelectModule
} from '../../../../components';
import { ProprietariosDadosDocumentosComponent } from './proprietarios-dados-documentos.component';

@NgModule({
  declarations: [
    ProprietariosDadosDocumentosComponent
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,
    ReactiveFormsModule,

    // components
    CadastroStatusModule,
    DatepickerModule,
    FooterModule,
    HeaderModule,
    InputModule,
    SelectModule,

    // others
    PipesModule,
  ],
  exports: [
    ProprietariosDadosDocumentosComponent
  ]
})
export class ProprietariosDadosDocumentosModule { }
