export class RelatorioVendasCidadesDto {
  quantidades: number[];
  valores: number[];
  cidades: string[];
  estados: string[];

  constructor() {
    this.quantidades = [];
    this.valores = [];
    this.cidades = [];
    this.estados = [];
  }
}
