import { Component, Input, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ViewWillLeave } from '@ionic/angular';
import { UF } from '@usucampeao/interfaces';
import { AddressDto } from '@usucampeao/lib-reurb-simplificado';
import { REGEX_ALFANUMERICO_ESPACO_PONTO } from '@usucampeao/utils';
import { AbstractForm, OnlyNumbers } from '@usucampeao/utils-frontend';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { EnderecoService } from '../../../services/endereco.service';

@Component({
  selector: 'usucampeao-endereco-form',
  templateUrl: './endereco-form.component.html',
  styleUrls: ['./endereco-form.component.scss']
})
export class EnderecoFormComponent extends AbstractForm implements OnDestroy, ViewWillLeave {
  @Input() estado: UF;
  @Input() bairro: string;
  @Input() cidade: string;
  @Input() readonly = false;

  private ngUnsubscribe$ = new Subject<void>();
  public readonly estados = Object.values(UF);

  constructor(
    private fb: FormBuilder,
    private enderecoService: EnderecoService,
  ) {
    super();
  }

  ionViewWillLeave(): void {
    this.ngUnsubscribe$.next();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.complete();
  }

  public buildFormGroup(address?: AddressDto): FormGroup {
    this.form = this.fb.group({
      zipCode: [address?.zipCode, [Validators.required, Validators.minLength(9)]],
      street: [address?.street, Validators.required],
      number: [address?.number, [Validators.required, OnlyNumbers]],
      complement: [address?.complement, [Validators.pattern(REGEX_ALFANUMERICO_ESPACO_PONTO), Validators.maxLength(50)]],
      neighborhood: [address?.neighborhood, [Validators.required, Validators.maxLength(50)]],
      city: [address?.city, [Validators.required, Validators.maxLength(50)]],
      state: [address?.state, Validators.required]
    });

    this.subscribeZipCodeChanges();
    return this.form;
  }

  private subscribeZipCodeChanges(): void {
    this.form.get('zipCode').valueChanges
      .pipe(
        debounceTime(400),
        distinctUntilChanged(),
        filter(value => value?.length === 9),
        switchMap(zipCode => this.enderecoService.buscarCep(zipCode)),
        tap(endereco => {
          this.form.patchValue({
            street: endereco.street,
            neighborhood: endereco.neighborhood || this.bairro,
            city: endereco.city || this.cidade,
            state: endereco.state || this.estado,
          })
        }
        ),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe();
  }
}
