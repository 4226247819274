import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { NotificacaoDto } from '@usucampeao/lib-reurb-simplificado';

export interface NotificationsState extends EntityState<NotificacaoDto> {
  loaded: boolean;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'notifications',
  resettable: true
})
export class NotificationsStore extends EntityStore<NotificationsState> {
  constructor() {
    super();
  }
}
