import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { LazyLoadImageHooks, PipesModule } from '@usucampeao/utils-frontend';
import { LAZYLOAD_IMAGE_HOOKS, LazyLoadImageModule } from 'ng-lazyload-image';
import { AccordionModule } from '../../accordion/accordion.module';
import { SelectModule } from '../../forms';
import { StatusPreenchimentoModule } from '../../status-preenchimento/status-preenchimento.module';
import { UploadFotoDocumentoModule } from '../upload-foto-documento/upload-foto-documento.module';
import { UploadFotoDocumentoV2Component } from './upload-foto-documento-v2.component';

@NgModule({
  declarations: [UploadFotoDocumentoV2Component],
  imports: [
    // root
    CommonModule,
    IonicModule,
    ReactiveFormsModule,

    // components
    AccordionModule,
    StatusPreenchimentoModule,
    SelectModule,
    UploadFotoDocumentoModule,

    // external libraries
    LazyLoadImageModule,

    // others
    PipesModule,
  ],
  exports: [UploadFotoDocumentoV2Component],
  providers: [
    {
      provide: LAZYLOAD_IMAGE_HOOKS,
      useClass: LazyLoadImageHooks
    }
  ],
})
export class UploadFotoDocumentoV2Module { }
