import { Pipe, PipeTransform } from '@angular/core';
import { PropertyOccupancy } from '@usucampeao/lib-reurb-simplificado';

@Pipe({
  name: 'imovelOcupacao'
})
export class ImovelOcupacaoPipe implements PipeTransform {

  transform(value: PropertyOccupancy): string {
    switch (value) {
      case PropertyOccupancy.LEASED:
        return 'Alugada';

      case PropertyOccupancy.OCCUPATED:
        return 'Ocupada';

      case PropertyOccupancy.OWN:
        return 'Própria';

      case PropertyOccupancy.RENTED:
        return 'Cedida';

      default:
        return value;
    }
  }
}
