import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AlteracaoDto, GetUpdateOwnerContactDto, RegistrationStatus } from '@usucampeao/lib-reurb-simplificado';
import { AbstractForm } from '@usucampeao/utils-frontend';

@Component({
  selector: 'usucampeao-proprietarios-dados-contato',
  templateUrl: './proprietarios-dados-contato.component.html',
  styleUrls: ['./proprietarios-dados-contato.component.scss']
})
export class ProprietariosDadosContatoComponent extends AbstractForm implements OnInit, OnChanges {
  @Input() dadosContato: GetUpdateOwnerContactDto;
  @Input() alteracoes: AlteracaoDto[];
  @Input() formDesabilitado = false;

  @Output() aoSubmeter = new EventEmitter<GetUpdateOwnerContactDto>();

  constructor(private fb: FormBuilder) {
    super();
    this.form = this.fb.group({
      email: [null, Validators.compose([Validators.required, Validators.email])],
      phones: this.fb.group({
        cellphone: [null, Validators.compose([Validators.required, Validators.minLength(14)])],
        home: [null, Validators.minLength(13)],
        message: [null, Validators.minLength(13)],
      }),
      contactName: [null]
    });
  }

  ngOnInit(): void {
    this.preencherForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dadosContato) {
      this.preencherForm();
    }
  }

  public get cadastroStatus(): RegistrationStatus {
    return RegistrationStatus.CHANGES_REQUESTED;
  }

  public get mostrarAlteracoes(): boolean {
    return this.alteracoes && this.alteracoes.length > 0;
  }

  private preencherForm(): void {
    const dadosContato = Object.assign({}, this.dadosContato);
    this.form.patchValue(dadosContato);
  }

  public submit(): void {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }

    const dadosContato: GetUpdateOwnerContactDto = Object.assign({}, this.form.value);
    this.aoSubmeter.emit(dadosContato);
  }
}
