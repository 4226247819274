import { AddressDto } from '@usucampeao/lib-reurb-simplificado';
import { RemoveSpecialCharacters } from '@usucampeao/utils';
import { Expose } from 'class-transformer';
import {
  IsEnum,
  IsNotEmpty,
  IsNumber,
  IsNumberString,
  IsOptional,
  IsString,
  Length
} from 'class-validator';
import { UF } from '../enums/uf.enum';
export class Endereco {
  @Expose()
  @RemoveSpecialCharacters()
  @IsNumberString()
  @Length(8, 9)
  cep: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  rua: string;

  @Expose()
  @IsNumber()
  @IsOptional()
  numero?: number;

  @Expose()
  @IsString()
  @IsOptional()
  complemento?: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  bairro: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  cidade: string;

  @Expose()
  @IsEnum(UF)
  @IsNotEmpty()
  estado: UF;

  @Expose()
  @IsString()
  @IsOptional()
  ibge?: string;

  static converterAddress(address: AddressDto): Endereco {
    return {
      cep: address.zipCode,
      rua: address.street,
      numero: +address.number,
      bairro: address.neighborhood,
      cidade: address.city,
      estado: address.state,
      ...address.complement ? { complemento: address.complement } : {}
    }
  }
}


export function formataEndereco(endereco: Endereco | AddressDto): string {
  if (endereco instanceof Endereco) {
    return `${endereco.cep} - ${endereco.rua}, ${endereco.numero}${endereco.complemento ? ', ' + endereco.complemento : ''}, ${endereco.bairro} - ${endereco.cidade} - ${endereco.estado}`;
  }

  return `${endereco.zipCode} - ${endereco.street}, ${endereco.number}${endereco.complement ? ', ' + endereco.complement : ''}, ${endereco.neighborhood} - ${endereco.city} - ${endereco.state}`;
}

