import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { FooterModule, HeaderModule } from '../../../../components';
import { CadastroPagamentosResumoComponent } from './cadastro-pagamentos-resumo/cadastro-pagamentos-resumo.component';
import { PagamentosListagemComponent } from './pagamentos-listagem.component';
import { PagamentoItemComponent } from './pagamento-item/pagamento-item.component';

@NgModule({
  declarations: [
    PagamentosListagemComponent,
    CadastroPagamentosResumoComponent,
    PagamentoItemComponent
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,

    // components
    HeaderModule,
    FooterModule,
  ],
  exports: [
    PagamentosListagemComponent,
  ]
})
export class PagamentosListagemModule { }
