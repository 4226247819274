import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AddressPipe } from './address.pipe';
import { ContratoTipoAssinaturaPipe } from './contrato-tipo-assinatura.pipe';
import { DocumentoTipoPipe } from './documento-tipo.pipe';
import { EnderecoPipe } from './endereco.pipe';
import { EstadoCivilPipe } from './estado-civil.pipe';
import { GeneroPipe } from './genero.pipe';

import { AlteracaoTipoPipe } from './alteracao-tipo.pipe';
import { CadastroStatusPipe } from './cadastro-status.pipe';
import { ImovelOcupacaoPipe } from './imovel-ocupacao.pipe';
import { ImovelSituacaoPipe } from './imovel-situacao.pipe';
import { ImovelUsoPipe } from './imovel-uso.pipe';
import { NotificacaoTipoPipe } from './notificacao-tipo.pipe';
import { NumeroExtensoPipe } from './numero-extenso.pipe';
import { ParcelasPipe } from './parcelas.pipe';
import { RegimeDeBensPipe } from './regime-de-bens.pipe';
import { RendaFamiliarPipe } from './renda-familiar.pipe';
import { ReurbModalidadePipe } from './reurb-modalidade.pipe';
import { StatusPreenchimentoPipe } from './status-preenchimento.pipe';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    AddressPipe,
    AlteracaoTipoPipe,
    CadastroStatusPipe,
    ContratoTipoAssinaturaPipe,
    DocumentoTipoPipe,
    EnderecoPipe,
    EstadoCivilPipe,
    GeneroPipe,
    ImovelOcupacaoPipe,
    ImovelSituacaoPipe,
    ImovelUsoPipe,
    NotificacaoTipoPipe,
    NumeroExtensoPipe,
    ParcelasPipe,
    RegimeDeBensPipe,
    RendaFamiliarPipe,
    ReurbModalidadePipe,
    StatusPreenchimentoPipe,
  ],
  exports: [
    AddressPipe,
    AlteracaoTipoPipe,
    CadastroStatusPipe,
    ContratoTipoAssinaturaPipe,
    DocumentoTipoPipe,
    EnderecoPipe,
    EstadoCivilPipe,
    GeneroPipe,
    ImovelOcupacaoPipe,
    ImovelSituacaoPipe,
    ImovelUsoPipe,
    NotificacaoTipoPipe,
    NumeroExtensoPipe,
    ParcelasPipe,
    RegimeDeBensPipe,
    RendaFamiliarPipe,
    ReurbModalidadePipe,
    StatusPreenchimentoPipe,
  ]
})
export class PipesModule { }
