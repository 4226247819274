/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import {
  GetUpdateOwnerContactDto,
  ProjetoSimplificadoDto,
  RegistrationStatus,
  RevisaoDto,
  STATUS_CADASTROS_CANCELADOS,
  StatusPreenchimento
} from '@usucampeao/lib-reurb-simplificado';
import { CadastroFichaDto, ImovelStateDto } from '../../../domain';

@Component({
  selector: 'usucampeao-cadastros-ficha-v2',
  templateUrl: './cadastros-ficha-v2.component.html',
  styleUrls: ['./cadastros-ficha-v2.component.scss']
})
export class CadastrosFichaV2Component implements OnChanges {
  @Input() cadastro: CadastroFichaDto;
  @Input() projeto: ProjetoSimplificadoDto;
  @Input() imovel: ImovelStateDto;
  @Input() quantidadePagamentosNaoPagos = 0;
  @Input() revisao: RevisaoDto;
  @Input() dadosContatoProprietario?: GetUpdateOwnerContactDto;

  @Output() aoAdicionarFotoFachada = new EventEmitter<File>();
  @Output() aoEnviarParaAnalise = new EventEmitter<void>();
  @Output() aoAlterarContato = new EventEmitter<void>();

  public imovelStatus: StatusPreenchimento;
  public proprietariosStatus: StatusPreenchimento;
  public quadraLoteStatus: StatusPreenchimento;
  public cadastroStatus: StatusPreenchimento;

  public idBotaoFluxoContinuar: string;

  constructor(
    private alertCtrl: AlertController,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.cadastro) {
      this.configurarIdBotaoFluxoContinuar();
      this.configurarImovelStatus();
      this.configurarProprietariosStatus();
      this.configurarQuadraLoteStatus();
      this.configurarCadastroStatus();
    }
  }

  /**
   * Foto da fachada do imóvel
   */
  public get fotoFachadaImovel(): string {
    if (!this.imovel?.files) {
      return '';
    }

    const [fotoFachada] = this.imovel.files;
    if (fotoFachada && this.imovel.id) {
      return `/properties/${this.imovel.id}/photo/${fotoFachada.id}`;
    }
  }

  public get mostrarResumoPagamento(): boolean {
    return !!this.cadastro.valorContrato && !!this.cadastro.contratoDataAssinatura && !STATUS_CADASTROS_CANCELADOS.includes(this.cadastro.status);
  }

  public get cadastroStatusLista(): typeof RegistrationStatus {
    return RegistrationStatus;
  }

  private configurarImovelStatus(): void {
    const dadosImovel = this.cadastro?.fillInStatus?.propertyData?.fillInStatus;
    if (!dadosImovel) {
      this.imovelStatus = StatusPreenchimento.PENDENTE;
      return;
    }

    const camposImovelParaVerificacao = [
      dadosImovel.additionalInformationsData,
      dadosImovel.addressData,
      dadosImovel.documents,
    ];
    const possuiDadosEmAlteracoesNecessarias = camposImovelParaVerificacao.includes(StatusPreenchimento.ALTERACAO_NECESSARIA);
    if (possuiDadosEmAlteracoesNecessarias) {
      this.imovelStatus = StatusPreenchimento.ALTERACAO_NECESSARIA;
      return;
    }

    const possuiDadosPendentes = camposImovelParaVerificacao.includes(StatusPreenchimento.PENDENTE);
    if (possuiDadosPendentes) {
      this.imovelStatus = StatusPreenchimento.PENDENTE;
      return;
    }

    this.imovelStatus = StatusPreenchimento.ENVIADO;
  }

  private configurarProprietariosStatus(): void {
    const dadosProprietarios = this.cadastro?.fillInStatus?.ownersData;
    if (!dadosProprietarios) {
      this.proprietariosStatus = StatusPreenchimento.PENDENTE;
      return;
    }

    this.proprietariosStatus = this.cadastro?.fillInStatus?.ownersData?.reduce(
      (acc: StatusPreenchimento, curr) => {
        const statusPreenchimento = curr.fillInStatus;
        const possuiDadosEmAlteracoesNecessarias = [
          statusPreenchimento.contactData,
          statusPreenchimento.documents,
          statusPreenchimento.documentsData,
          statusPreenchimento.personalData,
        ]
          .includes(StatusPreenchimento.ALTERACAO_NECESSARIA);

        const possuiDadosPendentes = [
          statusPreenchimento.contactData,
          statusPreenchimento.documents,
          statusPreenchimento.documentsData,
          statusPreenchimento.personalData,
        ]
          .includes(StatusPreenchimento.PENDENTE);

        if (possuiDadosEmAlteracoesNecessarias) {
          acc = StatusPreenchimento.ALTERACAO_NECESSARIA;
        } else if (acc !== StatusPreenchimento.ALTERACAO_NECESSARIA && possuiDadosPendentes) {
          acc = StatusPreenchimento.PENDENTE;
        }

        return acc;
      },
      StatusPreenchimento.ENVIADO
    );
  }

  private configurarQuadraLoteStatus(): void {
    const dadosImovel = this.cadastro?.fillInStatus?.propertyData?.fillInStatus;
    if (!dadosImovel) {
      this.quadraLoteStatus = StatusPreenchimento.PENDENTE;
      return;
    }

    this.quadraLoteStatus = dadosImovel.localizationData;
  }

  private configurarCadastroStatus(): void {
    if (!this.cadastro?.statusPreenchimento) {
      this.cadastroStatus = StatusPreenchimento.PENDENTE;
      return;
    }

    const { contrato, procuracao } = this.cadastro.statusPreenchimento;
    const statusContratoProcuracao = [contrato, procuracao];

    const possuiDocumentosEmAlteracaoNecessaria = statusContratoProcuracao.includes(StatusPreenchimento.ALTERACAO_NECESSARIA);
    if (possuiDocumentosEmAlteracaoNecessaria) {
      this.cadastroStatus = StatusPreenchimento.ALTERACAO_NECESSARIA;
      return;
    }

    const possuiDocumentosPendentes = statusContratoProcuracao.includes(StatusPreenchimento.PENDENTE);
    if (possuiDocumentosPendentes) {
      this.cadastroStatus = StatusPreenchimento.PENDENTE;
      return;
    }

    this.cadastroStatus = StatusPreenchimento.ENVIADO;
  }

  public configurarIdBotaoFluxoContinuar(): void {
    switch (this.cadastro?.status) {
      case RegistrationStatus.PRONTO_PARA_FECHAR_CONTRATO:
        this.idBotaoFluxoContinuar = 'ficha-cadastro-contrato-fechar';
        break;

      case RegistrationStatus.AGUARDANDO_ASSINATURA_CONTRATO:
        this.idBotaoFluxoContinuar = 'ficha-cadastro-contrato-assinar';
        break;

      default:
        this.idBotaoFluxoContinuar = 'ficha-cadastro-fluxo-continuar';
        break;
    }
  }

  public get textoBotao(): string {
    switch (this.cadastro.status) {
      case RegistrationStatus.INCOMPLETE:
      case RegistrationStatus.CONTRATO_FECHADO:
      case RegistrationStatus.AVAILABLE_SCHEDULE:
        return 'Continuar';

      case RegistrationStatus.READY_TO_SUBMIT:
        return 'Enviar para análise';

      case RegistrationStatus.CHANGES_REQUESTED:
        return 'Reenviar para análise ';

      case RegistrationStatus.PRONTO_PARA_FECHAR_CONTRATO:
        return 'Fechar contrato';

      case RegistrationStatus.AGUARDANDO_ASSINATURA_CONTRATO:
        return 'Finalizar aceite de contrato';

      default:
        return null;
    }
  }

  public navegarPara(rota: string): void {
    this.router.navigate([rota], { relativeTo: this.route })
  }

  public continuar(): void {
    const statusFecharContrato = [RegistrationStatus.READY_TO_SUBMIT, RegistrationStatus.PRONTO_PARA_FECHAR_CONTRATO];
    const statusPreenchimentoInvalidos = [StatusPreenchimento.PENDENTE, StatusPreenchimento.ALTERACAO_NECESSARIA];
    const imovelDadosInvalidos = statusPreenchimentoInvalidos.includes(this.imovelStatus);
    const proprietariosDadosInvalidos = statusPreenchimentoInvalidos.includes(this.proprietariosStatus);
    const cadastroDadosInvalidos = statusPreenchimentoInvalidos.includes(this.cadastroStatus);

    if (statusFecharContrato.includes(this.cadastro.status)) {
      if (this.cadastro.contratoDataAssinatura) {
        this.mostrarAlertaEnvioAnalise();
      } else {
        this.navegarPara(!this.projeto.semPagamento ? 'contrato' : 'contrato/selecionar-forma-pagamento');
      }

    } else if (this.cadastro.status === RegistrationStatus.AGUARDANDO_ASSINATURA_CONTRATO) {
      if (this.cadastro.contratoTipoAssinatura) {
        this.navegarPara(`contrato/assinar`);
      } else {
        this.navegarPara(`contrato/selecionar-tipo-assinatura`);
      }

    } else if (imovelDadosInvalidos) {
      const dadosImovel = this.cadastro.fillInStatus.propertyData?.fillInStatus;
      if (statusPreenchimentoInvalidos.includes(dadosImovel.localizationData)) {
        this.navegarPara(`imovel/${this.cadastro.propertyId}/mapa`);
      } else if (
        statusPreenchimentoInvalidos.includes(dadosImovel.addressData) ||
        statusPreenchimentoInvalidos.includes(dadosImovel.additionalInformationsData) ||
        statusPreenchimentoInvalidos.includes(dadosImovel.documents)
      ) {
        this.navegarPara(`imovel/${this.cadastro.propertyId}/editar`);
      }

    } else if (proprietariosDadosInvalidos) {
      this.navegarPara('proprietarios');
    } else if (cadastroDadosInvalidos) {
      this.navegarPara('documentos');
    }
  }

  private async mostrarAlertaEnvioAnalise(): Promise<void> {
    const alert = await this.alertCtrl.create({
      header: 'Confirmar o envio?',
      message: `
      <p>Caso tenha finalizado as correções, envie o cadastro para análise para continuar o processo.</p>
      <p>Enquanto o cadastro estiver em análise, não será possível alterá-lo.</p>
      <p>Enviar para análise?</p>
      `,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel'
        },
        {
          text: 'Enviar',
          role: 'confirm',
          handler: () => {
            this.aoEnviarParaAnalise.emit();
          }
        }
      ],
    });

    await alert.present();
  }

  public adicionarFotoFachada(arquivos: File[]): void {
    this.aoAdicionarFotoFachada.emit(arquivos[0]);
  }
}
