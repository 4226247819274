import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CriarContaComponent } from './criar-conta.component';

const routes: Routes = [
  { path: '', component: CriarContaComponent },
  {
    path: 'cadastrar-foto',
    loadChildren: () => import('./cadastrar-foto/cadastrar-foto.module').then(m => m.CadastrarFotoModule)
  },
  {
    path: 'cadastro-finalizado',
    loadChildren: () => import('./cadastro-completo/cadastro-completo.module').then(m => m.CadastroCompletoModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CriarContaRoutingModule { }
