/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { PipesModule } from '@usucampeao/utils-frontend';
import { NgxMaskModule } from 'ngx-mask';
import { FooterModule, ModalSelecaoTipoEnvioLinkModule } from '../../../components';
import { HeaderModule } from "../../../components/header/header.module";
import { NotificacaoIconeModule } from '../notificacao-icone/notificacao-icone.module';
import { AlteracoesNecessariasComponent } from './alteracoes-necessarias/alteracoes-necessarias.component';
import { CadastroEmAnaliseComponent } from './cadastro-em-analise/cadastro-em-analise.component';
import { CadastroValidadoComponent } from './cadastro-validado/cadastro-validado.component';
import { ContratoExpiradoComponent } from './contrato-expirado/contrato-expirado.component';
import { ContratoFechadoComponent } from './contrato-fechado/contrato-fechado.component';
import { NotificacoesDetalhesComponent } from './notificacoes-detalhes.component';
import { ProjetoAprovadoPelaPrefeituraComponent } from './projeto-aprovado-pela-prefeitura/projeto-aprovado-pela-prefeitura.component';
import { ProjetoAtendendoDevolutivasCartorioComponent } from './projeto-atendendo-devolutivas-cartorio/projeto-atendendo-devolutivas-cartorio.component';
import { ProjetoAtendendoDevolutivasPrefeituraComponent } from './projeto-atendendo-devolutivas-prefeitura/projeto-atendendo-devolutivas-prefeitura.component';
import { ProjetoCadastroSocialPerimetroFinalComponent } from './projeto-cadastro-social-perimetro-final/projeto-cadastro-social-perimetro-final.component';
import { ProjetoEnviadoPrefeituraComponent } from './projeto-enviado-a-prefeitura/projeto-enviado-a-prefeitura.component';
import { ProjetoInstauracaoNucleoComponent } from './projeto-instauracao-nucleo/projeto-instauracao-nucleo.component';
import { ProjetoRegistradoCartorioComponent } from './projeto-registrado-cartorio/projeto-registrado-cartorio.component';
import { ProjetoRelatorioTecnicoELepacComponent } from './projeto-relatorio-tecnico-lepac/projeto-relatorio-tecnico-lepac.component';
import { ProjetoTituloEmitidoComponent } from './projeto-titulo-emitido/projeto-titulo-emitido.component';
import { ProjetoUrbanisticoComponent } from './projeto-urbanistico/projeto-urbanistico.component';
import { ProntoParaFecharContratoComponent } from './pronto-para-fechar-contrato/pronto-para-fechar-contrato.component';
import { VencimentoAmanhaComponent } from './vencimento-amanha/vencimento-amanha.component';
import { VencimentoProximoComponent } from './vencimento-próximo/vencimento-proximo.component';

@NgModule({
  declarations: [
    AlteracoesNecessariasComponent,
    CadastroEmAnaliseComponent,
    CadastroValidadoComponent,
    ContratoExpiradoComponent,
    ContratoFechadoComponent,
    NotificacoesDetalhesComponent,
    ProjetoAtendendoDevolutivasCartorioComponent,
    ProjetoAtendendoDevolutivasPrefeituraComponent,
    ProjetoAprovadoPelaPrefeituraComponent,
    ProjetoCadastroSocialPerimetroFinalComponent,
    ProjetoEnviadoPrefeituraComponent,
    ProjetoInstauracaoNucleoComponent,
    ProjetoRegistradoCartorioComponent,
    ProjetoRelatorioTecnicoELepacComponent,
    ProjetoTituloEmitidoComponent,
    ProntoParaFecharContratoComponent,
    ProjetoUrbanisticoComponent,
    VencimentoAmanhaComponent,
    VencimentoProximoComponent,
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,
    // components
    HeaderModule,
    FooterModule,
    ModalSelecaoTipoEnvioLinkModule,
    NotificacaoIconeModule,
    // external
    NgxMaskModule.forRoot(),
    //others
    PipesModule,
  ],
  exports: [NotificacoesDetalhesComponent]
})
export class NotificacoesDetalhesModule { }
