import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './pages/auth/guard/auth.guard';
import { PossuiUsuarioGuard } from './pages/auth/guard/possui-usuario.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'atendimentos/:id',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/auth/pages/login/login.module').then((m) => m.LoginModule)
  },
  {
    path: 'criar-conta',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/auth/pages/criar-conta/criar-conta.module').then(m => m.CriarContaModule)
  },
  {
    path: 'home',
    canActivate: [PossuiUsuarioGuard],
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: 'meus-imoveis',
    canActivate: [PossuiUsuarioGuard],
    loadChildren: () =>
      import('./pages/meus-imoveis/meus-imoveis.module').then((m) => m.MeusImoveisPageModule),
  },
  {
    path: 'novo-cadastro',
    canActivate: [PossuiUsuarioGuard],
    loadChildren: () =>
      import('./pages/cadastros-novo/cadastros-novo.module').then((m) => m.CadastrosNovoModule)
  },
  {
    path: 'cadastros/:cadastroId',
    canActivate: [PossuiUsuarioGuard],
    loadChildren: () =>
      import('./pages/cadastros/cadastros.module').then((m) => m.CadastrosModule)
  },
  {
    path: 'notificacoes',
    canActivate: [PossuiUsuarioGuard],
    loadChildren: () =>
      import('./pages/notifications/notification.module').then((m) => m.NotificationPageModule)
  },
  {
    path: 'ajuda',
    canActivate: [PossuiUsuarioGuard],
    loadChildren: () => import('./pages/help/help.page.module').then(m => m.HelpPageModule)
  },
  {
    path: 'perfil',
    canActivate: [PossuiUsuarioGuard],
    loadChildren: () => import('./pages/perfil/perfil.module').then(m => m.PerfilModule)
  },
  {
    path: 'meus-boletos',
    canActivate: [PossuiUsuarioGuard],
    loadChildren: () => import('./pages/boletos/boletos.module').then(m => m.BoletosPageModule)
  },
  {
    path: '**',
    redirectTo: 'home',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
