import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TipoContract } from '@usucampeao/lib-contratos';
import { CadastroContratoDto, PropertyDto, ProprietariosCadastroDto } from '@usucampeao/lib-reurb-simplificado';
@Component({
  selector: 'usucampeao-modal-contrato-procuracao',
  templateUrl: './modal-contrato-procuracao.component.html',
  styleUrls: ['./modal-contrato-procuracao.component.scss']
})
export class ModalContratoProcuracaoComponent implements OnInit {
  @Input() tipo: TipoContract;
  @Input() dadosContrato: CadastroContratoDto;

  public proprietario!: ProprietariosCadastroDto;
  public conjuge!: ProprietariosCadastroDto;

  constructor(private modalCtrl: ModalController) { }

  ngOnInit(): void {
    this.proprietario = this.dadosContrato.proprietarios.find(proprietario => proprietario.main) as ProprietariosCadastroDto;
    this.conjuge = this.dadosContrato.proprietarios.find(proprietario => !proprietario.main) as ProprietariosCadastroDto;
  }

  public get imovel(): PropertyDto {
    return this.dadosContrato?.imovel;
  }

  public dismiss(): void {
    this.modalCtrl.dismiss();
  }
}
