import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { PAGAMENTOS_STATUS_PAGOS, PAGAMENTOS_STATUS_PENDENTES, PagamentoListagemDto, PagamentoStatus } from '@usucampeao/lib-reurb-simplificado';

type PagamentoResumoProps = {
  quantidade: number;
  valor: number;
}

type PagamentoResumo = {
  pendentes?: PagamentoResumoProps;
  pagos?: PagamentoResumoProps;
  vencidos?: PagamentoResumoProps;
  emAnalise?: PagamentoResumoProps;
}

@Component({
  selector: 'usucampeao-cadastro-pagamentos-resumo',
  templateUrl: './cadastro-pagamentos-resumo.component.html',
  styleUrls: ['./cadastro-pagamentos-resumo.component.scss']
})
export class CadastroPagamentosResumoComponent implements OnInit, OnChanges {
  @Input() pagamentos: PagamentoListagemDto[] = [];

  public resumo: PagamentoResumo = {}

  ngOnInit(): void {
    this.gerarResumo();
  }

  ngOnChanges(): void {
    this.gerarResumo();
  }

  private gerarResumo(): void {
    const pagamentosPendentes = this.pagamentos.filter((pagamento) => PAGAMENTOS_STATUS_PENDENTES.includes(pagamento.status));
    const pagamentosPagos = this.pagamentos.filter((pagamento) => PAGAMENTOS_STATUS_PAGOS.includes(pagamento.status));
    const pagamentosVencidos = this.pagamentos.filter((pagamento) => pagamento.status === PagamentoStatus.NAO_PAGO);
    const pagamentosEmAnalise = this.pagamentos.filter((pagamento) => pagamento.status === PagamentoStatus.EM_ANALISE);

    this.resumo.pendentes = {
      quantidade: pagamentosPendentes.length,
      valor: this.somarParcelas(pagamentosPendentes)
    }
    this.resumo.pagos = {
      quantidade: pagamentosPagos.length,
      valor: this.somarParcelas(pagamentosPagos)
    }
    this.resumo.vencidos = {
      quantidade: pagamentosVencidos.length,
      valor: this.somarParcelas(pagamentosVencidos)
    }
    this.resumo.emAnalise = {
      quantidade: pagamentosEmAnalise.length,
      valor: this.somarParcelas(pagamentosEmAnalise)
    }
  }

  private somarParcelas(pagamentos: PagamentoListagemDto[]): number {
    if (!pagamentos || pagamentos.length === 0) {
      return 0
    }
    return pagamentos.map((item) => item.valorDevido).reduce((acc, parcela) => acc + parcela, 0)
  }

}
