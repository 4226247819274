import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { DocumentosUploadModule } from '@usucampeao/ui-mobile';
import { OutrosDocumentosUploadRoutingModule } from './outros-documentos-upload-routing.module';
import { OutrosDocumentosUploadPage } from './outros-documentos-upload.page';

@NgModule({
  declarations: [
    OutrosDocumentosUploadPage
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,

    // components
    DocumentosUploadModule,

    // route
    OutrosDocumentosUploadRoutingModule
  ]
})
export class OutrosDocumentosUploadModule { }
