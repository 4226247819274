/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertController, ModalController } from '@ionic/angular';
import { CadastroContratoAssinaturaTipoEnvioLink } from '@usucampeao/interfaces';
import { MaskPipe } from 'ngx-mask';
import { ModalEditarInfosContatoComponent, ModalEditarInfosContatoOutput } from './modal-editar-infos-contato/modal-editar-infos-contato.component';

export type ModalSelecaoTipoEnvioLinkOutput = {
  tipoEnvioLink: CadastroContratoAssinaturaTipoEnvioLink;
  confirmarSelecaoTipo: boolean;
  realizouAlteracaoContato: boolean;
};

@Component({
  selector: 'usucampeao-modal-selecao-tipo-envio-link',
  templateUrl: './modal-selecao-tipo-envio-link.component.html',
  styleUrls: ['./modal-selecao-tipo-envio-link.component.scss']
})
export class ModalSelecaoTipoEnvioLinkComponent {
  @Input() cadastroId: string;
  @Input() proprietarioId: string;
  @Input() proprietarioEmail: string;
  @Input() proprietarioWhatsapp: string;
  @Input() falhaEnvio = false;
  @Input() contatoEnviado: string; // Contato que foi enviado o link. Seria o email ou whatsapp do proprietário.

  public form: FormGroup;
  private realizouAlteracaoContato = false;

  constructor(
    private readonly alertCtrl: AlertController,
    private readonly formBuilder: FormBuilder,
    private readonly maskPipe: MaskPipe,
    private readonly modalController: ModalController,
  ) {
    this.form = this.formBuilder.group({
      tipoEnvioLink: [null, Validators.required],
    });

  }

  public get cadastroContratoAssinaturaTipoEnvioLink(): typeof CadastroContratoAssinaturaTipoEnvioLink {
    return CadastroContratoAssinaturaTipoEnvioLink;
  }

  private get contatoASerEnviadoFormatado(): string {
    return this.tipoEnvioSelecionado === CadastroContratoAssinaturaTipoEnvioLink.EMAIL ?
      this.contatoASerEnviado :
      this.maskPipe.transform(this.contatoASerEnviado, '(00) 00000-0000');
  }

  private get contatoASerEnviado(): string {
    return this.tipoEnvioSelecionado === CadastroContratoAssinaturaTipoEnvioLink.EMAIL ? this.proprietarioEmail : this.proprietarioWhatsapp;
  }

  private get tipoEnvioSelecionado(): CadastroContratoAssinaturaTipoEnvioLink {
    return this.form.value.tipoEnvioLink;
  }

  public async mostrarModalEditarInfosContato(): Promise<void> {
    const modal = await this.modalController.create({
      component: ModalEditarInfosContatoComponent,
      componentProps: {
        proprietarioEmail: this.proprietarioEmail,
        proprietarioWhatsapp: this.proprietarioWhatsapp,
        cadastroId: this.cadastroId,
        proprietarioId: this.proprietarioId
      },
      cssClass: 'modal-editar-infos-contato',
    });
    await modal.present();

    const { data } = await modal.onDidDismiss<ModalEditarInfosContatoOutput>();
    this.realizouAlteracaoContato = data.email !== this.proprietarioEmail || data.whatsapp !== this.proprietarioWhatsapp;
    this.proprietarioEmail = data.email;
    this.proprietarioWhatsapp = data.whatsapp;
  }


  public submitForm(): void {
    if (this.form.invalid) {
      return;
    }

    if (this.falhaEnvio && this.contatoASerEnviado === this.contatoEnviado) {
      this.mostrarAlertaDeMesmoContato();
      return;
    }

    this.fecharModal(true);
  }

  private async mostrarAlertaDeMesmoContato(): Promise<void> {
    const alert = await this.alertCtrl.create({
      header: 'Atenção!',
      message: `
        <p class="font-text">Não foi possível enviar o contrato para ${this.contatoASerEnviadoFormatado}</p>
        <p class="font-text">Altere a informação de contato ou a forma de envio de link antes de continuar.</p>
      `,
      buttons: [
        {
          text: 'Voltar',
          role: 'cancel',
        },
      ],
    });
    await alert.present();
  }

  public fecharModal(confirmarSelecaoTipo = false): void {
    const { tipoEnvioLink } = this.form.value;
    this.modalController.dismiss({ tipoEnvioLink, confirmarSelecaoTipo, realizouAlteracaoContato: this.realizouAlteracaoContato });
  }
}
