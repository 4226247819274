import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  templateUrl: 'lote-selecionar-tutorial.component.html',
  styleUrls: ['lote-selecionar-tutorial.component.scss'],
})
export class LoteSelecionarTutorialComponent {
  constructor(
    private readonly modalCtrl: ModalController,
  ) { }


  public fecharModal(): void {
    this.modalCtrl.dismiss();
  }
}
