import { Component, Input } from '@angular/core';
import { NotificacaoDto } from '@usucampeao/lib-reurb-simplificado';

@Component({
  selector: 'usucampeao-projeto-registrado-cartorio',
  templateUrl: './projeto-registrado-cartorio.component.html',
  styleUrls: ['./projeto-registrado-cartorio.component.scss'],
})
export class ProjetoRegistradoCartorioComponent {
  @Input() public notificacao: NotificacaoDto;
}
