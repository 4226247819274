import { Expose, plainToInstance } from 'class-transformer';
import { TimeStatus } from './time-status.enum';

export class TimeDetalhesDto {
  @Expose()
  id: number;

  @Expose()
  nome: string;

  @Expose({ name: 'usuario_responsavel' })
  usuarioResponsavel: string;

  @Expose()
  cidade: string;

  @Expose({ name: 'usuario_criacao' })
  usuarioCriacao: string;

  @Expose({ name: 'data_criacao' })
  dataCriacao: Date;

  @Expose()
  status: TimeStatus;

  /**
   * Cria uma nova instância de TimeDetalhesDto a partir de dtos
   * @param props Interface de TimeDetalhes
   * @returns nova instância de TimeDetalhesDto
   */
  static from(props?: TimeDetalhes): TimeDetalhesDto {
    return plainToInstance(TimeDetalhesDto, props, { exposeUnsetFields: false, excludeExtraneousValues: true });
  }
}

export interface TimeDetalhes {
  id: number;
  nome: string;
  usuario_responsavel: string;
  cidade: string;
  usuario_criacao: string;
  data_criacao: Date;
  status: TimeStatus;
}
