import { Pipe, PipeTransform } from '@angular/core';
import { TipoNotificacao } from '@usucampeao/lib-reurb-simplificado';

@Pipe({
  name: 'notificacaoTipo',
})
export class NotificacaoTipoPipe implements PipeTransform {
  transform(notificacaoTipo: TipoNotificacao): string {
    switch (notificacaoTipo) {
      case TipoNotificacao.CADASTRO_PRONTO_PARA_FECHAR_CONTRATO:
        return 'Pronto para fechar contrato';

      case TipoNotificacao.CADASTRO_CONTRATO_FECHADO:
        return 'Contrato fechado';

      case TipoNotificacao.CADASTRO_ALTERACOES_NECESSARIAS:
        return 'Alterações necessárias';

      case TipoNotificacao.CADASTRO_CONTRATO_EXPIRADO:
        return 'Prazo de assinatura expirado';

      case TipoNotificacao.CADASTRO_EM_ANALISE:
        return 'Cadastro em análise';

      case TipoNotificacao.CADASTRO_VALIDADO:
        return 'Cadastro validado';

      case TipoNotificacao.BOLETO_VENCE_PROXIMO:
      case TipoNotificacao.BOLETO_VENCE_PROXIMO_DEPRECATED:
        return 'Vencimento próximo';

      case TipoNotificacao.BOLETO_VENCE_AMANHA:
      case TipoNotificacao.BOLETO_VENCE_AMANHA_DEPRECATED:
        return 'Vencimento amanhã';

      case TipoNotificacao.PROJETO_INSTAURACAO_NUCLEO:
        return 'Instauração do núcleo';

      case TipoNotificacao.PROJETO_RELATORIO_TECNICO_E_LEPAC:
        return 'Relatório técnico e LEPAC';

      case TipoNotificacao.PROJETO_CADASTRO_SOCIAL_E_PERIMETRO_FINAL:
        return 'Cadastro social e perímetro final';

      case TipoNotificacao.PROJETO_PROJETO_URBANISTICO:
        return 'Projeto urbanístico';

      case TipoNotificacao.PROJETO_ENVIADO_A_PREFEITURA:
        return 'Projeto enviado à prefeitura';

      case TipoNotificacao.PROJETO_ATENDENDO_DEVOLUTIVAS_DA_PREFEITURA:
        return 'Atendendo devolutivas da prefeitura';

      case TipoNotificacao.PROJETO_APROVADO_PELA_PREFEITURA:
        return 'Aprovado pela prefeitura';

      case TipoNotificacao.PROJETO_REGISTRADO_EM_CARTORIO:
        return 'Registrado em cartório';

      case TipoNotificacao.PROJETO_ATENDENDO_DEVOLUTIVAS_DO_CARTORIO:
        return 'Atendendo devolutivas do cartório';

      case TipoNotificacao.PROJETO_TITULO_EMITIDO:
        return 'Venha buscar seu título!';

      default:
        return notificacaoTipo;
    }
  }
}
