import { IonicModule } from '@ionic/angular';
import { SwiperModule } from 'swiper/angular';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FooterModule } from '@usucampeao/ui-mobile';
import { TutorialModal } from './tutorial.modal';

@NgModule({
  imports: [
    // root
    CommonModule,
    IonicModule,

    // components
    FooterModule,

    // external libs
    SwiperModule,
  ],

  declarations: [TutorialModal],

  exports: [TutorialModal],
})
export class TutorialModule { }
