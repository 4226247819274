import { RemoveSpecialCharacters } from '@usucampeao/utils';
import { Expose, Transform } from 'class-transformer';
import { IsNotEmpty, IsNumberString, IsOptional, Length, ValidateIf } from 'class-validator';

export class PhoneDto {
  @Expose()
  @Transform(({ value }) => {
    if (value.startsWith('55')) {
      return value.substring(2);
    }
    return value;
  })
  @RemoveSpecialCharacters()
  @IsNumberString()
  @Length(11, 11)
  @IsNotEmpty()
  @ValidateIf((o: PhoneDto) => !o.home && !o.message)
  cellphone: string;

  @Expose()
  @RemoveSpecialCharacters()
  @IsNumberString()
  @Length(10, 11)
  @IsNotEmpty()
  @ValidateIf((o: PhoneDto) => !o.cellphone && !o.message)
  home?: string;

  @Expose()
  @IsNumberString()
  @Length(10, 11)
  @IsNotEmpty()
  @ValidateIf((o: PhoneDto) => !o.home && !o.cellphone)
  message?: string;
}


export class PhonePartialDto {
  @Expose()
  @RemoveSpecialCharacters()
  @IsNumberString()
  @Length(11, 11)
  @IsOptional()
  cellphone: string;

  @Expose()
  @RemoveSpecialCharacters()
  @IsNumberString()
  @Length(10, 11)
  @Transform(({ value }) => {
    if (!value) {
      return null;
    }
    return value;
  })
  @IsOptional()
  home?: string;

  @Expose()
  @IsNumberString()
  @Length(10, 11)
  @Transform(({ value }) => {
    if (!value) {
      return null;
    }
    return value;
  })
  @IsOptional()
  message?: string;
}
