import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ProjetoDetalhesDto } from '@usucampeao/lib-reurb-simplificado';

interface Fase {
  id: number,
  fase: string,
  text: string,
  days: string,
  obs?: string,
}

@Component({
  selector: 'usucampeao-projetos-calendario',
  templateUrl: './projetos-calendario.component.html',
  styleUrls: ['./projetos-calendario.component.scss'],
})
export class ProjetosCalendarioComponent {
  @Input() projeto: ProjetoDetalhesDto;

  constructor(
    private readonly modalCtrl: ModalController,
  ) { }

  public fecharModal(): void {
    this.modalCtrl.dismiss();
  }
}
