<section class="n-detalhes">
  <div class="ion-margin-bottom">
    <usucampeao-header title="Ver notificação"></usucampeao-header>
  </div>

  <div class="n-detalhes__row">
    <usucampeao-notificacao-icone [tipoNotificacao]="notificacao?.tipo"></usucampeao-notificacao-icone>
    <ion-text class="ion-margin-start">
      <h1 class="title title--medium ion-no-margin">{{ notificacao?.tipo | notificacaoTipo }}</h1>
      <p class="description-text ion-no-margin">{{ notificacao?.dataEnvio | date }} às {{ notificacao?.dataEnvio | date: 'HH:mm' }}</p>
    </ion-text>
  </div>

  <div class="n-detalhes__localizacao" *ngIf="cadastro">
    <ion-text>
      <h3 class="title title--medium ion-no-margin">{{ cadastro.projetoNome }}</h3>
      <p>
        <ion-icon name="map-outline"></ion-icon>
        Quadra {{ cadastro.quadra}} Lote {{ cadastro.lote}}
      </p>
      <p class="ion-no-margin">
        <ion-icon name="location-outline"></ion-icon>
        {{ cadastro.address | address }}
      </p>
    </ion-text>
  </div>

  <div class="n-detalhes__content" [ngSwitch]="notificacao.tipo">
    <usucampeao-pronto-para-fechar-contrato *ngSwitchCase="notificacaoTipo.CADASTRO_PRONTO_PARA_FECHAR_CONTRATO"
      [projetoId]="cadastro?.projetoFid" [cadastroId]="cadastro?.id">
    </usucampeao-pronto-para-fechar-contrato>

    <usucampeao-contrato-fechado *ngSwitchCase="notificacaoTipo.CADASTRO_CONTRATO_FECHADO">
    </usucampeao-contrato-fechado>

    <usucampeao-contrato-expirado *ngSwitchCase="notificacaoTipo.CADASTRO_CONTRATO_EXPIRADO"
      [projetoId]="cadastro?.projetoFid" [cadastroId]="cadastro?.id">
    </usucampeao-contrato-expirado>

    <usucampeao-cadastro-em-analise *ngSwitchCase="notificacaoTipo.CADASTRO_EM_ANALISE">
    </usucampeao-cadastro-em-analise>

    <usucampeao-cadastro-validado *ngSwitchCase="notificacaoTipo.CADASTRO_VALIDADO">
    </usucampeao-cadastro-validado>

    <usucampeao-alteracoes-necessarias *ngSwitchCase="notificacaoTipo.CADASTRO_ALTERACOES_NECESSARIAS"
      [notificacao]="notificacao">
    </usucampeao-alteracoes-necessarias>

    <usucampeao-projeto-instauracao-nucleo
      *ngSwitchCase="notificacaoTipo.PROJETO_INSTAURACAO_NUCLEO"></usucampeao-projeto-instauracao-nucleo>

    <usucampeao-projeto-relatorio-tecnico-lepac *ngSwitchCase="notificacaoTipo.PROJETO_RELATORIO_TECNICO_E_LEPAC">
    </usucampeao-projeto-relatorio-tecnico-lepac>

    <usucampeao-projeto-cadastro-social-perimetro-final
      *ngSwitchCase="notificacaoTipo.PROJETO_CADASTRO_SOCIAL_E_PERIMETRO_FINAL">
    </usucampeao-projeto-cadastro-social-perimetro-final>

    <usucampeao-projeto-urbanistico
      *ngSwitchCase="notificacaoTipo.PROJETO_PROJETO_URBANISTICO"></usucampeao-projeto-urbanistico>

    <usucampeao-projeto-enviado-a-prefeitura
      *ngSwitchCase="notificacaoTipo.PROJETO_ENVIADO_A_PREFEITURA"></usucampeao-projeto-enviado-a-prefeitura>

    <usucampeao-projeto-atendendo-devolutivas-prefeitura
      *ngSwitchCase="notificacaoTipo.PROJETO_ATENDENDO_DEVOLUTIVAS_DA_PREFEITURA">
    </usucampeao-projeto-atendendo-devolutivas-prefeitura>

    <usucampeao-projeto-aprovado-pela-prefeitura *ngSwitchCase="notificacaoTipo.PROJETO_APROVADO_PELA_PREFEITURA"
      [notificacao]="notificacao">
    </usucampeao-projeto-aprovado-pela-prefeitura>

    <usucampeao-projeto-registrado-cartorio *ngSwitchCase="notificacaoTipo.PROJETO_REGISTRADO_EM_CARTORIO"
      [notificacao]="notificacao"></usucampeao-projeto-registrado-cartorio>

    <usucampeao-projeto-atendendo-devolutivas-cartorio
      *ngSwitchCase="notificacaoTipo.PROJETO_ATENDENDO_DEVOLUTIVAS_DO_CARTORIO">
    </usucampeao-projeto-atendendo-devolutivas-cartorio>

    <usucampeao-projeto-titulo-emitido *ngSwitchCase="notificacaoTipo.PROJETO_TITULO_EMITIDO"
      [notificacao]="notificacao"></usucampeao-projeto-titulo-emitido>

      <usucampeao-vencimento-proximo *ngSwitchCase="notificacaoTipo.BOLETO_VENCE_PROXIMO"
      [notificacao]="notificacao"></usucampeao-vencimento-proximo>

      <usucampeao-vencimento-proximo *ngSwitchCase="notificacaoTipo.BOLETO_VENCE_PROXIMO_DEPRECATED"
      [notificacao]="notificacao"></usucampeao-vencimento-proximo>

      <usucampeao-vencimento-amanha *ngSwitchCase="notificacaoTipo.BOLETO_VENCE_AMANHA"
      [notificacao]="notificacao"></usucampeao-vencimento-amanha>

      <usucampeao-vencimento-amanha *ngSwitchCase="notificacaoTipo.BOLETO_VENCE_AMANHA_DEPRECATED"
      [notificacao]="notificacao"></usucampeao-vencimento-amanha>
  </div>

  <usucampeao-footer>
    <div class="n-detalhes__actions" *ngIf="cadastro">
      <ion-button class="ion-margin-bottom" mode="md"
        *ngIf="cadastro && notificacao?.tipo !== notificacaoTipo.CADASTRO_PRONTO_PARA_FECHAR_CONTRATO"
        (click)="navegarParaFicharDoCadastro()">
        Ver o cadastro
      </ion-button>
      <ion-button mode="md" fill="outline" (click)="voltar()">Voltar</ion-button>
    </div>
  </usucampeao-footer>
</section>
