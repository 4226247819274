<ion-text class="font-text">
  <p>
    Olá, {{ nomeUsuario$ | async }}
  </p>
  <p>
    Uma parcela do processo de regularização do imóvel localizado em {{ endereco?.rua }} {{ endereco?.numero }},
    {{ endereco?.bairro }}, {{ endereco?.cidade }}, está <strong>próxima do vencimento.</strong>
  </p>
  <p>
    O boleto da <strong>parcela
      {{ notificacaoSelecionada?.payload?.parcela ===0 ?'de Entrada': notificacaoSelecionada?.payload?.parcela }}</strong> no valor de
    <strong>{{ notificacaoSelecionada?.payload?.valorDevido | currency:'BRL' }},</strong> já está
    disponível e possui vencimento em <strong> {{ notificacaoSelecionada?.payload?.dataVencimento | date: 'dd/MM/yyyy' }}.</strong>
  </p>
  <p>
    <a class="text-link" (click)="navegarParaPagamento()">Clique aqui</a> para navegar diretamente para o cadastro e acessar o boleto.
  </p>
  <p>
    Você pode pagar este boleto através do aplicativo do seu banco, internet banking, em casas lotéricas, agências
    bancárias ou em caixas eletrônicos.
  </p>
</ion-text>
