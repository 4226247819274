export class NucleoDto {
  id: number;
  nome: string;
  arquivoNome: string;
  arquivoUrlStorage: string;
  coords: number[][][];
  cidadeId: number;
  usuarioCriacaoId: number;
  dataCriacao: Date;
  usuarioAtualizacaoId: number;
  dataAtualizacao: Date;
}
