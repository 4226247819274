<div class="datepicker">
  <ion-item mode="md" fill="solid">
    <ion-label mode="md" position="floating" [class.datepicker__label--disabled]="disabled">{{ label }}</ion-label>

    <ion-input mode="md" type="tel" [class.datepicker--disabled]="disabled"  [placeholder]="placeholder"
      [ngModel]="dataFormatada | mask: '00/00/0000'" maxlength="10" (ionChange)="setDate($event)" (ionBlur)="markAsTouched()" [readonly]="disabled">
    </ion-input>

    <div class="datepicker__suffix-icon" slot="end" [class.datepicker__suffix-icon--disabled]="disabled" (click)="openDatepickerModal()">
      <ion-icon color="medium" name="calendar">
      </ion-icon>
    </div>
  </ion-item>

  <div class="datepicker__error">
    <span>{{errorMessage}}</span>
  </div>
</div>
