import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';

import { ContratoAssinaturaTipoLinkModule, FooterModule, HeaderModule } from '@usucampeao/ui-mobile';
import { ContratoAssinaturaPageRoutingModule } from './contrato-assinatura-routing.module';
import { ContratoAssinaturaTipoFisicoComponent } from './contrato-assinatura-tipo-fisico/contrato-assinatura-tipo-fisico.component';
import { ContratoAssinaturaPage } from './contrato-assinatura.page';


@NgModule({
  imports: [
    // root
    CommonModule,
    IonicModule,

    // route
    ContratoAssinaturaPageRoutingModule,

    // components modules
    FooterModule,
    HeaderModule,
    ContratoAssinaturaTipoLinkModule,
  ],
  providers: [],
  declarations: [
    ContratoAssinaturaPage,
    ContratoAssinaturaTipoFisicoComponent,
  ],
})
export class ContratoAssinaturaPageModule { }
