import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'usucampeao-modal-confirmar-fechamento-contrato',
  templateUrl: './modal-confirmar-fechamento-contrato.component.html',
  styleUrls: ['./modal-confirmar-fechamento-contrato.component.scss']
})
export class ModalConfirmarFechamentoContratoComponent {

  constructor(
    private modalCtrl: ModalController,
  ) { }

  public fecharModal(confirmarEnvio = false): void {
    this.modalCtrl.dismiss(confirmarEnvio);
  }
}
