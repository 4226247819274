<section class="proprietarios-ficha">
  <usucampeao-header title="Proprietários"></usucampeao-header>

  <ion-text>
    <p class="description-text">Proprietário</p>
  </ion-text>
  <usucampeao-proprietario-ficha [proprietario]="proprietarioPrincipal"></usucampeao-proprietario-ficha>

  <ng-container *ngIf="conjuge">
    <ion-text>
      <p class="description-text ion-margin-top">Cônjuge/companheiro(a)</p>
    </ion-text>
    <usucampeao-proprietario-ficha [proprietario]="conjuge"></usucampeao-proprietario-ficha>
  </ng-container>

  <usucampeao-footer></usucampeao-footer>
</section>
