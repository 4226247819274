import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthQuery } from '../../pages/auth/state/auth.query';
import { AuthService } from '../../pages/auth/state/auth.service';

@Component({
  selector: 'usucampeao-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit {
  @Input() showBackButton = true;
  @Input() backRoute = '/home';
  @Input() editOption = false;

  defaultImage = '../../../assets/person-circle-outline.svg';

  constructor(
    public authQuery: AuthQuery,
    public authService: AuthService,
    private router: Router,
    private menu: MenuController,
  ) { }

  navigateToEditPhoto() {
    this.router.navigate(['/trocar-foto']);
  }

  ngOnInit(): void { }

  public get userPhoto(): Observable<string> {
    return this.authQuery.photoId$
      .pipe(
        map(id => id ? `/users/my-photo/${id}` : null)
      );
  }

  public close() {
    this.menu.close();
  }
}
