import { IsEnum, IsNotEmpty, IsOptional, IsString } from 'class-validator';
import { TipoAlteracao } from './tipo-alteracao.enum';

export class RevisaoAlteracaoRemoverDto {
  @IsEnum(TipoAlteracao)
  @IsNotEmpty()
  tipo: TipoAlteracao;

  @IsString()
  @IsOptional()
  proprietarioOuDocumentoId?: string;
}
