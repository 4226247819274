import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { PipesModule } from '@usucampeao/utils-frontend';
import { AlertaAlteracoesNecessariasComponent } from './alerta-alteracoes-necessarias.component';

@NgModule({
  declarations: [AlertaAlteracoesNecessariasComponent],
  imports: [
    // root
    CommonModule,
    IonicModule,

    // others
    PipesModule,
  ],
  exports: [AlertaAlteracoesNecessariasComponent],
})
export class AltertaAlteracoesNecessariasModule { }
