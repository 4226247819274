import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { PipesModule } from '@usucampeao/utils-frontend';
import { FooterModule, UploadFotoDocumentoModule } from '../../../components';
import { DocumentosUploadComponent } from './documentos-upload.component';

@NgModule({
  declarations: [
    DocumentosUploadComponent
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,

    // components
    FooterModule,
    UploadFotoDocumentoModule,

    // others
    PipesModule,
  ],
  exports: [
    DocumentosUploadComponent
  ]
})
export class DocumentosUploadModule { }
