import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ViewWillEnter } from '@ionic/angular';
import { AlteracaoDto, CadastroContratoDto, CadastroDocumentoTipo, DocumentFileDto, TipoAlteracao } from '@usucampeao/lib-reurb-simplificado';
import { LoadingService, ToastService } from '@usucampeao/ui-mobile';
import { Observable, forkJoin, throwError } from 'rxjs';
import { catchError, finalize, take, tap } from 'rxjs/operators';
import { CadastrosQuery } from '../state/cadastros.query';
import { CadastroService } from '../state/cadastros.service';

@Component({
  templateUrl: './outros-documentos.page.html',
  styleUrls: ['./outros-documentos.page.scss']
})
export class OutrosDocumentosPage implements ViewWillEnter {
  private cadastroId: string;

  public alteracoes$: Observable<AlteracaoDto[]>;
  public dadosContrato: CadastroContratoDto;
  public procuracaoDocumento: DocumentFileDto;
  public contratoDocumento: DocumentFileDto;

  constructor(
    private cadastrosQuery: CadastrosQuery,
    private cadastroService: CadastroService,
    private loadingService: LoadingService,
    private route: ActivatedRoute,
    private toastService: ToastService,
  ) { }

  ionViewWillEnter(): void {
    this.route.params
      .pipe(
        tap(params => this.cadastroId = params.cadastroId),
        tap(() => this.buscarDadosContrato()),
        tap(() => this.alteracoes$ = this.cadastrosQuery.buscarAlteracoes$(this.cadastroId, [TipoAlteracao.CONTRATO, TipoAlteracao.PROCURACAO])),
        take(1),
      ).subscribe();
  }

  public async buscarDadosContrato(event?: any): Promise<void> {
    if (!event) {
      await this.loadingService.createLoader();
    }

    forkJoin([
      this.cadastroService.buscarContrato(this.cadastroId),
      this.cadastroService.buscarDocumentosDoCadastro(this.cadastroId)
    ])
      .pipe(
        tap(response => {
          const [dadosContrato, documentos] = response;
          this.dadosContrato = dadosContrato;
          this.procuracaoDocumento = documentos.find(documento => documento.type === CadastroDocumentoTipo.PROCURACAO);
          this.contratoDocumento = documentos.find(documento => documento.type === CadastroDocumentoTipo.CONTRATO);
        }),
        catchError(async error => {
          await this.toastService.error('Erro ao buscar dados do cadastro.').toPromise();
          throwError(error);
        }),
        finalize(() => event ? event.target.complete() : this.loadingService.dismiss()),
      )
      .subscribe();
  }
}
