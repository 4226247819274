<section class="c-assinatura-tipo-link">
  <ng-container *ngIf="!cadastro?.contratoLinkFalhaEnvio">
    <img *ngIf="cadastro?.contratoLinkTipoEnvio === contratoLinkTipoEnvio.EMAIL" class="c-assinatura-tipo-link__img"
      src="assets/images/email.svg" alt="Imagem email">
    <img *ngIf="cadastro?.contratoLinkTipoEnvio === contratoLinkTipoEnvio.WHATSAPP" class="c-assinatura-tipo-link__img"
      src="assets/images/email.svg" alt="Imagem email">

    <ion-text class="ion-text-center">
      <h1 class="title">Aguardando assinatura</h1>

      <p class="font-text">
        O link contendo o contrato digital foi enviado {{
        cadastro?.contratoLinkTipoEnvio === contratoLinkTipoEnvio.EMAIL ? 'para o e-mail': 'por whatsapp para o número'
        }} <b>
          {{
          cadastro?.contratoLinkTipoEnvio === contratoLinkTipoEnvio.WHATSAPP ?
          (cadastro?.contratoLinkContatoEnviado | mask: '(00) 00000-0000') :
          cadastro?.contratoLinkContatoEnviado
          }}
        </b>.
      </p>
      <p class="font-text">
        Clique no link recebido e faça a assinatura até {{ cadastro?.contratoDataExpiracao | date: 'dd/MM/yyyy' }}.
      </p>
      <p class="font-text">
        Você poderá solicitar um novo link a partir das {{ contratoLinkDataProximoEnvio | date: 'HH:mm' }}.
        Verifique se {{ cadastro?.contratoLinkTipoEnvio === contratoLinkTipoEnvio.WHATSAPP ?
        'o número do whatsapp' : 'o endereço de email' }} está correto.
      </p>

      <ion-button *ngIf="mostrarLinkReenvio" class="font-text--bold ion-margin-bottom" color="secondary" fill="clear"
        (click)="mostrarModalSelecaoTipoEnvioLink()">
        Reenviar link ou trocar a forma de envio
      </ion-button>
    </ion-text>
  </ng-container>

  <ng-container *ngIf="cadastro?.contratoLinkFalhaEnvio">
    <ion-icon class="c-assinatura-tipo-link__icone-erro c-assinatura-tipo-link__img" name="warning"
      color="danger"></ion-icon>
    <ion-text class="ion-text-center">
      <h1 class="title">Informação de contato inválida</h1>
      <p class="font-text">
        Não foi possível enviar o link contendo o contrato {{
          cadastro?.contratoLinkTipoEnvio === contratoLinkTipoEnvio.EMAIL ? 'para o e-mail': 'por whatsapp para o número'
          }} especificado: <br>
        <b>{{
          cadastro?.contratoLinkTipoEnvio === contratoLinkTipoEnvio.WHATSAPP ?
          (cadastro?.contratoLinkContatoEnviado | mask: '(00) 00000-0000') :
          cadastro?.contratoLinkContatoEnviado
          }}</b>
      </p>
      <p class="font-text ion-margin-top">
        Clique no botão abaixo para corrigir a informação de contato e reenviar link contendo o contrato.
      </p>
    </ion-text>
  </ng-container>


  <div class="c-assinatura-tipo-link__actions">
    <ion-button class="ion-margin-top" mode="md" color="secondary" (click)="irParaAFichaDoCadastro()"
      *ngIf="!cadastro?.contratoLinkFalhaEnvio">
      Retornar para a ficha do cadastro
    </ion-button>

    <ion-button class="ion-margin-top" mode="md" color="secondary" (click)="mostrarModalSelecaoTipoEnvioLink()"
      *ngIf="cadastro?.contratoLinkFalhaEnvio">
      Reenviar contrato
    </ion-button>

    <ion-button class="ion-margin-top" mode="md" fill="outline" color="secondary"
      (click)="irParaSelecionarTipoAssinatura()">
      Alterar método de assinatura
    </ion-button>
  </div>
</section>
