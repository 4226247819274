import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { LoginPageModule } from '@usucampeao/ui-mobile';
import { LoginRoutingModule } from './login-routing.module';
import { LoginComponent } from './login.component';


@NgModule({
  declarations: [
    LoginComponent
  ],
  imports: [
    CommonModule,
    IonicModule,

    // route
    LoginRoutingModule,

    // components
    LoginPageModule,
  ]
})
export class LoginModule { }
