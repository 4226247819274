<ion-content [scrollY]="false">
  <ion-refresher slot="fixed" (ionRefresh)="carregarLocalizacao($event)">
    <ion-refresher-content>
    </ion-refresher-content>
  </ion-refresher>

  <ng-container *ngIf="(lotes$ | async) && mapa$ | async">
    <usucampeao-projetos-mapa [lotes]="lotes$ | async" [mapa]="mapa$ | async"
      [loteSelecionadoId]="loteSelecionadoId$ | async" [alteracoes]="alteracoes$ | async" [desabilitado]="selecionarLoteDesabilitado$ | async"
      (aoSelecionarLote)="alterarLocalizacao($event)"></usucampeao-projetos-mapa>
  </ng-container>
</ion-content>
