<ion-content class="ion-padding">
  <ion-refresher slot="fixed" (ionRefresh)="carregarDadosBasicos($event)">
    <ion-refresher-content>
    </ion-refresher-content>
  </ion-refresher>

  <usucampeao-proprietarios-dados-basicos-v2 [dadosBasicos]="dadosBasicos$ | async"
    [formDesabilitado]="formDesabilitado$ | async"
    (aoSubmeter)="alterarDadosBasicos($event)"></usucampeao-proprietarios-dados-basicos-v2>
</ion-content>
