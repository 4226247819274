import { Component, Input, OnInit } from '@angular/core';
import { PagamentoStatus } from '@usucampeao/interfaces';
import { PAGAMENTOS_STATUS_PAGOS, PagamentoListagemDto } from '@usucampeao/lib-reurb-simplificado';
import { addDays, differenceInCalendarDays, endOfDay, startOfDay } from 'date-fns';

@Component({
  selector: 'usucampeao-pagamento-item',
  templateUrl: './pagamento-item.component.html',
  styleUrls: ['./pagamento-item.component.scss']
})
export class PagamentoItemComponent implements OnInit {
  @Input() pagamento: PagamentoListagemDto;

  public mostrarStatusPagamento = false;
  public pagamentoAtrasado = false;

  ngOnInit(): void {
    this.configurarStatus();
  }

  public get pagamentoStatus(): typeof PagamentoStatus {
    return PagamentoStatus;
  }

  public get mostrarBadgeStatus(): boolean {
    return differenceInCalendarDays(new Date(this.pagamento.dataVencimento), new Date()) <= 7;
  }

  private configurarStatus(): void {
    const dataVencimentoSeteDias = endOfDay(addDays(new Date(), 7));
    const dataVenciemento = new Date(this.pagamento.dataVencimento)
    this.mostrarStatusPagamento = !PAGAMENTOS_STATUS_PAGOS.includes(this.pagamento.status) && dataVenciemento < dataVencimentoSeteDias;
    this.pagamentoAtrasado = this.pagamento.status === PagamentoStatus.NAO_PAGO || dataVenciemento < startOfDay(new Date());
  }

  public get badgeColor(): string {
    switch (this.pagamento.status) {
      case PagamentoStatus.EM_ANALISE:
        return "warning";
      case PagamentoStatus.NAO_PAGO:
        return "danger";
      case PagamentoStatus.PAGO:
        return "success";
      default:
        return 'medium';
    }
  }

}
