import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { PipesModule } from '@usucampeao/utils-frontend';
import { AccordionModule, CadastroStatusModule, EnderecoFormModule, HeaderModule, InputModule, SelectModule } from '../../../../components';
import { ImovelInfosAdicionaisFormV2Module } from '../../../../components/forms/imovel-infos-adicionais-form-v2/imovel-infos-adicionais-form-v2.module';
import { StatusPreenchimentoModule } from '../../../../components/status-preenchimento/status-preenchimento.module';
import { UploadFotoDocumentoV2Module } from '../../../../components/upload-foto/upload-foto-documento-v2/upload-foto-documento-v2.module';
import { ImoveisEditarEnderecoFormComponent } from './imoveis-editar-endereco-form/imoveis-editar-endereco-form.component';
import { ImoveisEditarInfosAdicionaisFormComponent } from './imoveis-editar-infos-adicionais-form/imoveis-editar-infos-adicionais-form.component';
import { ImoveisEditarComponent } from './imoveis-editar.component';

@NgModule({
  declarations: [
    ImoveisEditarComponent,
    ImoveisEditarEnderecoFormComponent,
    ImoveisEditarInfosAdicionaisFormComponent,
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,
    ReactiveFormsModule,
    // components
    AccordionModule,
    CadastroStatusModule,
    EnderecoFormModule,
    HeaderModule,
    ImovelInfosAdicionaisFormV2Module,
    InputModule,
    SelectModule,
    StatusPreenchimentoModule,
    UploadFotoDocumentoV2Module,
    // others
    PipesModule,
  ],
  exports: [
    ImoveisEditarComponent,
  ]
})
export class ImoveisEditarModule { }
