<div class="t-amortizacao">
  <table>
    <tr>
      <th class="t-amortizacao__column">Nº</th>
      <th class="t-amortizacao__column">Data de Vencimento</th>
      <th class="t-amortizacao__column">Valor da Parcela</th>
      <th class="t-amortizacao__column ">Amortização</th>
      <th class="t-amortizacao__column t-amortizacao__column">Juros</th>
      <th class="t-amortizacao__column">Saldo Devedor</th>
    </tr>
    <tbody>
      <tr class="t-amortizacao__gray-row">
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td class="t-amortizacao__column">{{ valorServico - valorDesconto | currency: 'BRL' }}</td>
      </tr>
      <tr [class.t-amortizacao__gray-row]="i % 2 === 1" *ngFor="let pagamento of pagamentos; let i = index">
        <td class="t-amortizacao__column">{{ pagamento.parcela === 0 ? textoPrimeiraParcela : pagamento.parcela }}</td>
        <td class="t-amortizacao__column">{{ pagamento.dataVencimento | date: 'dd/MM/yyyy' }}</td>
        <td class="t-amortizacao__column">{{ pagamento.valorDevido | currency: 'BRL' }}</td>
        <td class="t-amortizacao__column ">{{ pagamento.valorAmortizado | currency: 'BRL' }}</td>
        <td class="t-amortizacao__column t-amortizacao__column">{{ pagamento.juros | currency: 'BRL' }}</td>
        <td class="t-amortizacao__column">{{ pagamento.saldoDevedor | currency: 'BRL' }}</td>
      </tr>
    </tbody>
  </table>
</div>
