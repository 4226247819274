<ion-content class="ion-padding m-selecao-tipo-envio-link" scrollY="none">
  <ion-text class="ion-margin-vertical">
    <h1 class="title ion-no-margin">Atualizar contatos</h1>
  </ion-text>

  <ion-text>
    <p class="font-text font-text--medium">
      Dados de contato
    </p>
  </ion-text>

  <form [formGroup]="form">
    <usucampeao-input label="Whatsapp" type="tel" inputmode="numeric" mask="(00)00000-0000" controlName="whatsapp"></usucampeao-input>
    <usucampeao-input label="Email" type="email" inputmode="email" controlName="email"></usucampeao-input>
  </form>


  <div class="m-editar-infos-contato__actions">
    <ion-button class="ion-margin-end" fill="clear" mode="md" color="secondary" (click)="fecharModal()">Voltar</ion-button>
    <ion-button mode="md" color="secondary" (click)="submitForm()">Atualizar</ion-button>
  </div>
</ion-content>
