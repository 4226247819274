import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CadastrosNovoRoutingModule } from './cadastros-novo-routing.module';

@NgModule({
  imports: [
    CommonModule,
    CadastrosNovoRoutingModule,
  ],
})
export class CadastrosNovoModule { }
