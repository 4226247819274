import { Expose } from 'class-transformer';
import { IsDate, IsEnum, IsNotEmpty, IsOptional, IsString, ValidateIf } from 'class-validator';
import { DocumentFileDto } from '../document-file';
import { RevisaoDadosImovelDto, RevisaoDadosProprietarioDto } from './revisao-detalhes.dto';
import { TipoAlteracao } from './tipo-alteracao.enum';

export const ALTERACOES_PROPRIETARIO = [
  TipoAlteracao.PROPRIETARIO_DADOS_BASICOS,
  TipoAlteracao.PROPRIETARIO_DADOS_CONTATO,
  TipoAlteracao.PROPRIETARIO_DADOS_DOCUMENTOS,
  TipoAlteracao.PROPRIETARIO_DOCUMENTO_ESTADO_CIVIL,
  TipoAlteracao.PROPRIETARIO_DOCUMENTO_PESSOAL
];

export const ALTERACOES_PROPRIETARIO_DOCUMENTO = [
  TipoAlteracao.PROPRIETARIO_DOCUMENTO_ESTADO_CIVIL,
  TipoAlteracao.PROPRIETARIO_DOCUMENTO_PESSOAL
];

export const ALTERACOES_IMOVEL = [
  TipoAlteracao.IMOVEL_DADOS_ADICIONAIS,
  TipoAlteracao.IMOVEL_DADOS_ENDERECO,
  TipoAlteracao.IMOVEL_DADOS_LOCALIZACAO,
  TipoAlteracao.IMOVEL_DOCUMENTO_AQUISICAO,
  TipoAlteracao.IMOVEL_DOCUMENTO_POSSE,
  TipoAlteracao.IMOVEL_FOTO_FACHADA,
];

export const ALTERACOES_IMOVEL_DOCUMENTO = [
  TipoAlteracao.IMOVEL_DOCUMENTO_AQUISICAO,
  TipoAlteracao.IMOVEL_DOCUMENTO_POSSE,
];

const ALTERACOES_DOCUMENTOS = [
  TipoAlteracao.IMOVEL_DOCUMENTO_AQUISICAO,
  TipoAlteracao.IMOVEL_DOCUMENTO_POSSE,
  TipoAlteracao.PROPRIETARIO_DOCUMENTO_PESSOAL,
  TipoAlteracao.PROPRIETARIO_DOCUMENTO_ESTADO_CIVIL,
];


export class AlteracaoDto {
  @Expose()
  @IsString()
  @IsOptional()
  descricao?: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  @ValidateIf((value: AlteracaoDto) => ALTERACOES_PROPRIETARIO.includes(value.tipo))
  proprietarioId?: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  @ValidateIf((value: AlteracaoDto) => ALTERACOES_PROPRIETARIO.includes(value.tipo))
  proprietarioCadastroId?: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  @ValidateIf((value: AlteracaoDto) => ALTERACOES_DOCUMENTOS.includes(value.tipo))
  documentoId?: string;

  @Expose()
  @IsEnum(TipoAlteracao)
  @IsNotEmpty()
  tipo: TipoAlteracao;

  @Expose()
  @IsOptional()
  payload?: Partial<RevisaoDadosProprietarioDto> | Partial<RevisaoDadosImovelDto> | AlteracaoDocumentoDto;

  @Expose()
  @IsDate()
  @IsOptional()
  dataAtualizacao?: Date;

  constructor(props?: Partial<AlteracaoDto>) {
    if (props) {
      Object.keys(props).forEach(key => this[key] = props[key]);
    }
  }
}

export class AlteracaoDocumentoDto extends DocumentFileDto {
  @Expose()
  arquivos: File[];
}
