import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { PipesModule } from '@usucampeao/utils-frontend';
import { AccordionModule, CadastroStatusModule, DatepickerModule, HeaderModule, InputModule, SelectModule } from '../../../../components';
import { StatusPreenchimentoModule } from '../../../../components/status-preenchimento/status-preenchimento.module';
import { UploadFotoDocumentoV2Module } from '../../../../components/upload-foto/upload-foto-documento-v2/upload-foto-documento-v2.module';
import { ProprietariosDadosBasicosFormComponent } from './proprietarios-dados-basicos-form/proprietarios-dados-basicos-form.component';
import { ProprietariosDadosContatoFormComponent } from './proprietarios-dados-contato-form/proprietarios-dados-contato-form.component';
import { ProprietariosDadosDocumentoFormComponent } from './proprietarios-dados-documento-form/proprietarios-dados-documento-form.component';
import { ProprietariosEditarComponent } from './proprietarios-editar.component';

@NgModule({
  declarations: [
    ProprietariosEditarComponent,
    ProprietariosDadosBasicosFormComponent,
    ProprietariosDadosDocumentoFormComponent,
    ProprietariosDadosContatoFormComponent,
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,
    ReactiveFormsModule,

    // components
    AccordionModule,
    CadastroStatusModule,
    DatepickerModule,
    HeaderModule,
    InputModule,
    SelectModule,
    StatusPreenchimentoModule,
    UploadFotoDocumentoV2Module,

    // others
    PipesModule,
  ],
  exports: [
    ProprietariosEditarComponent
  ]
})
export class ProprietariosEditarModule { }
