import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { PipesModule } from '@usucampeao/utils-frontend';
import { DocumentosListaModule, MenuItemModule } from '../../../../components';
import { ProprietariosDocumentosComponent } from './proprietarios-documentos.component';


@NgModule({
  declarations: [
    ProprietariosDocumentosComponent
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,

    // components
    DocumentosListaModule,
    MenuItemModule,

    // others
    PipesModule,
  ],
  exports: [
    ProprietariosDocumentosComponent
  ]
})
export class ProprietariosDocumentosModule { }
