import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContratoSelecionarFormaPagamentoPage } from './contrato-selecionar-forma-pagamento.page';

const routes: Routes = [{ path: '', component: ContratoSelecionarFormaPagamentoPage }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ContratoSelecionarFormaPagamentoRoutingModule { }
