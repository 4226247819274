import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Pagination } from '@usucampeao/interfaces';
import { LoteListarMapaDto, MapaDto, ProjetoDetalhesComerciaisDto, ProjetoDetalhesDto, ProjetoSimplificadoDto, ProjetosListarComDistancia, ProjetoVendaStatus, TabelaPrecoDetalhesDto, TabelaPrecoValoresDto } from '@usucampeao/lib-reurb-simplificado';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ProjetoStore } from './projeto.store';

@Injectable({ providedIn: 'root' })
export class ProjetoService {
  private readonly projetoUrl = '/projetos';
  private readonly projetoUrlV2 = '/v2/projetos';

  constructor(
    private http: HttpClient,
    private projetoStore: ProjetoStore,
  ) {
  }

  public carregarProjetosPorStatus(status: ProjetoVendaStatus): Observable<ProjetoSimplificadoDto[]> {
    return this.http.get<ProjetoSimplificadoDto[]>(`${this.projetoUrl}/status/cliente`, { params: { status } })
      .pipe(
        tap(projetos => this.projetoStore.set(projetos)),
      );
  }

  /**
   * Lista os projetos próximos a uma determinada localização.
   *
   * @param latitude Latitude da localização
   * @param longitude Longitude da localização
   * @returns Uma lista de projetos próximos, ordenados pela distância mais próxima.
   */
  public listarProjetosProximos(latitude: number, longitude: number): Observable<ProjetosListarComDistancia[]> {
    return this.http.post<ProjetosListarComDistancia[]>(
      `${this.projetoUrlV2}/listar-proximos`, { latitude, longitude })
      .pipe(
        tap(projetos => this.projetoStore.upsertMany(projetos)),
      );
  }

  /**
   * Lista todos os projetos disponíveis para venda.
   * Se a latitude e longitude forem informadas, retornará os projetos ordenados pela distância mais próxima.
   *
   * @param params Parametros de busca e paginação
   * @param params.latitude Latitude do ponto de referência
   * @param params.longitude Longitude do ponto de referência
   * @param params.pagina Número da página a ser listada
   * @param params.tamanho_pagina Quantidade de itens por página
   * @returns Um objeto Pagination com os projetos encontrados
   */
  public listarTodosOsProjetosDisponiveis(
    params: { latitude?: number, longitude?: number, pagina: number, tamanho_pagina: number }
  ): Observable<Pagination<ProjetosListarComDistancia>> {
    return this.http.get<Pagination<ProjetosListarComDistancia>>(
      `${this.projetoUrlV2}`,
      {
        params: {
          pagina: params.pagina,
          tamanho_pagina: params.tamanho_pagina,
          ...params.latitude && { latitude: params.latitude },
          ...params.longitude && { longitude: params.longitude },
        }
      }
    )
      .pipe(
        tap(projetos => this.projetoStore.upsertMany(projetos.items)),
      );
  }

  public buscarDetalheProjeto(id: string): Observable<ProjetoDetalhesDto> {
    return this.http.get<ProjetoDetalhesDto>(`/projetos/${id}/detalhes`)
      .pipe(
        tap(projeto => this.projetoStore.upsert(id, projeto)),
      );
  }

  public buscarDadosSimplificados(id: string): Observable<ProjetoSimplificadoDto> {
    return this.http.get<ProjetoSimplificadoDto>(`/projetos/${id}`)
      .pipe(
        tap(projeto => this.projetoStore.upsert(id, projeto)),
      );
  }

  public buscarDetalheComercialProjeto(id: string): Observable<ProjetoDetalhesComerciaisDto> {
    return this.http.get<ProjetoDetalhesComerciaisDto>(`/projetos/${id}/detalhes-comerciais`)
      .pipe(
        tap(dadosComerciais => this.projetoStore.update(id, { ...dadosComerciais }))
      );
  }

  public buscarTabelaPreco(id: string): Observable<TabelaPrecoDetalhesDto[]> {
    return this.http.get<TabelaPrecoDetalhesDto[]>(`/projetos/${id}/tabela-preco`)
      .pipe(
        tap(tabelaPreco => this.projetoStore.update(id, { tabelaPreco }))
      );
  }

  public buscarValoresTabelaPrecoProjeto(id: string): Observable<TabelaPrecoValoresDto[]> {
    return this.http.get<TabelaPrecoValoresDto[]>(`/projetos/${id}/tabela-preco/valores`)
      .pipe(
        tap(tabelaPreco => this.projetoStore.update(id, { tabelaPreco }))
      );
  }

  public buscarLotes(id: string): Observable<LoteListarMapaDto[]> {
    return this.http.get<LoteListarMapaDto[]>(`/projetos/${id}/lotes`)
      .pipe(
        tap(lotes => this.projetoStore.add({ id, lotes })),
        tap(lotes => this.projetoStore.update(id, { lotes }))
      );
  }

  public buscarMapa(id: string): Observable<MapaDto> {
    return this.http.get<MapaDto>(`/projetos/${id}/mapa`)
      .pipe(
        tap(mapa => this.projetoStore.add({ id, mapa })),
        tap(mapa => this.projetoStore.update(id, { mapa }))
      );
  }

}
