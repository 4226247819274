import { Component, Input } from '@angular/core';
import { TipoNotificacao } from '@usucampeao/lib-reurb-simplificado';

@Component({
  selector: 'usucampeao-notificacao-icone',
  templateUrl: 'notificacao-icone.component.html',
  styleUrls: ['notificacao-icone.component.scss'],
})
export class NotificacaoIconeComponent {
  @Input() set tipoNotificacao(tipoNotificacao: TipoNotificacao) {
    this._tipoNotificacao = tipoNotificacao;
    this.configurarIcone();
    this.configurarIconeCor();
  }

  private _tipoNotificacao: TipoNotificacao;

  public iconeNome: string;
  public iconeCor: string;

  private configurarIcone(): void {
    switch (this._tipoNotificacao) {
      case TipoNotificacao.CADASTRO_PRONTO_PARA_FECHAR_CONTRATO:
        this.iconeNome = 'send-sharp';
        break;

      case TipoNotificacao.BOLETO_VENCE_AMANHA:
      case TipoNotificacao.BOLETO_VENCE_AMANHA_DEPRECATED:
      case TipoNotificacao.BOLETO_VENCE_PROXIMO:
      case TipoNotificacao.BOLETO_VENCE_PROXIMO_DEPRECATED:
        this.iconeNome = 'barcode'
        break;

      case TipoNotificacao.CADASTRO_CONTRATO_EXPIRADO:
      case TipoNotificacao.CADASTRO_ALTERACOES_NECESSARIAS:
        this.iconeNome = 'warning-sharp';
        break;

      case TipoNotificacao.CADASTRO_VALIDADO:
        this.iconeNome = 'checkmark-done-circle';
        break;

      case TipoNotificacao.PROJETO_APROVADO_PELA_PREFEITURA:
        this.iconeNome = 'newspaper';
        break;

      case TipoNotificacao.PROJETO_REGISTRADO_EM_CARTORIO:
      case TipoNotificacao.PROJETO_ATENDENDO_DEVOLUTIVAS_DO_CARTORIO:
        this.iconeNome = 'folder';
        break;

      case TipoNotificacao.PROJETO_TITULO_EMITIDO:
        this.iconeNome = 'calendar';
        break;

      default:
        this.iconeNome = 'information-circle';
        break;
    }
  }

  private configurarIconeCor(): void {
    switch (this._tipoNotificacao) {
      case TipoNotificacao.CADASTRO_PRONTO_PARA_FECHAR_CONTRATO:
      case TipoNotificacao.CADASTRO_CONTRATO_FECHADO:
        this.iconeCor = 'warning';
        break;

      case TipoNotificacao.CADASTRO_ALTERACOES_NECESSARIAS:
      case TipoNotificacao.CADASTRO_CONTRATO_EXPIRADO:
        this.iconeCor = 'danger';
        break;

      case TipoNotificacao.CADASTRO_VALIDADO:
      case TipoNotificacao.PROJETO_TITULO_EMITIDO:
        this.iconeCor = 'success';
        break;

      case TipoNotificacao.BOLETO_VENCE_AMANHA:
      case TipoNotificacao.BOLETO_VENCE_AMANHA_DEPRECATED:
        this.iconeCor = 'warning';
        break;

      default:
        this.iconeCor = 'medium';
        break;
    }
  }
}
