<div class="input">
  <ion-item mode="md" fill="solid">
    <ion-label mode="md" position="floating" [attr.for]="key">
        <ion-text class="input-text">
            {{ label }}
        </ion-text>
    </ion-label>
   <ion-select mode="md" cancelText="Cancelar" okText="Selecionar" [formControlName]="controlName" [attr.disabled]="disabled">
    <ng-content></ng-content>
   </ion-select>
  </ion-item>

  <div class="input__error">
    <ng-content select="[usu-error]"></ng-content>
  </div>
</div>
