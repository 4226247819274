import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OwnerDocument, PropertyAcquisitionDocument, PropertyOwnershipDocument } from '@usucampeao/lib-reurb-simplificado';
import { NavigationService } from '../../services';

@Component({
  selector: 'usucampeao-documentos-selecionar-tipo',
  templateUrl: './documentos-selecionar-tipo.component.html',
  styleUrls: ['./documentos-selecionar-tipo.component.scss']
})
export class DocumentosSelecionarTipoComponent {
  @Input() documentosAquisicao: PropertyAcquisitionDocument[];
  @Input() documentosPosse: PropertyOwnershipDocument[];
  @Input() documentosIdentidade: OwnerDocument[];
  @Input() documentosEstadoCivil: OwnerDocument[];

  constructor(
    private navigation: NavigationService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  public get possuiDocumentoAquisicao(): boolean {
    return this.documentosAquisicao?.length > 0;
  }

  public get possuiDocumentoPosse(): boolean {
    return this.documentosPosse?.length > 0;
  }

  public get possuiDocumentoIdentidade(): boolean {
    return this.documentosIdentidade?.length > 0;
  }

  public get possuiDocumentoEstadoCivil(): boolean {
    return this.documentosEstadoCivil?.length > 0;
  }

  public iaParaUploadDocumento(tipo: string): void {
    this.router.navigate(['../upload'], {
      relativeTo: this.route,
      queryParams: { tipo },
      replaceUrl: true
    });
  }

  public cancelar(): void {
    this.navigation.voltar();
  }
}
