import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NotificacaoDto, RegistrationSimplifiedDto, StatusNotificacao } from '@usucampeao/lib-reurb-simplificado';
import { BaseComponent, LoadingService, ToastService } from '@usucampeao/ui-mobile';
import { Observable, of } from 'rxjs';
import { catchError, finalize, first, switchMap, takeUntil, tap } from 'rxjs/operators';
import { CadastroService } from '../../../cadastros/state/cadastros.service';
import { NotificationsQuery } from '../../state/notifications.query';
import { NotificationsService } from '../../state/notifications.service';

@Component({
  templateUrl: 'notificacoes-detalhes.page.html',
  styleUrls: ['notificacoes-detalhes.page.scss'],
})
export class NotificacoesDetalhesPage extends BaseComponent implements OnInit {
  public notificacao$: Observable<NotificacaoDto>;
  public cadastro: RegistrationSimplifiedDto | null;

  constructor(
    private readonly cadastroService: CadastroService,
    private readonly loadingService: LoadingService,
    private readonly notificacaoService: NotificationsService,
    private readonly notificationsQuery: NotificationsQuery,
    private readonly route: ActivatedRoute,
    private readonly toastService: ToastService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.route.params
      .pipe(
        first(),
        tap(params => this.carregarNotificacao(params.notificacaoId)),
        tap(params => this.notificacao$ = this.notificationsQuery.selectEntity(params.notificacaoId)),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe();
  }

  private carregarNotificacao(notificacaoId: string): void {
    this.loadingService.createLoader();
    this.notificacaoService.buscarNotificacao(notificacaoId)
      .pipe(
        switchMap(notificacao => {
          if (notificacao.status !== StatusNotificacao.LIDA) {
            return this.notificacaoService.lerNotificacao(notificacaoId)
          }

          return of(notificacao);
        }),
        switchMap(notificacao => {
          if (notificacao.cadastroId) {
            return this.cadastroService.buscarCadastro(notificacao.cadastroId)
          }

          return of(null);
        }),
        tap(cadastro => this.cadastro = cadastro),
        catchError(error => {
          this.toastService.error('Erro ao buscar detalhes da notificação. Tente novamente mais tarde.').toPromise();
          throw error;
        }),
        finalize(() => this.loadingService.dismiss()),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe();
  }
}
