<div class="pagamentos-resumo ion-padding">
  <div class="pagamentos-resumo__flex">
    <ion-icon class="pagamentos-resumo__icon" name="cash-sharp" color="medium"></ion-icon>

    <div class="pagamentos-resumo__content">
      <div class="pagamentos-resumo__flex pagamentos-resumo__valores">
        <ion-text>
          <p class="font-text ion-no-margin">{{ valorContrato | currency: 'BRL' }}</p>
        </ion-text>
        <ion-chip>
          <ion-label>{{ statusPagamentos }}</ion-label>
        </ion-chip>
      </div>

      <ion-text>
        <p class="description-text">
          Entrada de {{ valorEntrada | currency: 'BRL' }} + <br>
          {{ quantidadeParcelas }} parcelas de {{ valorParcela | currency: 'BRL' }}
        </p>
      </ion-text>
    </div>
  </div>

  <ion-button mode="md" (click)="navegarParaPagamentos()">
    <ion-icon slot="start" name="barcode"></ion-icon>
    Visualizar boletos
  </ion-button>
</div>
