import { ProjetoUsuarioCategoria } from './projeto-usuario';

export const PROJETO_USUARIO_LIDERANCA = [
  ProjetoUsuarioCategoria.COORDENADOR,
  ProjetoUsuarioCategoria.SUPERVISOR,
  ProjetoUsuarioCategoria.COORDENADOR_CALL_CENTER,
  ProjetoUsuarioCategoria.SUPERVISOR_CALL_CENTER,
];

export type ProjetoUsuarioLideranca = ProjetoUsuarioCategoria.COORDENADOR |
  ProjetoUsuarioCategoria.SUPERVISOR |
  ProjetoUsuarioCategoria.COORDENADOR_CALL_CENTER |
  ProjetoUsuarioCategoria.SUPERVISOR_CALL_CENTER;

export class UsuarioIdCategoriaDto {
  usuario_id: string;
  categoria: ProjetoUsuarioLideranca;
}
