import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { MaskDirectiveModule } from '../../../directives/mask.directive.module';
import { InputComponent } from './input.component';

@NgModule({
  declarations: [
    InputComponent
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,

    // directives
    MaskDirectiveModule,

    // external libs
    CurrencyMaskModule
  ],
  exports: [
    InputComponent
  ]
})
export class InputModule { }
