import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ImoveisEditarPage } from './imoveis-editar.page';

const routes: Routes = [{ path: '', component: ImoveisEditarPage }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ImoveisEditarRoutingModule { }
