import { Pipe, PipeTransform } from '@angular/core';
import { numeroExtenso, NumeroExtensoTipo } from '@usucampeao/utils';

@Pipe({
  name: 'numeroExtenso'
})
export class NumeroExtensoPipe implements PipeTransform {

  transform(value: number | string, tipo?: NumeroExtensoTipo): string {
    return numeroExtenso(value, tipo);
  }

}
