import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { FooterModule, HeaderModule } from '@usucampeao/ui-mobile';
import { HelpRoutingModule } from './help-routing.module';
import { HelpPage } from './help.page';

@NgModule({
  declarations: [
    HelpPage
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,

    // route
    HelpRoutingModule,

    // components
    HeaderModule,
    FooterModule,
  ]
})
export class HelpPageModule { }
