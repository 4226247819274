import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { CadastroStatusModule, HeaderModule, MenuItemModule, ModalContratoProcuracaoModule } from '@usucampeao/ui-mobile';
import { PipesModule } from '@usucampeao/utils-frontend';

import { OutrosDocumentosComponent } from './outros-documentos.component';


@NgModule({
  declarations: [
    OutrosDocumentosComponent
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,

    // components
    CadastroStatusModule,
    HeaderModule,
    MenuItemModule,
    ModalContratoProcuracaoModule,

    // others
    PipesModule,
  ],
  exports: [
    OutrosDocumentosComponent,
  ]
})
export class OutrosDocumentosModule { }
