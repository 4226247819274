import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { Router } from '@angular/router';
import Bugsnag from '@bugsnag/js';
import { ToastController } from '@ionic/angular';
import { DispositivoDto } from '@usucampeao/lib-reurb-simplificado';
import { ToastService } from '@usucampeao/ui-mobile';
import { Observable, of, Subscription } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import * as packageJson from '../../../../../../package.json';
import { environment } from '../../../environments/environment';
import { AuthStore } from '../../pages/auth/state/auth.store';
import { NotificationsService } from '../../pages/notifications/state/notifications.service';

const LOCAL_STORAGE_DISPOSITIVO_TOKEN = 'dispositivo_token';
const LOCAL_STORAGE_VERSAO_APP = 'versao_app';
@Injectable({ providedIn: 'root' })
export class PushNotificationService {
  private pushNotificationInicializado = false;
  private assinaturaOunvinteNotificacao: Subscription;

  constructor(
    private readonly angularFireMessaging: AngularFireMessaging,
    private readonly authStore: AuthStore,
    private readonly http: HttpClient,
    private readonly notificacaoService: NotificationsService,
    private readonly router: Router,
    private readonly toastController: ToastController,
    private readonly toastService: ToastService,
  ) {
    this.angularFireMessaging.usePublicVapidKey(environment.publicVapidKey);
  }

  /**
   * Inicializa o serviço de notificações.
   * Isso inclui a busca do token do dispositivo e a inicialização do ouvinte de notificações.
   * Se o serviço já estiver inicializado, somente busca o token do dispositivo.
   * O token do dispositivo sempre é buscado, mesmo que o serviço já esteja inicializado, porque caso o memso já tenha sido salvo,
   * ele não será salvo novamente.
   */
  public inicializar(): void {
    this.buscarTokenDispositivo();

    if (this.pushNotificationInicializado) {
      return;
    }
    this.pushNotificationInicializado = true;
    this.inicializarOuvinteNotificacoes();
  }

  /**
   * Inicializa o ouvinte de notificações.
   *
   * Este método é responsável por configurar o ouvinte de mensagens de notificação.
   * Quando uma nova mensagem de notificação é recebida, o método verifica se a mensagem
   * possui um corpo de notificação e redireciona o usuário para a URL especificada na mensagem.
   * Em seguida, o método realiza uma busca por notificações não lidas.
   *
   * @returns Nenhum valor de retorno.
   */
  private inicializarOuvinteNotificacoes(): void {
    this.assinaturaOunvinteNotificacao = this.angularFireMessaging.messages
      .pipe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        tap((message: any) => {
          const { usuarioId } = message?.data || {};
          const usuarioLogado = this.authStore.getValue()?.id;
          if (!usuarioId || usuarioId === usuarioLogado) {
            this.mostrarNotificacao(message);
          } //TODO: else: remover dispositivo do usuário, pois ele não está mais associado ao usuário que precisa ser notificado
        }),
        switchMap(() => this.notificacaoService.buscarNotificacaoNaoLidas()),
      )
      .subscribe();
  }

  /**
   * Exibe uma notificação para o usuário.
   *
   * @param message - Mensagem de notificação
   */
  private mostrarNotificacao(message: any) {
    const notificacaoId = message?.data?.notificacaoId;
    const titulo = message?.data?.titulo || message?.notification?.title;
    const url = notificacaoId ? `notificacoes/${notificacaoId}` : 'home';
    navigator?.serviceWorker?.ready?.then(registration => {
      const { descricao } = message?.data || {};
      const notificacaoOptions = {
        body: descricao,
        data: {
          url: `https://${self.location.hostname}/notificacoes/${notificacaoId}`,
        },
      };
      registration.showNotification(titulo, notificacaoOptions);
    });

    if (titulo) {
      this.mostrarNotificacaoToast(titulo, url);
    }
  }

  /**
   * Exibe uma notificação do tipo toast.
   *
   * @param titulo - Título da notificação
   * @param url - URL para redirecionamento
   */
  private async mostrarNotificacaoToast(titulo: string, url: string): Promise<void> {
    const toast = await this.toastController.create({
      message: titulo,
      position: 'top',
      duration: 3000,
      buttons: [
        {
          text: 'Abrir',
          handler: () => {
            this.router.navigateByUrl(url);
          }
        }
      ]
    });
    await toast.present();
  }

  /**
   * Busca o token do dispositivo.
   */
  private buscarTokenDispositivo(): void {
    this.angularFireMessaging.requestToken
      .pipe(
        tap(token => this.salvarTokenDispositivo(token)),
        catchError(error => {
          Bugsnag.notify(error, event => {
            event.addMetadata('error tracing', {
              message: 'Erro ao buscar token do dispositivo',
            });
            event.addMetadata('error response', error.response);
            event.addMetadata('error payload', error.payload);
          });
          return of(null);
        })
      )
      .subscribe();
  }

  /**
   * Salva o token do dispositivo no localStorage e no backend.
   * Caso o token já esteja salvo, não faz nada.
   *
   * @param token - Token do dispositivo
   * @returns
   */
  private salvarTokenDispositivo(token: string): void {
    const versaoApp = localStorage.getItem(LOCAL_STORAGE_VERSAO_APP);
    if (versaoApp === packageJson.version) {
      return;
    }

    this.adicionarDispositivoAoUsuario(token);
  }

  /**
   * Adiciona um dispositivo ao usuário.
   *
   * @param token - Token do dispositivo
   */
  private adicionarDispositivoAoUsuario(token: string): void {
    if (!token) {
      return;
    }
    this.http.post<DispositivoDto>('/users/me/dispositivos', { token })
      .pipe(
        tap(() => localStorage.setItem(LOCAL_STORAGE_DISPOSITIVO_TOKEN, token)),
        tap(() => localStorage.setItem(LOCAL_STORAGE_VERSAO_APP, packageJson.version)),
        catchError(error => {
          this.toastService.error('Não foi possível adicionar o dispositivo para receber notificações.');
          Bugsnag.notify(error, event => {
            event.addMetadata('error tracing', {
              message: 'Erro ao adicionar dispositivo ao usuário',
            });
            event.addMetadata('error response', error.response);
            event.addMetadata('error payload', error.payload);
          });

          return of(null);
        })
      )
      .subscribe();
  }

  /**
   * Remove o dispositivo do usuário.
   */
  public removerDispositivo(): Observable<void> {
    const token = localStorage.getItem(LOCAL_STORAGE_DISPOSITIVO_TOKEN);
    localStorage.removeItem(LOCAL_STORAGE_DISPOSITIVO_TOKEN);
    localStorage.removeItem(LOCAL_STORAGE_VERSAO_APP);
    this.assinaturaOunvinteNotificacao.unsubscribe();
    this.pushNotificationInicializado = false;
    if (!token) {
      return of(null);
    }

    return this.http.delete<void>(`/users/me/dispositivos/${token}`)
      .pipe(
        catchError(error => {
          Bugsnag.notify(error, (event) => {
            event.addMetadata('error response', error.response);
            event.addMetadata('error payload', error.payload);
          });
          return of(null);
        })
      );
  }
}
