<form [formGroup]="form">
  <usucampeao-input controlName="zipCode" label="CEP*" type="tel" mask="00000-000" [readonly]="readonly">
    <span usu-error>{{ gerarMensagemErro('zipCode')}}</span>
  </usucampeao-input>

  <usucampeao-input controlName="street" label="Rua*" [readonly]="readonly">
    <span usu-error>{{ gerarMensagemErro('street')}}</span>
  </usucampeao-input>

  <usucampeao-input controlName="number" label="Número*" type="tel" mask="0000000000" [readonly]="readonly">
    <span usu-error>{{ gerarMensagemErro('number')}}</span>
  </usucampeao-input>

  <usucampeao-input controlName="complement" label="Complemento" [readonly]="readonly">
    <span usu-error>{{ gerarMensagemErro('complement')}}</span>
  </usucampeao-input>

  <usucampeao-input controlName="neighborhood" label="Bairro*" [readonly]="readonly">
    <span usu-error>{{ gerarMensagemErro('neighborhood')}}</span>
  </usucampeao-input>

  <usucampeao-input controlName="city" label="Cidade*" [readonly]="readonly">
    <span usu-error>{{ gerarMensagemErro('city')}}</span>
  </usucampeao-input>

  <usucampeao-select label="Estado*" controlName="state" [disabled]="readonly">
    <ion-select-option *ngFor="let estado of estados" [value]="estado">{{ estado }}</ion-select-option>

    <span usu-error>{{ gerarMensagemErro('state')}}</span>
  </usucampeao-select>
</form>
