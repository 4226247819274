import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { LazyLoadImageHooks, PipesModule } from '@usucampeao/utils-frontend';
import { LAZYLOAD_IMAGE_HOOKS, LazyLoadImageModule } from 'ng-lazyload-image';
import { UploadFotoDocumentoComponent } from './upload-foto-documento.component';

@NgModule({
  declarations: [UploadFotoDocumentoComponent],
  imports: [
    // root
    CommonModule,
    IonicModule,

    // external libraries
    LazyLoadImageModule,

    // others
    PipesModule,
  ],
  exports: [UploadFotoDocumentoComponent],
  providers: [
    {
      provide: LAZYLOAD_IMAGE_HOOKS,
      useClass: LazyLoadImageHooks
    }
  ],
})
export class UploadFotoDocumentoModule { }
