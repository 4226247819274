import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { FooterModule, HeaderModule, MenuItemModule, UsuarioAvatarModule } from '../../../../components';
import { ProprietarioFichaComponent } from './proprietario-ficha/proprietario-ficha.component';
import { ProprietariosFichaV2Component } from './proprietarios-ficha-v2.component';

@NgModule({
  declarations: [
    ProprietariosFichaV2Component,
    ProprietarioFichaComponent
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,

    // components
    FooterModule,
    HeaderModule,
    MenuItemModule,
    UsuarioAvatarModule,
  ],
  exports: [
    ProprietariosFichaV2Component
  ]
})
export class ProprietariosFichaV2Module { }
