<ion-content class="ion-padding">
  <div class="m-contrato-procuracao__header">
    <a (click)="dismiss()">Fechar</a>
  </div>


  <lib-print-contract #printContract [tipo]="tipo" [cadastro]="dadosContrato" [proprietario]="proprietario"
    [conjuge]="conjuge" [imovel]="imovel"></lib-print-contract>

</ion-content>
