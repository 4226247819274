import { Type } from 'class-transformer';
import { IsOptional, IsString, ValidateNested } from 'class-validator';
import { TopicMensagemDto } from './topic-mensagem.dto';

export class TopicDto<T = any> {
  @Type(() => TopicMensagemDto)
  @ValidateNested()
  @IsOptional()
  message: TopicMensagemDto<T>;

  @IsString()
  @IsOptional()
  subscription: string;

  /**
   * JSON convertido em base64
   * O GPC PubSub envia os dados em base64
   */
  @IsString()
  @IsOptional()
  data: string;
}
