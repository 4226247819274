import { Endereco } from '@usucampeao/interfaces';
import { Expose, plainToInstance } from 'class-transformer';
import { GatewayPagamento } from '../pagamento';
import { ProjetoEndereco } from './projeto-endereco.model';
import { ProjetoModalidadeType } from './projeto-modalidade.enum';
import { ProjetoStatus } from './projeto-status.enum';
import { ProjetoTipo } from './projeto-tipo.enum';
import { ProjetoVendaStatus } from './projeto-venda-status.enum';
import { ProjetoDto } from './projeto.dto';

export class ProjetoSimplificadoDto {
  @Expose()
  id: string;

  @Expose()
  nome: string;

  @Expose()
  descricao?: string;

  @Expose()
  modalidade?: ProjetoModalidadeType;

  @Expose()
  averbacao?: boolean;

  @Expose()
  localizacao: Endereco;

  @Expose()
  tipo: ProjetoTipo;

  @Expose()
  vendaStatus: ProjetoVendaStatus;

  @Expose()
  status: ProjetoStatus;

  @Expose()
  semPagamento: boolean;

  @Expose()
  omieProjetoId: number;

  @Expose()
  gatewayPagamento: GatewayPagamento;

  @Expose()
  publicadoDiarioOficialData?: Date;

  @Expose()
  cartorioRegistroData?: Date;

  @Expose()
  tituloEntregaData?: Date;

  @Expose()
  tituloEntregaLocalizacao?: ProjetoEndereco;

  @Expose()
  reurbGratuito: boolean;

  /**
    * Cria uma nova instância de ProjetoSimplificadoDto a partir de dtos
    * @param props DTO de ProjetoSimplificadoDto
    * @returns nova instância de ProjetoSimplificadoDto
    */
  static from(props?: Partial<ProjetoDto>): ProjetoSimplificadoDto {
    return plainToInstance(ProjetoSimplificadoDto, props, { exposeUnsetFields: false, excludeExtraneousValues: true });
  }
}
