import { Component } from '@angular/core';
import { NavigationService } from '@usucampeao/ui-mobile';

@Component({
  selector: 'usucampeao-help',
  templateUrl: 'help.page.html',
  styleUrls: ['help.page.scss'],
})
export class HelpPage {

  constructor(
    private navigationService: NavigationService,
  ) { }

  public navigateBack(): void {
    this.navigationService.voltar();
  }
}
