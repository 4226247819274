import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { CadastrosQuery } from '../../cadastros/state/cadastros.query';
import { CadastroService } from '../../cadastros/state/cadastros.service';

@Injectable({
  providedIn: 'root'
})
export class ContratoFechadoGuard implements CanActivate {

  constructor(
    private readonly cadastroQuery: CadastrosQuery,
    private readonly cadastroService: CadastroService,
    private readonly router: Router,
  ) { }

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const { cadastroId } = route.params;
    if (!cadastroId) {
      return true;
    }

    const cadastro = await this.cadastroQuery.selectEntity(cadastroId)
      .pipe(
        take(1),
        switchMap(cadastro => {
          if (cadastro) {
            return of(cadastro);
          }

          return this.cadastroService.buscarCadastro(cadastroId);
        })
      ).toPromise();

    if (cadastro?.contratoDataAssinatura || cadastro?.contratoTipoAssinatura) {
      this.router.navigate(['cadastros', cadastroId]);
      return false;
    }

    return true;
  }

}
