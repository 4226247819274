import { AddressDto, DocumentFileDto, FileDto, PropertyFillInStatusDto, PropertyOccupancy, PropertySituation, PropertyUsage } from '@usucampeao/lib-reurb-simplificado';
import { Expose, plainToInstance } from 'class-transformer';

export interface ImovelStateDto {
  id: string;
  address?: AddressDto;
  usage?: PropertyUsage;
  occupation?: PropertyOccupancy;
  blockId?: number;
  quadra?: string;
  lotId?: number;
  lote?: string;
  situation?: PropertySituation;
  loteArea?: number;
  possuiOutroImovel?: boolean;
  fillInStatus?: PropertyFillInStatusDto;
  files?: FileDto[];
  documentos?: DocumentFileDto[];
}

export class ImovelFichaDto {
  @Expose()
  id: string;

  @Expose()
  projetoNome: string;

  @Expose()
  address: AddressDto;

  @Expose()
  quadra: string;

  @Expose()
  lote: string;

  @Expose()
  fillInStatus: PropertyFillInStatusDto;

  @Expose()
  files: FileDto[];

  static from(props: Partial<ImovelStateDto>): ImovelFichaDto {
    return plainToInstance(ImovelFichaDto, props, { exposeUnsetFields: false, excludeExtraneousValues: true });
  }
}


export class ImovelLocalizacaoDto {
  @Expose()
  lotId: number;

  @Expose()
  lote: string;

  @Expose()
  blockId: number;

  @Expose()
  quadra: string;

  static from(props: Partial<ImovelStateDto>): ImovelLocalizacaoDto {
    return plainToInstance(ImovelLocalizacaoDto, props, { exposeUnsetFields: false, excludeExtraneousValues: true });
  }
}
