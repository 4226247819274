import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ViewWillEnter } from '@ionic/angular';
import { AlteracaoDto, DocumentFileDto, GetUpdateOwnerContactDto, GetUpdateOwnerDocumentsDto, MaritalStatus, ProprietarioDadosPessoaisDto, TIPOS_ALTERACAO_PROPRIETARIO } from '@usucampeao/lib-reurb-simplificado';
import { BaseComponent, LoadingService, ProprietarioStateDto, ToastService } from '@usucampeao/ui-mobile';
import { iif, Observable, of } from 'rxjs';
import { catchError, finalize, switchMap, takeUntil, tap } from 'rxjs/operators';
import { CadastrosQuery } from '../../state/cadastros.query';
import { CadastroService } from '../../state/cadastros.service';
import { ProprietariosQuery } from '../state/proprietarios.query';
import { ProprietariosService } from '../state/proprietarios.service';

@Component({
  templateUrl: './proprietarios-editar.page.html',
  styleUrls: ['./proprietarios-editar.page.scss']
})
export class ProprietariosEditarPage extends BaseComponent implements ViewWillEnter {
  private proprietarioId: string;
  private cadastroId: string;

  public alteracoes$: Observable<AlteracaoDto[]>;
  public proprietario$: Observable<ProprietarioStateDto>;
  /**
   * Indica qual o estado civil do proprietário
   * É necessário salvar essa informação no componente pois se o estado civil for alterado é necessário buscar os documentos novamente.
   */
  public proprietarioEstadoCivil: MaritalStatus;
  public formDesabilitado$: Observable<boolean>;
  public documentos: DocumentFileDto[];

  constructor(
    private cadastrosQuery: CadastrosQuery,
    private cadastroService: CadastroService,
    private proprietariosQuery: ProprietariosQuery,
    private proprietariosService: ProprietariosService,
    private loadingService: LoadingService,
    private route: ActivatedRoute,
    private toastService: ToastService,
  ) {
    super();
  }

  ionViewWillEnter(): void {
    this.route.params
      .pipe(
        tap(params => this.cadastroId = params.cadastroId),
        tap(params => this.proprietarioId = params.proprietarioId),
        tap(() => this.carregarProprietario()),
        tap(() => this.proprietario$ = this.proprietariosQuery.selectEntity((this.proprietarioId))),
        tap(() => this.formDesabilitado$ = this.cadastrosQuery.cadastroEstaDesabilitadoParaEdicao(this.cadastroId)),
        tap(() => this.alteracoes$ = this.cadastrosQuery.buscarAlteracoes$(this.cadastroId, TIPOS_ALTERACAO_PROPRIETARIO, this.proprietarioId)),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe();
  }

  public async carregarProprietario(event?: any): Promise<void> {
    if (!event) {
      await this.loadingService.createLoader();
    }

    this.proprietariosService.buscarProprietario(this.cadastroId, this.proprietarioId)
      .pipe(
        tap(proprietario => this.proprietarioEstadoCivil = proprietario?.maritalStatus),
        switchMap(() => this.proprietariosService.buscarDocumentos(this.cadastroId, this.proprietarioId)),
        tap(documentos => this.documentos = documentos),
        switchMap(() => iif(() =>
          this.cadastrosQuery.cadastroEstaEmAlteracoesNecessarias(this.cadastroId),
          this.cadastroService.buscarUltimaRevisao(this.cadastroId, true),
          of()
        )),
        catchError(() => this.toastService.error('Erro ao buscar dados básicos do proprietário. Por favor tente novamente.')),
        finalize(async () => event ? event.target.complete() : await this.loadingService.dismiss()),
      )
      .subscribe();
  }

  public async alterarDadosPessoais(dadosPessoais: ProprietarioDadosPessoaisDto): Promise<void> {
    await this.loadingService.createLoader();
    this.proprietariosService.atualizarDadosPessoais(this.cadastroId, this.proprietarioId, dadosPessoais)
      .pipe(
        switchMap(() => {
          if (dadosPessoais?.maritalStatus !== this.proprietarioEstadoCivil) {
            this.proprietarioEstadoCivil = dadosPessoais?.maritalStatus;
            return this.proprietariosService.buscarDocumentos(this.cadastroId, this.proprietarioId);
          }
          return of(this.documentos);
        }),
        tap(documentos => this.documentos = documentos),
        catchError(error => {
          console.error(error);
          return this.toastService.error('Erro ao atualizar dados pessoais do proprietário. Por favor tente novamente.')
        }),
        finalize(async () => await this.loadingService.dismiss()),
      )
      .subscribe();
  }

  public async alterarDadosDocumento(dadosDocumento: GetUpdateOwnerDocumentsDto): Promise<void> {
    await this.loadingService.createLoader();
    this.proprietariosService.atualizarDadosDocumentos(this.cadastroId, this.proprietarioId, dadosDocumento)
      .pipe(
        catchError(error => {
          console.error(error);
          return this.toastService.error('Erro ao atualizar documentos do proprietário. Por favor tente novamente.')
        }),
        finalize(async () => await this.loadingService.dismiss()),
      )
      .subscribe();
  }

  public async alterarDadosContato(dadosContato: GetUpdateOwnerContactDto): Promise<void> {
    await this.loadingService.createLoader();
    this.proprietariosService.atualizarDadosContato(this.cadastroId, this.proprietarioId, dadosContato)
      .pipe(
        catchError(error => {
          console.error(error);
          return this.toastService.error('Erro ao atualizar documentos do proprietário. Por favor tente novamente.')
        }),
        finalize(async () => await this.loadingService.dismiss()),
      )
      .subscribe();
  }
}
