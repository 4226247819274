/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { PipesModule } from '@usucampeao/utils-frontend';
import { FooterModule, HeaderModule, MenuItemModule, UploadFotoDocumentoModule, UploadFotoImovelModule } from '../../../components';
import { CadastroStatusModule } from '../../../components/cadastro-ficha-status/cadastro-status.module';
import { CadastrosFichaComponent } from './cadastros-ficha.component';
import { PagamentosResumoComponent } from './pagamentos-resumo/pagamentos-resumo.component';

@NgModule({
  declarations: [
    CadastrosFichaComponent,
    PagamentosResumoComponent,
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,

    // components
    CadastroStatusModule,
    HeaderModule,
    FooterModule,
    MenuItemModule,
    UploadFotoImovelModule,
    UploadFotoDocumentoModule,

    // others
    PipesModule,
  ],
  exports: [
    CadastrosFichaComponent
  ]
})
export class CadastrosFichaModule { }
