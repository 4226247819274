import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent, LoadingService, PagamentoStateDto, ToastService } from '@usucampeao/ui-mobile';
import { Observable } from 'rxjs';
import { catchError, finalize, take, takeUntil, tap } from 'rxjs/operators';
import { CadastrosQuery } from '../../state/cadastros.query';
import { PagamentosService } from '../state/pagamentos.service';

@Component({
  templateUrl: './pagamentos-detalhes.page.html',
  styleUrls: ['./pagamentos-detalhes.page.scss']
})
export class PagamentosDetalhesPage extends BaseComponent implements OnInit {
  private pagamentoId: string;
  private cadastroId: string;

  public pagamento$: Observable<PagamentoStateDto>;

  constructor(
    private cadastrosQuery: CadastrosQuery,
    private loadingService: LoadingService,
    private pagamentosService: PagamentosService,
    private route: ActivatedRoute,
    private toastService: ToastService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.route.params
      .pipe(
        tap(params => this.cadastroId = params.cadastroId),
        tap(params => this.pagamentoId = params.pagamentoId),
        tap(() => this.pagamento$ = this.cadastrosQuery.buscarPagamento(this.cadastroId, this.pagamentoId)),
        tap(() => this.buscarDetalhesPagamento()),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe();
  }

  public async buscarDetalhesPagamento(event?: any): Promise<void> {
    if (!event) {
      await this.loadingService.createLoader();
    }

    this.pagamentosService.buscarPagamentosDetalhes(this.cadastroId, this.pagamentoId)
      .pipe(
        catchError(error => {
          console.error(error);
          return this.toastService.error('Erro ao buscar dados do pagamento. Por favor tente novamente.');
        }),
        finalize(async () => event ? event.target.complete() : await this.loadingService.dismiss()),
      )
      .subscribe();
  }

  public async abrirBoleto(): Promise<void> {
    await this.loadingService.createLoader();
    this.pagamentosService.downloadBoletoUrl(this.cadastroId, this.pagamentoId)
      .pipe(
        tap(data => window.open(data.url, "_blank")),
        catchError(() => this.toastService.error('Erro ao buscar boleto. Tente novamente mais tarde.')),
        take(1),
        finalize(async () => await this.loadingService.dismiss())
      )
      .subscribe();
  }

}
