import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ViewWillEnter } from '@ionic/angular';
import { AddressDto, AlteracaoDto, DocumentFileDto, GetUpdatePropertyAdditionalInformationsDto, TIPOS_ALTERACAO_IMOVEL } from '@usucampeao/lib-reurb-simplificado';
import { BaseComponent, ImovelStateDto, LoadingService, ToastService } from '@usucampeao/ui-mobile';
import { iif, Observable, of } from 'rxjs';
import { catchError, finalize, switchMap, takeUntil, tap } from 'rxjs/operators';
import { CadastrosQuery } from '../../state/cadastros.query';
import { CadastroService } from '../../state/cadastros.service';
import { ImoveisQuery } from '../state/imoveis.query';
import { ImoveisService } from '../state/imoveis.service';

@Component({
  templateUrl: './imoveis-editar.page.html',
  styleUrls: ['./imoveis-editar.page.scss']
})
export class ImoveisEditarPage extends BaseComponent implements ViewWillEnter {
  private imovelId: string;
  private cadastroId: string;

  public alteracoes$: Observable<AlteracaoDto[]>;
  public imovel$: Observable<ImovelStateDto>;
  public formDesabilitado$: Observable<boolean>;
  public documentos: DocumentFileDto[];

  constructor(
    private cadastrosQuery: CadastrosQuery,
    private cadastroService: CadastroService,
    private imoveisQuery: ImoveisQuery,
    private imoveisService: ImoveisService,
    private loadingService: LoadingService,
    private route: ActivatedRoute,
    private toastService: ToastService,
  ) {
    super();
  }

  ionViewWillEnter(): void {
    this.route.params
      .pipe(
        tap(params => this.cadastroId = params.cadastroId),
        tap(params => this.imovelId = params.imovelId),
        tap(() => this.carregarImovel()),
        tap(() => this.imovel$ = this.imoveisQuery.selectEntity((this.imovelId))),
        tap(() => this.formDesabilitado$ = this.cadastrosQuery.cadastroEstaDesabilitadoParaEdicao(this.cadastroId)),
        tap(() => this.alteracoes$ = this.cadastrosQuery.buscarAlteracoes$(this.cadastroId, TIPOS_ALTERACAO_IMOVEL)),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe();
  }

  public async carregarImovel(event?: any): Promise<void> {
    if (!event) {
      await this.loadingService.createLoader();
    }

    this.imoveisService.buscarImovelPorCadastroId(this.cadastroId)
      .pipe(
        switchMap(() => this.imoveisService.buscarDocumentos(this.cadastroId, this.imovelId)),
        tap(documentos => this.documentos = documentos),
        switchMap(() => iif(() =>
          this.cadastrosQuery.cadastroEstaEmAlteracoesNecessarias(this.cadastroId),
          this.cadastroService.buscarUltimaRevisao(this.cadastroId, true),
          of()
        )),
        catchError(() => this.toastService.error('Erro ao buscar dados do imóvel. Por favor tente novamente.')),
        finalize(async () => event ? event.target.complete() : await this.loadingService.dismiss()),
      )
      .subscribe();
  }

  public async alterarEndereco(endereco: AddressDto): Promise<void> {
    await this.loadingService.createLoader();
    this.imoveisService.atualizarEndereco(this.cadastroId, this.imovelId, endereco)
      .pipe(
        catchError(error => {
          this.toastService.error('Erro ao atualizar endereço do imóvel. Por favor tente novamente.').toPromise();
          throw error;
        }),
        finalize(async () => await this.loadingService.dismiss()),
      )
      .subscribe();
  }

  public async alterarInformacoesAdicionais(informacoesAdicionais: GetUpdatePropertyAdditionalInformationsDto): Promise<void> {
    await this.loadingService.createLoader();
    this.imoveisService.atualizarInformacoesAdicionais(this.cadastroId, this.imovelId, informacoesAdicionais)
      .pipe(
        catchError(error => {
          this.toastService.error('Erro ao atualizar informações adicionais do imóvel. Por favor tente novamente.').toPromise();
          throw error;
        }),
        finalize(async () => await this.loadingService.dismiss()),
      )
      .subscribe();
  }
}
