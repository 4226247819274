import { Component, Input } from '@angular/core';
import {
  DOCUMENTOS_ESTADO_CIVIL,
  DOCUMENTOS_IDENTIDADE,
  DocumentFileDto,
  DocumentFileSubtype,
  MaritalStatus,
  OwnerDocument
} from '@usucampeao/lib-reurb-simplificado';

@Component({
  selector: 'usucampeao-proprietarios-documentos-selecionar-tipo',
  templateUrl: './proprietarios-documentos-selecionar-tipo.component.html',
  styleUrls: ['./proprietarios-documentos-selecionar-tipo.component.scss']
})
export class ProprietariosDocumentosSelecionarTipoComponent {
  @Input() tipo: DocumentFileSubtype;
  @Input() documentos: DocumentFileDto[] = [];
  @Input() estadoCivilProprietario: MaritalStatus;

  public get documentosIdentidade(): OwnerDocument[] {
    const isDocumentoIdentidade = this.tipo === DocumentFileSubtype.IDENTITY;

    if (isDocumentoIdentidade || !this.tipo) {

      return DOCUMENTOS_IDENTIDADE
        .filter(tipo => !this.documentos?.find(documento => documento.type === tipo));
    }

    return [];
  }

  public get documentosEstadoCivil(): OwnerDocument[] {
    const isDocumentosEstadoCivil = this.tipo === DocumentFileSubtype.MARITAL_STATUS;

    if (isDocumentosEstadoCivil || !this.tipo) {
      return DOCUMENTOS_ESTADO_CIVIL
        .filter(tipo => !this.documentos?.find(documento => documento.type === tipo) && this.mostrarDocumento(tipo));
    }

    return [];
  }

  private mostrarDocumento(documento: OwnerDocument): boolean {
    switch (documento) {
      case OwnerDocument.BIRTH_CERTIFICATE:
        return this.estadoCivilProprietario === MaritalStatus.SINGLE;

      case OwnerDocument.DEATH_CERTIFICATE:
        return this.estadoCivilProprietario === MaritalStatus.WIDOWED;

      case OwnerDocument.DIVORCE_CERTIFICATE:
        return this.estadoCivilProprietario === MaritalStatus.DIVORCED;

      case OwnerDocument.MARRIAGE_CERTIFICATE:
        return this.estadoCivilProprietario !== MaritalStatus.SINGLE;

      default:
        return true;
    }
  }
}
