import { Component, Input, SkipSelf } from '@angular/core';
import { ControlContainer } from '@angular/forms';

@Component({
  selector: 'usucampeao-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  viewProviders: [{
    provide: ControlContainer,
    useFactory: (container: ControlContainer) => container,
    deps: [[new SkipSelf(), ControlContainer]],
  }]
})
export class InputComponent {
  @Input() label: string;
  @Input() type = 'text';
  @Input() inputmode = 'text';
  @Input() placeholder: string;
  @Input() controlName: string;
  @Input() readonly = false;
  @Input() mask?: string;
  @Input() suffixCenter = false;
}
