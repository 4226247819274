import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProjetoMapaPage } from './projeto-mapa.page';

const routes: Routes = [
  { path: '', component: ProjetoMapaPage }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProjetoMapaPageRoutingModule { }
