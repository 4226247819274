import { Transform } from 'class-transformer';

export function ConvertDate() {
  return Transform(data => convertDate(data.value));
}

export function convertDate(data: any): any {
  if (data?.toDate) {
    return data.toDate();
  }
  return data;
}
