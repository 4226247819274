import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { CadastroStatusModule } from 'libs/ui-mobile/src/lib/components/cadastro-ficha-status/cadastro-status.module';
import { FooterModule, HeaderModule, InputModule } from '../../../../components';
import { ProprietariosDadosContatoComponent } from './proprietarios-dados-contato.component';

@NgModule({
  declarations: [
    ProprietariosDadosContatoComponent
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,
    ReactiveFormsModule,

    // components
    CadastroStatusModule,
    FooterModule,
    HeaderModule,
    InputModule,
  ],
  exports: [
    ProprietariosDadosContatoComponent
  ]
})
export class ProprietariosDadosContatoModule { }
