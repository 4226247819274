import { Expose, plainToInstance, Type } from 'class-transformer';
import { IsDateString, IsNotEmpty, IsNumber, IsOptional, IsString } from 'class-validator';
import { IntegrationOmieAccountReceivableDto } from './integration-omie-account-receivable-dto.model';

export class OmieRecebimentoDto {
  @Expose({ name: 'codigo_conta_corrente' })
  bankAccountId?: number;

  @Expose({ name: 'valor' })
  amount: number;

  @Expose()
  desconto?: number;

  @Expose()
  juros?: number;

  @Expose()
  multa?: number;

  @Expose({ name: 'data' })
  date: string;

  @Expose()
  observacao?: string;
}


/**
 * TODO: Alterar atributos para português após ajustar back antigo.
 * TODO: Provavelmente essa classe será removida pois podemos adaptar a classe IntegrationOmieCustomerDto para resolver todos os problemas.
 */
export class OmieAccountReceivableDto {
  @Expose({ name: 'omieId' })
  @IsNumber()
  @IsOptional()
  //@ApiProperty()
  id?: number;

  @Expose({ name: 'id' })
  @IsString()
  @IsNotEmpty()
  //@ApiProperty()
  paymentId: string;

  @Expose({ name: 'omieClienteId' })
  @IsNumber()
  @IsNotEmpty()
  //@ApiProperty()
  customerId: number;

  @Expose()
  @IsDateString()
  @IsNotEmpty()
  //@ApiProperty()
  dueDate: Date;

  @Expose()
  @IsNumber()
  @IsNotEmpty()
  //@ApiProperty()
  amount: number;

  @Expose()
  valorDevido: number;

  @Expose()
  valorPago?: number;

  @Expose()
  @IsString()
  @IsNotEmpty()
  //@ApiProperty()
  categoryId: string;

  @Expose()
  @IsNumber()
  @IsNotEmpty()
  //@ApiProperty()
  bankAccountId: number;

  @Expose({ name: 'omieProjetoId' })
  @IsNumber()
  @IsNotEmpty()
  //@ApiProperty()
  projectId: number;

  @Expose()
  @IsNumber()
  @IsOptional()
  installment?: number;

  @Expose()
  @IsNumber()
  @IsOptional()
  totalInstallment?: number;

  @Expose({ name: 'status_titulo' })
  statusTitulo?: OmieContaReceberStatusTitulo;

  @Expose()
  @Type(() => OmieRecebimentoDto)
  recebimento?: OmieRecebimentoDto;

  @Expose()
  baixarDocumento?: OmieBaixarDocumento;

  /**
   * Cria uma nova instância de IntegrationOmieAccountReceivableDto
   * @param props DTO de pagamento
   * @returns nova instância de IntegrationOmieAccountReceivableDto
   */
  static from(props?: Partial<IntegrationOmieAccountReceivableDto>): OmieAccountReceivableDto {
    return plainToInstance(OmieAccountReceivableDto, props, { exposeUnsetFields: false, excludeExtraneousValues: true });
  }
}

export enum OmieContaReceberStatusTitulo {
  RECEBIDO = 'RECEBIDO',
}

export enum OmieBaixarDocumento {
  S = 'S',
  N = 'N'
}
