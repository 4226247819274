import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { StatusDocument, StatusPreenchimento } from '@usucampeao/lib-reurb-simplificado';

@Component({
  selector: 'usucampeao-menu-item',
  templateUrl: 'menu-item.component.html',
  styleUrls: ['menu-item.component.scss'],
})
export class MenuItemComponent implements OnChanges {
  @Input() icon: string;
  @Input() title: string;
  @Input() description?: string;
  @Input() comments?: string;
  @Input() isNavigation = false;
  @Input() showIconStatus = false;
  @Input() iconStatusColor: string = "medium";
  @Input() status: StatusPreenchimento | StatusDocument = StatusPreenchimento.PENDENTE;
  @Input() iconStatus?: string = 'ellipse-outline';
  @Input() isDisabled = false;

  private hasIcon = false;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.iconStatus?.firstChange && changes.iconStatus?.currentValue) {
      this.hasIcon = true;
    }

    if (!this.hasIcon && changes.status) {
      switch (changes.status.currentValue) {
        case StatusPreenchimento.APROVADO:
        case StatusPreenchimento.ENVIADO:
          this.iconStatus = 'checkmark-circle-sharp';
          this.iconStatusColor = 'success';
          if (!this.comments) {
            this.comments = 'Alterar';
          }
          break;

        case StatusPreenchimento.ALTERACAO_NECESSARIA:
        case StatusDocument.REJECTED:
          this.iconStatus = 'close-circle';
          this.iconStatusColor = 'danger';
          if (!this.comments) {
            this.comments = 'Alterar';
          }
          break;
        case StatusPreenchimento.JUSTIFICADO:
          this.iconStatus = 'checkmark-circle-sharp';
          this.iconStatusColor = 'medium';
          if (!this.comments) {
            this.comments = 'Justificativa adicionada';
          }
          break;

        default:
          this.iconStatus = 'ellipse-outline';
          this.iconStatusColor = 'medium';
          if (!this.comments) {
            this.comments = 'Preencher';
          }
          break;
      }
    }
  }

  public get isStatusPendenteOuAlteracaoNecessaria(): boolean {
    return this.status === StatusPreenchimento.PENDENTE || this.status === StatusPreenchimento.ALTERACAO_NECESSARIA;
  }
}
