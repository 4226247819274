<ion-content class="ion-padding">
  <ion-item lines="none" class="ion-no-padding">
    <ion-text slot="start" class="title">Termos de Uso</ion-text>
    <ion-icon (click)="dismiss()" slot="end" name="close-outline"></ion-icon>
  </ion-item>
  <ion-text class="description-text ion-margin-bottom">
    <p>Última atualização em 07 de fevereiro de 2023.</p>
  </ion-text>

  <div class="m-termos-uso__content">
    <div class="m-termos-uso__section">
      <h2 class="title title--medium">Introdução</h2>
      <ion-text class="ion-text-justify font-text font-text--small">
        <p>
          Os presentes Termos de Uso (“Termos de Uso”) destinam-se a regular o acesso pelos clientes, vendedores e
          usuários (“Usuário”) do site institucional (“Website”) e aplicativo (“Aplicativo”) da USUCAMPEAO TECNOLOGIA EM
          REGULARIZACAO FUNDIARIA LTDA. com relação às informações e serviços (“Serviços”) disponíveis no Website e
          Aplicativo (“Plataformas”), desenvolvidos pela USUCAMPEAO TECNOLOGIA EM REGULARIZACAO FUNDIARIA LTDA
          (“USUCAMPEÃO”), inscrita no CNPJ/MF sob o nº 36.174.049/0001-08, com sede na na Rua Cláudio Soares, nº 72, 6º
          Andar, conjunto 615, Pinheiros, CEP 05422-030, São Paulo – SP.
          A USUCAMPEÃO desenvolveu os presentes Termos de Uso, para que o Usuário tenha conhecimento das condições
          gerais
          que deverão ser observadas ao utilizar as Plataformas, e em reconhecimento da importância dos cuidados devidos
          no tratamento de dados pessoais, visando garantir a proteção dos direitos e liberdades dos titulares de dados
          e
          para manter a USUCAMPEÃO em conformidade com as leis e princípios de privacidade vigentes, especialmente a Lei
          Ordinária nº 13.709/2018 (“LGPD”)
        </p>
      </ion-text>
    </div>
    <div class="m-termos-uso__section">
      <h2 class="title title--medium">Definições</h2>

      <ion-text class="ion-text-justify font-text font-text--small">
        <p>
          Para os fins destes Termos de Uso, as expressões abaixo terão o seguinte significado:
        </p>
        <ul>
          <li>
            <span class="font-text--bold">USUCAMPEÃO:</span> Significa a USUCAMPEAO TECNOLOGIA EM REGULARIZACAO
            FUNDIARIA LTDA;
          </li>
          <li>
            <span class="font-text--bold">Usuário:</span> Significam usuários do Website da USUCAMPEÃO, vendedores,
            parceiros ou Clientes (Pessoas Físicas ou
            Jurídicas) que utilizem o Site ou Aplicativo USUCAMPEÃO;

          </li>
          <li>
            <span class="font-text--bold">Termos de Uso:</span> Significa o conjunto destes Termos e seus links
          </li>
          <li>
            <span class="font-text--bold">Política de Privacidade:</span> Significa a política de privacidade que tem
            como intuito regular os direitos e
            deveres da USUCAMPEAO no que tange à proteção de Dados Pessoais e informar o Usuário sobre como seus dados
            serão coletados, tratados e armazenados.

          </li>
          <li>
            <span class="font-text--bold">Plataformas:</span> Significa Site e Aplicativo da USUCAMPEAO
          </li>
          <li>
            <span class="font-text--bold">Dados Pessoais:</span> Significam as informações que permitem identificar
            pessoas naturais, incluindo os
            representantes legais de pessoas jurídicas;

          </li>
          <li>
            <span class="font-text--bold">Propriedade Intelectual:</span> Significa individualmente ou em conjunto,
            programas de computador, arquivos,
            textos,
            ícones, desenhos, vídeos, sons, marcas, logotipos, layouts, templates, invenções, modelos de utilidade,
            desenho industrial, obras artísticas, científicas ou literárias e todos os materiais, obras, planos e
            objetos,
            em qualquer formato ou suporte físico, que sejam passíveis de proteção com base na Lei de Propriedade
            Industrial (Lei nº 9.279/1996), na Lei de Direito Autoral (Lei nº 9.610/1998), na Lei de Software (Lei nº
            9.609/1998), em convenções internacionais de que o Brasil seja parte, que sejam de titularidade, posse,
            domínio da USUCAMPEAO, ou que sejam por ela utilizados na criação, fornecimento e manutenção das
            Plataformas,
            estejam eles registrados, patenteados, com pedido de registro ou depósito pendente, ou não.
          </li>
        </ul>
      </ion-text>
    </div>
    <div class="m-termos-uso__section">
      <h2 class="title title--medium">Objeto</h2>

      <ion-text class="ion-text-justify font-text font-text--small">
        <p>
          A coleta, tratamento e armazenamento de Dados Pessoais realizados pela USUCAMPEAO através das Plataformas são
          regidas por sua Política de Privacidade. Os documentos serão interpretados sempre em conjunto, prevalecendo a
          Política de Privacidade, entretanto, no que for específica em relação aos Termos de Uso. A Política de
          Privacidade, em sua versão mais atual, poderá sempre ser acessada pelo link: <a
            href="https://usucampeao.com.br" target="_blank">https://usucampeao.com.br</a>
        </p>
        <p>
          Todas as informações fornecidas pelo Usuário, assim como o próprio registro, serão armazenadas pela USUCAMPEAO
          de forma responsável e em conformidade com as Leis de Proteção de Dados Pessoais, pelo período que as leis
          aplicáveis determinarem e a finalidade necessária.
        </p>
        <p>
          Estes Termos de Uso contêm informações jurídicas importantes e indispensáveis à utilização das Plataformas. Ao
          utilizar os Serviços da USUCAMPEAO, o Usuário aceita e concorda com todos os termos e condições aqui
          descritos,
          vigentes nesta data. O Usuário não pode e não deve acessar as Plataformas se não concordar com estes Termos de
          Uso, e caso discorde de qualquer dispositivo aqui mencionado, deverá cancelar o procedimento de cadastro,
          quando
          aplicável, bem como abster-se de utilizar as Plataformas.
        </p>
        <p>
          Alertamos que estes Termos de Uso poderão ser modificados a qualquer momento pela USUCAMPEAO, em virtude de
          alterações na legislação ou nos Serviços, em decorrência da utilização de novas ferramentas tecnológicas ou,
          ainda, sempre que, ao exclusivo critério da USUCAMPEAO, tais alterações se façam necessárias. A utilização dos
          Serviços online disponibilizados pela USUCAMPEAO por qualquer Usuário implicará em expressa aceitação destes
          Termos de Uso. A USUCAMPEAO se reserva o direito de aprimorar as funcionalidades dos Serviços e de implementar
          novas tecnologias. Assim, os Termos de Uso poderão ser alterados, a qualquer tempo, exceto em caso de vedação
          legal, para incluir as modificações implementadas. Ao continuar a utilizar os nossos Serviços após alterados
          os
          Termos de Uso, o Usuário concorda com as alterações realizadas e vigentes à época do acesso.
        </p>
        <p>
          Os Serviços do Site e Aplicativos estão disponíveis em conteúdo fechado. Quando o acesso ao conteúdo for
          restrito, será necessário prévio cadastro do Usuário e o acesso ao ambiente por meio de login e senha.
          <span class="font-text--bold font-text--underline">Ao
            criar
            o seu cadastro no Site ou Aplicativo, o Usuário se responsabiliza integralmente pelo uso, acesso, cadastro
            ou
            qualquer outro ato realizado por meio das Plataformas.</span> O Usuário é responsável, ainda, por (i) criar
          e manter
          a
          salvo sua senha de acesso; (ii) pela veracidade das informações cadastradas e sendo, assim, desde já
          ressaltamos
          que o cadastro de informações falsas pode gerar inconsistência na prestação dos Serviços, bem como impactar ou
          interromper o seu acesso; (iii) manter suas informações atualizadas e corretas e atualizar o seu cadastro
          sempre
          que houver alterações. A qualquer tempo, sem aviso prévio, a USUCAMPEAO poderá suspender, cancelar ou
          interromper o acesso aos Serviços, respeitadas as condições da legislação aplicável. A USUCAMPEAO não se
          responsabiliza por eventuais danos e/ou problemas decorrentes da demora, interrupção ou bloqueio nas
          transmissões de dados decorrentes da conexão de internet do Usuário.
        </p>
      </ion-text>

      <h3 class="title title--small">Compartilhamento de Dados com Terceiros</h3>
      <ion-text class="ion-text-justify font-text font-text--small">
        <p>A USUCAMPEAO poderá compartilhar os dados pessoais do Usuário com terceiros, tais como seguradoras,
          provedores
          de serviço e armazenamento em nuvem (Cloud Computing/Storage as a Service) e demais parceiros.
          Também podemos divulgar seus dados pessoais, quando tal divulgação for necessária para o cumprimento de uma
          obrigação legal à qual estamos sujeitos, ou a fim de proteger seus interesses vitais ou os interesses vitais
          de
          outra pessoa natural. Também podemos divulgar seus dados pessoais quando tal divulgação for necessária para o
          exercício ou defesa de reivindicações legais, seja em processos judiciais, administrativo ou arbitral.
        </p>
      </ion-text>

      <h3 class="title title--small">Armazenamento e Eliminação dos Dados</h3>
      <ion-text class="ion-text-justify font-text font-text--small">
        <p>Seus dados pessoais serão armazenados pelo período necessário para o seu tratamento, sendo excluídos ou
          anonimizados após este período caso não surja uma nova finalidade que justifique sua manutenção na base da
          USUCAMPEAO.
          A USUCAMPEAO determina o período de retenção com base na finalidade do tratamento, de forma que os dados
          pessoais coletados serão tratados e conservados apenas enquanto necessários para o cumprimento das finalidades
          citadas acima.
          Excepcionalmente, os dados pessoais podem ser mantidos nos arquivos da USUCAMPEAO para: (i) o cumprimento de
          obrigações previstas em normas ou; (ii) para estabelecer, exercer ou defender reivindicações legais; nesses
          casos os dados pessoais serão mantidos por um período maior, assegurando-se, contudo, que os mesmos estarão
          sob
          os mesmos mecanismos de segurança e proteção.
        </p>
      </ion-text>
    </div>

    <div class="m-termos-uso__section">
      <h2 class="title title--medium">Direitos do Usuário</h2>
      <ion-text class="ion-text-justify font-text font-text--small">
        <p>
          <span class="font-text--bold">Acesso aos Dados:</span> O Usuário titular de dados tem direito ao acesso aos
          dados disposto coletados pela
          USUCAMPEAO. O titular será informado da existência, uso e divulgação de suas informações pessoais e terá
          acesso
          a essas informações sempre que solicitado.
        </p>
        <p>
          <span class="font-text--bold">Correção de Dados:</span> O Usuário tem o direito de solicitar para que a
          USUCAMPEAO corrija informações incorretas,
          desatualizadas ou errôneas. A corrija também poderá entrar em contato com o Usuário para realizar a
          atualização
          dos dados coletados.
        </p>
        <p>
          <span class="font-text--bold">Eliminação de Dados:</span> O titular tem o direito de requerer o apagamento de
          seus dados pessoais caso entenda
          que<span class="font-text--bold">:</span>
          seus dados pessoais não são mais necessários em relação aos propósitos para os quais foram coletados ou estão
          sendo processados para finalidade distinta; oposição ao processamento com base em certas regras legais; ou
          ainda
          que seus dados pessoais tenham sido processados em desconformidade com o disposto nas Leis de Proteção de
          Dados
          Pes<span class="font-text--bold">soais. No entanto, há exceções ao direito de eliminação:</span> quando o
          processamento é necessário para o
          cumprimento de uma obrigação legal ou para o exercício ou defesa de reivindicações legais.
        </p>
        <p>
          <span class="font-text--bold">Restrição do processamento de dados:</span> Em algumas circunstâncias, o titular
          tem o direito de restringir o
          processamento de seus dados pessoais. Essas circunstâncias são: contestar
          a exatidão dos dados pessoais; o
          processamento está em desconformidade com o disposto nas Leis de Proteção de Dados Pessoais, mas há oposição
          ao
          apagamento; os dados pessoais não são mais necessários para fins de processamento pela USUCAMPEÃO, mas o
          titular
          requer dados pessoais para o estabelecimento, exercício ou defesa de reivindicações legais; e se o titular se
          opôs ao processamento, aguardando a verificação dessa objeção.
          Quando o processamento foi restrito com base em uma dessas hipóteses, a USUCAMPEÃO poderá continuar a
          armazenar
          seus dados pessoais. No entanto, a USUCAMPEÃO irá processá-los apenas para o exercício ou defesa de
          reivindicações legais; para a proteção dos direitos de outra pessoa física ou jurídica; ou por razões de
          interesse público.
        </p>
        <p>
          <span class="font-text--bold">Retirada do consentimento:</span> O titular tem o direito de, a qualquer
          momento, retirar o consentimento dado neste
          termo para o processamento de seus dados pessoais. Se o titular fizer tal objeção, a USUCAMPEÃO deixará de
          processar as informações pessoais a menos que possamos demonstrar motivos legítimos convincentes para o
          processamento que se sobrepõem aos seus interesses, direitos e liberdades, ou caso o processamento seja para o
          exercício ou defesa de reivindicações legais.
          O Usuário pode exercer qualquer um dos seus direitos em relação aos seus dados pessoais enviando um e-mail
          para
          <a href="mailto:contato@usucampeao.com.br" target="_blank">contato@usucampeao.com.br</a>
          <!-- TODO: Criar um grupo para cuidar das solicitações referentes a políticas de dados. -->
        </p>
      </ion-text>

      <h3 class="title title--small">4.1 Responsabilidades do Usuário</h3>
      <ion-text class="ion-text-justify font-text font-text--small">
        <p>Ao utilizar os Serviços, o Usuário se compromete a respeitar a legislação brasileira vigente e o disposto
          nestes Termos de Uso, não devendo produzir, disponibilizar ou compartilhar qualquer conteúdo que:</p>
        <ul>
          <li>
            Implique na prática de ato ilícito e/ou viole a legislação brasileira vigente;</li>
          <li>
            Seja falso, incorreto, impreciso e/ou que possa induzir terceiros a erro;
          </li>
          <li>
            Viole direitos de terceiro ou direitos da USUCAMPEÃO e seus parceiros;
          </li>
          <li>
            Disponibilize ou permita o acesso a conteúdo ilegal, violento, pornográfico ou qualquer outro ato contrário
            à
            lei e à ordem pública;
          </li>
          <li>
            Induza à discriminação ou incite o ódio contra pessoas e/ou grupos de pessoas em razão de nacionalidade,
            raça,
            religião, orientação sexual, gênero, condição física, dentre outros atos que contrariem a ordem pública e a
            legislação brasileira vigente;
          </li>
          <li>
            Contenha vírus ou outros elementos que sejam capazes de causar danos ou impedir o funcionamento regular das
            Plataformas e/ou equipamentos da USUCAMPEÃO ou de terceiros; e
          </li>
          <li>
            Seja resguardado por direito de propriedade intelectual de terceiro e o Usuário não tenha autorização prévia
            para utilizá-lo;
          </li>
        </ul>

        <p>Durante a utilização das Plataformas o Usuário será responsável por: (a) todo ato ilícito realizado nas
          Plataformas, assim como pela reparação dos danos causados a terceiros, a USUCAMPEÃO e seus parceiros
          decorrentes
          de tais atos ilícitos; e (b) pelos próprios custos referentes ao seu acesso à internet, assim como pela
          utilização de ferramentas antivírus;
        </p>
        <p>O Usuário fica, ainda, proibido, estando sujeito às penas legais aplicáveis, de: (i) modificar, apagar,
          sabotar
          ou de qualquer forma violar, ou auxiliar, incentivar ou facilitar a terceiro a violação das Plataformas; e
          (ii)
          de qualquer forma utilizar as Plataformas ou qualquer de seus componentes para fraudar os presentes Termos de
          Uso e/ou infringir a legislação brasileira.
          Caso a USUCAMPEÃO identifique, ou venha ser notificada por terceiro interessado, sobre a existência de ato
          ilícito relacionado à utilização das Plataformas, o conteúdo será prontamente removido, com a identificação do
          responsável e as providências legais cabíveis serão tomadas.
        </p>
      </ion-text>
    </div>
    <div class="m-termos-uso__section">
      <h2 class="title title--medium">Propriedade Intelectual</h2>
      <ion-text class="ion-text-justify font-text font-text--small">
        <p>
          A USUCAMPEÃO é titular exclusivo de todos os direitos, morais e patrimoniais, incidentes ou eventualmente
          incidentes sobre aspectos visuais e/ou artísticos das Plataformas, bem como do código-fonte, os elementos
          técnicos, de design, de processos, relatórios, e outros que ajudem a caracterizar as Plataformas, todos os
          sinais distintivos, marcas, logos e qualquer material passível de proteção por direitos autorais ou
          propriedade
          industrial. Para permitir a utilização do Site e Aplicativo da USUCAMPEÃO pelo Usuário, a USUCAMPEÃO concede,
          pela duração da vigência de seu vínculo com o Usuário, uma licença de uso pessoal, limitada, revogável,
          intransferível, não exclusiva e não passível de sublicenciamento, dos direitos de propriedade intelectual
          necessários à utilização das Plataformas, o que não inclui direitos de reprodução, alteração, comunicação ao
          público ou qualquer forma de distribuição. É estritamente proibido qualquer tipo de cópia, alteração ou
          redistribuição das Plataformas, do código-fonte ou de qualquer elemento incluído sob a sigla “Propriedade
          Intelectual” (conforme definição acima) em parte ou no todo. O Usuário também concorda que não vai fazer,
          tentar
          fazer, ou ajudar alguém a fazer nenhum tipo de engenharia reversa ou tentativa de acesso ao código fonte das
          Plataformas
        </p>
      </ion-text>
    </div>
    <div class="m-termos-uso__section">
      <h2 class="title title--medium">Exceções de Garantias</h2>
      <ion-text class="ion-text-justify font-text font-text--small">
        <p>
          As Plataformas são oferecidas tal como se encontram, não garantindo a USUCAMPEÃO que elas sejam consideradas
          aptas ou adequadas para qualquer outra finalidade além daquelas específicas e expressamente descritas nestes
          Termos de Uso, devendo o Usuário utilizá-las por sua conta e risco.
        </p>
        <p>As Plataformas foram desenvolvidas segundo os padrões do mercado de qualidade, segurança e confidencialidade,
          buscando a USUCAMPEÃO sempre cumprir os mais altos padrões de boas práticas com relação à manutenção das
          Plataformas e a segurança e confidencialidade dos dados nelas contidos. A USUCAMPEÃO não garante, entretanto,
          que as Plataformas são perfeitas ou não estejam sujeitas a falhas de segurança e erros de operação. O Usuário,
          ao utilizar as Plataformas, declara que possui ciência do aqui disposto e que a USUCAMPEÃO não poderá ser
          responsabilizada por tais ocorrências, comprometendo- se a USUCAMPEÃO, por outro lado, a agir sempre com
          diligência para resolver todo e qualquer problema de segurança, confidencialidade ou confiabilidade das
          Plataformas, em tempo razoável.
        </p>
        <p>A USUCAMPEÃO não pode garantir a absoluta segurança, integridade e confidencialidade dos dados, pessoais ou
          não, inseridos nas Plataformas, caso os dispositivos móveis ou fixos utilizados para acesso às Plataformas
          apresentem, por si, vulnerabilidades de segurança, sejam elas acidentais (erros, bugs, ou outros) ou
          propositais, sejam elas causadas por terceiros (malware, violação de código, ataques cibernéticos) ou pelo
          próprio Usuário (por meio de procedimentos que diminuem a segurança do dispositivo, como jailbrake ou obtenção
          de acesso root).
        </p>
        <p>
          O Usuário está ciente de que, apesar de toda a segurança que a USUCAMPEÃO implementa em suas Plataformas, é
          possível que agentes maliciosos possam tentar ou efetivamente consigam violar as Plataformas, ou o dispositivo
          do Usuário para auferir vantagem para si ou para terceiros, não havendo qualquer responsabilidade da
          USUCAMPEÃO
          pelos danos decorrentes das ações dos agentes maliciosos.
        </p>
        <p>
          As Plataformas estão sujeitas a interrupções ou falhas que poderão ser causadas por instabilidades dos
          terceiros
          provedores de acesso, falhas técnicas, manutenção, falibilidade de prestadores de serviços de conexão à
          internet, não havendo qualquer responsabilidade da USUCAMPEÃO pelos eventuais danos ou prejuízos decorrentes
          de
          tais eventos.
        </p>
      </ion-text>

      <h3 class="title title--small">6.1 Exceções de Responsabilidade</h3>
      <ion-text class="ion-text-justify font-text font-text--small">
        <p>
          A USUCAMPEÃO, seus administradores, empregados e subcontratados não são responsáveis por quaisquer danos,
          sejam
          eles de natureza física, moral, estética, econômica, acidental, punitiva, perda de chance, lucros cessantes,
          perda de dados, ou qualquer outra que o Usuário ou terceiro venha a experimentar direta ou indiretamente
          relacionados ao uso das Plataformas. O Usuário também reconhece que a USUCAMPEÃO não é responsável por
          qualquer
          tipo de danos oriundos de, mas não limitado a falhas dos equipamentos; vulnerabilidades de segurança nos
          dispositivos de acesso às Plataformas, danos causados por ações ou omissões do Usuário; utilização indevida
          dos
          equipamentos e dispositivos; interrupções ou erros na transmissão de dados pela internet ou telefonia celular;
          danos e prejuízos de toda a natureza decorrentes do conhecimento que terceiros não autorizados possam ter de
          quaisquer dados ou senhas obtidos do Usuário, intervenção de qualquer espécie do Poder Público ou ainda força
          maior ou caso fortuito. As Plataformas são ofertadas tal como se encontram e o uso feito das Plataformas é de
          responsabilidade do próprio Usuário.
        </p>
        <p>
          A USUCAMPEÃO, seus administradores, empregados e subcontratados não são responsáveis por quaisquer danos,
          sejam
          eles de natureza física, moral, estética, econômica, acidental, punitiva, perda de chance, lucros cessantes,
          perda de dados, ou qualquer outra que Usuário ou terceiro venham a experimentar em razão da interferência
          ilegítima de terceiros no Aplicativo.
        </p>
        <p>
          Caso a USUCAMPEÃO seja implicada de qualquer maneira em cobranças extrajudiciais ou qualquer outra medida
          judicial em decorrência de danos causados por um Usuário ou pessoas pelas quais é responsável o Usuário, o
          Usuário deverá intervir nos procedimentos em trâmite, de modo a isentar a USUCAMPEÃO de qualquer
          responsabilidade e de qualquer possível resultado negativo. Ainda, a USUCAMPEÃO terá direito integral de
          regresso contra determinado Usuário quando o dano a ser indenizado decorrer direta ou indiretamente de culpa
          ou
          dolo do referido Usuário.
        </p>
        <p>
          A USUCAMPEÃO não terá a obrigação de fornecer e não fornecerá eventuais logs ou registros de conexão e de
          acesso
          ao Site ou Aplicativos que não digam respeito ao próprio Usuário solicitante, exceto se assim determinado por
          decisão judicial proferida por autoridade competente.
        </p>
      </ion-text>
    </div>
    <div class="m-termos-uso__section">
      <h2 class="title title--medium">Das Disposições Gerais</h2>
      <ion-text class="ion-text-justify font-text font-text--small">
        <p>
          Estes Termos de Uso são regidos pelas leis da República Federativa do Brasil, sendo esta integralmente
          aplicável
          a quaisquer disputas que possam surgir sobre a interpretação ou execução destes Termos de Uso, assim como
          qualquer outra disputa que envolva direta ou indiretamente o uso das Plataformas pelo Usuário.
        </p>
        <p>
          Será competente para dirimir toda e qualquer controvérsia oriunda destes Termos de Uso o foro da cidade de São
          Paulo, Estado de São Paulo, com exclusão de qualquer outro por mais privilegiado que seja. Fica claro, ainda,
          que a utilização de Serviços e as ordens comandadas fora do território brasileiro, ou ainda as decorrentes de
          operações iniciadas no exterior podem estar sujeitas também à legislação e jurisdição das autoridades dos
          países
          onde forem comandadas ou iniciadas.
        </p>
      </ion-text>
    </div>
    <div class="m-termos-uso__section">
      <h2 class="title title--medium">Contato com a USUCAMPEÃO</h2>
      <ion-text class="ion-text-justify font-text font-text--small">
        <p>
          Caso o usuário tenha alguma dúvida, queira fazer alguma reclamação ou exercer algum dos seus direitos como
          Titular de dados pessoais conforme nossas Políticas de Privacidade, poderá entrar em contato com o nosso
          Escritório de Privacidade, através do contato abaixo:
        </p>
        <a href="mailto:contato@usucampeao.com.br" target="_blank">contato@usucampeao.com.br</a>
        <!-- TODO: Criar um grupo para cuidar das solicitações referentes a políticas de dados. -->
      </ion-text>
    </div>
  </div>

</ion-content>
