export class PagamentosResumoDto {
  quantidadeReceber: number;
  quantidadePendente: number;
  quantidadeRecebido: number;
  quantidadeInadimplentes: number;
  valorReceber: number;
  valorPendente: number;
  valorRecebido: number;
  valorInadimplentes: number;

  constructor(
    quantidadeReceber: number,
    quantidadePendente: number,
    quantidadeRecebido: number,
    quantidadeInadimplentes: number,
    valorReceber?: number,
    valorPendente?: number,
    valorRecebido?: number,
    valorInadimplentes?: number,
  ) {
    this.quantidadeReceber = quantidadeReceber;
    this.quantidadePendente = quantidadePendente;
    this.quantidadeRecebido = quantidadeRecebido;
    this.quantidadeInadimplentes = quantidadeInadimplentes;
    this.valorReceber = valorReceber || 0;
    this.valorPendente = valorPendente || 0;
    this.valorRecebido = valorRecebido || 0;
    this.valorInadimplentes = valorInadimplentes || 0;
  }
}
