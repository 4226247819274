import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { DatepickerModule, InputModule, SelectModule } from '@usucampeao/ui-mobile';
import { UsuarioFormComponent } from './usuario-form.component';



@NgModule({
  declarations: [
    UsuarioFormComponent
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,
    ReactiveFormsModule,

    // components
    InputModule,
    DatepickerModule,
    SelectModule,
  ],
  exports: [
    UsuarioFormComponent
  ]
})
export class UsuarioFormModule { }
