import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController, ViewWillEnter, ViewWillLeave } from '@ionic/angular';
import { CadastroContratoAssinaturaTipoEnvioLink, CadastroContratoTipoAssinatura } from '@usucampeao/interfaces';
import { ErrorCode } from '@usucampeao/lib-reurb-simplificado';
import { CadastroFichaDto, LoadingService, ModalSelecaoTipoEnvioLinkComponent, ModalSelecaoTipoEnvioLinkOutput, ToastService } from '@usucampeao/ui-mobile';
import { of, Subject } from 'rxjs';
import { catchError, filter, finalize, switchMap, takeUntil, tap } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { ProjetoQuery } from '../../../../core/states/projeto/projeto.query';
import { ProjetoService } from '../../../../core/states/projeto/projeto.service';
import { ProjetoStateDto } from '../../../../core/states/projeto/projeto.store';
import { CadastrosQuery } from '../../state/cadastros.query';
import { CadastroService } from '../../state/cadastros.service';

@Component({
  templateUrl: 'contrato-selecionar-tipo-assinatura.page.html',
  styleUrls: ['contrato-selecionar-tipo-assinatura.page.scss'],
})
export class ContratoSelecionarTipoAssinaturaPage implements ViewWillEnter, ViewWillLeave, OnDestroy {

  public readonly mostrarAssinaturaDigital = environment.featuresFlags.contrato.assinaturaDigital;
  private ngUnsubscribe$ = new Subject();
  private cadastroFichaDto: CadastroFichaDto;
  public projeto: Partial<ProjetoStateDto>;

  constructor(
    private cadastroQuery: CadastrosQuery,
    private cadastroService: CadastroService,
    private loadingService: LoadingService,
    private modalCtrl: ModalController,
    private projetosQuery: ProjetoQuery,
    private projetoService: ProjetoService,
    private router: Router,
    private route: ActivatedRoute,
    private toastService: ToastService,
  ) { }

  ionViewWillEnter(): void {
    this.route.params
      .pipe(
        switchMap(params => this.cadastroQuery.buscarCadastroFicha(params.cadastroId)),
        tap(cadastro => this.cadastroFichaDto = cadastro),
        tap(() => this.carregarProjeto()),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe();
  }

  ionViewWillLeave(): void {
    this.ngUnsubscribe$.next();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.complete();
  }

  public get cadastroContratoTipoAssinatura(): typeof CadastroContratoTipoAssinatura {
    return CadastroContratoTipoAssinatura;
  }


  private carregarProjeto(): void {
    this.projetosQuery.selectEntity(this.cadastroFichaDto.projetoFid)
      .pipe(
        tap(projeto => this.projeto = projeto),
        filter(projeto => !projeto),
        tap(async () => await this.loadingService.createLoader()),
        switchMap(() => this.projetoService.buscarDetalheProjeto(this.cadastroFichaDto.projetoFid)),
        finalize(() => this.loadingService.dismiss()),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe();
  }

  public goTo(route: string): void {
    this.router.navigate([route], { relativeTo: this.route })
  }

  public verificarSeTipoPorLinkESelecionarTipoAssinatura(tipo: CadastroContratoTipoAssinatura): void {
    if (tipo === CadastroContratoTipoAssinatura.LINK) {
      this.mostrarModalSelecaoTipoEnvioLink(tipo);
    } else {
      this.selecionarTipoAssinatura(tipo);
    }
  }

  private async mostrarModalSelecaoTipoEnvioLink(tipo: CadastroContratoTipoAssinatura): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalSelecaoTipoEnvioLinkComponent,
      componentProps: {
        proprietarioEmail: this.cadastroFichaDto.proprietarioEmail,
        proprietarioWhatsapp: this.cadastroFichaDto.proprietarioWhatsapp,
        cadastroId: this.cadastroFichaDto.id,
        proprietarioId: this.cadastroFichaDto.proprietarioId,
      },
      cssClass: 'modal-selecionar-tipo-envio-link'
    });

    await modal.present();
    const { data } = await modal.onDidDismiss<ModalSelecaoTipoEnvioLinkOutput>();
    if (data.confirmarSelecaoTipo) {
      this.selecionarTipoAssinatura(tipo, data.tipoEnvioLink);
    }
  }

  private async selecionarTipoAssinatura(tipo: CadastroContratoTipoAssinatura, tipoEnvioLink?: CadastroContratoAssinaturaTipoEnvioLink) {
    await this.loadingService.createLoader();
    this.cadastroService.selecionarTipoAssinatura(this.cadastroFichaDto.id, tipo)
      .pipe(
        switchMap(() => {
          if (tipo !== CadastroContratoTipoAssinatura.LINK) {
            return of(true);
          }

          return this.cadastroService.assinaturaContratoPorLink(this.cadastroFichaDto.id, tipoEnvioLink);
        }),
        tap(() => this.goTo(`../assinar`)),
        catchError(async error => {
          let message = 'Erro ao selecionar tipo de assinatura. Por favor tente novamente.';
          if (error?.error?.errorCode === ErrorCode.REGISTRATION_EXPIRED_CONTRACT) {
            message = 'Contrato está expirado! Por favor selecione outra forma de pagamento e gere o contrato novamente.';
          }

          await this.toastService.error(message).toPromise();
          throw error;
        }),
        finalize(() => this.loadingService.dismiss())
      )
      .subscribe();
  }
}
