import {
  AfterContentInit,
  AfterViewInit, Component, EventEmitter,
  Input,
  NgZone,
  Output, ViewChild
} from '@angular/core';
import { AlertController, IonSearchbar, ModalController } from '@ionic/angular';
import { LoteListarMapaDto, MapaDto, MapaTipo } from '@usucampeao/lib-reurb-simplificado';
import { LocalizacaoService } from '../../services/localizacao.service';
import { ModalAjudaSelecaoLoteComponent } from './modal-ajuda-selecao-lote/modal-ajuda-selecao-lote.component';
export const COR_LOTE = '#f07e26';
export const COR_LOTE_SELECIONADO = '#4da3fa';

@Component({
  selector: 'usucampeao-mapa',
  templateUrl: './mapa.component.html',
  styleUrls: ['./mapa.component.scss'],
})
export class MapaComponent implements AfterViewInit, AfterContentInit {
  @ViewChild('buscaEnderecoInput', { read: IonSearchbar }) searchbar: IonSearchbar;

  @Input() lat: number;
  @Input() lng: number;
  @Input() lotes: LoteListarMapaDto[] = [];
  @Input() loteSelecionadoId: number;
  @Input() mapa: MapaDto = new MapaDto();
  @Input() desabilitado = false;
  @Input() mostrarBusca = true;
  @Input() mostrarMarker = false;
  @Input() buscarLocalizacao = true;
  @Output() aoSelecionarLote = new EventEmitter<LoteListarMapaDto>();

  public showModal = false;
  public loteSelecionado: LoteListarMapaDto;
  public isGoogleApiCarregada = false;

  constructor(
    private alertCtrl: AlertController,
    private localizacaoService: LocalizacaoService,
    private modalCtrl: ModalController,
    private ngZone: NgZone,
  ) { }

  ngAfterViewInit(): void {
    if (this.isGoogleMaps) {
      this.carregarApiGoogleMaps();
    }
    if (this.mostrarBusca) {
      this.inicializarBuscaEndereco();
    }
    if (this.buscarLocalizacao) {
      this.buscarLocalizacaoUsuario();
    }
    if (this.loteSelecionadoId) {
      this.selecionarLote(this.loteSelecionadoId);
    }
  }

  ngAfterContentInit(): void {
    if (!this.lat || !this.lng) {
      this.calcularLatLngInicial();
    }
  }

  public get isGoogleMaps(): boolean {
    return this.mapa && this.mapa.tipo === MapaTipo.GOOGLE_MAPS;
  }

  private calcularLatLngInicial(): void {
    this.lat = (this.mapa.latitudeMaxima + this.mapa.latitudeMinima) / 2;
    this.lng = (this.mapa.longitudeMaxima + this.mapa.longitudeMinima) / 2;
  }

  private buscarLocalizacaoUsuario(): void {
    navigator.geolocation.getCurrentPosition((resp) => {
      const lat = resp.coords.latitude;
      const lng = resp.coords.longitude;
      if (this.validCurrentPosition(lat, lng)) {
        this.lat = lat;
        this.lng = lng;
      }
    }, async () => {
      const alert = await this.alertCtrl.create({
        header: 'Atenção',
        message: 'Não foi possível obter sua localização. Caso queira ver sua localização no mapa, conceda a permissão de acesso à sua localização e tente novamente.',
        backdropDismiss: true,
        buttons: [
          {
            text: 'Ok',
          },
        ],
      });
      await alert.present();
    });
  }

  private async carregarApiGoogleMaps(): Promise<void> {
    await this.localizacaoService.loaderGoogleMaps.importLibrary('visualization');
    this.isGoogleApiCarregada = true;
  }

  /**
   * Inicializa autocomplete do google
   */
  private async inicializarBuscaEndereco(): Promise<void> {
    const inputElement = await this.searchbar.getInputElement();
    const { Autocomplete } = await this.localizacaoService.loaderGoogleMaps.importLibrary('places');
    const autocomplete = new Autocomplete(inputElement);

    autocomplete.addListener('place_changed', () => {
      this.ngZone.run(async () => {
        const place = autocomplete.getPlace();
        if (place.geometry === undefined || place.geometry === null) {
          return;
        }

        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();

        if (this.validCurrentPosition(lat, lng)) {
          this.lat = lat;
          this.lng = lng;
        } else {
          const alert = await this.alertCtrl.create({
            header: 'Atenção',
            message: 'Local selecionado fora da área do bairro.',
            backdropDismiss: false,
            buttons: [
              {
                text: 'Ok',
                handler: () => {
                  inputElement.value = '';
                },
              },
            ],
          });
          await alert.present();
        }
      });
    });

    this.isGoogleApiCarregada = true;
  }

  /**
   * Valida se a posição selecionada está dentro do limite válido
   * @param lat latitude selecionada
   * @param lng longitude selecionada
   * @returns true caso a posição selecionada esteja dentro do limite válido ou false caso esteja fora
   */
  private validCurrentPosition(lat: number, lng: number): boolean {
    const minLat = this.mapa.latitudeMinima < this.mapa.latitudeMaxima ? this.mapa.latitudeMinima : this.mapa.latitudeMaxima;
    const maxLat = this.mapa.latitudeMaxima > this.mapa.latitudeMinima ? this.mapa.latitudeMaxima : this.mapa.latitudeMinima;
    const minLng = this.mapa.longitudeMinima < this.mapa.longitudeMaxima ? this.mapa.longitudeMinima : this.mapa.longitudeMaxima;
    const maxLng = this.mapa.longitudeMaxima > this.mapa.longitudeMinima ? this.mapa.longitudeMaxima : this.mapa.longitudeMinima;

    return lat > minLat && lat < maxLat && lng > minLng && lng < maxLng;
  }

  public selecionarLote(loteSelecionado: LoteListarMapaDto | number): void {
    if (typeof loteSelecionado === 'number') {
      this.loteSelecionado = this.lotes.find(lote => lote.id === loteSelecionado);
    } else {
      this.loteSelecionado = loteSelecionado;
    }

    if (!this.desabilitado) {
      this.showModal = true;
    }
  }

  public confirmarSelecaoLote(): void {
    this.aoSelecionarLote.emit(this.loteSelecionado);
  }

  public async mostrarModalAjudaSelecaoLote(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalAjudaSelecaoLoteComponent,
      cssClass: 'ajuda-selecionar-lote',
    });

    await modal.present();
  }
}
