import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'usucampeao-contrato-expirado',
  templateUrl: './contrato-expirado.component.html',
  styleUrls: ['./contrato-expirado.component.scss'],
})
export class ContratoExpiradoComponent {
  @Input() projetoId: string;
  @Input() cadastroId: string;

  constructor(private readonly router: Router) { }

  public navegarParaAssinaturaDoContrato(): void {
    this.router.navigate(['novo-cadastro/bairro', this.projetoId, 'cadastro', this.cadastroId, 'contrato'])
  }
}
