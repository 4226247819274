/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { PipesModule } from '@usucampeao/utils-frontend';
import { NgxMaskModule } from 'ngx-mask';
import { FooterModule, HeaderModule, InputModule, ModalSelecaoTipoEnvioLinkModule, SelectModule } from '../../../components';
import { ContratoComponent } from './contrato.component';
import { ModalAdicionarCupomDescontoComponent } from './modal-adicionar-cupom-desconto/modal-adicionar-cupom-desconto.component';
import { ModalConfirmarFechamentoContratoComponent } from './modal-confirmar-fechamento-contrato/modal-confirmar-fechamento-contrato.component';

@NgModule({
  declarations: [
    ContratoComponent,
    ModalAdicionarCupomDescontoComponent,
    ModalConfirmarFechamentoContratoComponent,
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,
    FormsModule,
    NgxMaskModule.forRoot(),
    ReactiveFormsModule,

    // components
    FooterModule,
    HeaderModule,
    InputModule,
    ModalSelecaoTipoEnvioLinkModule,
    SelectModule,

    // others
    PipesModule,
  ],
  exports: [
    ContratoComponent
  ],
})
export class ContratoModule { }
