<ion-content class="ion-padding l-selecionar-tutorial">
  <ion-text class="l-selecionar-tutorial__header">
    <h1 class="title title--medium ion-no-margin">Como localizar meu lote?</h1>
    <ion-button size="small" fill="clear" color="medium" (click)="fecharModal()">
      <ion-icon slot="icon-only" name="close-outline"></ion-icon>
    </ion-button>
  </ion-text>

  <ion-button size="small" (click)="fecharModal()">
    Voltar para o mapa
  </ion-button>

  <div class="l-selecionar-tutorial__item">
    <ion-text>
      <p class="font-text">O marcador vermelho indica a sua localização:</p>
    </ion-text>
    <ion-img src="assets/images/tutorial_lote/tutorial_lote01.svg"></ion-img>
  </div>
  <div class="l-selecionar-tutorial__item">
    <ion-text>
      <p class="font-text">Clique no lote indicado, ele ficará marcado em laranja:</p>
    </ion-text>
    <ion-img src="assets/images/tutorial_lote/tutorial_lote02.svg"></ion-img>
  </div>

  <div class="l-selecionar-tutorial__item">
    <ion-text>
      <h2 class="font-text font-text-bold ">Como navegar no mapa</h2>
      <p class="font-text">
        <i>Zoom</i>
        <br>
        Faça um movimento de pinça com os dedos ou clique nos botões de + e -
      </p>
    </ion-text>

    <div class="l-selecionar-tutorial__item-row">
      <ion-img class="small" src="assets/images/tutorial_lote/tutorial_lote03.svg"></ion-img>
      <span class="font-text">ou</span>
      <ion-img class="small" src="assets/images/tutorial_lote/tutorial_lote04.svg"></ion-img>
    </div>
  </div>

  <div class="l-selecionar-tutorial__item">
    <ion-text>
      <p class="font-text">
        <i>Mover a tela</i>
        <br>
        Encoste o dedo no mapa e arraste com o dedo ainda pressionado.
      </p>
    </ion-text>
    <ion-img class="small" src="assets/images/tutorial_lote/tutorial_lote05.svg"></ion-img>
  </div>
</ion-content>
