import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'usucampeao-pagamentos-resumo',
  templateUrl: './pagamentos-resumo.component.html',
  styleUrls: ['./pagamentos-resumo.component.scss']
})
export class PagamentosResumoComponent {
  @Input() valorEntrada: number;
  @Input() valorContrato: number;
  @Input() valorParcela: number;
  @Input() quantidadeParcelas: number;
  @Input() quantidadePagamentosNaoPagos = 0;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
  ) { }

  public get statusPagamentos(): string {
    if (this.quantidadePagamentosNaoPagos > 0) {
      return 'Parcelas em aberto';
    }

    return 'Todas as parcelas pagas';
  }

  public navegarParaPagamentos(): void {
    this.router.navigate(['pagamentos'], { relativeTo: this.route });
  }
}
