import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CupomDescontoService {
  constructor(
    private http: HttpClient
  ) { }

  public aplicarCupom(params: {
    cadastroId: string,
    codigo: string,
    parcelas_quantidade?: number,
  }): Observable<void> {
    const { cadastroId, codigo, parcelas_quantidade } = params;
    return this.http.put<void>(`/cadastros/${cadastroId}/cupons-desconto`, { codigo: codigo.toUpperCase(), parcelas_quantidade })
  }

  public desaplicarCupom(cadastroId: string): Observable<void> {
    return this.http.delete<void>(`/cadastros/${cadastroId}/cupons-desconto`);
  }
}
