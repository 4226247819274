<ion-content class="ion-padding">
  <ion-refresher slot="fixed" (ionRefresh)="carregarCadastros($event)">
    <ion-refresher-content>
    </ion-refresher-content>
  </ion-refresher>

  <section class="boletos">
    <usucampeao-header title="Boletos"></usucampeao-header>

    <a class="boletos__whatsapp" (click)="abrirWhatsapp()" target="blank">
      <ion-icon name="logo-whatsapp"></ion-icon>
      Preciso de ajuda
    </a>

    <div class="boletos__item" *ngFor="let cadastro of cadastrosDoUsuario$ | async">
      <usucampeao-accordion [titulo]="cadastro.projetoNome + ' - ' + cadastro.quadra + ' ' + cadastro.lote"
        [headerComDestaque]="true">
        <div class="boletos__pagamentos">
          <usucampeao-pagamentos-listagem *ngIf="cadastro.pagamentos?.length; else semPagamentos" [cadastro]="cadastro"
            [pagamentos]="cadastro.pagamentos" [dadosSimplificados]="true">
          </usucampeao-pagamentos-listagem>

          <ng-template #semPagamentos>
            <div class="boletos__sem-pagamento">
              <ion-text class="ion-text-center">
                Nenhum boleto disponível para este cadastro.
              </ion-text>
            </div>
          </ng-template>
        </div>
      </usucampeao-accordion>
    </div>
  </section>
</ion-content>
