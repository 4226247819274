import { IsEnum, IsNotEmpty, IsNumber, IsString, Length, Min, ValidateIf } from 'class-validator';

export enum CadastroCancelamentoMotivo {
  CADASTRADO_POR_ENGANO = 'CADASTRADO_POR_ENGANO',
  CADASTRO_DUPLICADO = 'CADASTRO_DUPLICADO',
  DESISTENCIA = 'DESISTENCIA',
  OUTRO = 'OUTRO',
}

export class CadastroCancelarProprietarioDto {
  @IsString()
  @IsNotEmpty()
  cancelamentoObservacoes: string;
}

export class CadastroCancelarBackofficeDto extends CadastroCancelarProprietarioDto {
  @IsEnum(CadastroCancelamentoMotivo)
  @IsNotEmpty()
  cancelamentoMotivo: CadastroCancelamentoMotivo;

  @IsString()
  @IsNotEmpty()
  @ValidateIf(data => data.cancelamentoMotivo === CadastroCancelamentoMotivo.OUTRO)
  cancelamentoMotivoOutro?: string;
}

export class CadastroDistratoDto {
  @IsEnum(CadastroCancelamentoMotivo)
  @IsNotEmpty()
  distratoMotivo: CadastroCancelamentoMotivo;

  @IsString()
  @Length(5, 50)
  @IsNotEmpty()
  distratoObservacoes: string;

  @IsString()
  @IsNotEmpty()
  @ValidateIf(data => data.distratoMotivo === CadastroCancelamentoMotivo.OUTRO)
  distratoMotivoOutro?: string;

  @IsNumber()
  @Min(0)
  @IsNotEmpty()
  distratoMulta: number;

  @IsNumber()
  @Min(0)
  @IsNotEmpty()
  distratoReembolso: number;
}
