import { Pipe, PipeTransform } from '@angular/core';
import { CadastroContratoAssinaturaTipoEnvioLink } from '@usucampeao/interfaces';
import { PROJETO_STATUS_EM_ANDAMENTO, ProjetoSimplificadoDto, ProjetoStatus, RegistrationStatus } from '@usucampeao/lib-reurb-simplificado';
import { format } from 'date-fns';
import { CadastroFichaDto } from '../../domain';

@Pipe({
  name: 'cadastroFichaStatusDescricao'
})
export class CadastroFichaStatusDescricaoPipe implements PipeTransform {
  transform(value: RegistrationStatus, cadastro?: CadastroFichaDto, projeto?: ProjetoSimplificadoDto): string {
    switch (value) {
      case RegistrationStatus.INCOMPLETE:
        return 'Finalize o preenchimento do cadastro para prosseguir com o processo';

      case RegistrationStatus.CHANGES_REQUESTED:
        return 'Realize as alterações solicitadas para continuar com seu processo';

      case RegistrationStatus.READY_TO_SUBMIT:
        return 'Se não tiver mais alterações, envie-o para análise para continuar seu processo';

      case RegistrationStatus.PRONTO_PARA_FECHAR_CONTRATO:
        return 'O cadastro está pronto para fechar contrato.';

      case RegistrationStatus.AGUARDANDO_ASSINATURA_CONTRATO:
        if (cadastro?.contratoLinkAssinadoPelaUsucampeao) {
          return 'O contrato foi assinado pela central e está aguardando a assinatura do proprietário';
        }

        if (cadastro?.contratoLinkAssinadoPeloProprietario) {
          return 'O contrato foi assinado pelo proprietário e está aguardando a assinatura da UsuCampeão';
        }

        if (cadastro?.contratoLinkContatoEnviado) {
          const tipoContato = cadastro.contratoLinkTipoEnvio === CadastroContratoAssinaturaTipoEnvioLink.EMAIL ? 'e-mail' : 'número';
          return `
          O link para assinatura de contrato foi enviado para o ${tipoContato} "${cadastro.contratoLinkContatoEnviado}" e é
          válido até o dia ${format(new Date(cadastro.contratoDataExpiracao), 'dd/MM/yyyy')}.
         `;
        }

        return 'Finalize um dos processos de aceite de contrato.';

      case RegistrationStatus.CONTRATO_FECHADO:
        return 'O contrato desse cadastro já está fechado. Envie os documentos faltantes para enviá-lo para análise.';

      case RegistrationStatus.IN_ANALYSIS:
        return 'Aguarde a análise do cadastro para prosseguir com o processo';

      case RegistrationStatus.DISTRATO:
      case RegistrationStatus.CANCELED:
        return '';


      default:
        if (PROJETO_STATUS_EM_ANDAMENTO.includes(projeto?.status)) {
          return this.definirDescricaoPeloStatusDoProjeto(projeto);
        }

        return 'Seu cadastro foi validado, agora aguarde a emissão do título do seu imóvel';
    }
  }

  private definirDescricaoPeloStatusDoProjeto(projeto: ProjetoSimplificadoDto): string {
    switch (projeto.status) {
      case ProjetoStatus.INSTAURACAO_NUCLEO:
        return 'Os documentos necessários para que se inicie oficialmente o processo de regularização fundiária na prefeitura estão sendo elaborados e logo serão protocolados.';

      case ProjetoStatus.RELATORIO_TECNICO_E_LEPAC:
        return 'Está sendo elaborado o relatório técnico da infraestrutura da sua área e o desenho da LEPAC, contendo as informações topográficas da sua área.';

      case ProjetoStatus.CADASTRO_SOCIAL_E_PERIMETRO_FINAL:
        return `
          Está em andamento o cadastro social dos moradores da sua área para formalizar os dados dos moradores que serão enviados à prefeitura e
          cartório. <b>Fique de olho nos nossos canais oficiais de comunicação e esteja presente na data anunciada.</b>
        `;

      case ProjetoStatus.PROJETO_URBANISTICO:
        return `
          Está sendo elaborado o Projeto Urbanístico para regularização do seu núcleo.
          <br>
          <br>
          Logo será montada a primeira listagem de beneficiários que terão a matrícula do seu loteamento emitida. Certifique-se de que sua
          documentação está completa e que todos os seus pagamentos estejam em dia   para ser incluído nesta listagem.
        `;

      case ProjetoStatus.PROJETO_ENVIADO_A_PREFEITURA:
        return 'O projeto de regularização foi desenvolvido e enviado para a análise da prefeitura. Aguarde a aprovação e publicação no diário oficial.';

      case ProjetoStatus.ATENDENDO_DEVOLUTIVAS_DA_PREFEITURA:
        return `
          Recebemos as devolutivas da prefeitura sobre o projeto de regularização e estamos trabalhando para atender suas solicitações.
          <br>
          <br>
          Não se preocupe, esta é uma etapa habitual do processo de regularização e indica o andamento saudável dos procedimentos.
        `;

      case ProjetoStatus.APROVADO_PELA_PREFEITURA:
        return `A regularização do seu imóvel foi publicada em <b>${format(new Date(projeto.publicadoDiarioOficialData), 'dd/MM')}</b>. Aguarde o registro em cartório.`;

      case ProjetoStatus.REGISTRADO_EM_CARTORIO:
        return `O título do seu imóvel foi registrado em <b>${format(new Date(projeto.cartorioRegistroData), 'dd/MM')}</b>. Aguarde o agendamento da entrega.`;

      case ProjetoStatus.ATENDENDO_DEVOLUTIVAS_DO_CARTORIO:
        return `
          Recebemos as devolutivas do cartório sobre o projeto de regularização e estamos trabalhando para atender suas solicitações.
          <br>
          <br>
          Não se preocupe, esta é uma etapa habitual do processo de regularização e indica o andamento saudável dos procedimentos.
        `;

      case ProjetoStatus.TITULO_EMITIDO:
        return `
          Venha receber o título do seu imóvel em evento com a prefeitura. O evento de entrega será realizado no seguinte endereço:
          <br>
          <br>
          Local: ${projeto?.tituloEntregaLocalizacao?.logradouro}, ${projeto?.tituloEntregaLocalizacao?.numero} ${projeto?.tituloEntregaLocalizacao?.complemento}
          <br>
          Data: ${format(new Date(projeto.tituloEntregaData), 'dd/MM/yyyy HH:mm')}
        `;

      case ProjetoStatus.TITULO_ENTREGUE:
        return `
          Os títulos dos imóveis já foram entregues. Se você ainda não recebeu o seu título, entre em contato com a nossa central de atendimento.
          <br>
          <br>
          Certifique-se de estar com todos os pagamentos e documentação em dia.
        `;
    }
  }
}
