import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { MaskPipe, NgxMaskModule } from 'ngx-mask';
import { ModalEditarInfosContatoModule } from './modal-editar-infos-contato/modal-editar-infos-contato.module';
import { ModalSelecaoTipoEnvioLinkComponent } from './modal-selecao-tipo-envio-link.component';

@NgModule({
  declarations: [
    ModalSelecaoTipoEnvioLinkComponent,
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,
    ReactiveFormsModule,

    // components
    ModalEditarInfosContatoModule,

    // external
    NgxMaskModule.forRoot(),
  ],
  providers: [MaskPipe],
  exports: [ModalSelecaoTipoEnvioLinkComponent],
})
export class ModalSelecaoTipoEnvioLinkModule { }
