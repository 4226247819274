import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UF } from '@usucampeao/interfaces';
import {
  GetUpdateOwnerDocumentsDto,
  OwnerFillInStatusDto,
  StatusPreenchimento
} from '@usucampeao/lib-reurb-simplificado';
import { AbstractForm } from '@usucampeao/utils-frontend';
import { validateCPF } from '../../../../../components';

@Component({
  selector: 'usucampeao-proprietarios-dados-documento-form',
  templateUrl: './proprietarios-dados-documento-form.component.html',
  styleUrls: ['./proprietarios-dados-documento-form.component.scss']
})
export class ProprietariosDadosDocumentoFormComponent extends AbstractForm {
  @Input() set dadosDocumento(documentosPessoais: GetUpdateOwnerDocumentsDto) {
    this.possuiCPF = !!documentosPessoais?.cpf;
    this.preencherForm(documentosPessoais);
  }
  @Input() set status(status: OwnerFillInStatusDto) {
    this.statusPreenchimento = status?.documentsData || StatusPreenchimento.PENDENTE;
  }
  @Input() formDesabilitado = false;
  @Input() accordionEstaAberto = false;

  @Output() aoSubmeter = new EventEmitter<GetUpdateOwnerDocumentsDto>();

  public possuiCPF: boolean;
  public statusPreenchimento: StatusPreenchimento;
  public readonly estados = Object.values(UF);
  public readonly dataMaximaRg = new Date();

  constructor(
    private fb: FormBuilder
  ) {
    super();
    this.form = this.fb.group({
      cpf: [null, [Validators.required, validateCPF]],
      rg: this.fb.group({
        number: [null, Validators.required],
        issuer: [null, Validators.required],
        state: [null, Validators.required],
        issueDate: [null, Validators.required]
      })
    });
  }

  private preencherForm(dadosDocumento: GetUpdateOwnerDocumentsDto): void {
    this.form.patchValue({
      cpf: dadosDocumento?.cpf || null,
      rg: {
        number: dadosDocumento?.rg?.number || null,
        issuer: dadosDocumento?.rg?.issuer || null,
        state: dadosDocumento?.rg?.state || null,
        issueDate: dadosDocumento?.rg?.issueDate || null
      }
    });
  }

  public submit(): void {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }

    this.atualizarDadosDocumento();
  }

  private atualizarDadosDocumento(): void {
    const dadosDocumento: GetUpdateOwnerDocumentsDto = Object.assign({}, this.form.value);
    this.aoSubmeter.emit(dadosDocumento);
    this.alterarAberturaAccordion(false);
  }

  public alterarAberturaAccordion(estaAberto: boolean): void {
    this.accordionEstaAberto = estaAberto;
  }
}
