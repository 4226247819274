import { IsEnum, IsNotEmpty, IsNumberString, IsOptional, IsString, Length } from 'class-validator';
import { GatewayPagamento } from '../pagamento';
import { ProjetoModalidadeType } from './projeto-modalidade.enum';

export type ProjetoEditarFormDataConstructorProps = {
  nome: string;
  descricao?: string;
  cep: string;
  rua: string;
  numero?: string;
  complemento?: string;
  bairro?: string;
  modalidade?: ProjetoModalidadeType;
  averbacao?: boolean;
  omieProjetoId?: string;
  gatewayPagamento: GatewayPagamento;
}

export class ProjetoEditarFormDataDto {
  @IsString()
  @IsNotEmpty()
  nome: string;

  @IsString()
  @IsOptional()
  descricao?: string;

  @IsNumberString()
  @Length(8, 8)
  cep: string;

  @IsString()
  @IsNotEmpty()
  rua: string;

  @IsNumberString()
  @IsOptional()
  numero?: string;

  @IsString()
  @IsOptional()
  complemento?: string;

  @IsString()
  @IsOptional()
  bairro?: string;

  @IsString()
  @IsOptional()
  modalidade?: ProjetoModalidadeType;

  @IsString()
  @IsOptional()
  averbacao?: string;

  @IsString()
  @IsOptional()
  omieProjetoId?: string;

  @IsEnum(GatewayPagamento)
  @IsOptional()
  gatewayPagamento: GatewayPagamento;

  constructor(projetoEditarFormData?: ProjetoEditarFormDataDto) {
    if (projetoEditarFormData) {
      Object.keys(projetoEditarFormData).forEach(key => this[key] = projetoEditarFormData[key]);
    }
  }

  static create(props: ProjetoEditarFormDataConstructorProps): ProjetoEditarFormDataDto {
    return new ProjetoEditarFormDataDto({
      nome: props.nome,
      descricao: props.descricao,
      cep: props.cep,
      rua: props.rua,
      numero: props.numero,
      complemento: props.complemento,
      bairro: props.bairro,
      modalidade: props.modalidade,
      averbacao: props.averbacao ? 'true' : 'false',
      omieProjetoId: props.omieProjetoId,
      gatewayPagamento: props.gatewayPagamento,
    });
  }
}
