<usucampeao-accordion titulo="Contato" [estaAberto]="accordionEstaAberto"
  (aoAlterarAberturaAccordion)="alterarAberturaAccordion($event)">
  <usucampeao-status-preenchimento extras [status]="statusPreenchimento"></usucampeao-status-preenchimento>

  <form [formGroup]="form">
    <usucampeao-input label="Email" controlName="email" type="email" inputmode="email" [readonly]="formDesabilitado"
      [readonly]="formDesabilitado">
      <span usu-error>{{ gerarMensagemErro('email') }}</span>
    </usucampeao-input>

    <ng-container formGroupName="phones">
      <usucampeao-input label="Telefone celular*" controlName="cellphone" type="tel" inputmode="numeric"
        mask="(00)00000-0000" [readonly]="formDesabilitado" [readonly]="formDesabilitado">
        <span usu-error>{{ gerarMensagemErro('phones', 'Celular', 'cellphone') }}</span>
      </usucampeao-input>

      <usucampeao-input label="Telefone residencial" controlName="home" type="tel" inputmode="numeric"
        mask="(00)00000-0000" [readonly]="formDesabilitado" [readonly]="formDesabilitado">
        <span usu-error>{{ gerarMensagemErro('phones', 'Telefone residencial', 'home') }}</span>
      </usucampeao-input>

      <usucampeao-input label="Telefone Recado" controlName="message" type="tel" inputmode="numeric"
        mask="(00)00000-0000" [readonly]="formDesabilitado" [readonly]="formDesabilitado">
        <span usu-error>{{ gerarMensagemErro('phones', 'Telefone de recado', 'message') }}</span>
      </usucampeao-input>
    </ng-container>

    <usucampeao-input label="Nome do contato para recado" controlName="contactName" [readonly]="formDesabilitado"
      [readonly]="formDesabilitado">
      <span usu-error>{{ gerarMensagemErro('contactName') }}</span>
    </usucampeao-input>
    <ion-button mode="md" (click)="submit()">Salvar e continuar</ion-button>
  </form>
</usucampeao-accordion>
