import { UsuarioBackofficeTipo } from '../usuario-backoffice';
import { CidadeUsuarioStatus } from './cidade-usuario-status.enum';

export class CidadeUsuarioListDto {
  usuarioId: number;
  nome: string;
  telefone: string;
  email: string;
  tipo: UsuarioBackofficeTipo;
  cidade: string;
  cidadeId: number;
  status: CidadeUsuarioStatus;

  constructor(
    usuarioId: number,
    nome: string,
    telefone: string,
    email: string,
    tipo: UsuarioBackofficeTipo,
    cidade: string,
    cidadeId: number,
    status: CidadeUsuarioStatus
  ) {
    this.usuarioId = usuarioId;
    this.nome = nome;
    this.telefone = telefone;
    this.email = email;
    this.tipo = tipo;
    this.cidade = cidade;
    this.cidadeId = cidadeId;
    this.status = status;
  }
}
