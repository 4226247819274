import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { AltertaAlteracoesNecessariasModule } from '../../components';
import { CadastroStatusModule } from '../../components/cadastro-ficha-status/cadastro-status.module';
import { HeaderModule } from '../../components/header/header.module';
import { MapaModule } from '../../components/mapa/mapa.module';
import { ProjetosMapaPage } from './projetos-mapa.page';

@NgModule({
  declarations: [
    ProjetosMapaPage,
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,

    // components
    AltertaAlteracoesNecessariasModule,
    CadastroStatusModule,
    HeaderModule,
    MapaModule,
  ],
  exports: [
    ProjetosMapaPage,
  ]
})
export class ProjetosMapaPageModule { }
