<div class="pagamento-i pagamento-i__flex ion-padding ion-margin-bottom">
  <ion-badge mode="md" class="pagamento-i__badge" [color]="badgeColor" mode="md">
    <ion-icon name="barcode-outline"></ion-icon>
  </ion-badge>

  <div class="pagamento-i__content ion-margin-start">
    <ion-text>
      <h2 class="font-text ion-no-margin">
        {{ pagamento.parcela === 0 ? 'Entrada' : 'Parcela ' + pagamento.parcela }}
      </h2>
    </ion-text>

    <ion-text>
      <p class="description-text ion-no-margin">
        {{pagamento.valorDevido | currency:'BRL' || '' }}
      </p>

      <p class="description-text ion-no-margin">
        Vencimento em: {{pagamento.dataVencimento| date: 'dd/MM/yyyy'||'--' }}
      </p>
    </ion-text>
    <ion-chip *ngIf="mostrarBadgeStatus" class="pagamento-i__chip" [class.pagamento-i__chip--atrasado]="pagamentoAtrasado">
      <ion-label>{{ pagamentoAtrasado ? 'Em atraso' : 'Prox. Vencimento' }}</ion-label>
    </ion-chip>
  </div>

  <ion-button mode="md" size="small" *ngIf="pagamento.status === pagamentoStatus.PROGRAMADO">Pagar</ion-button>
</div>
