import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AlteracaoDto,
  DocumentFileDto,
  DocumentFileType,
  DOCUMENTOS_IDENTIDADE,
  GetUpdateOwnerContactDto,
  GetUpdateOwnerDocumentsDto,
  MaritalStatus,
  OwnerDocument,
  OwnerFillInStatusDto,
  ProprietarioDadosPessoaisDto,
  RegistrationStatus,
  STATUS_DOCUMENTOS_ENVIADOS,
  StatusPreenchimento
} from '@usucampeao/lib-reurb-simplificado';
import { Arquivo } from '../../../../components/upload-foto/upload-foto.component';
import { ProprietarioStateDto } from '../../../../domain';
import { NavigationService } from '../../../../services';
import { DocumentoService } from '../../../../services/documento.service';

@Component({
  selector: 'usucampeao-proprietarios-editar',
  templateUrl: './proprietarios-editar.component.html',
  styleUrls: ['./proprietarios-editar.component.scss']
})
export class ProprietariosEditarComponent {
  @Input() set proprietario(proprietario: ProprietarioStateDto) {
    this.dadosPessoais = ProprietarioDadosPessoaisDto.from(proprietario);
    this.dadosDocumento = GetUpdateOwnerDocumentsDto.from(proprietario);
    this.dadosContato = GetUpdateOwnerContactDto.from(proprietario);
    this.proprietarioStatus = proprietario?.fillInStatus;
    this.configurarDocumentoEstadoCivil(proprietario);
  }
  @Input() set documentos(documentos: DocumentFileDto[]) {
    this.documentoIdentidade = documentos?.find(documento => DOCUMENTOS_IDENTIDADE.includes(documento.type as OwnerDocument));
    this.documentoEstadoCivil = documentos?.find(documento => this.documentoEstadoCivilSelecionado === documento.type);
    this.configurarStatusDocumentos();
    this.configurarAccordionAberto();
  }
  @Input() alteracoes: AlteracaoDto[];
  @Input() formDesabilitado = false;

  @Output() aoAlterarDadosPessoais = new EventEmitter<ProprietarioDadosPessoaisDto>();
  @Output() aoAlterarDadosDocumento = new EventEmitter<GetUpdateOwnerDocumentsDto>();
  @Output() aoAlterarDadosContato = new EventEmitter<GetUpdateOwnerContactDto>();

  public readonly rotaAnterior = '../../';
  public readonly documentosIdentidade = [
    OwnerDocument.CNH,
    OwnerDocument.RG,
  ];

  public readonly cadastroAlteracoesNecessarias = RegistrationStatus.CHANGES_REQUESTED;
  public proprietarioStatus: OwnerFillInStatusDto;
  public dadosPessoais: ProprietarioDadosPessoaisDto;
  public accordionDadosPessoaisEstaAberto = false;

  public dadosDocumento: GetUpdateOwnerDocumentsDto;
  public accordionDadosDocumentosEstaAberto = false;

  public dadosContato: GetUpdateOwnerContactDto;
  public accordionDadosContatoEstaAberto = false;

  public documentoIdentidade: DocumentFileDto;
  public statusDocumentoIdentidade: StatusPreenchimento;
  public documentoIdentidadeSelecionado: OwnerDocument;
  public accordionDocumentoIdentidadeEstaAberto = false;

  public documentoEstadoCivil: DocumentFileDto;
  public statusDocumentoEstadoCivil: StatusPreenchimento;
  public documentoEstadoCivilSelecionado: OwnerDocument;
  public accordionDocumentoEstadoCivilEstaAberto = false;

  constructor(
    private readonly documentoService: DocumentoService,
    private readonly navigationService: NavigationService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
  ) { }

  private configurarAccordionAberto(): void {
    if (this.proprietarioStatus?.personalData === StatusPreenchimento.PENDENTE) {
      this.accordionDadosPessoaisEstaAberto = true;
      this.accordionDadosContatoEstaAberto = false;
      this.accordionDadosDocumentosEstaAberto = false;
      this.accordionDocumentoIdentidadeEstaAberto = false;
      this.accordionDocumentoEstadoCivilEstaAberto = false;
    } else if (this.proprietarioStatus?.documentsData === StatusPreenchimento.PENDENTE) {
      this.accordionDadosDocumentosEstaAberto = true;
      this.accordionDadosPessoaisEstaAberto = false;
      this.accordionDadosContatoEstaAberto = false;
      this.accordionDocumentoIdentidadeEstaAberto = false;
      this.accordionDocumentoEstadoCivilEstaAberto = false;
    } else if (this.proprietarioStatus?.contactData === StatusPreenchimento.PENDENTE) {
      this.accordionDadosContatoEstaAberto = true;
      this.accordionDadosPessoaisEstaAberto = false;
      this.accordionDadosDocumentosEstaAberto = false;
      this.accordionDocumentoIdentidadeEstaAberto = false;
      this.accordionDocumentoEstadoCivilEstaAberto = false;
    } else if (this.statusDocumentoIdentidade === StatusPreenchimento.PENDENTE) {
      this.accordionDocumentoIdentidadeEstaAberto = true;
      this.accordionDadosPessoaisEstaAberto = false;
      this.accordionDadosContatoEstaAberto = false;
      this.accordionDadosDocumentosEstaAberto = false;
      this.accordionDocumentoEstadoCivilEstaAberto = false;
    } else if (this.statusDocumentoEstadoCivil === StatusPreenchimento.PENDENTE) {
      this.accordionDocumentoEstadoCivilEstaAberto = true;
      this.accordionDadosPessoaisEstaAberto = false;
      this.accordionDadosContatoEstaAberto = false;
      this.accordionDadosDocumentosEstaAberto = false;
      this.accordionDocumentoIdentidadeEstaAberto = false;
    } else {
      this.accordionDadosPessoaisEstaAberto = false;
      this.accordionDadosContatoEstaAberto = false;
      this.accordionDadosDocumentosEstaAberto = false;
      this.accordionDocumentoIdentidadeEstaAberto = false;
      this.accordionDocumentoEstadoCivilEstaAberto = false;
    }
  }

  private configurarStatusDocumentos(): void {
    if (this.proprietarioStatus?.documents === StatusPreenchimento.ENVIADO) {
      this.statusDocumentoEstadoCivil = StatusPreenchimento.ENVIADO;
      this.statusDocumentoIdentidade = StatusPreenchimento.ENVIADO;
    } else {
      const documentoIdentidadeEnviado = this.documentoIdentidade && STATUS_DOCUMENTOS_ENVIADOS.includes(this.documentoIdentidade.status);
      this.statusDocumentoIdentidade = documentoIdentidadeEnviado ? StatusPreenchimento.ENVIADO : StatusPreenchimento.PENDENTE;
      const documentoEstadoCivilEnviado = this.documentoEstadoCivil && STATUS_DOCUMENTOS_ENVIADOS.includes(this.documentoEstadoCivil.status);
      this.statusDocumentoEstadoCivil = documentoEstadoCivilEnviado ? StatusPreenchimento.ENVIADO : StatusPreenchimento.PENDENTE;
    }
  }

  private configurarDocumentoEstadoCivil(proprietario: ProprietarioStateDto): void {
    if (proprietario?.maritalStatus === MaritalStatus.SINGLE) {
      this.documentoEstadoCivilSelecionado = OwnerDocument.BIRTH_CERTIFICATE;
      return;
    }

    this.documentoEstadoCivilSelecionado = OwnerDocument.MARRIAGE_CERTIFICATE;
  }

  public alterarDadosPessoais(dadosPessoais: ProprietarioDadosPessoaisDto): void {
    this.aoAlterarDadosPessoais.emit(dadosPessoais);
    this.accordionDadosDocumentosEstaAberto = true;
  }

  public alterarDadosDocumento(dadosDocumento: GetUpdateOwnerDocumentsDto): void {
    this.aoAlterarDadosDocumento.emit(dadosDocumento);
    this.accordionDadosContatoEstaAberto = true;
  }

  public alterarDadosContato(dadosContato: GetUpdateOwnerContactDto): void {
    this.aoAlterarDadosContato.emit(dadosContato);
    this.accordionDocumentoIdentidadeEstaAberto = true;
  }

  public selecionarDocumentoIdentidade(documentoSelecionado: DocumentFileType): void {
    this.documentoIdentidadeSelecionado = documentoSelecionado as OwnerDocument;
  }

  public irParaUploadDeDocumento(arquivos: Arquivo[], tipoDocumento: OwnerDocument): void {
    const arquivo = arquivos[0];
    if (!arquivo) {
      return;
    }

    this.documentoService.salvarDocumentoStorage(arquivo);
    this.router.navigate(
      ['../envio-documentos/upload'],
      {
        queryParams: {
          tipo: tipoDocumento,
        },
        relativeTo: this.route,
      }
    );
  }

  public voltar(): void {
    this.navigationService.voltar(this.rotaAnterior);
  }
}
