<section class="contrato">
  <div class="contrato__content ion-padding">
    <usucampeao-header [backRoute]="rotaAnterior" title="Contratação"></usucampeao-header>

    <ion-text>
      <h2 class="title title--medium">Regularize sua casa</h2>
      <p class="font-text font-text--medium">
        Além do seu terreno regularizado, o seu título de propriedade pode incluir também a sua casa, através da
        averbação!
      </p>
    </ion-text>

    <ul>
      <li class="font-text font-text--medium">
        <ion-icon name="checkmark-circle" color="success"></ion-icon>
        <span>Imóvel regularizado</span>
      </li>
      <li class="font-text font-text--medium">
        <ion-icon name="checkmark-circle" color="success"></ion-icon>
        <span>Facilidade para transferência de posse do imóvel como herança</span>
      </li>
      <li class="font-text font-text--medium">
        <ion-icon name="checkmark-circle" color="success"></ion-icon>
        <span>Facilidade para venda</span>
      </li>
      <li class="font-text font-text--medium">
        <ion-icon name="checkmark-circle" color="success"></ion-icon>
        <span>Valorização financeira do imóvel construído</span>
      </li>
    </ul>

    <form [formGroup]="form">
      <ion-radio-group mode="md" formControlName="averbacao">
        <ion-item class="contrato__radio-item contrato__radio-item--first ion-no-padding ion-margin-bottom" lines="none"
          *ngIf="possuiAverbacao">
          <div class="contrato__mais-escolhido">
            <span>Mais escolhido</span>
          </div>
          <div class="contrato__radio-item-content">
            <ion-radio [value]="true"></ion-radio>
            <ion-label class="ion-margin-start">
              Contratar averbação <br>
              <span class="description-text">
                em até {{ formaPagamentoAverbacaoMaiorParcelamento?.parcela }}x de {{
                formaPagamentoAverbacaoMaiorParcelamento?.valorParcela | currency:
                'BRL' }}
              </span>
            </ion-label>
          </div>
        </ion-item>

        <ion-item class="contrato__radio-item ion-no-padding" lines="none" text-wrap>
          <ion-radio [value]="false"></ion-radio>
          <ion-label class="ion-margin-start ion-text-wrap">Agora não, regularizar somente o terreno</ion-label>
        </ion-item>
      </ion-radio-group>
    </form>
  </div>

  <div class="contrato__footer ion-padding">
    <ion-text color="light-gray">
      <p class="font-text font-text--medium ion-no-margin" *ngIf="form.get('averbacao').value; else semAverbacao">
        {{ formaPagamentoAverbacaoMaiorParcelamento?.parcela }}x de
        <b>{{ formaPagamentoAverbacaoMaiorParcelamento?.valorParcela | currency: 'BRL' }}</b> <br>
        <span class="font-text--small  contrato__footer--p">ou 1x de {{ formaPagamentoAverbacaoAVista?.valorAto |
          currency: 'BRL' }}</span>
      </p>

      <ng-template #semAverbacao>
        <p class="font-text font-text--medium ion-no-margin contrato__footer--p">
          {{ reurbGratuito ? 'Gratuito' : 'Sem contratar serviços opcionais' }}
        </p>
      </ng-template>
    </ion-text>

    <ion-button mode="md" class="ion-margin-top" (click)="submit()">Continuar</ion-button>
  </div>
</section>
