<ng-container [ngSwitch]="tipoContrato">
  <usu-procuracao-reurb-averbacao *ngSwitchCase="tipoContract.PROCURACAO_REURB_AVERBACAO" [proprietario]="proprietario"
    [conjuge]="conjuge" [imovel]="imovel" [cadastro]="cadastro">
  </usu-procuracao-reurb-averbacao>

  <usu-morador-averbacao *ngSwitchCase="tipoContract.MORADOR_AVERBACAO" [proprietario]="proprietario"
    [conjuge]="conjuge" [imovel]="imovel" [cadastro]="cadastro" [isPrint]="isPrint"></usu-morador-averbacao>

  <usu-morador-usucampeao-2021 *ngSwitchCase="tipoContract.MORADOR_USUCAMPEAO_2021" [proprietario]="proprietario"
    [conjuge]="conjuge" [imovel]="imovel" [cadastro]="cadastro" [isPrint]="isPrint"></usu-morador-usucampeao-2021>

  <usu-morador-usucampeao-reurb-averbacao *ngSwitchCase="tipoContract.MORADOR_USUCAMPEAO_REURB_AVERBACAO"
    [proprietario]="proprietario" [conjuge]="conjuge" [imovel]="imovel" [cadastro]="cadastro" [isPrint]="isPrint">
  </usu-morador-usucampeao-reurb-averbacao>

  <usu-procuracao-averbacao *ngSwitchCase="tipoContract.PROCURACAO_AVERBACAO" [isPrint]="isPrint" [proprietario]="proprietario"
    [conjuge]="conjuge" [imovel]="imovel" [cadastro]="cadastro"></usu-procuracao-averbacao>

  <usu-declaracao-de-autonomo-e-renda *ngSwitchCase="tipoContract.DECLARACAO_DE_AUTONOMO_DE_RENDA"
    [proprietario]="proprietario" [conjuge]="conjuge" [imovel]="imovel" [cadastro]="cadastro">
  </usu-declaracao-de-autonomo-e-renda>

  <usu-questionario-juridico *ngSwitchCase="tipoContract.QUESTIONARIO_JURIDICO" [proprietario]="proprietario"
    [conjuge]="conjuge" [imovel]="imovel" [cadastro]="cadastro">
  </usu-questionario-juridico>

  <usu-declaracao-de-domicilio *ngSwitchCase="tipoContract.DECLARACAO_DE_DOMICILIO" [proprietario]="proprietario"
    [conjuge]="conjuge" [imovel]="imovel" [cadastro]="cadastro">
  </usu-declaracao-de-domicilio>

  <usu-declaracao-de-vizinhos *ngSwitchCase="tipoContract.DECLARACAO_DE_VIZINHOS" [proprietario]="proprietario"
    [conjuge]="conjuge" [imovel]="imovel" [cadastro]="cadastro">
  </usu-declaracao-de-vizinhos>
</ng-container>
