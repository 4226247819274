import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContratoAssinaturaPage } from './contrato-assinatura.page';

const routes: Routes = [
  {
    path: '',
    component: ContratoAssinaturaPage,
  },
  {
    path: 'contrato-fisico',
    loadChildren: () =>
      import('./contrato-assinatura-tipo-fisico/contrato-assinatura-tipo-fisico-upload/contrato-assinatura-tipo-fisico-upload.module').then((m) => m.ContratoAssinaturaTipoFisicoUploadModule),
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ContratoAssinaturaPageRoutingModule { }
