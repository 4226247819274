import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CreateUserDto } from '@usucampeao/lib-reurb-simplificado';
import { LoadingService, NavigationService, ToastService } from '@usucampeao/ui-mobile';
import { catchError, finalize, switchMap, take, tap } from 'rxjs/operators';
import { AuthQuery } from '../auth/state/auth.query';
import { AuthService } from '../auth/state/auth.service';

@Component({
  selector: 'usucampeao-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss']
})
export class PerfilComponent {
  public usuario$ = this.authQuery.dadosUsuarioCriacao$;
  public estaEditavel = false;
  public foto$ = this.authQuery.foto$;

  constructor(
    private authQuery: AuthQuery,
    private authService: AuthService,
    private loadingService: LoadingService,
    private navigationService: NavigationService,
    private router: Router,
    private toastService: ToastService,
  ) { }

  public habilitarEditarUsuario() {
    this.estaEditavel = true;
  }

  public desabilitarEditarUsuario(): void {
    this.estaEditavel = false;
  }

  public async onSubmit(usuarioAtualizado: CreateUserDto): Promise<void> {
    if (!this.estaEditavel) {
      return;
    }

    await this.loadingService.createLoader();
    this.authService.atualizarUsuario(usuarioAtualizado)
      .pipe(
        tap(() => this.estaEditavel = false),
        switchMap(() => this.toastService.success('Dados editados com sucesso.')),
        catchError(() => this.toastService.error('Erro ao atualizar dados da conta. Por favor tente novamente.')),
        take(1),
        finalize(() => this.loadingService.dismiss()),
      )
      .subscribe();
  }

  public voltar(): void {
    this.navigationService.voltar();
  }

  public irParaEdicaoDeFoto(): void {
    this.router.navigate(['/perfil/trocar-foto']);
  }
}
