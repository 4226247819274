import { Expose, plainToInstance } from 'class-transformer';
import { IsEnum, IsNotEmpty, IsNumber, IsOptional, IsPositive } from 'class-validator';
import { ProjetoComissaoTipo } from './projeto-comissao-tipo.enum';
import { ProjetoDto } from './projeto.dto';

export class ProjetoComissaoTimeConfigurarDto {
  @Expose()
  @IsEnum(ProjetoComissaoTipo)
  @IsNotEmpty()
  comissaoTipo: ProjetoComissaoTipo;

  @Expose()
  @IsNumber()
  @IsPositive()
  @IsOptional()
  comissaoReurb: number;

  @Expose()
  @IsNumber()
  @IsPositive()
  @IsOptional()
  comissaoReurbAverbacao: number;

  @Expose()
  @IsNumber()
  @IsPositive()
  @IsOptional()
  comissaoAverbacao: number;

  /**
   * Cria uma nova instância de ProjetoComissaoConfigurarDto a partir de dtos
   * @param props DTO de ProjetoComissaoConfigurarDto
   * @returns nova instância de ProjetoComissaoConfigurarDto
   */
  static from(props?: Partial<ProjetoDto>): ProjetoComissaoTimeConfigurarDto {
    return plainToInstance(ProjetoComissaoTimeConfigurarDto, props, { exposeUnsetFields: false, excludeExtraneousValues: true });
  }
}


export const COMISSAO_PORCENTAGEM_MAXIMA_PERMITIDA = 30;
