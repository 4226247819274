export enum StatusRevisao {
  EM_ABERTO = 'EM_ABERTO',
  EM_ANDAMENTO = 'EM_ANDAMENTO',
  CANCELADO = 'CANCELADO',
  APROVADO = 'APROVADO',
  APROVADO_COM_RESSALVAS = 'APROVADO_COM_RESSALVAS',
  REENVIADO_PARA_REVISAO = 'REENVIADO_PARA_REVISAO',
  RESSALVAS_CORRIGIDAS = 'RESSALVAS_CORRIGIDAS',
  REPROVADO = 'REPROVADO',
}

export const STATUS_HABILITADOS_INICIAR_REVISAO = [
  StatusRevisao.EM_ABERTO,
  StatusRevisao.REENVIADO_PARA_REVISAO,
  StatusRevisao.RESSALVAS_CORRIGIDAS,
];

export const STATUS_REVISOES_EM_ABERTO = [...STATUS_HABILITADOS_INICIAR_REVISAO, StatusRevisao.EM_ANDAMENTO];

export const STATUS_REVISOES_VALIDAS = [
  StatusRevisao.EM_ABERTO,
  StatusRevisao.EM_ANDAMENTO,
  StatusRevisao.APROVADO,
  StatusRevisao.APROVADO_COM_RESSALVAS,
  StatusRevisao.REENVIADO_PARA_REVISAO,
  StatusRevisao.RESSALVAS_CORRIGIDAS,
  StatusRevisao.REPROVADO,
];
