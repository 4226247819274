/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GetUpdateOwnerContactDto, ProprietarioEditarEmailEWhatsappDto } from '@usucampeao/lib-reurb-simplificado';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProprietarioService {

  constructor(
    private http: HttpClient,
  ) { }

  /**
   * Atualiza os dados de contato do proprietário.
   * @param cadastroId id do atendimento
   * @param id id do proprietário
   * @param dadosContato dados a serem atualizados
   */
  public atualizarEmailECelular(cadastroId: string, id: string, emailECelular: ProprietarioEditarEmailEWhatsappDto): Observable<void> {
    return this.http.put<void>(`/registrations/${cadastroId}/owners/${id}/email-celular`, emailECelular);
  }

  /**
   * Busca os dados de contato do proprietário e os atualiza na store.
   * @param id id do proprietário
   * @returns dados de contato do proprietário
   */
  public buscarDadosContato(cadastroId: string, id: string): Observable<GetUpdateOwnerContactDto> {
    return this.http.get<GetUpdateOwnerContactDto>(`/registrations/${cadastroId}/owners/${id}/contact`);
  }
}
