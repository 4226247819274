import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { DocumentFileDto, GetUpdateOwnerContactDto, GetUpdateOwnerDocumentsDto, ProprietarioDadosBasicosDto } from '@usucampeao/lib-reurb-simplificado';
import { ProprietarioFichaDto, ProprietarioStateDto } from '@usucampeao/ui-mobile';
import { isEqual } from '@usucampeao/utils';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { ProprietarioState, ProprietariosStore } from './proprietarios.store';

@Injectable({ providedIn: 'root' })
export class ProprietariosQuery extends QueryEntity<ProprietarioState> {

  constructor(protected store: ProprietariosStore) {
    super(store);
  }

  public buscarPorCadastroId(cadastroId: string): Observable<ProprietarioStateDto[]> {
    return this.selectAll({
      filterBy: (state) => state.cadastroId === cadastroId
    });
  }

  public buscarFichaPorCadastroId(cadastroId: string): Observable<ProprietarioFichaDto[]> {
    return this.buscarPorCadastroId(cadastroId)
      .pipe(
        filter(proprietarios => !!proprietarios),
        map(proprietarios => proprietarios.map(proprietario => ProprietarioFichaDto.from(proprietario)))
      );
  }

  public buscarDadosBasicos(id: string): Observable<ProprietarioDadosBasicosDto> {
    return this.selectEntity(id)
      .pipe(
        filter(proprietario => !!proprietario),
        distinctUntilChanged((prev, curr) => isEqual(prev, curr)),
        map(proprietario => {
          const dadosBasicos = ProprietarioDadosBasicosDto.from(proprietario);
          dadosBasicos.main = proprietario.main;
          return dadosBasicos;
        }),
      );
  }

  public buscarDadosContato(id: string): Observable<GetUpdateOwnerContactDto> {
    return this.selectEntity(id)
      .pipe(
        filter(proprietario => !!proprietario),
        distinctUntilChanged((prev, curr) => isEqual(prev, curr)),
        map(proprietario => GetUpdateOwnerContactDto.from(proprietario)),
      );
  }

  public buscarDadosDocumentos(id: string): Observable<GetUpdateOwnerDocumentsDto> {
    return this.selectEntity(id)
      .pipe(
        filter(proprietario => !!proprietario),
        distinctUntilChanged((prev, curr) => isEqual(prev, curr)),
        map(proprietario => GetUpdateOwnerDocumentsDto.from(proprietario)),
      );
  }

  public buscarDocumentos(id: string): Observable<DocumentFileDto[]> {
    return this.selectEntity(id)
      .pipe(
        filter(proprietario => !!proprietario),
        map(proprietario => proprietario.documentos)
      );
  }
}
