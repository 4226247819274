import { Endereco } from '@usucampeao/interfaces';
import { Expose, Type } from 'class-transformer';
import { ProjetoEndereco } from '../projeto';
import { AlteracaoDto } from '../revisao';

export class PayloadNotificacaoDto {
  @Type(() => AlteracaoDto)
  @Expose()
  alteracoes?: AlteracaoDto[];

  @Expose()
  dataVencimento?: Date;

  @Expose()
  valorPagamento?: number;

  @Expose()
  dataVisita?: Date;

  @Expose()
  valorDevido?: number;

  @Expose()
  endereco?: Endereco;

  @Expose()
  parcela?: number;

  @Expose()
  pagamentoId?: string;

  @Expose()
  publicadoDiarioOficialData?: Date;

  @Expose()
  cartorioRegistroData?: Date;

  @Expose()
  tituloEntregaData?: Date;

  @Expose()
  tituloEntregaLocalizacao?: ProjetoEndereco;
}
