<div class="c-assinatura-tipo-fisico">
  <ion-text class="font-text font-text--medium">
    <p>Tire uma foto ou suba o arquivo de imagem do contrato assinado pelo cliente.</p>
  </ion-text>

  <ion-text class="font-text font-text--medium">
    <p>Atenção! Somente será aceito o contrato com assinatura de duas testemunhas.</p>
  </ion-text>

  <ion-text class="text-link" (click)="abrirModalContrato()">
    Visualizar e imprimir contrato
  </ion-text>

  <div class="c-assinatura-tipo-fisico__actions">
    <ion-button class="ion-margin-bottom" mode="md" (click)="goTo('contrato-fisico')">
      Tirar ou subir Foto de contrato
    </ion-button>

    <ion-button id="contrato-alterar-metodo-assinatura" mode="md" fill="outline" (click)="goTo('../selecionar-tipo-assinatura')">
      Alterar método de validação
    </ion-button>
  </div>
</div>
