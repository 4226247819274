import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewWillEnter } from '@ionic/angular';
import { LoadingService, NavigationService, ToastService } from '@usucampeao/ui-mobile';
import { catchError, finalize, switchMap, take, tap } from 'rxjs/operators';
import { CadastroService } from '../../../../state/cadastros.service';

@Component({
  templateUrl: './contrato-assinatura-tipo-fisico-upload.page.html',
  styleUrls: ['./contrato-assinatura-tipo-fisico-upload.page.scss']
})
export class ContratoAssinaturaTipoFisicoUploadPage implements ViewWillEnter {
  public cadastroId: string;
  public arquivos: File[] = [];

  constructor(
    private cadastroService: CadastroService,
    private loadingService: LoadingService,
    private navigationService: NavigationService,
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
  ) { }

  ionViewWillEnter(): void {
    this.route.params
      .pipe(
        tap(params => this.cadastroId = params.cadastroId),
        take(1),
      )
      .subscribe();
  }

  public selecionarArquivos(arquivos: File[]): void {
    this.arquivos = arquivos;
  }

  public async assinarContrato(): Promise<void> {
    if (!this.arquivos || this.arquivos.length === 0) {
      await this.toastService.warning('Faça o upload de pelo menos 1 imagem.').toPromise();
      return;
    }

    await this.loadingService.createLoader();

    this.cadastroService.assinaturaContratoFisico(this.cadastroId, this.arquivos)
      .pipe(
        switchMap(() => this.toastService.success('Contrato assinado com sucesso.')),
        tap(() => {
          this.navigationService.limparHistoricoNavegacao();
          this.router.navigate(['cadastros', this.cadastroId], { replaceUrl: true });
        }),
        catchError(() => this.toastService.error('Erro ao realizar o upload do contrato. Por favor tente novamente.')),
        take(1),
        finalize(async () => await this.loadingService.dismiss()),
      )
      .subscribe();
  }

  public goTo(route: string): void {
    this.router.navigate([route], { relativeTo: this.route });
  }
}
