import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { ProprietariosDocumentosSelecionarTipoModule } from '@usucampeao/ui-mobile';
import { ProprietariosDocumentosSelecionarTipoRoutingModule } from './proprietarios-documentos-selecionar-tipo-routing.module';
import { ProprietariosDocumentosSelecionarTipoPage } from './proprietarios-documentos-selecionar-tipo.page';

@NgModule({
  declarations: [
    ProprietariosDocumentosSelecionarTipoPage
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,

    // route
    ProprietariosDocumentosSelecionarTipoRoutingModule,

    // components
    ProprietariosDocumentosSelecionarTipoModule,
  ]
})
export class ProprietariosDocumentosSelecionarTipoPageModule { }
