import { Component, Input } from '@angular/core';
import { PropertyDto } from '@usucampeao/interfaces';
import { OwnerDto, ProjetoTipo } from '@usucampeao/lib-reurb-simplificado';
import { TipoContract } from '../../enums';

@Component({
  selector: 'usucampeao-contratos-v1',
  templateUrl: './contratos-v1.component.html',
  styleUrls: ['./contratos-v1.component.scss']
})
export class ContratosV1Component {
  @Input() cadastro!: any;
  @Input() proprietario!: Partial<OwnerDto>;
  @Input() conjuge!: Partial<OwnerDto>;
  @Input() imovel!: Partial<PropertyDto>;
  @Input() tipo: TipoContract;
  @Input() isPrint: boolean;

  public get tipoContract(): typeof TipoContract {
    return TipoContract;
  }

  public get tipoContrato(): TipoContract {
    if (this.tipo) {
      return this.tipo;
    }

    switch (this.cadastro?.projetoTipo) {
      case ProjetoTipo.AVERBACAO:
        return TipoContract.MORADOR_AVERBACAO;

      case ProjetoTipo.REURB_TITULATORIO:
      case ProjetoTipo.REURB_TRADICIONAL:
        if (this.cadastro?.averbacao) {
          return TipoContract.MORADOR_USUCAMPEAO_REURB_AVERBACAO;
        };
        return TipoContract.MORADOR_USUCAMPEAO_2021;

      default:
        return TipoContract.MORADOR_USUCAMPEAO_2021;
    }
  }

}
