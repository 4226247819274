import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Attributes, IntersectionObserverHooks } from 'ng-lazyload-image';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable()
export class LazyLoadImageHooks extends IntersectionObserverHooks {

  constructor(private http: HttpClient) {
    super();
  }

  loadImage({ imagePath }: Attributes): string | Observable<string> {
    if (imagePath.includes('base64') || imagePath.includes('http')) {
      return of(imagePath);
    }

    return this.http.get(imagePath, { responseType: 'blob' }).pipe(map(blob => URL.createObjectURL(blob)));
  }
}
