
export class QuadraListarDto {
  id: number;
  label: string;

  constructor(
    label: string,
    id: number,
  ) {
    this.label = label
    this.id = id;
  }
}


export function sortQuadraByLabel(curr: QuadraListarDto, next: QuadraListarDto): number {
  if (curr.label.length > next.label.length) {
    return 1;
  }

  if (curr.label.length < next.label.length) {
    return -1;
  }

  if (curr.label.toLowerCase() > next.label.toLowerCase()) {
    return 1;
  }

  if (curr.label.toLowerCase() < next.label.toLowerCase()) {
    return -1;
  }

  return 0;
}
