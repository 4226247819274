<ion-content class="ion-padding">
  <ion-refresher slot="fixed" (ionRefresh)="carregarDadosDocumentos($event)">
    <ion-refresher-content>
    </ion-refresher-content>
  </ion-refresher>

  <usucampeao-proprietarios-dados-documentos [dadosDocumentos]="dadosDocumentos$ | async" [alteracoes]="alteracoes$ | async"
    [formDesabilitado]="formDesabilitado$ | async"
    (aoSubmeter)="alterarDadosDocumentos($event)"></usucampeao-proprietarios-dados-documentos>
</ion-content>
