import { Expose, plainToInstance, Type } from 'class-transformer';
import { IsNotEmpty, IsOptional, IsString, ValidateNested } from 'class-validator';

import { IsCpf, RemoveSpecialCharacters } from '@usucampeao/utils';

import { OwnerFillInStatusDto } from './owner-fill-in-status.dto';
import { OwnerDto } from './owner.dto';
import { RgDto, RgPartialDto } from './rg.dto';

export class GetUpdateOwnerDocumentsDto {
  @Expose()
  @RemoveSpecialCharacters()
  @IsString()
  @IsCpf()
  @IsNotEmpty()
  cpf: string;

  @Expose()
  @Type(() => RgDto)
  @ValidateNested()
  @IsNotEmpty()
  rg: RgDto;

  fillInStatus: OwnerFillInStatusDto;

  /**
   * Cria uma nova instância de GetUpdateOwnerDocumentsDto a partir de dtos
   * @param props DTO de Owner
   * @returns nova instância de GetUpdateOwnerDocumentsDto
   */
  static from(props?: OwnerDto | Partial<OwnerDto>): GetUpdateOwnerDocumentsDto {
    return plainToInstance(GetUpdateOwnerDocumentsDto, props, { exposeUnsetFields: false, excludeExtraneousValues: true });
  }
}

export class ProprietarioDadosDocumentoPartialDto {
  @Expose()
  @RemoveSpecialCharacters()
  @IsString()
  @IsCpf()
  @IsOptional()
  cpf?: string;

  @Expose()
  @Type(() => RgPartialDto)
  @ValidateNested()
  @IsOptional()
  rg?: RgPartialDto;

  @Expose()
  @IsString()
  @IsNotEmpty()
  proprietarioId: string;
}

