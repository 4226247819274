/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { NgxMaskModule } from 'ngx-mask';
import { ModalSelecaoTipoEnvioLinkModule } from '../../../components';
import { ContratoAssinaturaTipoLinkComponent } from './contrato-assinatura-tipo-link.component';

@NgModule({
  declarations: [
    ContratoAssinaturaTipoLinkComponent,
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,

    // components
    ModalSelecaoTipoEnvioLinkModule,

    // external
    NgxMaskModule.forRoot(),
  ],
  exports: [ContratoAssinaturaTipoLinkComponent]
})
export class ContratoAssinaturaTipoLinkModule { }
