<div class="u-foto-imovel u-foto-imovel__flex-center">
  <div class="u-foto-imovel__container" [class.u-foto-imovel__container__v2]="isV2" *ngIf="!urlImagem"
    (click)="selecionarImagem()">
    <ion-icon class="u-foto-imovel__icon" color="medium" name="camera-outline"></ion-icon>
    <ion-text *ngIf="isV2">
      <span class="font-text font-text--medium font-text--medium-color">*Tirar foto da fachada da casa</span>
    </ion-text>
  </div>

  <div class="u-foto-imovel__flex-center u-foto-imovel__flex-center--column" *ngIf="urlImagem">
    <img class="u-foto-imovel__imagem" [lazyLoad]="urlImagem" [defaultImage]="imagemCarregando" [class.u-foto-imovel__imagem__v2]="isV2"
      [errorImage]="imagemDefault" alt="Foto da fachada do imóvel.">
    <ion-button class="u-foto-imovel__button ion-no-padding" fill="clear" color="secondary"
      (click)="selecionarImagem()">
      Trocar Foto
    </ion-button>
  </div>
</div>
