<section class="p-editar">
  <usucampeao-header title="Proprietário" [backRoute]="rotaAnterior"></usucampeao-header>

  <div class="ion-margin-bottom" *ngIf="alteracoes?.length">
    <usucampeao-cadastro-status [status]="cadastroAlteracoesNecessarias"
      [alteracoes]="alteracoes"></usucampeao-cadastro-status>
  </div>

  <div class="p-editar__item">
    <usucampeao-proprietarios-dados-basicos-form [dadosPessoais]="dadosPessoais"
      [accordionEstaAberto]="accordionDadosPessoaisEstaAberto" [formDesabilitado]="formDesabilitado"
      [status]="proprietarioStatus"
      (aoSubmeter)="alterarDadosPessoais($event)"></usucampeao-proprietarios-dados-basicos-form>
  </div>

  <div class="p-editar__item">
    <usucampeao-proprietarios-dados-documento-form [dadosDocumento]="dadosDocumento"
      [accordionEstaAberto]="accordionDadosDocumentosEstaAberto" [formDesabilitado]="formDesabilitado"
      [status]="proprietarioStatus"
      (aoSubmeter)="alterarDadosDocumento($event)"></usucampeao-proprietarios-dados-documento-form>
  </div>

  <div class="p-editar__item">
    <usucampeao-proprietarios-dados-contato-form [dadosContato]="dadosContato"
      [accordionEstaAberto]="accordionDadosContatoEstaAberto" [formDesabilitado]="formDesabilitado"
      [status]="proprietarioStatus"
      (aoSubmeter)="alterarDadosContato($event)"></usucampeao-proprietarios-dados-contato-form>
  </div>

  <div class="p-editar__item">
    <usucampeao-upload-foto-documento-v2 titulo="Foto de documento de identidade"
      [accordionEstaAberto]="accordionDocumentoIdentidadeEstaAberto" [listaDeDocumentos]="documentosIdentidade"
      [documento]="documentoIdentidade" [statusPreenchimento]="statusDocumentoIdentidade" [readonly]="formDesabilitado"
      (aoSelecionarDocumento)="selecionarDocumentoIdentidade($event)"
      (aoRealizarUploadBase64)="irParaUploadDeDocumento($event, documentoIdentidadeSelecionado)">
      <ion-text class="font-text p-editar__documentos-lista">
        Documentos aceitos (Com nº de CPF)
        <ul>
          <li>RG</li>
          <li>CNH</li>
        </ul>
      </ion-text>
    </usucampeao-upload-foto-documento-v2>
  </div>

  <div class="p-editar__item">
    <usucampeao-upload-foto-documento-v2 titulo="Foto de comprovante de estado civil"
      [accordionEstaAberto]="accordionDocumentoEstadoCivilEstaAberto" [documento]="documentoEstadoCivil"
      [documentoTipo]="documentoEstadoCivilSelecionado" [statusPreenchimento]="statusDocumentoEstadoCivil"
      [readonly]="formDesabilitado"
      (aoRealizarUploadBase64)="irParaUploadDeDocumento($event, documentoEstadoCivilSelecionado)">
    </usucampeao-upload-foto-documento-v2>
  </div>

  <div class="p-editar__actions">
    <ion-button mode="md" fill="outline" (click)="voltar()">Voltar</ion-button>
  </div>
</section>
