<div class="usuario-form">

  <form mode="md" [formGroup]="form" (ngSubmit)="onSubmit()">
    <usucampeao-input controlName="name" label="Nome completo" [readonly]="!editavel">
      <span usu-error>
        {{ gerarMensagemErro('name') }}
      </span>
    </usucampeao-input>

    <usucampeao-input controlName="cpf" label="CPF" type="tel" inputmode="numeric" mask="000.000.000-00" [readonly]="!editavel">
      <span usu-error>
        {{ gerarMensagemErro('cpf') }}
      </span>
    </usucampeao-input>

    <usucampeao-datepicker formControlName="birthDate" label="Data de nascimento" [maxDate]="maxDate" [disabled]="!editavel">
      <span usu-error>
        {{ gerarMensagemErro('birthDate') }}
      </span>
    </usucampeao-datepicker>

    <usucampeao-select controlName="gender" label="Gênero" [disabled]="!editavel">
      <ion-select-option *ngFor="let genero of generos" [value]="genero.value">{{ genero.label }}</ion-select-option>

      <span usu-error>
        {{ gerarMensagemErro('gender') }}
      </span>
    </usucampeao-select>

    <usucampeao-input controlName="email" label="Email" type="email" inputmode="email" [readonly]="!editavel">
      <span usu-error>
        {{ gerarMensagemErro('email') }}
      </span>
    </usucampeao-input>
  </form>

  <ng-content></ng-content>

  <ion-button *ngIf="editavel" mode="md" (click)="onSubmit()">Salvar</ion-button>
</div>
