import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { PipesModule } from '@usucampeao/utils-frontend';
import { FooterModule, HeaderModule } from '../../../components';
import { ProjetosCalendarioComponent } from './projetos-calendario/projetos-calendario.component';
import { ProjetosDetalhesV2Page } from './projetos-detalhes-v2.page';

@NgModule({
  declarations: [
    ProjetosDetalhesV2Page,
    ProjetosCalendarioComponent,
  ],
  imports: [
    // root
    CommonModule,
    IonicModule,

    // components
    HeaderModule,
    IonicModule,
    FooterModule,

    // others
    PipesModule,
  ],
  exports: [
    ProjetosDetalhesV2Page,
  ],
  providers: [DatePipe]
})
export class ProjetosDetalhesV2PageModule { }
