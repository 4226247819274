import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OutrosDocumentosPage } from './outros-documentos.page';

const routes: Routes = [
  { path: '', component: OutrosDocumentosPage },
  {
    path: 'upload',
    loadChildren: () => import('./outros-documentos-upload/outros-documentos-upload.module').then(m => m.OutrosDocumentosUploadModule)
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OutrosDocumentosRoutingModule { }
