export * from './create-owner.dto';
export * from './get-update-owner-contact.dto';
export * from './get-update-owner-documents.dto';
export * from './get-update-owner-personal-data.dto';
export * from './marital-status.enum';
export * from './matrimonial-regime.enum';
export * from './owner-fill-in-status.dto';
export * from './owner-simplified.dto';
export * from './owner-status.enum';
export * from './owner.dto';
export * from './phone.dto';
export * from './rg.dto';
