import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'selecionar-averbacao', pathMatch: 'full' },
  {
    path: 'selecionar-averbacao',
    loadChildren: () => import('./contrato-selecionar-averbacao/contrato-selecionar-averbacao.page.module').then(m => m.ContratoSelecionarAverbacaoPageModule)
  },
  {
    path: 'selecionar-forma-pagamento',
    loadChildren: () => import('./contrato-selecionar-forma-pagamento/contrato-selecionar-forma-pagamento.page.module').then(m => m.ContratoSelecionarFormaPagamentoPageModule)
  },
  {
    path: 'confirmar-informacoes',
    loadChildren: () => import('./contrato-confirmar-informacoes/contrato-confirmar-informacoes.page.module').then(m => m.ContratoConfirmarInformacoesPageModule)
  },
  {
    path: 'selecionar-tipo-assinatura',
    loadChildren: () => import('./contrato-selecionar-tipo-assinatura/contrato-selecionar-tipo-assinatura.module').then(m => m.ContratoSelecionarTipoAssinaturaPageModule)
  },
  {
    path: 'assinar',
    loadChildren: () => import('./contrato-assinatura/contrato-assinatura.module').then(m => m.ContratoAssinaturaPageModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ContratoRoutingModule { }
