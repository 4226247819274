import { StatusPreenchimento } from '../../enums';

export enum StatusDocument {
  APPROVED = 'APROVADO',
  EXCUSED = 'JUSTIFICADO',
  PENDING = 'PENDENTE',
  REJECTED = 'REJEITADO',
  REMOVED = 'REMOVIDO',
  SENT = 'ENVIADO',
}


export const STATUS_DOCUMENTOS_PENDENTE_APROVACAO = [
  StatusDocument.EXCUSED,
  StatusDocument.PENDING,
  StatusDocument.SENT,
];

export const STATUS_DOCUMENTOS_ENVIADOS = [
  StatusDocument.SENT,
  StatusDocument.APPROVED,
  StatusDocument.EXCUSED,
];

export function converterDocumentoStatusParaStatusPreenchimento(documentoStatus: StatusDocument): StatusPreenchimento {
  switch (documentoStatus) {
    case StatusDocument.REJECTED:
      return StatusPreenchimento.ALTERACAO_NECESSARIA;

    case StatusDocument.PENDING:
    case StatusDocument.REMOVED:
    case null:
    case undefined:
      return StatusPreenchimento.PENDENTE;

    default:
      return documentoStatus as any;
  }
}
